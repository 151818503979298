<template>
  <section style="margin-top: -0.48%; width: 100%; height: 100%">
    <div class="line" style="margin-top: 0.03%; margin-left: 0.2%"></div>
    <div
      class="column"
      style="background-color: white; width: 100%; height: 100%"
      ref="scriptContainerperformanceStatus"
    >
      <performance-status-toolbar />

      <ag-grid-vue
        class="ag-theme-balham ag-default-layout"
        rowSelection="multiple"
        enableCellChangeFlash="true"
        stopEditingWhenGridLosesFocus="true"
        :enableRangeSelection="true"
        :undoRedoCellEditing="true"
        :treeData="false"
        :getDataPath="getDataPath"
        :defaultColDef="defaultColDef"
        :autoGroupColumnDef="autoGroupColumnDef"
        :columnDefs="columnDefs"
        :rowData="rowChildrenData"
        :components="childcomponents"
        :gridOptions="gridChildrenOptions"
        :overlayLoadingTemplate="loadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
        :detailCellRendererParams="detailCellRendererParams"
        @cellClicked="cellClicked"
        @cell-value-changed="onCellValueChanged"
        @row-drag-move="onRowDragMove"
        @row-drag-leave="onRowDragLeave"
        @row-drag-end="onRowDragEnd"
        @grid-ready="onGridReady"
        @sort-changed="onColumnChanged"
        @column-resized="onColumnChanged"
        @column-visible="onColumnChanged"
        @column-row-group-changed="onColumnChanged"
        @column-value-changed="onColumnChanged"
        @column-moved="onColumnChanged"
        @column-pinned="onColumnChanged"
      >
      </ag-grid-vue>
    </div>
  </section>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import performanceStatusToolbar from "./PerformanceStatusToolbar";
import LevelIconCellRenderer from "@/components/Data/LevelIconCellRenderer.vue";
import * as moment from "moment";
import * as utilitiesModule from "@/assets/utilities.js";
import { debounce } from "lodash";
import {
  getColumnDef,
  postColumnState,
  getColumnState,
} from "@/api/networking.js";

export default {
  name: "performanceStatusManager",
  components: {
    AgGridVue,
    performanceStatusToolbar,
    LevelIconCellRenderer, // eslint-disable-line
    //performanceStatusCellRenderer, // eslint-disable-line
  },
  data() {
    return {
      defaultColDef: null,
      autoGroupColumnDef: null,
      columnDefs: null,
      rowChildrenData: null,
      getDataPath: null,
      detailCellRendererParams: {
        refreshStrategy: "everything",
      },
      i: -1,
      gridChildrenOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
        processDataFromClipboard: (params) => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          const lastIndex =
            this.gridChildrenOptions.api.getModel().rowsToDisplay.length - 1;
          const focusedCell = this.gridChildrenOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;
          if (focusedIndex + params.data.length - 1 > lastIndex) {
            const resultLastIndex = focusedIndex + (params.data.length - 1);
            const addRowCount = resultLastIndex - lastIndex;
            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }
            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];
            rowsToAdd.map((r) => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map((i) => {
                row[currColumn.colDef.field] = i;
                currColumn =
                  this.gridChildrenOptions.columnApi.getDisplayedColAfter(
                    currColumn
                  );
              });
              row.extraColumnValues = [];
              row.rowUpdated = true;
              row.id = 1 - this.rowChildrenData.length;
              row.path = [row.id];
              newRowData.push(row);
              this.rowChildrenData.push(row);
            });

            this.gridChildrenOptions.api.applyTransactionAsync({
              add: newRowData,
            });
            this.gridChildrenOptions.api.redrawRows();
          }
          this.gridChildrenOptions.api.refreshCells();
          return params.data;
        },
      },
      groupDefaultExpanded: null,
      childcomponents: {
        performanceStatuscolorPicker: getperformanceStatuscolorPicker(),
        performanceStatusMetricCellRenderer: getperformanceStatusCellRenderer(),
        datePicker: getDatePicker(),
        integerCellEditor: getIntegerCellEditor(),
        decimalCellEditor: getDecimalCellEditor(),
        lookupCellEditor: getLookupCellEditor(),
        //   performanceStatusCellRenderer: getperformanceStatusCellRenderer(),
      },
      undoRedoCellEditingLimit: 15,
      parentObj: null,
      singleClickEdit: false,
      invalidindex: [],
      loadingTemplate: `<span class="ag-overlay-loading-center">Data is loading...</span>`,
      deleteSettings: {
        withBackdrop: true,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "center-center",
      },
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
    };
  },
  watch: {
    LoadChildData() {
      this.invalidindex = [];
      this.rowChildrenData = this.getChildrenCurrentData;
      this.gridChildrenOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.reloadColumnState();

      //this.childcomponents =
    },

    SaveError() {
      this.$vToastify.setSettings(this.NormalSettings);
      this.$vToastify.error("Invalid performanceStatus Resources Type!");
    },
    UndoCounter() {
      this.gridChildrenOptions.api.undoCellEditing();
    },

    RedoCounter() {
      this.gridChildrenOptions.api.redoCellEditing();
    },

    AddRecordCounter() {
      if (this.AddRecordCounter > 0) {
        this.rowChildrenData.push({
          id: -1,
          code: "",
          description: "",
          colour: "",
          value: "",
          sortOrder: "",
          rowUpdated: true,
          extraColumnValues: [],
        });
        this.gridChildrenOptions.api.applyTransaction({
          update: this.rowChildrenData,
        });
        let lastRowIndex = this.gridChildrenOptions.api.getLastDisplayedRow();
        this.gridChildrenOptions.api.redrawRows();
        this.gridChildrenOptions.api.ensureIndexVisible(lastRowIndex);
        this.gridChildrenOptions.api.setFocusedCell(lastRowIndex, "code");
        this.gridChildrenOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
      }
    },

    SaveCounter() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.SaveCounter > 0) {
        let newRecord = [],
          indexes = [],
          emptyRowCounter = 0;
        this.gridChildrenOptions.api.refreshCells();
        this.gridChildrenOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            if (node.data.code != null) {
              this.rowChildrenData.filter((element) => {
                if (element.code === node.data.code && node.key != undefined) {
                  if (node.key != element.id) {
                    console.log(node.key, element.id);
                    node.data.rowUpdated = "Duplicate";
                    indexes.push(node.rowIndex);
                  }
                }
              });
              newRecord.push(node.data);
            } else {
              newRecord = [];
              emptyRowCounter++;
              return false;
            }
          }
        });

        if (emptyRowCounter > 0 || indexes.length > 0) {
          this.gridChildrenOptions.api.redrawRows();
          this.gridChildrenOptions.api.refreshCells();
          newRecord = [];
          emptyRowCounter = 0;
          this.$vToastify.error("Code Value is empty or duplicate!");
        } else {
          this.saveData();
        }
      }
    },

    DeleteCounter() {
      if (this.DeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes =
                this.gridChildrenOptions.api.getSelectedNodes();

              let selectedData = selectedNodes.map((node) => node.data);
              if (selectedData.length > 0) {
                let DataDelete = [],
                  deleteIds = [];
                for (let i = 0; i < selectedData.length; i++) {
                  DataDelete.push(
                    this.rowChildrenData.filter((x) =>
                      x.path.includes(selectedData[i].id)
                    )
                  );
                }
                DataDelete.forEach((element) => {
                  element.forEach((data) => {
                    selectedData.push(data);
                  });
                });

                DataDelete = _.uniqBy(selectedData, "id");
                let Deletevalues = DataDelete.map((x) => x.id);
                Deletevalues.forEach((element) => {
                  deleteIds.push(
                    this.rowChildrenData.findIndex((x) => x.Id == element)
                  );
                });

                deleteIds.forEach((x) => {
                  delete this.rowChildrenData[x];
                });
                let indices = Deletevalues.filter((x) => x > 0);
                if (indices.length > 0) {
                  this.DeleteData({ Id: indices });
                }
                this.gridChildrenOptions.api.applyTransaction({
                  remove: DataDelete,
                });

                deleteIds = null;
                indices = null;
                Deletevalues = null;
                DataDelete = null;
                selectedData = null;
              }
            } else {
              this.$vToastify.setSettings(this.NormalSettings);
            }
          });
      }
    },

    isDelete() {
      if (this.isDelete > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.rowChildrenData = this.getChildrenCurrentData;
        this.gridChildrenOptions.api.applyTransaction({
          update: this.rowChildrenData,
        });
        this.gridChildrenOptions.api.redrawRows();
        this.gridChildrenOptions.api.refreshCells();
        this.$vToastify.success("Row Deleted Successfully!");
      }
    },

    DataWatcher() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.DataWatcher > -1) {
        let tempdata = [];
        this.rowChildrenData = [];
        this.gridChildrenOptions.api.forEachNode((node) => {
          if (node.data.id < 0 || node.data.rowUpdated) {
            tempdata.push(node.data);
          }
        });
        console.log(this.getChildTempData);
        this.gridChildrenOptions.api.applyTransaction({
          remove: tempdata,
        });
        this.gridChildrenOptions.api.applyTransaction({
          add: this.getChildTempData,
        });
        this.gridChildrenOptions.api.redrawRows();
        this.gridChildrenOptions.api.refreshCells();
        this.gridChildrenOptions.api.clearFocusedCell();

        this.gridChildrenOptions.api.forEachNode((node) => {
          node.data.rowUpdated = false;

          this.rowChildrenData.push(node.data);
        });
        this.setCurrentDate(this.rowChildrenData);
        let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
        if (selectedNodes.length > 0) {
          this.gridChildrenOptions.api.selectIndex(
            selectedNodes[0].rowIndex,
            false,
            false
          );
          this.gridChildrenOptions.api.setFocusedCell(
            selectedNodes[0].rowIndex,
            "code"
          );
          this.setChildrenObj(selectedNodes[0].data);
        }
        this.invalidindex = [];
        this.$vToastify.success("Data successfully saved!");
      } else {
        this.rowChildrenData = [];
        this.gridChildrenOptions.api.redrawRows();
        this.gridChildrenOptions.api.refreshCells();
      }
    },

    ColumnDefCounters() {
      // this.columnDefs = this.getChildrenGridColumn;
      this.getChildrenGridColumn.forEach((row) => {
        this.columnDefs.push(row);
      });
    },
  },

  computed: {
    /**
     * Load CostElements Controller Gettters, states
     */

    ...mapGetters("performanceStatusController", [
      "getChildrenGridColumn",
      "getChildrenCurrentData",
      "getAllStructureDetailsvalue",
      "getCopiedRows",
      "getChildrenSelectedId",
      "getChildTempData",
      "getCalenderData",
      "getChildrenSelectedObj",
      "getResourceTypeData",
    ]),

    /**performanceStatusResourceController */

    ...mapState({
      colDefs: (state) =>
        state.performanceStatusController.currentChildrenColDef,
      ColumnDefCounters: (state) =>
        state.performanceStatusController.currentChildrenGridColDefCountCounter,
      LoadChildData: (state) =>
        state.performanceStatusController.currentChildrenLoadRecordCounter,
      UndoCounter: (state) =>
        state.performanceStatusController.currentChildrenUndoWatcher,
      RedoCounter: (state) =>
        state.performanceStatusController.currentChildrenRedoWatcher,
      PasteRowCounter: (state) =>
        state.performanceStatusController.duplicateRecord,
      AddRecordCounter: (state) =>
        state.performanceStatusController.currentChildrenAddRecordCounter,
      DeleteCounter: (state) =>
        state.performanceStatusController.currentChildrenDeleteRecordCounter,
      isDelete: (state) => state.performanceStatusController.isDelete,
      SaveCounter: (state) =>
        state.performanceStatusController.currentChildrenSaveRecordCounter,
      DataWatcher: (state) => state.performanceStatusController.datawatcher,
      SaveError: (state) => state.performanceStatusController.SaveError,
      calenderLoad: (state) =>
        state.performanceStatusController.tempCalenderCounter,
      resourceLoad: (state) =>
        state.performanceStatusController.tempResourceCounter,
    }),

    noRowsTemplate() {
      if (this.isElementdetail) {
        return this.loadingTemplate;
      } else {
        return `<span>No Rows to Show</span>`;
      }
    },
  },
  methods: {
    /**
     * Load CostElements Controller Actions, Mutation
     */

    ...mapActions("performanceStatusController", [
      "loadperformanceStatus",
      "saveData",
      "DeleteData",
      "LoadResourceTypesColumn",
      "LoadExtraColumnValue",
    ]),
    ...mapMutations("performanceStatusController", [
      "setChildrenObj",
      "setSelectedChildrenRowObject",
      "mutateChildrenUndostate",
      "mutateChildrenRedostate",
      "mutateRecord",
      "setCurrentDate",
    ]),

    cellClicked(params) {
      this.setChildrenObj(params.node.data);
      //let selectedNodes = params.node;
      params.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();
      if (params.node.data) {
        let selectedNodes = params.node.data;
        if (selectedNodes.length > 0) {
          this.setChildrenObj(params.node.data);
        }
      }

      if (params.column.colId == "code") {
        var CodeIndex = this.rowChildrenData.filter(
          (x) => x.id != params.node.data.id
        );
        CodeIndex = CodeIndex.findIndex(
          (x) => x.code.toLowerCase() == params.newValue.toLowerCase()
        );
        if (CodeIndex > -1) {
          this.$vToastify.setSettings(this.NormalSettings);
          this.$vToastify.error("Duplicate code value detected!");
          params.node.data.code = params.newValue;
          params.node.data.rowUpdated = "Duplicate";
        } else {
          params.node.data.rowUpdated = true;
        }
      } else if (params.column.colId.match("et_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;

        tempIndex = params.node.data.extraColumnValues.findIndex(
          (y) => y.PerformanceStatusExtraColumnId == parseInt(colvalue[1])
        );
        params.node.data.rowUpdated = true;
        if (tempIndex < 0) {
          params.node.data.extraColumnValues.push({
            PerformanceStatusId: params.node.data.id,
            PerformanceStatusExtraColumnId: colvalue[1],
            value: params.newValue,
          });
        } else {
          params.node.data.extraColumnValues[tempIndex].value = params.newValue;
        }
      }

      params.node.data.rowUpdated = true;
      // params.api.refreshCells({ force: true, suppressFlash: true });
      if (undoSize == 0) {
        this.mutateChildrenUndostate(false);
        params.node.data.rowUpdated = false;
      } else {
        this.mutateChildrenUndostate(true);
      }

      if (redoSize == 0) {
        this.mutateChildrenRedostate(false);
      } else {
        this.mutateChildrenRedostate(true);
        params.node.data.rowUpdated = false;
      }
      params.api.refreshCells({ force: true, suppressFlash: true });
    },

    onRowDragMove(event) {
      setperformanceStatusPotentialParentForNode(event.api, event.overNode);
    },

    onRowDragLeave(event) {
      setperformanceStatusPotentialParentForNode(event.api, null);
    },

    onRowDragEnd(event) {
      this.$vToastify.setSettings(this.NormalSettings);
      if (!potentialParent) {
        return;
      }
      var movingData = event.node.data;
      var newParentPath = potentialParent.data ? potentialParent.data.path : [];
      if (potentialParent.data.id > 0) {
        var needToChangeParent = !areperformanceStatusPathsEqual(
          newParentPath,
          movingData.path
        );
        var invalidMode = isSelectionperformanceStatusParentOfTarget(
          event.node,
          potentialParent
        );
        if (invalidMode) {
          this.$vToastify.error("invalid move!");
          console.error("invalid move");
        }
        if (needToChangeParent && !invalidMode) {
          var updatedRows = [];
          moveperformanceStatusToPath(newParentPath, event.node, updatedRows);

          updatedRows.forEach((element) => {
            element.rowUpdated = true;
          });
          if (updatedRows[0].path.length > 1) {
            updatedRows[0].parentId =
              updatedRows[0].path[updatedRows[0].path.length - 2];
          } else {
            updatedRows[0].parentId = null;
          }
          this.gridChildrenOptions.api.applyTransactionAsync({
            update: updatedRows,
          });

          this.gridChildrenOptions.api.clearFocusedCell();
        }
      } else {
        this.$vToastify.error("invalid move!");
        console.error("invalid move");
      }
      setperformanceStatusPotentialParentForNode(event.api, null);
    },

    onGridReady(params) {
      getColumnDef(34)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerperformanceStatus;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time

          // NOTE: we can load month columns and row columns at same time
          this.LoadExtraColumnValue("PerformanceStatus").then(() => {
            this.loadperformanceStatus();
          }); //LoadExtraColumnValue this.loadperformanceStatusMetric();
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    reloadColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 34)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridChildrenOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnChanged: debounce(function () {
      this.onColumnStateSubmit();
    }, 300),

    onColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridChildrenOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 34,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },
  },

  beforeMount() {
    /*Set Grid Tree Collumn */
    /*  this.components = {
      // performanceStatusMetricCellRenderer: getperformanceStatusCellRenderer(),
    }; */
    /* this.defaultColDef = Object.assign({}, this.defaultColDef, {
      width: 180,
    }); */
    this.autoGroupColumnDef = {};
    this.getDataPath = (data) => {
      return data.path;
    };
    this.columnDefs = [];
    /**
     * Load Column Defination
     * Load All CostElement's Data
     * Set Basic Functions Here
     */
  },

  mounted() {
    //this.columnDefs = this.getChildrenGridColumn;
    //this.loadperformanceStatus();
    //
  },
};

window.moveperformanceStatusToPath = function moveperformanceStatusToPath(
  newParentPath,
  node,
  allUpdatedNodes
) {
  //console.log("adsadasdas");
  var oldPath = node.data.path;
  var fileName = oldPath[oldPath.length - 1];
  var newChildPath = newParentPath.slice();
  newChildPath.push(fileName);
  node.data.path = newChildPath;
  allUpdatedNodes.push(node.data);
  if (node.childrenAfterGroup) {
    node.childrenAfterGroup.forEach(function (childNode) {
      moveperformanceStatusToPath(newChildPath, childNode, allUpdatedNodes);
    });
  }
};

window.isSelectionperformanceStatusParentOfTarget =
  function isSelectionperformanceStatusParentOfTarget(
    selectedNode,
    targetNode
  ) {
    var children = selectedNode.childrenAfterGroup;
    for (var i = 0; i < children.length; i++) {
      if (targetNode && children[i].key === targetNode.key) return true;
      isSelectionperformanceStatusParentOfTarget(children[i], targetNode);
    }
    return false;
  };

window.areperformanceStatusPathsEqual = function areperformanceStatusPathsEqual(
  path1,
  path2
) {
  //console.log(path2, path2, path1);
  if (path1.length !== path2.length) {
    return false;
  }
  var equal = true;
  path1.forEach(function (item, index) {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
};

window.setperformanceStatusPotentialParentForNode =
  function setperformanceStatusPotentialParentForNode(api, overNode) {
    var newPotentialParent;
    if (overNode) {
      newPotentialParent = overNode;
    } else {
      newPotentialParent = null;
    }
    var alreadySelected = potentialParent === newPotentialParent;
    if (alreadySelected) {
      return;
    }
    var rowsToRefresh = [];
    if (potentialParent) {
      rowsToRefresh.push(potentialParent);
    }
    if (newPotentialParent) {
      rowsToRefresh.push(newPotentialParent);
    }
    potentialParent = newPotentialParent;
    refreshRows(api, rowsToRefresh);
  };

window.refreshRows = function refreshRows(api, rowsToRefresh) {
  var params = {
    rowNodes: rowsToRefresh,
    force: true,
  };
  api.refreshCells(params);
};

window.getperformanceStatusCellRenderer =
  function getperformanceStatusCellRenderer() {
    function performanceStatusCellRenderer() {}
    performanceStatusCellRenderer.prototype.init = function (params) {
      var tempDiv = document.createElement("div");

      // params.api.refreshCells({ force: true, suppressFlash: true });
      tempDiv.innerHTML =
        params.node.data.resourceTypeId == 1
          ? params.node.allChildrenCount == null && params.data.parentId == null
            ? '<img src="Icons/resources2.svg"; alt="" style="margin-top:5px; margin-left:28px; height:20px; width:20px;"/>'
            : '<img src="Icons/resources2.svg"; alt="" style="margin-top:5px; height:20px; width:20px;"/>'
          : params.node.data.resourceTypeId == 2 ||
            params.node.data.resourceTypeId == 5 ||
            params.node.data.resourceTypeId == 6
          ? params.node.allChildrenCount == null && params.data.parentId == null
            ? '<img src="Icons/labour2.svg"; alt="" style="margin-top:5px; margin-left:28px;  height:20px; width:20px;"/>'
            : '<img src="Icons/labour2.svg"; alt="" style="margin-top:5px; height:20px; width:20px;"/>'
          : params.node.data.resourceTypeId == 3
          ? params.node.allChildrenCount == null && params.data.parentId == null
            ? '<img src="Icons/materials2.svg"; alt="" style="margin-top:5px; margin-left:28px; height:20px; width:20px;"/>'
            : '<img src="Icons/materials2.svg"; alt="" style="margin-top:5px; height:20px; width:20px;"/>'
          : params.node.data.resourceTypeId == 4
          ? params.node.allChildrenCount == null && params.data.parentId == null
            ? '<img src="Icons/equipment2.svg";  alt="" style="margin-top:5px; margin-left:28px;  height:20px; width:20px;"/>'
            : '<img src="Icons/equipment2.svg"; alt="" style="margin-top:5px;  height:20px; width:20px;"/>'
          : null;

      this.eGui = tempDiv.firstChild;
    };
    performanceStatusCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };
    return performanceStatusCellRenderer;
  };

var potentialParent = null;

window.getDatePicker = function getDatePicker() {
  function Datepicker() {}
  var tempDatevalue = null;
  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "date");
    if (params.value != null) {
      this.eInput.value = moment(params.value).toISOString().substr(0, 10);
    }
    tempDatevalue = params.value; // moment(this.getSelectedRowObject.endDate).toISOString().substr(0, 10);

    this.eInput.classList.add("input");
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
    /*  $(this.eInput).datepicker({
      constrainInput: true, // prevent letters in the input field
      autoSize: true, // automatically resize the input field
      dateFormat: "dd/mm/yyyy",
      changeMonth: true,
      changeYear: true,
      yearRange: "1930:" + new Date().getFullYear(),
    }); */
  };
  Datepicker.prototype.getGui = function () {
    return this.eInput;
  };
  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Datepicker.prototype.getValue = function () {
    if (this.eInput.value.length > 0) {
      return moment(this.eInput.value).add(1, "days").format("YYYY-MM-DD");
    } else {
      return tempDatevalue;
    }
  };
  Datepicker.prototype.destroy = function () {};
  Datepicker.prototype.isPopup = function () {
    return false;
  };
  return Datepicker;
};

window.getIntegerCellEditor = function getIntegerCellEditor() {
  function IntegerPicker() {}
  IntegerPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.setAttribute("step", "1");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };

  /*  IntegerPicker.prototype.isKeyPressedNavigation = function(event) {
    return event.keyCode !=110;
  }; */
  IntegerPicker.prototype.getGui = function () {
    return this.eInput;
  };
  IntegerPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  IntegerPicker.prototype.getValue = function () {
    return parseInt(this.eInput.value);
  };
  IntegerPicker.prototype.destroy = function () {};
  IntegerPicker.prototype.isPopup = function () {
    return false;
  };
  return IntegerPicker;
};

window.getDecimalCellEditor = function getDecimalCellEditor() {
  function DecimalPicker() {}
  DecimalPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };
  DecimalPicker.prototype.getGui = function () {
    return this.eInput;
  };
  DecimalPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  DecimalPicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  DecimalPicker.prototype.destroy = function () {};
  DecimalPicker.prototype.isPopup = function () {
    return false;
  };
  return DecimalPicker;
};

window.getLookupCellEditor = function getLookupCellEditor() {
  function Lookuppicker() {}
  Lookuppicker.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var CostModelTypeFilter = CostModelTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );
    CostModelTypeFilter.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  Lookuppicker.prototype.getGui = function () {
    return this.eInput;
  };
  Lookuppicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Lookuppicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Lookuppicker.prototype.destroy = function () {};
  Lookuppicker.prototype.isPopup = function () {
    return false;
  };
  return Lookuppicker;
};

var ResourceTypes = null;

window.SetResourceTypes = function SetResourceTypes(data) {
  ResourceTypes = data;
};

window.getResourceTypesModelCellRenderer =
  function getResourceTypesModelCellRenderer() {
    function CustomResourceTypesCombobox() {}
    CustomResourceTypesCombobox.prototype.init = function (params) {
      this.eInput = document.createElement("select");
      this.eInput.setAttribute("class", "select");
      this.eOption = document.createElement("option");
      this.eInput.setAttribute("style", "width:100%");
      this.eInput.setAttribute("id", "resourceType");
      var ResourceTypesIDs = ResourceTypes.sort((a, b) =>
        a.description > b.description ? 1 : -1
      );
      //console.log(ResourceTypesIDs);
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      // this.eInput.appendChild(this.eOptionVal);
      ResourceTypesIDs.forEach((x) => {
        this.eOptionVal = document.createElement("option");
        //Statical set data in grid ComboBox
        this.eOptionVal.text = x.description;
        this.eOptionVal.value = x.description;
        if (x.description == params.value) {
          this.eOptionVal.selected = true;
        }
        this.eInput.appendChild(this.eOptionVal);
      });
    };
    CustomResourceTypesCombobox.prototype.getGui = function () {
      return this.eGui;
    };

    CustomResourceTypesCombobox.prototype.getGui = function () {
      return this.eInput;
    };
    CustomResourceTypesCombobox.prototype.afterGuiAttached = function () {
      this.eInput.focus();
    };
    CustomResourceTypesCombobox.prototype.getValue = function () {
      return this.eInput.value;
    };
    CustomResourceTypesCombobox.prototype.destroy = function () {};
    CustomResourceTypesCombobox.prototype.isPopup = function () {
      return false;
    };
    return CustomResourceTypesCombobox;
  };

window.getperformanceStatuscolorPicker =
  function getperformanceStatuscolorPicker() {
    function performanceStatuscolorPicker() {}
    performanceStatuscolorPicker.prototype.init = function (params) {
      this.eInput = document.createElement("input");
      this.eInput.setAttribute("type", "color");
      if (params.value == null) {
        this.eInput.value = "#FFFFFF";
      } else {
        this.eInput.value = params.value;
      }
      this.eInput.classList.add("input");
      this.eInput.style.display = "inline-block";
      this.eInput.setAttribute("style", "width:100%");
      this.eInput.style.height = "100%";
      this.eInput.style.fontFamily = "Helvetica";
      this.eInput.style.fontweight = "bold";
      this.eInput.style.fontSize = "1.0em";
    };
    performanceStatuscolorPicker.prototype.getGui = function () {
      return this.eInput;
    };
    performanceStatuscolorPicker.prototype.afterGuiAttached = function () {
      this.eInput.focus();
      this.eInput.select();
    };
    performanceStatuscolorPicker.prototype.getValue = function () {
      return this.eInput.value;
    };
    performanceStatuscolorPicker.prototype.destroy = function () {};
    performanceStatuscolorPicker.prototype.isPopup = function () {
      return false;
    };
    return performanceStatuscolorPicker;
  };
</script>

<style scoped lang="scss">
.line {
  width: 99.5%;
  height: 1%;
  border-bottom: 1px solid black;
  position: relative;
}
</style>
