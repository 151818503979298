import { DependencyModel } from "@bryntum/gantt";

// here you can extend our default Task class with your additional fields, methods and logic
export default class JiveConnectDependency extends DependencyModel {
  static get fields() {
    return [
      { name: "PredecessorScheduleId", type: "string" },
      { name: "SuccessorScheduleId", type: "string" },
      { name: "ActivityRelationType", type: "string" },
    ];
  }
}
