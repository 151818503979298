import { userService } from "@/api/user-auth";
import Vue from "vue";

const state = {
  entitiesLoaded: false,
  localAllEntities: [],
  selectedEntitites: [],
};
const actions = {
  loadAllData({ commit }) {
    state.entitiesLoaded = false;
    userService
      .getJson(`rowtype/GetAll`)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          commit("mutateAllEntities", result.data);
          state.entitiesLoaded = true;
        }
      })
      .catch((error) => {
        //dispatch("alert/error", error, { root: true });
      });
  },
};
const mutations = {
  mutateAllEntities(state, result) {
    state.localAllEntities = result;
  },
};
const getters = {
  allEntities(state) {
    return state.localAllEntities;
  },
  //   getIdByName(state) {
  //     return name => state.localAllEntities.find(x => x.Code.toLowerCase() == name.toLowerCase());
  //   },
  getIdByName: (state) => (id) => {
    return state.localAllEntities.find((x) => x.code.toLowerCase() == id).id;
  },
};

export const rowTypeDataManager = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
