<template>
  <div id="app" class="is-clipped">
    <TopAlertBar />

    <div class="columns is-mobile is-gapless">
      <!-- <CollapsableSideBar /> -->
      <DynamicCollapsableSideBar />
      <div class="column">
        <NavBar />
        <!-- Main Content Presented -->
        <router-view id="main-content" />
      </div>
      <CollapsableActionBar />
    </div>
    <StructureFilter v-if="isStructureFilterModalVisible" />
    <StructureRelation v-if="isRelationFilterModalVisible" />
    <StructureDetail v-if="isstrucuredetailModalVisible" />
    <ActivityDetail v-if="isactivityModalVisible" />
    <CommitmentCostElement v-if="iscommitmentModalVisible" />

    <CalendarWorkingNonWorkingInterval v-if="isnonWorkingModalVisible" />
  </div>
</template>

<style lang="scss">
@import "@/assets/main.scss";
@import "@/assets/main-ag.scss";
@import "@/assets/loadingScreen.scss";
@import "~@bryntum/gantt/gantt.stockholm.css";

#main-content {
  background-color: #d9e9e9 !important;
  //overflow-y: overlay !important;
  // width: 100% !important;
  // display: grid;
  height: calc(100% - 54px) !important;
}
</style>

<style scoped lang="scss">
.columns,
.column {
  height: 100%;
}
</style>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import NavBar from "@/components/Navigation/NavBar.vue";
import TopAlertBar from "@/components/Alerts/TopAlertBar.vue";
import CollapsableSideBar from "@/components/Navigation/CollapsableSideBar.vue";
import DynamicCollapsableSideBar from "@/components/Navigation/DynamicCollapsableSideBar.vue";
import CollapsableActionBar from "@/components/Navigation/CollapsableActionBar.vue";
import StructureFilter from "@/components/Filters/StructureFilter.vue";
import StructureRelation from "@/components/Relationship/RelationShipFilter.vue";
import StructureDetail from "@/components/StructureDetail/StructureDetail.vue";
import ActivityDetail from "@/components/Activity/ActivityDetails.vue";
import CommitmentCostElement from "@/components/CommitmentCostElement/CommitmentCostElement.vue";
import CalendarWorkingNonWorkingInterval from "@/components/CalendarAdmin/CalendarWorkNoWorkingPopup.vue";
export default {
  name: "app",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      views: (state) => state.userView.views,
      isMenuExpanded: (state) => state.filterControl.isMenuExpanded,
      isStructureFilterModalVisible: (state) =>
        state.filterControl.isStructureFilterModalVisible,
      isRelationFilterModalVisible: (state) =>
        state.relationControl.isRelationFilterModalVisible,
      isstrucuredetailModalVisible: (state) =>
        state.structredetailControl.isstrucuredetailModalVisible,
      isactivityModalVisible: (state) =>
        state.activityControl.isactivityModalVisible,
      iscommitmentModalVisible: (state) =>
        state.commitmentCostElementControl.isCommitmentCostElementModalVisible,
      isnonWorkingModalVisible: (state) =>
        state.calendarController.isnonWorkingModalVisible,
    }),
    ...mapGetters("account", ["loggedIn", "clientId"]),
    ...mapGetters("userView", ["selectedView", "selectedViewTitle"]),
    ...mapGetters("dataManager", ["selectedDataType"]),
    ...mapGetters("dashboardMenu", [
      "selectedDashboardType",
      "selectedDashboardValues",
    ]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
      this.clearAlert();
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
    }),

    ...mapMutations("userView", ["mutateSelectedView"]),
    ...mapMutations("dataManager", ["mutateSelectedDataType"]),
    ...mapMutations("dashboardMenu", ["mutateSelectedDashboardType"]),
    ...mapActions("account", ["reloadGlobalState"]),
  },
  mounted: function () {
    if (this.loggedIn) {
      this.reloadGlobalState();
    }
  },
  components: {
    NavBar,
    TopAlertBar,
    //CollapsableSideBar,
    DynamicCollapsableSideBar,
    CollapsableActionBar,
    StructureFilter,
    StructureRelation,
    StructureDetail,
    ActivityDetail,
    CommitmentCostElement,
    CalendarWorkingNonWorkingInterval,
  },
};
</script>
