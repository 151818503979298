<template>
  <section
    class="column"
    style="margin-top: -0.47%"
    ref="scriptContainerCostElement"
  >
    <div class="line" style="margin-top: -0.17%; margin-left: 0.2%"></div>
    <div
      class="columns"
      style="background-color: white; height: 101.7%; width: 101.5%"
    >
      <div
        class="column"
        style="margin-left: 0.52%; margin-right: 1%; height: 100%; width: 100%"
      >
        <CostElementToolbar />

        <ag-grid-vue
          class="ag-theme-balham ag-default-layout"
          rowSelection="multiple"
          enableCellChangeFlash="true"
          stopEditingWhenGridLosesFocus="true"
          :suppressRowClickSelection="true"
          :enableRangeSelection="true"
          :undoRedoCellEditing="true"
          :treeData="true"
          :valueCache="true"
          :getDataPath="getDataPath"
          :defaultColDef="defaultColDef"
          :autoGroupColumnDef="autoGroupColumnDef"
          :columnDefs="columnDefs"
          :rowData="rowChildrenData"
          :gridOptions="gridChildrenOptions"
          :components="childcomponents"
          :overlayLoadingTemplate="loadingTemplate"
          :overlayNoRowsTemplate="noRowsTemplate"
          :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
          :detailCellRendererParams="detailCellRendererParams"
          @cellClicked="cellClicked"
          @cell-value-changed="onCellValueChanged"
          @row-drag-move="onRowDragMove"
          @row-drag-leave="onRowDragLeave"
          @row-drag-end="onRowDragEnd"
          @grid-ready="onGridReady"
          @sort-changed="onColumnChanged"
          @column-resized="onColumnChanged"
          @column-visible="onColumnChanged"
          @column-row-group-changed="onColumnChanged"
          @column-value-changed="onColumnChanged"
          @column-moved="onColumnChanged"
          @column-pinned="onColumnChanged"
        >
        </ag-grid-vue>
      </div>
    </div>
  </section>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import CostElementToolbar from "./CostElementToolbar.vue";
import LevelIconCellRenderer from "@/components/Data/LevelIconCellRenderer.vue";
import * as moment from "moment";
import * as utilitiesModule from "@/assets/utilities.js";
import { debounce } from "lodash";
import {
  getColumnDef,
  postColumnState,
  getColumnState,
} from "@/api/networking.js";
export default {
  name: "CostElementComponent",
  components: {
    AgGridVue,
    CostElementToolbar,
    LevelIconCellRenderer, // eslint-disable-line
  },
  data() {
    return {
      defaultColDef: null,
      autoGroupColumnDef: null,
      columnDefs: null,
      rowChildrenData: null,
      tempChildData: null,
      getDataPath: null,
      componentId: null,
      detailCellRendererParams: {
        refreshStrategy: "everything",
      },
      i: -1,
      gridChildrenOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
            { statusPanel: "agAggregationComponent" },
          ],
        },
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
        processDataFromClipboard: (params) => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
          this.gridChildrenOptions.api.forEachNode((node) => {
            if (node.data.parentId === selectedNodes[0].data.parentId) {
              node.setSelected(true);
              node.setExpanded(false);
            }
          });
          let selectedData = selectedNodes.map((node) => node.data);
          let selectedKey = selectedData[0].id;
          selectedData = selectedData[0].parentId;

          selectedNodes = this.tempChildData;
          /*   this.gridChildrenOptions.api.getModel().gridOptionsWrapper.gridOptions
              .rowData; */
          const indexes = selectedNodes
            .map((selectedNodes, i) =>
              selectedNodes.parentId === selectedData ? i : null
            )
            .filter((i) => i !== null);

          const focusedCell = this.gridChildrenOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;
          const getParentId =
            this.gridChildrenOptions.api.getSelectedNodes(focusedIndex);

          var tempId = getParentId.findIndex((x) => x.data.id == selectedKey);
          if (tempId + params.data.length - 1 > indexes.length - 1) {
            const addRowCount = params.data.length - (indexes.length - tempId);
            this.i--;
            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            var temp = null;
            if (getParentId[tempId].data.id < 0) {
              this.i = getParentId[tempId].data.id;
            }
            if (getParentId[tempId].data.path.length > 1) {
              temp = getParentId[tempId].data.path.length;
              temp = getParentId[tempId].data.path.slice(0, temp - 1);
            }
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }

            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];

            //rowsToAdd = rowsToAdd.filter(Boolean);
            rowsToAdd.map((r) => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map((i) => {
                row[currColumn.colDef.field] = i;
                currColumn =
                  this.gridChildrenOptions.columnApi.getDisplayedColAfter(
                    currColumn
                  );
              });
              row.rowUpdated = true;
              row.CostHeaderID = this.parentObj.id;
              row.parentId = getParentId[tempId].data.parentId;
              row.id = this.i - 1;
              if (row.parentId != null) {
                if (getParentId[0].data.id < 0) {
                  row.path = temp.concat([row.id]); //[row.parentId, row.id];
                } else {
                  if (getParentId[0].data.path.length < 2) {
                    row.path = [row.parentId, row.id];
                  } else {
                    row.path = temp.concat([row.id]);
                  }
                }
              } else {
                row.path = [row.id];
              }
              row.estimateDetails = [];
              row.budgetDetails = [];
              row.objectRelationships = [];
              row.extraColumnValues = [];
              row.commitmentDetails = [];
              row.activitiyDetails = [];
              // row.tempCostElementType = null;
              row.tempCostElementTypeIcon = null;
              if (
                row.tempCostElementType != null ||
                row.tempCostElementType != undefined
              ) {
                let tempData = this.getCostElementTypes.find(
                  (y) => y.description == row.tempCostElementType
                );
                if (typeof tempData == "object") {
                  row.costElementTypeId = tempData.id;
                  row.tempCostElementTypeIcon = tempData.icon;
                }
              }
              // console.log(row.tempCostElementType);
              newRowData.push(row);
              this.tempChildData.push(row);
              this.i--;
            });
            this.gridChildrenOptions.api.applyTransactionAsync({
              add: newRowData,
            });
            this.gridChildrenOptions.api.redrawRows();
            this.gridChildrenOptions.api.refreshCells();
          }
          var undoSize = this.gridChildrenOptions.api.getCurrentUndoSize();
          if (undoSize == 0) {
            this.mutateChildrenUndostate(true);
          }
          return params.data;
        },
      },
      groupDefaultExpanded: null,
      childcomponents: {
        costElementCellRenderer: getCostElementCellRenderer(),
        CustomCombobox: getCostModelCellRenderer(),
        datePicker: getDatePicker(),
        integerCellEditor: getIntegerCellEditor(),
        decimalCellEditor: getDecimalCellEditor(),
        lookupCellEditor: getLookupCellEditor(),
        CostElementTypeCombobox: getCostElementTypeCombobox(),
      },
      undoRedoCellEditingLimit: 15,
      parentObj: null,
      singleClickEdit: false,
      invalidindex: [],
      loadingTemplate: `<span class="ag-overlay-loading-center">Data is loading...</span>`,
      deleteSettings: {
        withBackdrop: true,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "center-center",
      },
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
      tempCommimentId: null,
    };
  },
  watch: {
    CommitmentColummCounter() {
      this.getChildrenGridColumn.forEach((row) => {
        this.columnDefs.push(row);
      });
      /*  this.columnDefs = this.getChildrenGridColumn;
      this.gridChildrenOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      }); */
    },

    ColDef() {
      this.getTempChildColDef.forEach((row) => {
        this.columnDefs.push(row);
      });
      this.reloadColumnState();
    },

    LoadChildData() {
      if (this.parentObj) {
        this.invalidindex = [];
        this.rowChildrenData = this.getChildrenCurrentData;
        this.tempChildData = Array.from(this.getChildrenCurrentData);
        this.gridChildrenOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        SetCostElementTypes(this.getCostElementTypes);
      }
    },

    UndoCounter() {
      this.gridChildrenOptions.api.undoCellEditing();
    },

    RedoCounter() {
      this.gridChildrenOptions.api.redoCellEditing();
    },

    PasteRowCounter() {
      if (this.getCopiedRows.length > 0) {
        this.getCopiedRows.forEach((row) => {
          this.tempChildData.push(row);
        });
      }
      this.gridChildrenOptions.api.applyTransaction({
        add: this.getCopiedRows,
      });

      this.GridExapnd(this.getCopiedRows[0].id, false);
    },

    AddCounter() {
      setTimeout(() => {
        this.SetFocus();
      }, 100);
    },

    codeValue() {
      let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
      let dataIndex = this.tempChildData.findIndex(
        (x) => x.id == selectedNodes[0].data.id
      );
      let colvalue = "sd_" + this.getStructureIdvalue;

      this.tempChildData[dataIndex].rowUpdated = true;
      let tempIndex = this.tempChildData[dataIndex].structureDetails.findIndex(
        (y) => y.structureId == this.getStructureIdvalue
      );
      if (tempIndex < 0) {
        this.tempChildData[dataIndex].objectRelationships.push({
          structureDetailId: this.structuredetailId,
        });
        let tempData = this.getAllStructureDetailsvalue.find(
          (x) => x.id == this.structuredetailId
        );

        this.tempChildData[dataIndex].structureDetails.push(tempData);
        //selectedNodes[0].data.structureDetails.push(tempData);

        selectedNodes[0].data.objectRelationships.push({
          structureDetailId: this.structuredetailId,
        });
        //  console.log(this.tempChildData[dataIndex].structureDetails);
      } else {
        this.tempChildData[dataIndex].objectRelationships[
          tempIndex
        ].structureDetailId = this.structuredetailId;

        this.tempChildData[dataIndex].structureDetails[tempIndex] =
          this.getAllStructureDetailsvalue.find(
            (x) => x.id == this.structuredetailId
          );

        selectedNodes[0].data.structureDetails[tempIndex] =
          this.getAllStructureDetailsvalue.find(
            (x) => x.id == this.structuredetailId
          );

        selectedNodes[0].data.objectRelationships[tempIndex].structureDetailId =
          this.structuredetailId;
      }

      this.tempChildData[dataIndex][colvalue] = this.codeValue;
      this.gridChildrenOptions.api.applyTransactionAsync({
        update: [selectedNodes[0].data],
      });
      this.gridChildrenOptions.api.redrawRows();
      this.gridChildrenOptions.api.refreshCells();
    },

    activitiescodeValue() {
      let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
      let dataIndex = this.tempChildData.findIndex(
        (x) => x.id == selectedNodes[0].data.id
      );
      let colvalue = "activities";
      this.tempChildData[dataIndex].rowUpdated = true;
      this.tempChildData[dataIndex][colvalue] = this.activitiescodeValue;
      this.gridChildrenOptions.api.applyTransactionAsync({
        update: [selectedNodes[0].data],
      });
      let tempactivitiesId = this.getCurrentActivities.find(
        (x) => x.id == this.activitydetailId
      );

      var tempindex = selectedNodes[0].data.objectRelationships.findIndex(
        (x) => x.activityId > 0
      );
      selectedNodes[0].data.activitiyDetails = [tempactivitiesId];
      if (tempindex > 0) {
        selectedNodes[0].data.objectRelationships[tempindex].activityId =
          this.activitydetailId;
      } else {
        selectedNodes[0].data.objectRelationships.push({
          activityId: this.activitydetailId,
        });
      }
      this.gridChildrenOptions.api.redrawRows();
      this.gridChildrenOptions.api.refreshCells();
    },

    CommitmentSaveCounter() {
      let tempColValue = this.getCostModelTypeData.findIndex(
        (x) => x.id == this.tempCommimentId
      );
      if (tempColValue > -1) {
        let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
        let dataIndex = this.tempChildData.findIndex(
          (x) => x.id == selectedNodes[0].data.id
        );
        let colvalue = "cmit_" + this.tempCommimentId;
        this.tempChildData[dataIndex].rowUpdated = true;
        this.tempChildData[dataIndex][colvalue] =
          this.CommitmentCostElementcodeValue;
        this.gridChildrenOptions.api.applyTransactionAsync({
          update: [selectedNodes[0].data],
        });
        let tempcommitId = this.getCurrentCommitmentCostElement.find(
          (x) => x.id == this.CommitmentCostElementdetailId
        );

        //console.log(this.getCostModelTypeData[tempColValue].rowTypeId);

        if (this.getCostModelTypeData[tempColValue].rowTypeId == 7) {
          var tempindex = selectedNodes[0].data.objectRelationships.findIndex(
            (x) => x.commitmentCostElementId > 0
          );
          selectedNodes[0].data.commitmentDetails = [tempcommitId];
          if (tempindex > 0) {
            selectedNodes[0].data.objectRelationships[
              tempindex
            ].commitmentCostElementId = this.CommitmentCostElementdetailId;
          } else {
            selectedNodes[0].data.objectRelationships.push({
              commitmentCostElementId: this.CommitmentCostElementdetailId,
            });
          }
        } else if (this.getCostModelTypeData[tempColValue].rowTypeId == 5) {
          //console.log(this.getCostModelTypeData[tempColValue].code);
          if (
            this.getCostModelTypeData[tempColValue].code == "BUD" &&
            this.getCostModelTypeData[tempColValue].id == this.tempCommimentId
          ) {
            tempindex = selectedNodes[0].data.objectRelationships.findIndex(
              (x) => x.planningCostElementId > 0
            );
            selectedNodes[0].data.budgetDetails = [tempcommitId];
            if (tempindex > 0) {
              selectedNodes[0].data.objectRelationships[
                tempindex
              ].planningCostElementId = this.CommitmentCostElementdetailId;
            } else {
              selectedNodes[0].data.objectRelationships.push({
                planningCostElementId: this.CommitmentCostElementdetailId,
              });
            }
          } else {
            tempindex = selectedNodes[0].data.objectRelationships.findIndex(
              (x) => x.planningCostElementId > 0
            );
            selectedNodes[0].data.estimateDetails = [tempcommitId];
            if (tempindex > 0) {
              selectedNodes[0].data.objectRelationships[
                tempindex
              ].planningCostElementId = this.CommitmentCostElementdetailId;
            } else {
              selectedNodes[0].data.objectRelationships.push({
                planningCostElementId: this.CommitmentCostElementdetailId,
              });
            }
          }
          // console.log(selectedNodes[0].data);
          this.gridChildrenOptions.api.redrawRows();
          this.gridChildrenOptions.api.refreshCells();
        }
      }
      //console.log(this.gridChildrenOptions.api.getSelectedNodes());
    },

    AddSubRecordCounter() {
      if (this.AddSubRecordCounter > 0) {
        if (
          this.getChildrenSelectedId == null &&
          this.tempChildData.length > 0
        ) {
          let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
          this.setChildrenObj(selectedNodes[0].data);
        }
        this.i = this.i - 1;
        this.AddNewRecord(true);
      }
    },

    AddRecordCounter() {
      if (this.AddRecordCounter > 0) {
        if (this.getChildrenSelectedId == null) {
          let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
          if (selectedNodes.length > 0) {
            this.setChildrenObj(selectedNodes[0].data);
          } /* else {
            this.setChildrenObj(0);
          } */
        }
        this.i = this.i - 1;
        this.AddNewRecord(false);
      }
    },

    SaveCounter() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.SaveCounter > 0) {
        let newRecord = [],
          emptyRowCounter = 0;
        this.invalidindex = Array.from(this.invalidindex);
        this.invalidindex = this.invalidindex.filter((x) => x !== undefined);
        this.gridChildrenOptions.api.refreshCells();
        this.gridChildrenOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            if (node.data.code != null) {
              newRecord.push(node.data);
            } else {
              newRecord = [];
              emptyRowCounter++;
              return false;
            }
          }
        });
        //  console.log(this.invalidindex);
        if (emptyRowCounter > 0) {
          this.gridChildrenOptions.api.redrawRows();
          this.gridChildrenOptions.api.refreshCells();
          newRecord = [];
          emptyRowCounter = 0;
          this.$vToastify.error("Code Value is empty or duplicate!");
        } else if (this.invalidindex.length > 0) {
          this.gridChildrenOptions.api.redrawRows();
          this.gridChildrenOptions.api.refreshCells();
          newRecord = [];
          emptyRowCounter = 0;
          this.$vToastify.error("Invalid Strucutre Detail Code(s)!");
        } else {
          this.mutateRecord({ returnedNewData: newRecord });
          this.saveData();
        }
      }
    },

    DeleteCounter() {
      if (this.DeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes =
                this.gridChildrenOptions.api.getSelectedNodes();
              if (selectedNodes.length < 2) {
                var selectedData =
                  this.gridChildrenOptions.api.getSelectedRows();

                let TotalNodes = this.tempChildData.filter((x) =>
                  x.path.includes(selectedData[0].id)
                );
                let deleteIds = [];
                let indices = this.tempChildData.reduce(
                  (r, v, i) =>
                    r.concat(v.path.includes(selectedData[0].id) ? i : []),
                  []
                );

                indices.forEach((x) => {
                  deleteIds.push(this.tempChildData[x].id);
                  this.tempChildData = this.tempChildData.filter(
                    (item) => item.id != x
                  );
                  // delete this.tempChildData[x];
                });
                indices = deleteIds.filter((x) => x > 0);
                if (indices.length > 0) {
                  this.DeleteCostElement({ Id: indices });
                }
                this.gridChildrenOptions.api.applyTransaction({
                  remove: TotalNodes,
                });
                TotalNodes = [];
                deleteIds = [];
              } else {
                let selectedData = selectedNodes.map((node) => node.data);
                if (selectedData.length > 0) {
                  let DataDelete = [],
                    deleteIds = [];
                  for (let i = 0; i < selectedData.length; i++) {
                    DataDelete.push(
                      this.tempChildData.filter((x) =>
                        x.path.includes(selectedData[i].id)
                      )
                    );
                  }
                  DataDelete.forEach((element) => {
                    element.forEach((data) => {
                      selectedData.push(data);
                    });
                  });

                  DataDelete = _.uniqBy(selectedData, "id");
                  let Deletevalues = DataDelete.map((x) => x.id);
                  Deletevalues.forEach((element) => {
                    deleteIds.push(
                      this.tempChildData.findIndex((x) => x.Id == element)
                    );
                  });

                  deleteIds.forEach((x) => {
                    this.tempChildData = this.tempChildData.filter(
                      (item) => item.id != x
                    );
                    // delete this.tempChildData[x];
                  });
                  let indices = Deletevalues.filter((x) => x > 0);
                  if (indices.length > 0) {
                    this.DeleteCostElement({ Id: indices });
                  }
                  this.gridChildrenOptions.api.applyTransaction({
                    remove: DataDelete,
                  });
                  deleteIds = null;
                  indices = null;
                  Deletevalues = null;
                  DataDelete = null;
                  selectedData = null;
                } else {
                  this.$vToastify.setSettings(this.NormalSettings);
                  this.$vToastify.error("Selected Row(s) are Protected!");
                }
              }
              if (
                this.gridChildrenOptions.api.getModel().rowsToDisplay.length ==
                0
              ) {
                this.rowElementData = [];
                this.tempChildData = [];
                this.setChildrenObj(0);
              }
            } else {
              this.$vToastify.setSettings(this.NormalSettings);
            }
          });
      }
    },

    isDelete() {
      if (this.isDelete > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.gridChildrenOptions.api.redrawRows();
        this.gridChildrenOptions.api.refreshCells();
        this.$vToastify.success("Row Deleted Successfully!");
      }
    },

    DataWatcher() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.DataWatcher > -1) {
        this.gridChildrenOptions.api.applyTransactionAsync({
          update: this.getChildTempData,
        });
        this.gridChildrenOptions.api.redrawRows();
        this.gridChildrenOptions.api.refreshCells();

        this.tempChildData = [];
        this.gridChildrenOptions.api.forEachNode((node) => {
          this.tempChildData.push(node.data);
        });

        this.gridChildrenOptions.api.clearFocusedCell();
        let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
        if (selectedNodes.length > 0) {
          this.gridChildrenOptions.api.selectIndex(
            selectedNodes[0].rowIndex,
            false,
            false
          );
          this.gridChildrenOptions.api.setFocusedCell(
            selectedNodes[0].rowIndex,
            "code"
          );
          this.setChildrenObj(selectedNodes[0].data);
        }
        this.invalidindex = [];
        this.$vToastify.success("Data successfully saved!");
      } else {
        this.tempChildData = [];
        this.rowChildrenData = [];
        this.gridChildrenOptions.api.redrawRows();
        this.gridChildrenOptions.api.refreshCells();
      }
    },
  },

  computed: {
    /**
     * Load CostElements Controller Gettters, states
     */
    ...mapGetters("costHeaderController", [
      "getParentSelectedObj",
      "getCostModelTypeData",
    ]),

    ...mapGetters("costElementController", [
      "getChildrenGridColumn",
      "getChildrenCurrentData",
      "getAllStructureDetailsvalue",
      "getCopiedRows",
      "getChildrenSelectedId",
      "getChildTempData",
      "getTempChildColDef",
      "getCostElementTypes",
    ]),

    /**costElementController */

    ...mapState({
      ColDef: (state) =>
        state.costElementController.currentChildrenGridColDefCountCounter,
      CommitmentColummCounter: (state) =>
        state.costElementController.cuurentChildrenGridCommimentCounter,
      LoadChildData: (state) =>
        state.costElementController.currentChildrenLoadRecordCounter,
      UndoCounter: (state) =>
        state.costElementController.currentChildrenUndoWatcher,
      RedoCounter: (state) =>
        state.costElementController.currentChildrenRedoWatcher,
      PasteRowCounter: (state) => state.costElementController.duplicateRecord,
      AddRecordCounter: (state) =>
        state.costElementController.currentChildrenAddRecordCounter,
      AddSubRecordCounter: (state) =>
        state.costElementController.currentChildrenSubRecordCounter,
      DeleteCounter: (state) =>
        state.costElementController.currentChildrenDeleteRecordCounter,
      isDelete: (state) => state.costElementController.isDelete,
      SaveCounter: (state) =>
        state.costElementController.currentChildrenSaveRecordCounter,
      DataWatcher: (state) => state.costElementController.datawatcher,
    }),
    ...mapState("costElement", ["currentChildrenGridColDefCountCounter"]),
    ...mapState("commitmentCostElementControl", [
      "CommitmentSaveCounter",
      "CommitmentCostElementcodeValue",
      "CommitmentCostElementdetailId",
    ]),

    ...mapGetters("activityControl", ["getCurrentActivities"]),
    ...mapGetters("commitmentCostElementControl", [
      "getCurrentCommitmentCostElement",
      "getCurrentBudgetCostElement",
      "getCurrentEstimateCostElement",
    ]),
    ...mapGetters("structureManager", ["getStructureIdvalue"]),

    ...mapState("activityControl", ["activitiescodeValue", "activitydetailId"]),

    /*  ...mapGetters("commitmentCostElementControl", [

    ]), */

    ...mapState("structredetailControl", [
      "codeValue",
      "structuredetailId",
      "filtertitle",
    ]),

    noRowsTemplate() {
      if (this.isElementdetail) {
        return this.loadingTemplate;
      } else {
        return `<span>No Rows to Show</span>`;
      }
    },
  },
  methods: {
    /**
     * Load CostElements Controller Actions, Mutation
     */

    ...mapActions("costElementController", [
      "LoadCostElements",
      "LoadGlobalStructureDetails",
      "DeleteCostElement",
      "saveData",
      "LoadStructures",
      "LoadExtraColumnValue",
      "LoadCostElementTypes",
    ]),
    ...mapMutations("costElementController", [
      "setChildrenObj",
      "setSelectedChildrenRowObject",
      "mutateChildrenUndostate",
      "mutateChildrenRedostate",
      "mutateRecord",
    ]),

    ...mapMutations("structureManager", ["setDetailData"]),
    ...mapActions("activityControl", ["loadActivitiesbyCostHeadersID"]),

    ...mapMutations("structredetailControl", [
      "toggleStructureFilterModal",
      "mutateTitile",
      "mutateStructureCode",
    ]),

    ...mapMutations("activityControl", [
      "toggleActivityFilterModal",
      "setActivityId",
      "mutateActivityLoadCounter",
    ]),

    ...mapMutations("commitmentCostElementControl", [
      "toggleCommitmentCostElementFilterModal",
      "mutateCommitmentCostElementTitle",
      "setCommitmentCostElementId",
    ]),

    ...mapActions("commitmentCostElementControl", [
      "loadCommitmentCostElementbyCostHeadersID",
      "loadBudgetCostElementbyCostHeadersID",
      "loadEstimateCostElementbyCostHeadersID",
    ]),

    SetFocus() {
      let tempRowIndex = 0;
      this.gridCostHeaderOptions.api.forEachNode((node) => {
        if (node.data.id < 0) {
          node.setSelected(true);
          tempRowIndex = node.rowIndex;
        }
      });
      this.gridCostHeaderOptions.api.ensureIndexVisible(tempRowIndex);
      this.gridCostHeaderOptions.api.setFocusedCell(tempRowIndex, "code");
      //this.gridCostHeaderOptions.api.selectIndex(tempRowIndex, false, false);

      this.gridCostHeaderOptions.api.refreshCells();
    },

    cellClicked(params) {
      let tempdata = [];
      params.node.allLeafChildren.forEach((row) => {
        tempdata.push(row.data);
      });
      this.setSelectedChildrenRowObject(tempdata);

      let selectedNodes = params.node;
      params.api.forEachNode((node) => {
        if (node.data.path[node.data.path.length - 1] === selectedNodes.key) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
      this.setChildrenObj(params.node.data);
      if (params.column.colId == "ag-Grid-AutoColumn") {
        params.api.forEachNode((node) => {
          if (node.data.path[node.data.path.length - 1] === selectedNodes.key) {
            let thisParent = node.parent;
            while (thisParent) {
              thisParent.setExpanded(true);
              thisParent = thisParent.parent;
            }
            node.setSelected(true);
            node.setExpanded(true);
          }
        });
      } else if (params.column.colId.match("sd_")) {
        const myArr = params.column.colId.split("_");

        let tempData = this.getAllStructureDetailsvalue.filter(
          (x) => x.structureId == parseInt(myArr[1])
        );
        this.setDetailData({ returnedData: tempData });
        this.mutateTitile(params.column.colDef.headerName);
        let struValue = params.column.colId;
        this.mutateStructureCode(selectedNodes.data[struValue]);
        this.toggleStructureFilterModal();
      } else if (params.column.colId.match("activities")) {
        if (selectedNodes.data.activities != null) {
          this.mutateActivityLoadCounter();
          this.setActivityId({ code: selectedNodes.data.activities });
        } else {
          this.setActivityId({ code: 0 });
        }
        this.mutateTitile(params.column.colDef.headerName);
        this.toggleActivityFilterModal();
      } else if (params.column.colId.match("cmit_")) {
        /*    if (selectedNodes.data.activities != null) {
          this.mutateActivityLoadCounter();
          this.setActivityId({ code: selectedNodes.data.activities });
        } else {
          this.setActivityId({ code: 0 });
        }
        this.mutateTitile(params.column.colDef.headerName);
        //this.toggleActivityFilterModal(); */
        let tempAPI = "";
        if (params.column.colDef.headerName == "Commitment") {
          tempAPI = "CommitmentCostElement";
        } else if (
          params.column.colDef.headerName == "Budget" ||
          params.column.colDef.headerName == "Estimate"
        ) {
          tempAPI = "PlanningCostElement";
        }
        const myArr = params.column.colId.split("_");
        this.toggleCommitmentCostElementFilterModal();
        //if(params)
        this.loadCommitmentCostElementbyCostHeadersID({
          costHeaderId: this.getParentSelectedObj.id,
          CostModelTypeId: myArr[1],
          APIName: tempAPI,
        });
        this.tempCommimentId = myArr[1];

        this.mutateCommitmentCostElementTitle(params.column.colDef.headerName);
        if (
          selectedNodes.data.commitmentDetails != undefined &&
          selectedNodes.data.commitmentDetails.length > 0
        ) {
          this.setCommitmentCostElementId({
            code: selectedNodes.data.commitmentDetails[0].id,
          });
        } else if (selectedNodes.data.budgetDetails.length > 0)
          this.setCommitmentCostElementId({
            code: selectedNodes.data.budgetDetails[0].id,
          });
      } else if (
        selectedNodes.data.estimateDetails != undefined &&
        selectedNodes.data.estimateDetails.length > 0
      ) {
        this.setCommitmentCostElementId({
          code: selectedNodes.data.estimateDetails[0].id,
        });
      }
      params.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    /*  isEmpty(value) {
      return (
        // null or undefined
        value == null ||
        value == undefined ||
        // has length and it's zero
        value.length === 0 ||
        // is an Object and has no keys
        (value.constructor === Object && Object.keys(value).length === 0)
      );
    }, */

    AddNewRecord(subRecord) {
      if (this.getChildrenSelectedId != null) {
        let tempDataIndex = 0;
        this.gridChildrenOptions.api.refreshCells();
        const focusedCell = this.gridChildrenOptions.api.getFocusedCell();
        const focusedIndex = focusedCell.rowIndex;
        const getParentId =
          this.gridChildrenOptions.api.getSelectedNodes(focusedIndex);
        var tempId = getParentId.findIndex(
          (x) => x.data.id == this.getChildrenSelectedId
        );
        let rowsToAdd = [];
        var temp = null;
        var params = {
          force: false,
          suppressFlash: true,
        };
        if (getParentId[tempId].data.id < 0) {
          this.i = getParentId[tempId].data.id;
        }
        if (getParentId[tempId].data.path.length > 1) {
          temp = getParentId[tempId].data.path.length;
          temp = getParentId[tempId].data.path.slice(0, temp - 1);
        }
        if (
          getParentId[tempId].allChildrenCount == null &&
          getParentId[tempId].uiLevel == 0
        ) {
          params.force = true;
        }
        this.i = this.i - this.tempChildData.length;
        rowsToAdd.push(1);
        rowsToAdd = rowsToAdd.reverse();
        let newRowData = [];
        rowsToAdd.map(() => {
          let row = {};

          if (subRecord) {
            row.parentId = getParentId[tempId].data.id;
          } else {
            row.parentId = getParentId[tempId].data.parentId;
          }
          if (row.parentId == 0) {
            row.parentId = null;
          }

          row.id = this.i - 1;
          if (row.path == undefined) {
            row.path = [];
          }
          if (row.parentId != null) {
            if (getParentId[0].data.id < 0) {
              if (subRecord) {
                if (temp != null) {
                  row.path = temp.concat([row.parentId], [row.id]);
                } else {
                  row.path = [row.parentId, row.id];
                }
              } else {
                row.path = temp.concat([row.id]); //[row.parentId, row.id];
              }
            } else {
              if (getParentId[0].data.path.length < 2) {
                row.path = [row.parentId, row.id];
              } else {
                if (subRecord) {
                  if (temp != null) {
                    row.path = temp.concat([row.parentId], [row.id]);
                  } else {
                    row.path = [row.parentId, row.id];
                  }
                  // row.path = temp.concat([row.parentId], [row.id]);
                } else {
                  row.path = temp.concat([row.id]);
                }
              }
            }
          } else {
            row.path = [row.id];
          }
          tempDataIndex = row.path[row.path.length - 1];
          row.CostHeaderID = this.parentObj.id;
          row.description = null;
          row.code = null;
          row.tempCostElementType = null;
          row.costElementTypeId = null;
          row.tempCostElementTypeIcon = null;
          row.description = null;
          row.isDisabled = false;
          row.quantity = 0;
          row.totalCost = 0;
          row.totalHours = 0;
          row.totalQuantity = 0;
          row.unitCost = 0;
          row.unitHours = 0;
          row.uom = 0;
          row.rowUpdated = true;
          row.objectRelationships = [];
          row.extraColumnValues = [];
          row.commitmentDetails = [];
          row.structureDetails = [];
          row.activitiyDetails = [];
          row.estimateDetails = [];
          row.budgetDetails = [];
          // row.tempCostElementTypeIcon = null;
          this.i = this.i - 2;
          newRowData.push(row);
          this.tempChildData.push(row);
        });
        this.gridChildrenOptions.api.applyTransaction({
          add: newRowData,
        });
        this.gridChildrenOptions.api.refreshCells(params);
        this.GridExapnd(newRowData[0].id, true);
        let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();

        this.setChildrenObj(selectedNodes[0].data);
      }
      if (this.gridChildrenOptions.api.getModel().rowsToDisplay.length == 0) {
        let rowsToAdd = [];
        rowsToAdd.push(1);
        rowsToAdd = rowsToAdd.reverse();
        let newRowData = [];
        rowsToAdd.map(() => {
          let row = {};
          row.rowUpdated = true;
          row.parentId = null;
          row.id = this.i - 1;
          row.path = [row.id];
          row.CostHeaderID = this.parentObj.id;
          row.description = null;
          row.tempCostElementTypeIcon = null;
          row.costElementTypeId = null;
          row.code = null;
          row.description = null;
          row.isDisabled = false;
          row.quantity = null;
          row.totalCost = null;
          row.totalHours = null;
          row.totalQuantity = null;
          row.unitCost = null;
          row.unitHours = null;
          row.uom = null;
          row.objectRelationships = [];
          row.extraColumnValues = [];
          row.structureDetails = [];
          row.activitiyDetails = [];
          row.estimateDetails = [];
          row.commitmentDetails = [];
          row.budgetDetails = [];
          row.tempCostElementTypeIcon = null;
          this.i = this.i - 2;
          newRowData.push(row);
          this.tempChildData.push(row);
        });
        this.gridChildrenOptions.api.applyTransactionAsync({
          add: newRowData,
        });
        this.gridChildrenOptions.api.refreshCells();
        this.setChildrenObj(selectedNodes[0].data);
        this.gridChildrenOptions.api.selectIndex(0, false, false);
        this.gridChildrenOptions.api.setFocusedCell(0, "code");
      }
    },

    GridExapnd(subRecord, expand) {
      this.gridChildrenOptions.api.clearFocusedCell();
      let tempRowIndex = 0;
      this.gridChildrenOptions.api.forEachNode((node) => {
        if (node.data.id == subRecord) {
          let thisParent = node.parent;
          while (thisParent && expand) {
            thisParent.setExpanded(true);
            thisParent = thisParent.parent;
          }
          node.setSelected(true);
          node.setExpanded(expand);
          tempRowIndex = node.rowIndex;
        } else {
          node.setSelected(false);
        }
      });
      this.gridChildrenOptions.api.ensureIndexVisible(tempRowIndex);
      this.gridChildrenOptions.api.setFocusedCell(tempRowIndex, "code");
      this.gridChildrenOptions.api.refreshCells();
    },

    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();
      if (params.node.data) {
        let selectedNodes = params.node.data;
        if (selectedNodes.length > 0) {
          this.setChildrenObj(params.node.data);
        }
      }
      //console.log(params.column.colId);
      if (params.column.colId.match("sd_")) {
        if (params.newValue.length > 0) {
          const myArr = params.column.colId.split("_");
          let tempIndex = params.node.data.structureDetails.findIndex(
            (y) => y.structureId == parseInt(myArr[1])
          );
          let tempstructuredetailId = this.getAllStructureDetailsvalue.find(
            (x) =>
              x.structureId == parseInt(myArr[1]) && x.code == params.newValue
          );
          if (tempstructuredetailId != undefined) {
            let tempIndexs = this.invalidindex.findIndex(
              (x) => x == params.node.data.id
            );
            if (tempIndexs == -1) {
              delete this.invalidindex[tempIndexs];
            }
            if (tempIndex < 0) {
              params.node.data.objectRelationships.push({
                structureDetailId: tempstructuredetailId.id,
              });
            } else {
              params.node.data.objectRelationships[
                tempIndex
              ].structureDetailId = tempstructuredetailId.id;
            }
            params.node.data.structureDetails[tempIndex] =
              tempstructuredetailId;
          } else {
            params.node.data[params.column.colId] =
              " " + params.node.data[params.column.colId];
            this.invalidindex.push(params.node.data.id);
            this.$vToastify.error("Structuredetail Codes are not Valid!");
            console.error("Structuredetail Codes are not Valid!");
          }
        }
      } else if (
        params.column.colId == "unitCost" ||
        params.column.colId == "quantity"
      ) {
        this.recursion(params.node.parent);
        let answer = params.node.allLeafChildren.filter(
          (x) => x.data.parentId == params.data.id
        );
        for (let a = 0; a < answer.length; a++) {
          answer[a].data.rowUpdated = true;
        }
      } else if (params.column.colId.match("et_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;

        if (this.componentId == 19) {
          tempIndex = params.node.data.extraColumnValues.findIndex(
            (y) => y.ActualCostElementExtraColumnId == parseInt(colvalue[1])
          );
          if (tempIndex < 0) {
            params.node.data.extraColumnValues.push({
              ActualCostElementId: params.node.data.id,
              ActualCostElementExtraColumnId: colvalue[1],
              value: params.newValue,
            });
          } else {
            params.node.data.extraColumnValues[tempIndex].value =
              params.newValue;
          }
        } else if (this.componentId == 20) {
          tempIndex = params.node.data.extraColumnValues.findIndex(
            (y) => y.CommitmentCostElementExtraColumnId == parseInt(colvalue[1])
          );
          if (tempIndex < 0) {
            params.node.data.extraColumnValues.push({
              CommitmentCostElementId: params.node.data.id,
              CommitmentCostElementExtraColumnId: colvalue[1],
              value: params.newValue,
            });
          } else {
            params.node.data.extraColumnValues[tempIndex].value =
              params.newValue;
          }
        } else if (this.componentId == 21) {
          tempIndex = params.node.data.extraColumnValues.findIndex(
            (y) => y.ForecastCostElementExtraColumnId == parseInt(colvalue[1])
          );
          if (tempIndex < 0) {
            params.node.data.extraColumnValues.push({
              ForecastCostElementId: params.node.data.id,
              ForecastCostElementExtraColumnId: colvalue[1],
              value: params.newValue,
            });
          } else {
            params.node.data.extraColumnValues[tempIndex].value =
              params.newValue;
          }
        } else if (this.componentId == 22) {
          tempIndex = params.node.data.extraColumnValues.findIndex(
            (y) => y.PlanningCostElementExtraColumnId == parseInt(colvalue[1])
          );
          if (tempIndex < 0) {
            params.node.data.extraColumnValues.push({
              PlanningCostElementId: params.node.data.id,
              PlanningCostElementExtraColumnId: colvalue[1],
              value: params.newValue,
            });
          } else {
            params.node.data.extraColumnValues[tempIndex].value =
              params.newValue;
          }
        }
      } else if (params.column.colId.match("activities")) {
        let tempIndex = -1;
        if (params.newValue.length > 0) {
          let tempactivitiesId = this.getCurrentActivities.find(
            (x) => x.code == params.newValue
          );

          if (params.node.data.objectRelationships == undefined) {
            params.node.data.objectRelationships = [];
            // params.node.data.activities = null;
          }
          tempIndex = params.node.data.objectRelationships.findIndex(
            (x) => x.activityId > 0
          );

          if (
            tempIndex.length > 0 ||
            tempIndex == undefined ||
            tempIndex == -1
          ) {
            params.node.data[params.column.colId] == params.newValue;
            params.node.data.objectRelationships.push({
              activityId: tempactivitiesId.id,
            });
          } else {
            // console.log(params.node.data.objectRelationships, tempIndex);
            params.node.data[params.column.colId] == params.newValue;
            params.node.data.objectRelationships[tempIndex].activityId =
              tempactivitiesId.id;
          }
        }

        //  console.log(typeof params.newValue);
      } else if (params.column.colId.match("cmit_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;
        /*  console.log(
          this.getCurrentCommitmentCostElement,
          this.getCurrentBudgetCostElement,
          this.getCurrentEstimateCostElement
        ); */

        if (params.column.colDef.headerName == "Commitment") {
          if (params.newValue.length > 0) {
            let tempCommitmentId = this.getCurrentCommitmentCostElement.find(
              (x) => x.code == params.newValue
            );

            if (params.node.data.objectRelationships == undefined) {
              params.node.data.objectRelationships = [];
              // params.node.data.activities = null;
            }
            tempIndex = params.node.data.objectRelationships.findIndex(
              (x) => x.commitmentCostElementId > 0
            );

            if (
              tempIndex.length > 0 ||
              tempIndex == undefined ||
              tempIndex == -1
            ) {
              params.node.data[params.column.colId] == params.newValue;
              params.node.data.objectRelationships.push({
                commitmentCostElementId: tempCommitmentId.id,
              });
            } else {
              //console.log(params.node.data.objectRelationships, tempIndex);
              params.node.data[params.column.colId] == params.newValue;
              params.node.data.objectRelationships[
                tempIndex
              ].commitmentCostElementId = tempCommitmentId.id;
            }
          }
        }
        if (params.column.colDef.headerName == "Budget") {
          if (params.newValue.length > 0) {
            let tempCommitmentId = this.getCurrentBudgetCostElement.find(
              (x) => x.code == params.newValue
            );

            if (params.node.data.objectRelationships == undefined) {
              params.node.data.objectRelationships = [];
              // params.node.data.activities = null;
            }
            tempIndex = params.node.data.objectRelationships.findIndex(
              (x) => x.planningCostElementId > 0
            );

            if (
              tempIndex.length > 0 ||
              tempIndex == undefined ||
              tempIndex == -1
            ) {
              params.node.data[params.column.colId] == params.newValue;
              params.node.data.objectRelationships.push({
                planningCostElementId: tempCommitmentId.id,
              });
            } else {
              //  console.log(params.node.data.objectRelationships, tempIndex);
              params.node.data[params.column.colId] == params.newValue;
              params.node.data.objectRelationships[
                tempIndex
              ].planningCostElementId = tempCommitmentId.id;
            }
          }
        }
        if (params.column.colDef.headerName == "Estimate") {
          if (params.newValue.length > 0) {
            let tempCommitmentId = this.getCurrentEstimateCostElement.find(
              (x) => x.code == params.newValue
            );

            if (params.node.data.objectRelationships == undefined) {
              params.node.data.objectRelationships = [];
              // params.node.data.activities = null;
            }
            tempIndex = params.node.data.objectRelationships.findIndex(
              (x) => x.planningCostElementId > 0
            );

            if (
              tempIndex.length > 0 ||
              tempIndex == undefined ||
              tempIndex == -1
            ) {
              params.node.data[params.column.colId] == params.newValue;
              params.node.data.objectRelationships.push({
                planningCostElementId: tempCommitmentId.id,
              });
            } else {
              //  console.log(params.node.data.objectRelationships, tempIndex);
              params.node.data[params.column.colId] == params.newValue;
              params.node.data.objectRelationships[
                tempIndex
              ].planningCostElementId = tempCommitmentId.id;
            }
          }
        }
      } else if (params.column.colId.match("tempCostElementType")) {
        // console.log(params.newValue);
        let tempData = this.getCostElementTypes.find(
          (y) => y.description == params.newValue
        );
        if (typeof tempData == "object") {
          params.node.data.costElementTypeId = tempData.id;
          params.node.data.tempCostElementType = params.newValue;
          params.node.data.tempCostElementTypeIcon = tempData.icon;
        } else {
          params.node.data.costElementTypeId = null;
          params.node.data.tempCostElementType = null;
          params.node.data.tempCostElementTypeIcon = null;
        }
        //console.log(params.node.data.costElementTypeId);
      }

      params.node.data.rowUpdated = true;
      // params.api.refreshCells({ force: true, suppressFlash: true });
      if (undoSize == 0) {
        this.mutateChildrenUndostate(false);
        params.node.data.rowUpdated = false;
      } else {
        this.mutateChildrenUndostate(true);
      }

      if (redoSize == 0) {
        this.mutateChildrenRedostate(false);
      } else {
        this.mutateChildrenRedostate(true);
        params.node.data.rowUpdated = false;
      }
      params.api.refreshCells({ force: true, suppressFlash: true });
    },

    onRowDragMove(event) {
      setPotentialParentForNode(event.api, event.overNode);
    },

    onRowDragLeave(event) {
      setPotentialParentForNode(event.api, null);
    },

    onRowDragEnd(event) {
      this.$vToastify.setSettings(this.NormalSettings);
      if (!potentialParent) {
        return;
      }
      var movingData = event.node.data;
      var newParentPath = potentialParent.data ? potentialParent.data.path : [];
      if (potentialParent.data.id > 0) {
        var needToChangeParent = !arePathsEqual(newParentPath, movingData.path);
        var invalidMode = isSelectionParentOfTarget(
          event.node,
          potentialParent
        );
        if (invalidMode) {
          this.$vToastify.error("invalid move!");
          console.error("invalid move");
        }
        if (needToChangeParent && !invalidMode) {
          var updatedRows = [];
          moveToPath(newParentPath, event.node, updatedRows);

          updatedRows.forEach((element) => {
            element.rowUpdated = true;
          });
          if (updatedRows[0].path.length > 1) {
            updatedRows[0].parentId =
              updatedRows[0].path[updatedRows[0].path.length - 2];
          } else {
            updatedRows[0].parentId = null;
          }
          this.gridChildrenOptions.api.applyTransactionAsync({
            update: updatedRows,
          });

          this.gridChildrenOptions.api.clearFocusedCell();
        }
      } else {
        this.$vToastify.error("invalid move!");
        console.error("invalid move");
      }
      setPotentialParentForNode(event.api, null);
    },

    recursion(params) {
      if (params.data != undefined) {
        params.data.rowUpdated = true;
        if (params.parent.data != undefined) this.recursion(params.parent);
      }
    },

    insertArrayAt(array, index, arrayToInsert) {
      Array.prototype.splice.apply(array, [index, 0].join(arrayToInsert));
      return array;
    },

    onGridReady(params) {
      if (this.componentId > 0) {
        getColumnDef(this.componentId)
          .then((moduleBlob) => {
            // get blob
            // create script element
            // @onload use loaded global function from script
            let container = this.$refs.scriptContainerCostElement;
            var moduleUrl = URL.createObjectURL(moduleBlob);
            let scriptEl = document.createElement("script");
            scriptEl.type = "text/javascript";
            // NOTE: need eslint disable line because of dynamic function
            scriptEl.onload = () => {
              __hydrateColumns(this, utilitiesModule);
            }; // eslint-disable-line
            scriptEl.src = moduleUrl;
            container.appendChild(scriptEl);
            URL.revokeObjectURL(moduleUrl);
          })
          .then(() => {
            // load road async <can assume rows will already be set in backend>
            // NOTE: we can load month columns and row columns at same time
            this.LoadStructures({
              CostModelRowObject: this.getCostModelTypeData,
              CostHeaderRowObject: this.parentObj.costModelType.rowType.code,
            });
            /* this.LoadExtraColumnValue(
              this.parentObj.costModelType.rowType.code
            ); */
            this.LoadCostElements({
              CostElements: this.parentObj,
            });
          })
          .catch((error) => {
            console.error("alert/error", error, { root: true });
          });
      }
    },

    reloadColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, this.componentId)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridChildrenOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnChanged: debounce(function () {
      this.onColumnStateSubmit();
    }, 300),

    onColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridChildrenOptions.columnApi.getColumnState();

      postColumnState({
        componentId: this.componentId,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },
  },

  beforeMount() {
    /*Set Grid Tree Collumn */
    this.components = { costElementCellRenderer: getCostElementCellRenderer() };
    this.defaultColDef = Object.assign({}, this.defaultColDef, {
      width: 180,
    });
    this.autoGroupColumnDef = {
      headerName: "",
      rowDrag: true,
      suppressPaste: true,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: "costElementCellRenderer",
      },
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      width: 160,
    };
    this.getDataPath = (data) => {
      return data.path;
    };

    /**
     * Load Column Defination
     * Load All CostElement's Data
     * Set Basic Functions Here
     */

    /* Load All basic Functions */

    if (this.getAllStructureDetailsvalue.length == 0) {
      this.LoadGlobalStructureDetails();
    }
    var localStorageObject = localStorage.getItem("CostElement");
    //console.log(this.getParentSelectedObj, localStorageObject);
    this.getParentSelectedObj == null
      ? (this.parentObj = JSON.parse(localStorageObject))
      : (this.parentObj = this.getParentSelectedObj);

    if (this.parentObj.id > 0) {
      var name = this.parentObj.costModelType.rowType.code;
      if (name == "ActualCostElement") {
        this.componentId = 19;
      } else if (name == "CommitmentCostElement") {
        this.componentId = 20;
      } else if (name == "ForecastCostElement") {
        this.componentId = 21;
      } else if (name == "PlanningCostElement") {
        this.componentId = 22;
      }
      this.loadActivitiesbyCostHeadersID({
        costHeaderId: this.parentObj.id,
      });
      //console.log(this.parentObj);
      /*  this.columnDefs = this.getChildrenGridColumn;
      this.LoadCostElements({
        CostElements: this.parentObj,
        CostModelRowObject: this.getCostModelTypeData,
      });

      this.loadActivitiesbyCostHeadersID({
        costHeaderId: this.parentObj.id,
      }); */

      // console.log(this.parentObj.costModelType.rowType.id);
      this.columnDefs = [];
      this.LoadCostElementTypes({
        rowTypeId: this.parentObj.costModelType.rowType.id,
      });
      this.loadCommitmentCostElementbyCostHeadersID({
        costHeaderId: this.getParentSelectedObj.id,
        CostModelTypeId: 4,
        APIName: "CommitmentCostElement",
      });

      this.loadBudgetCostElementbyCostHeadersID({
        costHeaderId: this.getParentSelectedObj.id,
        CostModelTypeId: 2,
        APIName: "PlanningCostElement",
      });

      this.loadEstimateCostElementbyCostHeadersID({
        costHeaderId: this.getParentSelectedObj.id,
        CostModelTypeId: 1,
        APIName: "PlanningCostElement",
      });
    } else {
      this.$router.push("/data/costs");
    }
  },

  mounted() {},
};

window.moveToPath = function moveToPath(newParentPath, node, allUpdatedNodes) {
  var oldPath = node.data.path;
  var fileName = oldPath[oldPath.length - 1];
  var newChildPath = newParentPath.slice();
  newChildPath.push(fileName);
  node.data.path = newChildPath;
  allUpdatedNodes.push(node.data);
  if (node.childrenAfterGroup) {
    node.childrenAfterGroup.forEach(function (childNode) {
      moveToPath(newChildPath, childNode, allUpdatedNodes);
    });
  }
};

window.isSelectionParentOfTarget = function isSelectionParentOfTarget(
  selectedNode,
  targetNode
) {
  var children = selectedNode.childrenAfterGroup;
  for (var i = 0; i < children.length; i++) {
    if (targetNode && children[i].key === targetNode.key) return true;
    isSelectionParentOfTarget(children[i], targetNode);
  }
  return false;
};

window.arePathsEqual = function arePathsEqual(path1, path2) {
  //console.log(path2, path2, path1);
  if (path1.length !== path2.length) {
    return false;
  }
  var equal = true;
  path1.forEach(function (item, index) {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
};

window.setPotentialParentForNode = function setPotentialParentForNode(
  api,
  overNode
) {
  var newPotentialParent;
  if (overNode) {
    newPotentialParent = overNode;
  } else {
    newPotentialParent = null;
  }
  var alreadySelected = potentialParent === newPotentialParent;
  if (alreadySelected) {
    return;
  }
  var rowsToRefresh = [];
  if (potentialParent) {
    rowsToRefresh.push(potentialParent);
  }
  if (newPotentialParent) {
    rowsToRefresh.push(newPotentialParent);
  }
  potentialParent = newPotentialParent;
  refreshRows(api, rowsToRefresh);
};

window.refreshRows = function refreshRows(api, rowsToRefresh) {
  var params = {
    rowNodes: rowsToRefresh,
    force: true,
  };
  api.refreshCells(params);
};

window.getCostElementCellRenderer = function getCostElementCellRenderer() {
  function CostElementCellRenderer() {}
  CostElementCellRenderer.prototype.init = function (params) {
    var tempDiv = document.createElement("div");
    // console.log(params.node.data);
    //params.api.refreshCells();
    tempDiv.innerHTML =
      params.node.allChildrenCount == null && params.data.parentId == null
        ? '<img src="Icons/' +
          params.node.data.tempCostElementTypeIcon +
          '.svg"; style="margin-top:5px; margin-left:28px; height:20px; width:20px;"/>'
        : '<img src="Icons/' +
          params.node.data.tempCostElementTypeIcon +
          '.svg"; style="margin-top:5px; height:20px; width:20px;"/>';
    /*   : params.node.data.costElementTypeId == 2
        ? params.node.allChildrenCount == null && params.data.parentId == null
          ? '<img src="Icons/materials2.svg"; style="margin-top:5px; margin-left:28px; height:20px; width:20px;"/>'
          : '<img src="Icons/materials2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
        : params.node.data.costElementTypeId == 5
        ? params.node.allChildrenCount == null && params.data.parentId == null
          ? '<img src="Icons/wbs2.svg"; style="margin-top:5px; margin-left:28px; height:20px; width:20px;"/>'
          : '<img src="Icons/wbs2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
        : params.node.data.costElementTypeId == 10
        ? params.node.allChildrenCount == null && params.data.parentId == null
          ? '<img src="Icons/commitment2.svg";style="margin-top:5px; margin-left:28px; height:20px; width:20px;"/>'
          : '<img src="Icons/commitment2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
        : params.node.data.costElementTypeId == 15
        ? params.node.allChildrenCount == null && params.data.parentId == null
          ? '<img src="Icons/paid2.svg"; style="margin-top:5px; margin-left:28px; height:20px; width:20px;"/>'
          : '<img src="Icons/paid2.svg"; style="margin-top:5px; height:20px; width:20px;"/>' */
    //console.log(params.node.data.costElementTypeId);
    this.eGui = tempDiv.firstChild;
  };
  CostElementCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return CostElementCellRenderer;
};

window.SetCostModelTypes = function SetCostModelTypes(data) {
  CostModelTypes = data;
};

window.SetCostElementTypes = function SetCostElementTypes(data) {
  CostElementTypes = data;
};

window.getCostModelCellRenderer = function getCostModelCellRenderer() {
  function CustomCombobox() {}
  CustomCombobox.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var CostModelTypeFilter = CostModelTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );
    CostModelTypeFilter.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  CustomCombobox.prototype.getGui = function () {
    return this.eGui;
  };

  CustomCombobox.prototype.getGui = function () {
    return this.eInput;
  };
  CustomCombobox.prototype.afterGuiAttached = function () {
    this.eInput.focus();
  };
  CustomCombobox.prototype.getValue = function () {
    return this.eInput.value;
  };
  CustomCombobox.prototype.destroy = function () {};
  CustomCombobox.prototype.isPopup = function () {
    return false;
  };
  return CustomCombobox;
};

var potentialParent = null;
var CostModelTypes = null;
var CostElementTypes = null;

window.getDatePicker = function getDatePicker() {
  function Datepicker() {}
  var tempDatevalue = null;
  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "date");
    if (typeof params.value == "object" || typeof params.value == "undefined") {
      // console.log(typeof params.value);
      if (params.value != null) {
        this.eInput.value = moment(params.value).toISOString().substr(0, 10);
      }
    }
    tempDatevalue = params.value; // moment(this.getSelectedRowObject.endDate).toISOString().substr(0, 10);

    this.eInput.classList.add("input");
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
    /*  $(this.eInput).datepicker({
      constrainInput: true, // prevent letters in the input field
      autoSize: true, // automatically resize the input field
      dateFormat: "dd/mm/yyyy",
      changeMonth: true,
      changeYear: true,
      yearRange: "1930:" + new Date().getFullYear(),
    }); */
  };
  Datepicker.prototype.getGui = function () {
    return this.eInput;
  };
  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Datepicker.prototype.getValue = function () {
    if (this.eInput.value.length > 0) {
      return moment(this.eInput.value).format("YYYY-MM-DD");
    } else {
      return tempDatevalue;
    }
  };
  Datepicker.prototype.destroy = function () {};
  Datepicker.prototype.isPopup = function () {
    return false;
  };
  return Datepicker;
};

window.getIntegerCellEditor = function getIntegerCellEditor() {
  function IntegerPicker() {}
  IntegerPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.setAttribute("step", "1");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };

  /*  IntegerPicker.prototype.isKeyPressedNavigation = function(event) {
    return event.keyCode !=110;
  }; */
  IntegerPicker.prototype.getGui = function () {
    return this.eInput;
  };
  IntegerPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  IntegerPicker.prototype.getValue = function () {
    return parseInt(this.eInput.value);
  };
  IntegerPicker.prototype.destroy = function () {};
  IntegerPicker.prototype.isPopup = function () {
    return false;
  };
  return IntegerPicker;
};

window.getDecimalCellEditor = function getDecimalCellEditor() {
  function DecimalPicker() {}
  DecimalPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };
  DecimalPicker.prototype.getGui = function () {
    return this.eInput;
  };
  DecimalPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  DecimalPicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  DecimalPicker.prototype.destroy = function () {};
  DecimalPicker.prototype.isPopup = function () {
    return false;
  };
  return DecimalPicker;
};

window.getCostElementTypeCombobox = function getCostElementTypeCombobox() {
  function CostElementTypeCombobox() {}
  CostElementTypeCombobox.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var CostElementTypeFilter = CostElementTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );
    CostElementTypeFilter.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  CostElementTypeCombobox.prototype.getGui = function () {
    return this.eGui;
  };
  CostElementTypeCombobox.prototype.getGui = function () {
    return this.eInput;
  };
  CostElementTypeCombobox.prototype.afterGuiAttached = function () {
    this.eInput.focus();
  };
  CostElementTypeCombobox.prototype.getValue = function () {
    return this.eInput.value;
  };
  CostElementTypeCombobox.prototype.destroy = function () {};
  CostElementTypeCombobox.prototype.isPopup = function () {
    return false;
  };
  return CostElementTypeCombobox;
};

window.getLookupCellEditor = function getLookupCellEditor() {
  function Lookuppicker() {}
  Lookuppicker.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var CostModelTypeFilter = CostModelTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );
    CostModelTypeFilter.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  Lookuppicker.prototype.getGui = function () {
    return this.eInput;
  };
  Lookuppicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Lookuppicker.prototype.getValue = function () {
    //console.log(params.newValue);
    return this.eInput.value;
  };
  Lookuppicker.prototype.destroy = function () {};
  Lookuppicker.prototype.isPopup = function () {
    return false;
  };
  return Lookuppicker;
};
</script>

<style scoped lang="scss">
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font: normal 11px/22px "Open Sans", sans-serif;
  color: black;
  border: 1px solid #ccc;
}
.container-grid {
  margin-top: 0.1%;
  background-color: #fff;
  color: #444;
  /* Use flexbox */
  display: flex;
}

.line {
  width: 99.5%;
  height: 1%;
  border-bottom: 1px solid black;
  position: relative;
}
.box {
  margin-top: 0.1%;
  color: #fff;
  border-radius: 1px;
  padding: 15px;
  font-size: 150%;

  /* Use box-sizing so that element's outerwidth will match width property */
  box-sizing: border-box;

  /* Allow box to grow and shrink, and ensure they are all equally sized */
  flex: 1 1 auto;
}

.handler {
  margin-top: 0.8%;
  position: relative;
  width: 1px;
  padding: 0;
  cursor: col-resize;
  flex: 0 0 auto;
}

.handler::before {
  margin-top: 0.8%;
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background: silver;
  margin: 0 auto;
}
</style>
