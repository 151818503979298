import { userService } from "@/api/user-auth";
import router from "../router";

const user = JSON.parse(localStorage.getItem("user"));
const state = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

const actions = {
  login({ dispatch, commit }, { username, password }) {
    commit("loginIn", user);
    userService
      .login(username, password)
      .then(
        (user) => {
          if (user.isVerified) {
            commit("loginSuccess", user);
            commit("filterControl/resetFilterCounter", null, { root: true });
            //router.push("/");
          } else {
            commit("loginFailure", user);
            dispatch("alert/error", user.message, { root: true });
          }
        },
        (error) => {
          commit("loginFailure", error);
          dispatch("alert/error", error, { root: true });
        }
      )
      .then(
        // TODO: upon login find default `client` and load filters for it
        () => {
          dispatch("filterControl/updateFilters", null, { root: true });
          //Load the menu and route to the first option
          dispatch("jiveComponents/loadJiveComponents", null, { root: true });
        }
      );
  },
  logout({ dispatch, commit }) {
    dispatch("jiveComponents/logout", null, { root: true });
    userService.logout();
    commit("logout");
  },
  register({ dispatch, commit }, user) {
    commit("registerRequest", user);

    userService.register(user).then(
      (user) => {
        commit("registerSuccess", user);
        router.push("/login");
        setTimeout(() => {
          // display success message after route change completes
          dispatch("alert/success", "Registration successful", { root: true });
        });
      },
      (error) => {
        commit("registerFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  reloadGlobalState({ dispatch }) {
    // TODO: deal with multiple un-neccessary calls to loading global data
    dispatch("filterControl/updateFilters", null, { root: true });
    dispatch("userView/updateViewList", 1, { root: true });
  },
};

const mutations = {
  loginIn(state) {
    state.status = { loggingIn: true };
    state.user = null;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
  // eslint-disable-next-line no-unused-vars
  registerRequest(state, user) {
    state.status = { registering: true };
  },
  // eslint-disable-next-line no-unused-vars
  registerSuccess(state, user) {
    state.status = {};
  },
  // eslint-disable-next-line no-unused-vars
  registerFailure(state, error) {
    state.status = {};
  },
};

const getters = {
  loggedIn({ status, user }) {
    //return user && user.ok && status && status.loggedIn;isVerified
    return user && user.isVerified && status && status.loggedIn;
  },
  clientId({ user }) {
    var idToReturn = user && user.isVerified ? user.clientId : 0;
    return idToReturn;
  },
  username({ user }, { loggedIn }) {
    //return loggedIn ? user.name : "Please Register";
    return loggedIn ? user.userName : "Please Register";
  },
  userid({ user }, { loggedIn }) {
    //return loggedIn ? user.name : "Please Register";
    return loggedIn ? user.id : 0;
  },
  clientLogo({ status, user }) {
    return user.Client.ClientLogo;
  },
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
