<template>
  <div
    style="height: 100%; background-color: white"
    ref="scriptContainerCostHeader"
  >
    <div class="line" style="margin-left: 0.2%"></div>
    <div class="columns" style="height: 100%; width: 100%">
      <div
        class="column"
        style="margin-left: 1%; margin-right: 1%; height: 100%; width: 100%"
      >
        <CostHeaderBar />
        <ag-grid-vue
          class="ag-theme-balham ag-default-layout"
          rowSelection="multiple"
          enableCellChangeFlash="true"
          stopEditingWhenGridLosesFocus="false"
          :columnDefs="columnDefs"
          :rowData="rowParentData"
          :suppressRowClickSelection="false"
          :enableRangeSelection="true"
          :undoRedoCellEditing="true"
          :treeData="true"
          :getDataPath="getDataPath"
          :defaultColDef="defaultColDef"
          :detailCellRendererParams="detailCellRendererParams"
          :autoGroupColumnDef="autoGroupColumnDef"
          :components="parentComponents"
          :gridOptions="gridParentOptions"
          :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
          @cell-value-changed="onCellValueChanged"
          @selection-changed="onSelectionChanged"
          @grid-ready="onGridReady"
          @cell-clicked="onCellClicked"
          @sort-changed="onColumnChanged"
          @column-resized="onColumnChanged"
          @column-visible="onColumnChanged"
          @column-row-group-changed="onColumnChanged"
          @column-value-changed="onColumnChanged"
          @column-moved="onColumnChanged"
          @column-pinned="onColumnChanged"
        >
        </ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import CostHeaderBar from "./CostHeaderToolbar.vue";
import LevelIconCellRenderer from "@/components/Data/LevelIconCellRenderer.vue";
import * as moment from "moment";
import * as utilitiesModule from "@/assets/utilities.js";
import { debounce } from "lodash";
import {
  getColumnDef,
  postColumnState,
  getColumnState,
} from "@/api/networking.js";
export default {
  name: "CostHeaderManager",
  components: {
    AgGridVue,
    CostHeaderBar,
    LevelIconCellRenderer, // eslint-disable-line
  },
  data() {
    return {
      rowParentData: null,
      columnDefs: null,
      undoRedoCellEditingLimit: null,
      defaultColDef: null,
      i: -1,
      autoGroupColumnDef: null,
      gridParentOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
        enableRangeSelection: true,
        getRowNodeId: (data) => data.uid,
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
        /*   processDataFromClipboard: params => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          const lastIndex =
            this.gridParentOptions.api.getModel().rowsToDisplay.length - 1;
          const focusedCell = this.gridParentOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;
          if (focusedIndex + params.data.length - 1 > lastIndex) {
            const resultLastIndex = focusedIndex + (params.data.length - 1);
            const addRowCount = resultLastIndex - lastIndex;
            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }
            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];
            rowsToAdd.map(r => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map(i => {
                row[currColumn.colDef.field] = i;
                currColumn = this.gridParentOptions.columnApi.getDisplayedColAfter(
                  currColumn
                );
              });
              row.extraColumnValues = [];
              row.rowUpdated = true;
              row.id = -1;
              newRowData.push(row);
              this.rowParentData.push(row);
            });

            this.gridParentOptions.api.applyTransactionAsync({
              add: newRowData,
            });
            this.gridParentOptions.api.redrawRows();
          }
          this.gridParentOptions.api.refreshCells();
          return params.data;
        }, */
      },
      detailCellRendererParams: {
        refreshStrategy: "everything",
      },
      parentComponents: {
        CustomCombobox: getCostModelCellRenderer(),
        datePicker: getDatePicker(),
        integerCellEditor: getIntegerCellEditor(),
        decimalCellEditor: getDecimalCellEditor(),
        lookupCellEditor: getLookupCellEditor(),
        iconCostCellRenderer: getIconCostCellRenderer(),
      },
      singleClickEdit: false,
      SelectId: null,
      loadingTemplate: `<span class="ag-overlay-loading-center">Data is loading...</span>`,
      deleteSettings: {
        withBackdrop: true,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "center-center",
      },
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
      tempRowId: -1,
    };
  },
  watch: {
    /* Load Parent Grid Column Defs*/
    ParentExtaColumn() {
      // this.columnDefs = this.getParentGridColumn;
      this.getParentGridColumn.forEach((row) => {
        this.columnDefs.push(row);
      });
      this.reloadColumnState();
    },

    ParentRowdata() {
      this.rowParentData = this.getParentCurrentData;
      //console.log(this.getParentCurrentData.length, this.rowParentData.length);
      SetCostModelTypes(this.getCostModelTypeData);
    },

    UndoCounter() {
      this.gridParentOptions.api.undoCellEditing();
    },
    RedoCounter() {
      this.gridParentOptions.api.redoCellEditing();
    },

    AddCounter() {
      // setTimeout(() => {
      this.SetFocus();
      //}, 10);
    },

    isDelete() {
      if (this.isDelete > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.gridParentOptions.api.redrawRows();
        this.gridParentOptions.api.refreshCells();
        this.$vToastify.success("Row Deleted Successfully!");
      }
    },

    DeleteCounter() {
      if (this.DeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes = this.gridParentOptions.api.getSelectedNodes();
              let selectedData = selectedNodes.map((node) => node.data);
              let deleteIds = [];
              let indices = [];
              selectedData.forEach((x) => {
                deleteIds.push(x.id);
              });
              indices = deleteIds.filter((x) => x > 0);
              if (indices.length > 0) {
                this.DeleteParentNode({ costHeaderId: indices });
              }
              this.gridParentOptions.api.applyTransaction({
                remove: selectedData,
              });
              indices = [];
              selectedNodes = [];
              deleteIds = [];
              selectedData = [];
            }
          });
      }
    },

    DataWatcher() {
      this.$vToastify.setSettings(this.NormalSettings);

      let tempdata = [];
      if (this.DataWatcher > 0) {
        this.gridParentOptions.api.forEachNode((node) => {
          if (node.data != undefined) {
            if (node.data.rowUpdated) {
              node.data.rowUpdated = false;
              tempdata.push(node.data);
            }
          }
        });
        this.gridParentOptions.api.applyTransactionAsync({
          remove: tempdata,
        });
        // console.log(tempdata);
        this.gridParentOptions.api.applyTransactionAsync({
          add: this.getSavedData,
        });

        this.gridParentOptions.api.redrawRows();
        this.gridParentOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        this.$vToastify.success("Data successfully saved!");
        let selectedNodes = this.gridParentOptions.api.getSelectedNodes();

        this.gridParentOptions.api.forEachNode((node) => {
          if (node.data.id == this.getParentSelectedId) {
            node.setSelected(true);
            selectedNodes = [node];
          }
        });

        //console.log(selectedNodes, this.getParentSelectedId);
        if (selectedNodes.length > 0) {
          this.gridParentOptions.api.selectIndex(
            selectedNodes[0].rowIndex,
            false,
            false
          );
          this.gridParentOptions.api.ensureIndexVisible(
            selectedNodes[0].rowIndex
          );
          this.gridParentOptions.api.setFocusedCell(
            selectedNodes[0].rowIndex,
            "code"
          );
        }
      }

      /*  this.gridParentOptions.api.forEachNode(node => {
          if (node.data != undefined) {
            if (node.data.rowUpdated) {
              if (node.data.id < 0) {
                tempdata.push(node.data);
              }
              node.data.rowUpdated = false;
            }
          }
        });
        this.gridParentOptions.api.applyTransaction({
          remove: tempdata,
        });
        this.gridParentOptions.api.applyTransaction({
          add: this.getSavedData,
        });

        this.gridParentOptions.api.redrawRows();
        this.gridParentOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        let selectedNodes = this.gridParentOptions.api.getSelectedNodes();

        this.gridParentOptions.api.forEachNode(node => {
          if (node.data.id == this.getParentSelectedId) {
            node.setSelected(true);
            selectedNodes = [node];
          }
        });

        //console.log(selectedNodes, this.getParentSelectedId);
        if (selectedNodes.length > 0) {
          this.gridParentOptions.api.selectIndex(
            selectedNodes[0].rowIndex,
            false,
            false
          );
          this.gridParentOptions.api.ensureIndexVisible(
            selectedNodes[0].rowIndex
          );
          this.gridParentOptions.api.setFocusedCell(
            selectedNodes[0].rowIndex,
            "code"
          );
        }
        this.$vToastify.success("Data successfully saved!");
      }*/
    },

    SaveCounter() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.SaveCounter > 0) {
        let emptyRowCounter = 0,
          tempSaveData = [],
          newRecord = [];

        this.gridParentOptions.api.forEachNode((node) => {
          if (node.data != undefined) {
            if (node.data.rowUpdated) {
              if (node.data.id < 0) {
                tempSaveData.push(node.data);
              } else {
                tempSaveData.push(node.data);
              }
              if (node.data.code == null || node.data.costModelTypeId == "") {
                emptyRowCounter++;
                return false;
              }
            }
          }
        });
        newRecord = tempSaveData;
        if (emptyRowCounter > 0) {
          this.gridParentOptions.api.redrawRows();
          this.gridParentOptions.api.refreshCells();
          emptyRowCounter = 0;
          this.$vToastify.error("Code Value(s) or CostModel Type are empty!");
        } else {
          //console.log(tempSaveData);
          if (this.rowParentData.findIndex((x) => x.code.length == 0) == -1) {
            this.saveData({ tempData: newRecord });
          } else {
            this.$vToastify.error("CostHeader Code Value is empty!");
          }
        }
      }
    },

    SaveRelation() {
      let tempData = [],
        tempNewData = {},
        tempStoreData = [];

      //console.log(this.getAllProjects, this.getAllPrograms);
      this.gridParentOptions.api.forEachNode((node) => {
        if (node.data != undefined) {
          if (node.data.id == this.getParentSelectedId) {
            tempData.push(node.data);
          }
        }
      });

      this.gridParentOptions.api.applyTransaction({
        remove: tempData,
      });

      /*Adding Values in Ag grid*/
      let tempElementData = this.getCostHeaderRelation.filter(
        (x) => x.parentId == null
      );
      if (tempElementData.length > 0) {
        tempElementData.forEach((row) => {
          if (!this.getAllProjects.indexOf((e) => e.id == row.id)) {
            tempStoreData.push(row);
          }
          if (!this.getAllPrograms.indexOf((e) => e.id == row.id)) {
            tempStoreData.push(row);
          }
        });
      }

      tempElementData = this.getCostHeaderRelation.filter(
        (x) => x.id == this.getParentSelectedId
      );

      tempElementData.forEach((row) => {
        tempNewData = { ...tempData[0] };
        tempNewData.parentId = row.parentId;
        tempNewData.path = row.path;
        tempNewData.projects = row.projects;
        tempNewData.programs = row.programs;
        tempNewData.uid = row.uid;
        tempStoreData.push(tempNewData);
      });

      //console.log(this.getCostHeaderRelation)

      /*   this.getCostHeaderRelation.forEach(projRow => {
        tempData[0].parentId = projRow.parentId;
        tempData[0].path = projRow.path;
        tempData[0].projects = projRow.projects;
        tempStoreData.push(tempData[0]);
      }); */
      console.log(tempStoreData);
      this.gridParentOptions.api.applyTransaction({
        add: this.tempStoreData,
      });
      this.gridParentOptions.api.redrawRows();
      this.gridParentOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },
  },

  computed: {
    /*Parent Controller Getters */
    ...mapGetters("costHeaderController", [
      "getParentGridColumn",
      "getParentCurrentData",
      "getCostModelTypeData",
      "getSavedData",
      "getParentSelectedId",
      "getAllProjects",
      "getAllPrograms",
    ]),
    ...mapGetters("dataManager", ["selectedDataType"]),

    ...mapGetters("relationControl", ["getCostHeaderRelation"]),

    /*Parent Controller State */
    ...mapState({
      ParentExtaColumn: (state) =>
        state.costHeaderController.currentParentExtaColumnLoadCounter,
      ParentRowdata: (state) =>
        state.costHeaderController.currentParentLoadRecordCounter,
      RedoCounter: (state) =>
        state.costHeaderController.currentParentRedoWatcher,
      UndoCounter: (state) =>
        state.costHeaderController.currentParentUndoWatcher,
      AddCounter: (state) =>
        state.costHeaderController.currentParentAddRecordCounter,
      DeleteCounter: (state) =>
        state.costHeaderController.currentParentDeleteRecordCounter,
      isDelete: (state) => state.costHeaderController.isDelete,
      SaveCounter: (state) =>
        state.costHeaderController.currentParentSaveRecordCounter,
      DataWatcher: (state) => state.costHeaderController.datawatcher,
      SaveRelation: (state) => state.relationControl.saveCostHeaderCounter,
    }),
    noRowsTemplate() {
      if (this.isElementdetail) {
        return this.loadingTemplate;
      } else {
        return `<span>No Rows to Show</span>`;
      }
    },
  },
  methods: {
    /* Parent Controller Actions */
    ...mapActions("costHeaderController", [
      "LoadParentExtraColumn",
      "LoadParentData",
      "DeleteParentNode",
      "saveData",
    ]),

    /* Parent Controller Mutations */
    ...mapMutations("costHeaderController", [
      "mutateParentUndo",
      "mutateParentRedo",
      "mutateParentRedostate",
      "mutateParentUndostate",
      "setParentObj",
      "mutateCurrentParentData",
    ]),

    onCellValueChanged(params) {
      if (params.node.data != undefined) {
        //console.log(params.node.data);
        params.node.data.rowUpdated = true;
        var undoSize = params.api.getCurrentUndoSize();
        var redoSize = params.api.getCurrentRedoSize();
        if (params.column.colId == "costModelType.description") {
          params.data.costModelType.description = params.newValue;
          params.data.costModelTypeId = this.getCostModelTypeData.find(
            (x) => x.description == params.newValue
          ).id;
        } else if (params.column.colId.match("et_")) {
          let colvalue = params.column.colId.split("_");
          let tempIndex = -1;
          if (params.newValue.length > 0) {
            tempIndex = params.node.data.extraColumnValues.findIndex(
              (y) => y.CostHeaderExtraColumnId == parseInt(colvalue[1])
            );
            params.node.data.rowUpdated = true;
            if (tempIndex < 0) {
              params.node.data.extraColumnValues.push({
                CostHeaderId: params.node.data.id,
                CostHeaderExtraColumnId: colvalue[1],
                value: params.newValue,
              });
            } else {
              params.node.data.extraColumnValues[tempIndex].value =
                params.newValue;
            }
          } else {
            params.node.data.extraColumnValues = [];
          }
        }

        if (undoSize == 0) {
          this.mutateParentUndostate(false);
          params.node.data.rowUpdated = false;
        } else {
          this.mutateParentUndostate(true);
        }
        if (redoSize == 0) {
          this.mutateParentRedostate(false);
        } else {
          this.mutateParentRedostate(true);
          params.node.data.rowUpdated = false;
        }
        params.api.refreshCells({ force: true, suppressFlash: true });
      }
    },

    onSelectionChanged(event) {
      var selectedRowObjs = event.api.getSelectedNodes();
      if (selectedRowObjs.length > 0) {
        this.SelectId = selectedRowObjs[0].data.id;
        if (selectedRowObjs[0].data.rowType == 3) {
          this.setParentObj({ row: selectedRowObjs[0].data });
        }
      }
    },
    onCellClicked(event) {
      //console.log(event);
      if (event.data != undefined) {
        var selectedRowObjs = event.data;
        this.SelectId = selectedRowObjs.id;
        event.api.forEachNode((node) => {
          if (node.data != undefined) {
            if (
              selectedRowObjs.rowType == 3 &&
              node.data.id === this.SelectId
            ) {
              node.setSelected(true);
            }
          }
        });
        if (selectedRowObjs.rowType == 3 && this.SelectId > 0) {
          this.setParentObj({ row: selectedRowObjs });
          if (
            event.data.rowType == 3 &&
            event.column.colId == "ag-Grid-AutoColumn"
          ) {
            //if (event.column.colId == "ag-Grid-AutoColumn") {
            localStorage.setItem(
              "CostElement",
              JSON.stringify(selectedRowObjs)
            );
            this.$router.push({ path: "/CostElement" });
          }
        }
      }
    },

    onGridReady(params) {
      getColumnDef(this.selectedDataType.componentId)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerCostHeader;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          this.LoadParentExtraColumn();
          this.LoadParentData();
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    reloadColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, this.selectedDataType.componentId)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridParentOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnChanged: debounce(function () {
      this.onColumnStateSubmit();
    }, 300),

    onColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridParentOptions.columnApi.getColumnState();

      postColumnState({
        componentId: this.selectedDataType.componentId,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },

    SetFocus() {
      let tempRowIndex = 0;
      this.tempRowId--;
      let tempNewRow = [
        {
          id: this.tempRowId,
          code: "",
          description: "",
          comment: "",
          costHeaderOption: "",
          costModelType: [],
          costModelTypeId: "",
          extraColumnValues: [],
          objectRelationships: [],
          parentId: 1,
          rowType: 3,
          uid: this.tempRowId,
          projects: null,
          programs: null,
          path: [1, this.tempRowId],
          rowUpdated: true,
        },
      ];
      //this.mutateCurrentParentData({ row: tempNewRow });

      //
      this.gridParentOptions.api.applyTransaction({
        add: tempNewRow,
      });
      //    this.gridParentOptions.redrawRows();

      this.gridParentOptions.api.forEachNode((node) => {
        if (node.data != undefined) {
          if (node.data.id == 1) {
            let thisParent = node.parent;

            while (thisParent) {
              thisParent.setExpanded(true);
              thisParent = thisParent.parent;
            }
            node.setSelected(true);
            node.setExpanded(true);
            tempRowIndex = node.rowIndex;
          } else if (node.key < 0) {
            node.setSelected(true);
            tempRowIndex = node.rowIndex;
          }
        }
      });
      //  this.gridParentOptions.refreshCells();
      this.gridParentOptions.api.ensureIndexVisible(tempRowIndex);
      this.gridParentOptions.api.setFocusedCell(tempRowIndex, "code");
    },
  },

  beforeMount() {
    /* call ExtraColumn Method */
    // this.LoadParentExtraColumn();
    //this.LoadParentData();
    this.columnDefs = [];
    this.undoRedoCellEditingLimit = 15;
    this.mutateParentRedostate(false);
    this.mutateParentUndostate(false);
    this.defaultColDef = Object.assign({}, this.defaultColDef, {
      width: 180,
    });
    this.autoGroupColumnDef = {
      headerName: "",
      field: "uid",
      rowDrag: true,
      suppressPaste: true,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: "iconCostCellRenderer",
      },
      resizable: true,
      sortable: true,
      //filterParams: { excelMode: "windows" },
      //filter: "agSetColumnFilter",
      width: 160,
    };
    this.getDataPath = (data) => {
      return data.path;
    };
    this.rowParentData = [];
  },

  mounted() {},
};

window.getIconCostCellRenderer = function getIconCostCellRenderer() {
  function IconCostCellRenderer() {}
  IconCostCellRenderer.prototype.init = function (params) {
    var tempDiv = document.createElement("div");
    tempDiv.innerHTML =
      params.node.data != undefined
        ? params.node.data.costModelType != undefined
          ? params.node.data.costModelType.rowTypeId == 5
            ? '<img src="Icons/planning2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
            : params.node.data.costModelType.rowTypeId == 6
            ? '<img src="Icons/budget2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
            : params.node.data.costModelType.rowTypeId == 7
            ? '<img src="Icons/commitment2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
            : params.node.data.costModelType.rowTypeId == 8
            ? '<img src="Icons/paid2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
            : null
          : params.node.data.rowType == 2
          ? '<img src="Icons/projects2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
          : params.node.data.rowType == 1
          ? '<img src="Icons/programs2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
          : null
        : null;
    this.eGui = tempDiv.firstChild;
  };
  IconCostCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return IconCostCellRenderer;
};

window.SetCostModelTypes = function SetCostModelTypes(data) {
  CostModelTypes = data;
};

window.getCostModelCellRenderer = function getCostModelCellRenderer() {
  function CustomCombobox() {}
  CustomCombobox.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var CostModelTypeFilter = CostModelTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );
    CostModelTypeFilter.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  CustomCombobox.prototype.getGui = function () {
    return this.eGui;
  };

  CustomCombobox.prototype.getGui = function () {
    return this.eInput;
  };
  CustomCombobox.prototype.afterGuiAttached = function () {
    this.eInput.focus();
  };
  CustomCombobox.prototype.getValue = function () {
    return this.eInput.value;
  };
  CustomCombobox.prototype.destroy = function () {};
  CustomCombobox.prototype.isPopup = function () {
    return false;
  };
  return CustomCombobox;
};

var CostModelTypes = null;

window.getDatePicker = function getDatePicker() {
  function Datepicker() {}
  var tempDatevalue = null;
  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "date");
    if (params.value != null) {
      this.eInput.value = moment(params.value).toISOString().substr(0, 10);
    }
    tempDatevalue = params.value; // moment(this.getSelectedRowObject.endDate).toISOString().substr(0, 10);

    this.eInput.classList.add("input");
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };
  Datepicker.prototype.getGui = function () {
    return this.eInput;
  };
  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Datepicker.prototype.getValue = function () {
    if (this.eInput.value.length > 0) {
      return moment(this.eInput.value).add(1, "days").format("YYYY-MM-DD");
    } else {
      return tempDatevalue;
    }
  };
  Datepicker.prototype.destroy = function () {};
  Datepicker.prototype.isPopup = function () {
    return false;
  };
  return Datepicker;
};

window.getIntegerCellEditor = function getIntegerCellEditor() {
  function IntegerPicker() {}
  IntegerPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.setAttribute("step", "1");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };

  /*  IntegerPicker.prototype.isKeyPressedNavigation = function(event) {
    return event.keyCode !=110;
  }; */
  IntegerPicker.prototype.getGui = function () {
    return this.eInput;
  };
  IntegerPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  IntegerPicker.prototype.getValue = function () {
    return parseInt(this.eInput.value);
  };
  IntegerPicker.prototype.destroy = function () {};
  IntegerPicker.prototype.isPopup = function () {
    return false;
  };
  return IntegerPicker;
};

window.getDecimalCellEditor = function getDecimalCellEditor() {
  function DecimalPicker() {}
  DecimalPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };
  DecimalPicker.prototype.getGui = function () {
    return this.eInput;
  };
  DecimalPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  DecimalPicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  DecimalPicker.prototype.destroy = function () {};
  DecimalPicker.prototype.isPopup = function () {
    return false;
  };
  return DecimalPicker;
};

window.getLookupCellEditor = function getLookupCellEditor() {
  function Lookuppicker() {}
  Lookuppicker.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var CostModelTypeFilter = CostModelTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );

    CostModelTypeFilter.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  Lookuppicker.prototype.getGui = function () {
    return this.eInput;
  };
  Lookuppicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Lookuppicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Lookuppicker.prototype.destroy = function () {};
  Lookuppicker.prototype.isPopup = function () {
    return false;
  };
  return Lookuppicker;
};
</script>

<style scoped lang="scss">
.line {
  width: 99.5%;
  height: 1%;
  border-bottom: 1px solid black;
  position: relative;
}
</style>
