var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loggedIn)?_c('div',{staticClass:"sidemenu",class:{ 'is-expanded': _vm.sidemenuIsExpanded }},[_c('ul',{on:{"click":function($event){_vm.sidemenuIsExpanded = !_vm.sidemenuIsExpanded}}},[_vm._m(0)]),(_vm.loggedIn)?_c('ul',{class:{
      'is-active':
        _vm.currentRouteName == 'Dashboard' ||
        _vm.currentRouteName == 'Project Dashboard',
    },attrs:{"aria-expanded":"false"},on:{"!click":function($event){return _vm.expandSubMenu.apply(null, arguments)}}},[_vm._m(1),_c('ul',[_vm._m(2),_vm._l((_vm.dashboardTypes.filter((x) => x.clientId == _vm.clientId)),function(dashId){return _c('li',{key:dashId.id,staticClass:"long-view-text-size",class:{
          'is-active':
            (_vm.currentRouteName == 'Dashboard' || _vm.currentRouteName == '') &&
            _vm.selectedDashboardType &&
            dashId.id == _vm.selectedDashboardType.id,
        },on:{"click":function($event){return _vm.mutateSelectedDashboardType({ selectedDashboardTypeId: dashId.id })},"!click":function($event){$event.preventDefault();return _vm.routeToDashboard()}}},[_c('LevelIconCellRenderer',{attrs:{"value":dashId.name}}),_vm._v(" "+_vm._s(dashId.name + " Dashboard")+" ")],1)}),_vm._l((_vm.dashboardTypes.filter(
          (x) => x.clientId == 0 && _vm.clientId != 3
        )),function(dashId){return _c('li',{key:dashId.id,staticClass:"long-view-text-size",class:{
          'is-active':
            (_vm.currentRouteName == 'Dashboard' || _vm.currentRouteName == '') &&
            _vm.selectedDashboardType &&
            dashId.id == _vm.selectedDashboardType.id,
        },on:{"click":function($event){return _vm.mutateSelectedDashboardType({
            selectedDashboardTypeId: dashId.id,
          })},"!click":function($event){$event.preventDefault();return _vm.routeToDashboard()}}},[_c('LevelIconCellRenderer',{attrs:{"value":dashId.name}}),_vm._v(" "+_vm._s(dashId.name + " Dashboard")+" ")],1)}),(_vm.dashboardTypes.length < 1)?_c('li',{on:{"!click":function($event){$event.preventDefault();return _vm.routeToDashboard()}}},[_vm._v(" Click to load! ")]):_vm._e()],2)]):_vm._e(),(_vm.loggedIn)?_c('ul',{class:{ 'is-active': _vm.currentRouteName == 'Views' },attrs:{"aria-expanded":"false"},on:{"!click":function($event){return _vm.expandSubMenu.apply(null, arguments)}}},[_vm._m(3),_c('ul',[_vm._m(4),_vm._l((_vm.views.filter((x) => x.viewTypeId != 7)),function(view){return _c('li',{key:view.UserViewOrder,staticClass:"long-view-text-size",class:{
          'is-active':
            _vm.currentRouteName == 'Views' &&
            _vm.selectedView &&
            view.id == _vm.selectedView.id,
        },on:{"click":function($event){return _vm.mutateSelectedView({ selectedViewId: view.id })},"!click":function($event){$event.preventDefault();return _vm.routeToViews()}}},[_vm._v(" "+_vm._s(view.name)+" ")])}),(_vm.views.length < 1)?_c('li',{on:{"!click":function($event){$event.preventDefault();return _vm.routeToViews()}}},[_vm._v(" Click to load! ")]):_vm._e()],2)]):_vm._e(),(_vm.loggedIn && _vm.validIds.indexOf(_vm.userid) > -1)?_c('ul',{class:{ 'is-active': _vm.currentRouteName == 'Data' },attrs:{"aria-expanded":"false"},on:{"!click":function($event){return _vm.expandSubMenu.apply(null, arguments)}}},[_c('li',[_c('LevelIconCellRenderer',{attrs:{"value":'TransactionData'}}),_c('h1',[_vm._v("Transactional Data")]),_vm._m(5)],1),_c('ul',[_vm._m(6),_vm._l((_vm.dataTypes),function(currDT){return (
          currDT.id < 3 || (currDT.id >= 3 && _vm.adminIds.indexOf(_vm.userid) > -1)
        )?_c('li',{key:currDT.id,staticClass:"long-view-text-size",class:{
          'is-active':
            _vm.currentRouteName == 'Data' &&
            _vm.selectedDataType &&
            currDT.id == _vm.selectedDataType.id,
        },on:{"click":function($event){return _vm.mutateSelectedDataType({ selectedDataTypeId: currDT.id })},"!click":function($event){$event.preventDefault();return _vm.routeToData()}}},[_c('p',[_c('LevelIconCellRenderer',{attrs:{"value":currDT.name}}),_vm._v(" "+_vm._s(currDT.name)+" ")],1)]):_vm._e()}),(_vm.dataTypes.length < 1)?_c('li',{on:{"!click":function($event){$event.preventDefault();return _vm.routeToData()}}},[_vm._v(" Click to load! ")]):_vm._e()],2)]):_vm._e(),(_vm.loggedIn && _vm.adminIds.indexOf(_vm.userid) > -1)?_c('ul',{class:{ 'is-active': _vm.currentRouteName == 'MasterData' },attrs:{"aria-expanded":"false"},on:{"!click":function($event){return _vm.expandSubMenu.apply(null, arguments)}}},[_c('li',[_c('LevelIconCellRenderer',{attrs:{"value":'MasterData'}}),_c('h1',[_vm._v("Master Data")]),_vm._m(7)],1),_c('ul',[_vm._m(8),_vm._l((_vm.MasterDataTypes),function(currDT){return _c('li',{key:currDT.id,staticClass:"long-view-text-size",class:{
          'is-active':
            _vm.currentRouteName == 'MasterData' &&
            _vm.selectedMasterDataType &&
            currDT.id == _vm.selectedMasterDataType.id,
        },on:{"click":function($event){return _vm.mutateSelectedMasterDataType({
            selectedMasterDataTypeId: currDT.id,
          })},"!click":function($event){$event.preventDefault();return _vm.routeToMasterData()}}},[_c('LevelIconCellRenderer',{attrs:{"value":currDT.name}}),_vm._v(" "+_vm._s(currDT.name)+" ")],1)}),(_vm.MasterDataTypes.length < 1)?_c('li',{on:{"!click":function($event){$event.preventDefault();return _vm.routeToMasterData()}}},[_vm._v(" Click to load! ")]):_vm._e()],2)]):_vm._e(),(_vm.loggedIn && _vm.adminIds.indexOf(_vm.userid) > -1)?_c('ul',{class:{ 'is-active': _vm.currentRouteName == 'ProcessSets' },attrs:{"aria-expanded":"false"},on:{"!click":function($event){return _vm.expandSubMenu.apply(null, arguments)}}},[_c('li',[_c('i',{staticClass:"fas fa-cogs"}),_c('LevelIconCellRenderer',{attrs:{"value":'ProcessSets'}}),_c('h1',[_vm._v("Process Sets")]),_vm._m(9)],1),_c('ul',[_vm._m(10),_c('li',{staticClass:"long-view-text-size",class:{
          'is-active': _vm.currentRouteName == 'ProcessSets',
        },on:{"!click":function($event){$event.preventDefault();return _vm.routeToProcessSets()}}},[_vm._v(" "+_vm._s("ProcessSets")+" ")])])]):_vm._e(),(_vm.loggedIn && _vm.adminIds.indexOf(_vm.userid) > -1)?_c('ul',{class:{ 'is-active': _vm.currentRouteName == 'Settings' },attrs:{"aria-expanded":"false"},on:{"!click":function($event){return _vm.expandSubMenu.apply(null, arguments)}}},[_c('li',[_c('LevelIconCellRenderer',{attrs:{"value":'Settings'}}),_c('h1',[_vm._v("Settings")]),_vm._m(11)],1),_c('ul',[_vm._m(12),_vm._l((_vm.UserDataTypes),function(currDT){return _c('li',{key:currDT.id,staticClass:"long-view-text-size",class:{
          'is-active':
            _vm.currentRouteName == 'Settings' &&
            _vm.selectedUserDataType &&
            currDT.id == _vm.selectedUserDataType.id,
        },on:{"click":function($event){return _vm.mutateSelectedUserDataType({
            selectedUserDataType: currDT.id,
          })},"!click":function($event){$event.preventDefault();return _vm.routeToSettings()}}},[_c('LevelIconCellRenderer',{attrs:{"value":currDT.name}}),_vm._v(" "+_vm._s(currDT.name)+" ")],1)}),(_vm.UserDataTypes.length < 1)?_c('li',{on:{"!click":function($event){$event.preventDefault();return _vm.routeToSettings()}}},[_vm._v(" Click to load! ")]):_vm._e()],2)]):_vm._e(),_c('ul',[_c('li',[_c('LevelIconCellRenderer',{attrs:{"value":'Integrate'}}),_c('h1',[_vm._v("Integrate")]),_vm._m(13)],1),_c('ul',[_vm._m(14),_vm._l((_vm.IntegrateDataTypes),function(currDT){return _c('li',{key:currDT.id,staticClass:"long-view-text-size",class:{
          'is-active':
            _vm.currentRouteName == 'Integrate' &&
            _vm.selectedIntegrateDataType &&
            currDT.id == _vm.selectedIntegrateDataType.id,
        },on:{"click":function($event){return _vm.mutateSelectedIntegrateDataType({
            selectedIntegrateDataType: currDT.id,
          })},"!click":function($event){$event.preventDefault();return _vm.routeToSettings()}}},[_c('LevelIconCellRenderer',{attrs:{"value":currDT.name}}),_vm._v(" "+_vm._s(currDT.name)+" ")],1)}),(_vm.IntegrateDataTypes.length < 1)?_c('li',{on:{"!click":function($event){$event.preventDefault();return _vm.routeToSettings()}}},[_vm._v(" Click to load! ")]):_vm._e()],2)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fas fa-bars"}),_c('h1')])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fas fa-home"}),_c('h1',[_vm._v("Dashboards")]),_c('span',{staticClass:"expandable"},[_c('i',{staticClass:"fas fa-angle-down"}),_c('i',{staticClass:"fas fa-angle-up"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h1',[_vm._v("Dashboards")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fas fa-chart-bar"}),_c('h1',[_vm._v("P3 Management")]),_c('span',{staticClass:"expandable"},[_c('i',{staticClass:"fas fa-angle-down"}),_c('i',{staticClass:"fas fa-angle-up"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h1',[_vm._v("P3 Management")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"expandable"},[_c('i',{staticClass:"fas fa-angle-down"}),_c('i',{staticClass:"fas fa-angle-up"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h1',[_vm._v("Transactional Data")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"expandable"},[_c('i',{staticClass:"fas fa-angle-down"}),_c('i',{staticClass:"fas fa-angle-up"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h1',[_vm._v("Master Data")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"expandable"},[_c('i',{staticClass:"fas fa-angle-down"}),_c('i',{staticClass:"fas fa-angle-up"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h1',[_vm._v("Process Sets")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"expandable"},[_c('i',{staticClass:"fas fa-angle-down"}),_c('i',{staticClass:"fas fa-angle-up"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h1',[_vm._v("Settings")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"expandable"},[_c('i',{staticClass:"fas fa-angle-down"}),_c('i',{staticClass:"fas fa-angle-up"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h1',[_vm._v("Integrate")])])
}]

export { render, staticRenderFns }