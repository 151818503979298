<template>
  <div style="height: 100%; background-color: white">
    <div class="line"></div>
    <div class="columns" style="height: 100%; width: 100%">
      <div class="container-grid" style="width: 100%; height: 100%">
        <div class="box" style="height: 100%" ref="scriptContainercalendar">
          <CalendarToolBar />
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="single"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="false"
            :columnDefs="parentcolumnDefs"
            :rowData="rowParentData"
            :suppressRowClickSelection="true"
            :enableRangeSelection="true"
            :undoRedoCellEditing="true"
            :defaultColDef="defaultColDef"
            :detailCellRendererParams="detailCellRendererParams"
            :autoGroupColumnDef="autoGroupColumnDef"
            :components="parentComponents"
            :gridOptions="gridParentOptions"
            :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
            @cell-value-changed="onCellValueChanged"
            @selection-changed="onSelectionChanged"
            @cell-clicked="onCellClicked"
            @grid-ready="onGridReady"
            @sort-changed="onColumnChanged"
            @column-resized="onColumnChanged"
            @column-visible="onColumnChanged"
            @column-row-group-changed="onColumnChanged"
            @column-value-changed="onColumnChanged"
            @column-moved="onColumnChanged"
            @column-pinned="onColumnChanged"
          >
          </ag-grid-vue>
        </div>
        <div class="handler"></div>
        <div class="box">
          <div
            class="columns"
            style="color: black"
            :disabled="getParentSelectedId == null"
          >
            <div class="column">
              <div class="columns" style="height: 45vh">
                <div class="column" style="margin-left: 6%; margin-top: 3%">
                  <!--  Table Legend: -->
                  <div class="column">
                    <div class="columns is-mobile" style="color: black">
                      Table Legend:
                    </div>
                    <div class="columns is-mobile">
                      <table
                        valign="bottom"
                        class="controller"
                        style="align-self: flex-end; border: dotted 2px #000000"
                      >
                        <tr style="">
                          <td>
                            <div class="Datelegends"></div>
                          </td>
                          <td>Working</td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              class="Datelegends"
                              style="background-color: #d3d3d3"
                            ></div>
                          </td>
                          <td>NonWorking</td>
                        </tr>
                        <!--  <tr>
                          <td style="font-size:small; " class="text">
                            <div class="Datelegends"><u>31 </u></div>
                          </td>
                          <td>Edited Working Hours</td>
                        </tr>
                        <tr>
                          <td colspan="2">Edited Working Hours</td>
                        </tr> -->
                        <tr>
                          <td style="font-size: small" class="text">
                            <div
                              class="Datelegends"
                              style="background-color: #add8e6"
                            >
                              <u>31 </u>
                            </div>
                          </td>
                          <td>Exception Day</td>
                        </tr>
                        <!--  <tr>
                          <td style="font-size:small; " class="text">
                            <div
                              class="Datelegends"
                              style="background-color: #FFFFE0;"
                            >
                              <u>31 </u>
                            </div>
                          </td>
                          <td>Nonedefault Work Week</td>
                        </tr> -->
                      </table>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div style="height: 100vh">
                    <bryntum-date-picker
                      v-bind="calendarConfigLocal"
                    ></bryntum-date-picker>
                  </div>
                </div>
                <div class="column" name="demo11">
                  <p class="bd-notification is-info"></p>
                </div>
              </div>

              <div class="columns is-mobile is-12" style="height: 100%">
                <div class="column">
                  <div class="column">
                    <div class="tabs">
                      <ul>
                        <li
                          v-bind:class="{
                            'is-active': tabsel == 'exceptiontab',
                          }"
                          @click="tabsel = 'exceptiontab'"
                        >
                          <a>
                            <span class="icon is-small">
                              <i class="fas fa-exclamation-circle"></i
                            ></span>
                            Exceptions</a
                          >
                        </li>
                        <li
                          v-bind:class="{
                            'is-active': tabsel == 'Working&Non-Working',
                          }"
                          @click="tabsel = 'Working&Non-Working'"
                        >
                          <a>
                            <span class="icon is-small">
                              <i class="fas fa-calendar-week"></i
                            ></span>
                            Working And Non-Working Intervals</a
                          >
                        </li>
                      </ul>
                    </div>

                    <div class="content">
                      <div
                        v-show="tabsel == 'exceptiontab'"
                        style="height: 85%"
                        ref="scriptContainercalendarException"
                      >
                        <CalendarExceptionToolBar />
                        <ag-grid-vue
                          class="ag-theme-balham ag-default-layout"
                          rowSelection="single"
                          enableCellChangeFlash="true"
                          stopEditingWhenGridLosesFocus="true"
                          :columnDefs="exceptionColDef"
                          :rowData="rowExceptionData"
                          :components="exceptionComponents"
                          :suppressRowClickSelection="true"
                          :enableRangeSelection="true"
                          :undoRedoCellEditing="true"
                          :gridOptions="gridExceptionOptions"
                          :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
                          @cell-clicked="onCellExceptionClicked"
                          @cell-value-changed="onCellValueExceptionChanged"
                          @grid-ready="onExceptionsGridReady"
                          @sort-changed="onExceptionsColumnChanged"
                          @column-resized="onExceptionsColumnChanged"
                          @column-visible="onExceptionsColumnChanged"
                          @column-row-group-changed="onExceptionsColumnChanged"
                          @column-value-changed="onExceptionsColumnChanged"
                          @column-moved="onExceptionsColumnChanged"
                          @column-pinned="onExceptionsColumnChanged"
                        >
                        </ag-grid-vue>
                      </div>
                      <div
                        v-show="tabsel == 'Working&Non-Working'"
                        ref="scriptContainercalendarWorkingIntervals"
                      >
                        <CalendarworkingNonWorkingToolBar />
                        <div class="columns is-mobile" style="height: 32vh">
                          <div class="column">
                            <ag-grid-vue
                              class="ag-theme-balham ag-default-layout"
                              rowSelection="mutiple"
                              enableCellChangeFlash="true"
                              stopEditingWhenGridLosesFocus="true"
                              :columnDefs="workingNonWorkingColDef"
                              :rowData="rowTimeIntervalData"
                              :gridOptions="gridWorkingIntervalsOptions"
                              @cell-clicked="onCellIsWorkingClicked"
                              singleClickEdit="true"
                              @cell-value-changed="onCellValueWorkChanged"
                              @grid-ready="onWorkingGridReady"
                              @sort-changed="onWrokingColumnChanged"
                              @column-resized="onWrokingColumnChanged"
                              @column-visible="onWrokingColumnChanged"
                              @column-row-group-changed="onWrokingColumnChanged"
                              @column-value-changed="onWrokingColumnChanged"
                              @column-moved="onWrokingColumnChanged"
                              @column-pinned="onWrokingColumnChanged"
                            >
                            </ag-grid-vue>
                          </div>
                          <div class="column">
                            <CalendarWorkingNonWorkingInterval />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// gantt and its config
import { BryntumDatePicker } from "@bryntum/gantt-vue";
import { DatePicker, DateHelper } from "@bryntum/gantt";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import CalendarToolBar from "./CalendarToolbar.vue";
import CalendarExceptionToolBar from "./CalendarExceptionToolBar.vue";
import CalendarworkingNonWorkingToolBar from "./CalendarWorkingIntervalToolbar.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import LevelIconCellRenderer from "@/components/Data/LevelIconCellRenderer.vue";
import CalendarWorkingNonWorkingInterval from "@/components/CalendarAdmin/CalendarWorkNoWorkingPopup.vue";
import * as moment from "moment";
import * as utilitiesModule from "@/assets/utilities.js";
import { debounce } from "lodash";
import {
  getColumnDef,
  postColumnState,
  getColumnState,
} from "@/api/networking.js";

export default {
  name: "CalendarAdminManager",
  components: {
    AgGridVue,
    CalendarToolBar,
    CalendarExceptionToolBar,
    CalendarworkingNonWorkingToolBar,
    CalendarWorkingNonWorkingInterval,
    LevelIconCellRenderer, // eslint-disable-line
    BryntumDatePicker,
  },
  data() {
    return {
      rowParentData: null,
      parentcolumnDefs: null,
      undoRedoCellEditingLimit: null,
      autoGroupColumnDef: null,
      defaultColDef: null,
      i: -1,
      gridParentOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
        processDataFromClipboard: (params) => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          const lastIndex =
            this.gridParentOptions.api.getModel().rowsToDisplay.length - 1;
          const focusedCell = this.gridParentOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;
          if (focusedIndex + params.data.length - 1 > lastIndex) {
            const resultLastIndex = focusedIndex + (params.data.length - 1);
            const addRowCount = resultLastIndex - lastIndex;
            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }
            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];
            rowsToAdd.map((r) => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map((i) => {
                row[currColumn.colDef.field] = i;
                currColumn =
                  this.gridParentOptions.columnApi.getDisplayedColAfter(
                    currColumn
                  );
              });
              row.extraColumnValues = [];
              row.rowUpdated = true;
              row.id = -1;
              newRowData.push(row);
              this.rowParentData.push(row);
            });

            this.gridParentOptions.api.applyTransactionAsync({
              add: newRowData,
            });
            this.gridParentOptions.api.redrawRows();
          }
          this.gridParentOptions.api.refreshCells();
          return params.data;
        },
      },
      detailCellRendererParams: {
        refreshStrategy: "everything",
      },
      parentComponents: {
        /*  CustomCombobox: getCostModelCellRenderer(),
        datePicker: getDatePicker(),
        integerCellEditor: getIntegerCellEditor(),
        decimalCellEditor: getDecimalCellEditor(),
        lookupCellEditor: getLookupCellEditor(),
        iconCostCellRenderer: getIconCostCellRenderer(), */
      },
      singleClickEdit: false,
      SelectId: null,
      loadingTemplate: `<span class="ag-overlay-loading-center">Data is loading...</span>`,
      deleteSettings: {
        withBackdrop: true,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "center-center",
      },
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
      exceptionComponents: {
        newdatePicker: getExceptionDatepicker(),
      },

      tabsel: "exceptiontab",

      /**
       * WorkingNonWorking and Exceptions Grid
       */
      //Exception
      exceptionColDef: null,
      rowExceptionData: null,
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],

      gridExceptionOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
          ],
        },
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
        processDataFromClipboard: (params) => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          const lastIndex =
            this.gridExceptionOptions.api.getModel().rowsToDisplay.length - 1;
          const focusedCell = this.gridExceptionOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;

          if (focusedIndex + params.data.length - 1 > lastIndex) {
            const resultLastIndex = focusedIndex + (params.data.length - 1);
            const addRowCount = resultLastIndex - lastIndex;
            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }
            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];
            rowsToAdd.map((r) => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map((i) => {
                row[currColumn.colDef.field] = i;
                currColumn =
                  this.gridExceptionOptions.columnApi.getDisplayedColAfter(
                    currColumn
                  );
              });
              row.rowUpdated = true;
              row.id = -1;
              newRowData.push(row);
              this.rowExceptionData.push(row);
            });
            this.gridExceptionOptions.api.applyTransaction({ add: newRowData });
          }
          this.gridExceptionOptions.api.refreshCells();
          return params.data;
        },
      },

      calendarConfigLocal: {
        width: "500px",
        height: "390px",
        multiSelect: true,
        refresh: true,
        date: new Date(),
        cellRenderer: (cell) => {
          //console.log(cell.day);
          cell.cell.style = "color:black";
          if (this.getCurrentWorkIntervalData != undefined) {
            for (let a = 0; a < this.getCurrentWorkIntervalData.length; a++) {
              if (
                this.getCurrentWorkIntervalData[a] != undefined &&
                this.getCurrentWorkIntervalData[a].recurringStartDate != null
              ) {
                if (
                  this.getCurrentWorkIntervalData[a].intervalConfig ===
                  "WeekDays"
                ) {
                  if (cell.cell.classList.value.search("b-weekend") == -1) {
                    if (!this.getCurrentWorkIntervalData[a].isWorking)
                      cell.cell.style = "background-color:darkgrey";
                    //cell.cell.style = "background-color:white";
                  }
                  //
                } else if (
                  this.getCurrentWorkIntervalData[a].intervalConfig ===
                  "Weekends"
                ) {
                  if (cell.cell.classList.value.search("b-weekend") > 1) {
                    if (this.getCurrentWorkIntervalData[a].isWorking)
                      cell.cell.style = "background-color:green";
                    else cell.cell.style = "background-color:darkgrey";
                  }
                } else if (
                  this.getCurrentWorkIntervalData[a].intervalConfig ===
                  "SpecificDays"
                ) {
                  for (
                    let b = 0;
                    b < this.getCurrentWorkIntervalData[a].weekdays.length;
                    b++
                  ) {
                    if (
                      this.getCurrentWorkIntervalData[a].weekdays[b] ==
                      this.days[cell.day]
                    )
                      if (this.getCurrentWorkIntervalData[a].isWorking)
                        cell.cell.style = "background-color:red";
                      else cell.cell.style = "background-color:darkgrey";
                  }
                }
              }
            }
          }
          //  this is a delegate function to access exception and work intervals data
          /*  if (cell.cell.id.length == 0) {
            cell.cell.style = "color:black";
          } */
          if (this.getCurrentException != undefined) {
            for (let x = 0; x < this.getCurrentException.length; x++) {
              let tempDate = moment
                .utc(this.getCurrentException[x].startDate)
                .format("MM/DD/YYYY");
              if (
                cell.date >= new Date(tempDate) &&
                cell.date <= new Date(this.getCurrentException[x].endDate)
              ) {
                cell.cell.style = "background-color:skyblue";
              }
            }
          }
        },
        onSelectionChange: ({ selection }) => {
          //   console.log(selection);
        },
      },

      //Exception
      workingNonWorkingColDef: null,
      rowTimeIntervalData: null,
      cellClikedInterval: false,
      gridWorkingIntervalsOptions: {},
    };

    /**Working in Intervals or not Working */
  },
  watch: {
    ParentRowdata() {
      this.rowParentData = this.getParentCurrentData;
      this.reloadColumnState();
      this.reloadExceptionsColumnState();
      this.reloadWorkingColumnState();
    },

    UndoCounter() {
      this.gridParentOptions.api.undoCellEditing();
    },
    RedoCounter() {
      this.gridParentOptions.api.redoCellEditing();
    },
    Updatecalendar() {
      //console.log(" asdsadasd", this.getCurrentWorkIntervalData);
      const t1 = Math.floor(Math.random() * (23 - 1 + 1) + 1);
      const t2 = Math.floor(Math.random() * (10000 - 1 + 1) + 1);
      this.calendarConfigLocal.date = new Date(Date.now() + 3600 * t2 * t1);
    },
    SaveCounter() {
      this.saveData();
    },

    DataWatcher() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.DataWatcher > 0) {
        let tempdata = [];
        this.gridParentOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            node.data.rowUpdated = false;
            tempdata.push(node.data);
          }
        });
        // console.log(this.getSavedData);

        this.gridParentOptions.api.applyTransaction({
          update: tempdata,
        });
        this.gridParentOptions.api.applyTransaction({
          update: this.getSavedData,
        });

        //  this.getSavedData.forEach(row => {
        tempdata = [];
        this.gridExceptionOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            node.data.rowUpdated = false;
            tempdata.push(node.data);
          }
        });
        this.gridExceptionOptions.api.applyTransaction({
          update: tempdata,
        });

        this.gridExceptionOptions.api.redrawRows();
        this.gridExceptionOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        tempdata = [];
        this.gridWorkingIntervalsOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            node.data.rowUpdated = false;
            tempdata.push(node.data);
          }
        });
        this.gridWorkingIntervalsOptions.api.applyTransaction({
          update: tempdata,
        });
        //    });
        this.gridWorkingIntervalsOptions.api.redrawRows();
        this.gridWorkingIntervalsOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });

        const t1 = Math.floor(Math.random() * (23 - 1 + 1) + 1);
        const t2 = Math.floor(Math.random() * (10000 - 1 + 1) + 1);
        this.calendarConfigLocal.date = new Date(Date.now() + 3600 * t2 * t1);
        this.$vToastify.success("Data successfully saved!");
      }
    },

    isDelete() {
      if (this.isDelete > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.gridExceptionOptions.api.redrawRows();
        this.gridExceptionOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });

        this.gridExceptionOptions.api.redrawRows();
        this.gridExceptionOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });

        this.gridWorkingIntervalsOptions.api.redrawRows();
        this.gridWorkingIntervalsOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        this.$vToastify.success("Row Deleted Successfully!");
      }
    },

    DeleteCounter() {
      if (this.DeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedData = this.getParentSelectedObj;
              if (selectedData.id > 0) {
                this.DeleteParentNode({ Id: selectedData.id, intervals: 0 });
              }
              //console.log(selectedData);
              this.gridParentOptions.api.applyTransaction({
                remove: [selectedData],
              });
              selectedData = [];
            }
          });
      }
    },

    ExceptionDelete() {
      if (this.ExceptionDelete > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedData = this.getExceptionSelectedObj;
              if (selectedData.id > 0) {
                this.DeleteParentNode({
                  Id: this.getParentSelectedObj.id,
                  intervals: selectedData.id,
                  flag: "Exception",
                });
              }

              this.gridExceptionOptions.api.applyTransaction({
                remove: [selectedData],
              });
              selectedData = [];
            }
          });
      }
    },

    WorkingTabDelete() {
      if (this.WorkingTabDelete > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedData = this.getworkingNonWorkingSelectedObj;
              if (selectedData.id > 0) {
                this.DeleteParentNode({
                  Id: this.getParentSelectedObj.id,
                  intervals: selectedData.id,
                  flag: "WorkInterval",
                });
              }

              this.gridWorkingIntervalsOptions.api.applyTransaction({
                remove: [selectedData],
              });
              selectedData = [];
            }
          });
      }
    },

    UndoExceptionCounter() {
      this.gridExceptionOptions.api.undoCellEditing();
    },
    RedoExceptionCounter() {
      this.gridExceptionOptions.api.redoCellEditing();
    },
    SelectCounter() {
      this.rowExceptionData = [];
      this.rowTimeIntervalData = [];

      const oldData = [];
      this.gridExceptionOptions.api.forEachNode((r) => oldData.push(r.data));
      this.gridExceptionOptions.api.applyTransaction({ remove: oldData });

      this.gridExceptionOptions.api.redrawRows();
      this.gridExceptionOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });

      this.gridWorkingIntervalsOptions.api.redrawRows();
      this.gridWorkingIntervalsOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.rowExceptionData = this.getCurrentException;
      this.rowTimeIntervalData = this.getCurrentWorkIntervalData;

      const t1 = Math.floor(Math.random() * (23 - 1 + 1) + 1);
      const t2 = Math.floor(Math.random() * (10000 - 1 + 1) + 1);
      this.calendarConfigLocal.date = new Date(Date.now() + 3600 * t2 * t1);
    },
    ColdefCounter() {
      this.getParentColDefs.forEach((row) => {
        this.parentcolumnDefs.push(row);
      });
      this.reloadColumnState();
    },
  },

  computed: {
    ...mapGetters("calendarController", [
      "getParentGridColumn",
      "getParentCurrentData",
      "getExceptionColDef",
      "getWorkingNonWorkingColDef",
      "getCurrentException",
      "getCurrentWorkIntervalData",
      "getParentSelectedId",
      "getParentSelectedObj",
      "getExceptionSelectedObj",
      "getworkingNonWorkingSelectedObj",
      "getSavedData",
      "getParentColDefs",
    ]),

    ...mapState({
      ParentExtaColumn: (state) =>
        state.calendarController.currentParentExtaColumnLoadCounter,
      ParentRowdata: (state) =>
        state.calendarController.currentParentLoadRecordCounter,
      RedoCounter: (state) => state.calendarController.currentParentRedoWatcher,
      UndoCounter: (state) => state.calendarController.currentParentUndoWatcher,
      Updatecalendar: (state) =>
        state.calendarController.updatecalendarControllerCounter,
      RedoExceptionCounter: (state) =>
        state.calendarController.currentExceptionRedoWatcher,
      UndoExceptionCounter: (state) =>
        state.calendarController.currentExceptionUndoWatcher,
      SaveCounter: (state) =>
        state.calendarController.currentParentSaveRecordCounter,
      SelectCounter: (state) =>
        state.calendarController.currentParentSelctedCounter,
      DeleteCounter: (state) =>
        state.calendarController.currentParentDeleteRecordCounter,
      DataWatcher: (state) => state.calendarController.datawatcher,

      isDelete: (state) => state.calendarController.isDelete,

      ExceptionDelete: (state) =>
        state.calendarController.currentExceptionDeleteRecordCounter,
      WorkingTabDelete: (state) =>
        state.calendarController.currentworkingNonWorkingDeleteRecordCounter,
      ColdefCounter: (state) =>
        state.calendarController.currentParentColumDefCounter,
    }),

    noRowsTemplate() {
      if (this.isElementdetail) {
        return this.loadingTemplate;
      } else {
        return `<span>No Rows to Show</span>`;
      }
    },

    validated() {
      return true;
    },
  },
  methods: {
    ...mapActions("calendarController", [
      "LoadParentData",
      "saveData",
      "DeleteParentNode",
      "LoadCalendarExtraColumn",
    ]),

    ...mapMutations("calendarController", [
      "mutateParentUndo",
      "mutateParentRedo",
      "mutateParentRedostate",
      "mutateParentUndostate",
      "setParentObj",
      "setworkingNonWorkingObj",
      "setExceptionObj",
      "mutateExceptionUndostate",
      "mutateExceptionRedostate",
    ]),

    onCellClicked(event) {
      this.setParentObj({ row: event.node.data });
    },

    onCellExceptionClicked(params) {
      if (
        params.node.data.startDate.length > 0 &&
        params.node.data.endDate.length > 0
      ) {
        if (
          new Date(params.node.data.startDate) <=
          new Date(params.node.data.endDate)
        ) {
          this.calendarConfigLocal.date = new Date(params.node.data.startDate);
        } else
          this.$vToastify.error(
            params.node.data.name + " From Date is not less than End date!"
          );
      }
      this.setExceptionObj({ row: params.node.data });

      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();

      if (undoSize == 0) {
        this.mutateExceptionUndostate(false);
        params.node.data.rowUpdated = false;
      } else {
        this.mutateExceptionUndostate(true);
      }
      if (redoSize == 0) {
        this.mutateExceptionRedostate(false);
      } else {
        this.mutateExceptionRedostate(true);
        params.node.data.rowUpdated = false;
      }
    },

    onCellValueChanged(params) {
      params.node.data.rowUpdated = true;
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();
      if (params.column.colId.match("et_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;
        if (params.node.data.extraColumnValues == undefined) {
          params.node.data.extraColumnValues = [];
        }

        tempIndex = params.node.data.extraColumnValues.findIndex(
          (y) => y.calendarExtraColumnId == parseInt(colvalue[1])
        );
        params.node.data.rowUpdated = true;
        //console.log("ada");
        if (tempIndex < 0) {
          params.node.data.extraColumnValues.push({
            calendarId: params.node.data.id,
            calendarExtraColumnId: colvalue[1],
            value: params.newValue,
          });
        } else {
          params.node.data.extraColumnValues[tempIndex].value = params.newValue;
        }
      }
      if (undoSize == 0) {
        this.mutateParentUndostate(false);
        params.node.data.rowUpdated = false;
      } else {
        this.mutateParentUndostate(true);
      }
      if (redoSize == 0) {
        this.mutateParentRedostate(false);
      } else {
        this.mutateParentRedostate(true);
        params.node.data.rowUpdated = false;
      }
      params.api.refreshCells();
    },

    onCellValueExceptionChanged(params) {
      params.node.data.rowUpdated = true;
      params.api.refreshCells({ force: true, suppressFlash: true });
    },

    onCellValueWorkChanged(params) {
      params.node.data.rowUpdated = true;
      params.api.refreshCells({ force: true, suppressFlash: true });
    },
    onCellIsWorkingClicked(params) {
      this.gridWorkingIntervalsOptions.api.forEachNode((node) => {
        if (node.data.id === params.node.data.id) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
      this.setworkingNonWorkingObj({ row: params.node.data });
    },

    onSelectionChanged(event) {
      this.$vToastify.setSettings(this.NormalSettings);
    },

    onGridReady() {
      getColumnDef(17)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainercalendar;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);

          //this.gridStructureOptions.api.setColumnDefs(container);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          this.LoadCalendarExtraColumn().then(() => {
            this.LoadParentData();
          });
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    reloadColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 17)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridParentOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnChanged: debounce(function () {
      this.onColumnStateSubmit();
    }, 300),

    onColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridParentOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 17,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },

    cellClicked(params) {
      params.api.refreshCells();

      this.gridCostElementOptions.api.refreshCells();
    },

    changePage(pg) {
      this.currentPage = pg;
    },

    onExceptionsGridReady() {
      // this.loadStructureRows();
      getColumnDef(25)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainercalendarWorkingIntervals;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    onExceptionsColumnChanged: debounce(function () {
      this.onColumnExceptionsStateSubmit();
    }, 300),

    reloadExceptionsColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 25)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridExceptionOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnExceptionsStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridExceptionOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 25,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },

    onWorkingGridReady() {
      // this.loadStructureRows();
      getColumnDef(26)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainercalendarWorkingIntervals;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    onWrokingColumnChanged: debounce(function () {
      this.onColumnWorkingStateSubmit();
    }, 300),

    reloadWorkingColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 26)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridWorkingIntervalsOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnWorkingStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridWorkingIntervalsOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 26,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },
  },

  beforeMount() {
    /* call ExtraColumn Method */
    this.parentcolumnDefs = [];

    this.undoRedoCellEditingLimit = 15;
    this.mutateParentRedostate(false);
    this.mutateParentUndostate(false);
    this.cellClikedInterval = false;

    /**
     * Load exception coldef
     */
    this.exceptionColDef = [];

    /**
     * Load WorkingNonWorking Coldef
     */
    this.workingNonWorkingColDef = [];

    /**
     * Load Dummy Exception Data
     */

    //

    /*  const picker = new DatePicker({
      appendTo: document.body,
      width: "20em",
      multiSelect: true,
      date: new Date(),
      onSelectionChange: ({ selection }) => {
        console.log(selection[0], selection);
      },
    }); */
  },

  mounted() {},
};

window.getExceptionDatepicker = function getExceptionDatepicker() {
  // Initialize all input of type date

  function ExceptionDatepicker() {}
  var tempDatevalue = null;
  ExceptionDatepicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "date");
    //this.eInput.value = params.value;

    //console.log(params.value);
    if (params.value != "") {
      this.eInput.value = params.value;
      if (this.eInput.value == "") {
        this.eInput.value = params.value.substr(0, 10);
      }
    }

    tempDatevalue = params.value;
    this.eInput.classList.add("input");
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
    /* $(this.eInput).ExceptionDatepicker({
      constrainInput: true, // prevent letters in the input field
      autoSize: true, // automatically resize the input field
      dateFormat: "dd/mm/yyyy",
      changeMonth: true,
      changeYear: true,
      yearRange: "1930:" + new Date().getFullYear(),
      clearButton: true,
    }); */
  };

  ExceptionDatepicker.prototype.getGui = function () {
    return this.eInput;
  };
  ExceptionDatepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  ExceptionDatepicker.prototype.getValue = function () {
    if (this.eInput.value.length > 0) {
      return this.eInput.value;
    } else {
      return tempDatevalue;
    }
  };
  ExceptionDatepicker.prototype.destroy = function () {};
  ExceptionDatepicker.prototype.isPopup = function () {
    return false;
  };
  return ExceptionDatepicker;
};
</script>

<style scoped lang="scss">
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font: normal 11px/22px "Open Sans", sans-serif;
  color: black;
  border: 1px solid #ccc;
}
.container-grid {
  margin-top: 0.1%;
  background-color: #fff;
  color: #444;
  /* Use flexbox */
  display: flex;
}

.line {
  width: 99.5%;
  height: 1%;
  border-bottom: 1px solid black;
  position: relative;
}
.box {
  margin-top: 0.1%;
  color: #fff;
  border-radius: 1px;
  padding: 15px;
  font-size: 150%;

  /* Use box-sizing so that element's outerwidth will match width property */
  box-sizing: border-box;

  /* Allow box to grow and shrink, and ensure they are all equally sized */
  flex: 1 1 auto;
}

.handler {
  margin-top: 0.8%;
  position: relative;
  width: 1px;
  padding: 0;
  cursor: col-resize;
  flex: 0 0 auto;
}

.handler::before {
  margin-top: 0.8%;
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background: silver;
  margin: 0 auto;
}

.Datelegends {
  height: 1.5em;
  width: 1.5em;
  border: dotted 1px #000000;
  margin-left: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10%;
  // background-color: red;
}
table.controller td {
  color: black;
  font-size: small;
  padding: 12px;
  text-align: left;
}
.content {
  height: 14em;
  border: dotted 1px #000000;
}
div[disabled="disabled"] {
  pointer-events: none;
  opacity: 0.4;
}
</style>
