<template>
  <section class="columns">
    <div class="column jiveContainer">
      <div class="line"></div>
      <bryntum-scheduler-pro ref="scheduler" v-bind="schedulerProConfigLocal" />
      <bryntum-splitter />
      <bryntum-resource-histogram ref="histogram" v-bind="histogramConfig" />
    </div>
  </section>
</template>
<script>
// gantt and its config
import {
  BryntumGantt,
  BryntumSplitter,
  BryntumSchedulerPro,
  BryntumResourceHistogram,
} from "@bryntum/gantt-vue";
import {
  ProjectModel,
  DateTimeField,
  LocaleHelper,
  SvSE,
  DateHelper,
  TimeUnit,
  StringHelper,
} from "@bryntum/gantt";

import { mapMutations, mapGetters, mapState, mapActions } from "vuex";
import "./erpToolbar.js";
import JiveConnectTask from "../../assets/bryntumExtendedClasses/jiveConnectTask.js";
import JiveConnectDependency from "../../assets/bryntumExtendedClasses/jiveConnectDependency.js";
import JiveConnectResource from "../../assets/bryntumExtendedClasses/jiveConnectResource.js";
//import DateHelper from '../../Core/helper/DateHelper.js';
// App
export default {
  name: "EnterpriseResourceAssignment",
  components: {
    BryntumSchedulerPro,
    BryntumSplitter,
    BryntumResourceHistogram,
  },
  data() {
    return {
      project: null,
      schedulerProConfigLocal: {
        minHeight: "20em",
        flex: 1,
        rowHeight: 45,
        eventColor: "gantt-green",
        useInitialAnimation: false,
        dependenciesFeature: true,
        percentBarFeature: true,
        //infiniteScroll: true,
        columns: [
          {
            type: "resourceInfo",
            field: "name",
            text: "Resource",
            showEventCount: true,
            width: 330,
            renderer({ row, value }) {
              // Color only odd rows
              row.eachElement(
                (el) =>
                  (el.style.background =
                    row.index % 2 === 0 ? "#e0f0f6" : "#ffffff")
              );

              return value;
            },
          },
          {
            field: "description",
            text: "Description",
            showEventCount: false,
            width: 430,
            // renderer({ row, value }) {
            //   // Color only odd rows
            //   row.eachElement(
            //     el =>
            //       (el.style.background =
            //         row.index % 2 === 0 ? "#e0f0f6" : "#ffffff")
            //   );

            //return value;
            //},
          },
          {
            text: "Assigned ?",
            field: "events.length",
            width: 120,
            editor: false,
            align: "right",
            renderer: ({ value }) =>
              `${value >= 1 ? "Assigned" : "Unassigned"}`,
          },
          {
            text: "Assigned tasks",
            field: "events.length",
            width: 120,
            editor: false,
            //filter: true,
            align: "right",
            renderer: ({ value }) => `${value} task${value !== 1 ? "s" : ""}`,
          },
          {
            text: "Assigned work days",
            width: 160,
            editor: false,
            align: "right",
            renderer: ({ record }) =>
              record.events
                .map((task) => task.duration)
                .reduce((total, current) => {
                  return total + current;
                }, 0) + " days",
          },
        ],
        features: {
          eventTooltip: {
            // Tooltip configs can be used here
            align: "l-r", // Align left to right,
            // A custom HTML template
            template: (data) => `<dl>
            <dt @click="routeToGantt()">${
              data.eventRecord.scheduleHeaderCode
            } - ${data.eventRecord.scheduleHeaderDescription}</dt>
             <dd>
                 ${
                   data.eventRecord.name
                     ? StringHelper.xss`<div class="b-sch-event-title">${data.eventRecord.name}</div>`
                     : ""
                 }
                ${data.startClockHtml}
                ${data.endClockHtml}
                
                 
             </dd>
         </dl>`,
          },
          eventMenu: {
            items: {
              // Custom reference to the new menu item
              goToSchedule: {
                text: "Go to Schedule",
                cls: "b-separator", // Add a visual line above the item
                weight: 400, // Add the item to the bottom
                onItem: ({ eventRecord }) => {
                  console.log(
                    "Setting Id to : " + eventRecord._data.scheduleHeaderId
                  );
                  this.setSelectedEntity(eventRecord._data.scheduleHeaderId);
                  this.$router.push("/gantt");
                },
              },
            },
          },
        },
        tbar: {
          style:
            "background-color:#ffffff; width: 94.8vw; margin-bottom:0.3%;  border-bottom:2px  solid black;",
          type: "erptoolbar",
        },
      },
      histogramConfig: {
        showBarTip: true,
        showMaxEffort: false,
        getRectClass: function (series, rectConfig, datum, index) {
          if (series.id === "effort") {
            const resourceHistogram = this.owner;
            const me = resourceHistogram,
              { showBarTip, timeAxis } = me;
            const unit = resourceHistogram.getBarTipEffortUnit(...arguments),
              allocated = me.getEffortText(datum.effort, unit),
              available = me.getEffortText(
                datum.maxEffort * datum.resource._data.capacity,
                unit
              );
            //datum.maxEffort = datum.maxEffort * datum.resource._data.capacity;
            datum.isOverallocated =
              datum.effort > datum.maxEffort * datum.resource._data.capacity;
            datum.isUnderallocated =
              datum.effort < datum.maxEffort * datum.resource._data.capacity;

            switch (true) {
              case datum.isOverallocated:
                return "b-overallocated";

              case datum.isUnderallocated:
                return "b-underallocated";
            }
          }
          return "";
        },
        getBarTip: function (series, rectConfig, datum, index) {
          const resourceHistogram = this.owner;
          const me = resourceHistogram,
            { showBarTip, timeAxis } = me;

          let result = "";
          //console.log(datum);
          if (showBarTip && datum.effort) {
            var maxEffortCapacity =
              datum.maxEffort * datum.resource._data.capacity;
            const unit = resourceHistogram.getBarTipEffortUnit(...arguments),
              allocated = me.getEffortText(datum.effort, unit),
              available = me.getEffortText(maxEffortCapacity, unit),
              usage = maxEffortCapacity
                ? `${parseFloat(
                    ((datum.effort / maxEffortCapacity) * 100).toFixed(2)
                  )}%`
                : "Not available";
            let dateFormat = "L",
              resultFormat = me.L("L{barTipInRange}");
            datum.isOverallocated =
              datum.effort > datum.maxEffort * datum.resource._data.capacity;
            if (DateHelper.compareUnits(timeAxis.unit, TimeUnit.Day) == 0) {
              resultFormat = me.L("L{barTipOnDate}");
            } else if (
              DateHelper.compareUnits(timeAxis.unit, TimeUnit.Second) <= 0
            ) {
              dateFormat = "HH:mm:ss A";
            } else if (
              DateHelper.compareUnits(timeAxis.unit, TimeUnit.Hour) <= 0
            ) {
              dateFormat = "LT";
            }

            // TODO: we need smth like sprintf("has {0} of {1} items", cnt, total)
            // to be able to test localizable strings
            resultFormat += `<br>Usage: <span class="{cls}">${usage}</span>`;
            result = resultFormat
              .replace("{resource}", datum.resource.name)
              .replace(
                "{startDate}",
                DateHelper.format(datum.tick.startDate, "DD/MM/YYYY")
              )
              .replace(
                "{endDate}",
                DateHelper.format(datum.tick.endDate, "DD/MM/YYYY")
              )
              .replace("{allocated}", allocated)
              .replace("{available}", available)
              .replaceAll(
                "{cls}",
                datum.isOverallocated
                  ? "b-overallocated"
                  : datum.isUnderallocated
                  ? "b-underallocated"
                  : ""
              );

            result = `<div class="b-histogram-bar-tooltip">${result}</div>`;
          }

          return result;
        },
        columns: [
          {
            type: "resourceInfo",
            width: 200,
            field: "name",
            text: "Resource",
          },

          {
            width: 200,
            field: "description",
            text: "Resource Description",
          },

          {
            width: 200,
            field: "capacity",
            text: "Capacity",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters("activities", [
      "allEntities",
      "allEntitiesAsTree",
      "allRelations",
      "allResources",
      "allAssignments",
      "allCalendars",
      "allEntitiesCount",
      "entitiesLoaded",
      "enterpriseEntitiesLoaded",
      "enterpriseEntitiesUpdated",
      "entitiesUpdated",
    ]),
    ...mapGetters("schedulingDataManager", ["selectedEntitites"]),
    ...mapState({
      areEntitiesLoaded: (state) => state.activities.enterpriseEntitiesLoaded,
      areEntitiesUpdated: (state) => state.activities.enterpriseEntitiesUpdated,
    }),
    ...mapState("filterControl", ["filterWatcher"]),
  },
  watch: {
    filterWatcher() {
      if (this.filterWatcher > 1) {
        console.log("Masking Body");
        this.$refs.scheduler.instance.maskBody(
          "Updating resource assignments ..."
        );
        this.$refs.histogram.instance.maskBody(
          "Updating resource assignments ..."
        );
        this.loadEnterpriseActivities();
      }
    },
    areEntitiesLoaded() {
      if (this.enterpriseEntitiesLoaded) this.setProjectData();
    },
    areEntitiesUpdated() {
      console.log("areEntitiesUpdated Watch");
      if (this.enterpriseEntitiesUpdated) {
        const schedulerInstance = this.$refs.scheduler.instance;

        schedulerInstance.project.loadInlineData({
          eventsData: [...this.allEntitiesAsTree],
          dependenciesData: [...this.allRelations],
          resourcesData: [...this.allResources],
          assignmentsData: [...this.allAssignments],
          calendarsData: [...this.allCalendars],
        });
        //schedulerInstance.project.taskStore.sort("code", "ASC");

        //schedulerInstance.expandAll(true);
      }
    },
  },
  methods: {
    ...mapActions("schedulingDataManager", ["setSelectedEntity"]),
    ...mapActions("activities", [
      "loadEnterpriseActivities",
      "updateActivity",
      "updateActivities",
      "updateRelations",
      "addActivities",
      "saveUpdates",
    ]),
    setProjectData() {
      const schedulerInstance = this.$refs.scheduler.instance;

      console.log("this.allCalendars");
      console.log(this.allCalendars);
      schedulerInstance.project = new ProjectModel({
        taskModelClass: JiveConnectTask,
        dependencyModelClass: JiveConnectDependency,
        resourceModelClass: JiveConnectResource,
        //startDate: progressStartDate,
        endDate: new Date(),
        calendar: "general",
        hoursPerDay: 24,
        daysPerWeek: 7,
        weeksPerMonth: 4,
        //   this.selectedEntitites[0].calendar.daysPerMonth /
        //   this.selectedEntitites[0].calendar.daysPerWeek,
        tasksData: [...this.allEntitiesAsTree],
        dependenciesData: [...this.allRelations],
        resourcesData: [...this.allResources],
        assignmentsData: [...this.allAssignments],
        calendarsData: [...this.allCalendars],
        /*   calendarsData: [
          {
            id: 6,
            name: "My calendar",
            unspecifiedTimeIsWorking: false,
            intervals: [
              {
                id: 123,
                recurrentStartDate: "every weekday at 09:00",
                recurrentEndDate: "every weekday at 17:00",
                isWorking: true,
              },
            ],
          },
        ], */
        // stm: {
        //   autoRecord: true,
        // },
      });
      this.$refs.histogram.instance.project = schedulerInstance.project;
      this.$refs.histogram.instance.project.endDate = new Date();
      this.$refs.histogram.instance.scrollToDate(new Date());
      console.log("Unmasking Body");
      this.$refs.histogram.instance.unmaskBody();
      this.$refs.scheduler.instance.unmaskBody();
    },
    isValid(valueToCheck) {
      if (
        String(valueToCheck) != "" &&
        String(valueToCheck) != "null" &&
        String(valueToCheck) != "undefined"
      )
        return true;
      else {
        return false;
      }
    },
  },
  mounted() {
    this.$refs.scheduler.instance.maskBody("Updating resource assignments ...");
    this.$refs.histogram.instance.maskBody("Updating resource assignments ...");
    this.loadEnterpriseActivities();
    this.$refs.scheduler.instance.addPartner(this.$refs.histogram.instance);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/jiveStyles.scss";
// .b-grid-row.b-tree-parent-row .b-grid-cell {
//   font-weight: 900;
// }
// .b-tree-leaf-cell {
//   //font-weight: 500;
// }
// .b-cell-dirty {
//   color: green !important;
// }
// .b-grid-header {
//   text-transform: capitalize !important;
// }
// .b-widget {
//   font-size: 0.9em;
// }
// .b-tree-cell {
//   //font-weight: 900;
//   color: black !important;
// }
// .b-tree-cell.b-tree-parent-cell {
//   //font-weight: 900;
//   color: black !important;
// }
// .b-grid-cell {
//   //font-weight: 900;
//   color: black !important;
//   white-space: normal;
// }
// .b-grid-header-text {
//   font-weight: 600;
//   color: black !important;
// }
// .b-sch-header-text {
//   font-weight: 600;
//   color: black !important;
// }

// .b-status-column-cell {
//   font-size: 0.8em;
// }

// .b-status-column-cell i::before {
//   margin-right: 0.5em;
// }

// .Started {
//   color: #bbb;
// }

// .Late {
//   color: #ff5722;
// }

// .Completed {
//   color: #34d844;
// }

// .jiveContainer {
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
//     Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
//   font-size: 0.9em;
//   font-weight: 900;
//   color: black !important;

//   margin: 0;
//   position: absolute;
//   min-height: 0;
//   display: flex;
//   flex: 1 1 100%;
//   flex-direction: column;
//   // flex-flow: row nowrap;
//   align-items: stretch;
//   transform: translate(0, 0);
//   overflow: hidden;

//   margin-left: 0.15%;
//   //  overflow: auto;
//   background-color: white;
//   height: 94.5vh;
//   width: 97vw;
// }

// .line {
//   width: 100%;
//   height: 1%;
//   border-bottom: 1px solid black;
//   position: relative;
// }

// .b-gantt-task.b-task-selected {
//   background-color: #feac31;
// }
// .b-gantt-task {
//   background-color: #ffdd57;
//   border-color: #ffdd57;
// }
// .b-task-percent-bar {
//   background-color: #27ca37;
//   max-height: 50%;
// }
// .b-gantt-task-wrap.b-milestone-wrap
//   .b-gantt-task:not(.b-gantt-task-withicon)
//   .b-gantt-task-content {
//   background-color: #e37676;
// }
// .b-sch-style-plain.b-sch-color-gantt-green .b-sch-event:not(.b-milestone) {
//   background-color: #ffdd57;
//   border-color: #ffdd57;
// }
// .b-sch-style-plain.b-sch-color-gantt-green
//   .b-sch-event:not(.b-milestone).b-sch-event-selected,
// .b-sch-style-plain.b-sch-color-gantt-green
//   .b-sch-event:not(.b-milestone):hover {
//   background-color: #feac31;
// }
</style>
