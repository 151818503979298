<template>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-if="selectedDataType.id < 3"
  >
    <data-manager
      ref="masterDataGrid"
      :dataType="selectedDataType"
      v-if="selectedDataType != null"
    />
  </section>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedDataType.id == 3"
  >
    <cost-header-manager />
  </section>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedDataType.id == 4"
  >
    <schedulingDataView />
  </section>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedDataType.id == 5"
  >
    <EnterpriseResourceAssignment />
  </section>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedDataType.id == 6"
  >
    <PerformanceSheetManager />
  </section>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedDataType.id == 7"
  >
    <risk-manager />
  </section>
</template>

<script>
import DataManager from "@/components/Data/DataManager.vue";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import LoadScript from "vue-plugin-load-script";
import CostHeaderManager from "@/components/CostHeader/CostHeaderManager.vue";
import schedulingDataView from "../components/Scheduling/schedulingDataView.vue";
import EnterpriseResourceAssignment from "../components/enterpriseResourceAssignments/enterpriseResourceAssignment.vue";
import RiskManager from "@/components/Risks/RiskComponent.vue";
import PerformanceSheetManager from "@/components/PerformanceSheet/PerformanceSheetManager.vue";
export default {
  name: "MasterDataView",
  components: {
    DataManager,
    CostHeaderManager,
    schedulingDataView,
    EnterpriseResourceAssignment,
    RiskManager,
    PerformanceSheetManager,
  },
  watch: {
    selectedDataType() {
      Vue.use(LoadScript);
      var scriptToUse =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8080/drag-bar.js"
          : process.env.NODE_ENV === "Staging"
          ? "https://jiveconnect-staging.azurewebsites.net/drag-bar.js"
          : "https://app.jiveconnect.com.au/drag-bar.js";

      Vue.unloadScript(scriptToUse).catch((errormsg) => {
        if (errormsg != undefined) {
          console.error(errormsg);
        }
      });
      Vue.loadScript(scriptToUse).catch((error) => {
        console.error(error);
      });
    },
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("dataManager", ["selectedDataType"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted: function () {
    console.log("MasterDataView: $route.params.id", this.$route.params.id);
    if (
      !this.isValid(this.selectedDataType) ||
      this.selectedDataType.name != this.$route.params.id
    ) {
      console.log("Loading Data Type");
      this.setSelectedData(this.$route.params.id);
    }

    Vue.use(LoadScript);
    var scriptToUse =
      process.env.NODE_ENV === "development"
        ? "http://localhost:8080/drag-bar.js"
        : process.env.NODE_ENV === "Staging"
        ? "https://jiveconnect-staging.azurewebsites.net/drag-bar.js"
        : "https://app.jiveconnect.com.au/drag-bar.js";

    Vue.unloadScript(scriptToUse).catch((errormsg) => {
      if (errormsg != undefined) {
        console.error(errormsg);
      }
    });

    Vue.loadScript(scriptToUse).catch((error) => {
      console.error(error);
    });
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("dataManager", ["updateViewList", "setSelectedData"]),
    isValid(valueToCheck) {
      if (
        String(valueToCheck) != "" &&
        String(valueToCheck) != "null" &&
        String(valueToCheck) != "undefined"
      )
        return true;
      else {
        return false;
      }
    },
  },
};
</script>
