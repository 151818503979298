import VueJwtDecode from "vue-jwt-decode";

export function hasTokenExpired() {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user === null) {
    return true;
  }
  const { exp } = VueJwtDecode.decode(user.token);
  const now = Date.now() / 1000;
  let timeLeft = exp - now - 30;
  return timeLeft < 0;
}

export function hasRefreshTokenExpired() {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user === null || user.refresh_token === undefined) {
    return true;
  }
  const { exp } = VueJwtDecode.decode(user.refresh_token);
  const now = Date.now() / 1000;
  let timeLeft = exp - now - 30;
  return timeLeft < 0;
}

export function refreshHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.refresh_token) {
    return { Authorization: "Bearer " + user.refresh_token };
  } else {
    return null;
  }
}

export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token) {
    return { Authorization: "Bearer " + user.token };
  } else {
    return {};
  }
}

export function authHeaderWithResponseType(type) {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token) {
    return {
      Authorization: "Bearer " + user.token,
      responseType: type,
    };
  } else {
    return { responseType: type };
  }
}
