/* eslint-disable prettier/prettier */
<template>
  <div style="height: 100%; background-color: white">
    <div class="line"></div>
    <div class="columns" style="height: 100%; width: 100%">
      <div
        class="container-grid"
        style="width: 100%; height: 100%; overflow: auto"
      >
        <div class="box" style="height: 100%" ref="scriptContainerStructures">
          <StructureBarLeft />
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="single"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="false"
            :rowData="rowStructureData"
            :enableRangeSelection="true"
            :undoRedoCellEditing="true"
            :gridOptions="gridStructureOptions"
            :columnDefs="columnStructureDefs"
            :components="Structurecomponents"
            :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
            @cell-value-changed="onCellValueChangedStructure"
            @selection-changed="onSelectionChanged"
            @grid-ready="onGridReady"
            @sort-changed="onColumnChanged"
            @column-resized="onColumnChanged"
            @column-visible="onColumnChanged"
            @column-row-group-changed="onColumnChanged"
            @column-value-changed="onColumnChanged"
            @column-moved="onColumnChanged"
            @column-pinned="onColumnChanged"
          >
          </ag-grid-vue>
          <!--  :columnDefs="columnStructureDefs" -->
        </div>
        <div class="handler"></div>
        <div
          class="box"
          v-on:keyup="key"
          ref="scriptContainerStructuresDetails"
        >
          <StructureBarRight />
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="multiple"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            :enableRangeSelection="true"
            :columnDefs="DetailcolumnDefs"
            :rowData="rowDetailData"
            :gridOptions="gridDetailOptions"
            :treeData="true"
            :animateRows="true"
            :defaultColDef="defaultColDef"
            :groupDefaultExpanded="groupDefaultExpanded"
            :getDataPath="getDataPath"
            :autoGroupColumnDef="autoGroupColumnDef"
            :overlayLoadingTemplate="loadingTemplate"
            :overlayNoRowsTemplate="noRowsTemplate"
            :undoRedoCellEditing="true"
            :components="components"
            :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
            :detailCellRendererParams="detailCellRendererParams"
            @cell-value-changed="onCellValueChanged"
            @cellClicked="cellClicked"
            @row-drag-move="onRowDragMove"
            @row-drag-leave="onRowDragLeave"
            @row-drag-end="onRowDragEnd"
            @grid-ready="onStrDetailsGridReady"
            @sort-changed="onStrDetailsColumnChanged"
            @column-resized="onStrDetailsColumnChanged"
            @column-visible="onStrDetailsColumnChanged"
            @column-row-group-changed="onStrDetailsColumnChanged"
            @column-value-changed="onStrDetailsColumnChanged"
            @column-moved="onStrDetailsColumnChanged"
            @column-pinned="onStrDetailsColumnChanged"
          >
          </ag-grid-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { getStructureAll } from "@/api/networking.js";
import StructureBarRight from "./StructureToolbar.vue";
import StructureBarLeft from "./StructureToolbarLeft.vue";
import LevelIconCellRenderer from "@/components/Data/LevelIconCellRenderer.vue";
import * as utilitiesModule from "@/assets/utilities.js";
import { debounce } from "lodash";
import {
  getColumnDef,
  postColumnState,
  getColumnState,
} from "@/api/networking.js";
export default {
  name: "StructureManager",
  components: {
    AgGridVue,
    StructureBarRight,
    StructureBarLeft,
    LevelIconCellRenderer, // eslint-disable-line
  },
  watch: {
    SaveError() {
      this.$vToastify.setSettings(this.NormalSettings);
      this.$vToastify.error("Code Value is empty or duplicate!");
    },

    isDelete() {
      if (this.isDelete > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.gridDetailOptions.api.redrawRows();
        this.gridDetailOptions.api.refreshCells();
        this.$vToastify.success("Row Deleted Successfully!");
      }
    },

    isStrctureDelete() {
      if (this.isStrctureDelete > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.rowDetailData = [];
        this.gridStructureOptions.api.redrawRows();
        this.gridStructureOptions.api.refreshCells();
        this.$vToastify.success("Row Deleted Successfully!");
      }
    },

    dataWatcher() {
      this.$vToastify.setSettings(this.NormalSettings);
      this.loadStructureRows();
      if (this.getStructureIdvalue > -1) {
        this.gridDetailOptions.api.applyTransactionAsync({
          update: this.tempSaveData,
        });
        this.gridDetailOptions.api.redrawRows();
        this.gridDetailOptions.api.refreshCells();

        this.tempDetailData = [];
        this.gridDetailOptions.api.forEachNode((node) => {
          this.tempDetailData.push(node.data);
        });
        this.resetRequestUndo(false);
        this.resetRequestRedo(false);
        this.gridDetailOptions.api.clearFocusedCell();
        let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
        if (selectedNodes.length > 0) {
          this.gridDetailOptions.api.selectIndex(
            selectedNodes[0].rowIndex,
            false,
            false
          );
          this.gridDetailOptions.api.setFocusedCell(
            selectedNodes[0].rowIndex,
            "code"
          );
          this.setDeatilId({ selectedId: selectedNodes[0].key });
        }
        this.$vToastify.success("Data successfully saved!");
      } else {
        this.tempDetailData = [];
        this.rowDetailData = [];
        this.gridDetailOptions.api.redrawRows();
        this.gridDetailOptions.api.refreshCells();
      }
    },

    undoWatcher() {
      this.undo();
    },

    redoWatcher() {
      this.redo();
    },

    StrundoWatcher() {
      this.Strundo();
    },

    StrredoWatcher() {
      this.Strredo();
    },

    AddRecordCounter() {
      if (this.AddRecordCounter > 0) {
        if (this.getDetailIdvalue == null) {
          let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
          if (selectedNodes.length > 0) {
            this.setDeatilId({ selectedId: selectedNodes[0].key });
          }
        }
        this.i = this.i - 1;
        this.AddNewRecord(false);
      }
    },

    LoadCounter() {
      if (this.SelectId != null) {
        this.i = -1;
        this.rowDetailData = this.getDetailData;
        this.tempDetailData = Array.from(this.currentDetailData);
        this.resetRequestUndo(false);
        this.resetRequestRedo(false);
        this.gridDetailOptions.api.refreshCells();
        this.isStructuredetail = true;
        this.reloadDetailsColumnState();
      }
    },

    AddSubRecordCounter() {
      if (this.AddSubRecordCounter > 0) {
        if (this.getDetailIdvalue == null && this.tempSaveData.length > 0) {
          let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
          this.setDeatilId({ selectedId: selectedNodes[0].key });
        }
        this.i = this.i - 1;
        this.AddNewRecord(true);
      }
    },

    SaveCounter() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.SaveCounter > 0) {
        let newRecord = [],
          indexes = [],
          emptyRowCounter = 0;
        newRecord = new Set(
          this.tempDetailData.map((v) => v.code && v.rowUpdated == false)
        );
        newRecord = { ...newRecord };
        if (newRecord.size < this.tempDetailData.length) {
          indexes = _.countBy(this.tempDetailData, "code");
          newRecord = _.filter(this.tempDetailData, (x) => indexes[x.code] > 1);
          if (newRecord.length > 0) {
            newRecord.forEach((element) => {
              this.tempDetailData.map((obj, index) => {
                if (obj.id === element.id) {
                  this.tempDetailData[index].rowUpdated = "true";
                }
              });
            });
          }
        }
        newRecord = [];
        indexes = [];
        this.gridDetailOptions.api.refreshCells();
        this.gridDetailOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            if (node.data.code != null) {
              this.tempDetailData.filter((element) => {
                if (element.code === node.data.code) {
                  if (node.key != element.id) {
                    element.rowUpdated = "Duplicate";
                    node.data.rowUpdated = "Duplicate";
                    indexes.push(node.rowIndex);
                  }
                }
              });
              newRecord.push(node.data);
            } else {
              newRecord = [];
              indexes = [];
              emptyRowCounter++;
              return false;
            }
          }
        });

        this.gridStructureOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            if (node.data.code != null) {
              this.rowStructureData.filter((element) => {
                if (element.code === node.data.code) {
                  if (node.data.id != element.id) {
                    node.data.rowUpdated = "Duplicate";
                    indexes.push(node.rowIndex);
                  }
                }
              });
            } else {
              emptyRowCounter++;
              return false;
            }
          }
        });
        //console.log(indexes);
        this.gridDetailOptions.api.refreshCells();
        if (indexes.length > 0 || emptyRowCounter > 0) {
          this.gridDetailOptions.api.redrawRows();
          this.gridDetailOptions.api.refreshCells();
          this.gridStructureOptions.api.redrawRows();
          this.gridStructureOptions.api.refreshCells();
          this.$vToastify.error(
            "Code Value " + indexes.length + " is/are empty or duplicate!"
          );
          newRecord = [];
          indexes = [];
          emptyRowCounter = 0;
        } else {
          if (
            this.rowStructureData.findIndex((x) => x.code.length == 0) == -1
          ) {
            //console.log(newRecord);
            this.mutateRecord({ returnedNewData: newRecord });
            this.saveStructureData();
          } else {
            this.$vToastify.error("Structure Code Value is empty!");
          }
        }
      }
    },

    DeleteCounter() {
      if (this.DeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
              if (selectedNodes.length < 2) {
                var selectedData = this.gridDetailOptions.api.getSelectedRows();
                if (selectedData[0].hasRelations) {
                  this.$vToastify.setSettings(this.NormalSettings);
                  this.$vToastify.error("Selected Rows are Protected!");
                } else {
                  let TotalNodes = this.tempDetailData.filter((x) =>
                    x.path.includes(selectedData[0].id)
                  );
                  let deleteIds = [];
                  let indices = this.tempDetailData.reduce(
                    (r, v, i) =>
                      r.concat(v.path.includes(selectedData[0].id) ? i : []),
                    []
                  );

                  indices.forEach((x) => {
                    deleteIds.push(this.tempDetailData[x].id);
                    delete this.tempDetailData[x];
                  });
                  indices = deleteIds.filter((x) => x > 0);
                  if (indices.length > 0) {
                    this.DeleteStrDetails({ structureDetailId: indices });
                  }
                  this.gridDetailOptions.api.applyTransaction({
                    remove: TotalNodes,
                  });
                  TotalNodes = [];
                  deleteIds = [];
                }
              } else {
                let selectedData = selectedNodes.map((node) => node.data);
                selectedData = selectedData.filter(
                  (x) => x.hasRelations == false
                );

                if (selectedData.length > 0) {
                  let DataDelete = [],
                    deleteIds = [];
                  for (let i = 0; i < selectedData.length; i++) {
                    DataDelete.push(
                      this.tempDetailData.filter((x) =>
                        x.path.includes(selectedData[i].id)
                      )
                    );
                  }
                  DataDelete.forEach((element) => {
                    element.forEach((data) => {
                      selectedData.push(data);
                    });
                  });

                  DataDelete = _.uniqBy(selectedData, "id");
                  let Deletevalues = DataDelete.map((x) => x.id);
                  Deletevalues.forEach((element) => {
                    deleteIds.push(
                      this.tempDetailData.findIndex((x) => x.Id == element)
                    );
                  });

                  deleteIds.forEach((x) => {
                    delete this.tempDetailData[x];
                  });
                  let indices = Deletevalues.filter((x) => x > 0);
                  if (indices.length > 0) {
                    this.DeleteStrDetails({ structureDetailId: indices });
                  }
                  this.gridDetailOptions.api.applyTransaction({
                    remove: DataDelete,
                  });
                  deleteIds = null;
                  indices = null;
                  Deletevalues = null;
                  DataDelete = null;
                  selectedData = null;
                } else {
                  this.$vToastify.setSettings(this.NormalSettings);
                  this.$vToastify.error("Selected Rows are Protected!");
                }
              }
              if (
                this.gridDetailOptions.api.getModel().rowsToDisplay.length == 0
              ) {
                this.rowDetailData = [];
                this.tempDetailData = [];
                this.setDeatilId(null);
              }
            } else {
              this.$vToastify.setSettings(this.NormalSettings);
            }
          });
      }
    },

    StrDeleteCounter() {
      if (this.StrDeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              if (this.tempDetailData.length == 0) {
                let selectedNodes =
                  this.gridStructureOptions.api.getSelectedNodes();
                let selectedData = selectedNodes.map((node) => node.data);
                let deleteIds = [];
                let indices = [];
                selectedData.forEach((x) => {
                  deleteIds.push(x.id);
                });
                indices = deleteIds.filter((x) => x > 0);
                if (indices.length > 0) {
                  this.DeleteStructures({ structureId: indices });
                }
                this.gridStructureOptions.api.applyTransaction({
                  remove: selectedData,
                });
                indices = [];
                selectedNodes = [];
                deleteIds = [];
                selectedData = [];
              } else {
                this.$vToastify.setSettings(this.NormalSettings);
                this.$vToastify.error("Structures are not allowed to delete.");
              }
            }
          });
      }
    },

    AddCounter() {
      setTimeout(() => {
        this.SetFocus();
      }, 10);
    },
    currentParentColumDefCounter() {
      this.getParentColDefs.forEach((row) => {
        this.columnStructureDefs.push(row);
      });
      this.reloadColumnState();
    },
    currentChildColumDefCounter() {
      this.getChildColDefs.forEach((row) => {
        this.DetailcolumnDefs.push(row);
      });
      this.reloadDetailsColumnState();
    },
  },
  computed: {
    ...mapGetters("structureManager", [
      "getStructureColumns",
      "getDetailsColumns",
      "getStructureData",
      "getDetailData",
      "getStructureIdvalue",
      "getRedoWatcher",
      "getundoWatcher",
      "getStrRedoWatcher",
      "getStrUndoWatcher",
      "getDetailIdvalue",
      "getParentColDefs",
      "getChildColDefs",
    ]),
    ...mapState("structureManager", [
      "dataWatcher",
      "redoWatcher",
      "undoWatcher",
      "SaveError",
      "StrundoWatcher",
      "StrredoWatcher",
      "AddRecordCounter",
      "AddSubRecordCounter",
      "SaveCounter",
      "LoadCounter",
      "tempSaveData",
      "currentDetailData",
      "DeleteCounter",
      "isDelete",
      "isStrctureDelete",
      "StrDeleteCounter",
      "AddCounter",
      "currentParentColumDefCounter",
      "currentChildColumDefCounter",
    ]),
    newData: {
      get() {
        return this.getStructureData;
      },
      set(newData) {
        return newData;
      },
    },

    noRowsTemplate() {
      if (this.isStructuredetail) {
        return this.loadingTemplate;
      } else {
        return `<span>No Rows to Show</span>`;
      }
    },
  },
  data() {
    return {
      rowStructureData: null,
      columnStructureDefs: null,
      components: null,
      Structurecomponents: {
        datePicker: getDatePicker(),
        integerCellEditor: getIntegerCellEditor(),
        decimalCellEditor: getDecimalCellEditor(),
        lookupCellEditor: getLookupCellEditor(),
        StructureCheckBoxCellEditor: getStructureCheckBoxCellEditor(),
      },
      undoRedoCellEditingLimit: null,
      i: -1,
      gridStructureOptions: {
        // structurecolumnDefs: [],
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
        processDataFromClipboard: (params) => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          const lastIndex =
            this.gridStructureOptions.api.getModel().rowsToDisplay.length - 1;
          const focusedCell = this.gridStructureOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;
          if (focusedIndex + params.data.length - 1 > lastIndex) {
            const resultLastIndex = focusedIndex + (params.data.length - 1);
            const addRowCount = resultLastIndex - lastIndex;
            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }
            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];
            rowsToAdd.map((r) => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map((i) => {
                row[currColumn.colDef.field] = i;
                currColumn =
                  this.gridStructureOptions.columnApi.getDisplayedColAfter(
                    currColumn
                  );
              });
              row.extraColumnValues = [];
              row.rowUpdated = true;
              (row.id = -1), newRowData.push(row);
              this.rowStructureData.push(row);
            });

            this.gridStructureOptions.api.applyTransactionAsync({
              add: newRowData,
            });
            this.gridStructureOptions.api.redrawRows();
          }
          this.gridStructureOptions.api.refreshCells();
          return params.data;
        },
      },
      detailCellRendererParams: {
        refreshStrategy: "everything",
      },
      rowDetailData: null,
      DetailcolumnDefs: null,
      clickcell: false,
      defaultColDef: null,
      singleClickEdit: false,
      gridDetailOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
        processDataFromClipboard: (params) => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
          this.gridDetailOptions.api.forEachNode((node) => {
            if (node.data.parentId === selectedNodes[0].data.parentId) {
              node.setSelected(true);
              node.setExpanded(false);
            }
          });
          let selectedData = selectedNodes.map((node) => node.data);
          let selectedKey = selectedData[0].id;
          selectedData = selectedData[0].parentId;

          selectedNodes = this.tempDetailData;
          /*   this.gridDetailOptions.api.getModel().gridOptionsWrapper.gridOptions
              .rowData; */
          const indexes = selectedNodes
            .map((selectedNodes, i) =>
              selectedNodes.parentId === selectedData ? i : null
            )
            .filter((i) => i !== null);

          const focusedCell = this.gridDetailOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;
          const getParentId =
            this.gridDetailOptions.api.getSelectedNodes(focusedIndex);

          var tempId = getParentId.findIndex((x) => x.data.id == selectedKey);
          if (tempId + params.data.length - 1 > indexes.length - 1) {
            const addRowCount = params.data.length - (indexes.length - tempId);
            this.i--;
            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            var temp = null;
            if (getParentId[tempId].data.id < 0) {
              this.i = getParentId[tempId].data.id;
            }
            if (getParentId[tempId].data.path.length > 1) {
              temp = getParentId[tempId].data.path.length;
              temp = getParentId[tempId].data.path.slice(0, temp - 1);
            }
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }

            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];

            //rowsToAdd = rowsToAdd.filter(Boolean);
            rowsToAdd.map((r) => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map((i) => {
                row[currColumn.colDef.field] = i;
                currColumn =
                  this.gridDetailOptions.columnApi.getDisplayedColAfter(
                    currColumn
                  );
              });
              //console.log(r[0]);
              if (
                this.tempDetailData.filter((x) => x.code == row.code).length > 0
              ) {
                row.rowUpdated = "Duplicate";
              } else {
                row.rowUpdated = true;
              }
              //console.log(row.code);
              row.extraColumnValues = [];
              row.hasRelations = false;
              row.structureId = this.getStructureIdvalue;
              row.parentId = getParentId[tempId].data.parentId;
              row.id = this.i - 1;
              if (row.parentId != null) {
                if (getParentId[0].data.id < 0) {
                  row.path = temp.concat([row.id]); //[row.parentId, row.id];
                } else {
                  if (getParentId[0].data.path.length < 2) {
                    row.path = [row.parentId, row.id];
                  } else {
                    row.path = temp.concat([row.id]);
                  }
                }
              } else {
                row.path = [row.id];
              }
              newRowData.push(row);
              this.tempDetailData.push(row);
              this.i--;
            });
            this.gridDetailOptions.api.applyTransactionAsync({
              add: newRowData,
            });
            this.gridDetailOptions.api.redrawRows();
            this.gridDetailOptions.api.refreshCells();
          }
          var undoSize = this.gridDetailOptions.api.getCurrentUndoSize();
          if (undoSize == 0) {
            this.resetRequestUndo(true);
          }
          return params.data;
        },
      },
      isStructuredetail: false,
      getDataPath: null,
      autoGroupColumnDef: null,
      groupDefaultExpanded: null,
      SelectId: null,
      tempDetailData: [],
      loadingTemplate: `<span class="ag-overlay-loading-center">Data is loading...</span>`,

      deleteSettings: {
        withBackdrop: true,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "center-center",
      },
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
    };
  },
  methods: {
    ...mapMutations("structureManager", [
      "setStructureData",
      "setDetailData",
      "setStructureValue",
      "setSelectedRowObject",
      "requestUndo",
      "resetRequestUndo",
      "resetRequestRedo",
      "resetStrRequestRedo",
      "resetStrRequestUndo",
      "setDeatilId",
      "mutateAddcounter",
      "mutateRecord",
      "setcurrentStructureID",
    ]),

    ...mapActions("structureManager", [
      "saveStructureData",
      "LoadStructureDetails",
      "DeleteStrDetails",
      "DeleteStructures",
      "LoadStructureExtraColumn",
      "LoadStructureDetailExtraColumn",
    ]),

    loadStructureRows() {
      // this.loadColumnDefinitions();

      getStructureAll()
        .then((rowData) => {
          this.setStructureData({ returnedData: rowData.data });
          this.rowStructureData = rowData.data;
          this.setDeatilId(null);
        })
        .catch((error) => {
          console.error(error);
        });
      //this.reloadColumnState();
      this.gridStructureOptions.api.refreshCells();
    },

    loadColumnDefinitions() {
      this.columnDefs = this.getStructureColumns;
      if (this.rowDetailData != null) {
        this.gridStructureOptions.api.refreshCells();
      }
    },

    onCellValueChangedStructure(params) {
      if (params.column.colId == "code") {
        var CodeIndex = this.rowStructureData.filter(
          (x) => x.id != params.node.data.id
        );
        CodeIndex = CodeIndex.findIndex(
          (x) => x.code.toLowerCase() == params.newValue.toLowerCase()
        );
        if (CodeIndex > -1) {
          this.$vToastify.setSettings(this.NormalSettings);
          this.$vToastify.error("Duplicate code value detected!");
          params.node.data.code = params.newValue;
          params.node.data.rowUpdated = "Duplicate";
        } else {
          params.node.data.rowUpdated = true;
        }
      } else if (params.column.colId.match("et_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;

        tempIndex = params.node.data.extraColumnValues.findIndex(
          (y) => y.structureExtraColumnId == parseInt(colvalue[1])
        );
        params.node.data.rowUpdated = true;
        if (tempIndex < 0) {
          params.node.data.extraColumnValues.push({
            structureId: params.node.data.id,
            structureExtraColumnId: colvalue[1],
            value: params.newValue,
          });
        } else {
          params.node.data.extraColumnValues[tempIndex].value = params.newValue;
        }
      }
      params.node.data.rowUpdated = true;
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();
      if (undoSize == 0 && !params.node.data.rowUpdated) {
        this.resetStrRequestUndo(false);
        params.node.data.rowUpdated = false;
      } else {
        this.resetStrRequestUndo(true);
      }
      if (redoSize == 0) {
        this.resetStrRequestRedo(false);
      } else {
        this.resetStrRequestRedo(true);
        params.node.data.rowUpdated = false;
      }
      params.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    GridExapnd(subRecord) {
      this.gridDetailOptions.api.refreshCells();
      this.gridDetailOptions.api.clearFocusedCell();
      let tempRowIndex = 0;
      if (this.getDetailIdvalue != null) {
        this.gridDetailOptions.api.forEachNode((node) => {
          if (node.data.id == subRecord) {
            let thisParent = node.parent;
            while (thisParent) {
              thisParent.setExpanded(true);
              thisParent = thisParent.parent;
            }
            node.setSelected(true);
            node.setExpanded(true);
            tempRowIndex = node.rowIndex;
          }
        });
        this.gridDetailOptions.api.ensureIndexVisible(tempRowIndex);
        this.gridDetailOptions.api.selectIndex(tempRowIndex, false, false);
        this.gridDetailOptions.api.setFocusedCell(tempRowIndex, "code");
      }
    },

    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();
      let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
      if (selectedNodes.length > 0)
        this.setDeatilId({ selectedId: selectedNodes[0].key });
      if (params.column.colId == "code") {
        var CodeIndex = this.tempDetailData.filter(
          (x) => x.id != params.node.data.id
        );
        CodeIndex = CodeIndex.findIndex(
          (x) => x.code.toLowerCase() == params.newValue.toLowerCase()
        );
        if (CodeIndex > -1) {
          this.$vToastify.setSettings(this.NormalSettings);

          params.node.data.code = params.newValue;
          //this.tempDetailData[CodeIndex].rowUpdated = "Duplicate";
          params.node.data.rowUpdated = "Duplicate";
          this.gridDetailOptions.api.forEachNode((node) => {
            if (node.data.id === params.node.data.id) {
              let thisParent = node.parent;
              while (thisParent) {
                thisParent.setExpanded(true);
                thisParent = thisParent.parent;
              }
              node.setSelected(true);
              node.setExpanded(true);
            }
          });
          this.$vToastify.error("Duplicate code value detected!");
          params.api.refreshCells({ force: true, suppressFlash: true });
        }
      } else if (params.column.colId.match("et_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;

        tempIndex = params.node.data.extraColumnValues.findIndex(
          (y) => y.structureDetailExtraColumnId == parseInt(colvalue[1])
        );
        if (params.node.data.rowUpdated != "Duplicate") {
          params.node.data.rowUpdated = true;
        }
        //console.log(params.node.data.id);
        if (tempIndex < 0) {
          params.node.data.extraColumnValues.push({
            structureDetailsId: params.node.data.id,
            structureDetailExtraColumnId: colvalue[1],
            value: params.newValue,
          });
        } else {
          params.node.data.extraColumnValues[tempIndex].value = params.newValue;
        }
        //console.log(params.node.data.extraColumnValues);
      } else {
        if (params.node.data.rowUpdated != "Duplicate") {
          params.node.data.rowUpdated = true;
        }
        params.api.refreshCells({ force: true, suppressFlash: true });
      }
      if (undoSize == 0 && !params.node.data.rowUpdated) {
        this.resetRequestUndo(false);
        params.node.data.rowUpdated = false;
      } else {
        this.resetRequestUndo(true);
      }

      if (redoSize == 0) {
        this.resetRequestRedo(false);
      } else {
        this.resetRequestRedo(true);
        params.node.data.rowUpdated = false;
      }
      //console.log(params.node.data.rowUpdated);
      params.api.refreshCells({ force: true, suppressFlash: true });
    },

    onSelectionChanged(event) {
      this.setSelectedRowObject(0);
      var selectedRowObjs = event.api.getSelectedNodes();
      if (selectedRowObjs.length > 0) {
        this.SelectId = selectedRowObjs[0].data.id;
        this.isStructuredetail = false;
        if (this.SelectId == -1) {
          this.gridDetailOptions.api.setRowData([]);
          this.rowDetailData = [];
          this.tempDetailData = [];
          this.gridDetailOptions.api.redrawRows();
          this.gridDetailOptions.api.refreshCells();
          this.setStructureValue(this.selectedId);
          this.setDetailData({ returnedData: null });
          this.isStructuredetail = false;
        } else {
          this.tempDetailData = [];
          this.rowDetailData = [];
          this.setStructureValue(this.SelectId);
          this.LoadStructureDetails(this.SelectId);
        }
      } else {
        this.tempDetailData = [];
        this.rowDetailData = [];
      }
      this.$vToastify.setSettings(this.NormalSettings);
    },

    onGridReady() {
      //  this.loadStructureRows();
      getColumnDef(13)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerStructures;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);

          //this.gridStructureOptions.api.setColumnDefs(container);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time

          this.LoadStructureExtraColumn().then(() => {
            this.loadStructureRows();
          });

          //  this.LoadParentExtraColumn();
          // this.LoadParentData();
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    reloadColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 13)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridStructureOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnChanged: debounce(function () {
      this.onColumnStateSubmit();
    }, 300),

    onColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridStructureOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 13,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },

    onStrDetailsGridReady() {
      // this.loadStructureRows();
      getColumnDef(23)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerStructuresDetails;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .then(() => {
          this.LoadStructureDetailExtraColumn();
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    onStrDetailsColumnChanged: debounce(function () {
      this.onColumnDetailsStateSubmit();
    }, 300),

    reloadDetailsColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 23)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridDetailOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnDetailsStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridDetailOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 23,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },
    SetFocus() {
      let tempRowIndex = 0;
      this.gridStructureOptions.api.clearFocusedCell();
      tempRowIndex = this.gridStructureOptions.api.getLastDisplayedRow();
      this.gridStructureOptions.api.selectIndex(tempRowIndex, false, false);
      this.gridStructureOptions.api.setFocusedCell(tempRowIndex, "code");
      this.gridStructureOptions.api.refreshCells();
    },

    undo() {
      this.gridDetailOptions.api.undoCellEditing();
    },

    redo() {
      this.gridDetailOptions.api.redoCellEditing();
    },

    Strundo() {
      this.gridStructureOptions.api.undoCellEditing();
    },

    Strredo() {
      this.gridStructureOptions.api.redoCellEditing();
    },

    AddNewRecord(subRecord) {
      if (this.getDetailIdvalue != null) {
        let tempDataIndex = 0;
        this.gridDetailOptions.api.refreshCells();
        const focusedCell = this.gridStructureOptions.api.getFocusedCell();
        const focusedIndex = focusedCell.rowIndex;
        const getParentId =
          this.gridDetailOptions.api.getSelectedNodes(focusedIndex);
        var tempId = getParentId.findIndex(
          (x) => x.data.id == this.getDetailIdvalue.selectedId
        );
        let rowsToAdd = [];
        var temp = null;
        var params = {
          force: false,
          suppressFlash: true,
        };
        if (getParentId[tempId].data.id < 0) {
          this.i = getParentId[tempId].data.id;
        }
        if (getParentId[tempId].data.path.length > 1) {
          temp = getParentId[tempId].data.path.length;
          temp = getParentId[tempId].data.path.slice(0, temp - 1);
        }
        if (
          getParentId[tempId].allChildrenCount == null &&
          getParentId[tempId].uiLevel == 0
        ) {
          params.force = true;
        }
        this.i = this.i - this.tempDetailData.length;
        rowsToAdd.push(1);
        rowsToAdd = rowsToAdd.reverse();
        let newRowData = [];
        rowsToAdd.map(() => {
          let row = {};
          row.rowUpdated = true;
          if (subRecord) {
            row.parentId = getParentId[tempId].data.id;
          } else {
            row.parentId = getParentId[tempId].data.parentId;
          }
          row.id = this.i - 1;
          if (row.path == undefined) {
            row.path = [];
          }
          if (row.parentId != null) {
            if (getParentId[0].data.id < 0) {
              if (subRecord) {
                if (temp != null) {
                  row.path = temp.concat([row.parentId], [row.id]);
                } else {
                  row.path = [row.parentId, row.id];
                }
              } else {
                row.path = temp.concat([row.id]); //[row.parentId, row.id];
              }
            } else {
              if (getParentId[0].data.path.length < 2) {
                row.path = [row.parentId, row.id];
              } else {
                if (subRecord) {
                  if (temp != null) {
                    row.path = temp.concat([row.parentId], [row.id]);
                  } else {
                    row.path = [row.parentId, row.id];
                  }
                  // row.path = temp.concat([row.parentId], [row.id]);
                } else {
                  row.path = temp.concat([row.id]);
                }
              }
            }
          } else {
            row.path = [row.id];
          }
          tempDataIndex = row.path[row.path.length - 1];
          row.structureId = this.getStructureIdvalue;
          row.description = null;
          row.code = null;
          row.extraColumnValues = [];
          row.hasRelations = false;
          row.description = null;
          row.isLabour = false;
          this.i = this.i - 2;
          newRowData.push(row);
          this.tempDetailData.push(row);
        });
        this.gridDetailOptions.api.applyTransaction({ add: newRowData });
        this.gridStructureOptions.api.redrawRows();
        this.gridDetailOptions.api.refreshCells(params);
        //params.api.refreshCells();
        this.GridExapnd(tempDataIndex);
        let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
        this.setDeatilId({ selectedId: selectedNodes[0].key });
      }
      if (this.gridDetailOptions.api.getModel().rowsToDisplay.length == 0) {
        let rowsToAdd = [];
        rowsToAdd.push(1);
        rowsToAdd = rowsToAdd.reverse();
        let newRowData = [];
        rowsToAdd.map(() => {
          let row = {};
          row.rowUpdated = true;
          row.parentId = null;
          row.id = this.i - 1;
          row.path = [row.id];
          row.structureId = this.getStructureIdvalue;
          row.description = null;
          row.code = null;
          row.description = null;
          row.isLabour = false;
          row.hasRelations = false;
          row.extraColumnValues = [];
          this.i = this.i - 2;
          newRowData.push(row);
          this.tempDetailData.push(row);
        });
        this.gridDetailOptions.api.applyTransactionAsync({ add: newRowData });
        this.gridStructureOptions.api.redrawRows();
        this.gridDetailOptions.api.refreshCells();
        this.setDeatilId({ selectedId: newRowData.id });
        this.gridDetailOptions.api.selectIndex(0, false, false);
        this.gridDetailOptions.api.setFocusedCell(0, "code");
      }
    },

    cellClicked(params) {
      params.api.refreshCells();
      this.setSelectedRowObject(params.node.data);
      let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
      this.setDeatilId({ selectedId: selectedNodes[0].key });

      if (params.column.colId == "ag-Grid-AutoColumn") {
        this.gridDetailOptions.api.forEachNode((node) => {
          if (
            node.data.path[node.data.path.length - 1] === selectedNodes[0].key
          ) {
            let thisParent = node.parent;
            while (thisParent) {
              thisParent.setExpanded(true);
              thisParent = thisParent.parent;
            }
            node.setSelected(true);
            node.setExpanded(true);
          }
        });
        this.gridDetailOptions.api.clearFocusedCell();
      }

      this.gridDetailOptions.api.refreshCells();
    },

    onRowDragMove(event) {
      setPotentialParentForNode(event.api, event.overNode);
    },

    onRowDragLeave(event) {
      setPotentialParentForNode(event.api, null);
    },

    onRowDragEnd(event) {
      this.$vToastify.setSettings(this.NormalSettings);
      if (!potentialParent) {
        return;
      }
      var movingData = event.node.data;
      var newParentPath = potentialParent.data ? potentialParent.data.path : [];
      if (potentialParent.data.id > 0) {
        var needToChangeParent = !arePathsEqual(newParentPath, movingData.path);
        var invalidMode = isSelectionParentOfTarget(
          event.node,
          potentialParent
        );
        if (invalidMode) {
          this.$vToastify.error("invalid move!");
          console.error("invalid move");
        }
        if (needToChangeParent && !invalidMode) {
          var updatedRows = [];
          moveToPath(newParentPath, event.node, updatedRows);

          updatedRows.forEach((element) => {
            element.rowUpdated = true;
          });
          if (updatedRows[0].path.length > 1) {
            updatedRows[0].parentId =
              updatedRows[0].path[updatedRows[0].path.length - 2];
          } else {
            updatedRows[0].parentId = null;
          }
          this.gridDetailOptions.api.applyTransactionAsync({
            update: updatedRows,
          });

          this.gridDetailOptions.api.clearFocusedCell();
        }
      } else {
        this.$vToastify.error("invalid move!");
        console.error("invalid move");
      }
      setPotentialParentForNode(event.api, null);
    },

    key: function () {
      /* console.log(event);
      if (
        event.code == "KeyV" ||
        (event.keyCode == 86 && event.ctrlKey == 17)
      ) {
        this.singleClickEdit = false;
      } */
    },
  },
  beforeMount() {
    this.setcurrentStructureID();
    this.setSelectedRowObject(0);
    this.resetRequestUndo(false);
    this.resetRequestRedo(false);
    this.components = { structureCellRenderer: getStructureCellRenderer() };
    this.defaultColDef = Object.assign({}, this.defaultColDef, {
      width: 180,
    });
    this.autoGroupColumnDef = {
      headerName: "Structure",
      rowDrag: true,
      suppressPaste: true,
      //cellRenderer:"agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: "structureCellRenderer",
      },
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      width: 160,
    };
    this.getDataPath = (data) => {
      return data.path;
    };
    this.columnStructureDefs = [];
    this.DetailcolumnDefs = [];
  },

  mounted() {
    this.undoRedoCellEditingLimit = 15;
    this.loadColumnDefinitions();
    this.loadStructureRows();
    //  this.columnStructureDefs = this.getStructureColumns;
    //this.DetailcolumnDefs = this.getDetailsColumns;
    this.rowDetailData = 0;
  },
};

window.moveToPath = function moveToPath(newParentPath, node, allUpdatedNodes) {
  var oldPath = node.data.path;
  var fileName = oldPath[oldPath.length - 1];
  var newChildPath = newParentPath.slice();
  newChildPath.push(fileName);
  node.data.path = newChildPath;
  allUpdatedNodes.push(node.data);
  if (node.childrenAfterGroup) {
    node.childrenAfterGroup.forEach(function (childNode) {
      moveToPath(newChildPath, childNode, allUpdatedNodes);
    });
  }
};

window.isSelectionParentOfTarget = function isSelectionParentOfTarget(
  selectedNode,
  targetNode
) {
  var children = selectedNode.childrenAfterGroup;
  for (var i = 0; i < children.length; i++) {
    if (targetNode && children[i].key === targetNode.key) return true;
    isSelectionParentOfTarget(children[i], targetNode);
  }
  return false;
};

window.arePathsEqual = function arePathsEqual(path1, path2) {
  //console.log(path2, path2, path1);
  if (path1.length !== path2.length) {
    return false;
  }
  var equal = true;
  path1.forEach(function (item, index) {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
};

window.setPotentialParentForNode = function setPotentialParentForNode(
  api,
  overNode
) {
  var newPotentialParent;
  if (overNode) {
    newPotentialParent = overNode;
  } else {
    newPotentialParent = null;
  }
  var alreadySelected = potentialParent === newPotentialParent;
  if (alreadySelected) {
    return;
  }
  var rowsToRefresh = [];
  if (potentialParent) {
    rowsToRefresh.push(potentialParent);
  }
  if (newPotentialParent) {
    rowsToRefresh.push(newPotentialParent);
  }
  potentialParent = newPotentialParent;
  refreshRows(api, rowsToRefresh);
};

window.refreshRows = function refreshRows(api, rowsToRefresh) {
  var params = {
    rowNodes: rowsToRefresh,
    force: true,
  };
  api.refreshCells(params);
};

window.getStructureCellRenderer = function getStructureCellRenderer() {
  function StructureCellRenderer() {}
  StructureCellRenderer.prototype.init = function (params) {
    var tempDiv = document.createElement("div");

    //params.api.refreshCells();
    tempDiv.innerHTML =
      params.node.allChildrenCount == null && params.data.parentId == null
        ? '<img src="Icons/structures2.svg"; style="margin-top:5px; margin-left:28px; height:20px; width:20px;"/>'
        : '<img src="Icons/structures2.svg"; style="margin-top:5px; height:20px; width:20px;"/>';
    this.eGui = tempDiv.firstChild;
  };
  StructureCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return StructureCellRenderer;
};

window.getIntegerCellEditor = function getIntegerCellEditor() {
  function IntegerPicker() {}
  IntegerPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.setAttribute("step", "1");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };

  /*  IntegerPicker.prototype.isKeyPressedNavigation = function(event) {
    return event.keyCode !=110;
  }; */
  IntegerPicker.prototype.getGui = function () {
    return this.eInput;
  };
  IntegerPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  IntegerPicker.prototype.getValue = function () {
    return parseInt(this.eInput.value);
  };
  IntegerPicker.prototype.destroy = function () {};
  IntegerPicker.prototype.isPopup = function () {
    return false;
  };
  return IntegerPicker;
};

window.getDecimalCellEditor = function getDecimalCellEditor() {
  function DecimalPicker() {}
  DecimalPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };
  DecimalPicker.prototype.getGui = function () {
    return this.eInput;
  };
  DecimalPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  DecimalPicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  DecimalPicker.prototype.destroy = function () {};
  DecimalPicker.prototype.isPopup = function () {
    return false;
  };
  return DecimalPicker;
};

window.getLookupCellEditor = function getLookupCellEditor() {
  function Lookuppicker() {}
  Lookuppicker.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var CostModelTypeFilter = CostModelTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );
    CostModelTypeFilter.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  Lookuppicker.prototype.getGui = function () {
    return this.eInput;
  };
  Lookuppicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Lookuppicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Lookuppicker.prototype.destroy = function () {};
  Lookuppicker.prototype.isPopup = function () {
    return false;
  };
  return Lookuppicker;
};
window.getStructureCheckBoxCellEditor =
  function getStructureCheckBoxCellEditor() {
    // function to act as a class
    function BooleanEditor() {}

    // gets called once before the renderer is used
    BooleanEditor.prototype.init = function (params) {
      // create the cell
      var value = params.value;

      this.eInput = document.createElement("input");
      this.eInput.type = "checkbox";
      this.eInput.checked = value;
      this.eInput.value = value;
    };

    // gets called once when grid ready to insert the element
    BooleanEditor.prototype.getGui = function () {
      return this.eInput;
    };

    // focus and select can be done after the gui is attached
    BooleanEditor.prototype.afterGuiAttached = function () {
      this.eInput.focus();
      this.eInput.select();
    };

    // returns the new value after editing
    BooleanEditor.prototype.getValue = function () {
      return this.eInput.checked;
    };

    // any cleanup we need to be done here
    BooleanEditor.prototype.destroy = function () {
      // but this example is simple, no cleanup, we could
      // even leave this method out as it's optional
    };

    // if true, then this editor will appear in a popup
    BooleanEditor.prototype.isPopup = function () {
      // and we could leave this method out also, false is the default
      return false;
    };

    return BooleanEditor;
  };
/*
? params.data.hasRelations
          ? '<span><i class="fas fa-lock" style=" margin-left:28px;"></i>&ensp;<img src="Icons/structures2.svg" style="margin-top:5px; height:20px; width:20px;"/></span>'
          : '<span><i class="fas fa-lock-open"  style=" margin-left:28px;"></i>&ensp;<img src="Icons/structures2.svg" style="margin-top:5px;height:20px; width:20px;"/></span>'
        : params.data.hasRelations
        ? '<span><i class="fas fa-lock"></i>&ensp;<img src="Icons/structures2.svg" style="margin-top:5px; height:20px; width:20px;"/></span>'
        : '<span><i class="fas fa-lock-open">&ensp;</i><img src="Icons/structures2.svg" style="margin-top:5px; height:20px; width:20px;"/></span>';
 */

var potentialParent = null;
</script>

<style scoped lang="scss">
.container-grid {
  margin-top: 0.1%;
  background-color: #fff;
  color: #444;
  /* Use flexbox */
  display: flex;
}

.line {
  width: 99.5%;
  height: 1%;
  border-bottom: 1px solid black;
  position: relative;
}
.box {
  margin-top: 0.1%;
  color: #fff;
  border-radius: 1px;
  padding: 15px;
  font-size: 150%;

  /* Use box-sizing so that element's outerwidth will match width property */
  box-sizing: border-box;

  /* Allow box to grow and shrink, and ensure they are all equally sized */
  flex: 1 1 auto;
}

.handler {
  margin-top: 0.8%;
  position: relative;
  width: 1px;
  padding: 0;
  cursor: col-resize;
  flex: 0 0 auto;
}

.handler::before {
  margin-top: 0.8%;
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background: silver;
  margin: 0 auto;
}
</style>
