<template>
  <div style="height: 100%; background-color: white">
    <div class="line"></div>
    <div class="columns" style="height: 100%; width: 100%">
      <div class="container-grid" style="width: 100%; height: 100%">
        <div
          class="box"
          style="height: 100%"
          ref="scriptContainerperformanceHeader"
        >
          <performance-toolbar />
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="multiple"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            :enableRangeSelection="true"
            :undoRedoCellEditing="true"
            :autoGroupColumnDef="autoGroupColumnDef"
            :columnDefs="columnperformanceHeaderDefs"
            :rowData="rowParentData"
            :treeData="true"
            :getDataPath="getDataPath"
            :components="performanceHeadercomponents"
            :gridOptions="gridParentOptions"
            :defaultColDef="defaultHeaderColDef"
            :overlayLoadingTemplate="loadingTemplate"
            :overlayNoRowsTemplate="noRowsTemplate"
            :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
            :detailCellRendererParams="detailCellRendererParams"
            @cellClicked="cellClicked"
            @cell-value-changed="onCellValueChanged"
            @grid-ready="onGridReady"
            @sort-changed="onHeaderColumnChanged"
            @column-resized="onHeaderColumnChanged"
            @column-visible="onHeaderColumnChanged"
            @column-row-group-changed="onHeaderColumnChanged"
            @column-value-changed="onHeaderColumnChanged"
            @column-moved="onHeaderColumnChanged"
            @column-pinned="onHeaderColumnChanged"
          >
          </ag-grid-vue>
          <!--  :columnDefs="columnStructureDefs" -->
        </div>
        <div class="handler"></div>
        <div class="box" ref="scriptContainerperformanceItems">
          <performance-item-toolbar />

          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="multiple"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            :enableRangeSelection="true"
            :columnDefs="columnperformanceItemDefs"
            :rowData="rowperformanceItemData"
            :gridOptions="gridperformanceItemOptions"
            :animateRows="true"
            :treeData="true"
            :getDataPath="getDataPath"
            :defaultColDef="defaultColDef"
            :autoGroupColumnDef="autoItemGroupColumnDef"
            :groupDefaultExpanded="groupDefaultExpanded"
            :overlayLoadingTemplate="loadingTemplate"
            :overlayNoRowsTemplate="noRowsTemplate"
            :undoRedoCellEditing="true"
            :components="performanceItemcomponents"
            :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
            :detailCellRendererParams="detailCellRendererParams"
            @cell-value-changed="onCellperformanceItemValueChanged"
            @cellClicked="cellperformanceItemClicked"
            @grid-ready="onperformanceItemGridReady"
            id="performanceItemGrid"
            @first-data-rendered="PerformanceheaderHeightSetter()"
            @column-resized="onItemColumnChanged"
            @sort-changed="onItemColumnChanged"
            @column-visible="onItemColumnChanged"
            @column-row-group-changed="onItemColumnChanged"
            @column-value-changed="onItemColumnChanged"
            @column-moved="onItemColumnChanged"
            @column-pinned="onItemColumnChanged"
          >
          </ag-grid-vue>
          <!-- 
           @grid-ready="onperformanceItemGridReady"
            @sort-changed="onperformanceItemColumnChanged"
            @column-resized="onperformanceItemColumnChanged"
            @column-visible="onperformanceItemColumnChanged"
            @column-row-group-changed="onperformanceItemColumnChanged"
            @column-value-changed="onperformanceItemColumnChanged"
            @column-moved="onperformanceItemColumnChanged"
            @column-pinned="onperformanceItemColumnChanged" -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import PerformanceToolbar from "./PerformanceHeaderToolbar.vue";
import * as moment from "moment";
import * as utilitiesModule from "@/assets/utilities.js";
import { debounce } from "lodash";
import PerformanceItemToolbar from "./PerformanceItemToolbar.vue";
import {
  getColumnDef,
  postColumnState,
  getColumnState,
} from "@/api/networking.js";
export default {
  name: "PerformanceSheetManager",
  components: {
    AgGridVue,
    PerformanceToolbar,
    PerformanceItemToolbar,
  },
  data() {
    return {
      defaultColDef: null,
      defaultHeaderColDef: null,
      autoGroupColumnDef: null,
      tempParentData: null,
      columnperformanceHeaderDefs: null,
      rowParentData: null,
      getDataPath: null,
      detailCellRendererParams: {
        refreshStrategy: "everything",
      },
      i: -1,
      gridParentOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
        getRowNodeId: (data) => data.uid,
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
      },
      groupDefaultExpanded: null,
      performanceHeadercomponents: {
        //performanceHeaderCellRenderer: getperformanceCellRenderer(),
        datePicker: getDatePicker(),
        integerCellEditor: getIntegerCellEditor(),
        decimalCellEditor: getDecimalCellEditor(),
        lookupCellEditor: getLookupCellEditor(),
        iconPerformanceHeaderCellRenderer: getperformanceHeaderCellRenderer(),
      },
      performanceItemcomponents: {
        PerformanceMetricLookupValue: getperformanceMetricPicker(),
        integerCellEditor: getIntegerCellEditor(),
        decimalCellEditor: getDecimalCellEditor(),
        lookupCellEditor: getLookupCellEditor(),
        PerformanceStatusLookupValue: getperformanceStatusPicker(),
        iconPerformancItemCellRenderer: getperformanceItemCellRenderer(),

        //performanceSeverityLookupValue: getperformanceSeverityLookupValue(),
      },
      undoRedoCellEditingLimit: 15,
      parentObj: null,
      singleClickEdit: false,
      invalidindex: [],
      loadingTemplate: `<span class="ag-overlay-loading-center">Data is loading...</span>`,

      columnperformanceItemDefs: null,
      rowperformanceItemData: null,
      gridperformanceItemOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
      },
      tempnewId: -1,
      tempperformanceItemData: null,
      deleteSettings: {
        withBackdrop: true,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "center-center",
      },
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
    };
  },
  watch: {
    LoadParentData() {
      this.rowParentData = this.getParentCurrentData;
      this.tempParentData = Array.from(this.getParentCurrentData);
      this.gridParentOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.PerformanceheaderHeightSetter();

      this.reloadHeaderColumnState();

      //this.Parentcomponents =
    },

    LoadChildrenData() {
      this.rowperformanceItemData = this.getChildrenCurrentData;
      this.tempperformanceItemData = Array.from(this.rowperformanceItemData);
      //SetCostModelTypes(this.getPerformanceMetricsData);
      this.gridperformanceItemOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      SetperformanceMetrics(this.getPerformanceMetricsData);

      SetperformanceStatus(this.getPerformanceStatusData);
      this.reloadItemColumnState();

      //this.Parentcomponents =
    },
    SaveError() {
      this.$vToastify.setSettings(this.NormalSettings);
      this.$vToastify.error("Invalid performance Resources Type!");
    },
    UndoCounter() {
      this.gridParentOptions.api.undoCellEditing();
    },

    RedoCounter() {
      this.gridParentOptions.api.redoCellEditing();
    },

    UndoChildrenCounter() {
      this.gridperformanceItemOptions.api.undoCellEditing();
    },

    RedoChildrenCounter() {
      this.gridperformanceItemOptions.api.redoCellEditing();
    },

    AddRecordCounter() {
      if (this.AddRecordCounter > 0) {
        this.tempnewId--;
        let newRow = {
          id: --this.tempnewId,
          code: "",
          description: "",
          rowUpdated: true,
          parentId: 1,
          path: [1, this.tempnewId],
          programs: null,
          portfolios: null,
          projects: null,
          extraColumnValues: [],
          uid: --this.tempnewId,
        };
        this.setParentObj(newRow);
        this.gridParentOptions.api.applyTransaction({
          add: [newRow],
        });
        this.tempParentData.push(newRow);
        let lastRowIndex = this.gridParentOptions.api.getLastDisplayedRow();
        this.gridParentOptions.api.redrawRows();
        this.gridParentOptions.api.ensureIndexVisible(lastRowIndex);
        this.gridParentOptions.api.setFocusedCell(this.tempnewId, "code");
        this.gridParentOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
      }
    },

    SaveCounter() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.SaveCounter > 0) {
        let newRecord = [],
          newParentData = [],
          indexes = [],
          emptyRowCounter = 0;
        this.gridParentOptions.api.refreshCells();
        this.gridParentOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            if (node.data.code != null) {
              this.tempParentData.filter((element) => {
                if (element.code === node.data.code && node.key != undefined) {
                  if (node.data.id != element.id) {
                    console.log(node.key, element.uid);
                    node.data.rowUpdated = "Duplicate";
                    indexes.push(node.rowIndex);
                  }
                }
              });
              newParentData.push(node.data);
            } else {
              newParentData = [];
              emptyRowCounter++;
              return false;
            }
          }
        });
        newRecord = [];

        this.gridperformanceItemOptions.api.refreshCells();
        this.gridperformanceItemOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            if (node.data.code != null) {
              /*  this.tempperformanceItemData.filter(element => {
                if (element.code === node.data.code && node.key != undefined) {
                  if (node.key != element.id) {
                    node.data.rowUpdated = "Duplicate";
                    indexes.push(node.rowIndex);
                  }
                }
              }); */
              newRecord.push(node.data);
            } else {
              newRecord = [];
              emptyRowCounter++;
              return false;
            }
          }
        });

        if (emptyRowCounter > 0 || indexes.length > 0) {
          this.gridParentOptions.api.redrawRows();
          this.gridParentOptions.api.refreshCells();
          newRecord = [];
          emptyRowCounter = 0;
          this.$vToastify.error("Code Value is empty or duplicate!");
        } else {
          this.mutateRecord({ returnedNewData: newRecord });
          this.mutateParentRecord({ returnedNewData: newParentData });
          this.saveData();
        }
      }
    },

    DeleteCounter() {
      if (this.DeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes = this.gridParentOptions.api.getSelectedNodes();

              let selectedData = selectedNodes.map((node) => node.data);
              if (selectedData.length > 0) {
                let DataDelete = [],
                  deleteIds = [];
                for (let i = 0; i < selectedData.length; i++) {
                  DataDelete.push(
                    this.tempParentData.filter((x) =>
                      x.path.includes(selectedData[i].id)
                    )
                  );
                }
                DataDelete.forEach((element) => {
                  element.forEach((data) => {
                    selectedData.push(data);
                  });
                });

                DataDelete = _.uniqBy(selectedData, "id");
                let Deletevalues = DataDelete.map((x) => x.id);
                Deletevalues.forEach((element) => {
                  deleteIds.push(
                    this.tempParentData.findIndex((x) => x.Id == element)
                  );
                });

                deleteIds.forEach((x) => {
                  delete this.tempParentData[x];
                });
                let indices = Deletevalues.filter((x) => x > 0);
                if (indices.length > 0) {
                  this.DeleteData({
                    APIName: "performanceHeader",
                    Id: indices,
                  });
                }
                this.gridParentOptions.api.applyTransaction({
                  remove: DataDelete,
                });
                deleteIds = null;
                indices = null;
                Deletevalues = null;
                DataDelete = null;
                selectedData = null;
              }
            } else {
              this.$vToastify.setSettings(this.NormalSettings);
            }
          });
      }
    },

    isDelete() {
      if (this.isDelete > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.gridParentOptions.api.redrawRows();
        this.gridParentOptions.api.refreshCells();

        this.gridperformanceItemOptions.api.redrawRows();
        this.gridperformanceItemOptions.api.refreshCells();
        this.$vToastify.success("Row Deleted Successfully!");
      }
    },

    DataWatcher() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.DataWatcher > -1) {
        this.tempParentData.forEach((row) => (row.rowUpdated = false));
        let tempdata = [];
        this.gridParentOptions.api.forEachNode((node) => {
          node.data.rowUpdated = false;
          if (node.data.id < 0) tempdata.push(node.data);
        });
        /*  console.log(tempdata);
        this.gridParentOptions.api.applyTransaction({
          update: tempdata,
        });

        this.gridParentOptions.api.applyTransaction({
          update: this.getCurrentTempDate,
        });
 */
        //  console.log(this.getCurrentTempDate);
        this.gridParentOptions.api.applyTransaction({
          remove: tempdata,
        });

        this.gridParentOptions.api.applyTransaction({
          add: this.getCurrentTempDate,
        });

        this.gridParentOptions.api.redrawRows();
        this.gridParentOptions.api.refreshCells();
        this.gridParentOptions.api.clearFocusedCell();
        let selectedNodes = this.gridParentOptions.api.getSelectedNodes();
        if (selectedNodes.length > 0) {
          this.gridParentOptions.api.selectIndex(
            selectedNodes[0].rowIndex,
            false,
            false
          );
          this.gridParentOptions.api.setFocusedCell(
            selectedNodes[0].rowIndex,
            "code"
          );
          this.setParentObj(selectedNodes[0].data);
        }

        this.gridperformanceItemOptions.api.applyTransactionAsync({
          update: this.tempSaveData,
        });
        this.gridperformanceItemOptions.api.redrawRows();
        this.gridperformanceItemOptions.api.refreshCells();

        this.tempperformanceItemData = [];
        this.gridperformanceItemOptions.api.forEachNode((node) => {
          this.tempperformanceItemData.push(node.data);
        });
        this.gridperformanceItemOptions.api.redrawRows();
        this.gridperformanceItemOptions.api.refreshCells();
        this.gridperformanceItemOptions.api.clearFocusedCell();
        selectedNodes = this.gridperformanceItemOptions.api.getSelectedNodes();
        if (selectedNodes.length > 0) {
          this.gridperformanceItemOptions.api.selectIndex(
            selectedNodes[0].rowIndex,
            false,
            false
          );
          this.gridperformanceItemOptions.api.setFocusedCell(
            selectedNodes[0].rowIndex,
            "code"
          );
          this.setChildrenObj(selectedNodes[0].data);
        }
        this.invalidindex = [];
        this.$vToastify.success("Data successfully saved!");
      } else {
        this.tempParentData = [];
        this.gridParentOptions.api.redrawRows();
        this.gridParentOptions.api.refreshCells();
      }
    },

    ColumnDefCounters() {
      // this.columnDefs = this.getParentGridColumn;
      this.getParentGridColumn.forEach((row) => {
        this.columnperformanceHeaderDefs.push(row);
      });
      this.reloadHeaderColumnState();
    },

    ColumnChildDefCounters() {
      // this.columnDefs = this.getParentGridColumn;
      this.getChildrenGridColumn.forEach((row) => {
        this.columnperformanceItemDefs.push(row);
      });
      this.reloadItemColumnState();
    },

    DeleteChildCounter() {
      if (this.DeleteChildCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes =
                this.gridperformanceItemOptions.api.getSelectedNodes();

              let selectedData = selectedNodes.map((node) => node.data);
              if (selectedData.length > 0) {
                let DataDelete = [],
                  deleteIds = [];
                for (let i = 0; i < selectedData.length; i++) {
                  DataDelete.push(
                    this.tempParentData.filter((x) =>
                      x.path.includes(selectedData[i].id)
                    )
                  );
                }
                DataDelete.forEach((element) => {
                  element.forEach((data) => {
                    selectedData.push(data);
                  });
                });

                DataDelete = _.uniqBy(selectedData, "id");
                let Deletevalues = DataDelete.map((x) => x.id);
                Deletevalues.forEach((element) => {
                  deleteIds.push(
                    this.tempParentData.findIndex((x) => x.Id == element)
                  );
                });

                deleteIds.forEach((x) => {
                  delete this.tempParentData[x];
                });
                let indices = Deletevalues.filter((x) => x > 0);
                if (indices.length > 0) {
                  this.DeleteData({ APIName: "performanceItem", Id: indices });
                }
                this.gridperformanceItemOptions.api.applyTransaction({
                  remove: DataDelete,
                });
                deleteIds = null;
                indices = null;
                Deletevalues = null;
                DataDelete = null;
                selectedData = null;
              }
            } else {
              this.$vToastify.setSettings(this.NormalSettings);
            }
          });
      }
    },

    SaveRelation() {
      let tempData = [];
      this.gridParentOptions.api.forEachNode((node) => {
        if (node.data != undefined) {
          if (
            node.data.id == this.getParentSelectedId &&
            node.data.parentId > 0
          ) {
            tempData.push(node.data);
          }
        }
      });
      this.gridParentOptions.api.applyTransaction({
        remove: tempData,
      });

      this.gridParentOptions.api.applyTransaction({
        add: this.getPerformanceHeaderRelation,
      });
      this.gridParentOptions.api.redrawRows();
      this.gridParentOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });

      this.tempperformanceItemData = [];
      //  let tempdata = [];
      this.gridParentOptions.api.forEachNode((node) => {
        // node.data.rowUpdated = false;
        this.tempperformanceItemData.push(node.data);
      });
    },

    PasteRowCounter() {
      if (this.getCopiedRows.length > 0) {
        this.getCopiedRows.forEach((row) => {
          this.tempperformanceItemData.push(row);
        });
      }
      this.gridperformanceItemOptions.api.applyTransaction({
        add: this.getCopiedRows,
      });

      this.GridExapnd(this.getCopiedRows[0].id, false);
    },

    AddCounter() {
      setTimeout(() => {
        this.SetFocus();
      }, 100);
    },

    AddSubRecordCounter() {
      if (this.AddSubRecordCounter > 0) {
        if (
          this.getChildrenSelectedId == null &&
          this.tempperformanceItemData.length > 0
        ) {
          let selectedNodes =
            this.gridperformanceItemOptions.api.getSelectedNodes();
          this.setChildrenObj(selectedNodes[0].data);
        }
        this.i = this.i - 1;
        this.AddNewRecord(true);
      }
    },

    AddChildrenRecordCounter() {
      if (this.AddChildrenRecordCounter > 0) {
        if (this.getChildrenSelectedId == null) {
          let selectedNodes =
            this.gridperformanceItemOptions.api.getSelectedNodes();
          if (selectedNodes.length > 0) {
            this.setChildrenObj(selectedNodes[0].data);
          }
        }
        this.i = this.i - 1;
        this.AddNewRecord(false);
      }
    },
  },

  computed: {
    /**
     * Load CostElements Controller Gettters, states
     */

    ...mapGetters("performanceSheetManager", [
      "getParentGridColumn",
      "getParentCurrentData",
      "getAllStructureDetailsvalue",
      "getCopiedRows",
      "getParentSelectedId",
      "getParentTempData",
      "getCalenderData",
      "getParentSelectedObj",
      "getResourceTypeData",
      "getChildrenGridColumn",
      "getChildrenCurrentData",
      "getPerformanceMetricsData",
      "getPerformanceStatusData",
      "getChildrenSelectedId",
      "getCurrentTempDate",
    ]),
    ...mapGetters("relationControl", ["getPerformanceHeaderRelation"]),

    /**performanceResourceController */

    ...mapState({
      colDefs: (state) => state.performanceSheetManager.currentParentColDef,
      ColumnDefCounters: (state) =>
        state.performanceSheetManager.currentParentGridColDefCountCounter,
      LoadParentData: (state) =>
        state.performanceSheetManager.currentParentLoadRecordCounter,
      UndoCounter: (state) =>
        state.performanceSheetManager.currentParentUndoWatcher,
      RedoCounter: (state) =>
        state.performanceSheetManager.currentParentRedoWatcher,
      PasteRowCounter: (state) => state.performanceSheetManager.duplicateRecord,
      AddRecordCounter: (state) =>
        state.performanceSheetManager.currentParentAddRecordCounter,
      DeleteCounter: (state) =>
        state.performanceSheetManager.currentParentDeleteRecordCounter,
      isDelete: (state) => state.performanceSheetManager.isDelete,
      SaveCounter: (state) =>
        state.performanceSheetManager.currentParentSaveRecordCounter,
      DataWatcher: (state) => state.performanceSheetManager.datawatcher,
      SaveError: (state) => state.performanceSheetManager.SaveError,

      LoadChildrenData: (state) =>
        state.performanceSheetManager.currentChildrenLoadRecordCounter,
      AddChildrenRecordCounter: (state) =>
        state.performanceSheetManager.currentChildrenAddRecordCounter,
      AddSubRecordCounter: (state) =>
        state.performanceSheetManager.currentChildrenSubRecordCounter,
      RedoChildrenCounter: (state) =>
        state.performanceSheetManager.currentChildrenRedoWatcher,
      UndoChildrenCounter: (state) =>
        state.performanceSheetManager.currentChildrenUndoWatcher,
      DeleteChildCounter: (state) =>
        state.performanceSheetManager.currentChildrenDeleteRecordCounter,
      SaveRelation: (state) =>
        state.relationControl.savePerformanceHeaderCounter,
      tempSaveData: (state) => state.relationControl.tempSaveData,
      ColumnChildDefCounters: (state) =>
        state.performanceSheetManager.currentChildrenGridColDefCountCounter,
    }),

    noRowsTemplate() {
      if (this.isElementdetail) {
        return this.loadingTemplate;
      } else {
        return `<span>No Rows to Show</span>`;
      }
    },
  },
  methods: {
    /**
     * Load CostElements Controller Actions, Mutation
     */

    ...mapActions("performanceSheetManager", [
      "loadperformanceHeader",
      "saveData",
      "DeleteData",
      "loadPerformanceItem",
      "loadperformanceMetric",
      "loadperformanceStatus",
      "LoadHeaderExtraColumnValue",
      "LoadItemExtraColumnValue",
    ]),
    ...mapMutations("performanceSheetManager", [
      "setParentObj",
      "resetParentObj",
      "setSelectedParentRowObject",
      "mutateParentUndostate",
      "mutateParentRedostate",
      "mutateChildrenUndostate",
      "mutateChildrenRedostate",
      "mutateRecord",
      "setChildrenObj",
      "mutateParentRecord",
    ]),

    SetFocus() {
      let tempRowIndex = 0;
      this.gridperformanceItemOptions.api.forEachNode((node) => {
        if (node.data.id < 0) {
          node.setSelected(true);
          tempRowIndex = node.rowIndex;
        }
      });
      this.gridperformanceItemOptions.api.ensureIndexVisible(tempRowIndex);
      this.gridperformanceItemOptions.api.setFocusedCell(tempRowIndex, "code");
      //this.gridCostHeaderOptions.api.selectIndex(tempRowIndex, false, false);

      this.gridperformanceItemOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    cellClicked(params) {
      this.rowperformanceItemData = [];
      this.tempperformanceItemData = [];
      if (params.node.data.id > 0 && params.node.data.parentId > 0) {
        this.setParentObj(params.node.data);
        this.loadPerformanceItem({
          performanceHeaderId: params.node.data.id,
        });
      } else if (
        params.column.colId == "ag-Grid-AutoColumn" &&
        params.node.data.parentId > 0
      ) {
        this.setParentObj(params.node.data);
      } else if (params.node.data == null) {
        this.rowperformanceItemData = [];
        this.tempperformanceItemData = [];
        // this.resetParentObj(null);
      }
      //let selectedNodes = params.node;
      params.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    cellperformanceItemClicked(params) {
      this.setChildrenObj(params.node.data);
      //let selectedNodes = params.node;
      params.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    onCellperformanceItemValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();
      if (params.node.data) {
        let selectedNodes = params.node.data;
        if (selectedNodes.length > 0) {
          this.setParentObj(params.node.data);
        }
      }

      if (params.column.colId.match("code")) {
        let tempvalue = this.getPerformanceMetricsData.find(
          (x) => x.description == params.newValue
        );

        params.data.code = tempvalue.code;
        params.newValue = params.data.code;
        params.data.description = tempvalue.description;

        params.data.performanceMetricId = tempvalue.id;
      } else if (params.column.colId.match("description")) {
        let tempvalue = this.getPerformanceMetricsData.find(
          (x) => x.code == params.newValue
        );
        params.data.code = tempvalue.code;
        params.newValue = params.data.description;
        params.data.description = tempvalue.description;

        params.data.performanceMetricId = tempvalue.id;
      } else if (params.column.colId.match("et_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;

        tempIndex = params.node.data.extraColumnValues.findIndex(
          (y) => y.performanceItemExtraColumnId == parseInt(colvalue[1])
        );
        params.node.data.rowUpdated = true;
        if (tempIndex < 0) {
          params.node.data.extraColumnValues.push({
            performanceItemId: params.node.data.id,
            performanceItemExtraColumnId: colvalue[1],
            value: params.newValue,
          });
        } else {
          params.node.data.extraColumnValues[tempIndex].value = params.newValue;
        }
      }
      if (params.column.colId.match("performanceStatusId")) {
        if (params.newValue != "") {
          if (
            typeof parseInt(params.newValue) === "number" &&
            this.getPerformanceStatusData.find(
              (x) => x.id == params.newValue
            ) == undefined
          ) {
            params.data.performanceStatusId = params.newValue;
            params.data.tempperformanceStatusId =
              this.getPerformanceStatusData.find(
                (x) => x.description == params.newValue
              ).id;
            params.data.colour = this.getPerformanceStatusData.find(
              (x) => x.description == params.newValue
            ).colour;
          } else {
            params.data.tempperformanceStatusId = params.newValue;
            params.data.performanceStatusId =
              this.getPerformanceStatusData.find(
                (x) => x.id == params.newValue
              ).description;
            params.data.colour = this.getPerformanceStatusData.find(
              (x) => x.id == params.newValue
            ).colour;
          }
        } else {
          params.data.performanceStatusId = null;
          params.data.tempperformanceStatusId = null;
          params.data.colour = null;
        }
      }
      params.node.data.rowUpdated = true;
      // params.api.refreshCells({ force: true, suppressFlash: true });
      if (undoSize == 0) {
        this.mutateChildrenUndostate(false);
        params.node.data.rowUpdated = false;
      } else {
        this.mutateChildrenUndostate(true);
      }

      if (redoSize == 0) {
        this.mutateChildrenRedostate(false);
      } else {
        this.mutateChildrenRedostate(true);
        params.node.data.rowUpdated = false;
      }
      this.PerformanceheaderHeightSetter();
      params.api.refreshCells({ force: true, suppressFlash: true });
    },

    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();
      if (params.node.data) {
        let selectedNodes = params.node.data;
        if (selectedNodes.length > 0) {
          this.setParentObj(params.node.data);
        }
      }

      if (params.column.colId == "code") {
        var CodeIndex = this.tempParentData.filter(
          (x) => x.id != params.node.data.id
        );
        CodeIndex = CodeIndex.findIndex(
          (x) => x.code.toLowerCase() == params.newValue.toLowerCase()
        );
        if (CodeIndex > -1) {
          this.$vToastify.setSettings(this.NormalSettings);
          this.$vToastify.error("Duplicate code value detected!");
          params.node.data.code = params.newValue;
          params.node.data.rowUpdated = "Duplicate";
        } else {
          params.node.data.rowUpdated = true;
        }
      } else if (params.column.colId.match("et_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;

        tempIndex = params.node.data.extraColumnValues.findIndex(
          (y) => y.performanceHeaderExtraColumnId == parseInt(colvalue[1])
        );
        params.node.data.rowUpdated = true;
        if (tempIndex < 0) {
          params.node.data.extraColumnValues.push({
            performanceHeaderId: params.node.data.id,
            performanceHeaderExtraColumnId: colvalue[1],
            value: params.newValue,
          });
        } else {
          params.node.data.extraColumnValues[tempIndex].value = params.newValue;
        }
      } else {
        params.node.data.rowUpdated = true;
      }
      // params.api.refreshCells({ force: true, suppressFlash: true });
      if (undoSize == 0) {
        this.mutateParentUndostate(false);
        params.node.data.rowUpdated = false;
      } else {
        this.mutateParentUndostate(true);
      }

      if (redoSize == 0) {
        this.mutateParentRedostate(false);
      } else {
        this.mutateParentRedostate(true);
        params.node.data.rowUpdated = false;
      }
      params.api.refreshCells({ force: true, suppressFlash: true });
    },

    AddNewRecord(subRecord) {
      console.log(this.getChildrenSelectedId);
      if (this.getChildrenSelectedId != null) {
        let tempDataIndex = 0;
        this.gridperformanceItemOptions.api.refreshCells();
        const focusedCell =
          this.gridperformanceItemOptions.api.getFocusedCell();
        const focusedIndex = focusedCell.rowIndex;
        const getParentId =
          this.gridperformanceItemOptions.api.getSelectedNodes(focusedIndex);
        var tempId = getParentId.findIndex(
          (x) => x.data.id == this.getChildrenSelectedId
        );
        let rowsToAdd = [];
        var temp = null;
        var params = {
          force: false,
          suppressFlash: true,
        };
        if (getParentId[tempId].data.id < 0) {
          this.i = getParentId[tempId].data.id;
        }
        if (getParentId[tempId].data.path.length > 1) {
          temp = getParentId[tempId].data.path.length;
          temp = getParentId[tempId].data.path.slice(0, temp - 1);
        }
        if (
          getParentId[tempId].allChildrenCount == null &&
          getParentId[tempId].uiLevel == 0
        ) {
          params.force = true;
        }
        this.i = this.i - this.tempperformanceItemData.length;
        rowsToAdd.push(1);
        rowsToAdd = rowsToAdd.reverse();
        let newRowData = [];
        rowsToAdd.map(() => {
          let row = {};

          if (subRecord) {
            row.parentId = getParentId[tempId].data.id;
          } else {
            row.parentId = getParentId[tempId].data.parentId;
          }
          if (row.parentId == 0) {
            row.parentId = null;
          }

          row.id = this.i - 1;
          if (row.path == undefined) {
            row.path = [];
          }
          if (row.parentId != null) {
            if (getParentId[0].data.id < 0) {
              if (subRecord) {
                if (temp != null) {
                  row.path = temp.concat([row.parentId], [row.id]);
                } else {
                  row.path = [row.parentId, row.id];
                }
              } else {
                row.path = temp.concat([row.id]); //[row.parentId, row.id];
              }
            } else {
              if (getParentId[0].data.path.length < 2) {
                row.path = [row.parentId, row.id];
              } else {
                if (subRecord) {
                  if (temp != null) {
                    row.path = temp.concat([row.parentId], [row.id]);
                  } else {
                    row.path = [row.parentId, row.id];
                  }
                  // row.path = temp.concat([row.parentId], [row.id]);
                } else {
                  row.path = temp.concat([row.id]);
                }
              }
            }
          } else {
            row.path = [row.id];
          }
          tempDataIndex = row.path[row.path.length - 1];

          row.extraColumnValues = [];
          row.code = null;
          row.description = null;
          row.rowUpdated = true;
          row.performanceStatusId = null;
          row.performanceMetricId = null;
          row.resolutionPlan = null;
          row.performanceHeaderId = this.getParentSelectedId;

          this.i = this.i - 2;
          newRowData.push(row);
          console.log(row);

          this.tempperformanceItemData.push(row);
        });
        this.gridperformanceItemOptions.api.applyTransaction({
          add: newRowData,
        });
        this.gridperformanceItemOptions.api.refreshCells(params);
        this.GridExapnd(newRowData[0].id, true);
        let selectedNodes =
          this.gridperformanceItemOptions.api.getSelectedNodes();

        this.setChildrenObj(selectedNodes[0].data);
      }
      if (
        this.gridperformanceItemOptions.api.getModel().rowsToDisplay.length == 0
      ) {
        let rowsToAdd = [];

        rowsToAdd.push(1);
        rowsToAdd = rowsToAdd.reverse();
        let newRowData = [];
        rowsToAdd.map(() => {
          let row = {};
          row.rowUpdated = true;
          row.parentId = null;
          row.id = this.i - 1;
          row.path = [row.id];
          row.extraColumnValues = [];
          row.code = null;
          row.description = null;
          row.rowUpdated = true;
          row.performanceStatusId = null;
          row.performanceMetricId = null;
          row.resolutionPlan = null;
          row.performanceHeaderId = this.getParentSelectedId;
          //row.CostHeaderID = this.parentObj.id;
          this.i = this.i - 2;
          newRowData.push(row);
          this.tempperformanceItemData.push(row);
        });
        this.gridperformanceItemOptions.api.applyTransactionAsync({
          add: newRowData,
        });

        //  let selectedNodes = this.gridperformanceItemOptions.api.getSelectedNodes();
        this.gridperformanceItemOptions.api.refreshCells();
        this.setChildrenObj(newRowData);
        this.gridperformanceItemOptions.api.selectIndex(0, false, false);
        this.gridperformanceItemOptions.api.setFocusedCell(0, "code");
      }
    },

    GridExapnd(subRecord, expand) {
      this.gridperformanceItemOptions.api.clearFocusedCell();
      let tempRowIndex = 0;
      this.gridperformanceItemOptions.api.forEachNode((node) => {
        if (node.data.id == subRecord) {
          let thisParent = node.parent;
          while (thisParent && expand) {
            thisParent.setExpanded(true);
            thisParent = thisParent.parent;
          }
          node.setSelected(true);
          node.setExpanded(expand);
          tempRowIndex = node.rowIndex;
        }
      });
      this.gridperformanceItemOptions.api.ensureIndexVisible(tempRowIndex);
      this.gridperformanceItemOptions.api.setFocusedCell(tempRowIndex, "code");
      this.gridperformanceItemOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    onRowDragMove(event) {
      setperformancePotentialParentForNode(event.api, event.overNode);
    },

    onRowDragLeave(event) {
      setperformancePotentialParentForNode(event.api, null);
    },

    onRowDragEnd(event) {
      this.$vToastify.setSettings(this.NormalSettings);
      if (!potentialParent) {
        return;
      }
      var movingData = event.node.data;
      var newParentPath = potentialParent.data ? potentialParent.data.path : [];
      if (potentialParent.data.id > 0) {
        var needToChangeParent = !areperformancePathsEqual(
          newParentPath,
          movingData.path
        );
        var invalidMode = isSelectionperformanceParentOfTarget(
          event.node,
          potentialParent
        );
        if (invalidMode) {
          this.$vToastify.error("invalid move!");
          console.error("invalid move");
        }
        if (needToChangeParent && !invalidMode) {
          var updatedRows = [];
          moveperformanceToPath(newParentPath, event.node, updatedRows);

          updatedRows.forEach((element) => {
            element.rowUpdated = true;
          });
          if (updatedRows[0].path.length > 1) {
            updatedRows[0].parentId =
              updatedRows[0].path[updatedRows[0].path.length - 2];
          } else {
            updatedRows[0].parentId = null;
          }
          this.gridParentOptions.api.applyTransactionAsync({
            update: updatedRows,
          });

          this.gridParentOptions.api.clearFocusedCell();
        }
      } else {
        this.$vToastify.error("invalid move!");
        console.error("invalid move");
      }
      setperformancePotentialParentForNode(event.api, null);
    },

    onGridReady(params) {
      getColumnDef(36)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerperformanceHeader;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          this.LoadHeaderExtraColumnValue().then(() => {
            this.loadperformanceHeader();
          });
          //this.loadperformanceHeaderMetric();
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    onperformanceItemGridReady(params) {
      getColumnDef(37)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerperformanceItems;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          this.LoadItemExtraColumnValue();
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    reloadHeaderColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 36)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridParentOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },

    reloadItemColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 37)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridperformanceItemOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onHeaderColumnChanged: debounce(function () {
      this.onHeaderColumnStateSubmit();
    }, 300),

    onItemColumnChanged: debounce(function () {
      this.onItemColumnStateSubmit();
    }, 300),

    onHeaderColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      this.PerformanceheaderHeightSetter();
      var d = this.gridParentOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 36,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },

    onItemColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      this.PerformanceheaderHeightSetter();
      var d = this.gridperformanceItemOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 37,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },

    PerformanceheaderHeightSetter() {
      var padding = 20;
      var height = headerHeightGetter() + padding;
      this.gridperformanceItemOptions.api.setHeaderHeight(height);
      this.gridperformanceItemOptions.api.resetRowHeights();
    },
  },

  beforeMount() {
    /*Set Grid Tree Collumn */
    this.defaultColDef = Object.assign({}, this.defaultColDef, {
      //flex: 1,
      resizable: true,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          "  </div>" +
          "</div>",
      },
    });
    this.autoGroupColumnDef = {
      headerName: "",
      field: "id",
      rowDrag: true,
      suppressPaste: true,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: "iconPerformanceHeaderCellRenderer",
      },
      resizable: true,
      sortable: true,
      //filterParams: { excelMode: "windows" },
      //filter: "agSetColumnFilter",
      width: 160,
    };

    this.autoItemGroupColumnDef = {
      headerName: "",
      field: "id",
      rowDrag: true,
      suppressPaste: true,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: "iconPerformancItemCellRenderer",
      },
      resizable: true,
      sortable: true,
      //filterParams: { excelMode: "windows" },
      //filter: "agSetColumnFilter",
      width: 160,
    };
    this.getDataPath = (data) => {
      return data.path;
    };

    this.columnperformanceHeaderDefs = [];
    /**
     * Load Column Defination
     * Load All CostElement's Data
     * Set Basic Functions Here
     */

    this.columnperformanceItemDefs = [];
    this.rowperformanceItemData = [];
  },

  mounted() {
    //  this.columnperformanceHeaderDefs = this.getParentGridColumn;
    //this.columnperformanceItemDefs = this.getChildrenGridColumn;

    this.loadperformanceMetric();
    this.loadperformanceStatus();
    //
  },
};

window.moveperformanceToPath = function moveperformanceToPath(
  newParentPath,
  node,
  allUpdatedNodes
) {
  //console.log("adsadasdas");
  var oldPath = node.data.path;
  var fileName = oldPath[oldPath.length - 1];
  var newChildPath = newParentPath.slice();
  newChildPath.push(fileName);
  node.data.path = newChildPath;
  allUpdatedNodes.push(node.data);
  if (node.childrenAfterGroup) {
    node.childrenAfterGroup.forEach(function (childNode) {
      moveperformanceToPath(newChildPath, childNode, allUpdatedNodes);
    });
  }
};

window.isSelectionperformanceParentOfTarget =
  function isSelectionperformanceParentOfTarget(selectedNode, targetNode) {
    var children = selectedNode.childrenAfterGroup;
    for (var i = 0; i < children.length; i++) {
      if (targetNode && children[i].key === targetNode.key) return true;
      isSelectionperformanceParentOfTarget(children[i], targetNode);
    }
    return false;
  };

function headerHeightGetter() {
  var columnHeaderTexts = [
    ...document.querySelectorAll(".ag-header-cell-text"),
  ];
  var clientHeights = columnHeaderTexts.map(
    (headerText) => headerText.clientHeight
  );
  var tallestHeaderTextHeight = Math.max(...clientHeights);

  return tallestHeaderTextHeight;
}

window.areperformancePathsEqual = function areperformancePathsEqual(
  path1,
  path2
) {
  //console.log(path2, path2, path1);
  if (path1.length !== path2.length) {
    return false;
  }
  var equal = true;
  path1.forEach(function (item, index) {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
};

window.setperformancePotentialParentForNode =
  function setperformancePotentialParentForNode(api, overNode) {
    var newPotentialParent;
    if (overNode) {
      newPotentialParent = overNode;
    } else {
      newPotentialParent = null;
    }
    var alreadySelected = potentialParent === newPotentialParent;
    if (alreadySelected) {
      return;
    }
    var rowsToRefresh = [];
    if (potentialParent) {
      rowsToRefresh.push(potentialParent);
    }
    if (newPotentialParent) {
      rowsToRefresh.push(newPotentialParent);
    }
    potentialParent = newPotentialParent;
    refreshRows(api, rowsToRefresh);
  };

window.refreshRows = function refreshRows(api, rowsToRefresh) {
  var params = {
    rowNodes: rowsToRefresh,
    force: true,
  };
  api.refreshCells(params);
};

window.getperformanceItemCellRenderer =
  function getperformanceItemCellRenderer() {
    function performanceItemCellRenderer() {}
    performanceItemCellRenderer.prototype.init = function (params) {
      var tempDiv = document.createElement("div");
      tempDiv.innerHTML =
        '<img src="Icons/performance.svg"; style="margin-top:5px; height:20px; width:20px;"/>';
      this.eGui = tempDiv.firstChild;
    };
    performanceItemCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };
    return performanceItemCellRenderer;
  };
window.getperformanceHeaderCellRenderer =
  function getperformanceHeaderCellRenderer() {
    function performanceHeaderCellRenderer() {}
    performanceHeaderCellRenderer.prototype.init = function (params) {
      var tempDiv = document.createElement("div");
      tempDiv.innerHTML =
        params.node.data != undefined
          ? params.node.data.parentId > 0
            ? '<img src="Icons/performance.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
            : params.node.data.parentId == null && params.node.data.rowType == 2
            ? '<img src="Icons/projects2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
            : params.node.data.parentId == null && params.node.data.rowType == 1
            ? '<img src="Icons/programs2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
            : params.node.data.parentId == null && params.node.data.rowType == 0
            ? '<img src="Icons/portfolio2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
            : null
          : null;
      this.eGui = tempDiv.firstChild;
    };
    performanceHeaderCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };
    return performanceHeaderCellRenderer;
  };

var potentialParent = null;

window.getperformanceItemDatePicker = function getperformanceItemDatePicker() {
  function DatepickerperformanceItem() {}
  var tempDatevalue = null;
  DatepickerperformanceItem.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "date");
    if (params.value.length > 0 && params.value != null) {
      this.eInput.value = params.value.substr(0, 10);
    }
    tempDatevalue = params.value; // moment(this.getSelectedRowObject.endDate).toISOString().substr(0, 10);

    this.eInput.classList.add("input");
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
    /*  $(this.eInput).datepicker({
      constrainInput: true, // prevent letters in the input field
      autoSize: true, // automatically resize the input field
      dateFormat: "dd/mm/yyyy",
      changeMonth: true,
      changeYear: true,
      yearRange: "1930:" + new Date().getFullYear(),
    }); */
  };
  DatepickerperformanceItem.prototype.getGui = function () {
    return this.eInput;
  };
  DatepickerperformanceItem.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  DatepickerperformanceItem.prototype.getValue = function () {
    if (this.eInput.value.length > 0) {
      return moment(this.eInput.value).format("YYYY-MM-DD");
    } else {
      return tempDatevalue;
    }
  };
  DatepickerperformanceItem.prototype.destroy = function () {};
  DatepickerperformanceItem.prototype.isPopup = function () {
    return false;
  };
  return DatepickerperformanceItem;
};

window.getIntegerCellEditor = function getIntegerCellEditor() {
  function IntegerPicker() {}
  IntegerPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.setAttribute("step", "1");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };

  /*  IntegerPicker.prototype.isKeyPressedNavigation = function(event) {
    return event.keyCode !=110;
  }; */
  IntegerPicker.prototype.getGui = function () {
    return this.eInput;
  };
  IntegerPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  IntegerPicker.prototype.getValue = function () {
    return parseInt(this.eInput.value);
  };
  IntegerPicker.prototype.destroy = function () {};
  IntegerPicker.prototype.isPopup = function () {
    return false;
  };
  return IntegerPicker;
};

window.getDecimalCellEditor = function getDecimalCellEditor() {
  function DecimalPicker() {}
  DecimalPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };
  DecimalPicker.prototype.getGui = function () {
    return this.eInput;
  };
  DecimalPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  DecimalPicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  DecimalPicker.prototype.destroy = function () {};
  DecimalPicker.prototype.isPopup = function () {
    return false;
  };
  return DecimalPicker;
};

window.getLookupCellEditor = function getLookupCellEditor() {
  function Lookuppicker() {}
  Lookuppicker.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var CostModelTypeFilter = CostModelTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );
    CostModelTypeFilter.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  Lookuppicker.prototype.getGui = function () {
    return this.eInput;
  };
  Lookuppicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Lookuppicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Lookuppicker.prototype.destroy = function () {};
  Lookuppicker.prototype.isPopup = function () {
    return false;
  };
  return Lookuppicker;
};

var performanceMetric = null;

window.SetperformanceMetrics = function SetperformanceMetrics(data) {
  performanceMetric = data;
};

window.getperformanceMetricPicker = function getperformanceMetricPicker() {
  function CustomperformanceMetricCombobox() {}
  CustomperformanceMetricCombobox.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.setAttribute("id", "resourceType");
    var performanceMetricIDs = performanceMetric.sort((a, b) =>
      a.sortOrder > b.sortOrder ? 1 : -1
    );
    //console.log(performanceSeveritiesIDs);
    this.eOptionVal = document.createElement("option");
    //Statical set data in grid ComboBox
    // this.eInput.appendChild(this.eOptionVal);
    performanceMetricIDs.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      if (x.description == params.value) {
        this.eOptionVal.selected = true;
      }
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  CustomperformanceMetricCombobox.prototype.getGui = function () {
    return this.eGui;
  };

  CustomperformanceMetricCombobox.prototype.getGui = function () {
    return this.eInput;
  };
  CustomperformanceMetricCombobox.prototype.afterGuiAttached = function () {
    this.eInput.focus();
  };
  CustomperformanceMetricCombobox.prototype.getValue = function () {
    return this.eInput.value;
  };
  CustomperformanceMetricCombobox.prototype.destroy = function () {};
  CustomperformanceMetricCombobox.prototype.isPopup = function () {
    return false;
  };
  return CustomperformanceMetricCombobox;
};

var performanceStatus = null;

window.SetperformanceStatus = function SetperformanceStatus(data) {
  performanceStatus = data;
};
window.getperformanceStatusPicker = function getperformanceStatusPicker() {
  function CustomperformanceStatusPicker() {}
  CustomperformanceStatusPicker.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var performanceStatusIDs = performanceStatus.sort((a, b) =>
      a.sortOrder > b.sortOrder ? 1 : -1
    );
    //console.log(performanceSeveritiesIDs);
    this.eOptionVal = document.createElement("option");
    //Statical set data in grid ComboBox
    // this.eInput.appendChild(this.eOptionVal);
    performanceStatusIDs.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      if (x.description == params.value) {
        this.eOptionVal.selected = true;
      }
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  CustomperformanceStatusPicker.prototype.getGui = function () {
    return this.eInput;
  };
  CustomperformanceStatusPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
  };
  CustomperformanceStatusPicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  CustomperformanceStatusPicker.prototype.destroy = function () {};
  CustomperformanceStatusPicker.prototype.isPopup = function () {
    return false;
  };
  return CustomperformanceStatusPicker;
};
</script>

<style scoped lang="scss">
.container-grid {
  margin-top: 0.1%;
  background-color: #fff;
  color: #444;
  /* Use flexbox */
  display: flex;
}

.line {
  width: 99.5%;
  height: 1%;
  border-bottom: 1px solid black;
  position: relative;
}
.box {
  margin-top: 0.1%;
  color: #fff;
  border-radius: 1px;
  padding: 15px;
  font-size: 150%;

  /* Use box-sizing so that element's outerwidth will match width property */
  box-sizing: border-box;

  /* Allow box to grow and shrink, and ensure they are all equally sized */
  flex: 1 1 auto;
}

.handler {
  margin-top: 0.8%;
  position: relative;
  width: 1px;
  padding: 0;
  cursor: col-resize;
  flex: 0 0 auto;
}

.handler::before {
  margin-top: 0.8%;
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background: silver;
  margin: 0 auto;
}
</style>
