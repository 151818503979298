import { Column, ColumnStore } from "@bryntum/gantt";

export default class CodeColumn extends Column {
  static get $name() {
    return "CodeColumn";
  }
  static get type() {
    return "codecolumn";
  }
  static get isGanttColumn() {
    return true;
  }
  static get defaults() {
    return {
      // Set your default instance config properties here
      field: "code",
      text: "Code",
      id: "code",
      hidden: false,
      editor: true,
      width: 100,
      cellCls: "b-code-column-cell",
      htmlEncode: false,
    };
  }
}

ColumnStore.registerColumnType(CodeColumn, true);
