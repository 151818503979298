/* eslint-disable prettier/prettier */
<template>
  <div class="modal is-active is-overlay">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ this.getTitle }}</p>
        <button
          class="delete"
          aria-label="close"
          @click="toggleActivityFilterModal"
        ></button>
      </header>
      <section class="modal-card-body" style="margin-bottom: -10%">
        <div class="tabs is-centered is-boxed"></div>
        <div id="global-filter-section-content" class="section columns">
          <div class="field" style="width: 100%; overflow: auto">
            <div style="height: 100%">
              <ag-grid-vue
                class="ag-theme-balham ag-default-layout"
                rowSelection="single"
                stopEditingWhenGridLosesFocus="true"
                :enableRangeSelection="false"
                :columnDefs="DetailcolumnDefs"
                :rowData="rowDetailData"
                :gridOptions="gridDetailOptions"
                :treeData="true"
                :animateRows="true"
                :components="components"
                :defaultColDef="defaultColDef"
                :groupDefaultExpanded="groupDefaultExpanded"
                :getDataPath="getDataPath"
                :autoGroupColumnDef="autoGroupColumnDef"
                :detailCellRendererParams="detailCellRendererParams"
                @cellClicked="cellClicked"
              >
              </ag-grid-vue>
            </div>
          </div>
        </div>
        <div></div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="saveStructure">Apply</button>
        <button class="button" @click="CancelEvent">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import LevelIconCellRenderer from "@/components/Data/LevelIconCellRenderer.vue";
export default {
  name: "ActivityDetail",
  components: {
    AgGridVue,
    LevelIconCellRenderer, // eslint-disable-line
  },
  watch: {
    currentactivitydetailId() {
      setTimeout(() => this.GridExapnd(this.currentactivitydetailId), 100);
    },
  },
  computed: {
    ...mapGetters("structureManager", ["getDetailData"]),
    ...mapState("activityControl", [
      "activityLoadcounter",
      "currentactivitydetailId",
    ]),

    ...mapGetters("activityControl", [
      "getDetailsColumns",
      "getTitle",
      "getActivityDetailcode",
      "getActivityDetailId",
      "getCurrentActivities",
    ]),
  },
  data() {
    return {
      detailCellRendererParams: {
        refreshStrategy: "everything",
      },
      components: null,
      rowDetailData: null,
      DetailcolumnDefs: null,
      defaultColDef: null,
      gridDetailOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
      },
      isStructuredetail: false,
      getDataPath: null,
      autoGroupColumnDef: null,
      groupDefaultExpanded: null,
    };
  },
  methods: {
    ...mapMutations("activityControl", [
      "toggleActivityFilterModal",
      "mutateActivitiesCodeValue",
    ]),

    GridExapnd(selectedId) {
      this.gridDetailOptions.api.refreshCells();
      this.gridDetailOptions.api.clearFocusedCell();
      let tempRowIndex = 0;
      this.gridDetailOptions.api.forEachNode((node) => {
        if (node.data.id == selectedId) {
          let thisParent = node.parent;
          while (thisParent) {
            thisParent.setExpanded(true);
            thisParent = thisParent.parent;
          }
          node.setSelected(true);
          node.setExpanded(true);
          tempRowIndex = node.rowIndex;
        }
      });
      this.gridDetailOptions.api.selectIndex(tempRowIndex, false, false);
      this.gridDetailOptions.api.setFocusedCell(tempRowIndex, "code");
    },

    onGridReady() {
      this.loadStructureRows();
    },

    CancelEvent() {
      this.toggleActivityFilterModal();
    },

    saveStructure() {
      let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
      if (selectedNodes[0].data.parentId != null) {
        this.mutateActivitiesCodeValue({
          code: selectedNodes[0].data.code,
          Id: selectedNodes[0].data.id,
        });
        this.toggleActivityFilterModal();
      } else {
        this.$vToastify.error("ScheduleHeader is not allowed to select!");
      }
    },

    cellClicked(params) {
      params.api.refreshCells();
      let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
      if (params.column.colId == "ag-Grid-AutoColumn") {
        this.gridDetailOptions.api.forEachNode((node) => {
          if (
            node.data.path[node.data.path.length - 1] === selectedNodes[0].key
          ) {
            let thisParent = node.parent;
            while (thisParent) {
              thisParent.setExpanded(true);
              thisParent = thisParent.parent;
            }
            node.setSelected(true);
            node.setExpanded(true);
          }
        });
        this.gridDetailOptions.api.clearFocusedCell();
      }

      this.gridDetailOptions.api.refreshCells();
    },
  },
  beforeMount() {
    this.components = { activitiesCellRenderer: getActivitiesCellRenderer() };
    this.defaultColDef = Object.assign({}, this.defaultColDef, {
      width: 180,
    });
    this.autoGroupColumnDef = {
      headerName: "Activity",
      rowDrag: true,
      suppressPaste: true,
      //cellRenderer:"agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: "activitiesCellRenderer",
      },
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      width: 160,
    };
    this.getDataPath = (data) => {
      return data.path;
    };
  },
  mounted() {
    this.undoRedoCellEditingLimit = 15;
    this.DetailcolumnDefs = this.getDetailsColumns;
    this.rowDetailData = 0;
    this.rowDetailData = this.getCurrentActivities;
    setTimeout(() => this.GridExapnd(this.currentactivitydetailId), 10);
  },
};

window.getActivitiesCellRenderer = function getActivitiesCellRenderer() {
  function ActivitiesCellRenderer() {}
  ActivitiesCellRenderer.prototype.init = function (params) {
    var tempDiv = document.createElement("div");

    //params.api.refreshCells();
    tempDiv.innerHTML =
      params.data.parentId == null
        ? '<img src="Icons/schedule2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
        : params.node.allChildrenCount == null
        ? '<img src="Icons/activities2.svg"; style="margin-top:5px; height:20px; width:20px;"/> '
        : '<img src="Icons/wbs2.svg"; style="margin-top:5px; height:20px; width:20px;"/>';
    this.eGui = tempDiv.firstChild;
  };
  ActivitiesCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return ActivitiesCellRenderer;
};
</script>

<style lang="scss">
#global-filter-section-content {
  height: 25vw;
  padding-top: 0px;
}
.danger {
  color: #f44336;
}
/* Red */
</style>
