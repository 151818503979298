<template>
  <section style="height: 100%" v-show="loggedIn">
    <ClientAdminToolBar />
    <client-admin />
  </section>
</template>

<script>
import ClientAdmin from "@/components/ClientAdmin/ClientAdmin.vue";
import ClientAdminToolBar from "@/components/ClientAdmin/ClientAdminToolbar.vue";
import { mapGetters } from "vuex";

export default {
  name: "ClientAdminView",
  components: {
    ClientAdmin,
    ClientAdminToolBar,
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
  },
  data() {
    return {};
  },
};
</script>
