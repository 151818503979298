import { post } from "../api/user-auth.js";
import {
  getSpendingCurveHeaderAll,
  UpdateSpendingCurveHeader,
  DeleteSpendingCurveHeader,
  getCurveDetails,
  UpdateCurveDetail,
  DeleteSpendingCurveDetail,
  getAllExtraColumnDetails,
} from "@/api/networking.js";

let data = [],
  i = -1,
  tempData = null;
function getParentLoop(id, path) {
  let p = data.filter((i) => i.id == id)[0];
  if (p.parentId) {
    path.unshift(p.id);
    return getParentLoop(p.parentId, path);
  } else {
    path.unshift(p.id);
  }
  return path;
}

function setpath(data) {
  data.map((i) => {
    i.path = [];
    if (i.parentId) {
      i.path.push(i.id);
      getParentLoop(i.parentId, i.path);
    } else {
      i.path.push(i.id);
    }
    return i;
  });
  return data;
}

const state = {
  currentCurveData: [],
  currentDetailData: [],
  LoadCurveCounter: 0,
  tempSaveData: [],
  currentCurveID: null,
  dataWatcher: 0,
  selectedRowObj: {},
  addingRecord: false,
  addingDeatilRecord: false,
  addingSubRecord: false,
  isRelationship: false,
  undoWatcher: 0,
  redoWatcher: 0,
  isRedo: false,
  isUndo: false,
  CurveundoWatcher: 0,
  CurveredoWatcher: 0,
  isCurveRedo: false,
  isCurveUndo: false,
  SaveError: 0,
  currentDetailID: null,
  AddRecordCounter: 0,
  AddSubRecordCounter: 0,
  SaveCounter: 0,
  LoadCounter: 0,
  SaveWatcher: 0,
  popups: false,
  DeleteCounter: 0,
  isDelete: 0,
  CurveDeleteCounter: 0,
  isCurveDelete: 0,
  curveAddConter: 0,
  tempCurveData: 0,
  currentParentColumDefCounter: 0,
  selectedMasterDataTypeId: null,
  currentParentColDefs: [],
  currentChildColDefs: [],
  currentChildColumDefCounter: 0,
};

const actions = {
  /*Extra Column */
  async LoadSpendingCurveHeaderExtraColumn({ dispatch }) {
    state.currentParentColDefs = [];
    getAllExtraColumnDetails("SpendingCurveHeaderExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          rowData.data.forEach((y) =>
            state.currentParentColDefs.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 120,
              editable: true,
              filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: "OR",
              },
              filter: "agSetColumnFilter",
              cellStyle: function (params) {
                if (params.data != undefined && params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                } else if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return new Date(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.currentParentColumDefCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, {
          root: true,
        });
      });
  },

  async LoadSpendingCurveDetailExtraColumn({ dispatch }) {
    state.currentChildColDefs = [];
    getAllExtraColumnDetails("SpendingCurveDetailExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          rowData.data.forEach((y) =>
            state.currentChildColDefs.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 120,
              editable: true,
              filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: "OR",
              },
              filter: "agSetColumnFilter",
              cellStyle: function (params) {
                if (params.data != undefined && params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                } else if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return new Date(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.currentChildColumDefCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, {
          root: true,
        });
      });
  },

  async LoadSpendingCurve({ dispatch, commit }) {
    getSpendingCurveHeaderAll()
      .then((rowData) => {
        if (rowData.succeeded) {
          commit("setCurveData", { returnedData: rowData.data });
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async LoadDetails({ dispatch, commit }, SpendingCurveHeaderId) {
    getCurveDetails(SpendingCurveHeaderId)
      .then((rowData) => {
        if (rowData.succeeded) {
          commit("setDetailData", { returnedData: rowData.data });
          state.currentDetailID = null;
          state.currentCurveID = SpendingCurveHeaderId;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async DeleteCurve({ dispatch }, { curveheaderID }) {
    DeleteSpendingCurveHeader(curveheaderID)
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentDetailID = null;
          state.AddRecordCounter = 0;
          state.AddSubRecordCounter = 0;
          state.DeleteCounter = 0;
          state.isDelete = 0;
          state.isCurveDelete++;

          for (let a = 0; a < curveheaderID.length; a++) {
            let tempindex = state.currentCurveData.findIndex(
              (x) => x.id == curveheaderID[a]
            );
            delete state.currentCurveData[tempindex];
          }
          state.currentCurveData = state.currentCurveData.filter(Boolean);
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async DeleteDetailCurve({ dispatch }, { Id }) {
    DeleteSpendingCurveDetail(Id)
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentDetailID = null;
          state.AddRecordCounter = 0;

          for (let a = 0; a < Id.length; a++) {
            let tempindex = state.currentDetailData.findIndex(
              (x) => x.id == Id[a]
            );
            delete state.currentDetailData[tempindex];
          }
          state.currentDetailData = state.currentDetailData.filter(Boolean);
          state.isDelete++;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
  async saveCurveData({ dispatch, commit }) {
    var rowsToInsert = [];
    var rowsToUpdate = [];
    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {};
    state.currentCurveData.forEach((row) => {
      if (row.rowUpdated) {
        if (row.id == -1) {
          rowsToInsert.push(row);
        } else {
          rowsToUpdate.push({
            Id: row.id,
            code: row.code,
            description: row.description,
            ExtraColumnValues: row.extraColumnValues,
          });
        }
      }
    });
    if (rowsToInsert.length > 0) {
      createURL = "SpendingCurveHeader/Create";
      createObj = { spendingcurvesToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = "SpendingCurveHeader/Update";
      updateObj = { SpendingcurvesToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          /* if (values[1].data)
            returnedValues = returnedValues.concat(values[0].data); */
          commit("setCurveSavedData", {
            returnedSavedData: returnedValues,
          });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = UpdateSpendingCurveHeader(updateObj);
      Promise.all([createP, updateP])
        .then((values) => {
          var returnedValues = [];
          if (values[1]) returnedValues = values[1].data;
          commit("setCurveSavedData", {
            returnedSavedData: returnedValues,
          });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    //Curve Details
    rowsToInsert = [];
    rowsToUpdate = [];
    createP = Promise.resolve(1);
    updateP = Promise.resolve(1);
    createURL = "";
    updateURL = "";
    createObj = {};
    updateObj = {};
    state.currentDetailData.forEach((row) => {
      if (row.rowUpdated) {
        if (row.id <= -1) {
          rowsToInsert.push(row);
        } else {
          rowsToUpdate.push({
            Id: row.id,
            code: row.code,
            description: row.description,
            spendingCurveHeaderId: row.spendingCurveHeaderId,
            sortOrder: row.sortOrder,
            PercentOfCost: row.percentOfCost,
            PercentOfTime: row.percentOfTime,
            ExtraColumnValues: row.extraColumnValues,
          });
        }
      }
    });

    if (rowsToInsert.length > 0) {
      createURL = "SpendingCurveDetail/Create";
      createObj = { spendingcurvesDetailToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = "SpendingCurveDetail/Update";
      updateObj = { spendingcurveDetailsToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          commit("setDetailSavedData", { returnedSavedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = UpdateCurveDetail(updateObj);
      Promise.all([createP, updateP])
        .then((values) => {
          var returnedValues = [];
          if (values[1]) returnedValues = values[1].data;
          commit("setDetailSavedData", { returnedSavedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
  },
};

const mutations = {
  setCurveData(state, { returnedData }) {
    returnedData.forEach(function (row) {
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.spendingCurveHeaderExtraColumnId] = y.value)
        );
      }
      // returnedData.forEach(row => ();
    });
    //returnedData.forEach(row => (row.rowUpdated = false));
    state.currentCurveData = returnedData;
    state.undoWatcher = 0;
    state.redoWatcher = 0;
    state.isUndo = false;
    state.isRedo = false;
    state.CurveundoWatcher = 0;
    state.CurveredoWatcher = 0;
    state.isCurveRedo = false;
    state.isCurveUndo = false;
    state.LoadCurveCounter++;
  },

  setCurveSavedData(state, { returnedSavedData }) {
    state.currentCurveData = state.currentCurveData.filter(
      (x) => x.rowUpdated == false
    );
    returnedSavedData.forEach(function (row) {
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.spendingCurveHeaderExtraColumnId] = y.value)
        );
      }
      state.currentCurveData.push(row);
      // returnedData.forEach(row => ();
    });
    //state.currentCurveData = returnedSavedData;
    state.undoWatcher = 0;
    state.redoWatcher = 0;
    state.isUndo = false;
    state.isRedo = false;
    state.CurveundoWatcher = 0;
    state.CurveredoWatcher = 0;
    state.isCurveRedo = false;
    state.isCurveUndo = false;
    state.LoadCurveCounter++;
    state.dataWatcher += 1;
  },

  setDetailSavedData(state, { returnedSavedData }) {
    state.currentDetailData = state.currentDetailData.filter(
      (x) => x.rowUpdated == false
    );
    returnedSavedData.forEach(function (row) {
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.spendingCurveDetailExtraColumnId] = y.value)
        );
      }
      state.currentDetailData.push(row);
      // returnedData.forEach(row => ();
    });
    state.tempCurveData = returnedSavedData;
    state.SaveCounter = 0;
    state.LoadCounter++;
    state.dataWatcher += 1;
  },

  setDetailData(state, { returnedData }) {
    if (returnedData != null) {
      returnedData.forEach(function (row) {
        row.rowUpdated = false;
        if (row.extraColumnValues != null) {
          row.extraColumnValues.forEach(
            (y) => (row["et_" + y.spendingCurveDetailExtraColumnId] = y.value)
          );
        }
        // returnedData.forEach(row => ();
      });
      /*  data = returnedData;
      tempData = setpath(data); */
      state.currentDetailData = returnedData;
    }
    state.LoadCounter++;

    i--;
  },

  setCurveValue(state, selectedId) {
    state.currentDetailID = null;
    state.AddRecordCounter = 0;
    state.AddSubRecordCounter = 0;
    state.DeleteCounter = 0;
    state.isDelete = 0;
    state.currentCurveID = selectedId;
  },

  setcurrentCurveID(state) {
    state.currentCurveID = null;
  },

  setDeatilId(state, selectedId) {
    state.currentDetailID = selectedId;
  },

  setSelectedRowObject(state, selectedObj) {
    if (selectedObj != 0) {
      state.selectedRowObj = selectedObj;
    } else {
      state.selectedRowObj = 0;
    }
  },

  addCurveRecord() {
    state.addingRecord = true;
    state.currentCurveData.push({
      id: -1,
      code: "",
      description: "",
      rowUpdated: true,
      extraColumnValues: [],
    });
    state.curveAddConter++;
  },

  requestUndo(state) {
    state.undoWatcher += 1;
  },

  requestRedo(state) {
    state.redoWatcher += 1;
  },

  resetRequestUndo(state, Undovalue) {
    state.isUndo = Undovalue;
  },

  resetRequestRedo(state, Redovalue) {
    state.isRedo = Redovalue;
  },

  requestCurveUndo(state) {
    state.CurveundoWatcher += 1;
  },

  requestCurveRedo(state) {
    state.CurveredoWatcher += 1;
  },

  resetCurveRequestUndo(state, Undovalue) {
    state.isCurveUndo = Undovalue;
  },

  resetCurveRequestRedo(state, Redovalue) {
    state.isCurveRedo = Redovalue;
  },

  mutateSubRecord(state) {
    state.AddSubRecordCounter++;
  },

  mutateAddRecord(state) {
    state.AddRecordCounter++;
    state.currentDetailData.push({
      id: -1,
      code: "",
      description: "",
      spendingCurveHeaderId: state.currentCurveID,
      sortOrder: 0,
      percentOfCost: 0,
      percentOfTime: 0,
      rowUpdated: true,
      extraColumnValues: [],
    });
  },

  mutateSaveCounter(state) {
    state.SaveCounter++;
  },

  mutateRecord(state, { returnedNewData }) {
    state.tempSaveData = [];
    returnedNewData.forEach((element) => {
      if (element.rowUpdated) {
        state.tempSaveData.push(element);
      }
    });
    state.tempSaveData = returnedNewData;
  },

  mutateDelete(state) {
    state.DeleteCounter++;
  },

  mutateCurveDelete(state) {
    state.CurveDeleteCounter++;
  },
};

const getters = {
  getDataWatcher() {
    return state.dataWatcher;
  },

  getRedoWatcher() {
    return state.redoWatcher;
  },

  getUndoWatcher() {
    return state.undoWatcher;
  },

  getisRedo() {
    return state.isRedo;
  },

  getisUndo() {
    return state.isUndo;
  },

  getCurveRedoWatcher() {
    return state.CurveredoWatcher;
  },

  getCurveUndoWatcher() {
    return state.CurveundoWatcher;
  },

  getCurveisRedo() {
    return state.isCurveRedo;
  },

  getCurveisUndo() {
    return state.isCurveUndo;
  },

  getSelectedRowObject() {
    return state.selectedRowObj;
  },

  getCurveuctureData() {
    return state.currentCurveData;
  },

  getDetailData() {
    return state.currentDetailData;
  },

  getCurveIdvalue() {
    return state.currentCurveID;
  },

  getDetailIdvalue() {
    return state.currentDetailID;
  },

  getCurveColumns() {
    return [
      {
        headerName: "Code",
        field: "code",
        sortable: true,
        resizable: true,
        width: 280,
        filterParams: { excelMode: "windows" },
        editable: true,
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
      },
      {
        headerName: "Description",
        field: "description",
        sortable: true,
        resizable: true,
        width: 390,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
      },
    ];
  },

  addingRecord({ addingRecord }) {
    return addingRecord;
  },

  addingDeatilRecord({ addingDeatilRecord }) {
    return addingDeatilRecord;
  },

  addingSubRecord({ addingSubRecord }) {
    return addingSubRecord;
  },
  getParentColDefs(state) {
    return state.currentParentColDefs;
  },

  getChildColDefs(state) {
    return state.currentChildColDefs;
  },
  getSavedDetailData(state) {
    return state.tempCurveData;
  },

  getDetailsColumns() {
    return [
      {
        headerName: "Code",
        field: "code",
        sortable: true,
        resizable: true,
        width: 100,
        filterParams: { excelMode: "windows" },
        editable: true,
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
        cellRenderer: function (params) {
          if (params.data.rowUpdated == "Duplicate") {
            let eIconGui = document.createElement("span");
            return (eIconGui.innerHTML =
              '<i class="fas fa-exclamation" style="color:red;"></i>' +
              " " +
              params.data.code);
          }
          return params.data.code;
        },
      },
      {
        headerName: "Description",
        field: "description",
        sortable: true,
        resizable: true,
        width: 300,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
      },
      {
        headerName: "Sort Order",
        field: "sortOrder",
        sortable: true,
        resizable: true,
        width: 130,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
      },
      {
        headerName: "Percent Of Cost",
        field: "percentOfCost",
        sortable: true,
        resizable: true,
        width: 150,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
      },

      {
        headerName: "Percent Of Time",
        field: "percentOfTime",
        sortable: true,
        resizable: true,
        width: 150,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
      },
    ];
  },
};

export const spendingcurveManager = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
