<template>
  <section style="height: 100%">
    <ProjectConfigMgmtView
      v-show="loggedIn"
      v-if="selectedIntegrateDataType.id == 0"
    />
    <UATProjectConfigMgmtView
      v-show="loggedIn"
      v-if="selectedIntegrateDataType.id == 1"
    />
    <ProjectConfigMgmtView_New
      v-show="loggedIn"
      v-if="selectedIntegrateDataType.id == 2"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProjectConfigMgmtView from "./CXProjectConfigMgmt/ProjectConfigMgmtView.vue";
import UATProjectConfigMgmtView from "./CXProjectConfigMgmt/UATProjectConfigMgmtView.vue";

import ProjectConfigMgmtView_New from "./CXProjectConfigMgmt/ProjectConfigMgmtView_New.vue";
export default {
  name: "AnalyticsMasterView",
  components: {
    ProjectConfigMgmtView,
    UATProjectConfigMgmtView,
    ProjectConfigMgmtView_New,
  },
  watch: {},
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("analyticsDataManager", ["selectedIntegrateDataType"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  updated: function () {
    console.log("Updated");
  },
  mounted: function () {
    console.log("Analytics mounted");
    if (
      !this.isValid(this.selectedIntegrateDataType) ||
      this.selectedIntegrateDataType.name.toLowerCase() !=
        this.$route.params.id.toLowerCase()
    ) {
      this.setSelectedAnalyticDataByName(this.$route.params.id);
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("analyticsDataManager", ["setSelectedAnalyticDataByName"]),
    isValid(valueToCheck) {
      if (
        String(valueToCheck) != "" &&
        String(valueToCheck) != "null" &&
        String(valueToCheck) != "undefined"
      )
        return true;
      else {
        return false;
      }
    },
  },
};
</script>
