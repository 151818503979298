let checkNegativeCellClass = (params) =>
  params.value != null && params.value < 0
    ? "negative-ag-column ag-default-layout ag-default-cell"
    : "ag-default-layout ag-default-cell";
let checkBadPercentageCellClass = (params) =>
  params.value != null && params.value < 0.999
    ? "negative-ag-column ag-default-layout ag-default-cell"
    : "ag-default-layout ag-default-cell";

let isFormattableNumber = (num) => num != null && isFinite(num) && num != 0;

let currencyFormatter = new Intl.NumberFormat("en-AU", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
let fixedCurrencyFormatter = (params) =>
  isFormattableNumber(params.value)
    ? currencyFormatter.format(params.value || params.value != undefined)
    : "";

// eslint-disable-next-line
let fixedDecimalFormatter = params =>
  params.value != null ? params.value.toFixed(2) : "";

// eslint-disable-next-line
let fixedDecimalPercentFormatter = params =>
  params.value != null ? params.value.toFixed(2) + " %" : "";
let fixedPercentFormatter = (params) =>
  isFormattableNumber(params.value || params.value != undefined)
    ? (params.value * 100).toFixed(2) + " %"
    : "";

// eslint-disable-next-line
let fixedDecimalParser = params => Number(params.newValue);
function compareActivities(a, b) {
  return a.sequenceNumber - b.sequenceNumber;
}
import * as moment from "moment";
export {
  checkNegativeCellClass,
  checkBadPercentageCellClass,
  fixedCurrencyFormatter,
  fixedPercentFormatter,
  compareActivities,
};
