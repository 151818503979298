<template>
  <nav
    class="navbar"
    role="navigation"
    aria-label="main navigation"
    style="border-bottom: 2px solid; margin-bottom: 5px"
  >
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-end" style="margin-right: 18px">
        <!-- <button
          class="button"
          title=""
          v-if="currentRouteName != 'login'"
          :disabled="getExtraColumnIdvalue == null"
        ></button> -->
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
p {
  width: 165px;
  display: inline-block;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}
nav.navbar {
  border-top: 0px solid black;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "CostElementLeftToolbar",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
