<template>
  <div class="column" v-on:keyup="processDataChanged">
    <div v-if="processType == 'PTransform'">
      <span class="tag"> outputName</span>
      <br /><input
        class="input"
        v-model="processedData.outputName"
        name="outputName"
        autocomplete="off"
      />
      <br />
      <span class="tag"> XSLTName</span> <br /><input
        class="input"
        v-model="processedData.XSLTName"
        name="XSLTName"
        autocomplete="off"
      />
    </div>
    <div v-else-if="processType == 'PWebServiceCall'">
      <span class="tag">URL</span> <br /><input
        class="input"
        v-model="processedData.URL"
        name="URL"
        autocomplete="off"
      />
      <br />
      <span class="tag">Method</span> <br /><!-- <input
        class="input"
        v-model="processedData.HTTPMethod"
        name="Method"
        autocomplete="off"
      /> -->
      <select
        class="input"
        v-model="processedData.HTTPMethod"
        :on-change="HTTPMethodChanged(processedData.HTTPMethod)"
      >
        <option disabled value="">Please select one</option>
        <option
          v-for="HTTPobjects in HTTPMethods"
          :key="HTTPobjects.id"
          :value="HTTPobjects.name"
        >
          {{ HTTPobjects.name }}
        </option>
      </select>
      <br />
      <span class="tag">Headers</span
      ><!-- <input
        class="input"
        v-bind:value="JSON.stringify(processedData.Headers)"
        name="Headers"
        autocomplete="off"
      /> -->
      <button class="small-button" title="Add New" @click="addHTTPHeaderPara">
        <span class="icon is-small">
          <i class="fas fa-plus"></i>
        </span>
      </button>
      <button class="small-button" title="Delete" @click="deleteHTTPHeaderPara">
        <span class="icon is-small">
          <i class="fas fa-trash-alt"></i>
        </span>
      </button>
      <ag-grid-vue
        style="height: 28em; width: 35em"
        class="ag-theme-balham ag-default-layout"
        rowSelection="multiple"
        enableCellChangeFlash="true"
        stopEditingWhenGridLosesFocus="true"
        :gridOptions="gridHTTPParaOptions"
        :rowData="rowHTTPData"
        :defaultColDef="defaultColDef"
        :columnDefs="columnHTTPParaDefs"
        @cellValueChanged="HTTPcellValueChanged"
        :enableRangeSelection="true"
        :undoRedoCellEditing="true"
      >
      </ag-grid-vue>
      <br />
      <span class="tag" v-if="processedData.HTTPMethod != 'GET'"
        >InputName</span
      >
      <br /><input
        class="input"
        v-model="processedData.InputName"
        name="InputName"
        autocomplete="off"
        v-if="processedData.HTTPMethod != 'GET'"
      />
      <br />
      <span class="tag">OutputName</span> <br /><input
        class="input"
        v-model="processedData.outputName"
        name="outputName"
        autocomplete="off"
      />
    </div>

    <div v-else-if="processType == 'PP3MCommand'">
      <span class="tag">InputName</span> <br /><input
        class="input"
        v-model="processedData.InputName"
        name="InputName"
        autocomplete="off"
      /><br />
      <span class="tag">outputName</span> <br />
      <input
        class="input"
        v-model="processedData.outputName"
        name="outputName"
        autocomplete="off"
      />
      <br />
      <span class="tag">commandName</span> <br />
      <input
        class="input"
        v-model="processedData.commandName"
        name="commandName"
        autocomplete="off"
      />
    </div>
    <div v-else-if="processType == 'PSQL'">
      <span class="tag">OutputName</span> <br />
      <input
        class="input"
        v-model="processedData.OutputName"
        name="OutputName"
        autocomplete="off"
      />
      <br />
      <span class="tag">SQLScript</span>
      <br />
      <textarea
        name="SQLScript"
        v-model="processedData.SQLScript"
        spellcheck="false"
        autocomplete="off"
        style="height: 25em; width: 30em"
        placeholder="SQLScript"
        class="input"
      />
      <br />
      <span class="tag">Parameters</span>
      <button class="small-button" title="Add New" @click="addSQLPara">
        <span class="icon is-small">
          <i class="fas fa-plus"></i>
        </span>
      </button>
      <button class="small-button" title="Delete" @click="deleteSQLPara">
        <span class="icon is-small">
          <i class="fas fa-trash-alt"></i>
        </span>
      </button>
      <!-- <textarea
        name="Parameters"
        spellcheck="false"
        autocomplete="off"
        style="height: 10em; width: 30em"
        v-bind:value="JSON.stringify(processedData.Parameters)"
        class="input"
      /> -->
      <ag-grid-vue
        style="height: 18em; width: 35em"
        class="ag-theme-balham ag-default-layout"
        rowSelection="multiple"
        enableCellChangeFlash="true"
        stopEditingWhenGridLosesFocus="true"
        :gridOptions="gridSQLParaOptions"
        :rowData="rowSQLData"
        :defaultColDef="defaultColDef"
        :columnDefs="columnSQLParaDefs"
        @cellValueChanged="SQLcellValueChanged"
        :enableRangeSelection="true"
        :undoRedoCellEditing="true"
      >
      </ag-grid-vue>
    </div>

    <div v-else-if="processType == 'PConditional'">
      <span class="tag"> ConditionalStatement </span> <br />
      <input
        class="input"
        role="textbox"
        v-model="processedData.ConditionalStatement"
        name="ConditionalStatement"
        autocomplete="off"
      />
    </div>
    <div v-else-if="processType == 'PWhile'">
      <span class="tag"> WhileCondition </span> <br />
      <input
        class="input"
        v-model="processedData.WhileCondition"
        name="WhileCondition"
        autocomplete="off"
      />
    </div>
    <div v-else-if="processType == 'PExcel'">
      <span class="tag">OutputName</span> <br />
      <input
        class="input"
        v-model="processedData.OutputName"
        name="OutputName"
        autocomplete="off"
      />
      <br />
      <span class="tag">ContainerName</span> <br />
      <input
        class="input"
        v-model="processedData.ContainerName"
        name="ContainerName"
        autocomplete="off"
      />
      <br />
      <span class="tag">FolderPath</span> <br />
      <input
        class="input"
        v-model="processedData.FolderPath"
        name="FolderPath"
        autocomplete="off"
      />
      <br />
      <span class="tag">SheetName</span> <br />
      <input
        class="input"
        v-model="processedData.SheetName"
        name="SheetName"
        autocomplete="off"
      />
      <br />
      <span class="tag">FileName</span> <br />
      <input
        class="input"
        v-model="processedData.FileName"
        name="FileName"
        autocomplete="off"
      />
      <br />
      <span class="tag">ConnectionName</span> <br />
      <input
        class="input"
        v-model="processedData.ConnectionName"
        name="ConnectionName"
        autocomplete="off"
      />
    </div>
    <div v-else-if="processType == 'PAzureBlobExplorer'">
      <span class="tag">ActionName</span> <br />
      <input
        class="input"
        v-model="processedData.ActionName"
        name="ActionName"
        autocomplete="off"
      />
      <br />
      <span class="tag">OutputFolderPath</span> <br />
      <input
        class="input"
        v-model="processedData.OutputFolderPath"
        name="OutputFolderPath"
        autocomplete="off"
      />
      <br />
      <span class="tag">ContainerName</span> <br />
      <input
        class="input"
        v-model="processedData.ContainerName"
        name="ContainerName"
        autocomplete="off"
      />
      <br />
      <span class="tag">FolderPath</span> <br />
      <input
        class="input"
        v-model="processedData.FolderPath"
        name="FolderPath"
        autocomplete="off"
      />
      <br />
      <span class="tag">FileNamePattern</span> <br />
      <input
        class="input"
        v-model="processedData.FileNamePattern"
        name="FileNamePattern"
        autocomplete="off"
      />
      <br />
      <span class="tag">ConnectionName</span> <br />
      <input
        class="input"
        v-model="processedData.ConnectionName"
        name="ConnectionName"
        autocomplete="off"
      />
    </div>
    <div v-else-if="processType == 'PSSOLogin'">
      <span class="tag">BaseUrl</span> <br />
      <input
        class="input"
        v-model="processedData.BaseUrl"
        name="BaseUrl"
        autocomplete="off"
      />
      <br />
      <span class="tag">ClientId</span> <br />
      <input
        class="input"
        v-model="processedData.ClientId"
        name="ClientId"
        autocomplete="off"
      />
      <br />
      <span class="tag">ResourceId</span> <br />
      <input
        class="input"
        v-model="processedData.ResourceId"
        name="ResourceId"
        autocomplete="off"
      />
      <br />
      <span class="tag">ReturnUrl</span> <br />
      <input
        class="input"
        v-model="processedData.ReturnUrl"
        name="ReturnUrl"
        autocomplete="off"
      />
      <br />
      <span class="tag">TenantId</span> <br />
      <input
        class="input"
        v-model="processedData.TenantId"
        name="TenantId"
        autocomplete="off"
      />
      <br />
      <span class="tag">Username</span> <br />
      <input
        class="input"
        v-model="processedData.Username"
        name="Username"
        autocomplete="off"
      />
      <br />
      <span class="tag">Password</span> <br />
      <input
        class="input"
        v-model="processedData.Password"
        name="Password"
        autocomplete="off"
      />

      <br />
      <span class="tag">OutputName</span> <br />
      <input
        class="input"
        v-model="processedData.OutputName"
        name="OutputName"
        autocomplete="off"
      />
    </div>
    <div v-else-if="processType == 'PGoTo'">
      <span class="tag"> ProcessCode </span> <br />
      <input class="input" />
    </div>
    <div v-else-if="processType == 'PUserSetting'">
      <span class="tag">Read</span> <br />
      <select
        name="pUserReadWrite"
        id="pUserReadWrite"
        v-model="processedData.pUserSettingAction"
      >
        <option value="read" selected>Read</option>
        <option value="write">Write</option>
      </select>
      <br />
      <span class="tag">Setting Names (Comma separated)</span> <br />
      <input
        class="input"
        name="userSettingNames"
        autocomplete="off"
        v-model="processedData.pUserSettingSettingName"
      />
      <br />
      <span v-if="processedData.pUserSettingAction == 'write'" class="tag"
        >Context Object Names (Comma Separated)</span
      >
      <br />
      <input
        class="input"
        name="contextObjNames"
        autocomplete="off"
        v-if="processedData.pUserSettingAction == 'write'"
        v-model="processedData.pUserSettingContextObjNames"
      />
      <br />
      <span v-if="processedData.pUserSettingAction == 'write'" class="tag"
        >Setting Type Ids (Comma Separated)</span
      >
      <br />
      <input
        class="input"
        name="contextObjNames"
        autocomplete="off"
        v-if="processedData.pUserSettingAction == 'write'"
        v-model="processedData.pUserSettingTypeIds"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.small-button {
  border: 1px groove;
  background-color: white;
  color: black;
  cursor: pointer;
}
.tag {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  //padding-right:30px;
  margin: 10px 40px 0px 00px;
  position: static;
  text-transform: uppercase;
  width: 170px;
}
.input {
  display: inline-block;
  width: 20em;
  margin-bottom: 20px;
  //line-height: 25px;
  font-size: 14px;
  // font-weight: 500;
  font-family: inherit;
  //border-radius: 6px;
  -webkit-appearance: none;
  color: var(--input-color);
  //border: 1px solid var(--input-border);
  background: var(--input-background);
  // transition: border 0.3s ease;
  &::placeholder {
    color: var(--input-placeholder);
  }
}
</style>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { AgGridVue } from "ag-grid-vue";

export default {
  name: "processTypeEditor",
  props: ["processType"],
  components: { AgGridVue },
  data() {
    return {
      processedData: {},
      HTTPMethods: [
        { name: "GET", id: 0 },
        { name: "HEAD", id: 1 },
        { name: "PUT", id: 2 },
        { name: "POST", id: 3 },
        { name: "DELETE", id: 4 },
        { name: "PATCH", id: 5 },
      ],
      gridSQLParaOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
          ],
        },
      },
      rowSQLData: [],
      defaultColDef: {
        // set every column width
        width: 100,
        // make every column editable
        editable: true,
        // make every column use 'text' filter by default
        filter: "agTextColumnFilter",
      },

      gridHTTPParaOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
          ],
        },
      },
      rowHTTPData: [],
      columnHTTPParaDefs: [],
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
    };
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),

    ...mapState({
      currentChildId: (state) => state.processSetController.currentChildId,
      getCurrentChildObj: (state) => state.processSetController.currentChildObj,
    }),
  },
  watch: {
    currentChildId() {
      this.LoadData();
    },
    processedData() {
      /*   this.mutateProcessTypeData({
        processTypeObj: JSON.stringify(this.processedData),
      }); */
    },
  },
  methods: {
    ...mapMutations("processSetController", ["mutateProcessTypeData"]),
    /* Children Controller Mutations */
    LoadData() {
      this.rowHTTPData = [];
      if (this.currentChildId != null) {
        if (
          this.getCurrentChildObj.id < 0 &&
          this.getCurrentChildObj.processData == null
        ) {
          this.processTypeSwitch();
        } else {
          if (JSON.parse(this.getCurrentChildObj.processData)) {
            this.processedData = JSON.parse(
              this.getCurrentChildObj.processData
            );
            if (this.processType == "PSQL") {
              this.rowSQLData = [];
              //console.log(this.processedData);
              this.LoadAllSQLtypes();
              //console.log(this.processedData.Parameters[0]);
              this.processedData.Parameters.forEach((element, index) => {
                //console.log(this.getAllSQLDataTypes, element);
                var tempSQLType = this.getAllSQLDataTypes.find(
                  (x) => x.id == element.DataType
                );

                this.rowSQLData.push({
                  id: index,
                  DataType: tempSQLType,
                  ParameterName: element.ParameterName,
                  ParameterValue: element.ParameterValue,
                  DataSourceName: element.DataSourceName,
                });
              });
            } else if (this.processType == "PWebServiceCall") {
              this.LoadAllHTTPtypes();
              this.rowHTTPData = [];
              var tempHeaderObj = JSON.parse(
                this.getCurrentChildObj.processData
              ).Headers;
              if (tempHeaderObj != undefined) {
                this.rowHTTPData = tempHeaderObj;
              }
            }
          } else {
            this.processedData = this.getCurrentChildObj.processData;
          }
        }
      }
    },
    processDataChanged(event) {
      if (this.processType == "PSQL") {
        this.processedData.Parameters = [];
        this.rowSQLData.forEach((element) => {
          //console.log(element.DataType);
          var tempSQLType = this.getAllSQLDataTypes.find(
            (x) => x.id == element.DataType.id
          );
          this.processedData.Parameters.push({
            DataType: tempSQLType.id,
            ParameterName: element.ParameterName,
            ParameterValue: element.ParameterValue,
            DataSourceName: element.DataSourceName,
          });
        });
        // this.rowSQLData = [];
      } else if (this.processType == "PWebServiceCall") {
        //console.log(this.rowHTTPData);
        this.processedData.Headers = [];
        // = this.rowHTTPData;
        this.rowHTTPData.forEach((element) => {
          //console.log(element.DataType);
          this.processedData.Headers.push({
            key: element.key,
            value: element.value,
          });
        });
        //this.rowHTTPData = [];
      }
      this.mutateProcessTypeData({
        processTypeObj: JSON.stringify(this.processedData),
      });
    },
    HTTPMethodChanged() {},

    processTypeSwitch() {
      console.log("this.processType", this.processType);
      switch (this.processType) {
        default:
          this.processedData = null;
          return this.processedData;
        case "PWebServiceCall":
          this.LoadAllHTTPtypes();
          this.processedData = {
            URL: "",
            HTTPMethod: "",
            Headers: "",
            InputName: "",
            outputName: "",
          };
          return this.processedData;
        case "PP3MCommand":
          this.processedData = {
            InputName: "",
            outputName: "",
            commandName: "",
          };
          return this.processedData;
        case "PSQL":
          this.rowSQLData = [];
          this.LoadAllSQLtypes();
          this.processedData = {
            OutputName: "",
            SQLScript: "",
            Parameters: "",
          };
          return this.processedData;
        case "PConditional":
          this.processedData = { ConditionalStatement: "" };
          return this.processedData;
        case "PWhile":
          this.processedData = { WhileCondition: "" };
          return this.processedData;
        case "PExcel":
          this.processedData = {
            OutputName: "",
            ContainerName: "",
            FolderPath: "",
            SheetName: "",
            FileName: "",
            ConnectionName: "",
          };
          return this.processedData;
        case "PAzureBlobExplorer":
          this.processedData = {
            ActionName: "",
            OutputFolderPath: "",
            ContainerName: "",
            FolderPath: "",
            FileNamePattern: "",
            ConnectionName: "",
          };
          return this.processedData;
        case "PSSOLogin":
          this.processedData = {
            BaseUrl: "",
            ClientId: "",
            ResourceId: "",
            ReturnUrl: "",
            TenantId: "",
            Username: "",
            Password: "",
            OutputName: "",
          };
          return this.processedData;
        case "PTransform":
          this.processedData = { outputName: "", XSLTName: "" };
          return this.processedData;
        case "PGoTo":
          this.processedData = {};
          return this.processedData;
        case "PUserSetting":
          this.processedData = {
            pUserSettingAction: "read",
            pUserSettingSettingName: "",
            pUserSettingContextObjNames: "",
            pUserSettingTypeIds: "",
          };
          console.log("this.processedData", this.processedData);
          return this.processedData;
      }
    },
    LoadAllSQLtypes() {
      this.getAllSQLDataTypes = [
        {
          id: 0,
          className: "BigInt",
        },
        {
          id: 1,
          className: "Binary",
        },
        {
          id: 2,
          className: "Bit",
        },
        {
          id: 3,
          className: "Char",
        },
        {
          id: 4,
          className: "DateTime",
        },
        {
          id: 5,
          className: "Decimal",
        },
        {
          id: 6,
          className: "Float",
        },
        {
          id: 7,
          className: "Image",
        },
        {
          id: 8,
          className: "Int",
        },
        {
          id: 9,
          className: "Money",
        },
        {
          id: 10,
          className: "NChar",
        },
        {
          id: 11,
          className: "NText",
        },
        {
          id: 12,
          className: "NVarChar",
        },

        {
          id: 13,
          className: "Real",
        },
        {
          id: 14,
          className: "UniqueIdentifier",
        },
        {
          id: 15,
          className: "SmallDateTime",
        },
        {
          id: 16,
          className: "SmallInt",
        },
        {
          id: 17,
          className: "SmallMoney",
        },
        {
          id: 18,
          className: "Text",
        },
        {
          id: 19,
          className: "Timestamp",
        },
        {
          id: 20,
          className: "TinyInt",
        },
        {
          id: 21,
          className: "VarBinary",
        },
        {
          id: 22,
          className: "VarChar",
        },
        {
          id: 23,
          className: "Variant",
        },
        {
          id: 25,
          className: "Xml",
        },
        {
          id: 29,
          className: "Udt",
        },
        {
          id: 30,
          className: "Structured",
        },
        {
          id: 31,
          className: "Date",
        },
        {
          id: 32,
          className: "Time",
        },
        {
          id: 33,
          className: "DateTime2",
        },
        {
          id: 34,
          className: "DateTimeOffset",
        },
      ];
      this.columnSQLParaDefs = [
        {
          headerName: "Parameter Name",
          field: "ParameterName",
          sortable: true,
          resizable: true,
          width: 150,
          filterParams: {
            excelMode: "windows",
          },
          editable: true,
          filter: "agSetColumnFilter",
        },
        // overrides the default with a number filter
        {
          headerName: "Data Type",
          field: "DataType",
          cellEditor: "agRichSelectCellEditor",
          cellRenderer: SQLTypeCellRenderer,
          cellEditorPopup: true,
          cellEditorParams: {
            values: this.getAllSQLDataTypes.map((d) => {
              return {
                id: d.id,
                className: d.className,
              };
            }),
            cellRenderer: SQLTypeCellRenderer,
            cellEditorPopup: true,
            cellEditorPopupPosition: "under",
          },
          filterParams: {
            excelMode: "windows",
          },
          editable: true,
          filter: "agSetColumnFilter",
        },
        {
          headerName: "ParameterValue",
          field: "ParameterValue",
          sortable: true,
          resizable: true,
          width: 150,
          filterParams: {
            excelMode: "windows",
          },
          editable: true,
          filter: "agSetColumnFilter",
        },
        {
          headerName: "DataSource Name",
          field: "DataSourceName",
          sortable: true,
          resizable: true,
          width: 150,
          filterParams: {
            excelMode: "windows",
          },
          editable: true,
          filter: "agSetColumnFilter",
        },
        // overrides the default using a column type
      ];
    },
    SQLcellValueChanged(event) {
      this.processDataChanged();
    },
    HTTPcellValueChanged(event) {
      this.processDataChanged();
    },
    addSQLPara() {
      this.rowSQLData.push({
        id: this.rowSQLData.length++,
        ParameterName: "",
        DataType: { id: 0, className: "BigInt" },
        ParameterValue: "",
        DataSourceName: "",
      });
      this.gridSQLParaOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.processDataChanged();
    },
    deleteSQLPara() {
      const selectedData = this.gridSQLParaOptions.api.getSelectedRows();
      if (selectedData.length == 0) {
        this.$vToastify.error("No SQL parameter Selected!");
      } else {
        this.gridSQLParaOptions.api.applyTransaction({ remove: selectedData });

        this.rowSQLData = [];
        this.gridSQLParaOptions.api.forEachNode((node) =>
          this.rowSQLData.push(node.data)
        );
        //console.log(this.rowSQLData);
        this.gridSQLParaOptions.api.redrawRows();
        this.gridSQLParaOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        this.processDataChanged();
      }
    },

    addHTTPHeaderPara() {
      this.rowHTTPData.push({
        id: this.rowHTTPData.length++,
        key: "",
        value: "",
      });
      this.gridHTTPParaOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.processDataChanged();
    },
    deleteHTTPHeaderPara() {
      const selectedData = this.gridHTTPParaOptions.api.getSelectedRows();
      //console.log(selectedData);
      if (selectedData.length == 0) {
        this.$vToastify.error("No HTTP Headers parameter Selected!");
      } else {
        this.gridHTTPParaOptions.api.applyTransaction({ remove: selectedData });

        this.rowHTTPData = [];
        this.gridHTTPParaOptions.api.forEachNode((node) =>
          this.rowHTTPData.push(node.data)
        );
        //console.log(this.rowSQLData);
        this.gridHTTPParaOptions.api.redrawRows();
        this.gridHTTPParaOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        this.processDataChanged();
      }
    },
    LoadAllHTTPtypes() {
      this.columnHTTPParaDefs = [
        // overrides the default with a number filter
        {
          headerName: "KEY",
          field: "key",
          sortable: true,
          resizable: true,
          width: 200,
          filterParams: {
            excelMode: "windows",
          },
          editable: true,
          filter: "agSetColumnFilter",
        },
        {
          headerName: "Value",
          field: "value",
          sortable: true,
          resizable: true,
          width: 200,
          filterParams: {
            excelMode: "windows",
          },
          editable: true,
          filter: "agSetColumnFilter",
        },
      ];
    },
  },
  created() {
    this.LoadData();
    //  console.log(this.currentChildId); //prints out an empty string
  },
  mounted() {
    this.LoadAllSQLtypes();
    this.LoadAllHTTPtypes();
  },
};
class SQLTypeCellRenderer {
  init(params) {
    this.eGui = document.createElement("div");
    this.eGui.innerHTML = params.value.className;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    return false;
  }
}
</script>
