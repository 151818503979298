<template>
  <div v-on:keyup="key">
    <div class="block">
      <span class="is-size-6">From Date</span>
      <div class="field has-addons">
        <p class="control is-expanded">
          <input
            name="fromDate"
            type="date"
            v-model="fromDate"
            placeholder=""
          />
          <!-- <Datetime v-model="fromDate" value-zone="UTC" zone="UTC"></Datetime> -->
          <!--  </p>
        <p class="control"> -->
          <!-- <button class="button is-danger is-outlined" @click="clearFromDate">
            <span>Clear</span>
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button> -->
        </p>
      </div>
    </div>

    <div class="block">
      <span class="is-size-6">To Date</span>
      <div class="field has-addons">
        <p class="control is-expanded">
          <input name="toDate" type="date" v-model="toDate" placeholder="" />

          <!-- <input type="date" v-model="toDate" name="toDate" required /> -->

          <!--   <Datetime
            v-model="toDate"
            value-zone="UTC"
            zone="UTC"
            format="dd MMM yyyy"
          ></Datetime> -->
          <!-- </p>
        <p class="control"> -->
          <!--   <button class="button is-danger is-outlined" @click="clearToDate">
            <span>Clear</span>
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button> -->
        </p>
      </div>
    </div>

    <div class="block">
      <span class="is-size-6">Reporting Date</span>
      <div class="field has-addons">
        <p class="control is-expanded">
          <input
            name="reportDate"
            type="date"
            v-model="reportDate"
            placeholder=""
          />
          <!-- </p>
       
        <p class="control"> -->
          <!--  <button class="button is-danger is-outlined" @click="clearReportDate">
            <span>Clear</span> 
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button> -->
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
/* input[type="date"]:empty:-webkit-input-placeholder {
  color: transparent !important;
} */

input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: transparent;
}

input[type="date"]:default {
  color: transparent;
}

input[type="date"]::-webkit-clear-button {
  -webkit-appearance: auto;
}

input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

input[type="date"] {
  width: 100%;
  position: relative;
  height: 100%;
  font-family: "Segoe UI";
  font-size: "1.0em";
}

// style="width:100%;position: relative;height:100%; font-family:'Segoe UI'"
</style>

<script>
import { mapMutations } from "vuex";
import * as moment from "moment";
export default {
  name: "DateRangePicker",
  components: {},
  methods: {
    ...mapMutations("filterControl", [
      "clearFromDate",
      "clearToDate",
      "clearReportDate",
    ]),

    key: function (event) {
      if (
        event.key == "Delete" ||
        event.code == "Delete" ||
        event.keyCode == 46 ||
        event.keyCode == 8
      ) {
        if (event.path[0].name == "fromDate") {
          $("input[name=fromDate]").val("");
          this.clearFromDate();
        } else if (event.path[0].name == "toDate") {
          $("input[name=toDate]").val("");
          this.clearToDate();
        } else if (event.path[0].name == "reportDate") {
          $("input[name=reportDate]").val("");
          this.clearReportDate();
        }
      }
    },
  },
  computed: {
    fromDate: {
      get() {
        if (this.$store.state.filterControl.tmpFromDate == null) {
          return null;
        } else {
          if (this.$store.state.filterControl.tmpFromDate != "Invalid date") {
            return moment(this.$store.state.filterControl.tmpFromDate)
              .toISOString()
              .substr(0, 10);
          } else {
            return null;
          }
        }
      },
      set(value) {
        if (moment.utc(value).format() != "Invalid date") {
          this.$store.commit("filterControl/mutateDateRange", {
            fromDate: moment.utc(value).format(),
            toDate: null,
            reportDate: null,
          });
        } else {
          this.clearFromDate();
        }
      },
    },
    toDate: {
      get() {
        if (this.$store.state.filterControl.tmpToDate == null) {
          return null;
        } else {
          if (this.$store.state.filterControl.tmpToDate != "Invalid date") {
            return moment(this.$store.state.filterControl.tmpToDate)
              .toISOString()
              .substr(0, 10);
          } else {
            return null;
          }
        }

        //return this.$store.state.filterControl.tmpToDate;
      },
      set(value) {
        if (moment.utc(value).format() != "Invalid date") {
          this.$store.commit("filterControl/mutateDateRange", {
            fromDate: null,
            toDate: moment.utc(value).format(),
            reportDate: null,
          });
        } else {
          this.clearToDate();
        }
      },
    },
    reportDate: {
      get() {
        if (this.$store.state.filterControl.tmpReportDate == null) {
          return null;
        } else {
          if (this.$store.state.filterControl.tmpReportDate != "Invalid date") {
            return moment(this.$store.state.filterControl.tmpReportDate)
              .toISOString()
              .substr(0, 10);
          } else {
            return null;
          }
        }

        //return this.$store.state.filterControl.tmpReportDate;
      },
      set(value) {
        if (moment.utc(value).format() != "Invalid date") {
          this.$store.commit("filterControl/mutateDateRange", {
            fromDate: null,
            toDate: null,
            reportDate: moment.utc(value).format(),
          });
        } else {
          this.clearReportDate();
        }
      },
    },
  },
  data() {
    return {};
    //return this.$store.state.filterControl.fromDate;
  },
};
</script>
