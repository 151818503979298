<template>
  <div class="modal is-active is-overlay">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button
          class="delete"
          aria-label="close"
          @click="toggleRelationFilterModal"
        ></button>
      </header>
      <section class="modal-card-body">
        <!-- Structure Relation Tabs -->
        <div class="tabs is-centered is-boxed">
          <ul>
            <li
              :class="{ 'is-active': 'portfolios' == selectedTab }"
              @click="selectedTab = 'portfolios'"
              v-if="isPortfolio"
            >
              <a>
                <LevelIconCellRenderer :value="RelationsName[0]" />
                <span>Portfolios</span>
              </a>
            </li>
            <li
              :class="{ 'is-active': 'programs' == selectedTab }"
              @click="selectedTab = 'programs'"
              v-if="isProgram"
            >
              <a>
                <LevelIconCellRenderer :value="RelationsName[1]" />
                <span>Programs</span>
              </a>
            </li>
            <li
              :class="{ 'is-active': 'projects' == selectedTab }"
              @click="selectedTab = 'projects'"
            >
              <a>
                <LevelIconCellRenderer :value="RelationsName[2]" />
                <span>Projects</span>
              </a>
            </li>
          </ul>
        </div>

        <!-- Structure Relation Section -->
        <div id="global-filter-section-content" class="section">
          <div class="field is-grouped" v-show="'date' != selectedTab">
            <div class="control has-icons-left is-expanded">
              <input
                class="input"
                type="email"
                placeholder="Relation"
                v-model="partialName"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-search"></i>
              </span>
            </div>
            <div class="control buttons has-addons">
              <button class="button" @click="allSelect">Select</button>
              <button class="button" @click="allUnselect">Unselect</button>
              <button class="button" @click="allToggle">Toggle</button>
            </div>
          </div>

          <!-- Portfolio Tab -->
          <div
            v-for="item in portfolios"
            :key="'chkPf' + item.id"
            v-show="'portfolios' == selectedTab"
            class="field"
          >
            <div class="control" v-show="partialMatch(item, chkPortfolios)">
              <label class="checkbox" :for="'chkPf' + item.id">
                <input
                  type="checkbox"
                  :id="'chkPf' + item.id"
                  :name="'chkPf' + item.id"
                  :value="item.description"
                  v-model="chkPortfolios[item.id]"
                />
                {{ item.code }} - {{ item.description }}
              </label>
            </div>
          </div>

          <!-- Programs Tab -->
          <div
            v-for="item in programs"
            :key="'chkPrg' + item.id"
            v-show="'programs' == selectedTab"
            class="field"
          >
            <div class="control" v-show="partialMatch(item, chkPrograms)">
              <label class="checkbox" :for="'chkPrg' + item.id">
                <input
                  type="checkbox"
                  :id="'chkPrg' + item.id"
                  :name="'chkPrg' + item.id"
                  :value="item.description"
                  v-model="chkPrograms[item.id]"
                />
                {{ item.code }} - {{ item.description }}
              </label>
            </div>
          </div>

          <!-- Projects Tab -->
          <div
            v-for="item in projects"
            :key="'chkPrj' + item.id"
            v-show="'projects' == selectedTab"
            class="field"
          >
            <div class="control" v-show="partialMatch(item, chkProjects)">
              <label class="checkbox" :for="'chkPrj' + item.id">
                <input
                  type="checkbox"
                  :id="'chkPrj' + item.id"
                  :name="'chkPrj' + item.id"
                  :value="item.description"
                  v-model="chkProjects[item.id]"
                />
                {{ item.code }} - {{ item.description }}
              </label>
            </div>
          </div>
        </div>
        <div></div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="saveUserRelations">
          Apply
        </button>
        <button class="button" @click="CancelEvent">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import LevelIconCellRenderer from "../Data/LevelIconCellRenderer.vue";
const alertlabel = {
  isError: false,
  message: null,
};

export default {
  name: "StructureRelation",
  components: {
    LevelIconCellRenderer,
  },
  data() {
    return {
      chkPortfolios: {},
      chkPrograms: {},
      chkProjects: {},
      selectedTab: "programs",
      partialName: "",
      alertlabel,
      isPortfolio: false,
      isProject: false,
      isProgram: false,
      isSelectedId: false,
      title: "Relations",
      scheduleHeader: false,
      riskHeader: false,
      performanceHeader: false,
      dataP3MModule: true,
      RelationsName: [
        "RelationPortfolio",
        "RelationProgram",
        "RelationProject",
      ],
    };
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("relationControl", ["relation"]),
    ...mapGetters("dataManager", [
      "selectedDataType",
      "resetSelectedDataType",
      "getRelationshipStatus",
      "getSelectedRowObject",
    ]),
    ...mapGetters("schedulingDataManager", ["allDataTypes"]),

    ...mapGetters("costHeaderController", ["getcostHeaderStatus"]),

    ...mapGetters("riskController", ["getriskHeaderStatus"]),

    ...mapGetters("performanceSheetManager", ["getperformanceHeaderStatus"]),
    ...mapGetters("schedulingDataManager", ["getscheduleHeaderStatus"]),
    ...mapState({
      portfolios: (state) => state.relationControl.portfolios,
      programs: (state) => state.relationControl.programs,
      projects: (state) => state.relationControl.projects,
      chkPortfoliosval: (state) => state.relationControl.chkPortfolios,
      chkProgramsval: (state) => state.relationControl.chkPrograms,
      chkProjectsval: (state) => state.relationControl.chkProjects,
      RelationDataWatcher: (state) => state.relationControl.relationWatcher,
    }),
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    RelationDataWatcher() {
      this.reloadRelations();
    },
  },
  created() {},
  mounted() {
    this.reloadRelations();
  },
  methods: {
    ...mapMutations("relationControl", ["toggleRelationFilterModal"]),
    ...mapMutations("dataManager", ["mutateRealtionship"]),
    ...mapMutations("costHeaderController", ["mutateCostHeaderRealtionship"]),

    ...mapMutations("riskController", ["mutateRiskHeaderRealtionship"]),

    ...mapMutations("performanceSheetManager", [
      "mutatePerformanceHeaderRealtionship",
    ]),
    ...mapMutations("userView", ["requestDataUpload"]),
    ...mapActions({
      saveRelations: "relationControl/saveRelations",
      GetRelations: "relationControl/GetRelations",
      saveCostRelations: "relationControl/saveCostRelations",
      saveScheduleRelations: "relationControl/saveScheduleRelations",
      saveRiskHeaderRelations: "relationControl/saveRiskHeaderRelations",
      savePerformanceHeaderRelations:
        "relationControl/savePerformanceHeaderRelations",
    }),
    reloadRelations() {
      /*Change Views Options and Config*/
      if (this.getRelationshipStatus || this.getcostHeaderStatus) {
        this.isSelectedId = true;

        if (this.selectedDataType.id == 0 || this.selectedDataType.id == 3) {
          this.isProject = true;
          this.isProgram = true;
          this.selectedTab = "programs";
          this.isPortfolio = false;
          if (this.selectedDataType.id == 0) {
            this.dataP3MModule = true;
          }
        } else if (this.selectedDataType.id == 1) {
          this.isProject = true;
          this.isProgram = false;
          this.selectedTab = "portfolios";
          this.isPortfolio = true;
          this.dataP3MModule = true;
        }
        this.title = "Add Relationship";
        this.mutateRealtionship(false);
        this.mutateCostHeaderRealtionship(false);
        this.mutatePerformanceHeaderRealtionship(false);

        this.mutateRiskHeaderRealtionship(false);
      } else if (this.getscheduleHeaderStatus) {
        this.scheduleHeader = true;
        this.resetSelectedDataType;
        this.isSelectedId = true;
        this.isProject = true;
        this.isProgram = true;
        this.selectedTab = "programs";
        this.isPortfolio = false;
      } else if (this.getriskHeaderStatus) {
        this.riskHeader = true;
        //this.resetSelectedDataType;
        this.isSelectedId = true;
        this.isProject = true;
        this.isProgram = true;
        this.selectedTab = "portfolios";
        this.isPortfolio = true;
        this.mutatePerformanceHeaderRealtionship(false);
      } else if (this.getperformanceHeaderStatus) {
        this.performanceHeader = true;
        //this.resetSelectedDataType;
        this.isSelectedId = true;
        this.isProject = true;
        this.isProgram = true;
        this.selectedTab = "portfolios";
        //console.log(this.getperformanceHeaderStatus);
        this.mutateRiskHeaderRealtionship(false);
        this.isPortfolio = true;
      }
      // reset Relations
      this.chkPortfolios = {};
      this.chkPrograms = {};
      this.chkProjects = {};
      // if Relations not loaded for some reason, reload it

      /* this.RelationDataWatcher < 1 */
      // TODO: find current `client` and load Relations for it

      if (this.selectedDataType.id < 3 && this.dataP3MModule) {
        this.GetRelations({
          relationID: this.getSelectedRowObject.id,
          DataID: this.selectedDataType.id,
        });
        this.isSelectedId = false;
        this.dataP3MModule = false;
        return;
      }
      const relation = this.relation;

      if (this.selectedDataType.id == 1) {
        this.chkPortfolios = this.chkPortfoliosval;
      } else if (
        this.selectedDataType.id == 0 ||
        this.selectedDataType.id == 3
      ) {
        this.chkPrograms = this.chkProgramsval;
      } else if (
        this.selectedDataType.id == 7 ||
        this.selectedDataType.id == 6
      ) {
        this.chkPortfolios = this.chkPortfoliosval;
        this.chkPrograms = this.chkProgramsval;
      } else {
        this.chkPrograms = this.chkProgramsval;
      }
      this.chkProjects = this.chkProjectsval;
    },
    CancelEvent() {
      this.reloadRelations();
      this.mutateRiskHeaderRealtionship(false);
      this.mutatePerformanceHeaderRealtionship(false);
      this.toggleRelationFilterModal();
    },
    saveUserRelations() {
      let relation = [];
      let closureAddSelected = ([key, isSelected]) => (isSelected ? [key] : []);
      let flatMapAddSelected = (baseModel) =>
        Object.entries(baseModel).map(closureAddSelected).flat(1);
      relation.selectedProjects = flatMapAddSelected(this.chkProjects);
      if (this.selectedDataType.id == 0) {
        relation.selectedPortfolios = [this.getSelectedRowObject.id];
        relation.selectedPrograms = flatMapAddSelected(this.chkPrograms);
      } else if (this.selectedDataType.id == 1) {
        relation.selectedPortfolios = flatMapAddSelected(this.chkPortfolios);
        relation.selectedPrograms = [this.getSelectedRowObject.id];
      } else if (this.selectedDataType.id == 3) {
        relation.selectedPrograms = flatMapAddSelected(this.chkPrograms);
      } else if (
        this.selectedDataType.id == 7 ||
        this.selectedDataType.id == 6
      ) {
        relation.selectedPortfolios = flatMapAddSelected(this.chkPortfolios);
        relation.selectedPrograms = flatMapAddSelected(this.chkPrograms);
      } else {
        relation.selectedPrograms = flatMapAddSelected(this.chkPrograms);
      }
      //console.log(this.getperformanceHeaderStatus, this.getriskHeaderStatus);
      if (this.getscheduleHeaderStatus) {
        //this.mut
        this.saveScheduleRelations({
          relation: relation,
        });
      } else if (this.getriskHeaderStatus) {
        this.mutateRiskHeaderRealtionship(false);
        this.saveRiskHeaderRelations({
          relation: relation,
        });
      } else if (this.getperformanceHeaderStatus) {
        this.mutatePerformanceHeaderRealtionship(false);
        this.savePerformanceHeaderRelations({
          relation: relation,
        });
      } else {
        if (this.selectedDataType.id < 3) {
          this.saveRelations({
            relation: relation,
            typeName: this.selectedDataType.name,
          });
        } else if (this.selectedDataType.id == 3) {
          this.saveCostRelations({
            relation: relation,
          });
        }
      }

      this.toggleRelationFilterModal();
    },
    saveRelation() {
      alertlabel.isError = false;
      alertlabel.message = null;

      this.toggleRelationFilterModal();
    },
    partialMatch(item, model) {
      if (!this.partialName) {
        return true;
      } else if (item.id < 0) {
        return true;
      } else if ((item && item.description) || (item && item.code)) {
        return (
          item.description
            .toLowerCase()
            .indexOf(this.partialName.toLowerCase()) > -1 ||
          item.code.toLowerCase().indexOf(this.partialName.toLowerCase()) > -1
        );
      } else if (item && item.code) {
        return (
          item.code.toLowerCase().indexOf(this.partialName.toLowerCase()) > -1
        );
      } else if (model && model && model[item.id]) {
        return true;
      } else {
        return false;
      }
    },
    allSelect() {
      if (this.selectedTab == "portfolios") {
        for (const [key] of Object.entries(this.chkPortfolios)) {
          this.chkPortfolios[key] = true;
        }
        this.chkPortfolios = { ...this.chkPortfolios };
      } else if (this.selectedTab == "programs") {
        for (const [key] of Object.entries(this.chkPrograms)) {
          this.chkPrograms[key] = true;
        }
        this.chkPrograms = { ...this.chkPrograms };
      } else if (this.selectedTab == "projects") {
        for (const [key] of Object.entries(this.chkProjects)) {
          this.chkProjects[key] = true;
        }
        this.chkProjects = { ...this.chkProjects };
      }
    },
    allUnselect() {
      if (this.selectedTab == "portfolios") {
        for (const [key] of Object.entries(this.chkPortfolios)) {
          this.chkPortfolios[key] = false;
        }
        this.chkPortfolios = { ...this.chkPortfolios };
      } else if (this.selectedTab == "programs") {
        for (const [key] of Object.entries(this.chkPrograms)) {
          this.chkPrograms[key] = false;
        }
        this.chkPrograms = { ...this.chkPrograms };
      } else if (this.selectedTab == "projects") {
        for (const [key] of Object.entries(this.chkProjects)) {
          this.chkProjects[key] = false;
        }
        this.chkProjects = { ...this.chkProjects };
      }
    },
    allToggle() {
      if (this.selectedTab == "portfolios") {
        for (const [key, value] of Object.entries(this.chkPortfolios)) {
          this.chkPortfolios[key] = value ? false : true;
        }
        this.chkPortfolios = { ...this.chkPortfolios };
      } else if (this.selectedTab == "programs") {
        for (const [key, value] of Object.entries(this.chkPrograms)) {
          this.chkPrograms[key] = value ? false : true;
        }
        this.chkPrograms = { ...this.chkPrograms };
      } else if (this.selectedTab == "projects") {
        for (const [key, value] of Object.entries(this.chkProjects)) {
          this.chkProjects[key] = value ? false : true;
        }
        this.chkProjects = { ...this.chkProjects };
      }
    },
  },
};
</script>

<style lang="scss">
#global-filter-section-content {
  height: 25vw;
  padding-top: 0px;
}
.danger {
  color: #f44336;
}
/* Red */
</style>
