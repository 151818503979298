<template>
  <nav
    class="navbar"
    role="navigation"
    aria-label="main navigation"
    style="border-bottom: 2px solid; margin-bottom: 5px"
  >
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-end" style="margin-right: 18px">
        <button
          class="button"
          title="Save"
          v-if="currentRouteName != 'login'"
          @click="saveStructureRecords"
        >
          <!--  :disabled="getCurveIdvalue == null" -->
          <span class="icon is-small">
            <i class="fas fa-save"></i>
          </span>
        </button>
        <button
          class="button"
          title="Reset Columns"
          v-if="currentRouteName == 'login'"
        >
          <span class="icon is-small">
            <i class="fas fa-text-width"></i>
          </span>
        </button>

        <button
          class="button"
          title="Undo"
          v-if="currentRouteName != 'login'"
          @click="requestUndo"
          :disabled="getisUndo == false"
        >
          <span class="icon is-small">
            <i class="fas fa-undo"></i>
          </span>
        </button>

        <button
          class="button"
          title="Redo"
          v-if="currentRouteName != 'login'"
          @click="requestRedo"
          :disabled="getisRedo == false"
        >
          <span class="icon is-small">
            <i class="fas fa-redo"></i>
          </span>
        </button>

        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add New"
          @click="mutateAddRecord"
          :disabled="getCurveIdvalue == null"
        >
          <span class="icon is-small">
            <i class="fas fa-plus"></i>
          </span>
        </button>

        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Delete"
          @click="mutateDelete"
          :disabled="getSelectedRowObject == 0"
        >
          <!--  @click="saveRecords" @click="deleteRecord"> -->
          <span class="icon is-small">
            <i class="fas fa-trash-alt"></i>
          </span>
        </button>

        <!-- <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add Sub Record"
          @click="mutateSubRecord"
          :disabled="
            Object.keys(getSelectedRowObject).length < 1 ||
              getSelectedRowObject == 0
          "
        >
          <span class="icon is-small">
            <i class="far fa-plus-square"></i>
          </span>
        </button> -->
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
p {
  width: 165px;
  display: inline-block;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}
nav.navbar {
  border-top: 0px solid black;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "SpendingCurveRight",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("spendingcurveManager", [
      "getCurveIdvalue",
      "getSelectedRowObject",
      "getisRedo",
      "getisUndo",
      "getDetailIdvalue",
    ]),

    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapMutations("spendingcurveManager", [
      "mutateSubRecord",
      "mutateAddRecord",
      "requestUndo",
      "requestRedo",
      "mutateSaveCounter",
      "mutateDelete",
    ]),
    //...mapActions("spendingcurveManager", ["saveStructureData"]),
    saveStructureRecords() {
      this.mutateSaveCounter();
    },
  },
};
</script>
