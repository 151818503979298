<template>
  <div
    class="container is-fluid mb-6"
    style="overflow: auto"
    v-if="this.getLoadingStatus"
  >
    <h2 class="title" style="font-family: Verdana; font-size: 1.2vw"></h2>
    <div class="is-multiline dashboard-widget-container">
      <div
        class="columns is-gapless"
        v-for="currCard in cards"
        :key="currCard.id"
      >
        <div class="column" v-if="currCard.title == null">
          <div
            class="card"
            style="margin: auto; text-align: center; height: 110%"
          >
            <h1
              style="
                font-family: Verdana;
                font-size: 1.1vw;
                width: inherit;
                margin-left: 50px;
              "
            >
              <b> {{ currCard.heading }} </b>
            </h1>
          </div>
        </div>

        <div class="column" v-else>
          <div class="card">
            <header class="card-header">
              <p class="card-header-title is-size-4 has-text-weight-semibold">
                {{ currCard.title }}
              </p>
            </header>
            <h1 style="font-family: Verdana; font-size: 1vw; margin-left: 50px">
              <b> {{ currCard.heading }} </b>
            </h1>
            <div
              class="card-content"
              style="margin-left: 15px"
              v-if="currCard.charts.length != null"
            >
              <div class="columns is-ancestor is-gapless">
                <div v-for="currChart in currCard.charts" :key="currChart.id">
                  <div v-if="currChart.formate == 'layout1'">
                    <div class="tile is-ancestor">
                      <div class="tile is-parent">
                        <div class="tile is-vertical">
                          <article class="tile" v-if="currChart.id <= 3">
                            <Chart
                              :data="currChart.data"
                              :config="currChart.config"
                              :layout="currChart.layout"
                              :displayModeBar="currChart.displayModeBar"
                            />
                          </article>
                          <div class="tile" v-if="currChart.id == 3">
                            <Chart
                              :data="currCard.charts[3].data"
                              :config="currCard.charts[3].config"
                              :layout="currCard.charts[3].layout"
                              :displayModeBar="
                                currCard.charts[3].displayModeBar
                              "
                            />
                          </div>
                          <div class="tile" v-if="currChart.id == 3">
                            <Chart
                              :data="currCard.charts[4].data"
                              :config="currCard.charts[4].config"
                              :layout="currCard.charts[4].layout"
                              :displayModeBar="
                                currCard.charts[4].displayModeBar
                              "
                            />
                            <Chart
                              :data="currCard.charts[5].data"
                              :config="currCard.charts[5].config"
                              :layout="currCard.charts[5].layout"
                              :displayModeBar="
                                currCard.charts[5].displayModeBar
                              "
                            />
                            <Chart
                              :data="currCard.charts[6].data"
                              :config="currCard.charts[6].config"
                              :layout="currCard.charts[6].layout"
                              :displayModeBar="
                                currCard.charts[6].displayModeBar
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="currChart.formate == 'layout2'">
                    <div class="tile is-ancestor">
                      <div class="tile is-vertical">
                        <div class="tile">
                          <div class="content">
                            <Chart
                              :data="currChart.data"
                              :config="currChart.config"
                              :layout="currChart.layout"
                              :displayModeBar="currChart.displayModeBar"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column"></div>
    </div>
  </div>
  <div class="column" v-else>
    <!-- <div style="text-align: center" v-if="isError">
      <h1 style="font-size: 7em">204</h1>
      <h2>error: Data return is null.</h2>
    </div> -->
    <div>
      <CraneLoading />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import Chart from "../components/Plots/Chart.vue";
import CraneLoading from "@/components/loading/craneLoading.vue";
export default {
  name: "Dashboard",
  components: {
    Chart,
    CraneLoading,
  },
  data() {
    return {
      isLoaded: false,
      loadingTemplate: `<span class="overlay">Data is loading...</span>`,
    };
  },
  computed: {
    ...mapGetters("account", ["loggedIn", "clientId"]),
    ...mapGetters("userView", ["getDashboardViews"]),
    ...mapGetters("dashboard", ["dashboardName", "getLoadingStatus"]),
    ...mapState("filterControl", ["filterWatcher"]),
    ...mapGetters("filterControl", ["filters"]),
    ...mapState({
      charts: (state) => state.dashboard.charts,
      cards: (state) => state.dashboard.cards,
    }),
    idicdata() {
      return this.charts[0].data;
    },
  },
  watch: {
    filterWatcher() {
      // NOTE: don't load rows on initial filter load, this is already done via `onGridReady`
      // NOTE: we also have to reload months as they may change as well
      //console.log(this.filterWatcher);
      if (this.filterWatcher >= 2) {
        this.data();
        //this.reloadGlobalState(); */
      }
    },
  },
  methods: {
    ...mapActions("account", ["reloadGlobalState"]),
    ...mapActions({
      LoadDashboard: "dashboard/LoadDashboard",
    }),
    ...mapMutations("dashboard", ["storeapivalues", "changeLoadingStatus"]),
    data() {
      this.$store.commit("dashboard/changeLoadingStatus", {
        loadingstatus: false,
      });
      if (this.getDashboardViews.length > 0) {
        var projectViewID = this.getDashboardViews.filter(
          (x) => x.componentId == 30
        );
        this.LoadDashboard({
          reportDate: this.filters.Report,
          viewID: projectViewID[0].id,
        });
      }
    },
  },
  mounted: function () {
    if (this.loggedIn) {
      //this.data();
      this.reloadGlobalState();
    }
  },
  beforeMount() {
    this.$store.commit("dashboard/changeLoadingStatus", {
      loadingstatus: false,
    });
  },
};
</script>
<style scoped lang="scss">
.header-button {
  color: #d4d6da;
}

.body-text-style {
  font-weight: 500;
}

.body-number-style {
  color: #2c3c8d;
  font-weight: bold;
}

.card {
  overflow: auto;
  height: fit-content();
}

.card-content {
  overflow: auto;
}

.box-padding {
  padding-left: 1rem;
}

.header {
  grid-area: header;
  background-color: #648ca6;
}

.dashboard-widget-container {
  padding: 1px !important;
  box-sizing: content-box;
  display: block;
  margin-left: -10px;
}
</style>
