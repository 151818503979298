/* eslint-disable no-case-declarations */
import { post } from "../api/user-auth.js";
import * as moment from "moment";
import * as utilitiesModule from "@/assets/utilities.js";
import {
  DeleteProject,
  DeleteProgram,
  DeletePortfolio,
  UpdateMasterData,
  getAllExtraColumnDetails,
  getColumnDef,
} from "@/api/networking.js";

const state = {
  currentData: [],
  dataWatcher: 0,
  dataTypes: [
    {
      id: 0,
      name: "Portfolios",
      displayName: "Portfolios",
      API: "Portfolio",
      iconClass: "fas fa-folder-open",
      componentId: 6,
    },
    {
      id: 1,
      name: "Programs",
      displayName: "Programs",
      API: "Program",
      iconClass: "fas fa-sitemap",
      componentId: 7,
    },
    {
      id: 2,
      name: "Project",
      displayName: "Project",
      API: "Project",
      iconClass: "fas fa-diagram",
      componentId: 8,
    },
    {
      id: 3,
      name: "Costs",
      displayName: "Costs",
      iconClass: "fas fa-diagram",
      componentId: 5,
    },
    {
      id: 4,
      name: "ScheduleHeader",
      displayName: "Schedule",
      API: "SCHEDULEHEADER",
      iconClass: "fas fa-folder-open",
      apiPrefix: "ScheduleHeader",
      componentId: 9,
      access: "ReadOnly",
    },
    {
      id: 5,
      name: "ERP",
      displayName: "ERP",
      API: "activity",
      iconClass: "fas fa-folder-open",
      apiPrefix: "EnterpriseResourceHandler",
    },
    {
      id: 6,
      name: "PerformanceSheet",
      displayName: "Performance Sheet",
    },
    {
      id: 7,
      name: "Risk",
      displayName: "Risk",
    },
  ],
  projectColDef: [
    {
      headerName: "Code",
      field: "code",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 90,
      suppressSizeToFit: true,
      cellRenderer: function (params) {
        if (params.data.rowUpdated == "Duplicate") {
          let eIconGui = document.createElement("span");
          return (eIconGui.innerHTML =
            '<i class="fas fa-exclamation" style="color:red;"></i>' +
            " " +
            params.data.code);
        }
        return params.data.code;
      },
    },
    {
      headerName: "Description",
      field: "description",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 300,
    },
    {
      headerName: "Start Date",
      field: "startDate",
      filter: "agSetColumnFilter",
      resizable: true,
      sortable: true,
      editable: true,
      filterParams: { excelMode: "windows" },
      valueFormatter: function (params) {
        if (params.value != "Invalid date" && params.value != null) {
          return moment(params.value)
            .toISOString()
            .replace(/T.*/, "")
            .split("-")
            .reverse()
            .join("/");
        } else {
          params.value = null;
          return null;
        }
      },
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      cellEditor: "datePicker",
      width: 165,
    },
    {
      headerName: "End Date",
      field: "endDate",
      filter: "agSetColumnFilter",
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      editable: true,
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      valueFormatter: function (params) {
        if (params.value != "Invalid date" && params.value != null) {
          return moment(params.value)
            .toISOString()
            .replace(/T.*/, "")
            .split("-")
            .reverse()
            .join("/");
        } else {
          params.value = null;
          return null;
        }
      },
      cellEditor: "datePicker",
      width: 165,
    },
    {
      headerName: "Comment",
      field: "comment",
      width: 190,
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
    },
    {
      headerName: "Project Option",
      field: "projectOption",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 127,
    },
  ],
  programColDef: [
    {
      headerName: "Code",
      field: "code",
      editable: true,
      sortable: true,
      resizable: true,
      width: 305,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      cellRenderer: function (params) {
        if (params.data.rowUpdated == "Duplicate") {
          let eIconGui = document.createElement("span");
          return (eIconGui.innerHTML =
            '<i class="fas fa-exclamation" style="color:red;"></i>' +
            " " +
            params.data.code);
        }
        return params.data.code;
      },
    },
    {
      headerName: "Description",
      field: "description",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      width: 405,
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
    },
  ],
  portfolioColDef: [
    {
      headerName: "Code",
      field: "code",
      sortable: true,
      resizable: true,
      width: 100,
      filterParams: { excelMode: "windows" },
      editable: true,
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      cellRenderer: function (params) {
        if (params.data.rowUpdated == "Duplicate") {
          let eIconGui = document.createElement("span");
          return (eIconGui.innerHTML =
            '<i class="fas fa-exclamation" style="color:red;"></i>' +
            " " +
            params.data.code);
        }
        return params.data.code;
      },
    },
    {
      headerName: "Name",
      field: "description",
      sortable: true,
      resizable: true,
      width: 405,
      editable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
    },
  ],
  extraColumnDef: [],
  selectedDataTypeId: 0,
  selectedRowObj: {},
  addingRecord: false,
  isRelationship: false,
  editCount: 0,
  undoWatcher: 0,
  redoWatcher: 0,
  isRedo: false,
  isUndo: false,
  AddCounter: 0,
  DeleteCounter: 0,
  isDelete: 0,
  isDuplicate: 0,
  isSaveCounter: 0,
  extracolumnCounter: 0,
  columnDefCounter: 0,
};

const actions = {
  setSelectedData({ commit }, selectedDataTypeName) {
    selectedDataTypeName == "costs"
      ? (selectedDataTypeName = "Costs")
      : selectedDataTypeName;

    selectedDataTypeName == "scheduleHeader"
      ? (selectedDataTypeName = "ScheduleHeader")
      : selectedDataTypeName;
    console.log("selectedDataTypeName", selectedDataTypeName);

    console.log(
      "selectedDT",
      state.dataTypes.find((x) => x.name == selectedDataTypeName)
    );
    commit("mutateSelectedDataType", {
      selectedDataTypeId: state.dataTypes.find(
        (x) => x.name == selectedDataTypeName
      ).id,
    });
    console.log("Post", state.selectedDataTypeId);
  },
  async LoadDataMangerColDef({ dispatch }, selectedDataTypeid) {
    var componentId = state.dataTypes.find(
      (dt) => dt.id == selectedDataTypeid
    ).componentId;
    getColumnDef(componentId)
      .then((moduleBlob) => {
        // get blob
        // create script element
        // @onload use loaded global function from script
        let container = this.$refs.scriptContainer;
        var moduleUrl = URL.createObjectURL(moduleBlob);
        let scriptEl = document.createElement("script");
        scriptEl.type = "text/javascript";
        // NOTE: need eslint disable line because of dynamic function
        scriptEl.onload = () => {
          __hydrateColumns(this, utilitiesModule);
        }; // eslint-disable-line
        scriptEl.src = moduleUrl;
        //    container.appendChild(scriptEl);
        URL.revokeObjectURL(moduleUrl);
        console.log(moduleBlob);

        //state.projectColDef = tempaa[1];
        state.columnDefCounter++;
        /* } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        } */
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async LoadP3MExtraColumn({ dispatch }, selectedDataTypeid) {
    state.extraColumnDef = [];
    var APIName =
      state.dataTypes.find((dt) => dt.id == selectedDataTypeid).API +
      "ExtraColumn";
    getAllExtraColumnDetails(APIName)
      .then((rowData) => {
        if (rowData.succeeded) {
          rowData.data.forEach(function (y) {
            state.extraColumnDef.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              //   width: 100,
              editable: true,
              filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: "OR",
              },
              filter: "agSetColumnFilter",
              cellStyle: function (params) {
                if (params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                }
                if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return new Date(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            });
          });

          state.extracolumnCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async deleteMetadata(
    { dispatch, commit },
    { projectId, portfolioId, programId }
  ) {
    switch (state.selectedDataTypeId) {
      case 0:
        DeletePortfolio(portfolioId)
          .then((rowData) => {
            if (rowData.succeeded) {
              state.isDelete++;
            } else {
              dispatch("alert/error", "Network Connection lost", {
                root: true,
              });
            }
          })
          .catch((error) => {
            dispatch("alert/error", error, { root: true });
          });
        break;
      case 1:
        DeleteProgram(programId)
          .then((rowData) => {
            if (rowData.succeeded) {
              state.isDelete++;
            } else {
              dispatch("alert/error", "Network Connection lost", {
                root: true,
              });
            }
          })
          .catch((error) => {
            dispatch("alert/error", error, { root: true });
          });
        break;
      case 2:
        DeleteProject(projectId)
          .then((rowData) => {
            if (rowData.succeeded) {
              state.isDelete++;
            } else {
              dispatch("alert/error", "Network Connection lost", {
                root: true,
              });
            }
          })
          .catch((error) => {
            dispatch("alert/error", error, { root: true });
          });
    }
  },

  async saveData({ dispatch, commit }) {
    var rowsToInsert = [];
    var rowsToUpdate = [];
    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {};
    switch (state.selectedDataTypeId) {
      case 0:
        state.currentData.forEach((row) => {
          if (row.rowUpdated) {
            if (row.id == -1) {
              rowsToInsert.push(row);
            } else {
              rowsToUpdate.push({
                Id: row.id,
                code: row.code,
                description: row.description,
                ExtraColumnValues: row.extraColumnValues,
              });
            }
          }
        });
        if (rowsToInsert.length > 0) {
          createURL = "Portfolio/Create";
          createObj = { PortfoliosToCreate: rowsToInsert };
        }
        if (rowsToUpdate.length > 0) {
          updateURL = "Portfolio/Update";
          updateObj = { PortfoliosToUpdate: rowsToUpdate };
        }
        break;
      case 1:
        state.currentData.forEach((row) => {
          if (row.rowUpdated) {
            if (row.id == -1) {
              rowsToInsert.push(row);
            } else {
              rowsToUpdate.push({
                Id: row.id,
                code: row.code,
                description: row.description,
                ExtraColumnValues: row.extraColumnValues,
              });
            }
          }
        });
        if (rowsToInsert.length > 0) {
          createURL = "Program/Create";
          createObj = { ProgramsToCreate: rowsToInsert };
        }
        if (rowsToUpdate.length > 0) {
          updateURL = "Programs/Update";
          updateObj = { ProgramsToUpdate: rowsToUpdate };
        }
        break;
      case 2:
        state.currentData.forEach((row) => {
          if (row.rowUpdated) {
            if (row.id == -1) {
              rowsToInsert.push(row);
            } else {
              rowsToUpdate.push(row);
            }
          }
        });
        if (rowsToInsert.length > 0) {
          createURL = "Project/Create";
          createObj = { ProjectsToCreate: rowsToInsert };
        }
        if (rowsToUpdate.length > 0) {
          updateURL = "Projects/Update";
          updateObj = { ProjectsToUpdate: rowsToUpdate };
        }
        break;
    }
    if (createURL != "" && updateURL == "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values.data) returnedValues = values.data;
          if (values.data) returnedValues = returnedValues.concat(values.data);
          commit("setSavedData", { returnedSavedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = UpdateMasterData(updateObj, state.selectedDataTypeId);
      Promise.all([createP, updateP])
        .then((values) => {
          var returnedValues = [];
          if (values[0].data) returnedValues = values[0].data;
          if (values[1].data)
            returnedValues = returnedValues.concat(values[1].data);
          commit("setSavedData", { returnedSavedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
  },
};

const mutations = {
  mutateSelectedDataType(state, { selectedDataTypeId }) {
    state.selectedDataTypeId = selectedDataTypeId;
  },
  addDataRecord() {
    state.addingRecord = true;
    state.currentData.push({
      id: -1,
      code: "",
      description: "",
      extraColumnValues: [],
      rowUpdated: false,
    });
    state.AddCounter++;
  },
  setCurrentData(state, { returnedData }) {
    returnedData.forEach((row) => (row.rowUpdated = false));
    if (state.selectedDataTypeId == 0) {
      returnedData.forEach(function (x) {
        if (x.extraColumnValues != null) {
          x.extraColumnValues.forEach(
            (y) => (x["et_" + y.portfolioExtraColumnId] = y.value)
          );
        }
      });
    } else if (state.selectedDataTypeId == 1) {
      returnedData.forEach(function (x) {
        if (x.extraColumnValues != null) {
          x.extraColumnValues.forEach(
            (y) => (x["et_" + y.programExtraColumnId] = y.value)
          );
        }
      });
    } else if (state.selectedDataTypeId == 2) {
      returnedData.forEach(function (x) {
        if (x.extraColumnValues != null) {
          x.extraColumnValues.forEach(
            (y) => (x["et_" + y.projectExtraColumnId] = y.value)
          );
        }
      });
    }

    state.currentData = returnedData.sort((a, b) => (a.code > b.code ? 1 : -1));
  },

  mutateExtaColumn(state) {
    state.extracolumnCounter++;
  },

  setSavedData(state, { returnedSavedData }) {
    if (state.selectedRowObj.id < 0) {
      state.selectedRowObj = returnedSavedData[returnedSavedData.length - 1];
    }
    state.editCount = 0;
    state.isDuplicate = 0;
    state.currentData = returnedSavedData.sort((a, b) =>
      a.code > b.code ? 1 : -1
    );
    state.currentData.forEach((row) => (row.rowUpdated = false));
    state.dataWatcher += 1;
  },

  setSelectedRowObject(state, selectedObj) {
    state.editCount = 0;
    state.isDuplicate = 0;
    state.selectedRowObj = selectedObj;
  },

  mutateRealtionship(state, isRelationship) {
    state.isRelationship = isRelationship;
  },

  mutateEditCount(state) {
    state.editCount++;
  },

  requestUndo(state) {
    state.undoWatcher += 1;
  },
  requestRedo(state) {
    state.redoWatcher += 1;
  },

  resetRequestUndo(state, Undovalue) {
    state.isUndo = Undovalue;
  },

  resetRequestRedo(state, Redovalue) {
    state.isRedo = Redovalue;
  },

  mutateDelete(state) {
    state.DeleteCounter++;
  },

  mutateDuplicate(state) {
    state.isDuplicate++;
  },

  mutateSave(state) {
    state.isSaveCounter++;
  },
};

const getters = {
  getDataWatcher() {
    return state.dataWatcher;
  },
  getSelectedRowObject() {
    return state.selectedRowObj;
  },
  selectedDataType({ selectedDataTypeId, dataTypes }) {
    state.selectedRowObj = {};
    state.AddCounter = 0;
    state.isDelete = 0;
    state.DeleteCounter = 0;
    state.isSaveCounter = 0;
    return dataTypes.find((dt) => dt.id == selectedDataTypeId);
  },

  resetSelectedDataType() {
    return state.dataTypes.find((dt) => dt.id == -1);
  },
  addingRecord({ addingRecord }) {
    return addingRecord;
  },
  getCurrentData() {
    return state.currentData;
  },

  getSavedData({ currentData }) {
    return currentData;
  },

  getAllDataTypes() {
    return state.dataTypes;
  },
  getExtraCurrentColumns() {
    return state.extraColumnDef;
    /* switch (state.selectedDataTypeId) {
      case 0:
        return state.portfolioColDef;
      case 1:
        return state.programColDef;
      case 2:
        return state.projectColDef;
    } */
  },

  getRelationshipStatus() {
    return state.isRelationship;
  },

  getRedoWatcher() {
    return state.redoWatcher;
  },

  getUndoWatcher() {
    return state.undoWatcher;
  },

  getisRedo() {
    return state.isRedo;
  },

  getisUndo() {
    return state.isUndo;
  },
};

export const dataManager = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
