<template>
  <div class="columns" style="padding-top: 0.78em">
    <div style="width: 97vw; margin-left: 0.5vw">
      <splitpanes class="default-theme">
        <pane>
          <div style="height: 93vh" ref="scriptContainerProcessSetHeader">
            <processParentSetToolbar />
            <ag-grid-vue
              class="ag-theme-balham ag-default-layout"
              rowSelection="multiple"
              enableCellChangeFlash="true"
              stopEditingWhenGridLosesFocus="true"
              :gridOptions="gridParentOptions"
              :rowData="rowParentData"
              :defaultColDef="defaultColDef"
              :columnDefs="columnProcessSettDefs"
              @cell-clicked="onCellParentClicked"
              @cell-value-changed="onParentCellValueChanged"
              @grid-ready="onProcessSetGridReady"
              @sort-changed="onProcessSetColumnChanged"
              @column-resized="onProcessSetColumnChanged"
              @column-visible="onProcessSetColumnChanged"
              @column-row-group-changed="onProcessSetColumnChanged"
              @column-value-changed="onProcessSetColumnChanged"
              @column-moved="onProcessSetColumnChanged"
              @column-pinned="onProcessSetColumnChanged"
              :enableRangeSelection="true"
              :undoRedoCellEditing="true"
            >
            </ag-grid-vue>
          </div>
        </pane>

        <pane id="childNode">
          <div style="height: 93vh" ref="scriptContainerProcess">
            <processSetChildrenToolbar />
            <ag-grid-vue
              class="ag-theme-balham ag-default-layout"
              rowSelection="multiple"
              enableCellChangeFlash="true"
              stopEditingWhenGridLosesFocus="true"
              :treeData="true"
              :animateRows="true"
              :autoGroupColumnDef="autoGroupColumnDef"
              :components="components"
              :getDataPath="getDataPath"
              :gridOptions="gridChildOptions"
              :rowData="rowChildData"
              :columnDefs="columnProcessDefs"
              @cell-clicked="onCellChildClicked"
              @cell-value-changed="onCellValueChanged"
              @selection-changed="onSelectionChanged"
              :enableRangeSelection="true"
              :undoRedoCellEditing="true"
              @row-drag-move="onRowDragMove"
              @row-drag-leave="onRowDragLeave"
              @row-drag-end="onRowDragEnd"
              @sort-changed="onProcessColumnChanged"
              @column-resized="onProcessColumnChanged"
              @column-visible="onProcessColumnChanged"
              @column-row-group-changed="onProcessColumnChanged"
              @column-value-changed="onProcessColumnChanged"
              @column-moved="onProcessColumnChanged"
              @column-pinned="onProcessColumnChanged"
            >
            </ag-grid-vue>
          </div>
        </pane>

        <pane>
          <div class="columns" style="height: 98vh; background-color: white">
            <div class="column">
              <processSetChildrenToolbar />
              <div class="columns is-multiline is-mobile">
                <div
                  class="column is-12 group"
                  v-if="scriptType != 'PScript' && scriptType != null"
                  v-on:keyup="outputDataChanged"
                >
                  <div class="column is-12 group" v-if="scriptType != 'PGoTo'">
                    <!--  <textarea
                  name="description"
                  v-model="outputDataObj"
                  autocomplete="off"
                  spellcheck="false"
                  style="font-size: 12pt; font-family: 'Georgia'"
                  rows="30"    
                  cols="50" :key="scriptType"
                  :processType="scriptType"
                  v-on:keyup="outputDataChanged"
                /> -->
                    <process-type-editor
                      :key="scriptType"
                      :processType="scriptType"
                    ></process-type-editor>

                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <!-- <button v-on:click="isJsonString">Format String</button> -->
                  </div>
                  <div
                    class="column is-12 group"
                    v-else-if="scriptType == 'PGoTo'"
                  >
                    <span
                      >Targeted ProcessId: &nbsp; &nbsp;
                      {{ selectedProcessId }} &nbsp; &nbsp;
                    </span>
                    <span>
                      <select
                        v-model="selectedProcessId"
                        style="
                          font-size: 12pt;
                          font-family: 'Georgia';
                          width: 10em;
                        "
                        @change="outputDataChanged($event)"
                      >
                        <option
                          v-for="processIds in tempTargetedProcessIds"
                          :value="processIds"
                          :key="processIds"
                        >
                          {{ processIds }}
                        </option>
                      </select>
                    </span>
                  </div>
                </div>
                <div
                  class="column is-12 group"
                  v-on:keyup="outputDataChanged"
                  v-else-if="scriptType == 'PScript'"
                >
                  <input
                    name="description"
                    v-model="outputDataValue"
                    spellcheck="false"
                    autocomplete="off"
                    style="font-size: 12pt; font-family: 'Georgia'"
                    placeholder="outputData"
                  />
                  <input name="description" disabled />

                  <!--  <textarea
                name="description"
                v-model="scriptValue"
                spellcheck="false"
                autocomplete="off"
                style="font-size: 12pt; font-family: 'Cascadia Code'"
                rows="30"
                cols="50"
                placeholder="scriptValue"
                v-on:keyup="outputDataChanged"
              /> -->

                  <vue-ace-editor
                    style="margin-top: -3em"
                    v-model="scriptValue"
                    v-bind:options.sync="editoroptions"
                  >
                  </vue-ace-editor>
                  <button v-on:click="codeFormat">Format Code</button>
                  <span class="highlight"></span>
                  <span class="bar"></span>
                </div>
              </div>
            </div>
          </div>
        </pane>
      </splitpanes>
    </div>
  </div>
  <!-- <div style="background-color: white; height: 100%;">
    <DataToolBar />
    <div class="columns" style="width: 100%;height: 100%">
      <div class="container-grid" style="width: 100%;height: 100%">
        <div class="box" style="height: 100%">
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="multiple"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            :gridOptions="gridOptions"
            :rowData="rowData"
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :enableRangeSelection="true"
            :undoRedoCellEditing="true"
          >
          </ag-grid-vue>
        </div>
        <div class="handler"></div>
      </div>
    </div>-->
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import ComboCellRenderer from "../../assets/combo-cell-renderer.vue";
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import BtnCellRenderer from "../../assets/ps-btn-cell-renderer.vue";
import processParentSetToolbar from "./processSetToolBar.vue";
import processSetChildrenToolbar from "./processToolbar.vue";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import * as utilitiesModule from "@/assets/utilities.js";
import * as beautify from "js-beautify";
import { debounce } from "lodash";
import VueAceEditor from "./VueAceEditor.vue";
import processTypeEditor from "./ProcessTypeEditor.vue";
import {
  getColumnDef,
  postColumnState,
  getColumnState,
} from "@/api/networking.js";
export default {
  name: "processSetDataView",
  props: {},
  data() {
    return {
      rowParentData: [],
      gridParentOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
          ],
        },
      },
      defaultColDef: null,
      columnProcessSettDefs: [],
      components: null,
      rowChildData: [],
      getDataPath: null,
      gridChildOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
          ],
        },
      },
      autoGroupColumnDef: null,
      columnProcessDefs: [],
      outputDataObj: {},
      outputDataValue: "",
      scriptValue: ",",
      scriptType: "",
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
      deafultOptions: {
        indent_size: 4,
        indent_char: " ",
        indent_with_tabs: false,
        editorconfig: false,
        eol: "\n",
        end_with_newline: false,
        indent_level: 0,
        preserve_newlines: true,
        max_preserve_newlines: 10,
        space_in_paren: false,
        space_in_empty_paren: false,
        jslint_happy: false,
        space_after_anon_function: false,
        space_after_named_function: false,
        brace_style: "collapse",
        unindent_chained_methods: false,
        break_chained_methods: false,
        keep_array_indentation: false,
        unescape_strings: false,
        wrap_line_length: 0,
        e4x: false,
        comma_first: false,
        operator_position: "before-newline",
        indent_empty_lines: false,
        templating: ["auto"],
      },
      isCopiedData: false,
      deleteSettings: {
        withBackdrop: true,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "center-center",
      },
      tempTargetedProcessIds: [],
      selectedProcessId: null,
      editoroptions: {
        mode: "",
        theme: "",
        fontSize: 14,
        fontFamily: "Consolas",
        highlightActiveLine: true,
        highlightGutterLine: true,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
      },
      /* processTypes */
      processTypeVal: [],
    };
  },
  components: {
    //AgGridVue,
    //DataToolBar,
    comboCellRenderer: ComboCellRenderer, // eslint-disable-line vue/no-unused-components
    btnCellRenderer: BtnCellRenderer, // eslint-disable-line vue/no-unused-components
    AgGridVue,
    Splitpanes,
    Pane,
    "vue-ace-editor": VueAceEditor,
    processParentSetToolbar,
    processSetChildrenToolbar,
    processTypeEditor,
  },
  watch: {
    //Load All ProcessSets
    processSetCounter() {
      console.log("processSetCounter watch - " + this.processSetCounter);
      this.rowParentData = this.getAllProcessSets;
      this.onProcessGridReady();
      /*  this.columnProcessDefs = [
        {
          headerName: "OrderId",
          field: "orderId",
          sortable: true,
          resizable: true,
          width: 10,
          editable: false,
          hide: false,
          sort: "asc",
          sortingOrder: ["asc"],
          filterParams: { excelMode: "windows" },
          filter: "agSetColumnFilter",
          cellStyle: function (params) {
            if (params.data.rowUpdated) {
              return { "background-color": "LightGreen" };
            }
            return null;
          },
        },
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          resizable: true,
          width: 200,
          editable: true,
          filterParams: { excelMode: "windows" },
          filter: "agSetColumnFilter",
          cellStyle: function (params) {
            if (params.data.rowUpdated) {
              return { "background-color": "LightGreen" };
            }
            return null;
          },
        },
        {
          headerName: "Description",
          field: "description",
          sortable: true,
          resizable: true,
          width: 200,
          hide: false,
          filterParams: { excelMode: "windows" },
          editable: true,
          filter: "agSetColumnFilter",
          cellStyle: function (params) {
            if (params.data.rowUpdated) {
              return { "background-color": "LightGreen" };
            }
            return null;
          },
        },
        {
          headerName: "Process",
          field: "processType",
          sortable: true,
          resizable: true,
          width: 200,
          cellEditor: "agRichSelectCellEditor",
          cellRenderer: processTypeCellRenderer,
          cellEditorPopup: true,
          cellEditorParams: {
            values: this.getAllProcessTypes.map((d) => {
              return {
                name: d.name,
                className: d.className,
                description: d.description,
              };
            }),
            cellRenderer: processTypeCellRenderer,
            cellEditorPopup: true,
            cellEditorPopupPosition: "under",
          },
          filterParams: { excelMode: "windows" },
          editable: function (params) {
            if (params.data.id > 0) {
              return false;
            } else {
              return true;
            }
          },
          filter: "agSetColumnFilter",
          cellStyle: function (params) {
            if (params.data.rowUpdated) {
              return { "background-color": "LightGreen" };
            }
            return null;
          },
        },
        {
          headerName: "Write Context",
          field: "writeContext",
          sortable: true,
          width: 125,
          hide: true,
          cellStyle: function (params) {
            if (params.data.rowUpdated) {
              return { "background-color": "LightGreen" };
            }
            return null;
          },
          cellRenderer: function (params) {
            var input = document.createElement("input");
            input.type = "checkbox";
            input.checked = params.value;
            input.addEventListener("click", function (event) {
              if (params.value == null) {
                params.value = false;
              }
              params.value = !params.value;
              params.node.setDataValue(params.column.colId, params.value);
              //params.node.data.rowUpdated = true;
            });
            return input;
          },
        },
        {
          headerName: "Conditional Parameter",
          field: "processExecutionParameter",
          sortable: true,
          width: 125,
          editable: function (params) {
            return true;
          },
          cellStyle: function (params) {
            if (params.data.rowUpdated) {
              return { "background-color": "LightGreen" };
            }
            return null;
          },
        },
        {
          headerName: "Script Output",
          sortable: true,
          resizable: true,
          width: 200,
          colId: "action",
          filterParams: { excelMode: "windows" },
          editable: function (params) {
            if (params.data.processType.className == "PScript") {
              return true;
            } else return false;
          },
          cellEditor: "agLargeTextCellEditor",
          cellEditorPopup: true,
          cellEditorParams: { maxLength: "300", cols: "50", rows: "6" },
          cellRenderer: function (params) {
            if (params.data.processData != null) {
              if (params.data.processType.className == "PScript") {
                let tempProcess = JSON.parse(params.data.processData);
                return tempProcess.outputName;
              } else return null;
            } else return null;
          },
          valueGetter: function (params) {
            if (params.data.processData != null) {
              if (params.data.processType.className == "PScript") {
                let tempProcess = JSON.parse(params.data.processData);
                return tempProcess.outputName;
              } else return null;
            } else return null;
          },
          filter: "agSetColumnFilter",
          cellStyle: function (params) {
            if (params.data.rowUpdated) {
              return { "background-color": "LightGreen" };
            }
            return null;
          },
        },
      ]; */
    },

    processChildCounter() {
      this.isCopiedData = false;
      this.rowChildData = this.getAllChildProcessSets;
    },
    SaveCounter() {
      this.rowChildData = [];
      this.isCopiedData = false;
      this.outputDataObj = {};
      this.rowChildData = this.getAllChildProcessSets;
      this.outputDataObj = this.getCurrentChildObj.processData;
      this.gridChildOptions.api.redrawRows();

      this.gridChildOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.$vToastify.success("Data successfully saved!");
    },
    addProcessHeader() {
      if (this.addProcessHeader > 1)
        setTimeout(() => this.parentGridFocus(), 500);
    },

    saveProcessSet() {
      this.gridParentOptions.api.redrawRows();
      this.gridParentOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.rowParentData = this.getAllProcessSets;
      this.gridParentOptions.api.clearFocusedCell();
      let tempRowIndex = 0;
      setTimeout(() => {
        this.gridParentOptions.api.forEachNode((node) => {
          if (node.data.id == this.getCurrentParentObjId) {
            node.setSelected(true);
            tempRowIndex = node.rowIndex;
          }
        });
        this.gridParentOptions.api.ensureIndexVisible(tempRowIndex);
        this.gridParentOptions.api.setFocusedCell(tempRowIndex, "name");
        this.gridParentOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
      }, 500);
    },

    addProcess() {
      if (this.addProcess > 1) {
        this.rowChildData = [];
        this.gridChildOptions.api.deselectAll();
        this.gridChildOptions.api.clearFocusedCell();
        this.gridChildOptions.api.hideOverlay();
        this.outputDataObj = {};
        this.scriptType = null;
        this.outputDataValue = null;
        this.scriptValue = null;
        this.mutateScriptValue({ codeValue: null });
        this.rowChildData = this.getAllChildProcessSets;
        setTimeout(() => this.childGridFocus(), 300);
      }
    },
    copyCounter() {
      if (this.copyCounter > 0) {
        this.rowChildData = this.getAllChildProcessSets;
        this.gridChildOptions.api.redrawRows();
        this.gridChildOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        //console.log(this.getAllChildProcessSets);

        this.isCopiedData = true;
        setTimeout(() => this.childGridFocus(), 500);
      }
    },
    deleteCounter() {
      if (this.deleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes = this.gridChildOptions.api.getSelectedNodes();
              if (selectedNodes.length > 0) {
                var selectedData = this.gridChildOptions.api.getSelectedRows();
                let tempIds = [];
                selectedData.forEach((element) => {
                  tempIds.push(element.id);
                });
                this.deleteProcesses({ Ids: tempIds });
              }
            } else {
              this.$vToastify.setSettings(this.NormalSettings);
            }
          });
      }
    },
    isDeleted() {
      if (this.isDeleted) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.rowChildData = [];
        this.isCopiedData = false;
        this.outputDataObj = {};
        this.rowChildData = [...this.getAllChildProcessSets];
        this.gridChildOptions.api.redrawRows();

        this.gridChildOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
      }
    },
    callDelete() {
      if (this.callDelete) {
        this.deleteProcess();
        this.$vToastify.setSettings(this.NormalSettings);
      }
    },

    deleteProcessSetCounter() {
      if (this.deleteProcessSetCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes = this.gridParentOptions.api.getSelectedNodes();
              if (selectedNodes.length > 0) {
                var selectedData = this.gridParentOptions.api.getSelectedRows();
                let tempIds = [];
                selectedData.forEach((element) => {
                  tempIds.push(element.id);
                });
                //console.log(selectedData);
                this.deleteProcessSets({ Ids: tempIds });
              }
            } else {
              this.$vToastify.setSettings(this.NormalSettings);
            }
          });
      }
    },

    callProcessSetDelete() {
      if (this.callProcessSetDelete) {
        this.deleteProcessSet();
        this.$vToastify.setSettings(this.NormalSettings);
      }
    },

    isProcessSetDeleted() {
      if (this.isProcessSetDeleted > 0) {
        //console.log(this.getAllProcessSets);
        this.rowParentData = this.getAllProcessSets;
        this.gridParentOptions.api.redrawRows();
        this.gridParentOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        this.rowChildData = [];
        this.gridChildOptions.api.redrawRows();
        this.gridChildOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
      }
    },
    hasDuplicate() {
      if (this.hasDuplicate) {
        this.gridChildOptions.api.redrawRows();
        this.gridChildOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        this.$vToastify.error("Duplicate code value detected!");
      }
    },
    tempScriptValue() {
      // console.log("asdas",this.scriptValue,this.getCurrentChildObj);
      if (this.tempScriptValue.codeValue != null) {
        if (this.scriptType == "PScript") {
          this.scriptValue = this.tempScriptValue.codeValue;
        }
      }
    },
    tempProcessTypeValue() {
      if (Object.keys(this.tempProcessTypeValue.processTypeObj).length != 0) {
        //  console.log(this.tempProcessTypeValue.processTypeObj);
        this.outputDataObj = this.tempProcessTypeValue.processTypeObj;
        this.outputDataChanged();
      }
    },
  },
  methods: {
    ...mapActions("processSetController", [
      "loadAllProcessSetData",
      "loadAllProcessesByIdData",
      "loadAllProcessTypes",
      "deleteProcess",
      "deleteProcessSet",
    ]),
    ...mapMutations("processSetController", [
      "setChildSelectedObj",
      "resetChildSelectedObj",
      "setParentSelectedObj",
      "deleteProcesses",
      "deleteProcessSets",
      "mutateScriptValue",
      "mutateProcesses",
    ]),
    onCellParentClicked(event) {
      var selectedRowObjs = event.data;
      if (!this.isCopiedData) {
        this.rowChildData = [];
        this.resetChildSelectedObj();
      }

      this.mutateScriptValue({ codeValue: null });
      this.outputDataObj = {};
      this.outputDataValue = null;
      this.scriptValue = null;
      this.scriptType = null;
      this.tempTargetedProcessIds = [];
      this.selectedProcessId = null;
      this.setParentSelectedObj({ parentObj: selectedRowObjs });
      if (selectedRowObjs.id > 0) {
        this.loadAllProcessesByIdData(selectedRowObjs.id);
      }
    },
    onCellChildClicked(event) {
      var selectedRowObjs = event.data;
      this.outputDataObj = null;

      this.setChildSelectedObj({ childObj: selectedRowObjs });
      this.scriptType = selectedRowObjs.processType.className;
      this.tempTargetedProcessIds = this.rowChildData
        .filter((x) => x.id !== selectedRowObjs.id)
        .map((a) => a.name);
      if (this.scriptType != "PScript") {
        if (this.scriptType == "PGoTo") {
          this.selectedProcessId = selectedRowObjs.processData;
        } else {
          this.outputDataObj = selectedRowObjs.processData;
          this.selectedProcessId = null;

          //console.log("ASdsa", JSON.parse(this.outputDataObj));
          /*  if (this.scriptType == "PSQL") {
          this.outputDataObj = selectedRowObjs.processData;
        } else if (this.scriptType == "PGoTo") {
          this.selectedProcessId = selectedRowObjs.processData;
        } else if (this.scriptType != "PSQL") {
          this.outputDataObj = selectedRowObjs.processData; */

          //}
          this.outputDataValue = null;
          this.scriptValue = null;
          this.mutateScriptValue({ codeValue: null });
        }
        //console.log(this.outputDataObj, selectedRowObjs.processData);
      } else {
        this.editoroptions.mode = "javascript";
        this.editoroptions.theme = "tomorrow";
        //console.log(selectedRowObjs.processData)
        if (selectedRowObjs.processData != null) {
          let tempProcess = JSON.parse(selectedRowObjs.processData);
          this.outputDataValue = tempProcess.outputName;
          this.scriptValue = beautify(tempProcess.Script, this.deafultOptions);
        } else {
          this.mutateScriptValue({ codeValue: null });
          this.scriptValue = null;
          this.outputDataValue = null;
          //this.tempTargetedProcessIds = [];
          this.selectedProcessId = null;
        }
      }
    },
    outputDataChanged(event) {
      //console.log(this.getCurrentChildObj.processType.className);
      //console.log(this.outputDataObj);
      if (this.getCurrentChildObj.processType.className != "PScript") {
        if (this.getCurrentChildObj.processType.className == "PGoTo") {
          this.getCurrentChildObj.processData = this.selectedProcessId;
        } else {
          this.getCurrentChildObj.processData = this.outputDataObj;
        }
      } else {
        if (this.outputDataValue != null && this.scriptValue != null) {
          this.getCurrentChildObj.processData = JSON.stringify({
            outputName: this.outputDataValue,
            Script: this.scriptValue,
          });
          this.outputDataObj = {};
        } else {
          this.getCurrentChildObj.processData = null;
        }
        // console.log(this.getCurrentChildObj.processData);
      }

      if (!this.getCurrentChildObj.rowUpdated) {
        this.getCurrentChildObj.rowUpdated = true;
        this.gridChildOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
      }
    },
    onCellValueChanged(params) {
      if (params.column.colId == "name" && params.newValue != null) {
        var CodeIndex = this.getAllChildProcessSets.filter(
          (x) => x.id != params.node.data.id
        );
        CodeIndex = CodeIndex.findIndex(
          (x) => x.name.toLowerCase() == params.newValue.toLowerCase()
        );
        if (CodeIndex > -1) {
          this.$vToastify.setSettings(this.NormalSettings);
          this.$vToastify.error("Duplicate code value detected!");
          params.node.data.name = params.newValue;
          params.node.data.rowUpdated = "Duplicate";
        } else {
          params.node.data.rowUpdated = true;
        }
      } else {
        if (params.node.data.rowUpdated != "Duplicate")
          params.node.data.rowUpdated = true;
      }
      if (params.node.data.processType.className != null)
        this.scriptType = params.node.data.processType.className;
      params.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },
    codeFormat() {
      this.scriptValue = beautify(this.scriptValue, this.deafultOptions);
      this.mutateScriptValue({ codeValue: this.scriptValue });
    },
    parentGridFocus() {
      this.gridParentOptions.api.clearFocusedCell();
      let tempRowIndex = 0;
      this.gridParentOptions.api.forEachNode((node) => {
        if (node.data.id < 1) {
          node.setSelected(true);
          tempRowIndex = node.rowIndex;
        }
      });
      this.gridParentOptions.api.ensureIndexVisible(tempRowIndex);
      this.gridParentOptions.api.setFocusedCell(tempRowIndex, "name");
      this.gridParentOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    childGridFocus() {
      let tempRowIndex = 0;
      this.gridChildOptions.api.forEachNode((node) => {
        //console.log(node.data)
        if (node.data.id < 1) {
          let thisParent = node.parent;
          while (thisParent) {
            thisParent.setExpanded(true);
            thisParent = thisParent.parent;
          }
          node.setSelected(true);
          node.setExpanded(true);
          tempRowIndex = node.rowIndex;
        } else {
          node.setSelected(false);
        }
      });
      this.gridChildOptions.api.ensureIndexVisible(tempRowIndex);
      this.gridChildOptions.api.setFocusedCell(tempRowIndex, "name");

      this.gridChildOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });

      this.gridChildOptions.columnApi.applyColumnState({
        colId: "orderId",
        sort: "asc",
      });
      this.scriptType = "PScript";
      //this.onCellChildClicked(this.gridChildOptions.api);
    },
    isJsonString() {
      // console.log(this.outputDataObj.length);
      if (
        typeof this.outputDataObj !== Object ||
        this.outputDataObj.length == null ||
        this.outputDataObj.length == 0
      ) {
        return false;
      }
      try {
        var json = JSON.parse(this.outputDataObj);
        this.outputDataObj = JSON.stringify(json, null, 2);
        this.mutateScriptValue({ codeValue: this.outputDataObj });
        return typeof json === "object";
      } catch (error) {
        this.$vToastify.error("Invalid JSON");
        return false;
      }
    },
    onSelectionChanged(params) {
      var selectedRows = this.gridChildOptions.api.getSelectedRows();
      /*  if (selectedRows[0].processType != null) {
        if (selectedRows[0].processType.className != null) {
          if (selectedRows[0].processType.className != "PSQL") {
            //  this.isJsonString();
          }
        }
      } */
    },
    onParentCellValueChanged(params) {
      params.node.data.rowUpdated = true;
      params.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },
    onRowDragMove(event) {
      setPotentialParentForNode(event.api, event.overNode);
    },

    onRowDragLeave(event) {
      setPotentialParentForNode(event.api, null);
    },

    onRowDragEnd(event) {
      this.$vToastify.setSettings(this.NormalSettings);
      if (!potentialParent) {
        return;
      }
      var movingData = event.node.data;
      var tempRow = { ...movingData };
      var newParentPath = potentialParent.data ? potentialParent.data.path : [];
      if (potentialParent.data.id > 0) {
        var needToChangeParent = !arePathsEqual(newParentPath, movingData.path);
        var invalidMode = isSelectionParentOfTarget(
          event.node,
          potentialParent
        );
        if (invalidMode) {
          this.$vToastify.error(
            "invalid move! Can not create a child node under " +
              potentialParent.data.processType.className
          );
          console.error(
            "invalid move! Can not create a child node under " +
              potentialParent.data.processType.className
          );
        }

        if (needToChangeParent && !invalidMode) {
          var updatedRows = [];
          //var perviousRowsToUpdate= this.row

          moveToPath(newParentPath, event.node, updatedRows);

          updatedRows.forEach((element) => {
            //   element.orderId = potentialParent.childrenAfterGroup.length;
            element.rowUpdated = true;
          });
          updatedRows[0].orderId = potentialParent.childrenAfterGroup.length;
          if (updatedRows[0].path.length > 1) {
            updatedRows[0].parentProcessId =
              updatedRows[0].path[updatedRows[0].path.length - 2];
          } else {
            updatedRows[0].parentProcessId = null;
          }
          this.gridChildOptions.api.applyTransactionAsync({
            update: updatedRows,
          });
          this.gridChildOptions.api.refreshCells({
            force: true,
            suppressFlash: true,
          });
          //  this.gridChildOptions.api.redrawRows();

          var TempAllrows =
            this.gridChildOptions.api.getModel().gridOptionsWrapper.gridOptions
              .rowData;

          var perviousRowsToUpdate = TempAllrows.find(
            (x) => x.id == tempRow.parentProcessId
          );

          var tempAllchild = TempAllrows.filter(
            (x) =>
              x.parentProcessId ==
              (perviousRowsToUpdate === undefined ? 0 : perviousRowsToUpdate.id)
          ).sort(function (a, b) {
            if (a.orderId > b.orderId) return 1;
            if (a.orderId < b.orderId) return -1;
            return 0;
          });

          if (tempAllchild.length > 0) {
            tempAllchild.forEach((element, index) => {
              element.orderId = index;
              element.rowUpdated = true;
              index++;
            });
            this.gridChildOptions.api.applyTransactionAsync({
              update: tempAllchild,
            });
            this.gridChildOptions.api.refreshCells({
              force: true,
              suppressFlash: true,
            });
            //  this.gridChildOptions.api.redrawRows();
          }
          //= this.getAllChildProcessSets;

          this.gridChildOptions.api.clearFocusedCell();
        }
      } else {
        this.$vToastify.error("invalid move!");
        console.error("invalid move!");
      }
      setPotentialParentForNode(event.api, null);
      let allRows = [];
      this.gridChildOptions.api.forEachNodeAfterFilter((rowNode) =>
        allRows.push(rowNode.data)
      );
      this.mutateProcesses(allRows);
    },

    onProcessSetGridReady() {
      //  this.loadStructureRows();
      getColumnDef(44)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerProcessSetHeader;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);

          //this.gridStructureOptions.api.setColumnDefs(container);
        })
        .then(() => {
          this.reloadProcessSetColumnState();
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    reloadProcessSetColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 44)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridParentOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onProcessSetColumnChanged: debounce(function () {
      this.onProcessSetColumnStateSubmit();
    }, 300),

    onProcessSetColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridParentOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 44,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },

    onProcessGridReady() {
      //  this.loadStructureRows();
      getColumnDef(45)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerProcess;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);

          //this.gridStructureOptions.api.setColumnDefs(container);
        })
        .then(() => {
          this.reloadProcessColumnState();
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    reloadProcessColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 45)
        .then((resp) => {
          this.columnProcessDefs.find(
            (x) => x.headerName == "Process"
          ).cellEditorParams.values = this.getAllProcessTypes.map((d) => {
            return {
              name: d.name,
              className: d.className,
              description: d.description,
            };
          });

          this.columnProcessDefs.find(
            (x) => x.headerName == "Process"
          ).cellRenderer = processTypeCellRenderer;
          this.columnProcessDefs.find(
            (x) => x.headerName == "Process"
          ).cellEditorParams.cellRenderer = processTypeCellRenderer;

          this.gridChildOptions.api.setColumnDefs(this.columnProcessDefs);
          this.gridChildOptions.api.redrawRows();
          this.gridChildOptions.api.refreshCells({
            force: true,
            suppressFlash: true,
          });
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridChildOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onProcessColumnChanged: debounce(function () {
      this.onProcessColumnStateSubmit();
    }, 300),

    onProcessColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridChildOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 45,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },
  },

  computed: {
    ...mapGetters("processSetController", [
      "getAllProcessSets",
      "getAllChildProcessSets",
      "getCurrentChildObjId",
      "getCurrentChildObj",
      "getAllProcessTypes",
      "getCurrentParentObjId",
    ]),
    ...mapState({
      processSetCounter: (state) =>
        state.processSetController.loadProcessSetCounter,
      processChildCounter: (state) =>
        state.processSetController.loadChildProcessCounter,
      SaveCounter: (state) => state.processSetController.saveCounter,
      addProcessHeader: (state) =>
        state.processSetController.addProcessHeaderCounter,
      addProcess: (state) => state.processSetController.addProcessCounter,
      saveProcessSet: (state) =>
        state.processSetController.processSetSaveCounter,
      copyCounter: (state) => state.processSetController.copyCounter,
      deleteCounter: (state) =>
        state.processSetController.deleteProcessesCounter,
      isDeleted: (state) => state.processSetController.deleteCounter,
      callDelete: (state) => state.processSetController.isDeleteMethodCalled,
      deleteProcessSetCounter: (state) =>
        state.processSetController.deleteProcessesSetCounter,
      callProcessSetDelete: (state) =>
        state.processSetController.isParentDeleteMethodCalled,
      isProcessSetDeleted: (state) =>
        state.processSetController.deleteParentCounter,
      hasDuplicate: (state) => state.processSetController.hasDuplicate,
      tempScriptValue: (state) => state.processSetController.tempScriptValue,
      tempProcessTypeValue: (state) =>
        state.processSetController.tempProcessTypeValue,
    }),
  },
  mounted() {
    this.loadAllProcessTypes();
    //this.loadAllProcessSetData();
    this.$vToastify.setSettings(this.NormalSettings);
  },

  beforeMount() {
    this.defaultColDef = Object.assign({}, this.defaultColDef, {
      width: 180,
    });
    this.autoGroupColumnDef = {
      headerName: "Id",
      field: "id",
      sortable: true,
      resizable: true,
      width: 200,
      editable: false,
      rowDrag: true,
      filterParams: { excelMode: "windows" },
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: "processCellRenderer",
      },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
    };
    this.components = { processCellRenderer: getProcessCellRenderer() };
    this.getDataPath = (data) => {
      return data.path;
    };
  },
  created() {},
};

class processTypeCellRenderer {
  init(params) {
    this.eGui = document.createElement("div");
    this.eGui.innerHTML = params.value.name;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    return false;
  }
}

class outPutValueCellRenderer {
  init(params) {
    this.eGui = document.createElement("div");

    this.eGui.innerHTML = params.value;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    return false;
  }
}

window.moveToPath = function moveToPath(newParentPath, node, allUpdatedNodes) {
  var oldPath = node.data.path;
  var fileName = oldPath[oldPath.length - 1];
  var newChildPath = newParentPath.slice();
  newChildPath.push(fileName);
  node.data.path = newChildPath;
  allUpdatedNodes.push(node.data);
  if (node.childrenAfterGroup) {
    node.childrenAfterGroup.forEach(function (childNode) {
      moveToPath(newChildPath, childNode, allUpdatedNodes);
    });
  }
};

window.isSelectionParentOfTarget = function isSelectionParentOfTarget(
  selectedNode,
  targetNode
) {
  var children = selectedNode.childrenAfterGroup;
  if (
    targetNode.data.processType.className == "PConditional" ||
    targetNode.data.processType.className == "PWhile"
  ) {
    for (var i = 0; i < children.length; i++) {
      if (targetNode && children[i].key === targetNode.key) return true;
      isSelectionParentOfTarget(children[i], targetNode);
    }

    return false;
  }
  return true;
};

window.arePathsEqual = function arePathsEqual(path1, path2) {
  //console.log(path2, path2, path1);
  if (path1.length !== path2.length) {
    return false;
  }
  var equal = true;
  path1.forEach(function (item, index) {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
};

window.setPotentialParentForNode = function setPotentialParentForNode(
  api,
  overNode
) {
  var newPotentialParent;
  if (overNode) {
    newPotentialParent = overNode;
  } else {
    newPotentialParent = null;
  }
  var alreadySelected = potentialParent === newPotentialParent;
  if (alreadySelected) {
    return;
  }
  var rowsToRefresh = [];
  if (potentialParent) {
    rowsToRefresh.push(potentialParent);
  }
  if (newPotentialParent) {
    rowsToRefresh.push(newPotentialParent);
  }
  potentialParent = newPotentialParent;
  refreshRows(api, rowsToRefresh);
};

window.refreshRows = function refreshRows(api, rowsToRefresh) {
  var params = {
    rowNodes: rowsToRefresh,
    force: true,
  };
  api.refreshCells(params);
};

var potentialParent = null;

window.getProcessCellRenderer = function getProcessCellRenderer() {
  function ProcessCellRenderer() {}
  ProcessCellRenderer.prototype.init = function (params) {
    var tempDiv = document.createElement("div");
    //console.log(params.node);
    //params.api.refreshCells();
    tempDiv.innerHTML =
      params.node.allChildrenCount == null && params.node.level == 0
        ? '<span style="margin-left:28px;"/>' + params.data.id
        : "<span/>" + params.data.id;
    this.eGui = tempDiv.firstChild;
  };
  ProcessCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return ProcessCellRenderer;
};
</script>

<style lang="scss" scoped>
.vue-ace-editor {
  margin: 2rem 0;
  border: 1px solid #eee;
}
.cell-wrap-text {
  white-space: normal !important;
}
input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: transparent;
}

input[type="date"]:disabled {
  color: transparent;
}
input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

.title {
  font-family: Verdana;
  font-size: 1.4vw;
}
.formulate-input {
  .formulate-input-label {
    font-weight: bold;
  }
}
/* form starting stylings ------------------------------- */
.group {
  position: relative;
  margin-bottom: 15px;
}

input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  color: #000;
}

textarea {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
}
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}

h1 {
  font-family: "Trocchi", serif;
  font-weight: normal;
  line-height: 48px;
  margin: 0;
}
/* LABEL ======================================= */
label {
  color: #999;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 7px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label {
  top: -10px;
  font-size: 14px;
  color: black;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 300px;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
::-webkit-input-placeholder {
  color: #999;
  font-weight: bold;
}
.column {
  box-shadow: 0.01rem 0.01rem 0.01rem rgb(241, 224, 224);
  margin-top: 0.01rem;
}
.line {
  width: 99.5%;
  height: 1%;
  border-bottom: 1px solid black;
  position: relative;
}

select {
  // A reset of styles, including removing the default dropdown arrow
  border: 1em;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  cursor: inherit;
  line-height: inherit;

  // Stack above custom arrow
  z-index: 1;

  // Remove dropdown arrow in IE10 & IE11
  // @link https://www.filamentgroup.com/lab/select-css.html
  &::-ms-expand {
    display: none;
  }

  // Remove focus outline, will add on alternate element
  outline: none;
}

.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;

  select,
  &::after {
    grid-area: select;
  }

  min-width: 15ch;
  max-width: 30ch;

  //border: 1px solid var(--select-border);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;

  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;

  // Optional styles
  // remove for transparency
  // background-color: #fff;
  // background-image: linear-gradient(to top, #f9f9f9, #fff 33%);

  // Custom arrow
  &:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    //background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
}

// Interim solution until :focus-within has better support
select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  //border: 2px solid var(--select-focus);
  border-radius: inherit;
}

select[multiple] {
  padding-right: 0;

  /*
   * Safari will not reveal an option
   * unless the select height has room to 
   * show all of it
   * Firefox and Chrome allow showing 
   * a partial option
   */
  height: 6rem;

  option {
    white-space: normal;

    // Only affects Chrome
    //outline-color: var(--select-focus);
  }

  /* 
   * Experimental - styling of selected options
   * in the multiselect
   * Not supported crossbrowser
   */
  //   &:not(:disabled) option {
  //     border-radius: 12px;
  //     transition: 120ms all ease-in;

  //     &:checked {
  //       background: linear-gradient(hsl(242, 61%, 76%), hsl(242, 61%, 71%));
  //       padding-left: 0.5em;
  //       color: black !important;
  //     }
  //   }
}

label {
  font-size: 1.125rem;
  font-weight: 500;
}

.select + label {
  margin-top: 2rem;
}
</style>
