import { userService } from "@/api/user-auth";
import * as moment from "moment";
let data = [],
  i = -1,
  tempData = null;
function getParentLoop(id, path) {
  let p = data.filter((i) => i.id == id)[0];
  if (p.parentId) {
    path.unshift(p.id);
    return getParentLoop(p.parentId, path);
  } else {
    path.unshift(p.id);
  }
  return path;
}

function setpath(data) {
  data.map((i) => {
    i.path = [];
    if (i.parentId) {
      i.path.push(i.id);
      getParentLoop(i.parentId, i.path);
    } else {
      i.path.push(i.id);
    }
    return i;
  });
  return data;
}

const state = {
  isMenuExpanded: false,
  isactivityModalVisible: false,
  activitydetailcode: null,
  activitydetailId: null,
  currentactivitydetailId: 0,
  filtertitle: null,
  activityLoadcounter: 0,
  activitiescodeValue: null,
  currentActivitiesData: null,
  currenttempActivitiesData: [],
};

const actions = {
  LoadSchedualHeader({ dispatch, commit }, id) {
    userService
      .getJson(`ScheduleHeader/Get/${id}`)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          commit("mutateActivityTitle", result.data.code);
          state.currenttempActivitiesData[
            state.currenttempActivitiesData.findIndex(
              (x) => x.scheduleHeaderId == id
            )
          ].parentId = result.data.id;
          result.data.parentId = null;
          state.currenttempActivitiesData.unshift(result.data);
          commit("setActivitiesData", {
            returnedData: state.currenttempActivitiesData,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
  loadActivitiesbyCostHeadersID({ dispatch, commit }, { costHeaderId }) {
    userService
      .getJson(`Activity/GetByCostHeaderId/${costHeaderId}`)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          state.currenttempActivitiesData = result.data;
          let indexs = state.currenttempActivitiesData.filter(
            (x) => x.parentId == null
          );

          indexs.forEach((element) => {
            dispatch("LoadSchedualHeader", element.scheduleHeaderId);
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
};
const mutations = {
  toggleActivityFilterModal(state) {
    state.isactivityModalVisible = !state.isactivityModalVisible;
  },
  mutateActivityTitle(state, title) {
    state.filtertitle = title;
  },

  mutateActivitiesCodeValue(state, { code: code, Id: Id }) {
    state.activitiescodeValue = code;
    state.activitydetailId = Id;
  },
  setActivityId(state, { code: code }) {
    if (code != 0 || state.currenttempActivitiesData.length > 0) {
      let indexs = state.currenttempActivitiesData.findIndex(
        (x) => x.code == code
      );
      if (indexs > 0) {
        state.currentactivitydetailId =
          state.currenttempActivitiesData[indexs].id;
      } else {
        state.currentactivitydetailId = 0;
      }
    } else {
      state.currentactivitydetailId = 0;
    }
  },

  mutateActivityLoadCounter(state) {
    state.activityLoadcounter++;
  },

  setActivitiesData(state, { returnedData }) {
    if (returnedData != null) {
      data = returnedData;
      tempData = setpath(data);
      state.currentActivitiesData = tempData;
    }
  },
};
const getters = {
  getTitle() {
    return state.filtertitle + " Activity Module";
  },

  getActivityDetailcode() {
    return state.activitiescodeValue;
  },

  getActivityDetailId() {
    return state.activitydetailId;
  },
  getCurrentActivities() {
    return state.currentActivitiesData;
  },

  getDetailsColumns() {
    return [
      {
        headerName: "Code",
        field: "code",
        sortable: true,
        resizable: true,
        width: 90,
        filterParams: { excelMode: "windows" },
        editable: false,
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Description",
        field: "description",
        sortable: true,
        resizable: true,
        width: 300,
        editable: false,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
      },
      {
        headerName: "PlannedDuration",
        field: "plannedDuration",
        sortable: true,
        resizable: true,
        width: 100,
        editable: false,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
      },
      {
        headerName: "PlannedStartDate",
        field: "plannedStartDate",
        sortable: true,
        resizable: true,
        width: 100,
        editable: false,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        valueFormatter: function (params) {
          if (params.value) {
            return moment(params.value)
              .toISOString()
              .replace(/T.*/, "")
              .split("-")
              .reverse()
              .join("/");
          }
          return null;
        },
      },
      {
        headerName: "PlannedEndDate",
        field: "plannedEndDate",
        sortable: true,
        resizable: true,
        width: 100,
        editable: false,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        valueFormatter: function (params) {
          if (params.value) {
            return moment(params.value)
              .toISOString()
              .replace(/T.*/, "")
              .split("-")
              .reverse()
              .join("/");
          }
          return null;
        },
      },
    ];
  },
};

export const activityControl = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
