<template>
  <div style="background-color: white; width: 99%">
    <div class="jiveContainer" ref="jiveContainer">
      <AddBaselineModal
        v-show="isModalVisible"
        ref="addBaseline"
        isModalVisible="isModalVisible"
        :baselineCode="baselineCode"
        :baselineDesc="baselineDesc"
        @close="hideBaseline"
        @create="createBaseline"
      />
      <div class="line"></div>
      <div id="scriptContainerGantt" ref="scriptContainerGantt">
        <bryntum-gantt
          ref="gantt"
          v-bind="ganttConfigLocal"
          v-if="renderComponent"
        />
      </div>
      <bryntum-splitter />
      <div
        id="schedulerContainer"
        ref="schedulerContainer"
        v-show="showSchedule"
      >
        <bryntum-scheduler-pro
          ref="scheduler"
          v-bind="schedulerProConfigLocal"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  BryntumGantt,
  BryntumSplitter,
  BryntumSchedulerPro,
} from "@bryntum/gantt-vue";
import {
  ProjectModel,
  DateTimeField,
  StringHelper,
  DateHelper,
  Toast,
  Popup,
  CalendarModel,
  LocaleHelper,
  ColumnStore,
  Column,
  SvSE,
  Mask,
  UndoRedoBase,
  ResourceModel,
} from "@bryntum/gantt";
import { postFile } from "@/api/user-auth.js";
import { mapMutations, mapGetters, mapState, mapActions } from "vuex";
import "./ganttToolbar.js";
import * as utilitiesModule from "@/assets/utilities.js";
import JiveConnectTask from "../../assets/bryntumExtendedClasses/jiveConnectTask.js";
import JiveConnectDependency from "../../assets/bryntumExtendedClasses/jiveConnectDependency.js";
import JiveConnectResource from "../../assets/bryntumExtendedClasses/jiveConnectResource.js";
import JiveConnectBaseline from "../../assets/bryntumExtendedClasses/jiveConnectBaseline.js";
import AddBaselineModal from "./addBaseline.vue";
import * as moment from "moment";
import { compareActivities } from "../../assets/utilities.js";
import StatusColumn from "../../assets/bryntumExtendedClasses/jiveStatusColumn.js";
import CodeColumn from "../../assets/bryntumExtendedClasses/jiveCodeColumn.js";
//import Importer from "../../../assets/bryntumExtendedClasses/mppImporter.js";
//import JiveCrudManager from "../../../assets/bryntumExtendedClasses/jiveCrudManager.js";
import { debounce } from "lodash";
import {
  getColumnDef,
  postColumnState,
  postColumn,
  getColumnState,
} from "@/api/networking.js";
import { Context } from "ag-grid-community";
const ganttConsole = (arg) => console.log(arg);
const headerTpl = ({ currentPage, totalPages }) => `
    <div class="jiveContainer">
        <dl>
            <dt>Date: ${DateHelper.format(new Date(), "ll LT")}</dt>
            <dd>${
              totalPages ? `Page: ${currentPage + 1}/${totalPages}` : ""
            }</dd>
        </dl>
    </div>`;

const footerTpl = () =>
  `<div ref="schedulerContainer"><h3> © ` +
  new Date().getFullYear() +
  ` JIVE Connect </h3></div>`;

export default {
  name: "GanttComponent",
  components: {
    BryntumGantt,
    BryntumSplitter,
    BryntumSchedulerPro,
    AddBaselineModal,
  },
  data() {
    return {
      showSchedule: false,
      tmpScheduleHeight: 0,
      fileToUpload: {},
      project: null,
      parentHeight: 100,
      splitterConfigLocal: {
        floating: true,
        // x:10,
      },
      renderComponent: true,
      ganttConfigLocal: {
        dependencyIdField: "wbsCode",
        /* project: {
          stm: {
            autoRecord: false,
            resetUndoRedoQueuesAfterLoad: true,

            // This config enables response validation and dumping of found errors to the browser console.
            // It's meant to be used as a development stage helper only so please set it to false for production systems.
            validateResponse: true,

            enableUndoRedoKeys: true,
          },
        }, */
        responsiveLevels: {
          small: 600,
          normal: "*",
        },
        startDate: "2020-03-01",
        viewPreset: "weekAndDayLetter",
        infiniteScroll: true,
        showDirty: true,
        readOnly: false,
        zoomOnMouseWheel: true,
        scrollTaskIntoViewOnCellClick: true,
        columns: [
          //{ type: "statuscolumn" },
          //{ type: "wbs" },
          //{ type: "codecolumn" },
        ],
        //wbsMode: "auto",
        requireSize: true,
        rowHeight: 30,
        autoLoad: false,
        autoSync: false,
        rowCopyPaste: false,
        autoHeight: false,
        barMargin: 7,
        scrollable: true,
        subGridConfigs: {
          collapsed: false,
          locked: {
            flex: 8,
          },
          normal: {
            flex: 5,
          },
        },
        data: [],
        columnLines: false,
        listeners: {},
        features: {
          parentArea: false,
          search: true,
          mspExport: {
            disabled: false,
            msProjectVersion: 2019,
          },
          //PDF export Config
          pdfExport: {
            exportServer: "https://dev.bryntum.com:8082/", // Required
            // '/resources' is hardcoded in WebServer implementation
            translateURLsToAbsolute: "https://dev.bryntum.com:8082/resources",
            showErrorToast: true,
            //openAfterExport: false,
            //openInNewTab: true,
            alignRows: true,
            //headerTpl,
            footerTpl,
            filename: "Gantt Chart",
          },
          rowCopyPaste: {
            disabled: true,
            //pasteRecordText: "false",
            /* keyMap: {
              // disable paste via keyboard
              "Ctrl+B": "paste",
              "Ctrl+X": null,
              "Ctrl+C": null,
            }, */
          },
          /* cellEdit: {
            autoSelect: false,
            //triggerEvent: "cellclick",
          }, */
          taskCopyPaste: false,
          //treeGroup: true,
          //mergeCells:true,
          // this feature is actually enabled by default,
          // so no need for this unless you have changed defaults
          columnDragToolbar: true,
          columnPicker: true,

          regionResize: true,
          dependencies: true,
          columnResize: true,
          group: false,
          stickyEvents: false,
          sort: {
            prioritizeColumns: true,
          },

          stripe: true,
          taskEdit: {
            items: {
              generalTab: {
                items: {
                  startDate: {
                    format: "DD/MM/YY HH:mm",
                    editor: new DateTimeField({
                      dateField: {
                        format: "DD/MM/YY HH:mm",
                      },
                    }),
                  },
                  endDate: {
                    widgets: [{ type: "datetimefield" }],
                    format: "DD/MM/YY HH:mm",
                  },
                },
              },
            },
          },
        },
        rollupsFeature: {
          disabled: true,
        },
        baselinesFeature: {
          disabled: true,
          template(data) {
            const me = this,
              { baseline } = data,
              { task } = baseline,
              delayed = task.startDate > baseline.startDate,
              overrun = task.durationMS > baseline.durationMS;
            let { decimalPrecision } = me;

            if (decimalPrecision == null) {
              decimalPrecision = me.client.durationDisplayPrecision;
            }

            const multiplier = Math.pow(10, decimalPrecision),
              displayDuration =
                Math.round(baseline.duration * multiplier) / multiplier;
            return `
                    <div class="b-gantt-task-title">${StringHelper.encodeHtml(
                      task.name
                    )} (${baseline.name})</div>
                    <table>
                    <tr><td>${me.L("Start")}:</td><td>${
              data.startClockHtml
            }</td></tr>
                    ${
                      baseline.milestone
                        ? ""
                        : `
                        <tr><td>${me.L("End")}:</td><td>${
                            data.endClockHtml
                          }</td></tr>
                        <tr><td>${me.L("Duration")}:</td><td class="b-right">${
                            displayDuration +
                            " " +
                            DateHelper.getLocalizedNameOfUnit(
                              baseline.durationUnit,
                              baseline.duration !== 1
                            )
                          }</td></tr>
                    `
                    }
                    </table>
                    ${
                      delayed
                        ? `
                        <h4 class="statusmessage b-baseline-delay"><i class="statusicon b-fa b-fa-exclamation-triangle"></i>${me.L(
                          "Delayed start by"
                        )} ${DateHelper.formatDelta(
                            task.startDate - baseline.startDate
                          )}</h4>
                    `
                        : ""
                    }
                    ${
                      overrun
                        ? `
                        <h4 class="statusmessage b-baseline-overrun"><i class="statusicon b-fa b-fa-exclamation-triangle"></i>${me.L(
                          "Overrun by"
                        )} ${DateHelper.formatDelta(
                            task.durationMS - baseline.durationMS
                          )}</h4>
                    `
                        : ""
                    }
                    `;
          },
        },

        progressLineFeature: {
          disabled: true,
          statusDate: new Date(2019, 0, 25),
        },
        filterFeature: true,
        dependencyEditFeature: true,
        timeRangesFeature: {
          showCurrentTimeLine: true,
        },
        tbar: {
          style:
            "background-color:#ffffff; margin-bottom:0.2%; color:solid black;",
          cls: "tbar",
          type: "gantttoolbar",
        },
        selectionMode: {
          row: true,
          multiSelect: true,
          preserveSelectionOnDatasetChange: true,
          cell: true,
          rowCheckboxSelection: false,
          checkbox: false,
          showCheckAll: false,
          includeChildren: false,
        },
      },
      schedulerProConfigLocal: {
        //minHeight: "20em",
        //flex: 1,
        //autoHeight: false,
        //rowHeight: 45,
        displaySchedulingIssueResolutionPopup: false,
        rowCopyPaste: false,
        eventColor: "gantt-green",
        useInitialAnimation: false,
        dependenciesFeature: true,
        percentBarFeature: true,
        columns: [
          {
            type: "resourceInfo",
            field: "name",
            text: "Resource",
            showEventCount: true,
            width: 330,
            renderer({ row, value }) {
              // Color only odd rows
              row.eachElement(
                (el) =>
                  (el.style.background =
                    row.index % 2 === 0 ? "#e0f0f6" : "#ffffff")
              );

              return value;
            },
          },
          {
            field: "description",
            text: "Description",
            showEventCount: false,
            width: 430,
            // renderer({ row, value }) {
            //   // Color only odd rows
            //   row.eachElement(
            //     el =>
            //       (el.style.background =
            //         row.index % 2 === 0 ? "#e0f0f6" : "#ffffff")
            //   );

            //return value;
            //},
          },
          {
            text: "Assigned ?",
            field: "events",
            width: 120,
            editor: false,
            align: "right",
            renderer: ({ value }) =>
              `${value.length ? "Assigned" : "Unassigned"}`,
            sortable: function (record, record2) {
              return record.events.length < record2.events.length ? -1 : 1;
            },
            filterable: {
              filterFn: function ({ record, value }) {
                return record.events.length > 0;
              },
              filterField: {
                emptyText: "Filter by driver or vehicle",
              },
            },
          },
          {
            text: "Assigned tasks",
            field: "events.length",
            width: 120,
            editor: false,
            filter: true,
            align: "right",
            renderer: ({ value }) => `${value} task${value !== 1 ? "s" : ""}`,
          },
          {
            text: "Assigned work days",
            width: 160,
            editor: false,
            align: "right",
            renderer: ({ record }) =>
              record.events
                .map((task) => task.duration)
                .reduce((total, current) => {
                  return total + current;
                }, 0) + " days",
          },
        ],
      },
      //gridColumns: [],
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
      isModalVisible: false,
      baselineButtonSet: false,
      baselineCode: "",
      baselineDesc: "",
      menuBtnToAdd: {
        type: "button",
        text: "Show baseline",
        ref: "showBaseline",
        icon: "b-fa-bars",
        onItem: "up.toggleBaselineVisible",
        menu: [],
      },
      ganttColumnInstance: null,
      ganttSorter: null,
      loadSTM: false,
      isRowEditing: false,
      tempAllEntitiesAsTree: null,
      tempAllRelations: [],
      tempAllAssignments: [],
      tempAllCalendars: [],
      tempColumSort: null,
      /*  feature: {
        eventBuffer: true,
      }, */
    };
  },

  computed: {
    ...mapGetters("activities", [
      "allEntities",
      "allEntitiesAsTree",
      "allRelations",
      "allResources",
      "allAssignments",
      "allEntitiesCount",
      "entitiesLoaded",
      "entitiesUpdated",
      "allCalendars",
    ]),
    ...mapGetters("schedulingDataManager", ["selectedEntitites"]),
    ...mapGetters("baselines", {
      allBaselines: "allEntities",
      baselinesLoaded: "entitiesLoaded",
      baselineAdded: "entityAdded",
    }),

    ...mapGetters("jiveComponents", ["ganttAccess"]),
    ...mapState({
      areEntitiesLoaded: (state) => state.activities.entitiesLoaded,
      areEntitiesUpdated: (state) => state.activities.entitiesUpdated,
      SaveCounter: (state) => state.activities.isSave,
      areBaselinesLoaded: (state) => state.baselines.entitiesLoaded,
      areBaselinesUpdated: (state) => state.baselines.entitiesUpdated,
      allEntitiesUpdated: (state) => state.baselines.localAllEntities,
      isSaveClicked: (state) => state.isSaveClicked,
    }),
  },
  watch: {
    allEntitiesUpdated() {
      // console.log("allEntitiesUpdated");
      // console.log(this.allEntitiesUpdated);
    },
    areEntitiesLoaded() {
      if (this.entitiesLoaded) {
        //console.log("entitiesLoaded Watch");
        this.setProjectData();
      }
    },
    areBaselinesLoaded() {
      if (!this.baselineButtonSet && this.baselinesLoaded) {
        this.baselineButtonSet = true;
        this.allBaselines.forEach((x) => {
          this.menuBtnToAdd.menu.push({
            checked: false,
            text: x.code,
            onToggle: "up.toggleBaselineVisible",
          });
        });
        this.$refs.gantt.instance._tbar.items[0].items[3].add(
          this.menuBtnToAdd
        );
      }
    },
    areBaselinesUpdated() {
      //console.log("areBaselinesUpdated");
      //console.log(this.areBaselinesUpdated);
      //if (this.areBaselinesUpdated) {
      this.menuBtnToAdd.menu = [];
      this.allBaselines.forEach((x) => {
        this.menuBtnToAdd.menu.push({
          checked: false,
          text: x.code,
          onToggle: "up.toggleBaselineVisible",
        });
      });
      if (this.baselineButtonSet) {
        this.$refs.gantt.instance._tbar.items[0].items[3].remove(
          this.$refs.gantt.instance._tbar.widgetMap.showBaseline
        );
        this.$refs.gantt.instance._tbar.items[0].items[3].add(
          this.menuBtnToAdd
        );
      } else {
        this.$refs.gantt.instance._tbar.items[0].items[3].add(
          this.menuBtnToAdd
        );
      }
    },
    ///This monitors changes on the activities.entitiesUpdated. Fires after the save is completed.
    async areEntitiesUpdated() {
      if (this.entitiesUpdated) {
        this.mask(true, false);
        let tempRowStore = { ...this.$refs.gantt.instance.focusedCell };
        this.$refs.gantt.instance.deselectAll();

        const ganttInstance = this.$refs.gantt.instance;
        this.$refs.gantt.instance.assignmentStore.removeAll();
        this.$refs.gantt.instance.taskStore.removeAll();
        ganttInstance.project.loadInlineData({
          eventsData: [...this.allEntitiesAsTree],
          dependenciesData: [...this.allRelations],
          resourcesData: [...this.allResources],
          assignmentsData: [...this.allAssignments],
          calendarsData: [...this.allCalendars],
        });

        ganttInstance.project.propagate();
        ganttInstance.project.taskStore.sort("wbs", "ASC");
        ganttInstance.expandAll(true);
        ganttInstance.refreshRows();
        //this.mask(false, false);
        await this.$refs.gantt.instance.project.commitAsync();
        this.$refs.gantt.instance.taskStore.commitAsync().then(() => {
          let tempID =
            this.$refs.gantt.instance.taskStore.allRecords[
              tempRowStore._rowIndex
            ].id;

          //console.log(tempRowStore);
          ganttInstance.project.stm.resetQueue();
          ganttInstance.project.stm.enable();
          ganttInstance._tbar.widgetMap.undoRedo.stm =
            ganttInstance.project.stm;

          //console.log(tempRowStore);
          if (tempRowStore != null || tempRowStore != undefined) {
            /*  this.$refs.gantt.instance.selectedCell = {
              id: tempID,
              columnId: tempRowStore._column.id,
            }; */
            this.mask(false, false);

            this.$refs.gantt.instance.selectCell(
              {
                id: tempID == undefined ? 0 : tempID,
                columnId: tempRowStore._column.id,
              },
              true,
              true,
              true
            );
          }
          //scrollIntoViewfalse, addToSelectionfalse, silentfalse
        });
      }
    },
    async SaveCounter() {
      //this.$refs.gantt.instance._tbar.widgetMap.saveUpdatesBtn.disabled = false;
      this.$vToastify.setSettings(this.NormalSettings);
      this.$refs.gantt.instance.tbar.items[0].items[2].disabled = false;
      //this.$refs.gantt.instance.refreshRows();
      // await this.$refs.gantt.instance.project.commitAsync();
      this.$vToastify.success("Data successfully saved!");
    },
    ganttSorter() {
      this.$refs.gantt.instance.project.taskStore.state.sorters[0].field =
        this.ganttSorter;
      // console.log(this.ganttSorter);
    },
    loadSTM() {
      if (this.loadSTM) {
        let stm = this.$refs.gantt.instance.project.stm;
        stm.resetQueue();
        stm.enable();
        stm.startTransaction();
        stm.resetRedoQueue();
        stm.resetUndoQueue();
        stm.autoRecord = true;
        this.$refs.gantt.instance._tbar.widgetMap.undoRedo.stm =
          this.$refs.gantt.instance.project.stm;
        this.loadSTM = false;
        this.$refs.gantt.instance.refreshRows();
      }
    },
  },
  methods: {
    ...mapActions("activities", [
      "loadActivities",
      "updateActivity",
      "updateActivities",
      "updateRelations",
      "updateAssignments",
      "addActivities",
      "saveUpdates",
    ]),
    ...mapMutations("activities", [
      "mutatePasteRecords",
      "resetPasteRecordFlag",
    ]),
    ...mapActions({
      loadAllBaselines: "baselines/loadEntities",
      addBaselineToStore: "baselines/addBaselineObj",
    }),
    ...mapActions("schedulingDataManager", ["setSelectedEntityAfterRefresh"]),
    showBaseline() {
      this.$refs.addBaseline.baselineCode = "";
      this.$refs.addBaseline.baselineDesc = "";
      this.isModalVisible = true;
    },
    alternatingRenderer({ row, value }) {
      row.eachElement(
        (el) =>
          (el.style.background = row.index % 2 === 0 ? "#e0f0f6" : "#ffffff")
      );
      return value;
    },
    hideBaseline() {
      this.isModalVisible = false;
    },
    createBaseline() {
      this.isModalVisible = false;
      this.mask(true, false);
      this.addBaselineToStore({
        scheduleHeaderId: this.selectedEntitites[0].id,
        code: this.$refs.addBaseline.baselineCode,
        description: this.$refs.addBaseline.baselineDesc,
      });
      this.$refs.gantt.instance._tbar.widgetMap.addBaselineBtn.badge = "*";
      this.$refs.gantt.instance._tbar.widgetMap.saveUpdatesBtn.badge = "*";
      const ganttInstance = this.$refs.gantt.instance;
      var taskStore = ganttInstance.taskStore.allRecords;
      this.addBaselineToTasks(taskStore, this.$refs.addBaseline.baselineCode);
      this.mask(false, false);
    },
    addBaselineToTasks(tasksToUpdate, baselineCode) {
      tasksToUpdate.forEach((task) => {
        task.baselines.add({
          task,
          name: baselineCode,
          code: baselineCode,
          startDate: task.startDate,
          endDate: task.endDate,
        });
      });
    },
    saveChanges() {
      //this.$refs.gantt.instance._tbar.widgetMap.saveUpdatesBtn.disabled = true; //.badge = "*";
      const ganttInstance = this.$refs.gantt.instance;
      var activitiesToUpdate = this.getActivitiesToUpdate();
      /*  console.log(
        "ganttInstance.taskStore.changes",
        ganttInstance.taskStore.changes
      ); */
      //if(activitiesToUpdate.length > 0){//if (this.isValid(ganttInstance.taskStore.changes) || this.baselineAdded) {
      if (
        this.isValid(ganttInstance.taskStore.changes) ||
        activitiesToUpdate.length > 0
      ) {
        //console.log("activitiesToUpdate");
        //console.log(activitiesToUpdate);
        /* console.log(
          "ganttInstance.taskStore.changes",
          ganttInstance.taskStore.changes
        ); */
        this.updateActivities({
          ActivitiesToUpdate: activitiesToUpdate,
          ActivitiesToAdd: this.isValid(ganttInstance.taskStore.changes)
            ? [...ganttInstance.taskStore.changes.added]
            : [],
          ActivitiesToDelete: this.isValid(ganttInstance.taskStore.changes)
            ? [...ganttInstance.taskStore.changes.removed]
            : [],
        });
      }
      if (this.isValid(ganttInstance.dependencyStore.changes)) {
        this.updateRelations({
          RelationsToUpdate: ganttInstance.dependencyStore.changes.modified,
          RelationsToAdd: ganttInstance.dependencyStore.changes.added,
          RelationsToDelete: ganttInstance.dependencyStore.changes.removed,
        });
      }
      if (this.isValid(ganttInstance.assignmentStore.changes)) {
        this.updateAssignments({
          AssignmentsToUpdate: ganttInstance.assignmentStore.changes.modified,
          AssignmentsToAdd: ganttInstance.assignmentStore.changes.added,
          AssignmentsToDelete: ganttInstance.assignmentStore.changes.removed,
        });
      }
      //if (!this.isSaveClicked)
      //console.log(ganttInstance.tbar.items[0].items[2].items)
      ganttInstance.tbar.items[0].items[2].disabled = true;
      let stm = this.$refs.gantt.instance.project.stm;
      stm.resetQueue();
      stm.disable();
      this.saveUpdates(ganttInstance.features.progressLine.statusDate);
    },
    getActivitiesToUpdate() {
      var activitiesToUpdate = [];
      const ganttInstance = this.$refs.gantt.instance;
      var currReportingDate =
        this.$refs.gantt.instance.tbar.widgetMap.reportingDate.value;
      /*  console.log("currReportingDate");
      console.log(currReportingDate);
      console.log(ganttInstance.taskStore.allRecords); */
      //If a baseline has been added we want to update all records (that aren't new records)
      if (this.baselineAdded) {
        activitiesToUpdate = [
          ...ganttInstance.taskStore.allRecords.filter(
            (o) => typeof o.id == "number"
          ),
        ];
      } else {
        //console.log("Checking against date");
        //We want to update all activities whose current reporting period does not contain the current reporting date
        activitiesToUpdate = [
          ...ganttInstance.taskStore.allRecords.filter((o) => {
            var zeroReportDate = new Date(currReportingDate);
            zeroReportDate.setHours(0, 0, 0, 0);
            var zeroReportingPeriodStartDate = new Date(
              o.reportingPeriodStartDate
            );
            zeroReportingPeriodStartDate.setHours(0, 0, 0, 0);
            var zeroReportingPeriodEndDate = new Date(o.reportingPeriodEndDate);
            zeroReportingPeriodEndDate.setHours(0, 0, 0, 0);
            return (
              typeof o.id == "number" &&
              this.isValid(o.id) &&
              (moment(zeroReportDate).isBefore(zeroReportingPeriodStartDate) ||
                moment(zeroReportDate).isAfter(zeroReportingPeriodEndDate) ||
                o.reportingPeriodStartDate == "null" ||
                o.reportingPeriodEndDate == "null")
            );
          }),
        ];
        //console.log(activitiesToUpdate.length + " found by date");
        //If there have been other changes made, we want to grab them
        if (this.isValid(ganttInstance.taskStore.changes)) {
          //console.log(activitiesToUpdate.length + " merging changes");
          activitiesToUpdate = [
            ...activitiesToUpdate,
            ...ganttInstance.taskStore.changes.modified,
          ];

          activitiesToUpdate = activitiesToUpdate.filter(
            (v, i, a) => a.findIndex((t) => t.id === v.id) === i
          );
          // console.log(activitiesToUpdate.length + " after filter");
        }
      }

      return activitiesToUpdate;
    },
    async setProjectData() {
      var progressStartDate =
        this.selectedEntitites.length > 0
          ? this.selectedEntitites[0].startDate
          : new Date();
      var progressEndDate =
        this.selectedEntitites.length > 0
          ? this.selectedEntitites[0].endDate
          : new Date();
      //this.$refs.gantt.instance.project = null;
      const ganttInstance = this.$refs.gantt.instance;
      ganttInstance.project = new ProjectModel({
        stm: {
          autoRecord: false,
          disabled: false,
          getTransactionTitle(transaction) {
            const lastAction = transaction.queue[transaction.queue.length - 1];

            let { type, model } = lastAction;
            if (model != undefined && model.name != undefined) {
              if (lastAction.modelList && lastAction.modelList.length) {
                model = lastAction.modelList[0];
              }

              let title = lastAction.name + this.position;

              if (type === "UpdateAction") {
                title = " Edit Task " + model.name;
              } else if (type === "RemoveAction") {
                title = "Remove Task " + model.name;
              } else if (type === "AddAction") {
                title = "Add Task " + model.name;
              } /* else if (
              type === "AddAction" &&
              model instanceof DependencyModel
            ) {
              title = StringHelper.xss`Link ${model.fromEvent.name} -> ${model.toEvent.name}`;
            } */

              return title;
            }
          },
        },

        taskModelClass: JiveConnectTask,
        dependencyModelClass: JiveConnectDependency,
        resourceModelClass: JiveConnectResource,
        baselineModelClass: JiveConnectBaseline,
        startDate: progressStartDate,
        endDate: progressEndDate,
        calendar:
          this.selectedEntitites[0].calendar == undefined
            ? 1
            : this.selectedEntitites[0].calendar.id,
        hoursPerDay:
          this.selectedEntitites[0].calendar == undefined
            ? 24
            : this.selectedEntitites[0].calendar.hoursPerDay,
        daysPerWeek:
          this.selectedEntitites[0].calendar == undefined
            ? 7
            : this.selectedEntitites[0].calendar.daysPerWeek,
        weeksPerMonth:
          this.selectedEntitites[0].calendar == undefined
            ? 30 / 7
            : this.selectedEntitites[0].calendar.daysPerMonth /
              this.selectedEntitites[0].calendar.daysPerWeek,
        eventStore: {
          syncDataOnLoad: true,
        },
        taskStore: {
          syncDataOnLoad: true,
        },
        dependencyStore: {
          syncDataOnLoad: true,
        },
        resourcesStore: {
          syncDataOnLoad: true,
        },
        assignmentsStore: {
          syncDataOnLoad: true,
        },
        tasksData: [...this.allEntitiesAsTree],
        dependenciesData: [...this.allRelations],
        resourcesData: [...this.allResources],
        assignmentsData: [...this.allAssignments],
        calendarsData: [...this.allCalendars],

        listeners: {
          /*  schedulingConflict({ newEventRecord, resourceRecord }) {
            console.log("schedulingconflict");
          }, */
        },

        myCellEditorFeature: false,
        cellEditFeature: false,
        // Reset Undo / Redo after each load
        resetUndoRedoQueuesAfterLoad: true,

        enableProgressNotifications: true,

        // This config enables response validation and dumping of found errors to the browser console.
        // It's meant to be used as a development stage helper only so please set it to false for production systems.
        validateResponse: true,

        enableUndoRedoKeys: true,
        silenceInitialCommit: true,
      });

      //console.log("TaskStore", ganttInstance.taskStore);
      ganttInstance.features.progressLine.statusDate = new Date();
      ganttInstance.tbar.widgetMap.reportingDate.value = new Date();
      if (this.isValid(ganttInstance.tbar))
        ganttInstance.tbar.listeners = {
          SaveGanttChanges: (event) => {
            this.saveChanges();
          },
          onSaveDisbaleClick: (event) => {
            return false;
          },
          ShowBaseline: (event) => {
            this.showBaseline();
          },
          updateStatusDate: (event) => {
            ganttInstance.features.progressLine.statusDate = event;
          },
          SetFile: (event) => {
            this.fileToUpload = event.files[0];
          },
          LoadFile: (event) => {
            this.LoadFile();
          },
          ShowHideResources: (event) => {
            this.showSchedule = !this.showSchedule;
            if (!this.showSchedule) {
              //console.log(this.$refs.scriptContainerGantt.clientHeight);
              this.tmpScheduleHeight =
                this.$refs.scriptContainerGantt.clientHeight;
              document
                .getElementById("scriptContainerGantt")
                .setAttribute("style", "height:93vh");
            } else
              document
                .getElementById("scriptContainerGantt")
                .setAttribute("style", `height:${this.tmpScheduleHeight}px`);
          },
          onExportPDF: (event) => {
            //console.log("asdsa");
            this.exportPDFGantt();
          },
          onExportMSP: (event) => {
            //console.log("asdsa");
            this.exportMSPGantt();
          },
        };

      this.$refs.scheduler.instance.project = ganttInstance.project;

      //this.$refs.scheduler.instance.project.displaySchedulingIssueResolutionPopup = false;
      ganttInstance.project.on("schedulingConflict", (context) => {
        //console.log("schedulingconflict");
        // show notification to user
        Toast.show(
          "Scheduling conflict has happened ..recent changes were reverted"
        );

        console.error(
          "Scheduling conflict has happened ..recent changes were reverted"
        );
      });

      //console.log(ganttInstance);

      /*  this.$refs.scheduler.instance.project.loadInlineData({
        //tasksData: [...this.allEntitiesAsTree],
        dependenciesData: [...this.allRelations],
        resourcesData: [...this.allResources],
        assignmentsData: [...this.allAssignments],
        calendarsData: [...this.allCalendars],
      }); */

      ganttInstance.expandAll(true);
      ganttInstance.scrollToDate(new Date());

      ganttInstance.project.eventStore.sort("wbs", "ASC");
      ganttInstance.project.resourceStore.sort("code", "ASC");
      if (Array.isArray(this.tempColumSort)) {
        let tempSorting =
          this.tempColumSort[0].ascending == true ? true : false;

        //console.log(tempSorting);
        ganttInstance.project.taskStore.sort({
          field: this.tempColumSort[0].field.toString(),
          ascending: tempSorting,
        });
      } else {
        ganttInstance.project.taskStore.sort("code", "ASC");
      }

      this.ganttColumnInstance = ganttInstance;
      this.ganttSorter = ganttInstance.project.taskStore.state.sorters[0].field;

      this.ganttColumnInstance.columns.on({
        catchAll: () => {
          this.onColumnChange();
        },
        refresh: () => {
          console.log("sort");
        },
      });

      this.mask(false, false);
      //ToolButtons

      this.$refs.gantt.instance._tbar.widgetMap.saveUpdatesBtn.disabled =
        this.ganttAccess;
      this.$refs.gantt.instance._tbar.widgetMap.editTaskButton.disabled =
        this.ganttAccess;
      this.$refs.gantt.instance._tbar.widgetMap.addBaselineBtn.disabled =
        this.ganttAccess;
      this.$refs.gantt.instance._tbar.widgetMap.addTaskButton.disabled =
        this.ganttAccess;

      ganttInstance.on("cellclick", (context) => {
        this.isRowEditing = false;
      });

      ganttInstance.on("cancelCellEdit", (context) => {
        this.isRowEditing = false;
      });
      ganttInstance.on("startCellEdit", (context) => {
        this.isRowEditing = true;
      });
      ganttInstance.on("beforeCellEditStart", (context) => {
        this.isRowEditing = false;
      });
      ganttInstance.on("finishCellEdit", (context) => {
        this.isRowEditing = false;
      });

      setTimeout(() => {
        //ganttInstance.taskStore.commit();
        this.loadSTM = true;
      }, 6000);
    },

    async setCopyProjectData() {
      var progressStartDate =
        this.selectedEntitites.length > 0
          ? this.selectedEntitites[0].startDate
          : new Date();
      var progressEndDate =
        this.selectedEntitites.length > 0
          ? this.selectedEntitites[0].endDate
          : new Date();
      //this.$refs.gantt.instance.project = null;
      const ganttInstance = this.$refs.gantt.instance;
      ganttInstance.project = new ProjectModel({
        stm: {
          autoRecord: false,
          disabled: false,
          getTransactionTitle(transaction) {
            const lastAction = transaction.queue[transaction.queue.length - 1];

            let { type, model } = lastAction;
            if (model != undefined && model.name != undefined) {
              if (lastAction.modelList && lastAction.modelList.length) {
                model = lastAction.modelList[0];
              }

              let title = lastAction.name + this.position;

              if (type === "UpdateAction") {
                title = " Edit Task " + model.name;
              } else if (type === "RemoveAction") {
                title = "Remove Task " + model.name;
              } else if (type === "AddAction") {
                title = "Add Task " + model.name;
              } /* else if (
              type === "AddAction" &&
              model instanceof DependencyModel
            ) {
              title = StringHelper.xss`Link ${model.fromEvent.name} -> ${model.toEvent.name}`;
            } */

              return title;
            }
          },
        },

        taskModelClass: JiveConnectTask,
        dependencyModelClass: JiveConnectDependency,
        resourceModelClass: JiveConnectResource,
        baselineModelClass: JiveConnectBaseline,
        startDate: progressStartDate,
        endDate: progressEndDate,
        calendar:
          this.selectedEntitites[0].calendar == undefined
            ? 1
            : this.selectedEntitites[0].calendar.id,
        hoursPerDay:
          this.selectedEntitites[0].calendar == undefined
            ? 24
            : this.selectedEntitites[0].calendar.hoursPerDay,
        daysPerWeek:
          this.selectedEntitites[0].calendar == undefined
            ? 7
            : this.selectedEntitites[0].calendar.daysPerWeek,
        weeksPerMonth:
          this.selectedEntitites[0].calendar == undefined
            ? 30 / 7
            : this.selectedEntitites[0].calendar.daysPerMonth /
              this.selectedEntitites[0].calendar.daysPerWeek,
        eventStore: {
          syncDataOnLoad: true,
        },
        taskStore: {
          syncDataOnLoad: true,
        },
        dependencyStore: {
          syncDataOnLoad: true,
        },
        resourcesStore: {
          syncDataOnLoad: true,
        },
        assignmentsStore: {
          syncDataOnLoad: true,
        },

        resourcesData: [...this.allResources],

        calendarsData: [...this.allCalendars],

        listeners: {
          /*  schedulingConflict({ newEventRecord, resourceRecord }) {
            console.log("schedulingconflict");
          }, */
        },

        myCellEditorFeature: false,
        cellEditFeature: false,
        // Reset Undo / Redo after each load
        resetUndoRedoQueuesAfterLoad: true,

        enableProgressNotifications: true,

        // This config enables response validation and dumping of found errors to the browser console.
        // It's meant to be used as a development stage helper only so please set it to false for production systems.
        validateResponse: true,

        enableUndoRedoKeys: true,
        silenceInitialCommit: false,
      });
      //console.log([...this.allEntitiesAsTree]);

      ganttInstance.taskStore.add([...this.allEntitiesAsTree], true);

      ganttInstance.dependencyStore.add(this.allRelations, true);
      ganttInstance.assignmentStore.add(this.allAssignments, true);

      //console.log("TaskStore", ganttInstance.taskStore);
      ganttInstance.features.progressLine.statusDate = new Date();
      ganttInstance.tbar.widgetMap.reportingDate.value = new Date();
      if (this.isValid(ganttInstance.tbar))
        ganttInstance.tbar.listeners = {
          SaveGanttChanges: (event) => {
            this.saveChanges();
          },
          onSaveDisbaleClick: (event) => {
            return false;
          },
          ShowBaseline: (event) => {
            this.showBaseline();
          },
          updateStatusDate: (event) => {
            ganttInstance.features.progressLine.statusDate = event;
          },
          SetFile: (event) => {
            this.fileToUpload = event.files[0];
          },
          LoadFile: (event) => {
            this.LoadFile();
          },
          ShowHideResources: (event) => {
            this.showSchedule = !this.showSchedule;
            if (!this.showSchedule) {
              //console.log(this.$refs.scriptContainerGantt.clientHeight);
              this.tmpScheduleHeight =
                this.$refs.scriptContainerGantt.clientHeight;
              document
                .getElementById("scriptContainerGantt")
                .setAttribute("style", "height:93vh");
            } else
              document
                .getElementById("scriptContainerGantt")
                .setAttribute("style", `height:${this.tmpScheduleHeight}px`);
          },
        };

      this.$refs.scheduler.instance.project = ganttInstance.project;

      //this.$refs.scheduler.instance.project.displaySchedulingIssueResolutionPopup = false;
      ganttInstance.project.on("schedulingConflict", (context) => {
        //console.log("schedulingconflict");
        // show notification to user
        /*   Toast.show(
          "Scheduling conflict has happened ..recent changes were reverted"
        ); */

        console.error(
          "Scheduling conflict has happened ..recent changes were reverted"
        );
      });

      ganttInstance.expandAll(true);
      ganttInstance.scrollToDate(new Date());

      ganttInstance.project.eventStore.sort("wbs", "ASC");
      ganttInstance.project.resourceStore.sort("code", "ASC");

      this.ganttColumnInstance = ganttInstance;
      //this.ganttSorter = ganttInstance.project.taskStore.state.sorters[0].field;
      this.ganttColumnInstance.columns.on({
        catchAll: () => {
          this.onColumnChange();
        },
        refresh: () => {
          console.log("sort");
        },
      });

      this.mask(false, false);
      //ToolButtons

      this.$refs.gantt.instance._tbar.widgetMap.saveUpdatesBtn.disabled =
        this.ganttAccess;
      this.$refs.gantt.instance._tbar.widgetMap.editTaskButton.disabled =
        this.ganttAccess;
      this.$refs.gantt.instance._tbar.widgetMap.addBaselineBtn.disabled =
        this.ganttAccess;
      this.$refs.gantt.instance._tbar.widgetMap.addTaskButton.disabled =
        this.ganttAccess;

      ganttInstance.on("cellclick", (context) => {
        this.isRowEditing = false;
      });

      ganttInstance.on("cancelCellEdit", (context) => {
        this.isRowEditing = false;
      });
      ganttInstance.on("startCellEdit", (context) => {
        this.isRowEditing = true;
      });
      ganttInstance.on("beforeCellEditStart", (context) => {
        this.isRowEditing = false;
      });
      ganttInstance.on("finishCellEdit", (context) => {
        this.isRowEditing = false;
      });

      setTimeout(() => {
        //ganttInstance.taskStore.commit();
        this.loadSTM = true;
      }, 6000);
    },

    onColumnChange: debounce(function () {
      this.showSchedule = !this.isValid(this.showSchedule)
        ? false
        : this.showSchedule;
      this.onColumnStateSubmit(
        this.ganttColumnInstance.state,
        this.showSchedule
      );
    }, 300),

    onColumnStateSubmit(data, showScheduleState) {
      data.parentHeight = this.$refs.scriptContainerGantt.clientHeight;
      this.tmpScheduleHeight = this.$refs.scriptContainerGantt.clientHeight;
      data.showSchedule = showScheduleState;
      if (data != null || data != undefined) {
        postColumnState({
          componentId: 10,
          updatedColumnState: data,
        })
          .then(() => {
            console.log("Column State Saved");
          })
          .catch((error) => {
            console.error("Column State Not Saved: ", error);
          });
      }
    },

    reloadColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      let ganttState = null;
      getColumnState(0, 10)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            ganttState = JSON.parse(resp.data);
            this.showSchedule = ganttState.showSchedule;
            //This is all required as the splitter resizes the div by setting specific height in pixels.

            var parentHeightStr = !this.showSchedule
              ? "100%"
              : ganttState.parentHeight + "px";
            document
              .getElementById("scriptContainerGantt")
              .setAttribute("style", "height:" + parentHeightStr);
            if (this.showSchedule) {
              var scheduleHeight =
                this.$refs.jiveContainer.clientHeight -
                this.$refs.scriptContainerGantt.clientHeight;

              var height = scheduleHeight;
              var parentHeight = $("#scriptContainerGantt")
                .offsetParent()
                .height();
              var percentHeight = (100 * height) / parentHeight;
              //console.log(percentHeight, height, parentHeight);
              document
                .getElementById("schedulerContainer")
                .setAttribute("style", "height:" + percentHeight + "%");
            }
            delete ganttState.showSchedule;
            delete ganttState.parentHeight;
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          ColumnStore.registerColumnType(StatusColumn, true);
          // Remove my-component from the DOM
          this.renderComponent = false;
          this.$nextTick(() => {
            // Add the component back in
            this.renderComponent = true;
            //console.log(this.selectedEntitites);
            this.loadActivities(this.selectedEntitites[0].id);
            this.loadAllBaselines(this.selectedEntitites[0].id);
            if (ganttState != null) {
              this.tempColumSort = ganttState.store.sorters;
            }
          });
          // this function is called when vue has re-rendered the component.

          setTimeout(() => {
            this.$refs.scheduler.instance.addPartner(this.$refs.gantt.instance);
            this.mask(true, false);

            // this.$refs.gantt.instance.features.taskCopyPaste = false;
            document.addEventListener("paste", this.onPaste, {
              passive: true,
            });
            document.addEventListener("copy", this.onCopy);

            if (ganttState != null) {
              this.$refs.gantt.instance.state = ganttState;
            }
            this.$refs.gantt.instance.readOnly = this.ganttAccess;
          }, 900);
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    recordingstart(stm, transaction) {
      this.$refs.gantt.instance.refreshRows();
      /*  console.log("recordingstart");
      console.log(stm.transaction);
      console.log(transaction);
      console.log(this.$refs.gantt.instance.project);
      console.log(transaction); */
      /*  if (stm.position == 0)
        this.$refs.gantt.instance._tbar.widgetMap.UndoBtn.disabled = true;
      else this.$refs.gantt.instance._tbar.widgetMap.UndoBtn.disabled = false;
      console.log(this.$refs.gantt.instance._tbar.widgetMap.UndoBtn);
      if (this.store.count - stm.position == 0)
        this.$refs.gantt.instance._tbar.widgetMap.RedoBtn.disabled = true;
      else this.$refs.gantt.instance._tbar.widgetMap.RedoBtn.disabled = false; */
    },

    onRestoringStop({ stm }) {
      console.log("restoringstop");
    },
    async LoadFile() {
      // let formData = new FormData();
      // console.log(this.fileToUpload);
      // formData.append("file", this.fileToUpload);
      // const ganttInstance = this.$refs.gantt.instance;
      // console.log(formData);
      // this.mask(true, true);
      // postFile("Activity/LoadMPPFile", formData)
      //   .then(async prjData => {
      //     prjData.project = {
      //       calendar: 1,
      //       daysPerMonth: 20,
      //       daysPerWeek: 5,
      //       startDate: "2021-09-26T08:00:00",
      //       hoursPerDay: 8,
      //       direction: "Forward",
      //     };
      //     const { project } = ganttInstance;
      //     console.log("Importing This:");
      //     console.log(JSON.stringify(prjData));
      //     await this.importer.importData(prjData);
      //     console.log(0.6);
      //     // destroy old project
      //     project.destroy();
      //     console.log(0.7);
      //     // set the view start date to the loaded project start
      //     ganttInstance.setStartDate(ganttInstance.project.startDate);
      //     await ganttInstance.scrollToDate(ganttInstance.project.startDate, {
      //       block: "start",
      //     });
      //   })
      //   .catch(error => {
      //     console.log(error);
      //     this.$vToastify.error(error);
      //   });
      // this.mask(false, true);
    },
    isValid(valueToCheck) {
      if (
        String(valueToCheck) != "" &&
        String(valueToCheck) != "null" &&
        String(valueToCheck) != "undefined"
      )
        return true;
      else {
        return false;
      }
    },
    mask(masking, maskBody) {
      if (masking) {
        if (maskBody) {
          this.$refs.gantt.instance.maskBody("Loading Schedule ...");
          this.$refs.scheduler.instance.maskBody("Loading Schedule ...");
        } else {
          this.$refs.gantt.instance.mask("Loading Schedule ...");
          this.$refs.scheduler.instance.mask("Loading Schedule ...");
        }
      } else {
        if (maskBody) {
          this.$refs.gantt.instance.unmaskBody();
          this.$refs.scheduler.instance.unmaskBody();
        } else {
          this.$refs.gantt.instance.unmask();
          this.$refs.scheduler.instance.unmask();
        }
      }
    },
    onPaste(event) {
      const clipboardData =
          window.clipboardData ||
          event.clipboardData ||
          (event.originalEvent && event.originalEvent.clipboardData),
        pastedText =
          clipboardData.getData("Text") || clipboardData.getData("text/plain"),
        tempGanttInstance = this.$refs.gantt.instance;
      if (!pastedText && pastedText.length && tempGanttInstance) {
        return;
      }

      if (
        !this.isRowEditing ||
        tempGanttInstance.focusedCell._columnIndex == 0
      ) {
        let tempColumnId = { ...tempGanttInstance.focusedCell };

        if (tempGanttInstance.columns != undefined) {
          let tempColumns = [...tempGanttInstance.columns.visibleColumns];
          let temoColIndex =
            tempGanttInstance.focusedCell._columnIndex == 0
              ? 2
              : tempGanttInstance.focusedCell._columnIndex;
          const fields = tempColumns.splice(
            temoColIndex,
            tempGanttInstance.columns.visibleColumns.length - 2
          );
          const rows = [];
          const currentRow = tempGanttInstance.focusedCell.record;
          const currentParent =
            tempGanttInstance.focusedCell.record.parent.children;
          let tempAddParent = false;
          let tempCurrentChildIndex = currentParent.findIndex(
            (x) => x.id == currentRow.id
          );
          let rowsToAdd = currentParent.length - tempCurrentChildIndex;
          var columnIndexCheck = false;

          pastedText.split("\r\n").forEach((line, index) => {
            if (line.length > 0) {
              const arr = line.split("\t").filter(Boolean);
              //console.log(arr);
              if (arr.length > 0) {
                let data = {};
                for (let i = 0; i < Math.min(arr.length, fields.length); i++) {
                  if (rowsToAdd > index) {
                    tempAddParent = false;
                    //console.log(arr.length, fields.length, i);

                    if (fields[i].id == "status" || fields[i].id == "wbs") {
                      i++;
                    } else if (fields[i].id == "startdate") {
                      tempGanttInstance.taskStore
                        .getById(currentParent[tempCurrentChildIndex].id)
                        .set("startDate", new Date(arr[i]));
                      //.set("constraintdate", null);
                    } else if (fields[i].id == "enddate") {
                      tempGanttInstance.taskStore
                        .getById(currentParent[tempCurrentChildIndex].id)
                        .set("endDate", new Date(arr[i]));
                    } else if (fields[i].id == "percent") {
                      tempGanttInstance.taskStore
                        .getById(currentParent[tempCurrentChildIndex].id)
                        .set("percentDone", arr[i]);
                    } else if (
                      fields[i].id == "code" ||
                      fields[i].id == "description" ||
                      fields[i].id == "note" ||
                      fields[i].id == "duration"
                    ) {
                      tempGanttInstance.taskStore
                        .getById(currentParent[tempCurrentChildIndex].id)
                        .set(fields[i].id, arr[i]);
                    }
                    if (i == arr.length - 1) tempCurrentChildIndex++;
                    //console.log(fields[i].id, arr[i]);
                  } else {
                    tempAddParent = true;

                    data[fields[i].id] = arr[i];
                  }
                }
                if (tempAddParent) {
                  data["parentId"] =
                    tempGanttInstance.focusedCell.record.parentId;
                  data.duration = 1;

                  data.constraintdate =
                    new Date(data.constraintdate) === "Invalid Date" ||
                    isNaN(new Date(data.constraintdate))
                      ? null
                      : data.constraintdate;
                  rows.push(data);
                }
              }
            }
          });
          if (rows.length > 0) {
            this.$refs.gantt.instance.taskStore.add(rows, true);
            //this.$refs.gantt.instance.taskStore.commit(true, null);
            this.$refs.gantt.instance.refreshRows();
          }
          //this.isRowEditing = true;
        }
      }
    },

    onCopy(event) {
      //console.log(this.$refs.gantt.instance.focusedCell);
      if (this.$refs.gantt != undefined && !this.isRowEditing) {
        if (!this.$refs.gantt.instance.features.cellEdit.isEditing) {
          var selectedRecords = null;
          if (this.$refs.gantt.instance.selectedRecords.length == 0) {
            selectedRecords = this.$refs.gantt.instance.focusedCell.record;
            selectedRecords = [selectedRecords];
            selectedRecords.sort(compareActivities);
          } else {
            selectedRecords = [...this.$refs.gantt.instance.selectedRecords];
            selectedRecords.sort(compareActivities);
          }
          let result = selectedRecords
            .map((record, index, array) => {
              return this.$refs.gantt.instance.columns.visibleColumns
                .map(function (visibleColumn) {
                  if (visibleColumn.id == "Wbs") return record["wbsCode"];
                  else {
                    let TempCellValue =
                      record[visibleColumn.id] == undefined
                        ? null
                        : record[visibleColumn.id];
                    if (visibleColumn.id == "startdate")
                      TempCellValue = record["plannedStartDate"].toDateString();
                    if (visibleColumn.id == "enddate")
                      TempCellValue = record["plannedEndDate"].toDateString();
                    if (visibleColumn.id == "earlyEndDate")
                      TempCellValue = record["endDate"].toDateString();
                    if (visibleColumn.id == "earlyStartDate")
                      TempCellValue = record["startDate"].toDateString();
                    if (visibleColumn.id == "percent")
                      TempCellValue = record["percentDone"];
                    if (visibleColumn.id == "predecessor") {
                      TempCellValue = "";
                      for (
                        let i = 0;
                        i < record["predecessorTasks"].length;
                        i++
                      ) {
                        TempCellValue +=
                          record["predecessorTasks"][i].wbsValue.value + ";";
                      }
                    }

                    if (visibleColumn.id == "successor") {
                      TempCellValue = "";
                      for (
                        let i = 0;
                        i < record["successorTasks"].length;
                        i++
                      ) {
                        TempCellValue +=
                          record["successorTasks"][i].wbsValue.value + ";";
                      }
                    }

                    if (visibleColumn.id == "resourceassignment") {
                      TempCellValue = "";
                      for (let i = 0; i < record["resources"].length; i++) {
                        TempCellValue =
                          record["resources"][i].name +
                          " " +
                          record["assignments"][i].units +
                          " %;";
                      }
                    }
                    //console.log( record[index]["plannedStartDate"]);
                    return TempCellValue;
                  }
                })
                .join("\t");
            })
            .join("\n");

          navigator.clipboard.writeText(result).then(
            function () {
              console.log("Async: Copying to clipboard was successful!");
              selectedRecords = [];
              result = null;
              //this.isRowEditing = true;
            },
            function (err) {
              console.error("Async: Could not copy text: ", err);
            }
          );
        }
      }
    },

    onGridReady() {
      getColumnDef(10)
        .then((moduleBlob) => {
          let container = this.$refs.scriptContainerGantt;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          //console.log(container);
          URL.revokeObjectURL(moduleUrl);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          this.reloadColumnState();
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    exportPDFGantt() {
      const filename =
        this.selectedEntitites[0].code +
        " - " +
        this.selectedEntitites[0].description;
      this.$refs.gantt.instance.features.pdfExport.filename = filename;
      this.$refs.gantt.instance.features.pdfExport.showExportDialog();
    },

    exportMSPGantt() {
      const popup = new Popup({
        header: "MSP Export Configuration",
        autoShow: false,
        centered: true,
        closable: true,
        autoClose: false,
        masked: false,
        closeAction: "destroy",
        width: "30em",
        minHeight: "20em",
        bbar: [
          {
            text: "Cancel",
            minWidth: 100,
            onAction: "up.close",
          },
          {
            text: "OK",
            minWidth: 100,
            cls: "b-raised b-blue",
            onAction: () => {
              if (popup.widgetMap.filename.value.length == 0) {
                Toast.show("Filename or MS project cannot be Null");
              } else {
                this.mask(true, false);
                popup.mask({
                  text: "Please wait while rows are loading.......",
                });
                popup.masked = true;
                this.$refs.gantt.instance.features.mspExport.setConfig({
                  msProjectVersion: popup.widgetMap.msProjectVersion.value,
                  filename: popup.widgetMap.filename.value,
                });
                setTimeout(() => {
                  this.$refs.gantt.instance.features.mspExport.export();
                  popup.masked = false;
                  this.mask(false, false);
                  popup.close();
                }, 1000);
              }
            },
          },
        ],
        items: [
          // a text field
          {
            ref: "filename",
            type: "text",
            label: "filename",
            value:
              this.selectedEntitites[0].code +
              " - " +
              this.selectedEntitites[0].description,
            labelWidth: "12em",
          },
          {
            ref: "msProjectVersion",
            type: "combo",
            items: [2013, 2014, 2015, 2016, 2017, 2018, 2019],
            label: "Ms Project Version",
            value: 2019,
            labelWidth: "12em",
          },
        ],
      });
      popup.show();
      /* this.$refs.gantt.instance.features.mspExport.on({
        dataCollected: ({ data }) => {
          //console.log(this.$refs.gantt.instance.features.mspExport);
        },
      }); */
      /*  */
    },
  },
  beforeMount() {},

  mounted() {
    console.log("mounted");
    let tempGantt = localStorage.getItem("GanttElements");
    tempGantt = JSON.parse(tempGantt);
    //console.log(this.selectedEntitites.length);
    if (
      this.selectedEntitites.length == 0 &&
      Object.keys(tempGantt).length == 0
    )
      this.$router.push("/data/scheduleHeader");
    else if (this.selectedEntitites.length == 0) {
      this.setSelectedEntityAfterRefresh(tempGantt);
      this.onGridReady();
    } else this.onGridReady();

    //this.onGridReady();
  },
};
</script>

<style lang="scss">
@import "../../assets/jiveStyles.scss";
</style>
