import { post, userService } from "@/api/user-auth";
("use strict");

const state = {
  entitiesLoaded: false,
  allProcessSets: [],
  allProcessTypes: [],
  loadProcessSetCounter: 1,
  allChildProcessData: [],
  loadChildProcessCounter: 1,
  currentProcessObj: {},
  currentChildObj: {},
  currentChildId: null,
  saveCounter: 0,
  addProcessHeaderCounter: 1,
  processSetSaveCounter: 0,
  addProcessCounter: 1,
  currentParentObj: {},
  currentParentId: null,
  copyCounter: 0,
  deleteProcessesCounter: 0,
  deleteCounter: 0,
  isDeleteMethodCalled: false,
  deleteIds: [],
  deleteParentIds: [],
  deleteProcessesSetCounter: 0,
  isParentDeleteMethodCalled: false,
  deleteParentCounter: 0,
  hasDuplicate: false,
  tempScriptValue: null,
  tempProcessTypeValue: null,
};
let data = [],
  tempProccessId = -1;
function getParentLoop(id, path) {
  let p = data.filter((i) => i.id == id)[0];
  if (p.parentProcessId) {
    path.unshift(p.id);
    return getParentLoop(p.parentProcessId, path);
  } else {
    path.unshift(p.id);
  }
  return path;
}

function setpath(data) {
  data.map((i) => {
    i.path = [];
    if (i.parentProcessId) {
      i.path.push(i.id);
      getParentLoop(i.parentProcessId, i.path);
    } else {
      i.path.push(i.id);
    }
    return i;
  });
  return data;
}

const actions = {
  loadAllProcessTypes({ commit, dispatch }) {
    userService
      .getJson("ProcessType/GetAllProcessTypes")
      .then((result) => {
        if (result.succeeded && result.data != null) {
          state.allProcessTypes = result.data;
          dispatch("loadAllProcessSetData");
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
  loadAllProcessSetData({ commit, dispatch }) {
    userService
      .getJson("Processset/GetAllProcessSetHeaders")
      .then((result) => {
        if (result.succeeded && result.data != null) {
          state.currentChildObj = {};
          state.currentChildId = null;
          state.currentProcessObj = {};
          state.addProcessHeaderCounter = 1;
          state.currentParentObj == {};
          state.currentParentId = null;
          state.allProcessSets = result.data;
          state.copyCounter = 0;
          state.loadProcessSetCounter++;
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
  loadAllProcessesByIdData({ commit, dispatch }, id) {
    userService
      .getJson(`Processset/GetAllProcessesById/${id}`)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          result.data.processes.forEach((element) => {
            element.rowUpdated = false;
          });
          data = result.data.processes;
          state.allChildProcessData = setpath(data);
          state.currentProcessObj = result.data;
          state.loadChildProcessCounter++;
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
  /**Save Cost Element */
  async saveData({ dispatch, commit }) {
    var rowsToUpdate = [];
    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {};

    // #region Check duplicate values

    state.hasDuplicate = false;
    state.allChildProcessData
      .map((v) => v)
      .sort()
      .sort((a, b) => {
        if (a.name === b.name) {
          state.hasDuplicate = true;
        }
      });
    console.log("hasDuplicate", state.hasDuplicate);
    // #endregion

    if (!state.hasDuplicate) {
      // #region Processes to Update
      rowsToUpdate = state.allChildProcessData.filter(
        (x) => x.rowUpdated == true && x.id > 0
      );
      if (rowsToUpdate.length > 0) {
        updateURL = "Process/UpdateProcessById";
        updateObj = {
          ProcessesToUpdate: rowsToUpdate,
        };
      }
      if (updateURL != "") {
        updateP = post(updateURL, updateObj)
          .then((values) => {
            if (values.succeeded) {
              commit("setSavedChildData");
            }
          })
          .catch((error) => {
            dispatch("alert/error", error, { root: true });
          });
      }
      // #endregion

      // #region ProcessSet To Create
      let tempChildProcess = [...state.allChildProcessData];

      tempChildProcess = tempChildProcess.filter(
        (x) => x.rowUpdated == true && x.id < 0
      );

      //if (rowsToInsert.length > 0) {
      tempChildProcess.forEach(function (element) {
        element.processTypeId = state.allProcessTypes.find(
          (x) => x.className == element.processType.className
        ).id;
        element.ChildProcesses = element.path;
        //element.processType = null;
      });

      createURL = "ProcessSet/CreateByHeader";
      createObj = {
        CreateProcessSetObj: {
          id: state.currentParentId,
          name: state.currentParentObj.name,
          outputData: state.currentParentObj.outputData,
          outputDataName: state.currentParentObj.outputDataName,
          description: state.currentParentObj.description,
          iInputData: state.currentParentObj.inputData,
          inputDataName: state.currentParentObj.inputDataName,
          rowTypeId: state.currentParentObj.rowTypeId,
          isWizard: state.currentParentObj.isWizard,
          ChildProcesses: tempChildProcess,
        },
      };
      if (createURL != "") {
        createP = post(createURL, createObj)
          .then((values) => {
            if (values.succeeded && values.data) {
              dispatch("loadAllProcessesByIdData", values.data.id);
              if (state.currentParentId < 1) {
                commit("setSavedParentData", { parentObj: values.data });
              } else {
                /*  state.currentChildId =
                values.data.processes[values.data.processes.length - 1].id; */
              }
            }
          })
          .catch((error) => {
            dispatch("alert/error", error, { root: true });
          });
      }
      // #endregion

      // #region ProcessSet To Update
      rowsToUpdate = [];
      rowsToUpdate = state.allProcessSets.filter(
        (x) => x.rowUpdated == true && x.id > 0
      );
      if (rowsToUpdate.length > 0) {
        updateURL = "ProcessSet/UpdateByHeader";
        updateObj = { processSetObjs: rowsToUpdate };
      }
      if (updateURL != "") {
        updateP = post(updateURL, updateObj)
          .then((values) => {
            if (values.succeeded) {
              commit("setUpdateSavedParentData");
            }
          })
          .catch((error) => {
            dispatch("alert/error", error, { root: true });
          });
      }
      // #endregion
    }
  },
  async deleteProcess({ dispatch, commit }) {
    userService
      .post("Process/Delete", state.deleteIds)
      .then((result) => {
        if (result.succeeded) {
          state.deleteIds = [];
          state.isDeleteMethodCalled = false;
          state.deleteCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async deleteProcessSet({ dispatch, commit }) {
    userService
      .post(`ProcessSet/DeleteByHeader/`, state.deleteParentIds)
      .then((result) => {
        if (result.succeeded) {
          commit("resetParentObjs");
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
};

const mutations = {
  setParentSelectedObj(state, { parentObj }) {
    state.currentParentObj = parentObj;
    state.currentParentId = parentObj.id;
  },

  setChildSelectedObj(state, { childObj }) {
    state.currentChildObj = childObj;
    state.currentChildId = childObj.id;
  },

  resetChildSelectedObj(state) {
    state.currentChildObj = {};
    state.currentChildId = null;
    state.allChildProcessData = [];
    state.currentProcessObj = null;
  },
  setSavedChildData(state) {
    state.currentProcessObj.processes.forEach((x) => (x.rowUpdated = false));
    state.currentChildObj.rowUpdated = false;
    state.saveCounter++;
  },

  setSavedParentData(state, { parentObj }) {
    state.allProcessSets = state.allProcessSets.filter((x) => x.id > 0);
    state.allProcessSets.push({
      name: parentObj.name,
      description: parentObj.description,
      outputDataName: parentObj.outputDataName,
      id: parentObj.id,
      inputData: parentObj.inputData,
      inputDataName: parentObj.inputDataName,
      outputData: parentObj.outputData,
      rowTypeId: parentObj.rowTypeId,
      isWizard: parentObj.isWizard,
      rowUpdated: false,
    });
    state.currentParentId = parentObj.id;
    state.currentParentObj = parentObj;
    state.processSetSaveCounter++;
  },

  setUpdateSavedParentData(state) {
    state.allProcessSets.forEach((x) => (x.rowUpdated = false));
    state.processSetSaveCounter++;
  },

  addProcessHeader(state) {
    state.allProcessSets = [
      {
        id: -1 * state.addProcessHeaderCounter,
        name: "",
        description: "",
        outputDataName: "",
        iputData: "",
        inputDataName: "",
        outputData: "",
        rowTypeId: "",
        isWizard: false,
        rowUpdated: true,
      },
      ...state.allProcessSets,
    ];
    state.addProcessHeaderCounter = state.addProcessHeaderCounter + 1;
    state.loadProcessSetCounter++;
  },
  addProcess(state) {
    let tempOrderId = 0;
    //console.log(Object.getOwnPropertyNames(state.currentChildObj).length);
    if (Object.getOwnPropertyNames(state.currentChildObj).length > 1) {
      tempOrderId = state.allChildProcessData.filter(
        (x) => x.parentProcessId == state.currentChildObj.parentProcessId
      );
    } else {
      tempOrderId = state.allChildProcessData.filter(
        (x) => x.parentProcessId == 0 || x.parentProcessId == null
      );
    }
    let tempCurrentObj = { ...state.currentChildObj };
    let tempCurrentPathObj = [];
    let tempCurrentObjLength = Object.keys(tempCurrentObj).length;
    if (tempCurrentObjLength > 0) {
      tempCurrentPathObj = [...tempCurrentObj.path];
      tempCurrentPathObj.pop();
      tempCurrentPathObj.push(-1 * state.addProcessCounter);
    }

    var tempChildObj = {
      id: -1 * state.addProcessCounter,
      name: "",
      description: "",
      settings: [],
      writeContext: null,
      rowUpdated: true,
      orderId: tempOrderId.length,
      parentProcessId:
        tempCurrentObjLength == 0 ? null : tempCurrentObj.parentProcessId,
      path:
        tempCurrentObjLength == 0
          ? [-1 * state.addProcessCounter]
          : tempCurrentPathObj,
      processSetId: state.currentParentId,
      processTypeId: null,
      processType: {
        name: "Script Process",
        description: "Process to execute javascript script",
        className: "PScript",
      },
      processData: null,
      processExecutionParameter: null,
    };
    state.currentChildObj = tempChildObj;
    state.currentChildId = tempChildObj.id;
    state.allChildProcessData = [...state.allChildProcessData, tempChildObj];
    state.addProcessCounter++;
  },

  addChildProcess(state) {
    let tempPath = { ...state.currentChildObj };
    let tempOrderId = state.allChildProcessData.filter(
      (x) => x.parentProcessId == state.currentChildId
    );
    var tempChildObj = {
      id: -1 * state.addProcessCounter,
      name: "",
      description: "",
      settings: [],
      xmlData: null,
      writeContext: null,
      rowUpdated: true,
      processExecutionParameter: null,
      orderId: tempOrderId.length,
      parentProcessId: state.currentChildId,
      path: tempPath.path.concat([-1 * state.addProcessCounter]),
      processSetId: state.currentParentId,
      processType: {
        name: "Script Process",
        description: "Process to execute javascript script",
        className: "PScript",
      },
      processData: null,
    };

    state.currentChildObj = tempChildObj;
    state.currentChildId = tempChildObj.id;
    state.allChildProcessData.push(tempChildObj);
    //console.log(state.allChildProcessData);
    state.addProcessCounter++;
  },
  copyRecord(state) {
    state.allProcessSets.push({
      id: -999,
      name: state.currentParentObj.name + " Copy",
      description: state.currentParentObj.description + " Copy",
      inputData: state.currentParentObj.inputData,
      inputDataName: state.currentParentObj.inputDataName,
      outputData: state.currentParentObj.outputData,
      outputDataName: state.currentParentObj.outputDataName,
      rowTypeId: state.currentParentObj.rowTypeId,
      isWizard: state.currentParentObj.isWizard,
      rowUpdated: true,
    });
    state.addProcessHeaderCounter++;
    let tempRowValues = [...state.allChildProcessData];
    tempRowValues.forEach((element) => {
      element.id = -1 * element.id;
      element.rowUpdated = true;
      element.processSetId = -999;
      delete element.path;
      element.parentProcessId =
        element.parentProcessId != 0 ? -1 * element.parentProcessId : null;
    });

    data = tempRowValues;
    state.allChildProcessData = setpath(data);
    //state.allChildProcessData = tempRowValues;
    state.currentProcessObj = {
      id: -999,
      name: state.currentParentObj.name + " Copy",
      description: state.currentParentObj.description + " Copy",
      rowUpdated: true,
      inputData: state.currentParentObj.inputData,
      inputDataName: state.currentParentObj.inputDataName,
      outputData: state.currentParentObj.outputData,
      outputDataName: state.currentParentObj.outputDataName,
      rowTypeId: state.currentParentObj.rowTypeId,
      isWizard: state.currentParentObj.isWizard,
      outputDataType: 0,
      processes: state.allChildProcessData,
      rowTypeCode: null,
      settings: [],
      xmlData: null,
    };
    state.currentParentId = -999;
    state.currentParentObj = {
      id: -999,
      name: state.currentParentObj.name + " Copy",
      description: state.currentParentObj.description + " Copy",
      rowUpdated: true,
    };

    state.copyCounter++;
  },
  deleteProcesses(state, { Ids }) {
    let tempDeleteIds = [];
    state.isDeleteMethodCalled = false;
    let tempProcesses = null,
      tempOrderIdToUpdate = null,
      tempIndexToUpdate = null;
    Ids.forEach((id) => {
      tempProcesses = state.allChildProcessData.filter((x) =>
        x.path.includes(id)
      );
      if (tempProcesses.length > 0) {
        tempProcesses.forEach((x) => {
          tempDeleteIds.push(x.id);
        });
        tempProcesses = null;
        tempIndexToUpdate = state.allChildProcessData.find((x) => x.id == id);

        tempOrderIdToUpdate = state.allChildProcessData.filter(
          (x) => x.parentProcessId == tempIndexToUpdate.parentProcessId
        );
        let tempIndex = tempOrderIdToUpdate.findIndex((x) => x.id == id);
        if (tempIndex <= tempOrderIdToUpdate.length) {
          tempIndex = tempOrderIdToUpdate.splice(
            tempIndex,
            tempOrderIdToUpdate.length
          );
          for (var i = 0; i < state.allChildProcessData.length; i++) {
            tempIndex.forEach((x) => {
              if (state.allChildProcessData[i].id === x.id) {
                state.allChildProcessData[i].orderId =
                  state.allChildProcessData[i].orderId - 1;
                state.allChildProcessData[i].rowUpdated = true;
              }
            });
          }
        }
      }
    });
    state.deleteIds = tempDeleteIds.filter((x) => x > 0);
    for (var i = 0; i < state.allChildProcessData.length; i++) {
      tempDeleteIds.forEach((x) => {
        if (state.allChildProcessData[i].id === x) {
          state.allChildProcessData.splice(i, 1);
          i--;
        }
      });
    }

    //state.allChildProcessData = [...new Set(tempallChildProcessData)];
    state.currentProcessObj.processes = state.allChildProcessData;
    //console.table(state.allChildProcessData);
    state.currentChildObj = {};
    state.currentChildId = null;
    if (state.deleteIds.length == 0) {
      state.deleteCounter++;
    } else {
      state.deleteIds = state.deleteIds.sort(function (a, b) {
        return b - a;
      });
      state.isDeleteMethodCalled = true;
    }
  },
  mutateDeleteProcesses(state) {
    state.deleteProcessesCounter++;
  },
  mutateDeleteParentProcessSet(state) {
    state.deleteProcessesSetCounter++;
  },

  mutuateOrderId(state) {
    let tempOrderId = 0;
    let tempOrderIdToUpdate = [];
    //console.log(Object.getOwnPropertyNames(state.currentChildObj).length)
    if (Object.getOwnPropertyNames(state.currentChildObj).length === 1) {
      tempOrderId = state.allChildProcessData.filter(
        (x) => x.parentProcessId == 0 || x.parentProcessId == null
      );
      tempOrderId = tempOrderId.length;
    } else {
      tempOrderIdToUpdate = state.allChildProcessData.filter(
        (x) => x.parentProcessId == state.currentChildObj.parentProcessId
      );
      tempOrderIdToUpdate.sort(function (a, b) {
        return a.orderId - b.orderId;
      });
      let tempIndex = tempOrderIdToUpdate.findIndex(
        (x) => x.id == state.currentChildId
      );
      if (tempIndex + 1 <= tempOrderIdToUpdate.length) {
        tempIndex = tempOrderIdToUpdate.splice(
          tempIndex + 1,
          tempOrderIdToUpdate.length
        );
        for (var i = 0; i < state.allChildProcessData.length; i++) {
          tempIndex.forEach((x) => {
            if (state.allChildProcessData[i].id === x.id) {
              state.allChildProcessData[i].orderId =
                state.allChildProcessData[i].orderId + 1;
              state.allChildProcessData[i].rowUpdated = true;
            }
          });
        }
      }
      tempOrderId = state.currentChildObj.orderId + 1;
    }
    let tempCurrentObj = { ...state.currentChildObj };
    let tempCurrentPathObj = [];
    let tempCurrentObjLength = Object.keys(tempCurrentObj).length;
    if (tempCurrentObjLength > 0) {
      tempCurrentPathObj = [...tempCurrentObj.path];
      tempCurrentPathObj.pop();
      tempCurrentPathObj.push(-1 * state.addProcessCounter);
    }

    var tempChildObj = {
      id: -1 * state.addProcessCounter,
      name: "",
      description: "",
      settings: [],
      writeContext: null,
      rowUpdated: true,
      orderId: tempOrderId,
      parentProcessId:
        tempCurrentObjLength == 0 ? null : tempCurrentObj.parentProcessId,
      path:
        tempCurrentObjLength == 0
          ? [-1 * state.addProcessCounter]
          : tempCurrentPathObj,
      processSetId: state.currentParentId,
      processTypeId: null,
      processType: {
        name: "Script Process",
        description: "Process to execute javascript script",
        className: "PScript",
      },
      processData: null,
      processExecutionParameter: null,
    };
    state.currentChildObj = tempChildObj;
    state.currentChildId = tempChildObj.id;
    state.allChildProcessData.push(tempChildObj);
    state.addProcessCounter++;
  },

  exportToJsonFile() {
    let tempProcessSet = { ...state.currentProcessObj };

    let tempChildProcess = [...tempProcessSet.processes];
    let childProcessJSON = list_to_tree(tempChildProcess);
    //console.log(tempChildProcess, childProcessJSON);
    let newchildProcessJSON = childProcessesRecursion(childProcessJSON);

    let processSetExport = {
      Name: tempProcessSet.name,
      Description: tempProcessSet.description,
      InputData: tempProcessSet.inputData,
      InputDataName: tempProcessSet.inputDataName,
      OutputData: tempProcessSet.outputData,
      OutputDataName: tempProcessSet.outputDataName,
      RowTypeId: tempProcessSet.rowTypeId,
      IsWizard: tempProcessSet.isWizard,
      ChildProcesses: newchildProcessJSON,
    };

    let dataStr = JSON.stringify(processSetExport);
    let dataUri =
      "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

    let exportFileDefaultName = state.currentProcessObj.name + ".json";

    let linkElement = document.createElement("a");
    linkElement.setAttribute("href", dataUri);
    linkElement.setAttribute("download", exportFileDefaultName);
    linkElement.click();
  },

  deleteProcessSets(state, { Ids }) {
    state.isParentDeleteMethodCalled = false;
    state.deleteParentIds = Ids.filter((x) => x > 0);
    Ids.forEach((x) => {
      for (var i = state.allProcessSets.length - 1; i >= 0; i--) {
        if (state.allProcessSets[i].id === x) {
          state.allProcessSets.splice(i, 1);
        }
      }
    });
    if (state.deleteParentIds.length == 0) {
      state.currentChildObj = {};
      state.currentChildId = null;
      state.currentProcessObj = {};
      state.addProcessHeaderCounter = 1;
      state.currentParentObj == {};
      state.currentParentId = null;
      state.copyCounter = 0;
      state.deleteParentCounter++;
    } else {
      state.isParentDeleteMethodCalled = true;
    }
  },

  resetParentObjs(state) {
    state.currentChildObj = {};
    state.currentChildId = null;
    state.currentProcessObj = {};
    state.addProcessHeaderCounter = 1;
    state.currentParentObj == {};
    state.currentParentId = null;
    state.copyCounter = 0;
    state.deleteParentCounter++;
  },
  async convertJSONtoProcessSet(state, { tempProcessHeaderdata }) {
    //console.log(data);
    let tempProcessHeaderObj = {
      id: -999,
      name: tempProcessHeaderdata.Name,
      description: tempProcessHeaderdata.Description,
      inputData:
        tempProcessHeaderdata.InputData == undefined
          ? null
          : tempProcessHeaderdata.InputData,
      inputDataName:
        tempProcessHeaderdata.InputDataName == undefined
          ? null
          : tempProcessHeaderdata.InputDataName,
      outputData:
        tempProcessHeaderdata.OutputData == undefined
          ? null
          : tempProcessHeaderdata.OutputData,
      outputDataName:
        tempProcessHeaderdata.OutputDataName == undefined
          ? null
          : tempProcessHeaderdata.OutputDataName,
      rowTypeId:
        tempProcessHeaderdata.RowTypeId == undefined
          ? null
          : tempProcessHeaderdata.RowTypeId,
      isWizard:
        tempProcessHeaderdata.IsWizard == undefined
          ? null
          : tempProcessHeaderdata.IsWizard,
      rowUpdated: true,
    };
    let tempChildProcesses = tempProcessHeaderdata.ChildProcesses;
    tempProccessId = -1;
    let returnChildobject = [];
    let listofProcesses = await convertChildProc_to_treeProc(
      tempChildProcesses,
      null,
      returnChildobject
    ).then((response) => {
      if (response.length > 0) {
        data = [...response];
        state.allChildProcessData = setpath(data);
        tempProcessHeaderObj.processes = state.allChildProcessData;
        state.allProcessSets.push(tempProcessHeaderObj);
        state.currentProcessObj = tempProcessHeaderObj;
        state.currentParentId = -999;
        state.currentParentObj = tempProcessHeaderObj;
        state.currentChildId = tempProccessId;
        state.copyCounter++;
      }
    });
    /* data = listofProcesses;
    console.log(data);
    state.allChildProcessData = setpath(data);
    tempProcessHeaderObj.processes = state.allChildProcessData;
    state.allProcessSets.push(tempProcessHeaderObj);
    state.currentProcessObj = tempProcessHeaderObj; */
    //state.copyCounter++;
  },

  mutateScriptValue(state, codeValue) {
    state.tempScriptValue = codeValue;
  },

  mutateProcessTypeData(state, processTypeObj) {
    //state.currentChildObj.processData = processTypeObj;
    state.tempProcessTypeValue = processTypeObj;
  },

  mutateProcesses(state, dataObj) {
    //console.log(dataObj);
    state.allChildProcessData = dataObj;
  },
};

function childProcessesRecursion(childProcessJSON) {
  let returnChildobject = [];
  childProcessJSON.sort((a, b) =>
    a.orderId > b.orderId ? 1 : a.orderId < b.orderId ? -1 : 0
  );
  childProcessJSON.forEach((element, index) => {
    if (
      element.processType.className != "PSQL" &&
      element.processType.className != "PGoTo"
    ) {
      if (element.processData != null && element.processData.length > 0) {
        element.processData = JSON.parse(element.processData);
      }
    }
    if (element.childProcesses.length > 0) {
      returnChildobject.push({
        Name: element.name,
        Description: element.description,
        ProcessType: element.processType.className,
        ProcessExecutionParameter: element.processExecutionParameter,
        ProcessData: element.processData,
        WriteContext: element.writeContext,
        ChildProcesses: childProcessesRecursion(element.childProcesses),
      });
    } else {
      returnChildobject.push({
        Name: element.name,
        Description: element.description,
        ProcessType: element.processType.className,
        WriteContext: element.writeContext,
        ProcessExecutionParameter: element.processExecutionParameter,
        ProcessData: element.processData,
      });
    }
  });
  return returnChildobject;
}

function list_to_tree(processes) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < processes.length; i += 1) {
    map[processes[i].id] = i; // initialize the map
    processes[i].childProcesses = []; // initialize the children
  }
  //console.log(processes)

  for (i = 0; i < processes.length; i += 1) {
    node = processes[i];

    //console.log(typeof node.parentProcessId, node.parentProcessId);
    if (node.parentProcessId > 0 || node.parentProcessId < 0) {
      // if you have dangling branches check that map[node.parentId] exists
      processes[map[node.parentProcessId]].childProcesses.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

async function convertChildProc_to_treeProc(
  ChildProcesses,
  parentId,
  returnChildobject
) {
  // let returnChildobject = [];
  ChildProcesses.forEach((row, index) => {
    tempProccessId--;
    row.id = tempProccessId;
    row.parentProcessId = parentId;
    // row.path = [-1 * index];
    let tempProcessType = state.allProcessTypes.find(
      (x) => x.className == row.ProcessType
    );
    if (tempProcessType != undefined) {
      row.processTypeId = tempProcessType.id;
      row.ProcessType = {
        name: tempProcessType.name,
        description: tempProcessType.description,
        className: tempProcessType.className,
      };
    }
    returnChildobject.push({
      description: row.Description,
      id: tempProccessId,
      name: row.Name,
      orderId: index,
      processSetId: -999,
      processData: JSON.stringify(row.ProcessData),
      processExecutionParameter: null,
      processType: row.ProcessType,
      settings: [],
      writeContext: false,
      xmlData: null,
      rowUpdated: true,
      parentProcessId: row.parentProcessId,
    });
    if (row.ChildProcesses != undefined) {
      convertChildProc_to_treeProc(
        row.ChildProcesses,
        row.id,
        returnChildobject
      );
    }
    //tempProccessId--;
  });
  return returnChildobject;
}

const getters = {
  getAllProcessSets() {
    return state.allProcessSets;
  },
  getAllChildProcessSets() {
    return state.allChildProcessData;
  },
  getCurrentChildObj() {
    //console.log(state.currentChildObj);
    return state.currentChildObj;
  },
  getCurrentChildObjId() {
    return state.currentChildId;
  },
  getAllProcessTypes() {
    return state.allProcessTypes;
  },
  getCurrentParentObj() {
    return state.currentParentObj;
  },
  getCurrentParentObjId() {
    return state.currentParentId;
  },
};

export const processSetController = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
