<template>
  <div
    class="sidemenu"
    :class="{ 'is-expanded': sidemenuIsExpanded }"
    v-if="loggedIn"
  >
    <ul @click="sidemenuIsExpanded = !sidemenuIsExpanded">
      <li>
        <i class="fas fa-bars"></i>
        <h1></h1>
      </li>
    </ul>

    <ul
      :class="{
        'is-active':
          currentRouteName == 'Dashboard' ||
          currentRouteName == 'Project Dashboard',
      }"
      @click.capture="expandSubMenu"
      v-if="loggedIn"
      aria-expanded="false"
    >
      <li>
        <i class="fas fa-home"></i>
        <h1>Dashboards</h1>
        <span class="expandable"
          ><i class="fas fa-angle-down"></i><i class="fas fa-angle-up"></i
        ></span>
      </li>
      <ul>
        <li><h1>Dashboards</h1></li>
        <li
          class="long-view-text-size"
          v-for="dashId in dashboardTypes.filter((x) => x.clientId == clientId)"
          :key="dashId.id"
          :class="{
            'is-active':
              (currentRouteName == 'Dashboard' || currentRouteName == '') &&
              selectedDashboardType &&
              dashId.id == selectedDashboardType.id,
          }"
          @click="
            mutateSelectedDashboardType({ selectedDashboardTypeId: dashId.id })
          "
          @click.capture.prevent="routeToDashboard()"
        >
          <LevelIconCellRenderer :value="dashId.name" />
          {{ dashId.name + " Dashboard" }}
        </li>

        <li
          class="long-view-text-size"
          v-for="dashId in dashboardTypes.filter(
            (x) => x.clientId == 0 && clientId != 3
          )"
          :key="dashId.id"
          :class="{
            'is-active':
              (currentRouteName == 'Dashboard' || currentRouteName == '') &&
              selectedDashboardType &&
              dashId.id == selectedDashboardType.id,
          }"
          @click="
            mutateSelectedDashboardType({
              selectedDashboardTypeId: dashId.id,
            })
          "
          @click.capture.prevent="routeToDashboard()"
        >
          <LevelIconCellRenderer :value="dashId.name" />
          {{ dashId.name + " Dashboard" }}
        </li>

        <li
          v-if="dashboardTypes.length < 1"
          @click.capture.prevent="routeToDashboard()"
        >
          Click to load!
        </li>
      </ul>
    </ul>

    <ul
      :class="{ 'is-active': currentRouteName == 'Views' }"
      @click.capture="expandSubMenu"
      v-if="loggedIn"
      aria-expanded="false"
    >
      <li>
        <i class="fas fa-chart-bar"></i>
        <h1>P3 Management</h1>
        <span class="expandable"
          ><i class="fas fa-angle-down"></i><i class="fas fa-angle-up"></i
        ></span>
      </li>
      <!-- <li @click.capture.prevent="routerPush('/views')" ... -->
      <ul>
        <li><h1>P3 Management</h1></li>

        <li
          class="long-view-text-size"
          v-for="view in views.filter((x) => x.viewTypeId != 7)"
          :key="view.UserViewOrder"
          :class="{
            'is-active':
              currentRouteName == 'Views' &&
              selectedView &&
              view.id == selectedView.id,
          }"
          @click="mutateSelectedView({ selectedViewId: view.id })"
          @click.capture.prevent="routeToViews()"
        >
          {{ view.name }}
        </li>
        <li v-if="views.length < 1" @click.capture.prevent="routeToViews()">
          Click to load!
        </li>
      </ul>
    </ul>

    <ul
      :class="{ 'is-active': currentRouteName == 'Data' }"
      @click.capture="expandSubMenu"
      v-if="loggedIn && validIds.indexOf(userid) > -1"
      aria-expanded="false"
    >
      <li>
        <LevelIconCellRenderer :value="'TransactionData'" />
        <h1>Transactional Data</h1>
        <span class="expandable"
          ><i class="fas fa-angle-down"></i><i class="fas fa-angle-up"></i
        ></span>
      </li>
      <ul>
        <li><h1>Transactional Data</h1></li>
        <!-- This is because eslint doesnt like mixing a v-for and a v-if, once this is resolved we can remove it -->
        <!-- eslint-disable -->
        <li
          class="long-view-text-size"
          v-for="currDT in dataTypes"
          :key="currDT.id"
          :class="{
            'is-active':
              currentRouteName == 'Data' &&
              selectedDataType &&
              currDT.id == selectedDataType.id,
          }"
          @click="mutateSelectedDataType({ selectedDataTypeId: currDT.id })"
          @click.capture.prevent="routeToData()"
          v-if="
            currDT.id < 3 || (currDT.id >= 3 && adminIds.indexOf(userid) > -1)
          "
        >
          <!-- eslint-enable -->
          <p>
            <LevelIconCellRenderer :value="currDT.name" />
            {{ currDT.name }}
          </p>
        </li>
        <li v-if="dataTypes.length < 1" @click.capture.prevent="routeToData()">
          Click to load!
        </li>
      </ul>
    </ul>

    <ul
      :class="{ 'is-active': currentRouteName == 'MasterData' }"
      @click.capture="expandSubMenu"
      v-if="loggedIn && adminIds.indexOf(userid) > -1"
      aria-expanded="false"
    >
      <li>
        <LevelIconCellRenderer :value="'MasterData'" />
        <h1>Master Data</h1>
        <span class="expandable">
          <i class="fas fa-angle-down"></i><i class="fas fa-angle-up"></i>
        </span>
      </li>
      <ul>
        <li><h1>Master Data</h1></li>

        <li
          class="long-view-text-size"
          v-for="currDT in MasterDataTypes"
          :key="currDT.id"
          :class="{
            'is-active':
              currentRouteName == 'MasterData' &&
              selectedMasterDataType &&
              currDT.id == selectedMasterDataType.id,
          }"
          @click="
            mutateSelectedMasterDataType({
              selectedMasterDataTypeId: currDT.id,
            })
          "
          @click.capture.prevent="routeToMasterData()"
        >
          <LevelIconCellRenderer :value="currDT.name" />
          {{ currDT.name }}
        </li>
        <li
          v-if="MasterDataTypes.length < 1"
          @click.capture.prevent="routeToMasterData()"
        >
          Click to load!
        </li>
        <!--  <li
          class="long-view-text-size"
          :class="{
            'is-active': currentRouteName == 'Structures',
          }"
          @click.capture.prevent="routeToStructure()"
        >
          <LevelIconCellRenderer :value="'structure'" />
          {{ "Structures" }}
        </li> -->
        <!--   <li @click.capture.prevent="routeToStructure()">
          Click to load!
        </li> -->
      </ul>
    </ul>
    <!--   <ul
      :class="{ 'is-active': currentRouteName == 'Scheduling' }"
      @click.capture="expandSubMenu"
      v-if="loggedIn && adminIds.indexOf(userid) > -1"
      aria-expanded="false"
    >
      <li>
        <i class="fas fa-chart-bar"></i>
        <h1>Scheduling Data</h1>
        <span class="expandable"
          ><i class="fas fa-angle-down"></i><i class="fas fa-angle-up"></i
        ></span>
      </li>
      <ul>
        <li><h1>Scheduling Data</h1></li>
        <li
          class="long-view-text-size"
          v-for="currDT in allDataTypes"
          :key="currDT.id"
          :class="{
            'is-active':
              currentRouteName == 'Scheduling' &&
              selectedScheduleDataType &&
              currDT.id == selectedScheduleDataType.id,
          }"
          @click="mutateSchedulingDataType(currDT.id)"
          @click.capture.prevent="routeToSchedule()"
        >
          <LevelIconCellRenderer :value="currDT.name" />
          {{ currDT.name }}
        </li>
        <li
          v-if="allDataTypes.length < 1"
          @click.capture.prevent="routeToSchedule()"
        >
          Click to load!
        </li>
      </ul>
    </ul> -->
    <ul
      :class="{ 'is-active': currentRouteName == 'ProcessSets' }"
      @click.capture="expandSubMenu"
      v-if="loggedIn && adminIds.indexOf(userid) > -1"
      aria-expanded="false"
    >
      <li>
        <i class="fas fa-cogs"></i>
        <LevelIconCellRenderer :value="'ProcessSets'" />
        <h1>Process Sets</h1>
        <span class="expandable">
          <i class="fas fa-angle-down"></i><i class="fas fa-angle-up"></i>
        </span>
      </li>
      <ul>
        <li><h1>Process Sets</h1></li>
        <li
          class="long-view-text-size"
          :class="{
            'is-active': currentRouteName == 'ProcessSets',
          }"
          @click.capture.prevent="routeToProcessSets()"
        >
          {{ "ProcessSets" }}
        </li>
      </ul>
    </ul>

    <ul
      :class="{ 'is-active': currentRouteName == 'Settings' }"
      @click.capture="expandSubMenu"
      v-if="loggedIn && adminIds.indexOf(userid) > -1"
      aria-expanded="false"
    >
      <li>
        <LevelIconCellRenderer :value="'Settings'" />
        <h1>Settings</h1>
        <span class="expandable">
          <i class="fas fa-angle-down"></i><i class="fas fa-angle-up"></i>
        </span>
      </li>
      <ul>
        <li><h1>Settings</h1></li>

        <li
          class="long-view-text-size"
          v-for="currDT in UserDataTypes"
          :key="currDT.id"
          :class="{
            'is-active':
              currentRouteName == 'Settings' &&
              selectedUserDataType &&
              currDT.id == selectedUserDataType.id,
          }"
          @click="
            mutateSelectedUserDataType({
              selectedUserDataType: currDT.id,
            })
          "
          @click.capture.prevent="routeToSettings()"
        >
          <LevelIconCellRenderer :value="currDT.name" />
          {{ currDT.name }}
        </li>
        <li
          v-if="UserDataTypes.length < 1"
          @click.capture.prevent="routeToSettings()"
        >
          Click to load!
        </li>
        <!--  <li
          class="long-view-text-size"
          :class="{
            'is-active': currentRouteName == 'Structures',
          }"
          @click.capture.prevent="routeToStructure()"
        >
          <LevelIconCellRenderer :value="'structure'" />
          {{ "Structures" }}
        </li> -->
        <!--   <li @click.capture.prevent="routeToStructure()">
          Click to load!
        </li> -->
      </ul>
    </ul>
    <ul>
      <li>
        <LevelIconCellRenderer :value="'Integrate'" />
        <h1>Integrate</h1>
        <span class="expandable">
          <i class="fas fa-angle-down"></i><i class="fas fa-angle-up"></i>
        </span>
      </li>
      <ul>
        <li><h1>Integrate</h1></li>

        <li
          class="long-view-text-size"
          v-for="currDT in IntegrateDataTypes"
          :key="currDT.id"
          :class="{
            'is-active':
              currentRouteName == 'Integrate' &&
              selectedIntegrateDataType &&
              currDT.id == selectedIntegrateDataType.id,
          }"
          @click="
            mutateSelectedIntegrateDataType({
              selectedIntegrateDataType: currDT.id,
            })
          "
          @click.capture.prevent="routeToSettings()"
        >
          <LevelIconCellRenderer :value="currDT.name" />
          {{ currDT.name }}
        </li>
        <li
          v-if="IntegrateDataTypes.length < 1"
          @click.capture.prevent="routeToSettings()"
        >
          Click to load!
        </li>
        <!--  <li
          class="long-view-text-size"
          :class="{
            'is-active': currentRouteName == 'Structures',
          }"
          @click.capture.prevent="routeToStructure()"
        >
          <LevelIconCellRenderer :value="'structure'" />
          {{ "Structures" }}
        </li> -->
        <!--   <li @click.capture.prevent="routeToStructure()">
          Click to load!
        </li> -->
      </ul>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
$sidemenu-orientation: left;
@import "./collapsable-sidebar.scss";
li.long-view-text-size {
  font-size: 12px !important;
}
</style>

<script>
import { expandSubMenu } from "./collapsable-sidebar.js";
import LevelIconCellRenderer from "../Data/LevelIconCellRenderer.vue";
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "CollapsableSideBar",
  components: {
    LevelIconCellRenderer,
  },
  computed: {
    ...mapGetters("account", ["loggedIn", "userid", "clientId"]),
    ...mapState({
      views: (state) => state.userView.views,
      dataTypes: (state) => state.dataManager.dataTypes,
      MasterDataTypes: (state) => state.structureManager.MasterDataTypes,
      dashboardTypes: (state) => state.dashboardMenu.dashboardMenuTypes,
      isMenuExpanded: (state) => state.filterControl.isMenuExpanded,
      UserDataTypes: (state) => state.userManagement.UserDataTypes,
      IntegrateDataTypes: (state) => state.processSetDataManager.allDataTypes,
    }),
    ...mapGetters("userView", ["selectedView"]),
    ...mapGetters("dataManager", ["selectedDataType"]),
    ...mapGetters("dashboardMenu", ["selectedDashboardType"]),

    ...mapGetters("schedulingDataManager", [
      "allDataTypes",
      "selectedScheduleDataType",
    ]),
    ...mapGetters("structureManager", ["selectedMasterDataType"]),
    ...mapGetters("userManagement", ["selectedUserDataType"]),
    ...mapGetters("processSetDataManager", ["selectedIntegrateDataType"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapMutations("userView", ["mutateSelectedView"]),
    ...mapMutations("dataManager", ["mutateSelectedDataType"]),
    ...mapMutations("schedulingDataManager", ["mutateSchedulingDataType"]),
    ...mapMutations("filterControl", ["toggleMenuExpanded"]),
    ...mapMutations("dashboardMenu", ["mutateSelectedDashboardType"]),
    ...mapMutations("structureManager", ["mutateSelectedMasterDataType"]),
    ...mapMutations("userManagement", ["mutateSelectedUserDataType"]),
    ...mapMutations("processSetDataManager", [
      "mutateSelectedIntegrateDataType",
    ]),
    expandSubMenu: expandSubMenu,
    routerPush(args) {
      this.$router.push(args);
    },
    routeToViews() {
      if (this.currentRouteName != "Views") {
        this.routerPush("/views");
      }
    },
    routeToData() {
      if (this.currentRouteName != "Data") {
        this.routerPush("/data");
      }
    },

    routeToMasterData() {
      if (this.currentRouteName != "MasterData") {
        this.routerPush("/masterdata");
      }
    },
    routeToSchedule() {
      if (this.currentRouteName != "Scheduling") {
        this.routerPush("/scheduling");
      }
    },
    routeToProcessSets() {
      if (this.currentRouteName != "Integrate") {
        console.log();
        this.routerPush("/integrate");
      }
    },

    routeToDashboard() {
      if (this.currentRouteName != "Dashboard") {
        this.routerPush("/Dashboard");
      }
    },
    routeToSettings() {
      if (this.currentRouteName != "Settings") {
        this.routerPush("/Settings");
      }
    },
  },
  data() {
    return {
      sidemenuIsExpanded: false,
      validIds: [
        "e09c810d-5c8d-469c-9b82-c362f096af10",
        "72f88744-dd7f-465e-9e78-7970b55a785a",
        "3c256b65-5e6a-4ec4-b562-17fa993f3ba4",
        "ddf167dc-46a3-433c-911c-ba81c47cbc96",
        "e48cc2f9-69d3-403d-a7b4-092a3fd71f50", //admin@mirvac
        "129b4d14-3d4d-40ee-99e0-5da4b63146a5", //frank.peylaire
        "1bd4596c-e203-40f5-9517-adfb09fc5ff6", //admin@jivedemo.com.au
        "5740db92-8fa2-4991-8871-afc178087514", //bronwyn.harris@mirvac.com
      ],
      adminIds: [
        "e09c810d-5c8d-469c-9b82-c362f096af10",
        "e48cc2f9-69d3-403d-a7b4-092a3fd71f50", //admin@mirvac
        "129b4d14-3d4d-40ee-99e0-5da4b63146a5", //frank.peylaire
        "ddf167dc-46a3-433c-911c-ba81c47cbc96", //rob ashley
        "1bd4596c-e203-40f5-9517-adfb09fc5ff6", //admin@jivedemo.com.au
        "5740db92-8fa2-4991-8871-afc178087514", //bronwyn.harris@mirvac.com
      ],
      //   dataTypes: [
      //   {
      //     id: 0,
      //     name: "Portfolio",
      //     iconClass: "fas fa-folder-open",
      //   },
      //   {
      //     id: 1,
      //     name: "Program",
      //     iconClass: "fas fa-sitemap",
      //   },
      //   {
      //     id: 2,
      //     name: "Projects",
      //     iconClass: "fas fa-diagram",
      //   },
      // ],
    };
  },
};
</script>
