<template>
  <nav
    class="navbar"
    role="navigation"
    aria-label="main navigation"
    style="border-bottom: 2px solid; margin-bottom: 5px"
  >
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-end" style="margin-right: 18px">
        <!--  Save Button -->
        <button
          class="button"
          title="Save"
          @click="mutateParentSave"
          v-if="currentRouteName != 'login'"
          :disabled="getParentSelectedId == null"
        >
          <span class="icon is-small">
            <i class="fas fa-save"></i>
          </span>
        </button>
        <!--  RelationShip Button -->
        <!--   :class="{ 'is-active': showFilterModal }" -->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add Relationship"
          aria-expanded="false"
          :class="{ 'is-active': showFilterModal }"
          @click.capture.prevent="AddRelationship"
          :disabled="getParentSelectedId == null"
        >
          <span class="icon is-small">
            <i class="fas fa-network-wired"></i>
          </span>
        </button>
        <!--  Rest Column State Button -->
        <button
          class="button"
          title="Reset Columns"
          v-if="currentRouteName == 'login'"
        >
          <span class="icon is-small">
            <i class="fas fa-text-width"></i>
          </span>
        </button>
        <!--  Undo Button -->
        <button
          class="button"
          title="Undo"
          v-if="currentRouteName != 'login'"
          @click="mutateParentUndo"
          :disabled="isUndo == false"
        >
          <span class="icon is-small">
            <i class="fas fa-undo"></i>
          </span>
        </button>
        <!--  Redo Button -->
        <button
          class="button"
          title="Redo"
          v-if="currentRouteName != 'login'"
          @click="mutateParentRedo"
          :disabled="isRedo == false"
        >
          <span class="icon is-small">
            <i class="fas fa-redo"></i>
          </span>
        </button>
        <!--  Add New Record Button -->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add New"
          @click="muatateParentAdd"
        >
          <span class="icon is-small">
            <i class="fas fa-plus"></i>
          </span>
        </button>
        <!--  Delete Button -->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Delete"
          @click="mutateParentDelete"
          :disabled="getParentSelectedId == null"
        >
          <!--  @click="saveRecords" @click="deleteRecord"> -->
          <span class="icon is-small">
            <i class="fas fa-trash-alt"></i>
          </span>
        </button>

        <!-- Copy Gantt and Schedule Record-->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Copy"
          aria-expanded="false"
          :class="{ 'is-active': showFilterModal }"
          @click="CopyRecord"
          :disabled="getParentSelectedId < 0 || getParentSelectedId == null"
        >
          <span class="icon is-small">
            <i class="fas fa-copy"></i>
          </span>
        </button>
        <!-- <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="paste"
          aria-expanded="false"
          :class="{ 'is-active': showFilterModal }"
          @click="
            mutatePasteRecords({
              flag: true,
              scheduleHeaderId: getParentSelectedId,
            })
          "
          :disabled="isCopyDone == false"
        >
          <span class="icon is-small">
            <i class="fas fa-paste"></i>
          </span>
        </button> -->
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
p {
  width: 165px;
  display: inline-block;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}
nav.navbar {
  border-top: 0px solid black;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
</style>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "SchedulingBar",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("schedulingDataManager", [
      "getParentSelectedId",
      "getCopyRecord",
    ]),
    ...mapState({
      showFilterModal: (state) =>
        state.filterControl.isStructureFilterModalVisible,
    }),

    /*Parent Controller State */
    ...mapState({
      isRedo: (state) => state.schedulingDataManager.currentParentredostate,
      isUndo: (state) => state.schedulingDataManager.currentParentundostate,
    }),

    ...mapState("activities", ["isCopyDone", "isPasteDone"]),

    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    isCopyDone() {
      if (this.isCopyDone) {
        this.mutatePasteRecords({
          flag: true,
          scheduleHeaderRecord: this.getCopyRecord,
        });
      }
    },
    isPasteDone() {
      if (this.isPasteDone) {
        this.saveCopiedRecord(this.getCopyRecord.startDate);
      }
    },
  },
  methods: {
    /* Parent Controller Mutations */
    ...mapMutations("schedulingDataManager", [
      "mutateParentUndo",
      "mutateParentRedo",
      "mutateParentDelete",
      "muatateParentAdd",
      "mutateParentSave",
      "mutateScheduleHeaderRealtionship",
      "mutuateCopyRecord",
    ]),
    /* RelationShip Mutations */
    ...mapMutations("relationControl", ["toggleRelationFilterModal"]),

    /* RelationShip Mutations */
    ...mapActions("relationControl", ["GetScheduleHeaderRelations"]),

    /* Copy Whole Gantt */
    ...mapActions("schedulingDataManager", ["saveCopyRecord"]),

    ...mapMutations("activities", ["mutatePasteRecords", "mutateGanttCopy"]),
    ...mapActions("activities", ["loadAndCopyActivities", "saveCopiedRecord"]),
    ...mapActions({
      loadAllBaselines: "baselines/loadEntities",
    }),

    AddRelationship() {
      this.mutateScheduleHeaderRealtionship(true);
      this.GetScheduleHeaderRelations({
        scheduleHeaderID: this.getParentSelectedId,
      });
      this.toggleRelationFilterModal();
    },

    CopyRecord() {
      this.loadAndCopyActivities(this.getParentSelectedId).then(() => {
        this.mutuateCopyRecord();
        this.saveCopyRecord();
      });
      this.loadAllBaselines(this.getParentSelectedId);
    },
  },
};
</script>
