import Vue from "vue";
import Vuex from "vuex";

import { alert } from "./alert";
import { account } from "./account";
import { filterControl } from "./filter-control";
import { relationControl } from "./relation-control";
import { storedates } from "./date-store.js";
import { userView } from "./user-view";
import { dataManager } from "./data-manager";
import { clientAdmin } from "./clientAdmin";
import { dashboard } from "./dashboard";
import { dashboardportfolio } from "./dashboardportfolio";
import { dashboardMenu } from "./dashboardMenu";
import { structureManager } from "./structureManager";
import { spendingcurveManager } from "./spendingcurve-control";
import { costelementManager } from "./CostHeader/costHeader-manager";
import { structredetailControl } from "./structuredetail-manger";
import { costelementExtraColumnManager } from "./costelementExtraColumn-manger";
import { activities } from "./activities";
import { schedulingDataManager } from "./schedulingDataManager";
import { processSetDataManager } from "./processSetDataManager";
import { analyticsDataManager } from "./Analytics/analytics";
import { activityControl } from "./Activity";
import { costHeaderController } from "./CostHeader/costHeader-controller.js";
import { costElementController } from "./CostHeader/costElement-controller";
import { rowTypeDataManager } from "./rowType";
import { scheduleResourceController } from "./ScheduleResources-controller";
import { calendarController } from "./CalendarController";
import { commitmentCostElementControl } from "./commitmentCostElement-manager";
import { performanceMetricController } from "./PerformanceMetricsController";
import { performanceStatusController } from "./PerformanceStatusController";
import { riskSeverityController } from "./RiskSeverityController";
import { riskController } from "./RiskController";
import { performanceSheetManager } from "./PerformanceSheet-controller";

import { baselines } from "./baselines";
import { TransactionalData } from "./TransactionalData.js";
import { jiveComponents } from "./jiveComponents.js";
import { groupAdmin } from "./groupAdmin.js";
import { userManagement } from "./UserManagement.js";
import { processSetController } from "./ProcessSetController.js";
import { externalSystemsAccess } from "./UserMgmt/ExternalSystemsAccess.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    account,
    alert,
    analyticsDataManager,
    filterControl,
    relationControl,
    storedates,
    userView,
    dataManager,
    structureManager,
    clientAdmin,
    dashboard,
    dashboardportfolio,
    dashboardMenu,
    costelementManager,
    structredetailControl,
    activities,
    spendingcurveManager,
    costHeaderController,
    costElementController,
    schedulingDataManager,
    processSetDataManager,
    costelementExtraColumnManager,
    activityControl,
    commitmentCostElementControl,
    rowTypeDataManager,
    scheduleResourceController,
    calendarController,
    baselines,
    performanceMetricController,
    performanceStatusController,
    riskSeverityController,
    riskController,
    performanceSheetManager,
    TransactionalData,
    jiveComponents,
    groupAdmin,
    userManagement,
    processSetController,
    externalSystemsAccess,
  },
});
