var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.selectedDashboardType.id == 0 &&
    _vm.selectedDashboardType.clientId == _vm.clientId
  )?_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.loggedIn),expression:"loggedIn"}],staticStyle:{"height":"100%","width":"100%","overflow":"auto"}},[_c('dashboard-portfolio')],1):(
    _vm.selectedDashboardType.id == 1 &&
    _vm.selectedDashboardType.clientId == _vm.clientId
  )?_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.loggedIn),expression:"loggedIn"}],staticStyle:{"height":"100%","width":"100%","overflow":"auto"}},[_c('dashboard')],1):(
    _vm.selectedDashboardType.id == 2 &&
    _vm.selectedDashboardType.clientId != _vm.clientId
  )?_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.loggedIn),expression:"loggedIn"}],staticStyle:{"height":"100%","width":"100%","overflow":"auto"}},[_c('default-portfolio-dashboard')],1):(
    _vm.selectedDashboardType.id == 3 &&
    _vm.selectedDashboardType.clientId != _vm.clientId
  )?_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.loggedIn),expression:"loggedIn"}],staticStyle:{"height":"100%","width":"100%","overflow":"auto"}},[_c('default-project-dashboard')],1):(_vm.retval == undefined && _vm.clientId == 3)?_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.loggedIn),expression:"loggedIn"}],staticStyle:{"height":"100%","width":"100%","overflow":"auto"}},[_c('dashboard-portfolio')],1):(_vm.retval == undefined && _vm.clientId != 3)?_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.loggedIn),expression:"loggedIn"}],staticStyle:{"height":"100%","width":"100%","overflow":"auto"}},[_c('default-project-dashboard')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }