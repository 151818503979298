<template>
  <section style="height: 100%" v-show="loggedIn">
    <StructureManager />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import StructureManager from "../components/Structure/StructureManager.vue";
import Vue from "vue";
import LoadScript from "vue-plugin-load-script";

export default {
  name: "MasterStructureView",
  components: {
    StructureManager,
  },
  watch: {},
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("structureManager", ["selectedMasterDataType"]),
  },
  mounted: function () {
    Vue.use(LoadScript);
    var scriptToUse =
      process.env.NODE_ENV === "development"
        ? "http://localhost:8080/drag-bar.js"
        : process.env.NODE_ENV === "Staging"
        ? "https://jiveconnect-staging.azurewebsites.net/drag-bar.js"
        : "https://app.jiveconnect.com.au/drag-bar.js";
    Vue.unloadScript(scriptToUse).catch((errormsg) => {
      if (errormsg != undefined) {
        console.error(errormsg);
      }
    });

    Vue.loadScript(scriptToUse).catch((errormsg) => {
      console.error(errormsg);
    });
  },
  data() {
    return {};
  },
  methods: {
    //...mapActions("dataManager", ["updateViewList"]),
  },
};
</script>
