import {
  CostHeaderGetByModel,
  DeletecostHeaders,
  GetCostElements,
  UpdateCostHeader,
  DeleteElement,
  getAllStructureDetail,
  getAllExtraColumnDetails,
  getCostModelType,
} from "@/api/networking.js";
import { post } from "@/api/user-auth.js";
import { getStructureAll } from "@/api/networking.js";
import * as moment from "moment";
let data = [],
  i = -1,
  tempData = null;
function getParentLoop(id, path) {
  let p = data.filter((i) => i.id == id)[0];
  if (p.parentId) {
    path.unshift(p.id);
    return getParentLoop(p.parentId, path);
  } else {
    path.unshift(p.id);
  }
  return path;
}

function setpath(data) {
  data.map((i) => {
    i.path = [];
    if (i.parentId) {
      i.path.push(i.id);
      getParentLoop(i.parentId, i.path);
    } else {
      i.path.push(i.id);
    }
    return i;
  });
  return data;
}

function setColElementsHeaders(TabelType) {
  switch (TabelType) {
    default:
      return null;
    case 5:
      return "PlanningCostElement";
    case 6:
      return "ForecastCostElement";
    case 7:
      return "CommitmentCostElement";
    case 8:
      return "ActualCostElement";
  }
}

const state = {
  currentData: [],
  LoadCounter: 0,
  currentCostHeaderID: null,
  editCount: 0,
  currentElementID: null,
  currentElementData: null,
  addingRecord: false,
  undoWatcher: 0,
  redoWatcher: 0,
  isRedo: false,
  isUndo: false,
  isElementUndo: false,
  isElementRedo: false,
  AddCounter: 0,
  DeleteCounter: 0,
  isDelete: 0,
  isDuplicate: 0,
  isSaveCounter: 0,
  AddRecordCounter: 0,
  HeaderDeleteCounter: 0,
  AddSubRecordCounter: 0,
  isCostDelete: 0,
  selectedRowObj: 0,
  elementLoadcounter: 0,
  redoElementWatcher: 0,
  undoElementWatcher: 0,
  dataWatcher: 0,
  tempSaveData: [],
  SaveError: 0,
  SaveCounter: 0,
  selectedCostHeaderObj: null,
  isElementDelete: 0,
  ElementDeleteCounter: 0,
  structuredetails: [],
  currentExtraCostCoulmnId: 0,
  currentStructureRowsCount: 0,
  currentCostModelType: null,
  duplicateRecord: 0,
  getCurrentCostColDef: [
    {
      headerName: "Code",
      field: "code",
      sortable: true,
      resizable: true,
      width: 100,
      filterParams: { excelMode: "windows" },
      editable: true,
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) return { "background-color": "LightGreen" };
        return null;
      },
    },
    {
      headerName: "Description",
      field: "description",
      sortable: true,
      resizable: true,
      width: 300,
      editable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
    },
    {
      headerName: "Quantity",
      field: "quantity",
      sortable: true,
      resizable: true,
      width: 100,
      editable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      valueFormatter: function (params) {
        if (params.value) {
          params.value = parseFloat(params.value);
          return params.value.toFixed(3);
        }
        return null;
      },
      cellRenderer: "agAnimateShowChangeCellRenderer",
    },
    {
      headerName: "TotalQuantity",
      field: "totalQuantity",
      sortable: true,
      resizable: true,
      width: 100,
      editable: false,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      valueFormatter: function (params) {
        if (params.value) {
          params.value = parseFloat(params.value);
          return params.value.toFixed(3);
        }
        return null;
      },
      valueGetter: function (params) {
        if (params.data.parentId == null || params.data.parentId == 0) {
          if (!params.data.isDisabled) {
            params.data.totalQuantity = params.data.quantity;
          } else {
            params.data.totalQuantity = 0;
          }
          return params.data.totalQuantity;
        } else {
          if (params.node.data.lumpSum) {
            params.data.totalQuantity = params.data.quantity;
          } else {
            params.data.totalQuantity =
              params.node.parent.data.totalQuantity * params.data.quantity;
          }
          return params.data.totalQuantity;
        }
      },
      cellRenderer: "agAnimateShowChangeCellRenderer",
    },
    {
      headerName: "UnitCost",
      field: "unitCost",
      sortable: true,
      resizable: true,
      width: 100,
      editable: function (params) {
        let answer = params.node.allLeafChildren.filter(
          (x) => x.data.parentId == params.data.id
        );

        if (answer.length > 0) {
          return false;
        } else {
          return true;
        }
      },
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      valueFormatter: function (params) {
        if (params.value) {
          params.value = parseFloat(params.value);
          return params.value.toFixed(2);
        }
        return null;
      },

      valueGetter: function (params) {
        let answer = params.node.allLeafChildren;
        let sumUnitCost = 0,
          tempChildValues = null;
        for (let x = answer.length - 1; x >= 0; x--) {
          tempChildValues = answer.filter(
            (y) => y.data.parentId == answer[x].data.id
          );
          if (tempChildValues.length > 0) {
            for (let z = 0; z < tempChildValues.length; z++) {
              if (answer[x].data.lumpSum) {
                sumUnitCost +=
                  (tempChildValues[z].data.unitCost *
                    tempChildValues[z].data.quantity) /
                  answer[x].data.totalQuantity;
              } else {
                sumUnitCost +=
                  tempChildValues[z].data.unitCost *
                  tempChildValues[z].data.quantity;
              }
            }
            answer[x].data.unitCost = sumUnitCost;
            sumUnitCost = 0;
          }
        }
        return params.data.unitCost;
      },
      cellRenderer: "agAnimateShowChangeCellRenderer",
    },
    {
      headerName: "TotalCost",
      field: "totalCost",
      sortable: true,
      resizable: true,
      width: 100,
      editable: false,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      valueFormatter: function (params) {
        if (params.value) {
          params.value = parseFloat(params.value);
          return params.value.toFixed(2);
        }
        return null;
      },
      valueGetter: function (params) {
        return params.data.totalQuantity * params.data.unitCost;
      },
      cellRenderer: "agAnimateShowChangeCellRenderer",
    },
    {
      headerName: "UnitHours",
      field: "unitHours",
      sortable: true,
      resizable: true,
      width: 100,
      editable: function (params) {
        let answer = params.node.allLeafChildren.filter(
          (x) => x.data.parentId == params.data.id
        );

        if (answer.length > 0) {
          return false;
        } else {
          return true;
        }
      },
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      valueFormatter: function (params) {
        if (params.value) {
          params.value = parseFloat(params.value);
          return params.value.toFixed(3);
        }
        return null;
      },
      valueGetter: function (params) {
        let answer = params.node.allLeafChildren;
        let sumUnitHours = 0,
          tempChildValues = null;
        for (let x = answer.length - 1; x >= 0; x--) {
          tempChildValues = answer.filter(
            (y) => y.data.parentId == answer[x].data.id
          );
          if (tempChildValues.length > 0) {
            for (let z = 0; z < tempChildValues.length; z++) {
              if (answer[x].data.lumpSum) {
                sumUnitHours +=
                  (tempChildValues[z].data.unitHours *
                    tempChildValues[z].data.quantity) /
                  answer[x].data.totalQuantity;
              } else {
                sumUnitHours +=
                  tempChildValues[z].data.unitHours *
                  tempChildValues[z].data.quantity;
              }
            }
            answer[x].data.unitHours = sumUnitHours;
            sumUnitHours = 0;
          }
        }
        return params.data.unitHours;
      },
      cellRenderer: "agAnimateShowChangeCellRenderer",
    },
    {
      headerName: "TotalHours",
      field: "totalHours",
      sortable: true,
      resizable: true,
      width: 100,
      editable: false,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      valueFormatter: function (params) {
        if (params.value) {
          params.value = parseFloat(params.value);
          return params.value.toFixed(3);
        }
        return null;
      },
      valueGetter: function (params) {
        return params.data.totalQuantity * params.data.unitHours;
      },
      cellRenderer: "agAnimateShowChangeCellRenderer",
    },
    {
      headerName: "UOM",
      field: "uom",
      sortable: true,
      resizable: true,
      width: 100,
      editable: function (params) {
        if (params.node.data.isDisabled) {
          return false;
        } else {
          return true;
        }
      },
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      valueFormatter: function (params) {
        if (params.value) {
          params.value = parseFloat(params.value);
          return params.value.toFixed(3);
        }
        return null;
      },
    },
    {
      headerName: "PlanDate",
      field: "planDate",
      sortable: true,
      resizable: true,
      width: 165,
      editable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      valueFormatter: function (params) {
        if (params.value) {
          return moment(params.value)
            .toISOString()
            .replace(/T.*/, "")
            .split("-")
            .reverse()
            .join("/");
        }
        return null;
      },
      cellEditor: "datePicker",
    },
    {
      headerName: "IsDisabled",
      field: "isDisabled",
      sortable: true,
      resizable: true,
      width: 100,
      editable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      cellRenderer: function (params) {
        var input = document.createElement("input");
        input.type = "checkbox";

        input.checked = params.value;
        input.addEventListener("click", function (event) {
          /* if (params.value == null) {
            params.value = false;
            params.node.data.isDisabled = false;
          } */
          params.value = !params.value;
          if (params.value) {
            for (let a = 0; a < params.node.allLeafChildren.length; a++) {
              params.node.allLeafChildren[a].data.rowUpdated = true;
              params.node.allLeafChildren[a].data.isDisabled = true;
            }
          } else {
            if (params.node.parent.data == undefined) {
              for (let a = 0; a < params.node.allLeafChildren.length; a++) {
                params.node.allLeafChildren[a].data.rowUpdated = true;
                params.node.allLeafChildren[a].data.isDisabled = false;
              }
            }
          }
          /* params.node.data.rowUpdated = true;
          params.node.data.isDisabled = params.value; */
        });
        return input;
      },
    },
    {
      headerName: "LumpSum",
      field: "lumpSum",
      sortable: true,
      resizable: true,
      width: 100,
      editable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      cellRenderer: function (params) {
        var input = document.createElement("input");
        input.type = "checkbox";

        input.checked = params.value;
        input.addEventListener("click", function (event) {
          /* if (params.value == null) {
            params.value = false;
            params.node.data.isDisabled = false;
          } */
          params.value = !params.value;
          params.data.rowUpdated = true;
          params.node.data.lumpSum = params.value;
        });
        return input;
      },
    },
    {
      headerName: "FromDate",
      field: "fromDate",
      sortable: true,
      resizable: true,
      width: 165,
      editable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      valueFormatter: function (params) {
        if (params.value) {
          return moment(params.value)
            .toISOString()
            .replace(/T.*/, "")
            .split("-")
            .reverse()
            .join("/");
        }
        return null;
      },
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      cellEditor: "datePicker",
    },
    {
      headerName: "ToDate",
      field: "toDate",
      sortable: true,
      resizable: true,
      width: 165,
      editable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      valueFormatter: function (params) {
        if (params.value) {
          return moment(params.value)
            .toISOString()
            .replace(/T.*/, "")
            .split("-")
            .reverse()
            .join("/");
        }
        return null;
      },
      cellEditor: "datePicker",
    },
    {
      headerName: "Activities",
      field: "activities",
      sortable: true,
      resizable: true,
      width: 165,
      editable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
    },
  ],
  loadStructureCounter: 0,

  getCurrentCostHeaderColDef: [
    {
      headerName: "Code",
      field: "code",
      sortable: true,
      resizable: true,
      width: 100,
      filterParams: { excelMode: "windows" },
      editable: true,
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      cellRenderer: function (params) {
        if (params.data.rowUpdated == "Duplicate") {
          let eIconGui = document.createElement("span");
          return (eIconGui.innerHTML =
            '<i class="fas fa-exclamation" style="color:red;"></i>' +
            " " +
            params.data.code);
        }
        return params.data.code;
      },
    },
    {
      headerName: "Description",
      field: "description",
      sortable: true,
      resizable: true,
      width: 200,
      editable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
    },
    {
      headerName: "Cost Header Option",
      field: "costHeaderOption",
      sortable: true,
      resizable: true,
      width: 100,
      editable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
    },
    {
      headerName: "CostModelType",
      field: "costModelType.description",
      sortable: true,
      resizable: true,
      width: 100,
      editable: function (params) {
        if (params.data.id > 0) {
          return false;
        } else {
          return true;
        }
      },
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      cellEditor: "CustomCombobox",
      cellRenderer: function (params) {
        console.log(params);
        return params;
      },
    },
    {
      headerName: "Comment",
      field: "comment",
      sortable: true,
      resizable: true,
      width: 100,
      editable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
    },
    {
      headerName: "IsActive",
      field: "isActive",
      sortable: true,
      resizable: true,
      width: 80,
      editable: false,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      cellRenderer: function (params) {
        var input = document.createElement("input");
        input.type = "checkbox";

        input.checked = params.value;
        input.addEventListener("click", function (event) {
          /* if (params.value == null) {
              params.value = false;
              params.node.data.isDisabled = false;
            } */
          params.value = !params.value;
          params.data.rowUpdated = true;
          params.node.data.isActive = params.value;
        });
        return input;
      },
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      resizable: true,
      width: 100,
      editable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
    },
  ],
  copyRowvalues: [],
};

const actions = {
  async LoadCostModelType({ dispatch }) {
    getCostModelType()
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentCostModelType = rowData.data;
          dispatch("LoadCostHeaderExtraColumn");
          state.LoadCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async LoadCostHeaderExtraColumn({ dispatch }) {
    getAllExtraColumnDetails("CostHeaderExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          state.getCurrentCostHeaderColDef =
            state.getCurrentCostHeaderColDef.slice(0, 7);
          rowData.data.forEach((y) =>
            state.getCurrentCostHeaderColDef.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 100,
              editable: true,
              cellStyle: function (params) {
                if (params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                }
                if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return moment(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.LoadCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async LoadGlobalStructureDetails({ dispatch }) {
    getAllStructureDetail()
      .then((rowData) => {
        if (rowData.succeeded) {
          state.structuredetails = rowData.data;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async LoadExtraColumnValue({ dispatch }, TabelType) {
    getAllExtraColumnDetails(TabelType + "ExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          state.getCurrentCostColDef = state.getCurrentCostColDef.slice(
            0,
            state.currentStructureRowsCount
          );

          rowData.data.forEach((y) =>
            state.getCurrentCostColDef.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 100,
              editable: true,
              cellStyle: function (params) {
                if (params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                }
                if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return moment(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.loadStructureCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async LoadStructures({ dispatch }) {
    getStructureAll()
      .then((rowData) => {
        if (rowData.succeeded) {
          state.getCurrentCostColDef = state.getCurrentCostColDef.slice(0, 15);
          rowData.data.forEach((y) =>
            state.getCurrentCostColDef.push({
              headerName: y.code,
              field: "sd_" + y.id,
              sortable: true,
              resizable: true,
              width: 100,
              editable: true,
              cellStyle: function (params) {
                if (params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellRenderer: function (params) {
                if (params.value != undefined && params.value.match(" ")) {
                  let eIconGui = document.createElement("span");
                  return (eIconGui.innerHTML =
                    '<i class="fas fa-exclamation" style="color:red;"></i>' +
                    " " +
                    params.value);
                }
                return params.value;
              },
            })
          );
          state.currentStructureRowsCount = state.getCurrentCostColDef.length;
          state.loadStructureCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async LoadCostHeader({ dispatch, commit }) {
    CostHeaderGetByModel()
      .then((rowData) => {
        if (rowData.succeeded) {
          if (state.currentCostModelType == null) {
            dispatch("LoadCostModelType");

            rowData.data.forEach(function (x) {
              if (x.extraColumnValues != null) {
                x.extraColumnValues.forEach(
                  (y) => (x["et_" + y.costHeaderExtraColumnId] = y.value)
                );
              }
            });
            state.currentExtraCostCoulmnId = 0;
          }
          commit("setcostheader", { returnedData: rowData.data });
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async LoadCostElements({ dispatch, commit }, { CostElements }) {
    let params = [CostElements.costModelType.rowType.code, CostElements.id];
    if (state.currentExtraCostCoulmnId == 0) {
      state.currentExtraCostCoulmnId = CostElements.costModelType.rowType.code;
      dispatch("LoadExtraColumnValue", CostElements.costModelType.rowType.code);
    } else if (
      state.currentExtraCostCoulmnId != CostElements.costModelType.rowType.code
    ) {
      state.currentExtraCostCoulmnId = CostElements.costModelType.rowType.code;
      dispatch("LoadExtraColumnValue", CostElements.costModelType.rowType.code);
    } else if (
      state.getCurrentCostColDef.find((x) => x.field.match("et_")) == undefined
    ) {
      state.currentExtraCostCoulmnId = CostElements.costModelType.rowType.code;
      dispatch("LoadExtraColumnValue", CostElements.costModelType.rowType.code);
    }
    GetCostElements({ CostElements: params })
      .then((rowData) => {
        if (rowData.succeeded) {
          rowData.data.forEach(function (x) {
            if (x.structureDetails.length > 0) {
              x.structureDetails.forEach(
                (y) => (x["sd_" + y.structureId] = y.code)
              );
            }

            if (x.activitiyDetails.length > 0) {
              x.activitiyDetails.forEach((y) => (x.activities = y.code));
            }
          });
          if (state.currentExtraCostCoulmnId == "ActualCostElement") {
            rowData.data.forEach(function (x) {
              if (x.extraColumnValues != null) {
                x.extraColumnValues.forEach(
                  (y) => (x["et_" + y.actualCostElementExtraColumnId] = y.value)
                );
              }
            });
          } else if (
            state.currentExtraCostCoulmnId == "CommitmentCostElement"
          ) {
            rowData.data.forEach(function (x) {
              if (x.extraColumnValues != null) {
                x.extraColumnValues.forEach(
                  (y) =>
                    (x["et_" + y.commitmentCostElementExtraColumnId] = y.value)
                );
              }
            });
          } else if (state.currentExtraCostCoulmnId == "ForecastCostElement") {
            rowData.data.forEach(function (x) {
              if (x.extraColumnValues != null) {
                x.extraColumnValues.forEach(
                  (y) =>
                    (x["et_" + y.forecastCostElementExtraColumnId] = y.value)
                );
              }
            });
          } else if (state.currentExtraCostCoulmnId == "PlanningCostElement") {
            rowData.data.forEach(function (x) {
              if (x.extraColumnValues != null) {
                x.extraColumnValues.forEach(
                  (y) =>
                    (x["et_" + y.planningCostElementExtraColumnId] = y.value)
                );
              }
            });
          }

          commit("setElementData", { returnedData: rowData.data });
          state.currentElementID = null;
          state.currentCostHeaderID = CostElements.id;
          state.undoElementWatcher = 0;
          state.redoElementWatcher = 0;
          state.isElementUndo = false;
          state.isElementRedo = false;
          state.duplicateRecord = 0;
          i = -2;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async DeleteCostHeader({ dispatch }, { costHeaderId }) {
    DeletecostHeaders(costHeaderId)
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentCostHeaderID = null;
          state.AddRecordCounter = 0;
          state.AddSubRecordCounter = 0;
          state.DeleteCounter = 0;
          state.isDelete = 0;
          state.isCostDelete++;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async DeleteElements({ dispatch }, { Id }) {
    DeleteElement(
      state.selectedCostHeaderObj.selectedObj.costModelType.rowType.code,
      Id
    )
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentElementID = null;
          state.AddRecordCounter = 0;
          state.AddSubRecordCounter = 0;
          state.DeleteCounter = 0;
          state.isDelete = 0;
          state.isElementDelete++;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async saveData({ dispatch, commit }) {
    var rowsToInsert = [];
    var rowsToUpdate = [];
    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {};
    state.currentData.forEach((row) => {
      if (row.rowUpdated) {
        if (row.id == -1) {
          rowsToInsert.push(row);
        } else {
          rowsToUpdate.push({
            Id: row.id,
            code: row.code,
            description: row.description,
            costHeaderOption: row.costHeaderOption,
            comment: row.comment,
            isActive: row.isActive,
            status: row.status,
            ExtraColumnValues: row.extraColumnValues,
          });
        }
      }
    });
    if (rowsToInsert.length > 0) {
      createURL = "CostHeader/Create";
      createObj = { costHeadersToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = "CostHeader/Update";
      updateObj = { CostHeadersToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values[0]) returnedValues = values[0];
          if (values[1]) returnedValues = returnedValues.concat(values[1]);
          commit("setCostHeaderSavedData", {
            returnedSavedData: returnedValues,
          });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = UpdateCostHeader(updateObj, state.selectedDataTypeId);
      Promise.all([createP, updateP])
        .then((values) => {
          var returnedValues = [];
          if (values[0]) returnedValues = values[0];
          if (values[1]) returnedValues = [values[1]];
          commit("setCostHeaderSavedData", {
            returnedSavedData: returnedValues,
          });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    //Cost, Planning, Forecast, Actual
    rowsToInsert = [];
    rowsToUpdate = [];
    createP = Promise.resolve(1);
    updateP = Promise.resolve(1);
    createURL = "";
    updateURL = "";
    createObj = {};
    updateObj = {};
    state.tempSaveData.forEach((row) => {
      if (row.code != undefined) {
        if (row.rowUpdated) {
          if (row.id <= -1) {
            row.ChildStructureDetails = row.path;
            //delete row.path;
            rowsToInsert.push(row);
          } else {
            rowsToUpdate.push({
              Id: row.id,
              code: row.code,
              description: row.description,
              parentId: row.parentId,
              Quantity: row.quantity,
              TotalQuantity: row.totalQuantity,
              Uom: row.uom,
              UnitCost: row.unitCost,
              TotalCost: row.totalCost,
              UnitHours: row.unitHours,
              TotalHours: row.totalHours,
              FromDate: row.FromDate,
              ToDate: row.ToDate,
              LumpSum: row.lumpSum,
              IsDisabled: row.isDisabled,
              objectRelationships: row.objectRelationships,
              StructureDetailsToAssign: row.structureDetails,
              ExtraColumnValues: row.extraColumnValues,
              ActivityToAssign: row.activitiyDetails,
            });
          }
        }
      } else {
        state.SaveError++;
        rowsToInsert = [];
        rowsToUpdate = [];
        return false;
      }
    });

    if (rowsToInsert.length > 0) {
      createURL =
        state.selectedCostHeaderObj.selectedObj.costModelType.rowType.code +
        "/Create";

      if (
        state.selectedCostHeaderObj.selectedObj.costModelType.rowType.code ==
        "ActualCostElement"
      )
        createObj = { ActualCostElementToCreate: rowsToInsert };
      else if (
        state.selectedCostHeaderObj.selectedObj.costModelType.rowType.code ==
        "PlanningCostElement"
      ) {
        createObj = { PlanningCostElementToCreate: rowsToInsert };
      } else if (
        state.selectedCostHeaderObj.selectedObj.costModelType.rowType.code ==
        "ForecastCostElement"
      ) {
        createObj = { ForecastCostElementToCreate: rowsToInsert };
      } else if (
        state.selectedCostHeaderObj.selectedObj.costModelType.rowType.code ==
        "CommitmentCostElement"
      ) {
        createObj = { CommitmentCostElementToCreate: rowsToInsert };
      }
    }
    if (rowsToUpdate.length > 0) {
      updateURL =
        state.selectedCostHeaderObj.selectedObj.costModelType.rowType.code +
        "/Update";

      updateObj = null;
      if (
        state.selectedCostHeaderObj.selectedObj.costModelType.rowType.code ==
        "ActualCostElement"
      )
        updateObj = { ActualCostElementsToUpdate: rowsToUpdate };
      else if (
        state.selectedCostHeaderObj.selectedObj.costModelType.rowType.code ==
        "PlanningCostElement"
      ) {
        updateObj = { PlanningCostElementsToUpdate: rowsToUpdate };
      } else if (
        state.selectedCostHeaderObj.selectedObj.costModelType.rowType.code ==
        "ForecastCostElement"
      ) {
        updateObj = { ForecastCostElementsToUpdate: rowsToUpdate };
      } else if (
        state.selectedCostHeaderObj.selectedObj.costModelType.rowType.code ==
        "CommitmentCostElement"
      ) {
        updateObj = { CommitmentCostElementsToUpdate: rowsToUpdate };
      }
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          /* if (values)
                    returnedValues = returnedValues.concat(values.data); */
          commit("setElementSavedData", { returnedSavedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = post(updateURL, updateObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = returnedValues.concat(values.data);
          commit("setElementSavedData", { returnedSavedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
    i = -1;
  },
};

const mutations = {
  setcostheader(state, { returnedData }) {
    returnedData.forEach((row) => (row.rowUpdated = false));
    state.currentData = returnedData.sort((a, b) => (a.code > b.code ? 1 : -1));
    state.undoElementWatcher = 0;
    state.redoElementWatcher = 0;
    state.isElementUndo = false;
    state.isElementRedo = false;
    state.LoadCounter++;
  },

  addCostHeaderRecord(state) {
    state.addingRecord = true;
    state.currentData.push({
      id: -1,
      code: "",
      description: "",
      rowUpdated: false,
      costHeaderOption: "",
      costModelType: [],
      costModelTypeId: null,
      extraColumnValues: [],
      objectRelationships: [],
      projects: null,
      status: null,
    });
    state.AddCounter++;
  },

  setElementValue(state, selectedId) {
    state.currentCostHeaderID = null;
    state.AddRecordCounter = 0;
    state.AddSubRecordCounter = 0;
    state.DeleteCounter = 0;
    state.isDelete = 0;
    state.currentCostHeaderID = selectedId;
  },

  setCostHeaderSavedData(state, { returnedSavedData }) {
    returnedSavedData.forEach((row) => (row.rowUpdated = false));
    state.currentData = returnedSavedData;
    state.dataWatcher += 1;
  },

  setElementSavedData(state, { returnedSavedData }) {
    returnedSavedData.forEach((row) => (row.rowUpdated = false));
    state.tempSaveData.forEach((row) => {
      row.rowUpdated = false;
      if (row.id < 0) {
        row.id = returnedSavedData.find(
          (x) => x.code == row.code && x.description == row.description
        ).id;
        row.path[row.path.length - 1] = row.id;
      }
      if (row.parentId < 0) {
        row.parentId = returnedSavedData.find(
          (x) => x.code == row.code && x.description == row.description
        ).parentId;
        if (row.path.filter((x) => x < 0).length > 1) {
          row.path = state.tempSaveData.find((x) => x.id == row.parentId).path;
          row.path.push(row.id); // row.id;
        } else {
          row.path[row.path.length - 2] = row.parentId;
          row.path[row.path.length - 1] = row.id;
        }
      }
      delete row.ChildStructureDetails;
    });
    //state.currentDetailID = state.tempSaveData[state.tempSaveData.length-1];
    state.SaveCounter = 0;
    state.dataWatcher += 1;
  },

  setElementData(state, { returnedData }) {
    if (returnedData != null) {
      state.selectedRowObj = [];
      returnedData.forEach((row) => (row.rowUpdated = false));
      data = returnedData;
      tempData = setpath(data);
      state.currentElementData = returnedData;
    }

    state.elementLoadcounter++;
    i--;
  },

  requestUndo(state) {
    state.undoWatcher += 1;
  },

  requestRedo(state) {
    state.redoWatcher += 1;
  },

  requestElementUndo(state) {
    state.undoElementWatcher += 1;
  },

  requestElementRedo(state) {
    state.redoElementWatcher += 1;
  },

  resetRequestUndo(state, Undovalue) {
    state.isUndo = Undovalue;
  },

  resetRequestRedo(state, Redovalue) {
    state.isRedo = Redovalue;
  },

  resetElementRequestUndo(state, Undovalue) {
    state.isElementUndo = Undovalue;
  },

  resetElementRequestRedo(state, Redovalue) {
    state.isElementRedo = Redovalue;
  },

  mutateDelete(state) {
    state.DeleteCounter++;
  },

  mutateElementDelete(state) {
    state.ElementDeleteCounter++;
  },

  mutateHeaderDelete(state) {
    state.HeaderDeleteCounter++;
  },

  setSelectedRowObject(state, selectedObj) {
    if (selectedObj != 0) {
      i = i - 15;
      state.selectedRowObj = selectedObj;
    } else {
      state.selectedRowObj = 0;
    }
  },

  setElementId(state, selectedId) {
    state.currentElementID = selectedId;
  },

  mutateAddRecord(state) {
    state.AddRecordCounter++;
  },

  mutateSubRecord(state) {
    state.AddSubRecordCounter++;
  },

  mutateRecord(state, { returnedNewData }) {
    state.tempSaveData = [];
    returnedNewData.forEach((element) => {
      if (element.rowUpdated) {
        state.tempSaveData.push(element);
      }
    });
    state.tempSaveData = returnedNewData;
  },

  mutateSaveCounter(state) {
    state.SaveCounter++;
  },

  setselectedCostHeaderObj(state, selectedObj) {
    state.selectedCostHeaderObj = selectedObj;
  },

  mutateCopyRecord(state) {
    const tempcopydata = [],
      tempconstData = [];

    state.selectedRowObj.forEach((row) => {
      tempcopydata.push(row);
      tempconstData.push(JSON.parse(JSON.stringify(row)));
    });

    let tempchange = null,
      tempchangechild = null,
      trackParent = false;
    let objtempParentData = tempcopydata[0].path;
    for (let y = 0; y < tempcopydata.length; y++) {
      tempcopydata[y].id = i - 25;
      tempcopydata[y].rowUpdated = true;
      if (y > 0) {
        if (tempcopydata[y].parentId == tempconstData[y - 1].parentId) {
          trackParent = false;
          tempchangechild = y - 1;
        } else {
          trackParent = true;
          tempchange = tempconstData.findIndex(
            (x) => x.id == tempcopydata[y].parentId
          );
        }
      }
      delete tempcopydata[y].path;
      if (tempcopydata[y].parentId > 0) {
        if (y == 0) {
          tempcopydata[y].parentId = state.selectedRowObj[0].parentId;
          objtempParentData[objtempParentData.length - 1] = tempcopydata[y].id;
          tempcopydata[y].path = objtempParentData;
        } else {
          if (trackParent) {
            tempcopydata[y].parentId = tempcopydata[tempchange].id;
          } else {
            tempcopydata[y].parentId = tempcopydata[tempchangechild].parentId;
          }
        }
      } else {
        tempcopydata[y].parentId = null;
      }
      i--;
    }
    state.copyRowvalues = setpath(tempcopydata);
    state.duplicateRecord++;
  },
};

const getters = {
  getCurrentData() {
    return state.currentData;
  },

  getCostHeaderColumn() {
    return state.getCurrentCostHeaderColDef;
  },

  getRedoWatcher() {
    return state.redoWatcher;
  },

  getUndoWatcher() {
    return state.undoWatcher;
  },

  getisRedo() {
    return state.isRedo;
  },

  getstructuredetailsData() {
    return state.structuredetails;
  },

  getCostHeaderId() {
    return state.currentCostHeaderID;
  },

  getisUndo() {
    return state.isUndo;
  },

  getElementData() {
    return state.currentElementData;
  },

  getElementColumn() {
    return [
      {
        headerName: "Code",
        field: "code",
        sortable: true,
        resizable: true,
        width: 100,
        filterParams: { excelMode: "windows" },
        editable: true,
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
        cellRenderer: function (params) {
          if (params.data.rowUpdated == "Duplicate") {
            let eIconGui = document.createElement("span");
            return (eIconGui.innerHTML =
              '<i class="fas fa-exclamation" style="color:red;"></i>' +
              " " +
              params.data.code);
          }
          return params.data.code;
        },
      },
      {
        headerName: "Description",
        field: "description",
        sortable: true,
        resizable: true,
        width: 300,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
      },
      {
        headerName: "Quantity",
        field: "quantity",
        sortable: true,
        resizable: true,
        width: 100,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
        valueFormatter: function (params) {
          if (params.value) {
            return params.value.toFixed(3);
          }
          return null;
        },
      },
      {
        headerName: "TotalQuantity",
        field: "totalQuantity",
        sortable: true,
        resizable: true,
        width: 100,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
        valueFormatter: function (params) {
          if (params.value) {
            return params.value.toFixed(3);
          }
          return null;
        },
      },
      {
        headerName: "TotalCost",
        field: "totalCost",
        sortable: true,
        resizable: true,
        width: 100,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
        valueFormatter: function (params) {
          if (params.value) {
            return params.value.toFixed(3);
          }
          return null;
        },
      },
      {
        headerName: "TotalHours",
        field: "totalHours",
        sortable: true,
        resizable: true,
        width: 100,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
        valueFormatter: function (params) {
          if (params.value) {
            return params.value.toFixed(3);
          }
          return null;
        },
      },
      {
        headerName: "UnitCost",
        field: "unitCost",
        sortable: true,
        resizable: true,
        width: 100,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
        valueFormatter: function (params) {
          if (params.value) {
            return params.value.toFixed(3);
          }
          return null;
        },
      },
      {
        headerName: "UnitHours",
        field: "unitHours",
        sortable: true,
        resizable: true,
        width: 100,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
        valueFormatter: function (params) {
          if (params.value) {
            return params.value.toFixed(3);
          }
          return null;
        },
      },
      {
        headerName: "UOM",
        field: "uom",
        sortable: true,
        resizable: true,
        width: 100,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
        valueFormatter: function (params) {
          if (params.value) {
            return params.value.toFixed(3);
          }
          return null;
        },
      },
      {
        headerName: "PlanDate",
        field: "planDate",
        sortable: true,
        resizable: true,
        width: 100,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
        valueFormatter: function (params) {
          if (params.value) {
            return moment(params.value).local().format("DD/MM/YYYY");
          }
          return null;
        },
      },
      {
        headerName: "IsDisabled",
        field: "isDisabled",
        sortable: true,
        resizable: true,
        width: 100,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
      },
    ];
  },

  getElemmentColDef() {
    return state.getCurrentCostColDef;
  },

  getElementIdvalue() {
    return state.currentElementID;
  },

  getSelectedRowObject() {
    return state.selectedRowObj;
  },

  getElementisRedo() {
    return state.isElementRedo;
  },

  getElementisUndo() {
    return state.isElementUndo;
  },

  getselectedCostHeaderObj() {
    return state.selectedCostHeaderObj;
  },

  getcurrentCostModelType() {
    return state.currentCostModelType;
  },
  getCopyRowvalues() {
    return state.copyRowvalues;
  },
};

export const costelementManager = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
