/* eslint-disable prettier/prettier */
<template>
  <div
    class="columns"
    style="font-size: small"
    v-if="getworkingNonWorkingSelectedObj != null"
    @change="workinModelChange()"
  >
    <div class="column">
      <div class="column is-mobile">
        <input
          type="radio"
          id="weekDays"
          value="WeekDays"
          v-model="getworkingNonWorkingSelectedObj.intervalConfig"
        />

        <span for="weekDays"> WeekDays</span>

        <!-- <label for="weekDays" v-bind:value="{ id: weekDays }">
                WeekDays</label
              > -->
        <br />
        <input
          type="radio"
          id="weekends"
          value="Weekends"
          v-model="getworkingNonWorkingSelectedObj.intervalConfig"
        />
        <span for="weekends"> Weekends</span>
        <br /><input
          type="radio"
          id="specificDays"
          value="SpecificDays"
          v-model="getworkingNonWorkingSelectedObj.intervalConfig"
        />
        <span for="specificDays"> Specific Days</span>
        <br />
      </div>
      <div class="column is-mobile" style="height: 180px">
        <select
          style="height: 100%"
          v-model="getworkingNonWorkingSelectedObj.weekdays"
          multiple
          :disabled="
            getworkingNonWorkingSelectedObj.intervalConfig != 'SpecificDays'
          "
        >
          <option>Monday</option>
          <option>Tuesday</option>
          <option>Wednesday</option>
          <option>Thursday</option>
          <option>Friday</option>
          <option>Saturday</option>
          <option>Sunday</option>
        </select>
        <br />
      </div>
    </div>
    <div class="column" style="height: 81%; margin-left: -30%">
      <div class="column group">
        <input
          type="checkbox"
          id="checkbox"
          v-model="getworkingNonWorkingSelectedObj.isWorking"
        />
        <span> IsWorking</span>
      </div>
      <div class="column group">
        <input
          type="time"
          v-model="getworkingNonWorkingSelectedObj.recurringStartDate"
        />
        <span class="highlight"></span>
        <span class="bar"></span>

        <label class="label"> Start Time </label>
      </div>
      <div class="column group">
        <input
          type="time"
          v-model="getworkingNonWorkingSelectedObj.recurringEndDate"
          autocomplete="off"
        />
        <span class="highlight"></span>
        <span class="bar"></span>

        <label class="label"> End Time </label>
      </div>
    </div>
  </div>
</template>

<script>
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import LevelIconCellRenderer from "@/components/Data/LevelIconCellRenderer.vue";
export default {
  name: "CalendarWorkingNonWorkingInterval",
  components: {
    //  AgGridVue,
    LevelIconCellRenderer, // eslint-disable-line
  },
  watch: {
    /*   getworkingNonWorkingSelectedObj() {
      this.getworkingNonWorkingSelectedObj.rowUpdated = true;
    }, */

    SaveCounter() {
      const time1 = this.getworkingNonWorkingSelectedObj.recurringStartDate;
      const time2 = this.getworkingNonWorkingSelectedObj.recurringEndDate;

      const getTime = (time) =>
        new Date(2021, 9, 2, time.substring(0, 2), time.substring(3, 5), 0, 0);

      const result = getTime(time1) < getTime(time2);
      var temp = "";

      if (result && this.getworkingNonWorkingSelectedObj.intervalConfig) {
        if (this.getworkingNonWorkingSelectedObj.intervalConfig == "WeekDays") {
          let t1 = time1.split(":");
          let t2 = time2.split(":");
          this.getworkingNonWorkingSelectedObj.weekdays = [];
          this.getworkingNonWorkingSelectedObj.tempFrom =
            t1[1] + " " + t1[0] + " * * 1-5";

          this.getworkingNonWorkingSelectedObj.tempTo =
            t2[1] + " " + t2[0] + " * * 1-5";
        } else if (
          this.getworkingNonWorkingSelectedObj.intervalConfig == "Weekends"
        ) {
          let t1 = time1.split(":");
          let t2 = time2.split(":");
          this.getworkingNonWorkingSelectedObj.weekdays = [];
          this.getworkingNonWorkingSelectedObj.tempFrom =
            t1[1] + " " + t1[0] + " * * 6-7";

          this.getworkingNonWorkingSelectedObj.tempTo =
            t2[1] + " " + t2[0] + " * * 6-7";
        } else {
          //console.log(this.getworkingNonWorkingSelectedObj);
          let t1 = time1.split(":");
          let t2 = time2.split(":");

          if (this.getworkingNonWorkingSelectedObj.weekdays.length == 7) {
            temp = "*";
          } else {
            for (
              let a = 0;
              a < this.getworkingNonWorkingSelectedObj.weekdays.length;
              a++
            ) {
              temp +=
                this.days.findIndex(
                  (x) => x == this.getworkingNonWorkingSelectedObj.weekdays[a]
                ) + ",";
            }
          }
          //console.log(this.getworkingNonWorkingSelectedObj);
          this.getworkingNonWorkingSelectedObj.tempFrom =
            t1[1] + " " + t1[0] + " * * " + temp;

          this.getworkingNonWorkingSelectedObj.tempTo =
            t2[1] + " " + t2[0] + " * * " + temp;
        }
        this.getworkingNonWorkingSelectedObj.rowUpdated = true;
        // console.log(this.getworkingNonWorkingSelectedObj.tempTo);
        this.mutateParentSave();
      } else {
        this.$vToastify.error("From Time cannot be greater than End Time");
      }
    },
  },
  computed: {
    ...mapGetters("calendarController", ["getworkingNonWorkingSelectedObj"]),
    ...mapState({
      SaveCounter: (state) =>
        state.calendarController.updatecalendarControllerCounter,
    }),
  },
  data() {
    return {
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    };
  },
  methods: {
    ...mapMutations("calendarController", [
      "mutateCalendarController",
      "mutateParentSave",
    ]),
    workinModelChange() {
      this.getworkingNonWorkingSelectedObj.rowUpdated = true;
    },
  },

  mounted() {
    this.$vToastify.setSettings(this.NormalSettings);
  },
};
</script>

<style scoped lang="scss">
#global-filter-section-content {
  height: 25vw;
  padding-top: 0px;
}
.danger {
  color: #f44336;
}

input[type="time"]:in-range::-webkit-datetime-edit-text {
  color: transparent;
}

input[type="time"]:disabled {
  color: transparent;
}
input[type="time"]:focus::-webkit-datetime-edit {
  color: black !important;
}

.title {
  font-family: Verdana;
  font-size: 1.4vw;
}
.formulate-input {
  .formulate-input-label {
    font-weight: bold;
  }
}
/* form starting stylings ------------------------------- */
.group {
  position: relative;
  margin-bottom: 15px;
}

input[type="time"] {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  color: #000;
}

input[type="time"]:focus {
  outline: none;
}

h1 {
  font-family: "Trocchi", serif;
  font-weight: normal;
  line-height: 48px;
  margin: 0;
}
/* LABEL ======================================= */
label {
  color: #999;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 7px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input[type="time"]:focus ~ label,
input[type="time"]:valid ~ label {
  top: -10px;
  font-size: 14px;
  color: black;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 300px;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

/* active state */
input[type="time"]:focus ~ .bar:before,
input[type="time"]:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input[type="time"]:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
::-webkit-input-placeholder {
  color: #999;
  font-weight: bold;
}
/* Red */
</style>
