<template>
  <div
    class="ag-header-cell ag-header-cell-sortable ag-focus-managed"
    role="columnheader"
    tabindex="-1"
    col-id="DocCode"
    aria-sort="none"
    aria-colindex="1"
    style="width: 200px; top: 0px; height: 32px; left: 0px"
  >
    <div ref="eResize" class="ag-header-cell-resize" role="presentation"></div>
    <div
      role="presentation"
      class="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all"
      aria-hidden="true"
    >
      <div
        ref="eLabel"
        class="ag-input-field-label ag-label ag-hidden ag-checkbox-label"
        aria-hidden="true"
        role="presentation"
      ></div>

      <input
        type="checkbox"
        name="checkAll"
        v-model="headerChecked"
        @change="checkAllClicked($event)"
      />
    </div>
    <div
      ref="eHeaderCompWrapper"
      class="ag-header-cell-comp-wrapper"
      role="presentation"
    >
      <div class="ag-cell-label-container" role="presentation">
        <span
          ref="eMenu"
          class="ag-header-icon ag-header-cell-menu-button"
          aria-hidden="true"
          ><span
            class="ag-icon ag-icon-menu"
            unselectable="on"
            role="presentation"
          ></span
        ></span>
        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
          <span ref="eText" class="ag-header-cell-text">{{
            params.displayName
          }}</span>
          <span
            ref="eFilter"
            class="ag-header-icon ag-header-label-icon ag-filter-icon ag-hidden"
            aria-hidden="true"
            ><span
              class="ag-icon ag-icon-filter"
              unselectable="on"
              role="presentation"
            ></span
          ></span>
          <!--AG-SORT-INDICATOR--><span
            class="ag-sort-indicator-container"
            ref="eSortIndicator"
          >
            <span
              ref="eSortOrder"
              class="ag-sort-indicator-icon ag-sort-order ag-hidden"
              aria-hidden="true"
            ></span>
            <span
              ref="eSortAsc"
              class="ag-sort-indicator-icon ag-sort-ascending-icon ag-hidden"
              aria-hidden="true"
              ><span
                class="ag-icon ag-icon-asc"
                unselectable="on"
                role="presentation"
              ></span
            ></span>
            <span
              ref="eSortDesc"
              class="ag-sort-indicator-icon ag-sort-descending-icon ag-hidden"
              aria-hidden="true"
              ><span
                class="ag-icon ag-icon-desc"
                unselectable="on"
                role="presentation"
              ></span
            ></span>
            <span
              ref="eSortMixed"
              class="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden"
              aria-hidden="true"
              ><span
                class="ag-icon ag-icon-none"
                unselectable="on"
                role="presentation"
              ></span
            ></span>
            <span
              ref="eSortNone"
              class="ag-sort-indicator-icon ag-sort-none-icon ag-hidden"
              aria-hidden="true"
              ><span
                class="ag-icon ag-icon-none"
                unselectable="on"
                role="presentation"
              ></span
            ></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      columnToUpdate: "",
      headerChecked: false,
    };
  },
  beforeMount() {},
  mounted() {
    this.columnToUpdate = this.params.column.colId;
    console.log("customHeaderMountedParams", this.params);
    // this.params.columnGroup
    //   .getProvidedColumnGroup()
    //   .addEventListener("expandedChanged", this.syncExpandButtons.bind(this));
    // this.syncExpandButtons();
  },
  methods: {
    checkAllClicked(eventParams) {
      this.params.context.componentParent.setAll(
        this.columnToUpdate,
        this.headerChecked
      );
      //console.log("headerChecked", this.headerChecked);
      //console.log("checkAllClicked", eventParams);
      //console.log("this.params.context", this.params.context);
      // let currentState = this.params.columnGroup
      //   .getProvidedColumnGroup()
      //   .isExpanded();
      // this.params.setExpanded(!currentState);
    },
    // syncExpandButtons() {
    //   this.groupExpanded = this.params.columnGroup
    //     .getProvidedColumnGroup()
    //     .isExpanded();
    // },
  },
};
</script>
