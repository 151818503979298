function expandSubMenu(event) {
  let parent = event.srcElement.parentElement;
  while (parent.nodeName != "UL") {
    if (parent.nodeName == "DIV") {
      // we have clicked border or something
      return;
    }
    parent = parent.parentElement;
  }
  if (!parent) {
    // somethign wrong happened
    return;
  } else if (!parent.getAttribute("aria-expanded")) {
    parent.setAttribute("aria-expanded", "true");
  } else if (parent.getAttribute("aria-expanded") == "true") {
    parent.setAttribute("aria-expanded", "false");
  } else if (parent.getAttribute("aria-expanded") == "false") {
    parent.setAttribute("aria-expanded", "true");
  }
}

export { expandSubMenu };

/***
 * 

    <ul class="is-active" @click.capture.prevent="expandSubMenu">
      <li><i class="fas fa-user"></i><h1>Users</h1></li>
      <ul> <!-- submenu -->
        <li><h1>Users</h1></li>
        <li>Active Users</li>
        <li>Deleted Users</li>
      </ul>
    </ul>

    <ul @click.capture.prevent="expandSubMenu">
      <li><i class="fas fa-filter"></i><h1>P3 Management</h1></li>
      <ul> <!-- submenu -->
        <li><h1>P3 Management</h1></li>
        <li>View 1</li>
        <li class="is-active">View 2</li>
        <li>View 3</li>
        <li>View 4</li>
      </ul>
    </ul>
 * 
 * 
 */
