<template>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-if="selectedMasterDataType.id == 0"
  >
    <structure-manager />
  </section>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedMasterDataType.id == 1"
  >
    <spending-curve-manager />
  </section>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedMasterDataType.id == 2"
  >
    <cost-element-extra-column-manager />
  </section>

  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedMasterDataType.id == 3"
  >
    <schedule-resources-manager />
  </section>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedMasterDataType.id == 4"
  >
    <calendar-admin-manager />
  </section>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedMasterDataType.id == 5"
  >
    <PerformanceMetricManager />
  </section>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedMasterDataType.id == 6"
  >
    <PerformanceStatusManager />
  </section>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedMasterDataType.id == 7"
  >
    <RiskSeverityManager />
  </section>
</template>

<script>
import SpendingCurveManager from "../components/SpendingCurve/SpendingCurveManger.vue";
import StructureManager from "../components/Structure/StructureManager.vue";
import CostElementExtraColumnManager from "../components/CostElementExtraColumn/CostElementExtraColumnManger.vue";
import ScheduleResourcesManager from "../components/ScheduleResources/ScheduleResourcesManager.vue";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import LoadScript from "vue-plugin-load-script";
import CalendarAdminManager from "@/components/CalendarAdmin/CalendarAdminManager.vue";

import PerformanceMetricManager from "../components/PerformanceMetrics/PerformanceMetricsComponent.vue";
import PerformanceStatusManager from "../components/PerformanceStatus/PerformanceStatusComponent.vue";
import RiskSeverityManager from "../components/RiskSeverity/RiskSeverityComponent.vue";
export default {
  name: "MasterNewDataView",
  components: {
    SpendingCurveManager,
    StructureManager,
    CostElementExtraColumnManager,
    ScheduleResourcesManager,
    CalendarAdminManager,

    PerformanceMetricManager,
    PerformanceStatusManager,
    RiskSeverityManager,
  },
  watch: {},
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("structureManager", ["selectedMasterDataType"]),
  },
  mounted: function () {
    console.log("this.selectedMasterDataType", this.selectedMasterDataType);
    if (
      !this.isValid(this.selectedMasterDataType) ||
      this.selectedMasterDataType.name != this.$route.params.id
    ) {
      console.log("Loading Master Structure Data Type");
      this.setSelectedData(this.$route.params.id);
    }
    Vue.use(LoadScript);
    var scriptToUse =
      process.env.NODE_ENV === "development"
        ? "http://localhost:8080/drag-bar.js"
        : process.env.NODE_ENV === "Staging"
        ? "https://jiveconnect-staging.azurewebsites.net/drag-bar.js"
        : "https://app.jiveconnect.com.au/drag-bar.js";

    Vue.unloadScript(scriptToUse).catch((errormsg) => {
      if (errormsg != undefined) {
        console.error(errormsg);
      }
    });

    Vue.loadScript(scriptToUse).catch((error) => {
      console.error(error);
    });
  },
  methods: {
    ...mapActions("structureManager", ["setSelectedData"]),
    isValid(valueToCheck) {
      if (
        String(valueToCheck) != "" &&
        String(valueToCheck) != "null" &&
        String(valueToCheck) != "undefined"
      )
        return true;
      else {
        return false;
      }
    },
  },
  data() {
    return {};
  },
};
</script>
