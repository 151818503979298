import { userService } from "@/api/user-auth";
import Vue from "vue";

const state = {
  entitiesLoaded: false,
  localAllEntities: [],
  selectedEntitites: [],
  selectedDataType: null,
  selectedIntegrateViewId: -1,

  childEntitiesLoaded: false,
  selectedChildEntitites: [],
  localAllChildEntities: [],
  selectedChildDataType: null,

  allDataTypes: [
    {
      id: "0",
      name: "ProcessSetInstance",
      displayName: "Process Set",
      iconClass: "fas fa-folder-open",
      apiPrefix: "Processset",
      getAllApiSuffix: "GetAllProcessSetHistory",
    },
    {
      id: "1",
      name: "ProcessSetAdmin",
      displayName: "Process Set Admin",
      iconClass: "fas fa-folder-open",
      apiPrefix: "Processset",
      getAllApiSuffix: "GetAll",
    },
  ],
};

const actions = {
  setSelectedIntegrateDataType({ commit }, dataId) {
    console.log("dataId", dataId);
    var selectedMDT = this.allDataTypes.find((x) => x.name == dataId);
    // console.log("selectedMDT", selectedMDT);
    commit("mutateSelectedIntegrateDataType", {
      selectedIntegrateViewId: selectedMDT.id,
    });
  },

  async setSelectedData({ commit }, selectedDataTypeName) {
    //console.log("UserDataTypeId: selectedDataTypeName", selectedDataTypeName);
    var currDT = state.allDataTypes.find((x) => x.name == selectedDataTypeName);
    console.log("selectedDT", currDT);
    commit("mutateSelectedIntegrateDataType", {
      selectedIntegrateViewId: currDT.id,
    });
    console.log("Post", state.selectedIntegrateViewId);
  },

  setSelectedEntity({ commit }, id) {
    state.selectedEntitites = [];
    state.selectedEntitites.push(
      state.localAllEntities.find((x) => x.id == id)
    );
  },
  setSelectedChildEntity({ commit }, id) {
    state.selectedChildEntitites = [];
    state.selectedChildEntitites.push(
      state.localAllChildEntities.find((x) => x.id == id)
    );
  },
  setChildDataType({ commit }, childId) {
    state.selectedChildDataType = state.allDataTypes.find(
      (x) => x.id === childId
    );
  },
  loadAllData({ commit, dispatch }) {
    if (!isValid(state.selectedDataType))
      state.selectedDataType = {
        id: "ProcessSets",
        name: "Process Sets",
        iconClass: "fas fa-folder-open",
        apiPrefix: "Processset",
        getAllApiSuffix: "GetAll",
      };
    state.entitiesLoaded = false;
    userService
      .getJson(
        state.selectedDataType.apiPrefix +
          `/` +
          state.selectedDataType.getAllApiSuffix
      )
      .then((result) => {
        if (result.succeeded && result.data != null) {
          commit("mutateAllEntities", result.data);
          state.entitiesLoaded = true;
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
  loadChildData({ commit, dispatch }) {
    if (state.selectedEntitites.length > 0) {
      console.log("Loading Children");
      if (!isValid(state.selectedChildDataType))
        state.selectedChildDataType = {
          id: "ProcessSetInstance",
          name: "Processes",
          iconClass: "fas fa-folder-open",
          apiPrefix: "Processset",
          getAllApiSuffix: "GetAllProcessSetHistory",
        };
      state.childEntitiesLoaded = false;
      userService
        .getJson(
          state.selectedChildDataType.apiPrefix +
            `/` +
            state.selectedChildDataType.getAllApiSuffix +
            `/` +
            state.selectedEntitites[0].id
        )
        .then((result) => {
          console.log("2");
          if (result.succeeded && result.data != null) {
            console.log(result.data);
            commit("mutateAllChildEntities", result.data);
            state.childEntitiesLoaded = true;
          } else {
            console.log("Fail");
            console.log(result.data);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
  },
  retryProcesssetInstance({ commit }, id) {
    userService
      .post(state.selectedDataType.apiPrefix + `/RetryProcessSetInstance/` + id)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          this._vm.$vToastify.success(result.data);
          commit.dispatch.loadChildData();
        }
      })
      .catch((error) => {
        //dispatch("alert/error", error, { root: true });
      });
  },
  executeProcessSet({ commit }, incomingData) {
    userService
      .post(state.selectedDataType.apiPrefix + `/Execute`, incomingData)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          this._vm.$vToastify.success(result.data);
          commit.dispatch.loadChildData();
        }
      })
      .catch((error) => {
        //dispatch("alert/error", error, { root: true });
      });
  },
};
function isValid(valueToCheck) {
  if (
    String(valueToCheck) != "" &&
    String(valueToCheck) != "null" &&
    String(valueToCheck) != "undefined"
  )
    return true;
  else {
    return false;
  }
}

const mutations = {
  mutateSchedulingDataType(state, selectedDataTypeCode) {
    state.selectedDataType = state.allDataTypes.find(
      (x) => x.id === selectedDataTypeCode
    );
  },
  mutateAllEntities(state, result) {
    state.localAllEntities = result;
  },
  mutateAllChildEntities(state, result) {
    state.localAllChildEntities = result;
  },
  mutateSelectedIntegrateDataType(state, { selectedIntegrateViewId }) {
    state.selectedIntegrateViewId = selectedIntegrateViewId;
  },
};

const getters = {
  getAllDataTypes(state) {
    return state.allDataTypes;
  },
  allEntities(state) {
    return state.localAllEntities;
  },
  allChildEntities(state) {
    return state.localAllChildEntities;
  },
  entitiesLoaded(state) {
    return state.entitiesLoaded;
  },
  childEntitiesLoaded(state) {
    return state.childEntitiesLoaded;
  },
  selectedScheduleDataType(state) {
    return state.selectedDataType;
  },
  allWizardProcessSets(state) {
    return state.localAllEntities.filter((x) => x.isWizard == true);
  },
  selectedIntegrateDataType({ selectedIntegrateViewId, allDataTypes }) {
    if (selectedIntegrateViewId == null) selectedIntegrateViewId = 0;
    return allDataTypes.find((dt) => dt.id == selectedIntegrateViewId);
  },
};

export const processSetDataManager = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
