import { userService } from "@/api/user-auth.js";

import { post } from "@/api/networking.js";

const state = {
  image: "",
  clientLogoPath: "",
  clientLogoFile: {},
  currentUsers: [],
  passwordCounter: 0,
  resetError: 0,
};

const actions = {
  // loadClientAdmin({ commit, rootGetters }) {
  //   getFile("sadasd").catch();
  // },

  loadUsers({ dispatch, commit, rootGetters }) {
    userService
      .getJson("Account/GetAllUsers")
      .then((result) => {
        if (result.succeeded && result.data != null) {
          let tmpCurrentUsers = result.data;
          commit("mutateUsers", {
            userList: tmpCurrentUsers,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async saveClient({ commit, rootGetters }) {
    var formData = new FormData();
    formData.append("clientLogo", state.clientLogoFile);
    post("client/UpdateSetting", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  async resetPassword({ commit }, AccountRestPassword) {
    userService
      .post("Account/resetPassword", AccountRestPassword)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          state.passwordCounter++;
        } else {
          state.resetError++;
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async connectToCX({ commit }, AccountRestPassword) {
    userService
      .post("Account/ConnectToCX", AccountRestPassword)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          state.passwordCounter++;
        } else {
          state.resetError++;
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
};

const getters = {
  getClientLogo({ currentData }) {
    return state.clientLogoPath.name;
  },
  getUsers() {
    return state.currentUsers;
  },
};

const mutations = {
  setClientLogoPath(state, file) {
    state.clientLogoFile = file;
    state.clientLogoPath = file.name;
  },
  mutateUsers(state, userList) {
    state.currentUsers = userList;
  },
};

export const clientAdmin = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
