import { getAllExtraColumnDetails } from "@/api/networking.js";
import { post } from "@/api/user-auth.js";
import { date } from "@breejs/later";
import * as moment from "moment";
const later = require("@breejs/later");
const tempDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const state = {
  /* Module Title*/

  APIName: "Calendar",
  /*left Side(parent grid details)*/
  /*CurrentData and details*/
  currentParentData: [],
  currentParentId: null,
  currentParentSelectedObj: [],

  /*Main Data Confing Tools*/
  currentParentLoadRecordCounter: 0,
  currentParentAddRecordCounter: 0,
  currentParentDeleteRecordCounter: 0,
  currentParentSaveRecordCounter: 0,
  currentParentSelctedCounter: 0,

  /*Parent Node Grid Config*/
  currentParentUndoWatcher: 0,
  currentParentRedoWatcher: 0,
  currentParentredostate: false,
  currentParentundostate: false,

  /*Commonn Config*/
  isSave: 0,
  isDelete: 0,
  datawatcher: 0,
  SaveError: 0,
  returnSavedData: null,
  /* Parent Column Def */
  currentParentColDef: [],
  currentParentExtaColumnLoadCounter: 0,

  /* Exception Column Def */
  currentExceptionColDef: [],

  currentExceptionId: null,
  currentExceptionSelectedObj: null,
  currentExceptionAddRecordCounter: 0,
  currentExceptionDeleteRecordCounter: 0,
  currentExceptionSaveRecordCounter: 0,

  currentExceptionUndoWatcher: 0,
  currentExceptionRedoWatcher: 0,
  currentExceptionredostate: false,
  currentExceptionundostate: false,
  currentExceptionData: [],

  /* TimeInterval Column Def */
  currentWorkingNonWorkingColDef: [],

  currentworkingNonWorkingId: null,
  currentworkingNonWorkingSelectedObj: null,
  currentworkingNonWorkingAddRecordCounter: 0,
  currentworkingNonWorkingDeleteRecordCounter: 0,
  currentworkingNonWorkingSaveRecordCounter: 0,

  currentworkingNonWorkingUndoWatcher: 0,
  currentworkingNonWorkingRedoWatcher: 0,
  currentworkingNonWorkingredostate: false,
  currentworkingNonWorkingundostate: false,
  /**set From and To Interval */
  isnonWorkingOpen: false,
  isnonWorkingModalVisible: false,

  currentworkingNonWorkingData: [],

  /** Get Col Def for Working and NonWorking popup */

  currentworkingNonWorkingPopUpData: [],

  currentworkingNonWorkingPopUpId: null,
  currentworkingNonWorkingPopUpSelectedObj: null,
  updatecalendarControllerCounter: 0,
  currentParentColumDefCounter: 0,
  currentParentColDefs: [],
};

const actions = {
  /*Extra Column */
  async LoadCalendarExtraColumn({ dispatch }) {
    state.currentParentColDefs = [];
    getAllExtraColumnDetails("CalendarExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          rowData.data.forEach((y) =>
            state.currentParentColDefs.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 120,
              editable: true,
              filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: "OR",
              },
              filter: "agSetColumnFilter",
              cellStyle: function (params) {
                if (params.data != undefined && params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                } else if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return new Date(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.currentParentColumDefCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, {
          root: true,
        });
      });
  },

  /*Calendar Data */
  async LoadParentData({ dispatch, commit }) {
    getAllExtraColumnDetails("Calendar")
      .then((rowData) => {
        if (rowData.succeeded) {
          commit("setParentData", {
            returnedData: rowData.data,
          });
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, {
          root: true,
        });
      });
  },

  /*Delete Calendar */
  async DeleteParentNode({ dispatch }, { Id, intervals, flag: flag }) {
    post(state.APIName + "/Delete/", {
      CalendarsToDelete: { Ids: [Id], IntervalIDs: [intervals] },
    })
      .then((rowData) => {
        if (rowData.succeeded) {
          if (intervals > 0) {
            let tempPIndex = state.currentParentData.findIndex(
              (x) => x.id == Id
            );
            let tempCIndex = state.currentParentData[
              tempPIndex
            ].intervals.findIndex((x) => x.id == intervals);

            if (flag == "Exception") {
              let tempNIndex = state.currentExceptionData.findIndex(
                (x) => x.id == intervals
              );
              delete state.currentExceptionData[tempNIndex];
              state.currentExceptionId = [];
              state.currentExceptionSelectedObj = [];
            } else if (flag == "WorkInterval") {
              let tempNIndex = state.currentworkingNonWorkingData.findIndex(
                (x) => x.id == intervals
              );
              delete state.currentworkingNonWorkingData[tempNIndex];
            }
            state.currentworkingNonWorkingId = [];
            state.currentworkingNonWorkingSelectedObj = [];
            delete state.currentParentData[tempPIndex].intervals[tempCIndex];
          } else {
            let tempindex = state.currentParentData.findIndex(
              (x) => x.id == Id
            );
            delete state.currentParentData[tempindex];

            state.currentDetailData = state.currentParentData.filter(Boolean);
            state.currentParentId = null;
            state.currentParentSelectedObj = [];
            state.currentParentId = null;
          }
          state.isDelete++;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /*Save Calendar */
  async saveData({ dispatch, commit }) {
    var rowsToInsert = [],
      rowsToUpdate = [];

    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {},
      isSave = true;

    /* Set Exception Data*/
    state.currentParentData.forEach((row) => {
      row.intervals.forEach((element) => {
        let tempElement = { ...element };
        if (element.rowUpdated) {
          if (element.id < 0) delete element.id;
          if (!row.rowUpdated) row.rowUpdated = true;
        }

        if (element.recurringStartDate != undefined) {
          [element.recurringStartDate, element.tempFrom] = [
            element.tempFrom,
            element.recurringStartDate,
          ];
          //element.recurringStartDate = element.tempFrom;
          [element.recurringEndDate, element.tempTo] = [
            element.tempTo,
            element.recurringEndDate,
          ];
          //element.recurringEndDate = element.tempTo;
        } else if (
          new Date(element.endDate) == "Invalid Date" ||
          new Date(element.startDate) == "Invalid Date"
        ) {
          state.SaveError++;
          isSave = false;
        }

        /* element.recurringStartDate = tempElement.recurringStartDate;
        element.recurringEndDate = tempElement.recurringStartDate;
        element.tempFrom = tempElement.tempFrom;
        element.tempTo = tempElement.tempTo; */
        //    element = tempElement;
      });
    });

    state.currentParentData.forEach((row) => {
      if (row.rowUpdated) {
        if (row.id == -1) {
          //  row.Intervals = rowsExceptionToInsert.concat(rowsWorkingToInsert);
          rowsToInsert.push(row);
        } else {
          rowsToUpdate.push({
            id: row.id,
            code: row.code,
            description: row.description,
            hoursPerDay: row.hoursPerDay,
            daysPerMonth: row.daysPerMonth,
            daysPerWeek: row.daysPerWeek,
            defaultIsWorking: row.defaultIsWorking,
            intervals: row.intervals,
            ExtraColumnValues: row.extraColumnValues,
          });
        }
      }
    });

    if (rowsToInsert.length > 0) {
      createURL = state.APIName + "/Create";
      createObj = { CalendarsToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = state.APIName + "/Update";
      updateObj = { CalendarsToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values[0]) returnedValues = values[0];
          if (values[1]) returnedValues = returnedValues.concat(values[1]);
          commit("setSavedParentData", {
            returnedData: returnedValues,
          });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = post(updateURL, updateObj); //UpdateCalendar(updateObj, "calendar");
      Promise.all([createP, updateP])
        .then((values) => {
          var returnedValues = [];
          if (values[0]) returnedValues = values[0];
          if (values[1]) returnedValues = values[1].data;
          commit("setSavedParentData", {
            returnedData: returnedValues,
          });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
  },
};

const mutations = {
  /*left Side(parent grid details)*/
  /*CurrentData and details*/

  setParentData(state, { returnedData }) {
    let tempA = null;
    returnedData.forEach(function (row) {
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.calendarExtraColumnId] = y.value)
        );
      }
      row.intervals.forEach(function (element) {
        if (
          element.recurringStartDate != null &&
          element.recurringEndDate != null
        ) {
          element.tempFrom = element.recurringStartDate;
          element.tempTo = element.recurringEndDate;
          tempA = element.tempFrom.split(" ");
          element.recurringStartDate = tempA[1] + ":" + tempA[0];
          tempA = element.tempTo.split(" ");
          element.recurringEndDate = tempA[1] + ":" + tempA[0];
          if (tempA[4] == "1-5") {
            element.intervalConfig = "WeekDays";
          } else if (tempA[4] == "6-7") {
            element.intervalConfig = "Weekends";
          } else {
            element.intervalConfig = "SpecificDays";
            if (tempA[4] == "*") {
              element.weekdays = tempDays;
            } else {
              element.weekdays = [];
              tempA = element.tempFrom.split(" ");
              tempA = tempA[4].split(",");
              tempA.forEach((x) => {
                if (x != "") element.weekdays.push(tempDays[x]);
              });
            }
          }
        }
      });
    });
    state.currentParentData = returnedData.sort((a, b) =>
      a.code > b.code ? 1 : -1
    );
    /*Reset the parent Grid property */
    state.currentParentId = null;
    state.currentParentSelectedObj = [];
    state.currentParentDeleteRecordCounter = 0;
    state.currentParentUndoWatcher = 0;
    state.currentParentRedoWatcher = 0;
    state.currentParentredostate = false;
    state.currentParentundostate = false;
    state.currentParentAddRecordCounter = 0;
    state.currentParentLoadRecordCounter++;
  },

  setSavedParentData(state, { returnedData }) {
    // returnedData.forEach(row => (row.rowUpdated = false));
    let tempA = null;

    state.currentParentData.forEach((row) => {
      row.intervals.forEach((element) => {
        row.rowUpdated = false;
        if (element.recurringStartDate != undefined) {
          /*   [element.recurringStartDate, element.tempFrom] = [
            element.tempFrom,
            element.recurringStartDate,
          ]; */

          [element.tempFrom, element.recurringStartDate] = [
            element.recurringStartDate,
            element.tempFrom,
          ];
          //element.recurringStartDate = element.tempFrom;
          [element.tempTo, element.recurringEndDate] = [
            element.recurringEndDate,
            element.tempTo,
          ];
          //element.recurringEndDate = element.tempTo;
        }
      });
    });

    // state.currentExceptionData = [];
    //  state.currentworkingNonWorkingData = [];
    returnedData.forEach(function (row) {
      row.rowUpdated = false;
      row.intervals.forEach(function (element) {
        if (
          element.recurringStartDate != null &&
          element.recurringEndDate != null
        ) {
          element.tempFrom = element.recurringStartDate;
          element.tempTo = element.recurringEndDate;
          tempA = element.tempFrom.split(" ");
          element.weekdays = [];
          element.recurringStartDate = tempA[1] + ":" + tempA[0];
          tempA = element.tempTo.split(" ");
          element.recurringEndDate = tempA[1] + ":" + tempA[0];
          if (tempA[4] == "1-5") {
            element.intervalConfig = "WeekDays";
          } else if (tempA[4] == "6-7") {
            element.intervalConfig = "Weekends";
          } else {
            element.intervalConfig = "SpecificDays";
            if (tempA[4] == "*") {
              element.weekdays = tempDays;
            } else {
              element.weekdays = [];
              tempA = element.tempFrom.split(" ");
              tempA = tempA[4].split(",");
              tempA.forEach((x) => {
                if (x != "") element.weekdays.push(tempDays[x]);
              });
            }
          }
        }
      });

      tempA = state.currentParentData.findIndex((x) => x.id == row.id);
      if (tempA >= 0) state.currentParentData[tempA].intervals = row.intervals;
    });

    returnedData.forEach(function (row) {
      row.intervals.forEach((element) => {
        element.rowUpdated = false;
        /*  if (element.startDate != null) {
          state.currentExceptionData.push(element);
        } else {
          if (element.weekdays == undefined) element.weekdays = [];
          state.currentworkingNonWorkingData.push(element);
        } */
      });
    });
    state.returnSavedData = returnedData;

    //state.currentParentData = returnedData;
    /*Reset the parent Grid property */

    // state.currentExceptionData = [];
    // state.currentExceptionSelectedObj = null;
    // state.currentExceptionId = null;
    // state.currentworkingNonWorkingData = [];
    //state.currentworkingNonWorkingSelectedObj = null;
    // state.currentworkingNonWorkingId = null;
    state.currentworkingNonWorkingSelectedObj = null;
    state.currentParentUndoWatcher = 0;
    state.currentParentRedoWatcher = 0;
    state.currentParentredostate = false;
    state.currentParentundostate = false;
    state.datawatcher++;
  },

  setParentObj(state, { row }) {
    row.intervals = row.intervals.filter(function (value, index, arr) {
      return value.id > 0;
    });
    state.currentParentSelectedObj = row;
    state.currentParentId = row.id;
    state.currentExceptionData = [];
    state.currentExceptionSelectedObj = null;
    state.currentExceptionId = null;
    state.currentworkingNonWorkingData = [];
    state.currentworkingNonWorkingSelectedObj = null;
    state.currentworkingNonWorkingId = null;
    //state.cuurent;

    row.intervals.forEach((element) => {
      element.rowUpdated = false;
      if (element.startDate != null) {
        state.currentExceptionData.push(element);
      } else {
        if (element.weekdays == undefined) element.weekdays = [];
        state.currentworkingNonWorkingData.push(element);
      }
    });
    state.currentParentSelctedCounter++;
  },

  /*mutate counter for CRUD*/
  muatateParentAdd(state) {
    state.currentParentData.push({
      id: -1,
      code: "",
      description: "",
      hoursPerDay: null,
      daysPerMonth: "",
      daysPerWeek: "",
      defaultIsWorking: false,
      intervals: [],
      rowUpdated: true,
    });
    state.currentParentAddRecordCounter++;
  },

  mutateParentDelete(state) {
    state.currentParentDeleteRecordCounter++;
  },

  mutateParentSave(state) {
    state.currentParentSaveRecordCounter++;
  },

  /*Parent Grid Config*/
  mutateParentRedo(state) {
    state.currentParentRedoWatcher++;
  },

  mutateParentUndo(state) {
    state.currentParentUndoWatcher++;
  },

  mutateParentRedostate(state, value) {
    state.currentParentredostate = value;
  },

  mutateParentUndostate(state, value) {
    state.currentParentundostate = value;
  },

  mutateCalendarRealtionship(state, isRelationship) {
    state.isRelationship = isRelationship;
  },

  /*Exception*/
  muatateExceptionAdd(state) {
    state.currentExceptionData.push({
      id: -1,
      code: "",
      description: "",
      startDate: "",
      endDate: "",
      // isWorking: false,
      rowUpdated: true,
    });
    /*  console.log(
      state.currentExceptionData[state.currentExceptionData.length - 1]
    ); */
    state.currentParentSelectedObj.intervals.push(
      state.currentExceptionData[state.currentExceptionData.length - 1]
    );

    //state.currentExceptionData.push({});
    state.currentExceptionAddRecordCounter++;
  },

  mutateExceptionDelete(state) {
    state.currentExceptionDeleteRecordCounter++;
  },

  mutateExceptionSave(state) {
    state.currentExceptionSaveRecordCounter++;
  },

  mutateExceptionRedo(state) {
    state.currentExceptionRedoWatcher++;
  },

  mutateExceptionUndo(state) {
    state.currentExceptionUndoWatcher++;
  },

  mutateExceptionRedostate(state, value) {
    state.currentExceptionredostate = value;
  },

  mutateExceptionUndostate(state, value) {
    state.currentExceptionundostate = value;
  },

  setExceptionObj(state, { row }) {
    state.currentExceptionSelectedObj = row;
    state.currentExceptionId = row.id;
  },

  /*workingNonWorking*/
  muatateworkingNonWorkingAdd(state) {
    state.currentworkingNonWorkingData.push({
      id: -1001,
      description: "",
      recurringStartDate: "",
      tempFrom: "",
      recurringEndDate: "",
      tempTo: "",
      isWorking: false,
      intervalConfig: "WeekDays",
      weekdays: [],
      rowUpdated: true,
    });
    state.currentParentSelectedObj.intervals.push(
      state.currentworkingNonWorkingData[
        state.currentworkingNonWorkingData.length - 1
      ]
    );
    state.currentworkingNonWorkingAddRecordCounter++;
  },

  mutateworkingNonWorkingDelete(state) {
    state.currentworkingNonWorkingDeleteRecordCounter++;
  },

  mutateworkingNonWorkingSave(state) {
    state.currentworkingNonWorkingSaveRecordCounter++;
  },

  mutateworkingNonWorkingRedo(state) {
    state.currentworkingNonWorkingRedoWatcher++;
  },

  mutateworkingNonWorkingUndo(state) {
    state.currentworkingNonWorkingUndoWatcher++;
  },

  mutateworkingNonWorkingRedostate(state, value) {
    state.currentworkingNonWorkingredostate = value;
  },

  mutateworkingNonWorkingUndostate(state, value) {
    state.currentworkingNonWorkingundostate = value;
  },

  setworkingNonWorkingObj(state, { row }) {
    state.currentworkingNonWorkingSelectedObj = row;
    state.currentworkingNonWorkingId = row.id;
  },

  toggleNonWorkingFilterModal(state) {
    state.isnonWorkingModalVisible = !state.isnonWorkingModalVisible;
  },

  mutateNonWorkingPopuObj(state, { row }) {
    state.currentworkingNonWorkingPopUpId = row.id;
    state.currentworkingNonWorkingPopUpSelectedObj = row;
  },
  mutateCalendarController(state) {
    state.updatecalendarControllerCounter++;
  },
};

const getters = {
  /*left Side(parent grid details)*/
  /*CurrentData and details*/
  getParentGridColumn() {
    return state.currentParentColDef;
  },

  getParentCurrentData() {
    return state.currentParentData;
  },

  getParentSelectedObj() {
    return state.currentParentSelectedObj;
  },
  getParentSelectedId() {
    return state.currentParentId;
  },
  /*Extra Config */
  getCostModelTypeData() {
    return state.currentCostModelType;
  },
  getCalendarStatus() {
    return state.isRelationship;
  },

  /**
   * get Exception and Time Intervals Col Defs
   */

  getExceptionColDef() {
    return state.currentExceptionColDef;
  },

  getWorkingNonWorkingColDef() {
    return state.currentWorkingNonWorkingColDef;
  },

  getCurrentException() {
    return state.currentExceptionData;
  },
  getExceptionSelectedId() {
    return state.currentExceptionId;
  },

  getExceptionSelectedObj() {
    return state.currentExceptionSelectedObj;
  },

  /** Work Intervals */
  getCurrentWorkIntervalData() {
    return state.currentworkingNonWorkingData;
  },

  getworkingNonWorkingSelectedId() {
    return state.currentworkingNonWorkingId;
  },

  getworkingNonWorkingSelectedObj() {
    return state.currentworkingNonWorkingSelectedObj;
  },

  /** Get Col Def for Working and NonWorking popup */

  getnonWorkingPopUpData() {
    return state.currentworkingNonWorkingPopUpData;
  },

  getworkingNonWorkingPopUpSelectedId() {
    return state.currentworkingNonWorkingPopUpId;
  },

  getworkingNonWorkingPopUpSelectedObj() {
    return state.currentworkingNonWorkingPopUpSelectedObj;
  },
  getSavedData() {
    return state.returnSavedData;
  },
  getParentColDefs(state) {
    return state.currentParentColDefs;
  },
};

/* Calendar Module */
export const calendarController = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
