<template>
  <div style="height: 100%; background-color: white" ref="scriptContainerP3M">
    <splitpanes class="default-theme">
      <pane style="margin-left: 0.5%" size="15">
        <div class="configHeader">
          <div class="left"><p>Template Details</p></div>
        </div>
        <div
          class="columns is-multiline is-mobile"
          style="height: 65%; width: 100%"
        >
          <div class="column is-12 group">
            <input
              type="text"
              name="Template Name"
              autocomplete="off"
              v-model="templateName"
              required
            />
            <span class="highlight"></span>
            <span class="bar"></span>
          </div>
        </div>
      </pane>
      <pane size="15">
        <div class="configHeader">
          <div class="left"><p>Project Codes</p></div>
          <div class="right" style="width: 50%">
            <GenericToolBar
              :toolbarConfig="toolbarConfig"
              @addNew="onAddNewClick"
            />
          </div>
        </div>
        <genericGridComponent
          style="height: 100%; width: 100%"
          :gridOptions="gridOptions"
          :rowData="rowData"
          :columnDefs="columnDefs"
          :processDataFromClipboard="processDataFromClipboard"
          :overlayNoRowsTemplate="noProjectCodeRowTemplate"
        >
        </genericGridComponent>
      </pane>
      <pane style="margin-right: 0.5%" size="70">
        <div class="configHeader">
          <div class="left"><p>Results</p></div>
          <div class="right">
            <button class="button is-success" @click="checkConfig">
              Check Configuration
            </button>
          </div>
        </div>
        <div
          class="left"
          style="background-color: white; height: 100%; width: 100%"
        >
          <!-- <DataToolBar /> -->

          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="multiple"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            defaultColumnDef="resultDefaultColumnDef"
            :gridOptions="resultsGridOptions"
            :rowData="resultRowData"
            :enableCharts="true"
            :columnDefs="resultColumnDefs"
            :enableRangeSelection="true"
            :undoRedoCellEditing="true"
            :enablePivot="true"
            :sideBar="true"
            :overlayNoRowsTemplate="noResultsRowTemplate"
          >
          </ag-grid-vue>
        </div>
        <!-- <genericGridComponent
          class="left"
          style="height: 100%; width: 100%"
          :enableCharts="true"
          :gridOptions="resultsGridOptions"
          :rowData="resultRowData"
          :columnDefs="resultColumnDefs"
          :overlayNoRowsTemplate="noResultsRowTemplate"
        >
        </genericGridComponent> -->
      </pane>
    </splitpanes>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import GenericToolBar from "@/components/Data/GenericToolbar.vue";
import genericGridComponent from "../../GenericComponents/genericGridComponent.vue";
import { userService } from "@/api/user-auth";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";

export default {
  name: "ProjectConfigMgmtView",
  props: {},
  data() {
    var self = this;
    return {
      noProjectCodeRowTemplate: "Add Project Codes to compare",
      noResultsRowTemplate: "Click Check Configuration to run comparison",
      runningProcessSet: false,
      loadingOverlayComponent: "", //CustomLoadingRenderer,
      loadingOverlayComponentParams: null,
      templateName: "", //"MIRVAC_AC_TMP_03",
      selectedGroup: {},
      columnDefs: [
        {
          headerName: "Project Code",
          field: "code",
          sortable: true,
          resizable: true,
          editable: true,
          filterParams: { excelMode: "windows" },
        },
      ],
      resultColumnDefs: [
        {
          headerName: "Project Code",
          field: "ProjectCode",
          sortable: true,
          enableRowGroup: true,
          filter: "agSetColumnFilter",
          filterParams: {
            excelMode: "windows",
          },
        },
        {
          headerName: "Object Checked",
          field: "ObjectChecked",
          sortable: true,
          enableRowGroup: true,
          filter: "agSetColumnFilter",
          filterParams: {
            excelMode: "windows",
          },
        },
        {
          headerName: "Code",
          field: "Code",
          sortable: true,
          enableRowGroup: true,
          filter: "agSetColumnFilter",
          filterParams: {
            excelMode: "windows",
          },
        },
        {
          headerName: "Setting",
          field: "Setting",
          sortable: true,
          resizable: true,
          filter: "agSetColumnFilter",
          filterParams: {
            excelMode: "windows",
          },
        },
        {
          headerName: "Template Setting",
          field: "TemplateSetting",
          sortable: true,
          resizable: true,
          filter: "agSetColumnFilter",
          filterParams: {
            excelMode: "windows",
          },
        },
        {
          headerName: "Project Setting",
          field: "ProjectSetting",
          sortable: true,
          resizable: true,
          filter: "agSetColumnFilter",
          filterParams: {
            excelMode: "windows",
          },
        },
      ],
      resultDefaultColumnDef: {
        flex: 1,
        // allow every column to be aggregated
        enableValue: true,
        // allow every column to be grouped
        enableRowGroup: true,
        // allow every column to be pivoted
        enablePivot: true,
        sortable: true,
        filter: "agSetColumnFilter",
        filterParams: {
          excelMode: "windows",
        },
      },
      componentColumnDefs: [],
      //rowData: [{ id: "001", code: "test" }],
      rowData: [],
      resultRowData: [],
      gridOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
          ],
        },
      },
      resultsGridOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
          ],
        },
      },
      processDataFromClipboard: function (params) {
        console.log("params", params);
        console.log("self", self);
        const data = [...params.data];
        const emptyLastRow =
          data[data.length - 1][0] === "" && data[data.length - 1].length === 1;

        if (emptyLastRow) {
          data.splice(data.length - 1, 1);
        }

        const lastIndex = self.gridOptions.api.getModel().getRowCount() - 1;
        const focusedCell = self.gridOptions.api.getFocusedCell();
        const focusedIndex = focusedCell.rowIndex;

        if (focusedIndex + data.length - 1 > lastIndex) {
          const resultLastIndex = focusedIndex + (data.length - 1);
          const numRowsToAdd = resultLastIndex - lastIndex;

          const rowsToAdd = [];
          for (let i = 0; i < numRowsToAdd; i++) {
            const index = data.length - 1;
            const row = data.slice(index, index + 1)[0];

            // Create row object
            const rowObject = {};
            let currentColumn = focusedCell.column;
            row.forEach((item) => {
              if (!currentColumn) {
                return;
              }
              rowObject[currentColumn.colDef.field] = item;
              currentColumn =
                self.gridOptions.columnApi.getDisplayedColAfter(currentColumn);
            });
            rowsToAdd.push(rowObject);
          }
          self.rowData = self.rowData.concat(rowsToAdd);
          self.gridOptions.api.applyTransaction({ add: rowsToAdd });
        }

        return data;
      },
      toolbarConfig: {
        showAddNew: true,
        showDelete: false,
      },
    };
  },
  components: {
    AgGridVue,
    GenericToolBar,
    genericGridComponent,
    Splitpanes,
    Pane,
  },
  mounted() {
    this.resultRowData = [];
    this.templateName = "";
    this.rowData = [];
  },
  watch: {},
  methods: {
    checkConfig() {
      if (
        !this.runningProcessSet &&
        this.templateName != "" &&
        this.rowData.length > 0
      ) {
        console.log("thisRowData", this.rowData);
        this.runningProcessSet = true;
        var prjCodes = this.rowData.filter((x) => x.code != "");
        this.resultsGridOptions.api.showLoadingOverlay();
        var incomingDataObj = [
          {
            inputDataName: "allTemplates",
            inputData: this.templateName.split(","),
          },
          {
            inputDataName: "projectsToCheck",
            inputData: this.rowData.map((x) => {
              return x.code;
            }),
          },
          {
            inputDataName: "TargetEnvironment",
            inputDataType: 0,
            inputData: this.selectedEnvironment,
          },
        ];
        userService
          .post(`Processset/Execute`, {
            id: this.selectedProcessSetId,
            IncomingData: incomingDataObj,
          })
          .then((result) => {
            if (result.succeeded && result.data != null) {
              //(result.data);
              this.resultRowData = JSON.parse(result.data);
              this.resultsGridOptions.api.hideOverlay();
            }
          })
          .catch((error) => {
            this.resultsGridOptions.api.hideOverlay();
          })
          .finally(() => {
            this.runningProcessSet = false;
          });
      }
    },
    onAddNewClick() {
      console.log("Adding");
      this.rowData = [...this.rowData, { code: "" }];
    },
  },
  computed: {
    ...mapGetters("jiveComponents", [
      "rawEntitiesLoaded",
      "allRawComponents",
      "defaultComponentColumns",
      "allGroupAccess",
    ]),
    ...mapGetters("analyticsDataManager", [
      "selectedProcessSetId",
      "selectedEnvironment",
    ]),
    // ...mapGetters("jiveComponents", [
    //   {
    //     allComponents: "allRawComponents",
    //     defaultComponentColumns: "defaultComponentColumns",
    //   },1
    // ]),
  },
};
</script>

<style scoped lang="scss">
.configHeader {
  height: 5%;
  width: 100%;
  margin: auto;
  vertical-align: middle;
}

.left {
  display: table;
  float: left;
  height: 100%;
}

.left p {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.right {
  float: right;
  height: 100%;
}

.right button {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.container-grid {
  margin-top: 0.1%;
  background-color: #fff;
  color: #444;
  /* Use flexbox */
  display: flex;
}
.box {
  margin-top: 0.1%;
  color: #fff;
  border-radius: 1px;
  padding: 15px;
  font-size: 150%;

  /* Use box-sizing so that element's outerwidth will match width property */
  box-sizing: border-box;

  /* Allow box to grow and shrink, and ensure they are all equally sized */
  flex: 1 1 auto;
}
.title {
  font-family: Verdana;
  font-size: 1.4vw;
}
.formulate-input {
  .formulate-input-label {
    font-weight: bold;
  }
}
/* form starting stylings ------------------------------- */
.group {
  position: relative;
  margin-bottom: 15px;
}

input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  color: #000;
}

textarea {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
}
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}

h1 {
  font-family: "Trocchi", serif;
  font-weight: normal;
  line-height: 48px;
  margin: 0;
}
/* LABEL ======================================= */
label {
  color: #999;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 7px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label {
  top: -10px;
  font-size: 14px;
  color: black;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 300px;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
::-webkit-input-placeholder {
  color: #999;
  font-weight: bold;
}
.column {
  box-shadow: 0.01rem 0.01rem 0.01rem rgb(241, 224, 224);
  margin-top: 0.01rem;
}
</style>
