<template>
  <div
    style="height: 95vh; width: 96.2vw; background-color: white"
    ref="scriptContainerP3M"
  >
    <GenericToolBar
      :selectedRowParentObjs="selectedGroup"
      :dataDirty="dataDirty"
      :toolbarConfig="toolbarConfig"
      @save="onSaveClick"
      @addNew="onAddNewClick"
    />
    <div class="columns" style="height: 96vh; width: 100%">
      <div class="container-grid" style="width: 100%; height: 100%">
        <div class="box" style="height: 96vh">
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="single"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            :gridOptions="gridOptions"
            :rowData="rowData"
            :columnDefs="columnDefs"
            @selection-changed="onSelectionChanged"
          >
          </ag-grid-vue>
        </div>
        <div class="handler"></div>
        <div
          class="box"
          style="width: 1%; overflow: auto; margin-left: 0.7em"
          :disabled="this.groupSelected != true"
        >
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            :gridOptions="userGridOptions"
            :rowData="userRowData"
            :columnDefs="userColumnDefs"
            @cell-value-changed="onUserCellValueChanged"
          >
          </ag-grid-vue>
          <!-- <data-operation />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import GenericToolBar from "@/components/Data/GenericToolbar.vue";

export default {
  name: "GroupAdmin",
  props: {},
  data() {
    return {
      selectedGroup: {},
      toolbarConfig: {
        showAddNew: true,
        showSave: true,
      },
      columnDefs: [],
      userColumnDefs: [],
      rowData: [],
      userRowData: [],
      gridOptions: {},
      userGridOptions: {},
      dataDirty: false,
      groupSelected: false,
      tmpId: -1,
    };
  },
  components: {
    AgGridVue,
    GenericToolBar,
  },
  mounted() {
    this.loadGroupsAndUsers();
  },
  watch: {
    entitiesLoaded() {
      if (this.entitiesLoaded) {
        this.rowData = [...this.allGroups];
        this.userRowData = [...this.allUsers];
        this.columnDefs = this.defaultGroupColumns;
        this.userColumnDefs = this.defaultUserColumns;
      }
    },
  },
  methods: {
    ...mapActions("groupAdmin", ["loadGroupsAndUsers", "saveGroupsAndMembers"]),
    onSelectionChanged(event) {
      var selectedRowObjs = event.api.getSelectedNodes();
      if (selectedRowObjs.length > 0) {
        this.groupSelected = true;
        this.selectedGroup = selectedRowObjs[0].data;
        this.UpdateMembershipFlag(selectedRowObjs[0].data.id);
      } else this.selectedGroup = null;
    },
    UpdateMembershipFlag(groupId) {
      var selectedGrp = this.rowData.find((x) => x.id == groupId);
      this.userRowData.forEach((elem, idx) => {
        elem.inGroup = this.isValid(
          selectedGrp.Users.find((x) => x.jiveUserId == elem.jiveUserId)
        );
        elem.rowUpdated = false;
      });
      this.userGridOptions.api.refreshCells();
    },
    onUserCellValueChanged(event) {
      this.dataDirty = true;
      event.node.data.rowUpdated = true;
      var selectedGrpObj = this.gridOptions.api.getSelectedNodes();
      var selectedGrp = selectedGrpObj[0].data;
      selectedGrp.updated = true;
      if (event.node.data.inGroup) selectedGrp.Users.push(event.node.data);
      else
        selectedGrp.Users = selectedGrp.Users.filter(
          (ju) => ju.jiveUserId !== event.data.jiveUserId
        );
    },
    isValid(valueToCheck) {
      if (
        String(valueToCheck) != "" &&
        String(valueToCheck) != "null" &&
        String(valueToCheck) != "undefined"
      )
        return true;
      else {
        return false;
      }
    },
    onSaveClick() {
      this.saveGroupsAndMembers(this.rowData);
    },
    onAddNewClick() {
      this.dataDirty = true;
      this.rowData.push({
        id: this.tmpId,
        code: "",
        description: "",
        updated: true,
        Users: [],
      });
      this.UpdateMembershipFlag(this.tmpId);
      --this.tmpId;
    },
  },
  computed: {
    ...mapGetters("groupAdmin", [
      "allGroups",
      "allUsers",
      "defaultUserColumns",
      "defaultGroupColumns",
      "allMemberships",
      "entitiesLoaded",
    ]),
  },
};
</script>

<style scoped lang="scss">
.container-grid {
  margin-top: 0.1%;
  background-color: #fff;
  color: #444;
  /* Use flexbox */
  display: flex;
}
.box {
  margin-top: 0.1%;
  color: #fff;
  border-radius: 1px;
  padding: 15px;
  font-size: 150%;

  /* Use box-sizing so that element's outerwidth will match width property */
  box-sizing: border-box;

  /* Allow box to grow and shrink, and ensure they are all equally sized */
  flex: 1 1 auto;
}
.handler {
  margin-top: 0.8%;
  position: relative;
  width: 1px;
  padding: 0;
  cursor: col-resize;
  flex: 0 0 auto;
}

.handler::before {
  margin-top: 0.8%;
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background: silver;
  margin: 0 auto;
}
</style>
