import { post } from "../api/user-auth.js";
import {
  UpdateStructure,
  UpdateStructureDetail,
  getStructureDetails,
  DeleteStructureDetail,
  DeleteStructure,
  getAllExtraColumnDetails,
} from "@/api/networking.js";

let data = [],
  i = -1,
  tempData = null;
function getParentLoop(id, path) {
  let p = data.filter((i) => i.id == id)[0];
  if (p.parentId) {
    path.unshift(p.id);
    return getParentLoop(p.parentId, path);
  } else {
    path.unshift(p.id);
  }
  return path;
}

function setpath(data) {
  data.map((i) => {
    i.path = [];
    if (i.parentId) {
      i.path.push(i.id);
      getParentLoop(i.parentId, i.path);
    } else {
      i.path.push(i.id);
    }
    return i;
  });
  return data;
}

const state = {
  currentStructureData: [],
  currentDetailData: [],
  tempSaveData: [],
  currentStructureID: null,
  dataWatcher: 0,
  selectedRowObj: {},
  addingRecord: false,
  addingDeatilRecord: false,
  addingSubRecord: false,
  isRelationship: false,
  undoWatcher: 0,
  redoWatcher: 0,
  isRedo: false,
  isUndo: false,
  StrundoWatcher: 0,
  StrredoWatcher: 0,
  isStrRedo: false,
  isStrUndo: false,
  SaveError: 0,
  currentDetailID: null,
  AddRecordCounter: 0,
  AddSubRecordCounter: 0,
  SaveCounter: 0,
  LoadCounter: 0,
  SaveWatcher: 0,
  popups: false,
  DeleteCounter: 0,
  isDelete: 0,
  StrDeleteCounter: 0,
  isStrctureDelete: 0,
  MasterDataTypes: [
    {
      id: 0,
      name: "Structures",
      displayName: "Structures",
    },
    {
      id: 1,
      name: "SpendingCurve",
      displayName: "Spending Curve",
    },
    {
      id: 2,
      name: "ExtraColumn",
      displayName: "Extra Column",
    },
    {
      id: 3,
      name: "ScheduleResources",
      displayName: "Schedule Resources",
    },
    {
      id: 4,
      name: "Calendars",
      displayName: "Calendars",
    },
    {
      id: 5,
      name: "PerformanceMetrics",
      displayName: "Performance Metrics",
      API: "PerformanceMetric",
      iconClass: "fas fa-folder-open",
      apiPrefix: "EnterpriseResourceHandler",
    },
    {
      id: 6,
      name: "PerformanceStatuses",
      displayName: "Performance Statuses",
      API: "PerformanceStatus",
      iconClass: "fas fa-folder-open",
      apiPrefix: "EnterpriseResourceHandler",
    },
    {
      id: 7,
      name: "RiskSeverity",
      displayName: "Risk Severity",
      API: "RiskSeverity",
      iconClass: "fas fa-folder-open",
      apiPrefix: "EnterpriseResourceHandler",
    },
  ],
  currentParentColumDefCounter: 0,
  selectedMasterDataTypeId: null,
  currentParentColDefs: [],
  currentChildColDefs: [],
  currentChildColumDefCounter: 0,
};

const actions = {
  /*Extra Column */
  async LoadStructureExtraColumn({ dispatch }) {
    state.currentParentColDefs = [];
    getAllExtraColumnDetails("StructureExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          rowData.data.forEach((y) =>
            state.currentParentColDefs.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 120,
              editable: true,
              filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: "OR",
              },
              filter: "agSetColumnFilter",
              cellStyle: function (params) {
                if (params.data != undefined && params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                } else if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                } /* else if (y.valueType == 6) {
                  return {
                    component: "StructureCheckBoxCellEditor",
                  };
                } */
                return null;
              },
              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;

                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                      if (params.node.data.rowUpdated != "Duplicate") {
                        params.node.data.rowUpdated = true;
                      }

                      params.api.refreshCells({
                        force: true,
                        suppressFlash: true,
                      });
                    }
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return new Date(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.currentParentColumDefCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, {
          root: true,
        });
      });
  },

  async LoadStructureDetailExtraColumn({ dispatch }) {
    state.currentChildColDefs = [];
    getAllExtraColumnDetails("StructureDetailExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          rowData.data.forEach((y) =>
            state.currentChildColDefs.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 120,
              editable: true,
              filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: "OR",
              },
              filter: "agSetColumnFilter",
              cellStyle: function (params) {
                if (params.data != undefined && params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                } else if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    if (params.node.data.rowUpdated != "Duplicate") {
                      params.node.data.rowUpdated = true;
                    }
                    params.api.refreshCells({
                      force: true,
                      suppressFlash: true,
                    });
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }
                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return new Date(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.currentChildColumDefCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, {
          root: true,
        });
      });
  },

  setSelectedMasterDataType({ commit }, dataId) {
    console.log("Looking for", dataId);
    var selectedMDT = this.MasterDataTypes.find((x) => x.name == dataId);
    console.log("selectedMDT", selectedMDT);
    commit("mutateSelectedMasterDataType", {
      selectedMasterDataTypeId: selectedMDT.id,
    });
  },
  async LoadStructureDetails({ dispatch, commit }, structureID) {
    getStructureDetails(structureID)
      .then((rowData) => {
        if (rowData.succeeded) {
          if (rowData.data.length > 0)
            commit("setDetailData", { returnedData: rowData.data });
          state.currentDetailID = null;
          state.currentStructureID = structureID;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async DeleteStrDetails({ dispatch }, { structureDetailId }) {
    DeleteStructureDetail(structureDetailId)
      .then((rowData) => {
        if (rowData.succeeded) {
          state.isDelete++;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async DeleteStructures({ dispatch }, { structureId }) {
    DeleteStructure(structureId)
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentDetailID = null;
          state.AddRecordCounter = 0;
          state.AddSubRecordCounter = 0;
          state.DeleteCounter = 0;
          state.isDelete = 0;
          state.isStrctureDelete++;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async saveStructureData({ dispatch, commit }) {
    var rowsToInsert = [];
    var rowsToUpdate = [];
    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {};
    state.currentStructureData.forEach((row) => {
      if (row.rowUpdated) {
        if (row.id == -1) {
          rowsToInsert.push(row);
        } else {
          rowsToUpdate.push({
            Id: row.id,
            code: row.code,
            description: row.description,
            ExtraColumnValues: row.extraColumnValues,
          });
        }
      }
    });
    if (rowsToInsert.length > 0) {
      createURL = "Structure/Create";
      createObj = { structuresToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = "Structure/Update";
      updateObj = { StructuresToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values[1]) returnedValues = values[1].data;
          commit("setStructureSavedData", {
            returnedSavedData: returnedValues,
          });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = UpdateStructure(updateObj, state.selectedDataTypeId);
      Promise.all([createP, updateP])
        .then((values) => {
          var returnedValues = [];
          if (values[1]) returnedValues = values[1].data;
          commit("setStructureSavedData", {
            returnedSavedData: returnedValues,
          });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    //Structure Details
    rowsToInsert = [];
    rowsToUpdate = [];
    createP = Promise.resolve(1);
    updateP = Promise.resolve(1);
    createURL = "";
    updateURL = "";
    createObj = {};
    updateObj = {};
    state.tempSaveData.forEach((row) => {
      if (row.code != undefined) {
        if (row.rowUpdated) {
          if (row.id <= -1) {
            row.ChildStructureDetails = row.path;
            //delete row.path;
            rowsToInsert.push(row);
          } else {
            rowsToUpdate.push({
              Id: row.id,
              code: row.code,
              description: row.description,
              isLabour: row.isLabour,
              parentId: row.parentId,
              ExtraColumnValues: row.extraColumnValues,
            });
          }
        }
      } else {
        state.SaveError++;
        rowsToInsert = [];
        rowsToUpdate = [];
        return false;
      }
    });

    if (rowsToInsert.length > 0) {
      createURL = "StructureDetail/Create";
      createObj = { structureDetailsToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = "StructureDetail/Update";
      updateObj = { structureDetailToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          //if (values[1]) returnedValues = values[1].data;
          if (values) returnedValues = values.data;
          commit("setDetailSavedData", { returnedSavedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = UpdateStructureDetail(updateObj, state.selectedDataTypeId);
      Promise.all([createP, updateP])
        .then((values) => {
          var returnedValues = [];
          if (values[1]) returnedValues = values[1].data;
          commit("setDetailSavedData", { returnedSavedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
    i = -1;
  },

  async setSelectedData({ commit }, selectedDataTypeName) {
    console.log("StructureManager: selectedDataTypeName", selectedDataTypeName);
    console.log(
      "selectedDT",
      state.MasterDataTypes.find((x) => x.name == selectedDataTypeName)
    );
    commit("mutateSelectedMasterDataType", {
      selectedMasterDataTypeId: state.MasterDataTypes.find(
        (x) => x.name == selectedDataTypeName
      ).id,
    });
    console.log("Post", state.selectedMasterDataTypeId);
  },
};

const mutations = {
  setStructureData(state, { returnedData }) {
    returnedData.forEach(function (row) {
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.structureExtraColumnId] = y.value)
        );
      }
      // returnedData.forEach(row => ();
    });
    state.currentStructureData = returnedData;
  },

  setStructureSavedData(state, { returnedSavedData }) {
    returnedSavedData.forEach((row) => (row.rowUpdated = false));
    state.currentStructureData = returnedSavedData;
    state.dataWatcher += 1;
  },

  setDetailSavedData(state, { returnedSavedData }) {
    returnedSavedData.forEach((row) => (row.rowUpdated = false));
    state.tempSaveData.forEach((row) => {
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.structureDetailExtraColumnId] = y.value)
        );
      }
      if (row.id < 0) {
        row.id = returnedSavedData.find(
          (x) => x.code == row.code && x.description == row.description
        ).id;
        row.path[row.path.length - 1] = row.id;
      }
      if (row.parentId < 0) {
        row.parentId = returnedSavedData.find(
          (x) => x.code == row.code && x.description == row.description
        ).parentId;
        if (row.path.filter((x) => x < 0).length > 1) {
          row.path = state.tempSaveData.find((x) => x.id == row.parentId).path;
          row.path.push(row.id); // row.id;
        } else {
          row.path[row.path.length - 2] = row.parentId;
          row.path[row.path.length - 1] = row.id;
        }
      }
      delete row.ChildStructureDetails;
    });
    //state.currentDetailID = state.tempSaveData[state.tempSaveData.length-1];
    state.SaveCounter = 0;
    state.dataWatcher += 1;
  },

  setDetailData(state, { returnedData }) {
    if (returnedData != null) {
      returnedData.forEach(function (row) {
        row.rowUpdated = false;
        if (row.extraColumnValues != null) {
          row.extraColumnValues.forEach(
            (y) => (row["et_" + y.structureDetailExtraColumnId] = y.value)
          );
        }
      });
      data = returnedData;
      tempData = setpath(data);
      state.currentDetailData = returnedData;
    }
    state.currentStructureID = returnedData[0].structureId;
    state.LoadCounter++;
    i--;
  },

  setStructureValue(state, selectedId) {
    state.currentDetailID = null;
    state.AddRecordCounter = 0;
    state.AddSubRecordCounter = 0;
    state.DeleteCounter = 0;
    state.isDelete = 0;
    state.currentStructureID = selectedId;
  },

  setcurrentStructureID(state) {
    state.currentStructureID = null;
  },

  setDeatilId(state, selectedId) {
    state.currentDetailID = selectedId;
  },

  setSelectedRowObject(state, selectedObj) {
    if (selectedObj != 0) {
      state.selectedRowObj = selectedObj;
    } else {
      state.selectedRowObj = 0;
    }
  },

  addStructureRecord() {
    state.addingRecord = true;
    state.currentStructureData.push({
      id: -1,
      code: "",
      description: "",
      rowUpdated: false,
      extraColumnValues: [],
    });
    state.AddCounter++;
  },

  requestUndo(state) {
    state.undoWatcher += 1;
  },

  requestRedo(state) {
    state.redoWatcher += 1;
  },

  resetRequestUndo(state, Undovalue) {
    state.isUndo = Undovalue;
  },

  resetRequestRedo(state, Redovalue) {
    state.isRedo = Redovalue;
  },

  requestStrUndo(state) {
    state.StrundoWatcher += 1;
  },

  requestStrRedo(state) {
    state.StrredoWatcher += 1;
  },

  resetStrRequestUndo(state, Undovalue) {
    state.isStrUndo = Undovalue;
  },

  resetStrRequestRedo(state, Redovalue) {
    state.isStrRedo = Redovalue;
  },

  mutateSubRecord(state) {
    state.AddSubRecordCounter++;
  },

  mutateAddRecord(state) {
    state.AddRecordCounter++;
  },

  mutateSaveCounter(state) {
    state.SaveCounter++;
  },

  mutateRecord(state, { returnedNewData }) {
    state.tempSaveData = [];
    returnedNewData.forEach((element) => {
      if (element.rowUpdated) {
        state.tempSaveData.push(element);
      }
    });
    state.tempSaveData = returnedNewData;
  },

  mutateDelete(state) {
    state.DeleteCounter++;
  },

  mutateStrDelete(state) {
    state.StrDeleteCounter++;
  },

  mutateSelectedMasterDataType(state, { selectedMasterDataTypeId }) {
    state.selectedMasterDataTypeId = selectedMasterDataTypeId;
  },
};

const getters = {
  getAllDataTypes() {
    return state.MasterDataTypes;
  },
  getDataWatcher() {
    return state.dataWatcher;
  },
  getParentColDefs(state) {
    return state.currentParentColDefs;
  },

  getChildColDefs(state) {
    return state.currentChildColDefs;
  },

  getRedoWatcher() {
    return state.redoWatcher;
  },

  getUndoWatcher() {
    return state.undoWatcher;
  },

  getisRedo() {
    return state.isRedo;
  },

  getisUndo() {
    return state.isUndo;
  },

  getStrRedoWatcher() {
    return state.StrredoWatcher;
  },

  getStrUndoWatcher() {
    return state.StrundoWatcher;
  },

  getStrisRedo() {
    return state.isStrRedo;
  },

  getStrisUndo() {
    return state.isStrUndo;
  },

  getSelectedRowObject() {
    return state.selectedRowObj;
  },

  getStructureData() {
    return state.currentStructureData;
  },

  getDetailData() {
    return state.currentDetailData;
  },

  getStructureIdvalue() {
    return state.currentStructureID;
  },

  getDetailIdvalue() {
    return state.currentDetailID;
  },

  selectedMasterDataType({ selectedMasterDataTypeId, MasterDataTypes }) {
    if (selectedMasterDataTypeId == null) selectedMasterDataTypeId = 0;
    return MasterDataTypes.find((dt) => dt.id == selectedMasterDataTypeId);
  },

  getStructureColumns() {
    return [
      {
        headerName: "Code",
        field: "code",
        sortable: true,
        resizable: true,
        width: 250,
        filterParams: { excelMode: "windows" },
        editable: true,
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
        cellRenderer: function (params) {
          if (params.data.rowUpdated == "Duplicate") {
            let eIconGui = document.createElement("span");
            return (eIconGui.innerHTML =
              '<i class="fas fa-exclamation" style="color:red;"></i>' +
              " " +
              params.data.code);
          }
          return params.data.code;
        },
      },
      {
        headerName: "Description",
        field: "description",
        sortable: true,
        resizable: true,
        width: 375,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
      },
    ];
  },

  addingRecord({ addingRecord }) {
    return addingRecord;
  },

  addingDeatilRecord({ addingDeatilRecord }) {
    return addingDeatilRecord;
  },

  addingSubRecord({ addingSubRecord }) {
    return addingSubRecord;
  },

  getDetailsColumns() {
    return [
      {
        headerName: "",
        field: "hasRelations",
        width: 40,
        cellRenderer: function (params) {
          let eIconGui = document.createElement("span");
          if (params.data.hasRelations) {
            return (eIconGui.innerHTML = '<i class="fas fa-lock"></i>');
          } else {
            return (eIconGui.innerHTML = '<i class="fas fa-lock-open"></i>');
          }
        },
      },
      {
        headerName: "Code",
        field: "code",
        sortable: true,
        resizable: true,
        width: 100,
        filterParams: { excelMode: "windows" },
        editable: true,
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
        cellRenderer: function (params) {
          if (params.data.rowUpdated == "Duplicate") {
            let eIconGui = document.createElement("span");
            return (eIconGui.innerHTML =
              '<i class="fas fa-exclamation" style="color:red;"></i>' +
              " " +
              params.data.code);
          }
          return params.data.code;
        },
      },
      {
        headerName: "Description",
        field: "description",
        sortable: true,
        resizable: true,
        width: 300,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
      },
      {
        headerName: "Labour",
        field: "isLabour",
        sortable: true,
        resizable: true,
        width: 90,
        editable: false,
        filterParams: { excelMode: "windows" },
        cellRenderer: function (params) {
          var input = document.createElement("input");
          input.type = "checkbox";
          input.checked = params.value;
          input.addEventListener("click", function (event) {
            if (params.value == null) {
              params.value = false;
              params.node.data.isLabour = false;
            }
            params.value = !params.value;
            params.data.rowUpdated = true;
            params.node.data.isLabour = params.value;
          });
          return input;
        },
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
      },
    ];
  },
};

export const structureManager = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
