<template>
  <div style="height: 100%; background-color: white" ref="scriptContainerP3M">
    <splitpanes class="default-theme">
      <pane style="margin-left: 0.5%" size="15">
        <ag-grid-vue
          class="ag-theme-balham ag-default-layout"
          rowSelection="single"
          enableCellChangeFlash="true"
          stopEditingWhenGridLosesFocus="true"
          defaultColumnDef="resultDefaultColumnDef"
          gridOptions="gridOptions"
          :rowData="systemRowData"
          :columnDefs="systemsColumnDefs"
          @selection-changed="onSelectionChanged"
          @grid-ready="onGridReady"
        >
        </ag-grid-vue>
      </pane>
      <pane
        style="margin-left: 0.5%"
        size="15"
        v-if="this.selectedSystemName == 'CX'"
      >
        <div class="columns">
          <GenericToolBar
            :toolbarConfig="toolbarConfig"
            :dataDirty="dataDirty"
            @save="onSaveClick"
          />
        </div>
        <div
          class="columns is-multiline is-mobile"
          style="height: 65%; width: 100%"
        >
          <div class="column is-multiline">
            <div class="column is-5 group">
              <div class="left configHeader"><p>Configure Connections</p></div>
            </div>
            <div class="column is-5 group">
              <input
                type="text"
                v-model="cxConnInfo.cxUsername"
                autocomplete="off"
                required
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>CX UserName</label>
            </div>
            <div class="column is-5 group">
              <input
                type="password"
                v-model="cxConnInfo.cxPassword"
                autocomplete="off"
                required
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>CX Password</label>
            </div>
            <div class="column is-5 group">
              <input
                type="text"
                v-model="cxConnInfo.cxClientId"
                autocomplete="off"
                required
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>CX Client Id</label>
            </div>
            <div class="column is-5 group">
              <input
                type="text"
                v-model="cxConnInfo.cxResourceId"
                autocomplete="off"
                required
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>CX Resource Id</label>
            </div>
            <div class="column is-5 group">
              <input
                type="text"
                v-model="cxConnInfo.cxBaseUrl"
                autocomplete="off"
                required
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>CX Base Url</label>
            </div>
            <div class="column is-5 group">
              <input
                type="text"
                v-model="cxConnInfo.cxTenantId"
                autocomplete="off"
                required
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>CX Tenant Id</label>
            </div>

            <!-- <div class="column is-12 group">
              <button
                class="button is-success"
                :disabled="cxReadyToTest == true"
                @click="sendCXConnectionAttempt"
              >
                Connect to CX
              </button>
              <span class="highlight"></span>
              <span class="bar"></span>
            </div> -->
          </div>
        </div>
      </pane>
      <pane
        style="margin-left: 0.5%"
        size="15"
        v-if="this.selectedSystemName == 'MTWO'"
      >
        <div class="columns">
          <GenericToolBar
            :toolbarConfig="toolbarConfig"
            :dataDirty="dataDirty"
            @save="onSaveClick"
          />
        </div>
        <div
          class="columns is-multiline is-mobile"
          style="height: 65%; width: 100%"
        >
          <div class="column is-multiline">
            <div class="column is-5 group">
              <div class="left configHeader"><p>Configure Connections</p></div>
            </div>
            <div class="column is-5 group">
              <input
                type="text"
                v-model="mtwoConnInfo.Username"
                autocomplete="off"
                required
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>MTWO UserName</label>
            </div>
            <div class="column is-5 group">
              <input
                type="password"
                v-model="mtwoConnInfo.Password"
                autocomplete="off"
                required
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>MTWO Password</label>
            </div>
            <div class="column is-12 group">
              <button
                class="button is-success"
                @click="sendCXConnectionAttempt"
              >
                Connect to MTWO
              </button>
              <span class="highlight"></span>
              <span class="bar"></span>
            </div>
          </div>
        </div>
      </pane>
      <pane
        style="margin-left: 0.5%"
        size="15"
        v-if="this.selectedSystemName == 'ACC'"
      >
      </pane>
      <pane
        style="margin-left: 0.5%"
        size="15"
        v-if="this.selectedSystemName == 'SIMPro'"
      >
        <div class="column is-12 group">
          <button class="button is-success" @click="connectToSimPro">
            Connect to SimPRO
          </button>
          <span class="highlight"></span>
          <span class="bar"></span>
        </div>
      </pane>
    </splitpanes>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import GenericToolBar from "@/components/Data/GenericToolbar.vue";

export default {
  name: "ExternalSystemAccess",
  props: {},
  data() {
    return {
      gridApi: {},
      gridOptions: {},
      dataDirty: true,
      cxReadyToTest: false,
      toolbarConfig: {
        showSave: true,
      },
      systemRowData: [
        { systemName: "CX" },
        { systemName: "MTWO" },
        { systemName: "ACC" },
        { systemName: "SIMPro" },
      ],
      systemsColumnDefs: [
        {
          headerName: "System Name",
          field: "systemName",
          sortable: true,
          resizable: true,
          editable: false,
          filterParams: { excelMode: "windows" },
        },
      ],
      cxConnInfo: {
        cxUsername: "",
        cxPassword: "",
        cxClientId: "",
        cxResourceId: "",
        cxBaseUrl: "",
        cxTenantId: "",
      },
      mtwoConnInfo: {},
      selectedSystemName: "",
    };
  },
  components: {
    Splitpanes,
    Pane,
    AgGridVue,
    GenericToolBar,
  },
  mounted() {
    this.loadUserSettings().then((x) => {
      if (this.getLoadedConnInfo.CX)
        this.cxConnInfo = this.getLoadedConnInfo.CX;
      else
        this.cxConnInfo = {
          cxUsername: "",
          cxPassword: "",
          cxClientId: "",
          cxResourceId: "",
          cxBaseUrl: "",
          cxTenantId: "",
        };
      if (this.getLoadedConnInfo.MTWO)
        this.mtwoConnInfo = this.getLoadedConnInfo.MTWO;
      else
        this.mtwoConnInfo = {
          Username: "",
          Password: "",
        };
    });
  },
  watch: {
    cxConnInfo(newInfo, oldInfo) {
      console.log("this.cxConnInfo", this.cxConnInfo);
      console.log("newInfo", newInfo);
      console.log("oldInfo", oldInfo);
      if (newInfo == undefined) {
        this.cxConnInfo = {
          cxUsername: "",
          cxPassword: "",
          cxClientId: "",
          cxResourceId: "",
          cxBaseUrl: "",
          cxTenantId: "",
        };
      }
      if (this.cxConnInfo) {
        if (
          this.cxConnInfo.cxUsername !== "" &&
          this.cxConnInfo.cxPassword !== "" &&
          this.cxConnInfo.cxClientId !== "" &&
          this.cxConnInfo.cxResourceId !== "" &&
          this.cxConnInfo.cxBaseUrl !== "" &&
          this.cxConnInfo.cxTenantId !== ""
        )
          this.cxReadyToTest = true;
      }
    },
    userSettingsLoaded() {
      if (this.userSettingsLoaded) {
        this.cxConnInfo = this.getLoadedConnInfo.CX;
      }
    },
  },
  methods: {
    ...mapActions("externalSystemsAccess", [
      "connectToCX",
      "saveConnections",
      "loadUserSettings",
    ]),
    onGridReady(params) {
      this.gridApi = params.api;
      // this.gridApi.forEachNode(function (node) {
      //   node.setSelected(node.data.systemName === "CX");
      // });
    },
    onSelectionChanged() {
      this.selectedSystemName = this.gridApi.getSelectedRows()[0].systemName;
    },
    onSaveClick() {
      let settingObj = {
        Name: "EXT_SYS_" + this.gridApi.getSelectedRows()[0].systemName,
        DataType: 4,
        Data: JSON.stringify(this.cxConnInfo),
        IsEncrypted: true,
      };
      console.log("settingObj", settingObj);
      this.saveConnections(settingObj).then((resp) => {
        this.$vToastify.success("Settings Saved Successfully!");
      });
    },

    async sendCXConnectionAttempt() {
      this.connectToCX(settingObj);
    },
    async connectToSimPro() {
      window.location.href =
        "https://enterprise-sandbox-au.simprosuite.com/oauth2/login?client_id=d81734c8e8e090d7addd504aa057ea";
    },
  },
  computed: {
    ...mapGetters("externalSystemsAccess", [
      "getLoadedConnInfo",
      "userSettingsLoaded",
    ]),
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/jiveInputs.scss";
</style>
