import Vue from "vue";
import VueRouter from "vue-router";
import { userService } from "@/api/user-auth";
import Views from "../views/Views.vue";
import MasterDataView from "../views/MasterDataView.vue";
import ClientAdminView from "../views/ClientAdminView.vue";
import Login from "../views/Login.vue";
import { Datetime } from "vue-datetime";
import C404 from "../views/404.vue";
import Dashboard from "../views/Dashboard.vue";
import UserMgmt from "../components/UserMgmt/UserMgmt.vue";
import UserAdmin from "../components/UserMgmt/UserAdmin.vue";
import MasterSpendingCurveView from "../views/MasterSpendingCurveView.vue";
import craneLoading from "../components/loading/craneLoading.vue";
import DashBoardsMenu from "../views/DashboardNew.vue";
import MasterStructureView from "../views/MasterStructureView.vue";
import schedulingDataManager from "../components/Scheduling/schedulingDataView.vue";
import MasterScheduleDataView from "../views/MasterSchedulingDataView.vue";

import ProcessSetView from "../components/ProcessSets/processSetView.vue";
import MasterNewDataView from "../views/MasterNewDataView.vue";
import UserConfigView from "../views/UserConfigView.vue";
import CostElementComponent from "../components/CostHeader/CostElementManager.vue";
import GroupAdmin from "../components/UserMgmt/GroupAdmin.vue";
import ComponentAccess from "../components/UserMgmt/ComponentAccess.vue";
import processSetViewManager from "../components/ProcessSetsData/processSetView.vue";
import processSetUIManager from "../components/ProcessSetsUI/processSetUIView.vue";
import store from "../store";
import Gantt from "../components/Gantt/GanttComponent.vue";
import IntegrateDataView from "../views/IntegrateDataView.vue";
import AnalyticsMasterView from "../components/Analytics/AnalyticsMasterView.vue";
import ExternalSystemAccess from "../components/UserMgmt/ExternalSystemAccess.vue";
import AuthLanding from "../components/AuthLanding/AuthLanding.vue";
Vue.use(VueRouter);
Vue.use(Datetime);

const DEFAULT_TITLE = "JIVE Connect Console";
const routes = [
  // {
  //   path: "/",
  //   name: "Dashboard",
  //   component: DashBoardsMenu,
  //   meta: { title: "Dashboard - " + DEFAULT_TITLE, requiresAuth: true },
  // },
  {
    path: "/Analytics/:id",
    name: "Analytics",
    component: AnalyticsMasterView,
    meta: { title: "Analytics" - +DEFAULT_TITLE, requiresAuth: true },
  },

  {
    path: "/Dashboard/:id",
    name: "Dashboard",
    component: DashBoardsMenu,
    meta: { title: "Dashboards - " + DEFAULT_TITLE, requiresAuth: true },
  },
  {
    path: "/craneLoading",
    name: "craneLoading",
    component: craneLoading,
    meta: {},
  },

  {
    path: "/Gantt",
    name: "Gantt",
    component: Gantt,
    meta: { title: "Schedule Gantt", requiresAuth: true },
  },

  {
    path: "/Views/:id",
    name: "Views",
    component: Views,
    meta: { title: "User Views - " + DEFAULT_TITLE, requiresAuth: true },
  },
  {
    path: "/Data/:id",
    name: "Data",
    component: MasterDataView,
    meta: { title: "Data" - +DEFAULT_TITLE, requiresAuth: true },
  },

  /*  {
    path: "/Scheduling",
    name: "Scheduling",
    component: MasterScheduleDataView,
    meta: { title: "Data" - +DEFAULT_TITLE },
  },
 */
  /*   {
    path: "/Scheduling",
    name: "scheduling",
    component: MasterSpendingCurveView,
    meta: { title: "Data" - +DEFAULT_TITLE },
  }, */

  {
    path: "/Masterdata/:id",
    name: "MasterData",
    component: MasterNewDataView,
    meta: { title: "MasterData" - +DEFAULT_TITLE, requiresAuth: true },
  },

  {
    path: "/Integrate/:id",
    name: "Integrate",
    component: IntegrateDataView,
    meta: { title: "Integrate" - +DEFAULT_TITLE, requiresAuth: true },
  },

  {
    path: "/Structure",
    name: "Structure",
    component: MasterStructureView,
    meta: { title: "Structure" - +DEFAULT_TITLE, requiresAuth: true },
  },

  {
    path: "/CostElement",
    name: "Cost Element",
    component: CostElementComponent,
    meta: { title: "Cost Element" - +DEFAULT_TITLE, requiresAuth: true },
  },

  {
    path: "/SpendingCurve",
    name: "SpendingCurve",
    component: MasterSpendingCurveView,
    meta: { title: "Spending Curve" - +DEFAULT_TITLE, requiresAuth: true },
  },
  {
    path: "/ClientAdmin",
    name: "ClientAdmin",
    component: ClientAdminView,
    meta: { title: "ClientAdmin", requiresAuth: true },
  },
  {
    path: "/UserMgmt",
    name: "UserMgmt",
    component: UserMgmt,
    meta: { title: "User Management", requiresAuth: true },
  },
  {
    path: "/ExternalSystem",
    name: "ExternalSystem",
    component: ExternalSystemAccess,
    meta: { title: "External System", requiresAuth: true },
  },
  {
    path: "/Settings/:id",
    name: "Settings",
    component: UserConfigView,
    meta: { title: "Settings" - +DEFAULT_TITLE, requiresAuth: true },
  },

  {
    path: "/UserAdmin",
    name: "User Admin",
    component: UserAdmin,
    meta: { title: "User Management", requiresAuth: true },
  },
  /* {
    path: "/scheduling",
    name: "Scheduling",
    component: schedulingDataManager,
    meta: { title: "Scheduling" },
  }, */
  /* {
    path: "/processsets",
    name: "ProcessSets",
    component: ProcessSetView,
    meta: { title: "Process Sets", requiresAuth: true },
  }, */

  /*   {
    path: "/Integrate/processsetData",
    name: "ProcessSetData",
    component: processSetViewManager,
    meta: { title: "Process Set DataView", requiresAuth: true },
  }, */
  {
    path: "/processsetUI",
    name: "ProcesssetUI",
    component: processSetUIManager,
    meta: { title: "Processset Data UI", requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: "Login - " + DEFAULT_TITLE, requiresAuth: false },
  },
  {
    path: "/groups",
    name: "Groups",
    component: GroupAdmin,
    meta: { title: "Group Admin", requiresAuth: true },
  },

  {
    path: "/groupsaccess",
    name: "GroupsAccess",
    component: ComponentAccess,
    meta: { title: "Group Access", requiresAuth: true },
  },
  {
    path: "/oauth2/accessCode",
    name: "AccessCode",
    component: AuthLanding,
    meta: { title: "Access Code", requiresAuth: true },
  },
  {
    path: "*",
    name: "404",
    component: C404, // todo: create 404 component
    meta: {
      title: "404: Page Not Found - " + DEFAULT_TITLE,
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");
  if (!loggedIn && to.path != "/login") {
    next("/login");
  } else if (to.meta.requiresAuth && !loggedIn) {
    next("/login");
  } else {
    //console.log("asdsa", to);
    switch (to.name.toLowerCase()) {
      case "analytics":
        console.log("Setting Analytics: ", to.params.id);
        store.dispatch(
          "analyticsDataManager/setSelectedAnalyticDataByName",
          to.params.id
        );
        break;
      case "dashboard":
        console.log("Setting Dashboard: ", to.params.id);
        store.dispatch("dashboardMenu/setSelectedDashboardId", to.params.id);
        break;
      case "data":
        console.log("To", to);
        store.dispatch("dataManager/setSelectedData", to.params.id);
        break;
      case "integrate":
        console.log("Setting Integrate: ", to.params.id);
        store.dispatch("processSetDataManager/setSelectedData", to.params.id);
        break;
      case "masterdata":
        console.log("Setting MasterData: ", to.params.id);
        store.dispatch("structureManager/setSelectedData", to.params.id);
        break;
      case "settings":
        console.log("Setting: ", to.params.id);
        store.dispatch("userManagement/setSelectedData", to.params.id);
        break;
      case "views":
        store.dispatch("userView/setSelectedViewId", to.params.id);
        break;
    }
    if (to.path == "/") {
      let temprouteLink = localStorage.getItem("pageRoute");
      let tempComoponentCode = localStorage.getItem("componentCode");
      if (
        (JSON.parse(temprouteLink) || tempComoponentCode != "undefined") &&
        (temprouteLink != "undefined" || JSON.parse(tempComoponentCode))
      ) {
        //console.log(temprouteLink);
        if (tempComoponentCode == "null" || tempComoponentCode == "undefined") {
          return next("/" + JSON.parse(temprouteLink));
        } else
          return next(
            "/" +
              JSON.parse(temprouteLink) +
              "/" +
              JSON.parse(tempComoponentCode)
          );
      } else next();
    }

    // set title bar
    Vue.nextTick(() => {
      document.title = to.meta.title || DEFAULT_TITLE;
    });

    if (to.path == "/logout") {
      userService.logout();
      return next("/login");
    } else next();
  }
});
