import { userService } from "@/api/user-auth";

const state = {
  fromDate: null,
  toDate: null,
  reportDate: null,
  tmpFromDate: null,
  tmpToDate: null,
  tmpReportDate: null,
  isMenuExpanded: false,
  isStructureFilterModalVisible: false,
  portfolios: [],
  programs: [],
  projects: [],
  selectedPortfolios: [],
  selectedPrograms: [],
  selectedProjects: [],
  filterWatcher: 0,
  isSave: false,
};

const actions = {
  updateFilters({ commit }) {
    userService.getJson("/JIVEUser/GetP3MFilter").then((result) => {
      if (result.succeeded && result.data != null) {
        let newFilters = result.data;
        newFilters.portfolios.sort((a, b) => (a.code > b.code ? 1 : -1));
        newFilters.programs.sort((a, b) => (a.code > b.code ? 1 : -1));
        newFilters.projects.sort((a, b) => (a.code > b.code ? 1 : -1));
        // add defaults -1 into the mix;
        newFilters.portfolios.splice(0, 0, {
          description: "All Portfolios",
          id: -1,
        });
        newFilters.programs.splice(0, 0, {
          description: "All Programs",
          id: -1,
        });
        newFilters.projects.splice(0, 0, {
          description: "All Projects",
          id: -1,
        });
        commit("mutateFilters", {
          filters: newFilters,
        });
      }
    });
  },
  saveFilters({ dispatch, commit, getters }, { filters }) {
    commit("saveFilters", { filters: filters });
    state.toDate = state.tmpToDate;
    state.fromDate = state.tmpFromDate;
    state.reportDate = state.tmpReportDate;
    // NOTE: use of `getter.filters`, this is used to prevent un-necessary clauses
    userService
      .post(`/JIVEUser/UpdateP3MFilter/`, getters.filters)
      .then((result) => {
        if (result.succeeded) {
          commit("requestFilterUpdate");
        } else {
          dispatch("alert/error", result.message, { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
    //userService.post(`/filters/${clientId}/`, getters.filters);
    // TODO: potentially change this once `selectAll` is implemented
  },
};

const mutations = {
  resetCurrentDate(state) {
    state.tmpFromDate = state.fromDate;
    state.tmpToDate = state.toDate;
    state.tmpReportDate = state.reportDate;
  },
  mutateDateRange(state, { fromDate, toDate, reportDate }) {
    state.tmpFromDate = fromDate ? fromDate : state.tmpFromDate;
    state.tmpToDate = toDate ? toDate : state.tmpToDate;
    state.tmpReportDate = reportDate ? reportDate : state.tmpReportDate;
    // if (fromDate) {
    //   state.fromDate = fromDate;
    // }
    // if (toDate) {
    //   state.toDate = toDate;
    // }
    // if (reportDate) {
    //   state.reportDate = reportDate;
    // }
  },
  clearFromDate(state) {
    //state.fromDate = null;
    state.tmpFromDate = null;
  },
  clearToDate(state) {
    //state.toDate = null;
    state.tmpToDate = null;
  },
  clearReportDate(state) {
    //state.reportDate = null;
    state.tmpReportDate = null;
  },
  toggleMenuExpanded(state) {
    state.isMenuExpanded = !state.isMenuExpanded;
  },
  toggleStructureFilterModal(state) {
    state.isStructureFilterModalVisible = !state.isStructureFilterModalVisible;
  },
  mutateFilters(state, { filters }) {
    state.portfolios = filters.portfolios;
    state.programs = filters.programs;
    state.projects = filters.projects;
    state.selectedPortfolios = filters.selectedPortfolios;
    state.selectedPrograms = filters.selectedPrograms;
    state.selectedProjects = filters.selectedProjects;
    state.fromDate = filters.from;
    state.toDate = filters.to;
    state.reportDate = filters.report;

    state.tmpFromDate = filters.from;
    state.tmpToDate = filters.to;
    state.tmpReportDate = filters.report;
    // Force users to pick at least one filter
    if (
      state.selectedPortfolios.length < 1 &&
      state.selectedPrograms.length < 1 &&
      state.selectedProjects.length < 1
    ) {
      state.isStructureFilterModalVisible = true;
    }
    state.filterWatcher += 1;
  },
  saveFilters(state, { filters }) {
    state.selectedPortfolios = filters.selectedPortfolios;
    state.selectedPrograms = filters.selectedPrograms;
    state.selectedProjects = filters.selectedProjects;
  },
  requestFilterUpdate(state) {
    state.filterWatcher += 1;
  },
  resetFilterCounter(state) {
    state.filterWatcher = 0;
  },
};

const getters = {
  filters({
    fromDate,
    toDate,
    reportDate,
    selectedPortfolios,
    selectedPrograms,
    selectedProjects,
    isSave,
  }) {
    return {
      Portfolios: selectedPortfolios,
      Programs: selectedPrograms,
      Projects: selectedProjects,
      From: fromDate,
      To: toDate,
      Report: reportDate,
      isSave: isSave,
    };
  },
};

export const filterControl = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
