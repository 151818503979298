import { post } from "../api/user-auth.js";
import { getAllExtraColumnDetails, DeleteElement } from "@/api/networking.js";

/* Create Tree Path */
let data = [],
  i = -1,
  tempData = null;
function getParentLoop(id, path) {
  let p = data.filter((i) => i.id == id)[0];
  if (p.parentId) {
    path.unshift(p.id);
    return getParentLoop(p.parentId, path);
  } else {
    path.unshift(p.id);
  }
  return path;
}

function setpath(data) {
  data.map((i) => {
    i.path = [];
    if (i.parentId) {
      i.path.push(i.id);
      getParentLoop(i.parentId, i.path);
    } else {
      i.path.push(i.id);
    }
    return i;
  });
  return data;
}
const state = {
  /* Module Title*/
  APIName: "RiskSeverity",
  /*left Side(Children grid details)*/
  /*CurrentData and details*/
  currentChildrenData: [],
  currentchildTempData: [],
  currentChildrenId: null,
  currentChildrenSelectedObj: [],
  currentParentID: [],
  currentselectedChildrenRows: [],
  cuurentcopyRowvalues: [],

  /*Main Data Confing Tools*/
  currentChildrenLoadRecordCounter: 0,
  currentChildrenAddRecordCounter: 0,
  currentChildrenSubRecordCounter: 0,
  currentChildrenDeleteRecordCounter: 0,
  currentChildrenSaveRecordCounter: 0,

  /*Children Node Grid Config*/
  currentChildrenUndoWatcher: 0,
  currentChildrenRedoWatcher: 0,
  currentChildrenredostate: false,
  currentChildrenundostate: false,

  /*Children Node Grid Config*/
  isRelationship: false,
  currentCostModelType: [],

  /*Commonn Config*/
  isSave: 0,
  isDelete: 0,
  datawatcher: 0,
  SaveError: 0,
  duplicateRecord: 0,

  /* Children Column Def */
  currentChildrenColDef: [
    /*   {
      headerName: "",
      rowDrag: true,
      field: "id",
      suppressPaste: true,
      //   cellRendererFramework:"RiskSeverityCellRenderer",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        // innerRenderer: "RiskSeverityMetricCellRenderer",
      },
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      width: 160,
      icons: {
        sortAscending: '<i class="fa fa-sort-alpha-up"/>',
        sortDescending: '<i class="fa fa-sort-alpha-down"/>',
        menu: '<i class="fa fa-caret-square-down"/>',
      },
    },
    {
      headerName: "Code",
      field: "code",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 90,
      suppressSizeToFit: true,
      cellRenderer: function(params) {
        if (params.data.rowUpdated == "Duplicate") {
          let eIconGui = document.createElement("span");
          return (eIconGui.innerHTML =
            '<i class="fas fa-exclamation" style="color:red;"></i>' +
            " " +
            params.data.code);
        }
        return params.data.code;
      },
    },
    {
      headerName: "Description",
      field: "description",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 300,
    },
    {
      headerName: "Colour",
      field: "colour",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        return { "background-color": params.value, color: params.value };
      },
      valueFormatter: function(params) {
        if (params.value) {
          return params.values;
        }
        return null;
      },
      width: 100,
      cellEditor: "riskSeveritycolorPicker",
    },
    {
      headerName: "Value",
      field: "value",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 100,
    },
    {
      headerName: "SortOrder",
      field: "sortOrder",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 100,
    }, */
  ],
  loadStructureCounter: 0,
  currentGridColDefCount: 0,
  currentChildrenGridColDefCountCounter: 0,
  tempCalenarData: [],
  tempCalenderCounter: 0,
  tempResourceTypeData: [],
  tempResourceCounter: 0,
  tempData: [],
};

const actions = {
  /*LoadData */
  async loadRiskSeverity({ dispatch, commit }) {
    getAllExtraColumnDetails(state.APIName)
      .then((rowData) => {
        if (rowData.succeeded) {
          commit("setChildrenData", { returnedData: rowData.data });
          i = -2;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
  /*Extra Column */
  async LoadExtraColumnValue({ dispatch }, TabelType) {
    getAllExtraColumnDetails(TabelType + "ExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentChildrenColDef = []; //state.currentChildrenColDef.slice(0, 5);

          rowData.data.forEach((y) =>
            state.currentChildrenColDef.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 100,
              editable: true,
              cellStyle: function (params) {
                if (params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                }
                if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return new Date(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.currentChildrenGridColDefCountCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /**Delete Cost Element */
  async DeleteData({ dispatch }, { Id }) {
    DeleteElement(state.APIName, Id)
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentChildrenData = state.currentChildrenData.filter(
            (x) => x.id != Id
          );
          state.currentChildrenId = null;
          state.currentChildrenSelectedObj = [];
          state.currentChildrenUndoWatcher = 0;
          state.currentChildrenRedoWatcher = 0;
          state.currentChildrenredostate = false;
          state.currentChildrenundostate = false;
          state.isDelete++;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /**Save Cost Element */
  async saveData({ dispatch, commit }) {
    var rowsToInsert = [];
    var rowsToUpdate = [];
    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {};

    state.currentChildrenData.forEach((row) => {
      if (row.rowUpdated) {
        if (row.id <= -1) rowsToInsert.push(row);
        else {
          rowsToUpdate.push({
            Id: row.id,
            code: row.code,
            colour: row.colour,
            value: row.value,
            sortOrder: row.sortOrder,
            description: row.description,
            ExtraColumnValues: row.extraColumnValues,
            //  parentId: row.parentId,
          });
        }
      }
    });

    if (rowsToInsert.length > 0) {
      createURL = state.APIName + "/Create";

      createObj = { RiskSeverityToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = state.APIName + "/Update";

      updateObj = { RiskSeverityToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          /* if (values)
                        returnedValues = returnedValues.concat(values.data); */
          commit("setSavedChildrenData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = post(updateURL, updateObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          commit("setSavedChildrenData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
    i = -1;
  },
};

const mutations = {
  /*left Side(Children grid details)*/
  /*CurrentData and details*/

  setChildrenData(state, { returnedData }) {
    returnedData.forEach((row) => {
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.riskSeverityExtraColumnId] = y.value)
        );
      }
    });
    data = returnedData;
    tempData = setpath(data);
    state.currentChildrenData = returnedData;
    /*Reset the Children Grid property */
    state.currentChildrenId = null;
    state.currentChildrenSelectedObj = [];
    state.currentChildrenDeleteRecordCounter = 0;
    state.currentChildrenUndoWatcher = 0;
    state.currentChildrenRedoWatcher = 0;
    state.currentChildrenredostate = false;
    state.currentChildrenundostate = false;
    state.currentChildrenAddRecordCounter = 0;
    state.currentChildrenLoadRecordCounter++;
  },

  setSavedChildrenData(state, { returnedData }) {
    //returnedData.forEach(row => (row.rowUpdated = false));
    returnedData.forEach((row) => {
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.riskSeverityExtraColumnId] = y.value)
        );
      }
    });
    data = returnedData;
    tempData = setpath(data);
    state.currentchildTempData = returnedData;
    /*Reset the Children Grid property */
    state.currentChildrenUndoWatcher = 0;
    state.currentChildrenRedoWatcher = 0;
    state.currentChildrenredostate = false;
    state.currentChildrenundostate = false;
    state.datawatcher++;
  },

  setChildrenObj(state, row) {
    state.currentChildrenSelectedObj = row;
    state.currentChildrenId = row.id;
  },

  setSelectedChildrenRowObject(state, row) {
    state.currentselectedChildrenRows = row;
    //state.currentChildrenSelectedObj = row;
    // state.currentChildrenId = row.id;
  },
  setCurrentDate(state, rows) {
    rows.forEach((row) => {
      row.rowUpdated = false;
    });
    state.currentChildrenData = rows;
  },

  /*mutate counter for CRUD*/
  mutateChildrenAddRecord(state) {
    state.currentChildrenAddRecordCounter++;
    /* state.currentChildrenData.push({
      id: -1,
      code: "",
      description: "",
      colour: "",
      value: "",
      sortOrder: "",
      rowUpdated: true,
      extraColumnValues: [],
    }); */
  },

  mutateChildrenDelete(state) {
    state.currentChildrenDeleteRecordCounter++;
  },

  mutateChildrenSave(state) {
    state.currentChildrenSaveRecordCounter++;
  },

  /*Children Grid Config*/
  mutateChildrenRedo(state) {
    state.currentChildrenRedoWatcher++;
  },

  mutateChildrenUndo(state) {
    state.currentChildrenUndoWatcher++;
  },

  mutateChildrenRedostate(state, value) {
    state.currentChildrenredostate = value;
  },

  mutateChildrenUndostate(state, value) {
    state.currentChildrenundostate = value;
  },

  mutateElementRealtionship(state, isRelationship) {
    state.isRelationship = isRelationship;
  },
};

const getters = {
  /*left Side(Children grid details)*/
  /*CurrentData and details*/
  getChildrenGridColumn() {
    return state.currentChildrenColDef;
  },

  getChildrenCurrentData() {
    return state.currentChildrenData;
  },

  getChildTempData() {
    return state.currentchildTempData;
  },

  getChildrenSelectedObj() {
    return state.currentChildrenSelectedObj;
  },
  getChildrenSelectedId() {
    return state.currentChildrenId;
  },
  /*Extra Config */
  getCostModelTypeData() {
    return state.currentCostModelType;
  },
  getcostHeaderStatus() {
    return state.isRelationship;
  },
  getAllStructureDetailsvalue() {
    return state.tempstructuredetails;
  },

  getCopiedRows() {
    return state.cuurentcopyRowvalues;
  },
  getCalenderData() {
    return state.tempCalenarData;
  },
  getResourceTypeData() {
    return state.tempResourceTypeData;
  },
};

export const riskSeverityController = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
