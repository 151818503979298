import { userService } from "@/api/user-auth";
import { post } from "@/api/user-auth.js";
import * as moment from "moment";
import router from "../router";
import { getAllExtraColumnDetails, DeleteElement } from "@/api/networking.js";

let tempProjectParent = [
    {
      uid: 1,
      id: 1,
      code: "Unassigned ",
      description: "Unassigned ",
      rowType: 2,
      path: [1],
    },
  ],
  tempProgramParent = [],
  temprow = null;
let AddNewRowId = -1,
  tempCopyCounter = 0;
const state = {
  entitiesLoaded: false,
  localAllEntities: [],
  selectedEntitites: [],
  localAllChildEntities: [],
  selectedAllChildEntities: [],
  selectedDataType: null,
  allDataTypes: [
    {
      id: "SCHEDULEHEADER",
      name: "Schedule Header",
      iconClass: "fas fa-folder-open",
      apiPrefix: "ScheduleHeader",
    },
    /* {
      id: "SPENDINGCURVEHEADER",
      name: "Spending Curve Header",
      iconClass: "fas fa-folder-open",
      apiPrefix: "SpendingCurve",
    }, */
  ],
  currentParentColDefs: [],

  /*CurrentData and details*/
  currentParentTempRow: [],
  currentParentId: null,
  currentParentSelectedObj: [],
  currentSavedChildrenData: [],

  /*Main Data Confing Tools*/
  currentParentColumDefCounter: 0,
  currentParentLoadRecordCounter: 0,
  currentParentAddRecordCounter: 0,
  currentParentDeleteRecordCounter: 0,
  currentParentSaveRecordCounter: 0,

  /*Parent Node Grid Config*/
  currentParentUndoWatcher: 0,
  currentParentRedoWatcher: 0,
  currentParentredostate: false,
  currentParentundostate: false,

  /*Parent Node Grid Config*/
  isRelationship: false,
  datawatcher: 0,
  isDelete: 0,
  tempCalenarData: null,
  totalRowLength: 0,

  /*Copy Gantt Var */
  tempScheduleRecord: [],
  isCopyButtonClicked: false,
  tempCopyRecordIndex: [],
};

const actions = {
  /* Load Calendar */
  async LoadCalendarColumn({ dispatch }) {
    getAllExtraColumnDetails("Calendar")
      .then((rowData) => {
        if (rowData.succeeded) {
          state.tempCalenarData = rowData.data;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, {
          root: true,
        });
      });
  },

  /*Extra Column */
  async LoadParentExtraColumn({ dispatch }) {
    state.currentParentColDefs = [];
    getAllExtraColumnDetails("ScheduleHeaderExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          rowData.data.forEach((y) =>
            state.currentParentColDefs.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 120,
              editable: true,
              filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: "OR",
              },
              filter: "agSetColumnFilter",
              cellStyle: function (params) {
                if (params.data != undefined && params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                } else if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return new Date(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.currentParentColumDefCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, {
          root: true,
        });
      });
  },
  loadAndSetEntity({ commit, dispatch }, id) {
    state.selectedEntitites.push(new { id: id }());
  },
  setSelectedEntity({ commit, dispatch }, id) {
    state.selectedEntitites = [];
    let isRecordValid = state.localAllEntities.find((x) => x.id == id);
    if (isRecordValid == undefined) {
      state.selectedEntitites.push(
        state.currentSavedChildrenData.find((x) => x.id == id)
      );
    } else {
      state.selectedEntitites.push(isRecordValid);
    }
  },
  setSelectedEntityAfterRefresh({ commit, dispatch }, entityRow) {
    state.selectedEntitites = [];
    state.selectedEntitites = [entityRow];
  },
  loadAllData({ commit }) {
    if (!isValid(state.selectedDataType))
      state.selectedDataType = state.allDataTypes[0];
    state.entitiesLoaded = false;
    state.tempCopyRecordIndex = [];
    userService
      .getJson(
        state.selectedDataType.apiPrefix + `/GetAllScheduleHeaderWithP3MQuery`
      )
      .then((result) => {
        if (result.succeeded && result.data != null) {
          commit("mutateAllEntities", result.data);
          state.entitiesLoaded = true;
        }
      })
      .catch((error) => {
        //dispatch("alert/error", error, { root: true });
      });
  },
  loadChildData({ commit }) {
    if (!isValid(state.selectedDataType))
      state.selectedDataType = state.allDataTypes[0];
    state.entitiesLoaded = false;
    userService
      .getJson(`Activity/GetByScheduleHeaderIdForGantt/`)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          commit("mutateAllEntities", result.data);
          state.entitiesLoaded = true;
        }
      })
      .catch((error) => {
        //dispatch("alert/error", error, { root: true });
      });
  },

  /**Delete Schedule Element */
  async DeleteParentNode({ dispatch }, { Id }) {
    DeleteElement("ScheduleHeader", Id)
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentParentId = null;
          state.currentParentSelectedObj = [];
          state.currentParentUndoWatcher = 0;
          state.currentParentRedoWatcher = 0;
          state.currentParentredostate = false;
          state.currentParentundostate = false;
          state.isDelete++;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async saveData({ dispatch, commit }) {
    var rowsToInsert = [];
    var rowsToUpdate = [];
    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {};

    state.currentParentTempRow.forEach((row) => {
      if (row.code != undefined) {
        if (row.rowUpdated) {
          if (row.id <= -1) {
            row.ChildStructureDetails = row.path;
            //delete row.path;
            row.calendarId = row.tempcalendarId;
            rowsToInsert.push(row);
          } else {
            rowsToUpdate.push({
              Id: row.id,
              code: row.code,
              description: row.description,
              programs: row.programs,
              projects: row.projects,
              scheduleHeaderTypeId: row.scheduleHeaderTypeId,
              // objectRelationships: row.objectRelationships,
              ExtraColumnValues: row.extraColumnValues,
              startDate: row.startDate,
              endDate: row.endDate,
              isActive: row.isActive,
              calendarId: row.tempcalendarId,
            });
          }
        }
      } else {
        state.SaveError++;
        rowsToInsert = [];
        rowsToUpdate = [];
        return false;
      }
    });

    if (rowsToInsert.length > 0) {
      createURL = "ScheduleHeader/Create";

      createObj = { scheduleHeadersToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = "ScheduleHeader/Update";

      updateObj = { ScheduleHeadersToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          /* if (values)
                    returnedValues = returnedValues.concat(values.data); */
          commit("setSavedParentData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = post(updateURL, updateObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = returnedValues.concat(values.data);
          commit("setSavedParentData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
  },

  /*Save Copy Record only */
  saveCopyRecord({ dispatch, commit }) {
    var rowsToInsert = [];
    var createP = Promise.resolve(1);
    var createURL = "";
    var createObj = {};
    state.isCopyButtonClicked = true;

    state.currentParentTempRow.forEach((row) => {
      if (row.code != undefined) {
        if (row.rowUpdated) {
          if (row.id <= -1) {
            row.ChildStructureDetails = row.path;
            //delete row.path;
            row.calendarId = row.tempcalendarId;
            rowsToInsert.push(row);
          }
        }
      } else {
        state.SaveError++;
        rowsToInsert = [];
        rowsToUpdate = [];
        return false;
      }
    });

    if (rowsToInsert.length > 0) {
      createURL = "ScheduleHeader/Create";

      createObj = { scheduleHeadersToCreate: rowsToInsert };
    }
    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          state.tempScheduleRecord = returnedValues[0];
          state.tempCopyRecordIndex.push(returnedValues[0].id);
          commit("setSavedParentData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
  },
};
function isValid(valueToCheck) {
  if (
    String(valueToCheck) != "" &&
    String(valueToCheck) != "null" &&
    String(valueToCheck) != "undefined"
  )
    return true;
  else {
    return false;
  }
}

const mutations = {
  mutateSchedulingDataType(state, selectedDataTypeCode) {
    state.selectedDataType = state.allDataTypes.find(
      (x) => x.id === selectedDataTypeCode
    );
  },
  mutateAllEntities(state, result) {
    let newRows = [],
      tempid = 2;
    result.forEach(function (row) {
      if (row != undefined) {
        row.rowUpdated = false;
        if (
          row.extraColumnValues != null ||
          row.extraColumnValues != undefined
        ) {
          row.extraColumnValues.forEach((y) => (row["et_" + y.id] = y.value));
        }
        if (row.calendarId != null) {
          row.tempcalendarId = row.calendarId;
          var tempdata = state.tempCalenarData.filter(
            (x) => x.id == row.calendarId
          );
          row.calendarId = tempdata[0].description;
        }

        if (row.projects != null) {
          row.projects.forEach((parentProjects) => {
            temprow = { ...row };
            //  tempid++;
            //temprow = row;
            temprow.uid = ++tempid;
            temprow.parentId = parentProjects.id;
            temprow.path = [parentProjects.id, temprow.uid];
            newRows.push(temprow);
            let TempIndex = tempProjectParent.findIndex(
              (x) => x.id == parentProjects.id
            );
            if (TempIndex == -1) {
              //tempid++;
              parentProjects.uid = ++tempid;
              parentProjects.rowType = 2;
              parentProjects.parentId = null;
              parentProjects.path = [parentProjects.id];
              tempProjectParent.push(parentProjects);
            }
          });
        }
        if (row.programs != null) {
          row.programs.forEach((parentPrograms) => {
            // tempid++;
            temprow = { ...row };
            temprow.uid = ++tempid;
            temprow.parentId = parentPrograms.id;
            temprow.path = [parentPrograms.id, temprow.uid];
            newRows.push(temprow);
            let TempIndex = tempProgramParent.findIndex(
              (x) => x.id == parentPrograms.id
            );
            if (TempIndex == -1) {
              // tempid++;
              parentPrograms.uid = ++tempid;
              parentPrograms.rowType = 1;
              parentPrograms.parentId = null;
              parentPrograms.path = [parentPrograms.id];
              tempProgramParent.push(parentPrograms);
            }
          });
        }
        if (row.projects == null && row.programs == null) {
          //tempid++;
          row.uid = ++tempid;
          row.parentId = 1;
          // row.rowType = 3;
          row.path = [1, tempid];
          temprow = { ...row };
          newRows.push(temprow);
        }
      }
    });
    result = newRows;
    tempProjectParent.forEach((row) => {
      result.push(row);
    });
    tempProgramParent.forEach((row) => {
      result.push(row);
    });
    state.totalRowLength = result.length;
    state.localAllEntities = result.sort((a, b) => (a.uid > b.uid ? 1 : -1));
    //state.localAllEntities = result.sort((a, b) => (a.code > b.code ? 1 : -1));
    // state.localAllEntities = result;
    state.currentParentId = null;
    state.currentParentSelectedObj = [];
    state.currentParentDeleteRecordCounter = 0;
    state.currentParentUndoWatcher = 0;
    state.currentParentRedoWatcher = 0;
    state.currentParentredostate = false;
    state.currentParentundostate = false;
    state.currentParentAddRecordCounter = 0;
  },

  setSavedParentData(state, { returnedData }) {
    returnedData.forEach((row) => {
      row.rowUpdated = false;
      state.totalRowLength++;
    });
    returnedData.forEach(function (row) {
      if (row != undefined) {
        row.rowUpdated = false;
        row.rowType = 2;
        if (row.extraColumnValues != null) {
          row.extraColumnValues.forEach(
            (y) => (row["et_" + y.ScheduleHeaderId] = y.value)
          );
        }
        if (row.calendarId != null) {
          row.tempcalendarId = row.calendarId;
          //row.calendarId = tempdata[0].description;
          var tempdata = state.tempCalenarData.filter(
            (x) => x.id == row.calendarId
          );
          row.calendar = tempdata[0];
          row.calendarId = tempdata[0].description;
        }
        let tempIndex = state.currentParentTempRow.findIndex(
          (x) => x.id == row.id
        );

        state.totalRowLength++;
        if (tempIndex == -1) {
          row.parentId = 1;
          row.path = [1, state.totalRowLength];
          row.objectRelationships = [];
          row.uid = state.totalRowLength;
        } else {
          row.uid = state.currentParentTempRow[tempIndex].path[1];
          row.parentId = state.currentParentTempRow[tempIndex].parentId;
          row.path = state.currentParentTempRow[tempIndex].path;
          row.objectRelationships =
            state.currentParentTempRow[tempIndex].objectRelationships;
        }
      }
      let tempIndex = state.currentParentTempRow.findIndex(
        (x) => x.id == row.id
      );
      if (tempIndex < 0) {
        row.parentId = 1;
        row.path = [1, state.totalRowLength];
      } else {
        row.parentId = state.currentParentTempRow[tempIndex].parentId;
        row.path = state.currentParentTempRow[tempIndex].path;
        row.objectRelationships =
          state.currentParentTempRow[tempIndex].objectRelationships;
      }
    });

    state.currentSavedChildrenData = returnedData;
    /*Reset the Parent Grid property */
    state.currentParentTempRow = [];
    state.currentParentTempRow = returnedData;
    state.currentParentId = null;
    state.currentParentSelectedObj = [];
    state.currentParentUndoWatcher = 0;
    state.currentParentRedoWatcher = 0;
    state.currentParentredostate = false;
    state.currentParentundostate = false;
    state.datawatcher++;
  },

  setParentObj(state, { row }) {
    state.currentParentSelectedObj = row;
    state.currentParentId = row.id;
  },

  /*mutate counter for CRUD*/
  muatateParentAdd(state) {
    state.currentParentTempRow = [];
    AddNewRowId--;
    state.currentParentTempRow.push({
      id: AddNewRowId,
      code: "",
      description: "",
      startDate: "",
      endDate: "",
      isActive: null,
      calendarId: null,
      tempcalendarId: null,
      programs: null,
      projects: null,
      scheduleHeaderTypeId: state.currentParentSelectedObj.scheduleHeaderTypeId,
      objectRelationships: [],
      extraColumnValues: [],
      status: 0,
      type: null,
      uid: AddNewRowId,
      parentId: 1,
      path: [1, AddNewRowId],
      rowUpdated: true,
    });
    state.currentParentAddRecordCounter++;
  },

  mutateParentDelete(state) {
    state.currentParentDeleteRecordCounter++;
  },

  mutateParentSave(state) {
    state.currentParentSaveRecordCounter++;
  },

  mutuateCopyRecord(state) {
    state.currentParentTempRow = [];
    AddNewRowId--;
    let checkCopiedRecord = state.tempCopyRecordIndex.indexOf(
      state.currentParentSelectedObj.id
    );
    let ifcopiedRecordIndex = state.localAllEntities.findIndex(
      (x) => x.code == state.currentParentSelectedObj.code
    );
    let tempCode = state.currentParentSelectedObj.code,
      tempDescription = state.currentParentSelectedObj.description;

    if (checkCopiedRecord > -1 || ifcopiedRecordIndex > -1) {
      tempCopyCounter++;
      tempCode = tempCode + "_Copy (" + tempCopyCounter + ")";
      tempDescription = tempDescription + "_Copy (" + tempCopyCounter + ")";
    } else {
      tempCode = tempCode + "_Copy";
      tempDescription = tempDescription + "_Copy";
      tempCopyCounter = 0;
    }

    state.currentParentTempRow.push({
      id: AddNewRowId,
      code: tempCode,
      description: tempDescription,
      startDate: state.currentParentSelectedObj.startDate,
      endDate: state.currentParentSelectedObj.endDate,
      isActive: state.currentParentSelectedObj.isActive,
      calendarId: state.currentParentSelectedObj.calendarId,
      tempcalendarId: state.currentParentSelectedObj.tempcalendarId,
      programs: null,
      projects: null,
      scheduleHeaderTypeId: state.currentParentSelectedObj.scheduleHeaderTypeId,
      objectRelationships: [],
      extraColumnValues: [],
      status: 0,
      type: null,
      uid: AddNewRowId,
      parentId: 1,
      path: [1, AddNewRowId],
      rowUpdated: true,
    });
    state.tempCopyRecordIndex.push(state.currentParentSelectedObj.id);

    state.currentParentAddRecordCounter++;
  },

  /*Parent Grid Config*/
  mutateParentRedo(state) {
    state.currentParentRedoWatcher++;
  },

  mutateParentUndo(state) {
    state.currentParentUndoWatcher++;
  },

  mutateParentRedostate(state, value) {
    state.currentParentredostate = value;
  },

  mutateParentUndostate(state, value) {
    state.currentParentundostate = value;
  },

  mutateScheduleHeaderRealtionship(state, isRelationship) {
    state.isRelationship = isRelationship;
  },

  mutateRecord(state, { returnedNewData }) {
    state.currentParentTempRow = [];
    returnedNewData.forEach((element) => {
      if (element.rowUpdated) {
        state.currentParentTempRow.push(element);
      }
    });
    state.currentParentTempRow = returnedNewData;
  },

  restCopyButtonState(state) {
    state.isCopyButtonClicked = false;
  },
};

const getters = {
  allDataTypes(state) {
    return state.allDataTypes;
  },
  allEntities(state) {
    return state.localAllEntities;
  },
  entitiesLoaded(state) {
    return state.entitiesLoaded;
  },
  selectedScheduleDataType(state) {
    return state.selectedDataType;
  },
  selectedEntitites(state) {
    return state.selectedEntitites;
  },
  getParentColDefs(state) {
    return state.currentParentColDefs;
  },
  getParentSelectedObj() {
    return state.currentParentSelectedObj;
  },
  getParentSelectedId() {
    return state.currentParentId;
  },
  getParentAddRow() {
    return state.currentParentTempRow;
  },
  getscheduleHeaderStatus() {
    return state.isRelationship;
  },
  selectedEntityId(state) {
    return state.selectedEntitites.length > 0
      ? state.selectedEntitites[0].id
      : -1;
  },
  selectedEntity(state) {
    return state.selectedEntitites.length > 0
      ? state.selectedEntitites[0]
      : undefined;
  },
  getcalendarData() {
    return state.tempCalenarData;
  },
  getSavedData() {
    return state.currentSavedChildrenData;
  },
  getCopyRecord() {
    return state.tempScheduleRecord;
  },
};

export const schedulingDataManager = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
