<template>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-if="selectedIntegrateDataType.id == 0"
  >
    <process-set-view />
  </section>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedIntegrateDataType.id == 1"
  >
    <process-set-data-view />
  </section>
  <!-- <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedIntegrateDataType.id == 2"
  >
   <user-admin /> 
  </section> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProcessSetView from "../components/ProcessSets/processSetView.vue";
import ProcessSetDataView from "../components/ProcessSetsData/processSetView.vue";

export default {
  name: "IntegrateDataView",
  components: {
    ProcessSetView,
    ProcessSetDataView,
  },
  watch: {},
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("processSetDataManager", ["selectedIntegrateDataType"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted: function () {
    console.log(
      "Integrate Management: $route.params.id",
      this.$route.params.id,
      this.selectedIntegrateDataType.id
    );
    if (
      !this.isValid(this.selectedIntegrateDataType) ||
      this.selectedIntegrateDataType.name != this.$route.params.id
    ) {
      console.log("Loading Integrate Type");
      this.setSelectedData(this.$route.params.id);
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("processSetDataManager", ["setSelectedData"]),
    isValid(valueToCheck) {
      if (
        String(valueToCheck) != "" &&
        String(valueToCheck) != "null" &&
        String(valueToCheck) != "undefined"
      )
        return true;
      else {
        return false;
      }
    },
  },
};
</script>
