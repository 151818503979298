<template>
  <div style="height: 100%; background-color: white">
    <splitpanes class="default-theme">
      <pane style="margin-left: 0.5%" :size="prjComparePaneSize">
        <div class="rowContainer">
          <div class="item0 resizeHeader" @click.capture="setActivePane(0)">
            <p>Projects To Compare</p>
          </div>
          <div class="item1">
            <!-- <div class="left"><p>Template Details</p></div> -->
            <p>Template Details</p>
          </div>
          <div class="item2 textContainer">
            <input
              type="text"
              name="Template Name"
              autocomplete="off"
              v-model="templateDetails"
              required
            />
          </div>
          <div class="item2">
            <button
              class="button is-success"
              @click="loadProjectsFromTemplate"
              :disabled="templateDetails == ''"
            >
              Load Projects From Template
            </button>
          </div>
          <div class="configHeader item2">
            <div class="projectCodeHeader">
              <p>Project Codes</p>
              <div></div>
              <GenericToolBar
                :toolbarConfig="toolbarConfig"
                @addNew="onAddNewClick"
                @delete="onDeleteClick"
              />
            </div>
          </div>
          <div class="item3">
            <div style="background-color: white; height: 100%; width: 100%">
              <ag-grid-vue
                class="ag-theme-balham ag-default-layout"
                rowSelection="multiple"
                enableCellChangeFlash="true"
                stopEditingWhenGridLosesFocus="true"
                style="height: 100%; width: 100%"
                :gridOptions="gridOptions"
                :rowData="localProjectCodeRowData"
                :columnDefs="columnDefs"
                :overlayNoRowsTemplate="noProjectCodeRowTemplate"
                :overlayLoadingTemplate="projectCodesLoadingOverlay"
                @gridReady="onProjectCodeGridReady"
              >
              </ag-grid-vue>
            </div>
          </div>
        </div>
      </pane>
      <pane :size="prjOptionsPaneSize">
        <div class="optionsContainer">
          <div class="item0 resizeHeader" @click.capture="setActivePane(1)">
            <p>Comparison Options</p>
          </div>
          <div class="item1">
            <p>Select Environment</p>
            <select
              name="cfgEnvironment"
              id="pUserReadWrite"
              class="select"
              style="width: 100%"
              v-model="$store.state.analyticsDataManager.selectedEnv"
            >
              <option
                v-for="env in $store.state.analyticsDataManager.environments"
                :key="env.value"
                :value="env.value"
              >
                {{ env.name }}
              </option>
            </select>
          </div>
          <div class="item2">
            <div class="field">
              <div class="control">
                <label class="checkbox checkboxLabel" for="compareRoles">
                  Compare Roles
                  <input
                    type="checkbox"
                    id="compareRoles"
                    v-model="roles"
                    :disabled="!templateValid"
                  />
                </label>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <label class="checkbox">
                  Compare Groups
                  <input
                    type="checkbox"
                    id="compareGroups"
                    v-model="groups"
                    :disabled="!templateValid"
                  />
                </label>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="checkbox">
                  Compare Common UDFs
                  <input
                    type="checkbox"
                    id="compareCommonUDF"
                    v-model="commonUDF"
                    :disabled="!templateValid"
                  />
                </label>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="checkbox">
                  Compare Saved Searches
                  <input
                    type="checkbox"
                    id="compareSavedSearches"
                    v-model="savedSearches"
                    :disabled="!templateValid"
                  />
                </label>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="checkbox">
                  Compare Cost Codes
                  <input
                    type="checkbox"
                    id="compareCostCodes"
                    v-model="costCodes"
                    :disabled="!templateValid"
                  />
                </label>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="checkbox">
                  Compare Doc Types
                  <input
                    type="checkbox"
                    id="compareDocTypes"
                    v-model="docTypes"
                    :disabled="!templateValid"
                  />
                </label>
              </div>
            </div>
          </div>
          <div class="item3">
            <div style="background-color: white; height: 100%; width: 100%">
              <ag-grid-vue
                class="ag-theme-balham ag-default-layout"
                rowSelection="multiple"
                rowMultiSelectWithClick="true"
                enableCellChangeFlash="true"
                stopEditingWhenGridLosesFocus="true"
                style="height: 100%; width: 100%"
                :context="context"
                :singleClickEdit="true"
                :gridOptions="docCodesGridOptions"
                :columnDefs="docCodesColumnDefs"
                :rowData="docTypeRowData"
                :defaultColDef="docCodesDefaultColDef"
                :overlayNoRowsTemplate="docTypeNoResultsRowTemplate"
                :overlayLoadingTemplate="docCodesLoadingOverlay"
                :disabled="!templateValid"
                @row-selected="onDocCodeRowSelected"
              >
                <!-- :rowData="$store.state.analyticsDataManager.docTypeCodes" -->
              </ag-grid-vue>
            </div>
          </div>
        </div>
      </pane>
      <pane
        style="margin-right: 0.5%"
        :size="prjResultsPaneSize"
        class="optionsContainer"
      >
        <div class="item0 resizeHeader" @click.capture="setActivePane(2)">
          <p>Results</p>
        </div>
        <div class="configHeader">
          <div class="left"><p>Results</p></div>
          <div class="right">
            <button
              class="button is-success"
              @click="checkConfig"
              :disabled="canCheckConfig"
            >
              Check Configuration
            </button>
          </div>
        </div>
        <div
          class="left"
          style="background-color: white; height: 100%; width: 100%"
        >
          <!-- <DataToolBar /> -->

          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="multiple"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            defaultColumnDef="resultDefaultColumnDef"
            :gridOptions="resultsGridOptions"
            :rowData="resultRowData"
            :enableCharts="true"
            :columnDefs="resultColumnDefs"
            :enableRangeSelection="true"
            :undoRedoCellEditing="true"
            :enablePivot="true"
            :sideBar="true"
            :overlayNoRowsTemplate="noResultsRowTemplate"
          >
          </ag-grid-vue>
        </div>
        <!-- <genericGridComponent
            class="left"
            style="height: 100%; width: 100%"
            :enableCharts="true"
            :gridOptions="resultsGridOptions"
            :rowData="resultRowData"
            :columnDefs="resultColumnDefs"
            :overlayNoRowsTemplate="noResultsRowTemplate"
          >
          </genericGridComponent> -->
      </pane>
    </splitpanes>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import GenericToolBar from "@/components/Data/GenericToolbar.vue";
//import genericGridComponent from "../../GenericComponents/genericGridComponent.vue";
import { userService } from "@/api/user-auth";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import checkBoxHeader from "./CustomHeader.vue";

export default {
  name: "ProjectConfigMgmtView_New",
  props: {},
  data() {
    var self = this;
    return {
      context: {},
      noProjectCodeRowTemplate: "Add Project Codes to compare",
      noResultsRowTemplate: "Click Check Configuration to run comparison",
      docTypeNoResultsRowTemplate:
        "Select a Template, and 'Compare Doc Types' option to populate",
      runningProcessSet: false,
      docCodesLoadingOverlay: "Loading DocCodes from Template",
      projectCodesLoadingOverlay: "Loading projects created from this template",
      loadingOverlayComponent: "", //CustomLoadingRenderer,
      loadingOverlayComponentParams: null,
      templateName: "", //"MIRVAC_AC_TMP_03",
      selectedGroup: {},
      columnDefs: [
        {
          headerName: "Project Code",
          field: "code",
          sortable: true,
          resizable: true,
          editable: true,
          filterParams: { excelMode: "windows" },
        },
      ],
      resultColumnDefs: [
        {
          headerName: "Project Code",
          field: "ProjectCode",
          sortable: true,
          enableRowGroup: true,
          filter: "agSetColumnFilter",
          filterParams: {
            excelMode: "windows",
          },
        },
        {
          headerName: "Object Checked",
          field: "ObjectChecked",
          sortable: true,
          enableRowGroup: true,
          filter: "agSetColumnFilter",
          filterParams: {
            excelMode: "windows",
          },
        },
        {
          headerName: "Code",
          field: "Code",
          sortable: true,
          enableRowGroup: true,
          filter: "agSetColumnFilter",
          filterParams: {
            excelMode: "windows",
          },
        },
        {
          headerName: "Setting",
          field: "Setting",
          sortable: true,
          resizable: true,
          filter: "agSetColumnFilter",
          filterParams: {
            excelMode: "windows",
          },
        },
        {
          headerName: "Template Setting",
          field: "TemplateSetting",
          sortable: true,
          resizable: true,
          filter: "agSetColumnFilter",
          filterParams: {
            excelMode: "windows",
          },
        },
        {
          headerName: "Project Setting",
          field: "ProjectSetting",
          sortable: true,
          resizable: true,
          filter: "agSetColumnFilter",
          filterParams: {
            excelMode: "windows",
          },
        },
      ],
      resultDefaultColumnDef: {
        flex: 1,
        // allow every column to be aggregated
        enableValue: true,
        // allow every column to be grouped
        enableRowGroup: true,
        // allow every column to be pivoted
        enablePivot: true,
        sortable: true,
        filter: "agSetColumnFilter",
        filterParams: {
          excelMode: "windows",
        },
      },
      localProjectCodeRowData: [],
      componentColumnDefs: [],
      docTypeRowData: [],
      resultRowData: [],
      gridOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
          ],
        },
        processDataFromClipboard: (params) => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          const lastIndex =
            this.gridOptions.api.getModel().rowsToDisplay.length - 1;
          const focusedCell = this.gridOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;
          if (focusedIndex + params.data.length - 1 > lastIndex) {
            const resultLastIndex = focusedIndex + (params.data.length - 1);
            const addRowCount = resultLastIndex - lastIndex;
            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }
            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];
            rowsToAdd.map((r) => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map((i) => {
                row[currColumn.colDef.field] = i;
                currColumn =
                  this.gridOptions.columnApi.getDisplayedColAfter(currColumn);
              });
              (row.id = -1), newRowData.push(row);
              this.localProjectCodeRowData.push(row);
            });

            this.gridOptions.api.applyTransactionAsync({
              add: newRowData,
            });
            this.gridOptions.api.redrawRows();
          }
          this.gridOptions.api.refreshCells();
          return params.data;
        },
      },
      resultsGridOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
          ],
        },
      },
      frameworkComponents: null,
      docCodesColumnDefs: null,
      docCodesDefaultColDef: {
        sortable: true,
        resizable: true,
        filter: "agSetColumnFilter",
        filterParams: {
          excelMode: "windows",
        },
      },
      docCodesGridOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
          ],
        },
      },
      processDataFromClipboard: function (params) {
        console.log("params", params);
        console.log("self", self);
        const data = [...params.data];
        const emptyLastRow =
          data[data.length - 1][0] === "" && data[data.length - 1].length === 1;

        if (emptyLastRow) {
          data.splice(data.length - 1, 1);
        }

        const lastIndex = self.gridOptions.api.getModel().getRowCount() - 1;
        const focusedCell = self.gridOptions.api.getFocusedCell();
        const focusedIndex = focusedCell.rowIndex;

        if (focusedIndex + data.length - 1 > lastIndex) {
          const resultLastIndex = focusedIndex + (data.length - 1);
          const numRowsToAdd = resultLastIndex - lastIndex;

          const rowsToAdd = [];
          for (let i = 0; i < numRowsToAdd; i++) {
            const index = data.length - 1;
            const row = data.slice(index, index + 1)[0];

            // Create row object
            const rowObject = {};
            let currentColumn = focusedCell.column;
            row.forEach((item) => {
              if (!currentColumn) {
                return;
              }
              rowObject[currentColumn.colDef.field] = item;
              currentColumn =
                self.gridOptions.columnApi.getDisplayedColAfter(currentColumn);
            });
            rowsToAdd.push(rowObject);
          }
          self.rowData = self.rowData.concat(rowsToAdd);
          self.gridOptions.api.applyTransaction({ add: rowsToAdd });
        }

        return data;
      },
      toolbarConfig: {
        showAddNew: true,
        showDelete: true,
      },
      miniSelectedPaneSize: 40,
      miniNonSelectedPaneSize: 10,
      maxiSelectedPaneWidth: 80,
      maxiNonSelectedPaneWidth: 50,
      prjComparePaneSize: this.miniSelectedPaneSize,
      prjOptionsPaneSize: this.miniNonSelectedPaneSize,
      prjResultsPaneSize: this.maxiNonSelectedPaneWidth,
      overlayNoRowsTemplate: null,
      tempProjectCodeRow: null,
      projectCodeGridApi: null,
    };
  },
  components: {
    AgGridVue,
    GenericToolBar,
    //genericGridComponent,
    Splitpanes,
    Pane,
    // eslint-disable-next-line vue/no-unused-components
    checkBoxHeader,
    //agColumnHeader: CustomCheckboxHeader,
  },
  mounted() {
    this.resultRowData = [];
    this.resetCompareOptions();
    this.localProjectCodeRowData = [];
    this.templateDetails = "";
    this.$store.state.analyticsDataManager.selectedEnv =
      this.$store.state.analyticsDataManager.environments[0].value;
    this.prjComparePaneSize = this.miniSelectedPaneSize;
    this.prjOptionsPaneSize = this.miniNonSelectedPaneSize;
    this.prjResultsPaneSize = this.maxiNonSelectedPaneWidth;
  },
  beforeMount() {
    const self = this;
    this.context = {
      componentParent: this,
    };
    this.docCodesColumnDefs = [
      {
        headerName: "Doc Code",
        field: "DocCode",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
      },
      {
        headerName: "Doc Type Code",
        field: "DocTypeCode",
      },
      {
        headerName: "Title",
        field: "Title",
      },
      {
        headerName: "Compare UDF",
        field: "compareUDF",
        editable: true,
        cellRenderer: "agCheckboxCellRenderer",
        cellEditor: "agCheckboxCellEditor",
        headerComponent: "checkBoxHeader",
        valueSetter: (params) => {
          console.log("params", params);
          params.data.compareUDF = params.newValue;
          if (params.data.compareUDF) params.node.setSelected(true);
          return params.data.compareUDF;
        },
      },
      {
        headerName: "Compare UDF Options",
        field: "compareUDFOption",
        editable: true,
        cellDataType: "boolean",
        cellRenderer: "agCheckboxCellRenderer",
        headerComponent: "checkBoxHeader",
        valueSetter: (params) => {
          params.data.compareUDFOption = params.newValue;
          if (params.data.compareUDFOption) params.node.setSelected(true);
          return params.data.compareUDFOption;
        },
      },
      {
        headerName: "Compare Status",
        field: "compareStatus",
        editable: true,
        cellDataType: "boolean",
        cellRenderer: "agCheckboxCellRenderer",
        headerComponent: "checkBoxHeader",
        valueSetter: (params) => {
          params.data.compareStatus = params.newValue;
          if (params.data.compareStatus) params.node.setSelected(true);
          return params.data.compareStatus;
        },
      },
      {
        headerName: "Compare Security",
        field: "compareSecurity",
        editable: true,
        cellDataType: "boolean",
        cellRenderer: "agCheckboxCellRenderer",
        headerComponent: "checkBoxHeader",
        valueSetter: (params) => {
          params.data.compareSecurity = params.newValue;
          if (params.data.compareSecurity) params.node.setSelected(true);
          return params.data.compareSecurity;
        },
      },
    ];
  },
  created() {},
  watch: {},
  methods: {
    ...mapActions("analyticsDataManager", ["resetCompareOptions"]),
    checkConfig() {
      this.setActivePane(2);
      console.log("this.runningProcessSet", this.runningProcessSet);
      console.log("this.templateDetails", this.templateDetails);
      console.log(
        "this.docCodesGridOptions.api.getSelectedRows",
        this.docCodesGridOptions.api.getSelectedRows()
      );

      var docTypeOptions = this.$store.state.analyticsDataManager.compareOptions
        .docTypes
        ? this.docCodesGridOptions.api.getSelectedRows()
        : //  ? this.docTypeRowData.filter(
          //      (x) =>
          //        x.compareUDF ||
          //        x.compareUDFOption ||
          //        x.compareStatus ||
          //        x.compareSecurity
          //    )
          [];

      if (
        !this.runningProcessSet &&
        this.templateDetails != "" &&
        this.localProjectCodeRowData.length > 0
      ) {
        this.runningProcessSet = true;
        var prjCodes = this.localProjectCodeRowData.filter((x) => x.code != "");
        this.resultsGridOptions.api.showLoadingOverlay();
        var incomingDataObj = [
          {
            inputDataName: "allTemplates",
            inputData: this.templateDetails.split(","),
          },
          {
            inputDataName: "projectsToCheck",
            inputData: this.localProjectCodeRowData.map((x) => {
              return x.code;
            }),
          },
          {
            inputDataName: "TargetEnvironment",
            inputDataType: 0,
            inputData: this.selectedEnvironment, //this.$store.state.analyticsDataManager.selectedEnv,
          },
          {
            inputDataName: "compareOptions",
            inputDataType: 0,
            inputData: this.$store.state.analyticsDataManager.compareOptions,
          },
          {
            inputDataName: "docTypeOptions",
            inputDataType: 0,
            inputData: docTypeOptions,
          },
          {
            inputDataName: "compareToProjects",
            inputData: true,
          },
        ];
        userService
          .post(`Processset/Execute`, {
            id: this.$store.state.analyticsDataManager.newCompareProcessSetId,
            IncomingData: incomingDataObj,
          })
          .then((result) => {
            if (result.succeeded && result.data != null) {
              //(result.data);
              this.resultRowData = JSON.parse(result.data);
              this.resultsGridOptions.api.hideOverlay();
            }
          })
          .catch((error) => {
            this.resultsGridOptions.api.hideOverlay();
          })
          .finally(() => {
            this.runningProcessSet = false;
          });
      }
    },
    onAddNewClick() {
      this.localProjectCodeRowData = [
        ...this.localProjectCodeRowData,
        { code: "" },
      ];
      //this.localProjectCodeRowData.push({ code: "" });
      console.log(this.localProjectCodeRowData);
      //this.projectCodeGridApi.setRowData(this.localProjectCodeRowData);
      // if (this.tempProjectCodeRow) {
      //   let ghostNode = this.getTempProjectCodeNode();
      //   console.log("ghostNode", ghostNode);
      //   console.log("this.tempProjectCodeRow", this.tempProjectCodeRow);
      //   this.startEditingNode(ghostNode);
      // } else {
      //   this.tempProjectCodeRow = {
      //     id: this.$store.state.analyticsDataManager.projectCodeRowData.length,
      //     ghost: true,
      //     code: "",
      //   };
      //   this.$store.commit(
      //     "analyticsDataManager/addProjectCode",
      //     this.tempProjectCodeRow
      //   );
      // }
    },
    onDeleteClick() {
      let newRows = [...this.localProjectCodeRowData];
      let sel = this.gridOptions.api.getSelectedRows();
      console.log("sel", sel);
      sel.forEach((x) => {
        let indToRemove = newRows.findIndex((y) => y.code == x.code);
        console.log("indToRemove", indToRemove);
        newRows.splice(indToRemove, 1);
      });
      this.localProjectCodeRowData = newRows;
      //this.gridOptions.api.applyTransaction({ remove: sel });
      console.log("this.localProjectCodeRowData", this.localProjectCodeRowData);
    },
    setActivePane(id) {
      if (id == 0) {
        this.prjComparePaneSize = this.miniSelectedPaneSize;
        this.prjOptionsPaneSize = this.miniNonSelectedPaneSize;
        this.prjResultsPaneSize = this.maxiNonSelectedPaneWidth;
      } else if (id == 1) {
        this.prjComparePaneSize = this.miniNonSelectedPaneSize;
        this.prjOptionsPaneSize = this.miniSelectedPaneSize;
        this.prjResultsPaneSize = this.maxiNonSelectedPaneWidth;
      } else if (id == 2) {
        this.prjComparePaneSize = this.miniNonSelectedPaneSize;
        this.prjOptionsPaneSize = this.miniNonSelectedPaneSize;
        this.prjResultsPaneSize = this.maxiSelectedPaneWidth;
      }
    },
    getDocCodes() {
      //this.docTypeRowData = [];
      this.docCodesGridOptions.api.showLoadingOverlay();
      var incomingDataObj = [
        {
          inputDataName: "allTemplates",
          inputData:
            this.$store.state.analyticsDataManager.templateDetails.split(","),
        },
        {
          inputDataName: "TargetEnvironment",
          inputDataType: 0,
          inputData: this.selectedEnvironment,
        },
      ];
      userService
        .post(`Processset/Execute`, {
          id: this.$store.state.analyticsDataManager.getDocCodesProcessSetId,
          IncomingData: incomingDataObj,
        })
        .then((result) => {
          this.docTypeRowData = [];
          if (result.succeeded && result.data != null) {
            //(result.data);
            var resultArr = JSON.parse(result.data);
            resultArr = resultArr.map((obj) => ({
              ...obj,
              compare: false,
              compareUDF: false,
              compareUDFOption: false,
              compareStatus: false,
              compareSecurity: false,
            }));
            console.log("resultArr", resultArr);
            this.docTypeRowData = [...resultArr];
            this.docCodesGridOptions.api.hideOverlay();
          }
        })
        .catch((error) => {
          this.docTypeRowData = [];
          this.docCodesGridOptions.api.hideOverlay();
        })
        .finally(() => {
          this.docCodesGridOptions.api.hideOverlay();
        });
    },
    getRowNodeId(data) {
      return data.id;
    },
    getTempProjectCodeNode() {
      console.log("this.projectCodeGridApi", this.projectCodeGridApi);
      return this.projectCodeGridApi.getRowNode(this.tempProjectCodeRow.id);
    },
    startEditingNode(node) {
      this.projectCodeGridApi.startEditingCell({
        rowIndex: node.rowIndex,
        colKey: "code",
      });
      this.projectCodeGridApi.flashCells({ rowNodes: [node] });
    },
    onProjectCodeRowEditingStopped(params) {
      let prjCode = params.data;
      if (prjCode.ghost) {
        //if (prjCode.Code === "") {
        //// remove empty rows from our data set
        //this.$store.commit("deleteProjectCode", { prjCode, force: true });
        //} else {
        //// commit non-empty, ghost rows
        this.$store.commit("commitProjectCode", prjCode);
        //}
      }
      this.tempProjectCodeRow = null;
    },
    onProjectCodeGridReady(params) {
      console.log("onGridReady", params);
      this.projectCodeGridApi = params.api;
      this.rowData = this.localProjectCodeRowData;
      //this.columnApi = params.api;
    },
    setAll(colId, value) {
      const nodes = [];
      this.docCodesGridOptions.api.forEachNodeAfterFilter((node) => {
        node.data[colId] = value;
        if (value == true) {
          nodes.push(node);
        }
      });
      this.docCodesGridOptions.api.setNodesSelected({
        nodes,
        newValue: true,
      });
      this.docCodesGridOptions.api.refreshCells();
    },
    docCodeCanSelect() {
      return !(
        rowNode.data.compareUDF ||
        rowNode.data.compareUDFOption ||
        rowNode.data.compareStatus ||
        rowNode.data.compareSecurity
      );
    },
    onDocCodeRowSelected(event) {
      if (
        !event.node.isSelected() &&
        (event.node.data.compareUDF ||
          event.node.data.compareUDFOption ||
          event.node.data.compareStatus ||
          event.node.data.compareSecurity)
      ) {
        event.node.setSelected(true);
      }
    },
    loadProjectsFromTemplate() {
      this.templateDetails;
      this.gridOptions.api.showLoadingOverlay();
      userService
        .post(`Processset/Execute`, {
          id: this.$store.state.analyticsDataManager
            .newGetProjectsFromTemplateProcessSetId,
          IncomingData: [
            {
              inputDataName: "templateCode",
              inputData: this.templateDetails.split(",")[0],
            },
            {
              inputDataName: "TargetEnvironment",
              inputDataType: 0,
              inputData: this.selectedEnvironment,
            },
          ],
        })
        .then((result) => {
          if (result.succeeded && result.data != null) {
            //(result.data);
            let tmpPrjList = JSON.parse(result.data);
            this.localProjectCodeRowData = [
              ...this.localProjectCodeRowData,
              ...tmpPrjList,
            ];

            this.gridOptions.api.hideOverlay();
          }
        })
        .catch((error) => {
          this.gridOptions.api.hideOverlay();
        });
      // .finally(() => {
      //   this.runningProcessSet = false;
      // });
    },
  },
  computed: {
    ...mapGetters("jiveComponents", [
      "rawEntitiesLoaded",
      "allRawComponents",
      "defaultComponentColumns",
      "allGroupAccess",
    ]),
    ...mapGetters("analyticsDataManager", [
      "selectedProcessSetId",
      "selectedEnvironment",
      "getProjectCodeRowData",
    ]),
    ...mapState({
      compareOptions: (state) => state.analyticsDataManager.compareOptions,
    }),
    templateValid() {
      return (
        this.templateDetails !== "" && this.localProjectCodeRowData.length > 0
      );
    },
    canCheckConfig() {
      return !(
        this.templateValid &&
        (this.$store.state.analyticsDataManager.compareOptions.savedSearches ||
          this.$store.state.analyticsDataManager.compareOptions.groups ||
          this.$store.state.analyticsDataManager.compareOptions.roles ||
          this.$store.state.analyticsDataManager.compareOptions.commonUDF ||
          this.$store.state.analyticsDataManager.compareOptions.docTypes ||
          this.$store.state.analyticsDataManager.compareOptions.costCodes)
      );
    },
    templateDetails: {
      get() {
        return this.$store.state.analyticsDataManager.templateDetails;
      },
      set(value) {
        //if (value !== this.$store.state.analyticsDataManager.templateDetails) {
        this.docTypeRowData = [];
        this.docTypes = false;
        //}
        this.$store.commit("analyticsDataManager/updateTemplateDetails", value);
      },
    },
    costCodes: {
      get() {
        return this.$store.state.analyticsDataManager.compareOptions.costCodes;
      },
      set(value) {
        this.setActivePane(1);
        this.$store.commit("analyticsDataManager/updateCompareOption", {
          name: "costCodes",
          boolVal: value,
        });
      },
    },
    savedSearches: {
      get() {
        return this.$store.state.analyticsDataManager.compareOptions
          .savedSearches;
      },
      set(value) {
        this.setActivePane(1);
        this.$store.commit("analyticsDataManager/updateCompareOption", {
          name: "savedSearches",
          boolVal: value,
        });
      },
    },
    groups: {
      get() {
        return this.$store.state.analyticsDataManager.compareOptions.groups;
      },
      set(value) {
        this.setActivePane(1);
        this.$store.commit("analyticsDataManager/updateCompareOption", {
          name: "groups",
          boolVal: value,
        });
      },
    },
    roles: {
      get() {
        return this.$store.state.analyticsDataManager.compareOptions.roles;
      },
      set(value) {
        this.setActivePane(1);
        this.$store.commit("analyticsDataManager/updateCompareOption", {
          name: "roles",
          boolVal: value,
        });
      },
    },
    commonUDF: {
      get() {
        return this.$store.state.analyticsDataManager.compareOptions.commonUDF;
      },
      set(value) {
        this.setActivePane(1);
        this.$store.commit("analyticsDataManager/updateCompareOption", {
          name: "commonUDF",
          boolVal: value,
        });
      },
    },
    docTypes: {
      get() {
        return this.$store.state.analyticsDataManager.compareOptions.docTypes;
      },
      set(value) {
        this.$store.commit("analyticsDataManager/updateCompareOption", {
          name: "docTypes",
          boolVal: value,
        });
        if (value) {
          this.setActivePane(1);
          console.log(
            "docTypes",
            this.$store.state.analyticsDataManager.compareOptions.docTypes
          );
          if (this.$store.state.analyticsDataManager.compareOptions.docTypes) {
            console.log("getDocCode");
            this.getDocCodes();
            //call process set
          }
        }
      },
    },
    projectCodeRowData: {
      get() {
        return this.$store.state.analyticsDataManager.projectCodeRowData;
        //return this.getProjectCodeRowData;
      },
      set(value) {
        this.$store.commit("analyticsDataManager/setProjectCodeRowData", value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.rowContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.resizeHeader {
  cursor: pointer;
  font-weight: 550;
}
.item0 {
  order: 0;
  padding: 0 0.3em 0 0.3em;
  height: 3vh;
  border-bottom: solid 3px black;
}

.item1 {
  order: 1;
  padding: 1em 0.3em 1em 0.3em;
}
.item1 p {
}

.item2 {
  order: 2;
  padding: 0 0.3em 0 0.3em;
}

.item2 button {
  order: 2;
  margin-top: 0.5em;
}

.item3 {
  order: 3;
  padding: 0 0.3em 0 0.3em;
  height: 100%;
}

.projectCodeHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

input[type="text"] {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  color: #000;
}
input:focus {
  outline: none;
}

.splitpanes.default-theme .splitpanes__pane {
  background: #ffffff;
}

nav.navbar {
  border-top: 0;
  border-bottom: 0;
}

input[type="checkbox"] {
  margin: 0 0 0 10px;
}
// .configHeader {
//   height: 5%;
//   width: 100%;
//   margin: auto;
//   vertical-align: middle;
// }

// .left {
//   display: table;
//   float: left;
//   height: 100%;
//   padding: 0.75px;
// }

// .textContainer {
//   padding: 0.25em;
// }

// .left p {
//   display: table-cell;
//   vertical-align: middle;
//   text-align: center;
// }

// .right {
//   float: right;
//   height: 100%;
// }

// .right button {
//   display: table-cell;
//   vertical-align: middle;
//   text-align: center;
// }

// .container-grid {
//   margin-top: 0.1%;
//   background-color: #fff;
//   color: #444;
//   /* Use flexbox */
//   display: flex;
// }
// .box {
//   margin-top: 0.1%;
//   color: #fff;
//   border-radius: 1px;
//   padding: 15px;
//   font-size: 150%;

//   /* Use box-sizing so that element's outerwidth will match width property */
//   box-sizing: border-box;

//   /* Allow box to grow and shrink, and ensure they are all equally sized */
//   flex: 1 1 auto;
// }
// .title {
//   font-family: Verdana;
//   font-size: 1.4vw;
// }
// .formulate-input {
//   .formulate-input-label {
//     font-weight: bold;
//   }
// }
// /* form starting stylings ------------------------------- */
// .group {
//   position: relative;
//   margin-bottom: 15px;
// }

// textarea {
//   font-size: 18px;
//   padding: 10px 10px 10px 5px;
//   display: block;
//   width: 100%;
//   border: none;
//   border-bottom: 1px solid #757575;
// }

// textarea:focus {
//   outline: none;
// }

// h1 {
//   font-family: "Trocchi", serif;
//   font-weight: normal;
//   line-height: 48px;
//   margin: 0;
// }
// /* LABEL ======================================= */
// label {
//   color: #999;
//   font-size: 18px;
//   font-weight: bold;
//   position: absolute;
//   pointer-events: none;
//   left: 15px;
//   top: 7px;
//   transition: 0.2s ease all;
//   -moz-transition: 0.2s ease all;
//   -webkit-transition: 0.2s ease all;
// }

// /* active state */
// input:focus ~ label,
// input:valid ~ label {
//   top: -10px;
//   font-size: 14px;
//   color: black;
// }

// /* BOTTOM BARS ================================= */
// .bar {
//   position: relative;
//   display: block;
//   width: 300px;
// }
// .bar:before,
// .bar:after {
//   content: "";
//   height: 2px;
//   width: 0;
//   bottom: 1px;
//   position: absolute;
//   background: #5264ae;
//   transition: 0.2s ease all;
//   -moz-transition: 0.2s ease all;
//   -webkit-transition: 0.2s ease all;
// }
// .bar:before {
//   left: 50%;
// }
// .bar:after {
//   right: 50%;
// }

// /* active state */
// input:focus ~ .bar:before,
// input:focus ~ .bar:after {
//   width: 50%;
// }

// /* HIGHLIGHTER ================================== */
// .highlight {
//   position: absolute;
//   height: 60%;
//   width: 100px;
//   top: 25%;
//   left: 0;
//   pointer-events: none;
//   opacity: 0.5;
// }

/* active state */
input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

// /* ANIMATIONS ================ */
// @-webkit-keyframes inputHighlighter {
//   from {
//     background: #5264ae;
//   }
//   to {
//     width: 0;
//     background: transparent;
//   }
// }
// @-moz-keyframes inputHighlighter {
//   from {
//     background: #5264ae;
//   }
//   to {
//     width: 0;
//     background: transparent;
//   }
// }
// @keyframes inputHighlighter {
//   from {
//     background: #5264ae;
//   }
//   to {
//     width: 0;
//     background: transparent;
//   }
// }
// ::-webkit-input-placeholder {
//   color: #999;
//   font-weight: bold;
// }
// .column {
//   box-shadow: 0.01rem 0.01rem 0.01rem rgb(241, 224, 224);
//   margin-top: 0.01rem;
// }
</style>
