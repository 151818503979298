<template>
  <div
    v-if="alert.message"
    class="is-radiusless notification top-bar-alert"
    :class="alert.type"
  >
    {{ alert.message }}
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TopAlertBar",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
};
</script>

<style scoped lang="scss">
.top-bar-alert {
  margin-bottom: 0px !important;
}
</style>
