<template>
  <span class="icon">
    <img
      v-if="iconName"
      :src="require('@/assets/icons/' + iconName + '.svg')"
      :alt="iconName"
      class="level-icon"
    />
    <!-- <span class="icon node-level-padding">{{nodeLevel}}</span> -->
  </span>
</template>

<style lang="scss" scoped>
.level-icon {
  height: 75% !important;
  width: 75% !important;
}
.node-level-padding {
  padding-left: 2.5px;
}
</style>

<script>
//https://www.ag-grid.com/vue-grid/component-cell-renderer/#simple-cell-renderer
//https://www.ag-grid.com/vue-grid/group-cell-renderer/#example-group-cellrenderer
/**
 * # cellRenderer vs cellRendererFramework
 * cellRenderer is a function that takes in params and returns HTML element
 *  -> register via ag-grid.components
 *  -> in columnDef -> cellRenderer
 *
 * cellRendererFramework is a VueComponent that produces element from template etc.
 *  -> register via `components` for Vue item. Will also need unused component es-lint-ignore
 *  -> in columnDef -> cellRendererFramework
 *
 */

//import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "LevelIconCellRenderer",
  props: ["value"],
  data() {
    return {
      iconName: "",
      nodeLevel: "",
    };
  },
  computed: {},
  methods: {},
  beforeMount() {
    /**
    pf = portfolio
    prg = program
    prj = project
    ch = cost header
    sd = structure detail
    act = activity
    ap = activity progress
    ac = actual cost element
    pc = planning cost element
    cc = commitment cost element
    fc = forecast cost element

    
     // params.value = params.data.level_name (default field name lookup)
     // params.node.level -> node level
     // params.data -> can lookup other fields or contextual info

    */

    // this.nodeLevel = this.params.node.level;
    // this.nodeLevel = this.params.data.code;
    //this.iconName = "portfolio2";
    if (this.value) {
      switch (this.value) {
        case "Portfolios":
          this.iconName = "portfolio3";
          break;
        case "Portfolio":
          this.iconName = "portfolio3";
          break;
        case "Programs":
          this.iconName = "programs3";
          break;
        case "Project":
        case "Projects":
          this.iconName = "projects3";
          break;
        case "FilterPortfolio":
          this.iconName = "portfolio2";
          break;
        case "FilterProject":
          this.iconName = "projects2";
          break;
        case "FilterProgram":
          this.iconName = "programs2";
          break;
        case "FilterDates":
          this.iconName = "dates2";
          break;
        case "RelationPortfolio":
          this.iconName = "portfolio2";
          break;
        case "RelationProject":
          this.iconName = "projects2";
          break;
        case "RelationProgram":
          this.iconName = "programs2";
          break;
        case "Structures":
          this.iconName = "structures3";
          break;
        case "Master Data":
        case "MasterData":
          this.iconName = "resources3";
          break;
        case "TransactionData":
        case "Transactional Data":
          this.iconName = "TransactionData";
          break;
        case "Costs":
          this.iconName = "estimates3";
          break;

        case "ScheduleHeader":
          this.iconName = "schedule3";
          break;

        case "Spending Curve":
        case "SpendingCurve":
          this.iconName = "budget3";
          break;

        case "Extra Column":
        case "ExtraColumn":
          this.iconName = "schedule3";
          break;
        case "Calendars":
        case "Calendar":
          this.iconName = "schedule3";
          break;

        case "Schedule Resources":
        case "ScheduleResources":
          this.iconName = "resources3";
          break;
        case "ERP":
          this.iconName = "Capacity";
          break;
        case "Risk":
          this.iconName = "Risk2";
          break;

        case "Performance Sheet":
        case "PerformanceSheet":
          this.iconName = "performance2";
          break;
        case "Settings":
        case "Setting":
          this.iconName = "performance2";
          break;
        default:
          this.iconName = null;
      }
    } else if (this.params) {
      switch (this.params.value) {
        case "pf":
          this.iconName = "portfolio2";
          break;
        case "prg":
          this.iconName = "programs2";
          break;
        case "prj":
          this.iconName = "projects2";
          break;
        case "ch":
          this.iconName = null; // TODO: cost header
          break;
        case "sd":
          this.iconName = "structures2"; // structure detail
          break;
        case "act":
          this.iconName = "activities2";
          break;
        case "ap":
          this.iconName = "dates2"; // activity progress
          break;
        case "ac":
          this.iconName = "paid2"; // actual cost element
          break;
        case "pc":
          this.iconName = "planning2"; // planning cost element
          break;
        case "cc":
          this.iconName = "commitment2"; // commitment cost element
          break;
        case "fc":
          this.iconName = "estimates2"; // forecast cost element
          break;
        default:
          this.iconName = null;
      }
    }
  },
};
</script>
