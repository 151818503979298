<template>
  <nav class="navbar is-primary" role="navigation" aria-label="main navigation">
    <div class="navbar-brand" :class="{ 'brand-is-centered': !loggedIn }">
      <router-link class="navbar-item" to="/" v-if="!loggedIn">
        <!-- is-background-light -->
        <img alt="Jive Connect Logo" src="/logo/JIVEConnect.png" height="28" />
      </router-link>

      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarMain"
        :class="{ 'is-active': showNavbar }"
        @click="toggleNavbar"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div
      id="navbarMain"
      class="navbar-menu"
      :class="{ 'is-active': showNavbar }"
    >
      <div class="navbar-start" v-if="loggedIn">
        <!-- Left Navbar Items  -->
        <!-- <a
          class="navbar-item is-hoverable"
          @click="requestDataUpload"
          v-if="currentRouteName == 'Views'"
        >
          <span class="icon is-small">
            <i class="fas fa-file-upload"></i>
          </span>
          <span class="ml-2">Save Rows</span>
        </a>

        <a
          class="navbar-item is-hoverable"
          @click="requestColumnStateUpload"
          v-if="currentRouteName == 'Views'"
        >
          <span class="icon is-small">
            <i class="fas fa-text-width"></i>
          </span>
          <span class="ml-2">Save Columns</span>
        </a> -->

        <a
          id="scaling-title"
          class="navbar-item is-size-4 has-text-weight-bold is-family-sans-serif is-hoverable ml-5"
          v-if="loggedIn && currentDisplayTitle"
        >
          {{ currentDisplayTitle }}
        </a>

        <!-- <div class="field navbar-item" v-if="currentRouteName == 'Dashboard'">
          <p class="control has-icons-left">
            <input class="input" type="text" />
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </p>
        </div> -->
      </div>

      <div class="navbar-end">
        <!-- Client logo -->
        <router-link class="navbar-item" to="/ClientAdmin" v-if="loggedIn">
          <!-- <img
            alt="Client Logo"
            src="/logo/AusGrid.png"
            height="28"
          /> -->
          <img :alt="clientId" :src="getImgUrl()" height="28" />
        </router-link>

        <!-- Sign in button -->
        <div
          class="navbar-item"
          v-if="!loggedIn && currentRouteName != 'Login'"
        >
          <div class="field is-grouped">
            <p class="control">
              <a class="button is-primary">
                <span class="icon">
                  <i class="fas fa-sign-in-alt" aria-hidden="true"></i>
                </span>
                <span>Login</span>
              </a>
            </p>
          </div>
        </div>

        <!-- User Management -->
        <a class="navbar-item has-dropdown is-hoverable" v-if="loggedIn">
          <!-- Change View - Main -->
          <a class="navbar-link is-arrowless">
            <div class="bg-circle">
              <span class="has-text-weight-semibold">{{
                usernameInitials
              }}</span>
            </div>
          </a>

          <!-- Manage User: Available options -->
          <div class="navbar-dropdown is-right">
            <!--  <router-link class="navbar-item" to="/UserAdmin">
              <span class="icon-text">
                <span class="icon">
                  <i class="fas fa-user-plus" aria-hidden="true"></i>
                </span>
                <span>Add a New User</span>
              </span>
            </router-link> -->
            <!-- <a class="navbar-item"> Change Client </a>
            <a class="navbar-item"> Manage Account </a> -->
            <router-link class="navbar-item" to="/UserMgmt">
              <span class="icon-text">
                <span class="icon">
                  <i class="fas fa-users-cog" aria-hidden="true"></i>
                </span>
                <span>Manage Account</span>
              </span>
            </router-link>
            <router-link class="navbar-item" to="/ExternalSystem">
              <span class="icon-text">
                <span class="icon">
                  <i class="fas fa-users-cog" aria-hidden="true"></i>
                </span>
                <span>External System Access</span>
              </span>
            </router-link>
            <hr class="navbar-divider" />
            <router-link class="navbar-item" to="/logout">
              <span class="icon-text">
                <span class="icon">
                  <i class="fas fa-sign-out-alt" aria-hidden="true"></i>
                </span>
                <span>Logout</span>
              </span>
            </router-link>
          </div>
        </a>

        <!-- User Info Tag
        <div class="navbar-item" v-if="loggedIn">
          <div class="tags has-addons">
            <span class="tag">
                <span class="icon">
                  <i class="fas fa-user" aria-hidden="true"></i>
                </span>
            </span>
            <span class="tag is-info has-text-light">User Name</span>
          </div>
        </div> -->
        <!-- <router-link class="navbar-item is-background-light" to="/">
          <img
            alt="Jive Connect Logo"
            src="/logo/JIVEConnect.png"
            height="28"
            v-if="loggedIn"
          />
        </router-link> -->
      </div>
    </div>
  </nav>
</template>

<script>
import { Gantt } from "@bryntum/gantt";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "NavBar",
  data() {
    return {
      showNavbar: false,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      views: (state) => state.userView.views,
    }),
    ...mapGetters("account", ["loggedIn", "username", "clientId"]),
    ...mapGetters("userView", ["selectedView", "selectedViewTitle"]),
    ...mapGetters("dashboardMenu", ["selectedDashboardType"]),
    ...mapGetters("dataManager", ["selectedDataType"]),
    ...mapGetters("structureManager", ["selectedMasterDataType"]),
    ...mapGetters("schedulingDataManager", ["selectedEntitites"]),
    ...mapGetters("costHeaderController", ["getParentSelectedObj"]),
    ...mapGetters("processSetDataManager", ["selectedIntegrateDataType"]),
    currentRouteName() {
      //console.log(this.$route.name)
      return this.$route.name;
    },
    currentDisplayTitle() {
      if (this.currentRouteName) {
        if (this.currentRouteName == "Views") {
          return this.selectedViewTitle;
        }
        if (this.currentRouteName == "Data") {
          return this.selectedDataType.displayName;
        } else if (this.currentRouteName == "MasterData") {
          return this.selectedMasterDataType.displayName;
        }
        if (this.currentRouteName == "Cost Element") {
          localStorage.setItem("pageRoute", JSON.stringify("CostElement"));
          localStorage.setItem("componentCode", null);
          return (
            /* this.getParentSelectedObj.costModelType.description + */
            " Cost Elements"
          );
        }
        if (this.currentRouteName == "Scheduling") {
          return this.$route.name;
        } else if (this.currentRouteName == "UserMgmt") {
          return "User Management";
        } else if (this.currentRouteName == "Settings") {
          return this.$route.params.id;
        } else if (this.currentRouteName == "Integrate") {
          return this.selectedIntegrateDataType.displayName;
          //return this.$route.params.id;
        } else if (this.currentRouteName == "Gantt") {
          localStorage.setItem("pageRoute", JSON.stringify("Gantt"));
          localStorage.setItem("componentCode", null);

          if (this.selectedEntitites.length == 0) {
            let tempGantt = localStorage.getItem("GanttElements");
            tempGantt = JSON.parse(tempGantt);
            return "Gantt: " + tempGantt.code + " - " + tempGantt.description;
          } else {
            return (
              "Gantt: " +
              this.selectedEntitites[0].code +
              " - " +
              this.selectedEntitites[0].description
            );
          }
        } else {
          if (this.selectedDashboardType != undefined)
            return this.selectedDashboardType.name + " Dashboard";
          else return "Project Dashboard";
        }
      }
      return null;
    },
    usernameInitials() {
      return this.username
        .split(" ")
        .map((p) => p[0].toUpperCase())
        .join();
    },
    clientLogo() {
      switch (this.clientId.toString()) {
        case "3":
          return "/logo/AusGrid.png";
        case "4":
          return "/logo/JH Logo.png";
        case "5":
          return "/logo/MirvacLogo.png";
      }
      return "";
      // return this.clientId.toString() == "3"
      //   ? "/logo/AusGrid.png"
      //   : "/logo/JH Logo.png";
    },
  },
  created() {},
  methods: {
    ...mapActions("account", ["login", "logout"]),
    toggleNavbar: function () {
      this.showNavbar = !this.showNavbar;
    },
    getImgUrl() {
      var valToReturn = "";
      switch (this.clientId) {
        case 3:
          valToReturn = "/logo/AusGrid.png";
          break;
        case 4:
          valToReturn = "/logo/JiveConnect.png";
          break;
        case 5:
          valToReturn = "/logo/MirvacLogo.png";
          break;
        case 6:
          valToReturn = "/logo/Electranet.svg";
          break;
        default:
          console.log(`Sorry, we are out of ${expr}.`);
      }
      return valToReturn;
      //return this.clientId == 3 ? "/logo/AusGrid.png" : "/logo/JH Logo.png";
    },
    isValid(valueToCheck) {
      if (
        String(valueToCheck) != "" &&
        String(valueToCheck) != "null" &&
        String(valueToCheck) != "undefined"
      )
        return true;
      else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.navbar-brand.brand-is-centered {
  margin-left: calc(50% - 115px);
  flex-grow: 1;
}
#scaling-title {
  font-size: 20px !important; //  1.5vw
}
.bg-circle {
  height: 40px;
  width: 40px;
  // text-align: center;
  // align-content: center;
  background-color: #186fb9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-self: center;

  > span {
    color: #fff;
    font-size: 23px !important;
    justify-content: center;
    align-self: center;
  }
}
</style>
