import { userService } from "@/api/user-auth";

const state = {
  entitiesLoaded: false,
  entitiesLoading: false,
  entitiesUpdated: false,
  allGroups: [],
  allUsers: [],
  defaultGroupColumns: [
    {
      field: "code",
      editable: true,
    },
    {
      field: "description",
      editable: true,
    },
  ],
  defaultUserColumns: [
    {
      field: "userId",
    },
    {
      field: "fullname",
    },
    {
      headerName: "In Group?",
      field: "inGroup",
      cellRenderer: function (params) {
        var input = document.createElement("input");
        input.type = "checkbox";
        input.checked = params.value;
        input.addEventListener("click", function (event) {
          let checked = event.target.checked;
          let colId = params.column.colId;
          params.node.setDataValue(colId, checked);
        });
        return input;
      },
    },
  ],
};

const actions = {
  loadGroupsAndUsers({ commit, rootGetters }) {
    commit("mutateEntitiesLoaded", false);
    userService.getJson(`JIVEUser/GetGroupMembership`).then((result) => {
      if (result.succeeded && result.data != null) {
        result.data.allGroups.forEach((x) => {
          var currGroupUsers = result.data.groupMembership.filter(
            (y) => y.groupId == x.id
          );
          var currGrpUserIds = currGroupUsers.map((z) => z.jiveUserId);
          x.Users = result.data.allUsers.filter((y) =>
            currGrpUserIds.includes(y.jiveUserId)
          );
        });
        commit("mutateAllGroups", result.data.allGroups);
        commit("mutateAllUsers", result.data.allUsers);
        commit("mutateEntitiesLoaded", true);
      }
    });
  },
  async saveGroupsAndMembers({ commit }, newGroups) {
    var newGroupsOrMembers = newGroups.filter((x) => x.updated);
    await userService
      .post(`JIVEUser/UpdateGroupMemberships`, newGroupsOrMembers)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          //Update groups with thier Id
          newGroupsOrMembers.forEach((newGrp, ind) => {
            newGrp.id = result.data[ind];
          });
        }
      });
    commit("mutateAllGroups", newGroups);
  },
};

const mutations = {
  mutateAllGroups(state, result) {
    result.map((obj) => ({ ...obj, updated: false }));
    state.allGroups = result;
  },
  mutateAllUsers(state, result) {
    result.map((obj) => ({ ...obj, inGroup: false }));
    state.allUsers = result;
  },
  mutateEntitiesLoaded(state, result) {
    state.entitiesLoaded = result;
  },
};

const getters = {
  allGroups(state) {
    return state.allGroups;
  },
  allUsers(state) {
    return state.allUsers;
  },
  defaultUserColumns(state) {
    return state.defaultUserColumns;
  },
  defaultGroupColumns(state) {
    return state.defaultGroupColumns;
  },
  allMemberships(state) {
    return state.allMemberships;
  },
  entitiesLoaded(state) {
    return state.entitiesLoaded;
  },
};

export const groupAdmin = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
