<template>
  <section style="margin-top: -0.48%; width: 100%; height: 100%">
    <div class="line" style="margin-top: 0.03%; margin-left: 0.2%"></div>
    <div
      class="column"
      style="background-color: white; width: 100%; height: 100%"
      ref="scriptContainerperformanceMetric"
    >
      <performance-metrics-toolbar />

      <ag-grid-vue
        class="ag-theme-balham ag-default-layout"
        rowSelection="multiple"
        enableCellChangeFlash="true"
        stopEditingWhenGridLosesFocus="true"
        :enableRangeSelection="true"
        :undoRedoCellEditing="true"
        :treeData="true"
        :getDataPath="getDataPath"
        :defaultColDef="defaultColDef"
        :autoGroupColumnDef="autoGroupColumnDef"
        :columnDefs="columnDefs"
        :rowData="rowChildrenData"
        :components="childcomponents"
        :gridOptions="gridChildrenOptions"
        :overlayLoadingTemplate="loadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
        :detailCellRendererParams="detailCellRendererParams"
        @cellClicked="cellClicked"
        @cell-value-changed="onCellValueChanged"
        @row-drag-move="onRowDragMove"
        @row-drag-leave="onRowDragLeave"
        @row-drag-end="onRowDragEnd"
        @grid-ready="onGridReady"
        @sort-changed="onColumnChanged"
        @column-resized="onColumnChanged"
        @column-visible="onColumnChanged"
        @column-row-group-changed="onColumnChanged"
        @column-value-changed="onColumnChanged"
        @column-moved="onColumnChanged"
        @column-pinned="onColumnChanged"
      >
      </ag-grid-vue>
    </div>
  </section>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import performanceMetricsToolbar from "./PerformanceMetricsToolbar";
import LevelIconCellRenderer from "@/components/Data/LevelIconCellRenderer.vue";
import * as moment from "moment";
import * as utilitiesModule from "@/assets/utilities.js";
import { debounce } from "lodash";
import {
  getColumnDef,
  postColumnState,
  getColumnState,
} from "@/api/networking.js";

export default {
  name: "PerformanceMetricManager",
  components: {
    AgGridVue,
    performanceMetricsToolbar,
    LevelIconCellRenderer, // eslint-disable-line
    //performanceCellRenderer, // eslint-disable-line
  },
  data() {
    return {
      defaultColDef: null,
      autoGroupColumnDef: null,
      columnDefs: null,
      rowChildrenData: null,
      tempChildData: null,
      getDataPath: null,
      detailCellRendererParams: {
        refreshStrategy: "everything",
      },
      i: -1,
      gridChildrenOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
        processDataFromClipboard: (params) => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          const lastIndex =
            this.gridChildrenOptions.api.getModel().rowsToDisplay.length - 1;
          const focusedCell = this.gridChildrenOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;
          if (focusedIndex + params.data.length - 1 > lastIndex) {
            const resultLastIndex = focusedIndex + (params.data.length - 1);
            const addRowCount = resultLastIndex - lastIndex;
            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }
            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];
            rowsToAdd.map((r) => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map((i) => {
                row[currColumn.colDef.field] = i;
                currColumn =
                  this.gridChildrenOptions.columnApi.getDisplayedColAfter(
                    currColumn
                  );
              });
              row.extraColumnValues = [];
              row.rowUpdated = true;
              row.id = 1 - this.rowChildrenData.length;
              row.path = [row.id];
              newRowData.push(row);
              this.rowChildrenData.push(row);
            });

            this.gridChildrenOptions.api.applyTransactionAsync({
              add: newRowData,
            });
            this.gridChildrenOptions.api.redrawRows();
          }
          this.gridChildrenOptions.api.refreshCells();
          return params.data;
        },
      },
      groupDefaultExpanded: null,
      childcomponents: {
        performanceMetricCellRenderer: getperformanceCellRenderer(),
        datePicker: getDatePicker(),
        integerCellEditor: getIntegerCellEditor(),
        decimalCellEditor: getDecimalCellEditor(),
        lookupCellEditor: getLookupCellEditor(),
        //   performanceCellRenderer: getperformanceCellRenderer(),
      },
      undoRedoCellEditingLimit: 15,
      parentObj: null,
      singleClickEdit: false,
      invalidindex: [],
      loadingTemplate: `<span class="ag-overlay-loading-center">Data is loading...</span>`,
      deleteSettings: {
        withBackdrop: true,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "center-center",
      },
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
    };
  },
  watch: {
    LoadChildData() {
      this.invalidindex = [];
      this.rowChildrenData = this.getChildrenCurrentData;
      this.tempChildData = Array.from(this.getChildrenCurrentData);
      this.gridChildrenOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.reloadColumnState();

      //this.childcomponents =
    },

    SaveError() {
      this.$vToastify.setSettings(this.NormalSettings);
      this.$vToastify.error("Invalid performance Resources Type!");
    },
    UndoCounter() {
      this.gridChildrenOptions.api.undoCellEditing();
    },

    RedoCounter() {
      this.gridChildrenOptions.api.redoCellEditing();
    },

    PasteRowCounter() {
      if (this.getCopiedRows.length > 0) {
        this.getCopiedRows.forEach((row) => {
          this.tempChildData.push(row);
        });
      }
      this.gridChildrenOptions.api.applyTransaction({
        add: this.getCopiedRows,
      });

      this.GridExapnd(this.getCopiedRows[0].id, false);
    },

    AddCounter() {
      setTimeout(() => {
        this.SetFocus();
      }, 100);
    },

    AddSubRecordCounter() {
      if (this.AddSubRecordCounter > 0) {
        if (
          this.getChildrenSelectedId == null &&
          this.tempChildData.length > 0
        ) {
          let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
          this.setChildrenObj(selectedNodes[0].data);
        }
        this.i = this.i - 1;
        this.AddNewRecord(true);
      }
    },

    AddRecordCounter() {
      if (this.AddRecordCounter > 0) {
        if (this.getChildrenSelectedId == null) {
          let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
          if (selectedNodes.length > 0) {
            this.setChildrenObj(selectedNodes[0].data);
          }
        }
        this.i = this.i - 1;
        this.AddNewRecord(false);
      }
    },

    SaveCounter() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.SaveCounter > 0) {
        let newRecord = [],
          indexes = [],
          emptyRowCounter = 0;
        newRecord = new Set(
          this.tempChildData.map((v) => v.code && v.rowUpdated == false)
        );
        if (newRecord.size < this.tempChildData.length) {
          indexes = _.countBy(this.tempChildData, "code");
          newRecord = _.filter(this.tempChildData, (x) => indexes[x.code] > 1);
          if (newRecord.length > 0) {
            newRecord.forEach((element) => {
              this.tempChildData.map((obj, index) => {
                if (obj.id === element.id) {
                  this.tempChildData[index].rowUpdated = true;
                }
              });
            });
          }
        }

        newRecord = [];
        indexes = [];
        this.invalidindex = Array.from(this.invalidindex);
        this.invalidindex = this.invalidindex.filter((x) => x !== undefined);
        this.gridChildrenOptions.api.refreshCells();
        this.gridChildrenOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            if (node.data.code != null) {
              this.tempChildData.filter((element) => {
                if (element.code === node.data.code) {
                  if (node.key != element.id) {
                    node.data.rowUpdated = "Duplicate";
                    indexes.push(node.rowIndex);
                  }
                }
              });
              newRecord.push(node.data);
            } else {
              newRecord = [];
              emptyRowCounter++;
              return false;
            }
          }
        });
        if (emptyRowCounter > 0 || indexes.length > 0) {
          this.gridChildrenOptions.api.redrawRows();
          this.gridChildrenOptions.api.refreshCells();
          newRecord = [];
          emptyRowCounter = 0;
          this.$vToastify.error("Code Value is empty or duplicate!");
        } else {
          this.mutateRecord({ returnedNewData: newRecord });
          this.saveData();
        }
      }
    },

    DeleteCounter() {
      if (this.DeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes =
                this.gridChildrenOptions.api.getSelectedNodes();

              let selectedData = selectedNodes.map((node) => node.data);
              if (selectedData.length > 0) {
                let DataDelete = [],
                  deleteIds = [];
                for (let i = 0; i < selectedData.length; i++) {
                  DataDelete.push(
                    this.tempChildData.filter((x) =>
                      x.path.includes(selectedData[i].id)
                    )
                  );
                }
                DataDelete.forEach((element) => {
                  element.forEach((data) => {
                    selectedData.push(data);
                  });
                });

                DataDelete = _.uniqBy(selectedData, "id");
                let Deletevalues = DataDelete.map((x) => x.id);
                Deletevalues.forEach((element) => {
                  deleteIds.push(
                    this.tempChildData.findIndex((x) => x.Id == element)
                  );
                });

                deleteIds.forEach((x) => {
                  delete this.tempChildData[x];
                });
                let indices = Deletevalues.filter((x) => x > 0);
                if (indices.length > 0) {
                  this.DeleteData({ Id: indices });
                }
                this.gridChildrenOptions.api.applyTransaction({
                  remove: DataDelete,
                });
                deleteIds = null;
                indices = null;
                Deletevalues = null;
                DataDelete = null;
                selectedData = null;
              }
            } else {
              this.$vToastify.setSettings(this.NormalSettings);
            }
          });
      }
    },

    isDelete() {
      if (this.isDelete > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.gridChildrenOptions.api.redrawRows();
        this.gridChildrenOptions.api.refreshCells();
        this.$vToastify.success("Row Deleted Successfully!");
      }
    },

    DataWatcher() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.DataWatcher > -1) {
        this.gridChildrenOptions.api.applyTransactionAsync({
          update: this.getChildTempData,
        });
        this.gridChildrenOptions.api.redrawRows();
        this.gridChildrenOptions.api.refreshCells();

        this.tempChildData = [];
        this.gridChildrenOptions.api.forEachNode((node) => {
          this.tempChildData.push(node.data);
        });

        this.gridChildrenOptions.api.clearFocusedCell();
        let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
        if (selectedNodes.length > 0) {
          this.gridChildrenOptions.api.selectIndex(
            selectedNodes[0].rowIndex,
            false,
            false
          );
          this.gridChildrenOptions.api.setFocusedCell(
            selectedNodes[0].rowIndex,
            "code"
          );
          this.setChildrenObj(selectedNodes[0].data);
        }
        this.invalidindex = [];
        this.$vToastify.success("Data successfully saved!");
      } else {
        this.tempChildData = [];
        this.rowChildrenData = [];
        this.gridChildrenOptions.api.redrawRows();
        this.gridChildrenOptions.api.refreshCells();
      }
    },

    ColumnDefCounters() {
      this.getChildrenGridColumn.forEach((row) => {
        this.columnDefs.push(row);
      });
      // this.reloadColumnState();

      /* this.getExtraCurrentColumns.forEach(row => {
        this.columnDefs.push(row);
      }); */
      this.reloadColumnState();
    },
  },

  computed: {
    /**
     * Load CostElements Controller Gettters, states
     */

    ...mapGetters("performanceMetricController", [
      "getChildrenGridColumn",
      "getChildrenCurrentData",
      "getAllStructureDetailsvalue",
      "getCopiedRows",
      "getChildrenSelectedId",
      "getChildTempData",
      "getCalenderData",
      "getChildrenSelectedObj",
      "getResourceTypeData",
    ]),

    /**performanceMetricResourceController */

    ...mapState({
      colDefs: (state) =>
        state.performanceMetricController.currentChildrenColDef,
      ColumnDefCounters: (state) =>
        state.performanceMetricController.currentChildrenGridColDefCountCounter,
      LoadChildData: (state) =>
        state.performanceMetricController.currentChildrenLoadRecordCounter,
      UndoCounter: (state) =>
        state.performanceMetricController.currentChildrenUndoWatcher,
      RedoCounter: (state) =>
        state.performanceMetricController.currentChildrenRedoWatcher,
      PasteRowCounter: (state) =>
        state.performanceMetricController.duplicateRecord,
      AddRecordCounter: (state) =>
        state.performanceMetricController.currentChildrenAddRecordCounter,
      AddSubRecordCounter: (state) =>
        state.performanceMetricController.currentChildrenSubRecordCounter,
      DeleteCounter: (state) =>
        state.performanceMetricController.currentChildrenDeleteRecordCounter,
      isDelete: (state) => state.performanceMetricController.isDelete,
      SaveCounter: (state) =>
        state.performanceMetricController.currentChildrenSaveRecordCounter,
      DataWatcher: (state) => state.performanceMetricController.datawatcher,
      SaveError: (state) => state.performanceMetricController.SaveError,
      calenderLoad: (state) =>
        state.performanceMetricController.tempCalenderCounter,
      resourceLoad: (state) =>
        state.performanceMetricController.tempResourceCounter,
    }),

    noRowsTemplate() {
      if (this.isElementdetail) {
        return this.loadingTemplate;
      } else {
        return `<span>No Rows to Show</span>`;
      }
    },
  },
  methods: {
    /**
     * Load CostElements Controller Actions, Mutation
     */

    ...mapActions("performanceMetricController", [
      "loadperformanceMetric",
      "saveData",
      "DeleteData",
      "LoadResourceTypesColumn",
      "LoadExtraColumnValue",
    ]),
    ...mapMutations("performanceMetricController", [
      "setChildrenObj",
      "setSelectedChildrenRowObject",
      "mutateChildrenUndostate",
      "mutateChildrenRedostate",
      "mutateRecord",
    ]),

    SetFocus() {
      let tempRowIndex = 0;
      this.gridCostHeaderOptions.api.forEachNode((node) => {
        if (node.data.id < 0) {
          node.setSelected(true);
          tempRowIndex = node.rowIndex;
        }
      });
      this.gridCostHeaderOptions.api.ensureIndexVisible(tempRowIndex);
      this.gridCostHeaderOptions.api.setFocusedCell(tempRowIndex, "code");
      //this.gridCostHeaderOptions.api.selectIndex(tempRowIndex, false, false);

      this.gridCostHeaderOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    cellClicked(params) {
      let tempdata = [];
      params.node.allLeafChildren.forEach((row) => {
        tempdata.push(row.data);
      });
      this.setSelectedChildrenRowObject(tempdata);
      this.setChildrenObj(params.node.data);
      let selectedNodes = params.node;
      params.api.forEachNode((node) => {
        if (node.data.path[node.data.path.length - 1] === selectedNodes.key) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
      this.setChildrenObj(params.node.data);
      if (params.column.colId == "ag-Grid-AutoColumn") {
        params.api.forEachNode((node) => {
          if (node.data.path[node.data.path.length - 1] === selectedNodes.id) {
            let thisParent = node.parent;
            while (thisParent) {
              thisParent.setExpanded(true);
              thisParent = thisParent.parent;
            }
            node.setSelected(true);
            node.setExpanded(true);
          }
        });
      }
      params.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    AddNewRecord(subRecord) {
      //console.log(this.getChildrenSelectedId);
      if (this.getChildrenSelectedId != null) {
        let tempDataIndex = 0;
        this.gridChildrenOptions.api.refreshCells();
        const focusedCell = this.gridChildrenOptions.api.getFocusedCell();
        const focusedIndex = focusedCell.rowIndex;
        const getParentId =
          this.gridChildrenOptions.api.getSelectedNodes(focusedIndex);
        var tempId = getParentId.findIndex(
          (x) => x.data.id == this.getChildrenSelectedId
        );
        let rowsToAdd = [];
        var temp = null;
        var params = {
          force: false,
          suppressFlash: true,
        };
        if (getParentId[tempId].data.id < 0) {
          this.i = getParentId[tempId].data.id;
        }
        if (getParentId[tempId].data.path.length > 1) {
          temp = getParentId[tempId].data.path.length;
          temp = getParentId[tempId].data.path.slice(0, temp - 1);
        }
        if (
          getParentId[tempId].allChildrenCount == null &&
          getParentId[tempId].uiLevel == 0
        ) {
          params.force = true;
        }
        this.i = this.i - this.tempChildData.length;
        rowsToAdd.push(1);
        rowsToAdd = rowsToAdd.reverse();
        let newRowData = [];
        rowsToAdd.map(() => {
          let row = {};

          if (subRecord) {
            row.parentId = getParentId[tempId].data.id;
          } else {
            row.parentId = getParentId[tempId].data.parentId;
          }
          if (row.parentId == 0) {
            row.parentId = null;
          }

          row.id = this.i - 1;
          if (row.path == undefined) {
            row.path = [];
          }
          if (row.parentId != null) {
            if (getParentId[0].data.id < 0) {
              if (subRecord) {
                if (temp != null) {
                  row.path = temp.concat([row.parentId], [row.id]);
                } else {
                  row.path = [row.parentId, row.id];
                }
              } else {
                row.path = temp.concat([row.id]); //[row.parentId, row.id];
              }
            } else {
              if (getParentId[0].data.path.length < 2) {
                row.path = [row.parentId, row.id];
              } else {
                if (subRecord) {
                  if (temp != null) {
                    row.path = temp.concat([row.parentId], [row.id]);
                  } else {
                    row.path = [row.parentId, row.id];
                  }
                  // row.path = temp.concat([row.parentId], [row.id]);
                } else {
                  row.path = temp.concat([row.id]);
                }
              }
            }
          } else {
            row.path = [row.id];
          }
          tempDataIndex = row.path[row.path.length - 1];

          row.extraColumnValues = [];
          row.code = null;
          row.description = null;
          row.rowUpdated = true;

          this.i = this.i - 2;
          newRowData.push(row);

          this.tempChildData.push(row);
        });
        this.gridChildrenOptions.api.applyTransaction({
          add: newRowData,
        });
        this.gridChildrenOptions.api.refreshCells(params);
        this.GridExapnd(newRowData[0].id, true);
        let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();

        this.setChildrenObj(selectedNodes[0].data);
      }
      if (this.gridChildrenOptions.api.getModel().rowsToDisplay.length == 0) {
        let rowsToAdd = [];
        rowsToAdd.push(1);
        rowsToAdd = rowsToAdd.reverse();
        let newRowData = [];
        rowsToAdd.map(() => {
          let row = {};
          row.rowUpdated = true;
          row.parentId = null;
          row.id = this.i - 1;
          row.path = [row.id];
          (row.extraColumnValues = []),
            //row.CostHeaderID = this.parentObj.id;
            (this.i = this.i - 2);
          newRowData.push(row);
          this.tempChildData.push(row);
        });
        this.gridChildrenOptions.api.applyTransactionAsync({
          add: newRowData,
        });
        this.gridChildrenOptions.api.refreshCells();
        this.setChildrenObj(selectedNodes[0].data);
        this.gridChildrenOptions.api.selectIndex(0, false, false);
        this.gridChildrenOptions.api.setFocusedCell(0, "code");
      }
    },

    GridExapnd(subRecord, expand) {
      this.gridChildrenOptions.api.clearFocusedCell();
      let tempRowIndex = 0;
      this.gridChildrenOptions.api.forEachNode((node) => {
        if (node.data.id == subRecord) {
          let thisParent = node.parent;
          while (thisParent && expand) {
            thisParent.setExpanded(true);
            thisParent = thisParent.parent;
          }
          node.setSelected(true);
          node.setExpanded(expand);
          tempRowIndex = node.rowIndex;
        }
      });
      this.gridChildrenOptions.api.ensureIndexVisible(tempRowIndex);
      this.gridChildrenOptions.api.setFocusedCell(tempRowIndex, "code");
      this.gridChildrenOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();
      if (params.node.data) {
        let selectedNodes = params.node.data;
        if (selectedNodes.length > 0) {
          this.setChildrenObj(params.node.data);
        }
      }

      if (params.column.colId == "code") {
        var CodeIndex = this.tempChildData.filter(
          (x) => x.id != params.node.data.id
        );
        CodeIndex = CodeIndex.findIndex(
          (x) => x.code.toLowerCase() == params.newValue.toLowerCase()
        );
        if (CodeIndex > -1) {
          this.$vToastify.setSettings(this.NormalSettings);
          this.$vToastify.error("Duplicate code value detected!");
          params.node.data.code = params.newValue;
          params.node.data.rowUpdated = "Duplicate";
        } else {
          params.node.data.rowUpdated = true;
        }
      } else if (params.column.colId.match("et_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;

        tempIndex = params.node.data.extraColumnValues.findIndex(
          (y) => y.PerformanceMetricExtraColumnId == parseInt(colvalue[1])
        );
        params.node.data.rowUpdated = true;
        if (tempIndex < 0) {
          params.node.data.extraColumnValues.push({
            PerformanceMetricId: params.node.data.id,
            PerformanceMetricExtraColumnId: colvalue[1],
            value: params.newValue,
          });
        } else {
          params.node.data.extraColumnValues[tempIndex].value = params.newValue;
        }
      }
      //console.log(params.node.data);

      params.node.data.rowUpdated = true;
      // params.api.refreshCells({ force: true, suppressFlash: true });
      if (undoSize == 0) {
        this.mutateChildrenUndostate(false);
        params.node.data.rowUpdated = false;
      } else {
        this.mutateChildrenUndostate(true);
      }

      if (redoSize == 0) {
        this.mutateChildrenRedostate(false);
      } else {
        this.mutateChildrenRedostate(true);
        params.node.data.rowUpdated = false;
      }
      params.api.refreshCells({ force: true, suppressFlash: true });
    },

    onRowDragMove(event) {
      setperformancePotentialParentForNode(event.api, event.overNode);
    },

    onRowDragLeave(event) {
      setperformancePotentialParentForNode(event.api, null);
    },

    onRowDragEnd(event) {
      this.$vToastify.setSettings(this.NormalSettings);
      if (!potentialParent) {
        return;
      }
      var movingData = event.node.data;
      var newParentPath = potentialParent.data ? potentialParent.data.path : [];
      if (potentialParent.data.id > 0) {
        var needToChangeParent = !areperformancePathsEqual(
          newParentPath,
          movingData.path
        );
        var invalidMode = isSelectionperformanceParentOfTarget(
          event.node,
          potentialParent
        );
        if (invalidMode) {
          this.$vToastify.error("invalid move!");
          console.error("invalid move");
        }
        if (needToChangeParent && !invalidMode) {
          var updatedRows = [];
          moveperformanceToPath(newParentPath, event.node, updatedRows);

          updatedRows.forEach((element) => {
            element.rowUpdated = true;
          });
          if (updatedRows[0].path.length > 1) {
            updatedRows[0].parentId =
              updatedRows[0].path[updatedRows[0].path.length - 2];
          } else {
            updatedRows[0].parentId = null;
          }
          this.gridChildrenOptions.api.applyTransactionAsync({
            update: updatedRows,
          });

          this.gridChildrenOptions.api.clearFocusedCell();
        }
      } else {
        this.$vToastify.error("invalid move!");
        console.error("invalid move");
      }
      setperformancePotentialParentForNode(event.api, null);
    },

    onGridReady(params) {
      getColumnDef(33)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerperformanceMetric;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          this.LoadExtraColumnValue("PerformanceMetric").then(() => {
            this.loadperformanceMetric();
          });
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    reloadColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 33)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridChildrenOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnChanged: debounce(function () {
      this.onColumnStateSubmit();
    }, 300),

    onColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridChildrenOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 33,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },
  },

  beforeMount() {
    /*Set Grid Tree Collumn */
    this.components = {
      // performanceMetricCellRenderer: getperformanceCellRenderer(),
    };
    /*  this.defaultColDef = Object.assign({}, this.defaultColDef, {
      width: 180,
    }); */
    this.autoGroupColumnDef = {
      headerName: "",
      rowDrag: true,
      field: "id",
      suppressPaste: true,
      //   cellRendererFramework:"performanceCellRenderer",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        // innerRenderer: "performanceMetricCellRenderer",
      },
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      width: 160,
      icons: {
        sortAscending: '<i class="fa fa-sort-alpha-up"/>',
        sortDescending: '<i class="fa fa-sort-alpha-down"/>',
        menu: '<i class="fa fa-caret-square-down"/>',
      },
    };
    this.getDataPath = (data) => {
      return data.path;
    };
    this.columnDefs = [];
    /**
     * Load Column Defination
     * Load All CostElement's Data
     * Set Basic Functions Here
     */
  },

  mounted() {
    //this.columnDefs = this.getChildrenGridColumn;
    this.loadperformanceMetric();
    //
  },
};

window.moveperformanceToPath = function moveperformanceToPath(
  newParentPath,
  node,
  allUpdatedNodes
) {
  //console.log("adsadasdas");
  var oldPath = node.data.path;
  var fileName = oldPath[oldPath.length - 1];
  var newChildPath = newParentPath.slice();
  newChildPath.push(fileName);
  node.data.path = newChildPath;
  allUpdatedNodes.push(node.data);
  if (node.childrenAfterGroup) {
    node.childrenAfterGroup.forEach(function (childNode) {
      moveperformanceToPath(newChildPath, childNode, allUpdatedNodes);
    });
  }
};

window.isSelectionperformanceParentOfTarget =
  function isSelectionperformanceParentOfTarget(selectedNode, targetNode) {
    var children = selectedNode.childrenAfterGroup;
    for (var i = 0; i < children.length; i++) {
      if (targetNode && children[i].key === targetNode.key) return true;
      isSelectionperformanceParentOfTarget(children[i], targetNode);
    }
    return false;
  };

window.areperformancePathsEqual = function areperformancePathsEqual(
  path1,
  path2
) {
  //console.log(path2, path2, path1);
  if (path1.length !== path2.length) {
    return false;
  }
  var equal = true;
  path1.forEach(function (item, index) {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
};

window.setperformancePotentialParentForNode =
  function setperformancePotentialParentForNode(api, overNode) {
    var newPotentialParent;
    if (overNode) {
      newPotentialParent = overNode;
    } else {
      newPotentialParent = null;
    }
    var alreadySelected = potentialParent === newPotentialParent;
    if (alreadySelected) {
      return;
    }
    var rowsToRefresh = [];
    if (potentialParent) {
      rowsToRefresh.push(potentialParent);
    }
    if (newPotentialParent) {
      rowsToRefresh.push(newPotentialParent);
    }
    potentialParent = newPotentialParent;
    refreshRows(api, rowsToRefresh);
  };

window.refreshRows = function refreshRows(api, rowsToRefresh) {
  var params = {
    rowNodes: rowsToRefresh,
    force: true,
  };
  api.refreshCells(params);
};

window.getperformanceCellRenderer = function getperformanceCellRenderer() {
  function performanceCellRenderer() {}
  performanceCellRenderer.prototype.init = function (params) {
    var tempDiv = document.createElement("div");

    // params.api.refreshCells({ force: true, suppressFlash: true });
    tempDiv.innerHTML =
      params.node.data.resourceTypeId == 1
        ? params.node.allChildrenCount == null && params.data.parentId == null
          ? '<img src="Icons/resources2.svg"; alt="" style="margin-top:5px; margin-left:28px; height:20px; width:20px;"/>'
          : '<img src="Icons/resources2.svg"; alt="" style="margin-top:5px; height:20px; width:20px;"/>'
        : params.node.data.resourceTypeId == 2 ||
          params.node.data.resourceTypeId == 5 ||
          params.node.data.resourceTypeId == 6
        ? params.node.allChildrenCount == null && params.data.parentId == null
          ? '<img src="Icons/labour2.svg"; alt="" style="margin-top:5px; margin-left:28px;  height:20px; width:20px;"/>'
          : '<img src="Icons/labour2.svg"; alt="" style="margin-top:5px; height:20px; width:20px;"/>'
        : params.node.data.resourceTypeId == 3
        ? params.node.allChildrenCount == null && params.data.parentId == null
          ? '<img src="Icons/materials2.svg"; alt="" style="margin-top:5px; margin-left:28px; height:20px; width:20px;"/>'
          : '<img src="Icons/materials2.svg"; alt="" style="margin-top:5px; height:20px; width:20px;"/>'
        : params.node.data.resourceTypeId == 4
        ? params.node.allChildrenCount == null && params.data.parentId == null
          ? '<img src="Icons/equipment2.svg";  alt="" style="margin-top:5px; margin-left:28px;  height:20px; width:20px;"/>'
          : '<img src="Icons/equipment2.svg"; alt="" style="margin-top:5px;  height:20px; width:20px;"/>'
        : null;

    this.eGui = tempDiv.firstChild;
  };
  performanceCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return performanceCellRenderer;
};

var potentialParent = null;

window.getDatePicker = function getDatePicker() {
  function Datepicker() {}
  var tempDatevalue = null;
  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "date");
    if (params.value != null) {
      this.eInput.value = moment(params.value).toISOString().substr(0, 10);
    }
    tempDatevalue = params.value; // moment(this.getSelectedRowObject.endDate).toISOString().substr(0, 10);

    this.eInput.classList.add("input");
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
    /*  $(this.eInput).datepicker({
      constrainInput: true, // prevent letters in the input field
      autoSize: true, // automatically resize the input field
      dateFormat: "dd/mm/yyyy",
      changeMonth: true,
      changeYear: true,
      yearRange: "1930:" + new Date().getFullYear(),
    }); */
  };
  Datepicker.prototype.getGui = function () {
    return this.eInput;
  };
  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Datepicker.prototype.getValue = function () {
    if (this.eInput.value.length > 0) {
      return moment(this.eInput.value).add(1, "days").format("YYYY-MM-DD");
    } else {
      return tempDatevalue;
    }
  };
  Datepicker.prototype.destroy = function () {};
  Datepicker.prototype.isPopup = function () {
    return false;
  };
  return Datepicker;
};

window.getIntegerCellEditor = function getIntegerCellEditor() {
  function IntegerPicker() {}
  IntegerPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.setAttribute("step", "1");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };

  /*  IntegerPicker.prototype.isKeyPressedNavigation = function(event) {
    return event.keyCode !=110;
  }; */
  IntegerPicker.prototype.getGui = function () {
    return this.eInput;
  };
  IntegerPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  IntegerPicker.prototype.getValue = function () {
    return parseInt(this.eInput.value);
  };
  IntegerPicker.prototype.destroy = function () {};
  IntegerPicker.prototype.isPopup = function () {
    return false;
  };
  return IntegerPicker;
};

window.getDecimalCellEditor = function getDecimalCellEditor() {
  function DecimalPicker() {}
  DecimalPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };
  DecimalPicker.prototype.getGui = function () {
    return this.eInput;
  };
  DecimalPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  DecimalPicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  DecimalPicker.prototype.destroy = function () {};
  DecimalPicker.prototype.isPopup = function () {
    return false;
  };
  return DecimalPicker;
};

window.getLookupCellEditor = function getLookupCellEditor() {
  function Lookuppicker() {}
  Lookuppicker.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var CostModelTypeFilter = CostModelTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );
    CostModelTypeFilter.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  Lookuppicker.prototype.getGui = function () {
    return this.eInput;
  };
  Lookuppicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Lookuppicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Lookuppicker.prototype.destroy = function () {};
  Lookuppicker.prototype.isPopup = function () {
    return false;
  };
  return Lookuppicker;
};

var ResourceTypes = null;

window.SetResourceTypes = function SetResourceTypes(data) {
  ResourceTypes = data;
};

window.getResourceTypesModelCellRenderer =
  function getResourceTypesModelCellRenderer() {
    function CustomResourceTypesCombobox() {}
    CustomResourceTypesCombobox.prototype.init = function (params) {
      this.eInput = document.createElement("select");
      this.eInput.setAttribute("class", "select");
      this.eOption = document.createElement("option");
      this.eInput.setAttribute("style", "width:100%");
      this.eInput.setAttribute("id", "resourceType");
      var ResourceTypesIDs = ResourceTypes.sort((a, b) =>
        a.description > b.description ? 1 : -1
      );
      //console.log(ResourceTypesIDs);
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      // this.eInput.appendChild(this.eOptionVal);
      ResourceTypesIDs.forEach((x) => {
        this.eOptionVal = document.createElement("option");
        //Statical set data in grid ComboBox
        this.eOptionVal.text = x.description;
        this.eOptionVal.value = x.description;
        if (x.description == params.value) {
          this.eOptionVal.selected = true;
        }
        this.eInput.appendChild(this.eOptionVal);
      });
    };
    CustomResourceTypesCombobox.prototype.getGui = function () {
      return this.eGui;
    };

    CustomResourceTypesCombobox.prototype.getGui = function () {
      return this.eInput;
    };
    CustomResourceTypesCombobox.prototype.afterGuiAttached = function () {
      this.eInput.focus();
    };
    CustomResourceTypesCombobox.prototype.getValue = function () {
      return this.eInput.value;
    };
    CustomResourceTypesCombobox.prototype.destroy = function () {};
    CustomResourceTypesCombobox.prototype.isPopup = function () {
      return false;
    };
    return CustomResourceTypesCombobox;
  };
</script>

<style scoped lang="scss">
.line {
  width: 99.5%;
  height: 1%;
  border-bottom: 1px solid black;
  position: relative;
}
</style>
