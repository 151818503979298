const state = {
  isMenuExpanded: false,
  isstrucuredetailModalVisible: false,
  structuredetailcode: null,
  structuredetailId: null,
  filtertitle: null,
  codeValue: null,
};
const mutations = {
  toggleStructureFilterModal(state) {
    state.isstrucuredetailModalVisible = !state.isstrucuredetailModalVisible;
  },
  mutateTitile(state, title) {
    state.filtertitle = title;
  },
  mutateStructureCode(state, code) {
    state.structuredetailcode = code;
  },

  mutateCodeValue(state, { code: code, Id: Id }) {
    state.codeValue = code;
    state.structuredetailId = Id;
  },
};
const getters = {
  getTitle() {
    return state.filtertitle + " Structure Module";
  },

  getStructureDetailcode() {
    return state.structuredetailcode;
  },

  getDetailsColumns() {
    return [
      {
        headerName: "Code",
        field: "code",
        sortable: true,
        resizable: true,
        width: 90,
        filterParams: { excelMode: "windows" },
        editable: false,
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Description",
        field: "description",
        sortable: true,
        resizable: true,
        width: 300,
        editable: false,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Labour",
        field: "isLabour",
        sortable: true,
        resizable: true,
        width: 90,
        editable: false,
        filterParams: { excelMode: "windows" },
        cellRenderer: function (params) {
          var input = document.createElement("input");
          input.type = "checkbox";
          input.checked = params.value;
          input.addEventListener("click", function (event) {
            if (params.value == null) {
              params.value = false;
              params.node.data.isLabour = false;
            }
            params.value = !params.value;
            params.data.rowUpdated = true;
            params.node.data.isLabour = params.value;
          });
          return input;
        },
      },
    ];
  },
};

export const structredetailControl = {
  namespaced: true,
  state,
  //actions,
  mutations,
  getters,
};
