<template>
  <div class="columns">
    <div class="column">
      <h1 class="title">Details</h1>
      <div class="columns is-multiline is-mobile" v-on:keyup="key">
        <div class="column is-12 group">
          <input
            type="text"
            v-model="getSelectedRowObject.code"
            name="code"
            autocomplete="off"
            required
            :disabled="
              Object.keys(getSelectedRowObject).length == 0 ||
              getSelectedRowObject == undefined
            "
          />
          <span class="highlight"></span>
          <span class="bar"></span>
          <label>Code</label>
        </div>

        <div class="column is-12 group">
          <input
            v-model="getSelectedRowObject.description"
            name="description"
            autocomplete="off"
            :disabled="Object.keys(getSelectedRowObject).length == 0"
          />
          <span class="highlight"></span>
          <span class="bar"></span>
          <label>Description </label>
        </div>
        <div class="column is-12 group" v-if="selectedDataType.id == 2">
          <input
            v-model="startDate"
            name="startDate"
            type="date"
            placeholder=""
            :disabled="Object.keys(getSelectedRowObject).length == 0"
          />

          <span class="highlight"></span>
          <span class="bar"></span>
          <label>Start Date </label>
        </div>
        <div class="column is-12 group" v-if="selectedDataType.id == 2">
          <input
            v-model="endDate"
            name="endDate"
            type="date"
            placeholder=""
            :disabled="Object.keys(getSelectedRowObject).length == 0"
          />

          <span class="highlight"></span>
          <span class="bar"></span>
          <label>End Date </label>
        </div>

        <div class="column is-12 group" v-if="selectedDataType.id == 2">
          <span
            style="font-size: 15px; font-weight: bold; color: black"
            v-if="Object.keys(getSelectedRowObject).length != 0"
            >Comment
          </span>
          <div class="columns">
            <div class="column">
              <textarea
                v-model="getSelectedRowObject.comment"
                name="Comment"
                type="textarea"
                autocomplete="off"
                placeholder="Comment"
                :disabled="Object.keys(getSelectedRowObject).length == 0"
              />
            </div>
          </div>
        </div>

        <div
          class="column is-12 group"
          v-if="selectedDataType.id == 2"
          :disabled="Object.keys(getSelectedRowObject).length == 0"
        >
          <input
            v-model="getSelectedRowObject.projectOption"
            name="projectOption"
            autocomplete="off"
            :disabled="Object.keys(getSelectedRowObject).length == 0"
          />
          <span class="highlight"></span>
          <span class="bar"></span>
          <label>Project Option </label>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: transparent;
}

input[type="date"]:disabled {
  color: transparent;
}
input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

.title {
  font-family: Verdana;
  font-size: 1.4vw;
}
.formulate-input {
  .formulate-input-label {
    font-weight: bold;
  }
}
/* form starting stylings ------------------------------- */
.group {
  position: relative;
  margin-bottom: 15px;
}

input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  color: #000;
}

textarea {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
}
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}

h1 {
  font-family: "Trocchi", serif;
  font-weight: normal;
  line-height: 48px;
  margin: 0;
}
/* LABEL ======================================= */
label {
  color: #999;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 7px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label {
  top: -10px;
  font-size: 14px;
  color: black;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 300px;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
::-webkit-input-placeholder {
  color: #999;
  font-weight: bold;
}
.column {
  box-shadow: 0.01rem 0.01rem 0.01rem rgb(241, 224, 224);
  margin-top: 0.01rem;
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import * as moment from "moment";
export default {
  name: "DataOperation",
  components: {},
  data() {
    return {
      tempstart: false,
      tempend: false,
      user: [
        {
          type: "text",
          name: "code",
          label: "Code",
          placeholder: "Code",
          validation: "required",
        },
        {
          type: "text",
          name: "description",
          label: "Description",
          placeholder: "Description",
          validation: "required",
        },
        {
          type: "date",
          name: "startDate",
          label: "date",
          validation: "required",
        },
        {
          type: "date",
          name: "to date",
          label: "date",
          validation: "required",
        },
        {
          type: "textarea",
          name: "comment",
          label: "Comment",
          placeholder: "Comment",
          validation: "required",
        },
        {
          type: "text",
          name: "options",
          label: "Options",
          placeholder: "Options",
          validation: "required",
        },
      ],
    };
  },
  watch: {},
  computed: {
    ...mapGetters("dataManager", [
      "selectedDataType",
      "getSelectedRowObject",
      "getCurrentColumns",
    ]), // "getSelectedRowObject",
    startDate: {
      get() {
        if (this.getSelectedRowObject.startDate == null) {
          return null;
        } else if (this.getSelectedRowObject.startDate != "Invalid date") {
          return moment(this.getSelectedRowObject.startDate)
            .toISOString()
            .substr(0, 10);
        } else {
          // this.getSelectedRowObject.startDate = null;
          return null;
        }
      },
      set(value) {
        this.mutateEditCount();
        if (this.tempstart) {
          this.getSelectedRowObject.rowUpdated = true;
          this.tempstart = true;
        } else {
          this.tempstart = true;
        }
        if (value)
          this.getSelectedRowObject.startDate = moment.utc(value).format();
        else this.getSelectedRowObject.startDate = null;
      },
    },
    endDate: {
      get() {
        if (this.getSelectedRowObject.endDate == null) {
          return null;
        } else if (this.getSelectedRowObject.endDate != "Invalid date") {
          return moment(this.getSelectedRowObject.endDate)
            .toISOString()
            .substr(0, 10);
        } else {
          // this.getSelectedRowObject.startDate = null;
          return null;
        }
      },
      set(value) {
        this.mutateEditCount();
        if (this.tempend) {
          this.getSelectedRowObject.rowUpdated = true;
        } else {
          this.tempend = true;
        }
        if (value)
          this.getSelectedRowObject.endDate = moment.utc(value).format();
        else this.getSelectedRowObject.endDate = null;
      },
    },
  },
  methods: {
    ...mapMutations("dataManager", ["mutateEditCount", "mutateDuplicate"]),
    loadlabels() {
      for (let i = 0; i < this.getCurrentColumns.length; i++) {
        this.user[i].name = this.getCurrentColumns[i].field;
        this.user[i].label = this.getCurrentColumns[i].headerName;
      }
    },
    key: function (event) {
      if (!this.getSelectedRowObject.rowUpdated) {
        this.getSelectedRowObject.rowUpdated = true;
      }
      this.mutateEditCount();
      if (event.path[0].name == "code") {
        this.mutateDuplicate();
      }
    },
    clearStartdate() {
      this.getSelectedRowObject.startDate = null;
    },
    clearEnddate() {
      this.getSelectedRowObject.endDate = null;
    },
  },
  mounted() {},
};
</script>
