<template>
  <div
    class="sidemenu"
    :class="{ 'is-expanded': sidemenuIsExpanded }"
    v-if="loggedIn"
  >
    <ul @click="sidemenuIsExpanded = !sidemenuIsExpanded">
      <li>
        <i class="fas fa-bars"></i>
        <h1></h1>
      </li>
    </ul>
    <ul
      v-for="jiveComponent in allComponents"
      :key="jiveComponent.MenuHeader"
      @click.capture="expandSubMenu"
      aria-expanded="false"
      :class="{
        'is-active': jiveComponent.Components[0].Path == '/' + currentRouteName,
      }"
    >
      <li>
        <!-- <i :class="jiveComponent.Icon" /> -->
        <span
          class="icon"
          v-if="jiveComponent.Icon && !jiveComponent.Icon.startsWith('fas')"
        >
          <img
            :src="require('@/assets/icons' + jiveComponent.Icon)"
            v-if="jiveComponent.Icon"
            class="level-icon"
          />
        </span>

        <h1>{{ jiveComponent.MenuHeader }}</h1>
        <span class="expandable"
          ><i class="fas fa-angle-down"></i><i class="fas fa-angle-up"></i
        ></span>
      </li>
      <ul>
        <li>
          <h1>{{ jiveComponent.MenuHeader }}</h1>
        </li>
        <li
          class="long-view-text-size"
          v-for="currComponent in jiveComponent.Components"
          :class="{
            'is-active':
              (currComponent.Path + '/' + currComponent.Code ==
                currentRoutePath)
              | (currComponent.Path + '/' + String(currComponent.Id) ==
                currentRoutePath),
          }"
          :key="currComponent.Id"
          @click="moveTo(currComponent.Path, currComponent.Code)"
        >
          <LevelIconCellRenderer :value="currComponent.Code" />
          {{ currComponent.Desc }}
        </li>
      </ul>
    </ul>
  </div>
</template>
<script>
import { expandSubMenu } from "./collapsable-sidebar.js";
import LevelIconCellRenderer from "../Data/LevelIconCellRenderer.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { userService } from "@/api/user-auth";

export default {
  name: "DynamicCollapsableSideBar",
  components: {
    LevelIconCellRenderer,
  },
  watch: {
    // loggedIn() {
    //   if (this.loggedIn && !this.entitiesLoaded && !this.entitiesLoading) {
    //     this.loadJiveComponents();
    //   }
    // },
    entitiesLoaded() {
      this.loadingComponents = false;
      //console.log(this.$route.path);
      console.log("jiveComponents", this.allComponents);
    },
  },
  methods: {
    ...mapActions("jiveComponents", ["loadJiveComponents"]),
    ...mapActions("userView", ["updateViewListAsync"]),
    ...mapMutations("TransactionalData", ["mutateSelectedDataType"]),
    ...mapMutations("userManagement", ["mutateSelectedUserDataType"]),
    ...mapMutations("structureManager", ["mutateSelectedMasterDataType"]),
    ...mapMutations("dataManager", ["mutateSelectedDataType"]),
    ...mapMutations("userView", ["mutateSelectedView"]),
    ...mapMutations("processSetDataManager", ["mutateSelectedIntegrateView"]),
    expandSubMenu: expandSubMenu,

    //Move this logic into the router
    moveTo(path, componentCode) {
      //console.log("componentCode", componentCode);
      //console.log("path", path);
      if (
        path.toLowerCase() == "/data" ||
        path.toLowerCase() == "/masterdata" ||
        path.toLowerCase() == "/settings" ||
        path.toLowerCase() == "/integrate" ||
        path.toLowerCase() == "/analytics"
      ) {
        this.$router.push(path + "/" + componentCode);
      }
      if (path == "/Views" || path == "/Dashboard") {
        var selectedView = {};
        if (!this.userViewsLoaded) {
          console.log("Loading User Views");
          this.updateViewListAsync().then((result) => {
            selectedView = this.allUserViews.find(
              (x) => x.name == componentCode
            );
            if (selectedView && selectedView != {}) {
              this.$router.push(path + "/" + selectedView.id);
            }
          });
        } else {
          selectedView = this.allUserViews.find((x) => x.name == componentCode);
          if (selectedView && selectedView != {}) {
            this.$router.push(path + "/" + selectedView.id);
          }
        }
      }
      console.log("Moving To", path);
    },
  },
  computed: {
    ...mapGetters("account", ["loggedIn", "userid", "clientId"]),
    ...mapGetters("dataManager", ["getAllDataTypes"]),
    ...mapGetters("userView", [
      "selectedView",
      "userViewsLoaded",
      "allUserViews",
    ]),

    ...mapGetters("structureManager", {
      gatAllMasterDataTypes: "getAllDataTypes",
    }),
    ...mapGetters("userManagement", {
      gatAllUserDataTypes: "getAllUserDataTypes",
    }),

    ...mapGetters("processSetDataManager", {
      gatAllIntegrateDataTypes: "getAllDataTypes",
    }),
    ...mapGetters("jiveComponents", [
      "entitiesLoaded",
      "entitiesLoading",
      "allComponents",
      "menuHeaders",
    ]),
    ...mapState({
      isMenuExpanded: (state) => state.filterControl.isMenuExpanded,
    }),
    currentRouteComponentId() {
      return this.$route.params.id;
    },
    currentRouteName() {
      //console.log("this.$route", this.$route, this.allComponents);
      if (this.$route.path == "/" && this.$route.name == null) {
        let temprouteLink = localStorage.getItem("pageRoute");
        let tempComoponentCode = localStorage.getItem("componentCode");
        if (tempComoponentCode != undefined && temprouteLink != "null")
          this.moveTo(
            "/" + JSON.parse(temprouteLink),
            JSON.parse(tempComoponentCode)
          );
        return this.$route.name;
      } else {
        localStorage.setItem("pageRoute", JSON.stringify(this.$route.name));
        localStorage.setItem(
          "componentCode",
          JSON.stringify(this.$route.params.id)
        );

        return this.$route.name;
      }
    },
    currentRoutePath() {
      return this.$route.path;
    },
  },
  created: function () {
    this.loadingComponents = true;
    if (this.loggedIn && !this.entitiesLoaded) {
      this.loadJiveComponents();
    }
  },
  mounted: function () {
    if (this.loggedIn && !this.entitiesLoaded && !this.loadingComponents) {
      this.loadJiveComponents();
    }
  },
  data() {
    return {
      sidemenuIsExpanded: false,
      componentsLoaded: false,
      loadingComponents: false,
    };
  },
};
</script>

<style lang="scss" scoped>
$sidemenu-orientation: left;
@import "./collapsable-sidebar.scss";
li.long-view-text-size {
  font-size: 12px !important;
}
.level-icon {
  height: 75% !important;
  width: 75% !important;
}
</style>
