import { userService, post } from "@/api/user-auth";

const state = {
  /* Parent Column Def */
  currentParentColDef: [
    {
      headerName: "First Name",
      field: "firstName",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      comparator: (valueA, valueB) => {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      },
      width: 120,
      suppressSizeToFit: true,
    },
    {
      headerName: "Last Name",
      field: "lastName",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 120,
    },
    {
      headerName: "UserName",
      field: "userName",
      filter: "agSetColumnFilter",
      resizable: true,
      sortable: true,
      width: 250,
      editable: function (params) {
        if (params.node.data.id < 0) {
          return true;
        } else {
          return false;
        }
      },
      filterParams: { excelMode: "windows" },
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
    },
    {
      headerName: "Email",
      field: "email",
      filter: "agSetColumnFilter",
      resizable: true,
      sortable: true,
      width: 250,
      editable: function (params) {
        if (params.node.data.id < 0) {
          return true;
        } else {
          return false;
        }
      },
      filterParams: { excelMode: "windows" },
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
    },
    {
      headerName: "Password",
      field: "password",
      filter: "agSetColumnFilter",
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      editable: true,
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      cellEditor: "passwordManager",
      tooltipValueGetter: function (params) {
        let value =
          "At least one upper case English letter, \n\n" +
          "At least one lower case English letter, \n\n" +
          "At least one digit,   \n\n" +
          "At least one special character,   \n\n" +
          "Minimum eight in length. \n\n";
        return value;
      },
      cellRenderer: function (params) {
        if (params.data.passwordInvalid) {
          let eIconGui = document.createElement("span");
          return (eIconGui.innerHTML =
            '<i class="fas fa-exclamation" style="color:red;"></i>' +
            "****************");
        } else return "****************";
      },
    },
    {
      headerName: "Confirm Password",
      field: "confirmPassword",
      filter: "agSetColumnFilter",
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      editable: true,
      tooltipValueGetter: function (params) {
        var value =
          "At least one upper case English letter, \n\n" +
          "At least one lower case English letter, \n\n" +
          "At least one digit,   \n\n" +
          "At least one special character,   \n\n" +
          "Minimum eight in length. \n\n";
        return value;
      },
      cellStyle: function (params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      cellEditor: "passwordManager",
      cellRenderer: function (params) {
        if (params.data.passwordInvalid) {
          let eIconGui = document.createElement("span");
          return (eIconGui.innerHTML =
            '<i class="fas fa-exclamation" style="color:red;"></i>' +
            "****************");
        } else return "****************";
      },
    },

    /*   {
      headerName: "IsActive",
      field: "isDelete",
      sortable: true,
      resizable: true,
      width: 80,
      editable: false,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      cellRenderer: function(params) {
        var input = document.createElement("input");
        input.type = "checkbox";

        input.checked = params.value;
        input.addEventListener("click", function(event) {

          params.value = !params.value;
          params.data.rowUpdated = true;
          params.node.data.isDelete = !params.value;
        });
        return input;
      },
    }, */
  ],
  currentData: [],
  dataWatcher: 0,
  dataLoadCounter: 0,
  undoWatcher: 0,
  redoWatcher: 0,
  isRedo: false,
  isUndo: false,
  addCounter: 0,
  DeleteCounter: 0,
  isDelete: 0,
  isSaveCounter: 0,
  selectedRowObj: {},
  saveError: false,
  saveErrorCounter: 0,

  UserDataTypes: [
    {
      id: 0,
      name: "Groups",
      displayName: "User Groups",
    },
    {
      id: 1,
      name: "GroupsAccess",
      displayName: "User Group Access",
    },
    {
      id: 2,
      name: "UserAdmin",
      displayName: "User Management",
    },
  ],
  selectedUserDataTypeId: null,
};

const actions = {
  loadAllUsers({ dispatch, commit }) {
    userService
      .getJson(`Account/GetAllUsersByClient/`)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          state.currentData = result.data;
          state.dataLoadCounter++;
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /**Save Users */
  async saveUser({ dispatch, commit }) {
    var rowsToInsert = [];
    var rowsToUpdate = [];
    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {};
    state.saveError = false;
    state.currentData.forEach((row) => {
      if (row.rowUpdated) {
        if (
          row.userName.length > 0 &&
          row.password != undefined &&
          !row.password.localeCompare(row.confirmPassword) &&
          !row.passwordInvalid
        ) {
          if (row.id <= -1) rowsToInsert.push(row);
          else {
            rowsToUpdate.push({
              Id: row.id,
              FirstName: row.firstName,
              LastName: row.lastName,
              Email: row.email,
              UserName: row.userName,
              NewPassword: row.password,
              ConfirmPassword: row.confirmPassword,
              ClientId: 0,
            });
          }
        } else if (row.userName.length > 0 && row.password == undefined) {
          rowsToUpdate.push({
            Id: row.id,
            FirstName: row.firstName,
            LastName: row.lastName,
            Email: row.email,
            UserName: row.userName,
            Password: row.password,
            ConfirmPassword: row.confirmPassword,
            ClientId: 0,
          });
        } else {
          state.saveError = true;
          state.saveErrorCounter++;
        }
      }
    });

    if (!state.saveError) {
      if (rowsToInsert.length > 0) {
        createURL = "Account/register";
        createObj = rowsToInsert;
      }
      if (rowsToUpdate.length > 0) {
        updateURL = "Account/UpdateUser";

        updateObj = rowsToUpdate;
      }

      if (createURL != "") {
        createP = post(createURL, createObj)
          .then((values) => {
            var returnedValues = [];
            if (values) returnedValues = values.data;
            state.dataWatcher++;
            dispatch("loadAllUsers");
          })
          .catch((error) => {
            dispatch("alert/error", error, { root: true });
          });
      }

      if (updateURL != "") {
        updateP = post(updateURL, updateObj)
          .then((values) => {
            var returnedValues = [];
            if (values) returnedValues = returnedValues.concat(values.data);
            commit("setSavedData", { returnedData: returnedValues });
          })
          .catch((error) => {
            dispatch("alert/error", error, { root: true });
          });
      }
      //state.saveErrorCounter++;
    }
  },

  setSelectedMasterDataType({ commit }, dataId) {
    var selectedMDT = this.UserDataTypes.find((x) => x.name == dataId);
    console.log("selectedMDT", selectedMDT);
    commit("mutateSelectedUserDataType", {
      selectedUserDataTypeId: selectedMDT.id,
    });
  },

  async setSelectedData({ commit }, selectedDataTypeName) {
    console.log("UserDataTypeId: selectedDataTypeName", selectedDataTypeName);
    console.log(
      "selectedDT",
      state.UserDataTypes.find((x) => x.name == selectedDataTypeName)
    );
    commit("mutateSelectedUserDataType", {
      selectedUserDataTypeId: state.UserDataTypes.find(
        (x) => x.name == selectedDataTypeName
      ).id,
    });
    console.log("Post", state.selectedUserDataTypeId);
  },
};

const mutations = {
  setSavedData(state) {
    state.currentData.forEach((row) => (row.rowUpdated = false));
    state.dataWatcher++;
  },
  addDataRecord(state) {
    state.currentData = [
      ...state.currentData,
      {
        id: -1,
        firstName: "",
        lastName: "",
        userName: "",
        password: "",
        email: "",
        confirmPassword: "",
        clinetId: 0,
        rowUpdated: true,
        passwordInvalid: false,
      },
    ];
    // state.currentData.push({
    //   id: -1,
    //   firstName: "",
    //   lastName: "",
    //   userName: "",
    //   password: "",
    //   email: "",
    //   confirmPassword: "",
    //   clinetId: 0,
    //   rowUpdated: true,
    //   passwordInvalid: false,
    // });
    state.addCounter++;
    state.dataLoadCounter++;
  },
  requestUndo(state) {
    state.undoWatcher += 1;
  },
  requestRedo(state) {
    state.redoWatcher += 1;
  },

  resetRequestUndo(state, Undovalue) {
    state.isUndo = Undovalue;
  },

  resetRequestRedo(state, Redovalue) {
    state.isRedo = Redovalue;
  },

  mutateDelete(state) {
    state.DeleteCounter++;
  },

  mutateSave(state) {
    state.isSaveCounter++;
  },

  setSelectedRowObject(state, { selectedObj }) {
    state.selectedRowObj = {};
    if (selectedObj != 0 || selectedObj != undefined)
      state.selectedRowObj = selectedObj;
    else state.selectedRowObj = {};
  },
  resetCurrentData(state) {
    state.currentData = [];
  },

  mutateSelectedUserDataType(state, { selectedUserDataTypeId }) {
    state.selectedUserDataTypeId = selectedUserDataTypeId;
  },
};

const getters = {
  getAllUserDataTypes() {
    return state.UserDataTypes;
  },

  selectedUserDataType({ selectedUserDataTypeId, UserDataTypes }) {
    if (selectedUserDataTypeId == null) selectedUserDataTypeId = 0;
    return UserDataTypes.find((dt) => dt.id == selectedUserDataTypeId);
  },

  getCurrentColumns() {
    return state.currentParentColDef;
  },
  getRedoWatcher() {
    return state.redoWatcher;
  },

  getUndoWatcher() {
    return state.undoWatcher;
  },

  getisRedo() {
    return state.isRedo;
  },

  getisUndo() {
    return state.isUndo;
  },
  getSelectedRowObject() {
    return state.selectedRowObj;
  },
  getCurrentRowData() {
    return state.currentData;
  },
};

export const userManagement = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
