<template>
  <div style="height: 100%; width: 96vw; background-color: white">
    <UserAdminToolBar />

    <div class="columns" style="height: 100%; background-color: white">
      <div
        class="container-grid"
        style="width: 100%; height: 98%; margin: 1%; overflow: auto"
      >
        <!-- <form @submit.prevent="handleSubmit">
          <div class="column is-multiline">
            <div class="column is-5 group">
              <input
                type="text"
                v-model="user.firstName"
                v-validate="'required'"
                autocomplete="off"
                :class="{ 'is-invalid': submitted && errors.has('firstName') }"
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>First Name</label>
            </div>
          </div>
          <div class="column is-multiline">
            <div class="column is-5 group">
              <label for="lastName">Last Name</label>
              <input
                type="text"
                v-model="user.lastName"
                v-validate="'required'"
                name="lastName"
                class="form-control"
                :class="{ 'is-invalid': submitted && errors.has('lastName') }"
              />
              <div
                v-if="submitted && errors.has('lastName')"
                class="invalid-feedback"
              >
                {{ errors.first("lastName") }}
              </div>
            </div>
          </div>
          <div class="column is-multiline">
            <div class="column is-5 group">
              <label for="username">Username</label>
              <input
                type="text"
                v-model="user.username"
                v-validate="'required'"
                name="username"
                class="form-control"
                :class="{ 'is-invalid': submitted && errors.has('username') }"
              />
              <div
                v-if="submitted && errors.has('username')"
                class="invalid-feedback"
              >
                {{ errors.first("username") }}
              </div>
            </div>
          </div>
          <div class="column is-multiline">
            <div class="column is-5 group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                v-model="user.password"
                v-validate="{ required: true, min: 6 }"
                name="password"
                class="form-control"
                :class="{ 'is-invalid': submitted && errors.has('password') }"
              />
              <div
                v-if="submitted && errors.has('password')"
                class="invalid-feedback"
              >
                {{ errors.first("password") }}
              </div>
            </div>
          </div>
          <div class="column is-multiline">
            <div class="column is-5 group">
              <label htmlFor="confirmpassword">Confirm Password</label>
              <input
                type="password"
                v-model="user.password"
                v-validate="{ required: true, min: 6 }"
                name="password"
                class="form-control"
                :class="{ 'is-invalid': submitted && errors.has('password') }"
              />
              <div
                v-if="submitted && errors.has('password')"
                class="invalid-feedback"
              >
                {{ errors.first("password") }}
              </div>
            </div>
          </div>
          <div class="column is-multiline">
            <div class="column is-5 group">
              <button
                class="button"
                @click="submit()"
                type="submit"
                value="Submit"
              >
                Register
              </button>
            </div>
          </div>
        </form> -->

        <ag-grid-vue
          class="ag-theme-balham ag-default-layout"
          rowSelection="single"
          enableCellChangeFlash="true"
          stopEditingWhenGridLosesFocus="true"
          :columnDefs="columnDefs"
          :rowData="rowParentData"
          :suppressRowClickSelection="false"
          :enableRangeSelection="true"
          :undoRedoCellEditing="true"
          :defaultColDef="defaultColDef"
          :components="components"
          :autoGroupColumnDef="autoGroupColumnDef"
          :gridOptions="gridUserOptions"
          :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
          @cell-value-changed="onCellValueChanged"
          @selection-changed="onSelectionChanged"
          @grid-ready="onGridReady"
          @sort-changed="onColumnChanged"
          @column-resized="onColumnChanged"
          @column-visible="onColumnChanged"
          @column-row-group-changed="onColumnChanged"
          @column-value-changed="onColumnChanged"
          @column-moved="onColumnChanged"
          @column-pinned="onColumnChanged"
        >
        </ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { debounce } from "lodash";
import UserAdminToolBar from "./UserAdminToolbar.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "UserAdmin",
  props: {},
  data() {
    return {
      gridUserOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
        tooltipShowDelay: 0,
        tooltipHideDelay: 2000,
      },
      components: {
        passwordManager: getPasswordManager(),
      },
      rowParentData: null,
      columnDefs: null,
      undoRedoCellEditingLimit: null,
      defaultColDef: null,
      autoGroupColumnDef: null,
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
      contains_passwordConditions:
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
    };
  },
  components: {
    AgGridVue,
    UserAdminToolBar,
  },

  watch: {
    undoWatcher() {
      this.undo();
    },

    redoWatcher() {
      this.redo();
    },
    addCounter() {
      setTimeout(() => {
        this.SetFocus();
      }, 1000);
    },
    dataLoadCounter() {
      this.rowParentData = this.getCurrentRowData;
    },
    saveErrorCounter() {
      if (this.saveError) {
        this.$vToastify.error(
          "Error in Password Value. Please Check the password Conditions."
        );
      }
    },
    dataWatcher() {
      this.gridUserOptions.api.redrawRows();
      this.gridUserOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.$vToastify.success("Data successfully saved!");
    },
  },
  computed: {
    ...mapGetters("clientAdmin", ["getUsers"]),
    ...mapGetters("userManagement", [
      "getCurrentColumns",
      "getRedoWatcher",
      "getundoWatcher",
      "getCurrentRowData",
    ]),
    ...mapState({
      views: (state) => state.userView.views,
    }),
    ...mapGetters("account", ["loggedIn", "username", "clientId"]),
    ...mapState("clientAdmin", ["passwordCounter", "resetError"]),
    ...mapState("userManagement", [
      "redoWatcher",
      "undoWatcher",
      "addCounter",
      "dataLoadCounter",
      "dataWatcher",
      "saveError",
      "saveErrorCounter",
    ]),

    usernameInitials() {
      return this.username
        .split(" ")
        .map((p) => p[0].toUpperCase())
        .join();
    },
    clientLogo() {
      return this.clientId.toString() == "3"
        ? "/logo/AusGrid.png"
        : "/logo/JH Logo.png";
    },
  },
  methods: {
    ...mapActions("clientAdmin", ["loadUsers", "resetPassword"]),

    ...mapActions("userManagement", ["loadAllUsers"]),
    ...mapMutations("userManagement", [
      "requestUndo",
      "requestRedo",
      "resetRequestUndo",
      "resetRequestRedo",
      "setSelectedRowObject",
      "resetCurrentData",
    ]),
    handleSubmit(e) {
      this.submitted = true;
      this.$validator.validate().then((valid) => {
        if (valid) {
          console.log("User is Valid");
        }
      });
    },
    getImgUrl() {
      var valToReturn = "";
      switch (this.clientId) {
        case 3:
          valToReturn = "/logo/AusGrid.png";
          break;
        case 4:
          valToReturn = "/logo/AusGrid.png";
          break;
        case 5:
          valToReturn = "/logo/Mirvac Logo.png";
          break;
        case 6:
          valToReturn = "/logo/Electranet.svg";
          break;
        default:
          console.log(`Sorry, we are out of ${expr}.`);
      }
      return valToReturn;
      //return this.clientId == 3 ? "/logo/AusGrid.png" : "/logo/JH Logo.png";
    },
    onGridReady(params) {
      /*    getColumnDef(this.selectedDataType.componentId)
        .then(moduleBlob => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerCostHeader;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          this.LoadParentExtraColumn();
          this.LoadParentData();
        })
        .catch(error => {
          console.error("alert/error", error, { root: true });
        }); */
    },

    onColumnChanged: debounce(function () {
      // this.onColumnStateSubmit();
    }, 300),

    onColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridParentOptions.columnApi.getColumnState();

      postColumnState({
        componentId: this.selectedDataType.componentId,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },

    onCellValueChanged(params) {
      if (params.column.colId == "password") {
        if (params.newValue.length > 0) {
          var passcheck = this.contains_passwordConditions.test(
            params.newValue
          );
          if (passcheck) {
            params.node.data.passwordInvalid = false;
          } else {
            params.node.data.passwordInvalid = true;
          }
        }
      }
      if (params.newValue.length > 0) {
        params.node.data.rowUpdated = true;
      }
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();
      if (undoSize == 0) {
        this.resetRequestUndo(false);
        params.node.data.rowUpdated = false;
      } else {
        this.resetRequestUndo(true);
      }

      if (redoSize == 0) {
        this.resetRequestRedo(false);
      } else {
        this.resetRequestRedo(true);
        params.node.data.rowUpdated = false;
      }
      params.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    onSelectionChanged(event) {
      var selectedRowObjs = event.api.getSelectedNodes();
      if (selectedRowObjs.length > 0) {
        this.setSelectedRowObject({ selectedObj: selectedRowObjs[0].data });
      }
      this.gridUserOptions.api.refreshCells();
    },

    SetFocus() {
      let tempRowIndex = 0;
      this.gridUserOptions.api.clearFocusedCell();
      tempRowIndex = this.gridUserOptions.api.getLastDisplayedRow();
      console.log("tempRowIndex", tempRowIndex);
      var rowNode =
        this.gridUserOptions.api.getDisplayedRowAtIndex(tempRowIndex);
      console.log("rowNode", rowNode);
      rowNode.setSelected(true);
      //this.gridUserOptions.api.selectIndex(tempRowIndex, false, false);
      this.gridUserOptions.api.setFocusedCell(tempRowIndex, "firstName");
      //this.gridUserOptions.api.refreshCells();
    },
    undo() {
      this.gridUserOptions.api.undoCellEditing();
    },

    redo() {
      this.gridUserOptions.api.redoCellEditing();
    },
  },
  beforeMount() {
    this.undoRedoCellEditingLimit = 15;
    this.defaultColDef = Object.assign({}, this.defaultColDef, {
      width: 180,
    });
    this.setSelectedRowObject({ selectedObj: 0 });
  },
  mounted() {
    this.autoGroupColumnDef = {
      headerName: "",
      field: "id",
      rowDrag: true,
      suppressPaste: true,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        //innerRenderer: "iconCostCellRenderer",
      },
      resizable: true,
      sortable: true,
      //filterParams: { excelMode: "windows" },
      //filter: "agSetColumnFilter",
      width: 160,
    };
    this.resetRequestUndo(false);
    this.resetRequestRedo(false);
    this.$vToastify.setSettings(this.NormalSettings);
    this.rowParentData = [];
    this.columnDefs = this.getCurrentColumns;
    this.resetCurrentData();
    this.loadAllUsers();
    //console.log(this.clientId)
  },
};

window.getPasswordManager = function getPasswordManager() {
  // Initialize all input of type date

  function PasswordInput() {}
  PasswordInput.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "password");
    this.eInput.classList.add("input");
    this.eInput.minLength = "8";
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };

  PasswordInput.prototype.getGui = function () {
    return this.eInput;
  };
  PasswordInput.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  PasswordInput.prototype.getValue = function () {
    return this.eInput.value;
  };
  PasswordInput.prototype.destroy = function () {};
  PasswordInput.prototype.isPopup = function () {
    return false;
  };
  return PasswordInput;
};
</script>

<style scoped lang="scss">
.line {
  width: 100%;
  height: 3%;
  border-bottom: 1px solid black;
  position: relative;
  background-color: white;
}
</style>
