<template>
  <div style="height: 100%; background-color: white" ref="scriptContainerP3M">
    <div
      class="columns is-multiline is-mobile"
      style="height: 65%; width: 100%"
    >
      <div class="column is-multiline">
        <div class="column is-5 group">
          <div class="left configHeader"><p>Token Settings</p></div>
        </div>
        <div class="column is-5 group">
          <label>Token Code</label>
          <span class="highlight"></span>
          <span class="bar"></span>
          <input type="text" v-model="tokenCode" autocomplete="off" required />
        </div>
        <button class="button is-success" @click="obtainAccessToken">
          Get Access Token
        </button>
        <div class="column is-5 group">
          <label>Access Token</label>
          <span class="highlight"></span>
          <span class="bar"></span>
          <input
            type="text"
            v-model="accessToken"
            autocomplete="off"
            required
          />
        </div>
        <div class="column is-5 group">
          <label>Refresh Token</label>
          <span class="highlight"></span>
          <span class="bar"></span>
          <input
            type="text"
            v-model="refreshToken"
            autocomplete="off"
            required
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import GenericToolBar from "@/components/Data/GenericToolbar.vue";
import { userService } from "@/api/user-auth";

export default {
  name: "AuthLanding",
  props: {},
  data() {
    return {
      tokenCode: "",
      accessToken: "",
      refreshToken: "",
    };
  },
  components: {},
  mounted() {
    this.tokenCode = this.$route.query.code;
  },
  watch: {},
  methods: {
    obtainAccessToken() {
      userService
        .post(`https://enterprise-sandbox-au.simprosuite.com/oauth2/token`, {
          grant_type: "authorization_code",
          client_id: "d81734c8e8e090d7addd504aa057ea",
          client_secret: "0e57a5a472",
          code: this.tokenCode,
          state: "",
        })
        .then((result) => {
          this.accessToken = result.access_token;
          this.refreshToken = result.refresh_token;
        })
        .catch((error) => {
          console.log(error);
          //this.resultsGridOptions.api.hideOverlay();
        })
        .finally(() => {
          this.runningProcessSet = false;
        });
    },
  },
  computed: {},
};
</script>
