import {
  Toolbar,
  Toast,
  DateHelper,
  CSSHelper,
  ProjectModel,
} from "@bryntum/gantt";

/**
 * @module GanttToolbar
 */

/**
 * @extends Core/widget/Toolbar
 */
export default class GanttToolbar extends Toolbar {
  // Factoryable type name
  static get type() {
    return "gantttoolbar";
  }

  static get $name() {
    return "GanttToolbar";
  }

  // Called when toolbar is added to the Gantt panel
  set parent(parent) {
    super.parent = parent;
    const me = this;

    me.gantt = parent;

    parent.project.on({
      load: "updateStartDateField",
      refresh: "updateStartDateField",
      thisObj: me,
    });
    me.styleNode = document.createElement("style");
    document.head.appendChild(me.styleNode);
  }

  get parent() {
    return super.parent;
  }

  static get configurable() {
    return {
      //enableUndoRedoKeys: true,
      items: [
        {
          type: "buttonGroup",
          style: "position: absolute;  height: 60%; right: 4.5%; top: 15px;",
          items: [
            // {
            //   allowBlank: false,
            //   type: "FilePicker",
            //   name: "mpp-file",
            //   multiple: false,
            //   text: "Import from MS Project",
            //   onChange: "up.onFileChange",
            //   buttonConfig: {
            //     iconCls: "fa fa-folder-open",
            //   },
            // },
            // {
            //   style: "background-color:transparent;",
            //   ref: "loadFileBtn",
            //   icon: "fas fa-file-upload",
            //   tooltip: "Load File",
            //   onAction: "up.onLoadFileClick",
            // },
            {
              type: "datefield",
              label: "Reporting date",
              ref: "reportingDate",
              step: "1d",
              format: "DD/MM/YY",
              inputWidth: "7.1em",
              /*  DateHelper: {
                locale: "en-AU",
              }, */
              listeners: {
                change: "up.onStatusDateChange",
              },
            },
            {
              label: "Show Resources",
              ref: "showSchedule",
              tooltip: "Show Resources",
              icon: "far fa-eye-slash",
              onAction: "up.onShowResourcesChange",
            },
            {
              type: "buttonGroup",
              items: [
                {
                  id: "abccd",
                  style: "background-color:transparent;",
                  ref: "saveUpdatesBtn",
                  icon: "fas fa-save",
                  tooltip: "Save Changes",
                  onAction: "up.onSaveClick",
                  //disabled: "up.onSaveDisbaleClick",
                  /* bind: {
                    disabled: "up.isSaveClicked",
                  }, */
                  //  disabled: "",
                },
              ],
            },
            {
              type: "buttonGroup",
              items: [
                {
                  style: "background-color:transparent;",
                  ref: "addTaskButton",
                  icon: "fas fa-plus",
                  tooltip: "Create new task",
                  onAction: "up.onAddTaskClick",
                },
                {
                  style: "background-color:transparent;",
                  ref: "addBaselineBtn",
                  icon: "fas fa-plus-square",
                  tooltip: "Create Baseline",
                  onAction: "up.onCreateBaselineClick",
                },
                // {
                //   type: "button",
                //   text: "Show baseline",
                //   ref: "showBaseline",
                //   icon: "b-fa-bars",
                //   onItem: "up.toggleBaselineVisible",
                //   menu: [],
                //   //disabled: true,
                // },
              ],
            },
            {
              type: "buttonGroup",
              items: [
                {
                  style: "background-color:transparent;",
                  ref: "editTaskButton",
                  icon: "fas fa-edit",

                  //   text: "Edit",
                  tooltip: "Edit selected task",
                  onAction: "up.onEditTaskClick",
                },
                {
                  ref: "undoRedo",
                  type: "undoredo",
                  items: {
                    transactionsCombo: {
                      width: 250,
                      displayValueRenderer(value, combo) {
                        // console.log(this.store.data, this.store, this);
                        const stmPos =
                          combo.up("gantt", true).project.stm.position || 0;
                        return (
                          stmPos +
                          " undo actions / " +
                          (this.store.count - stmPos) +
                          " redo actions"
                        );
                      },
                    },
                  },
                },
              ],
            },
            {
              type: "buttonGroup",
              style: "background-color:transparent;",
              items: [
                {
                  ref: "exportPDF",
                  icon: "b-fa-file-export",
                  tooltip: "Export To PDF",
                  style: "background-color:transparent;",
                  onAction: "up.onExportPDFClick",
                },
                {
                  ref: "exportMSP",
                  icon: "b-fa-file-export",
                  tooltip: "Export to MSP",
                  style: "background-color:transparent;",
                  onAction: "up.onExportMSPClick",
                },
              ],
            },
            {
              type: "buttonGroup",
              style: "background-color:transparent;",
              items: [
                {
                  ref: "expandAllButton",
                  icon: "b-fa b-fa-angle-double-down",
                  tooltip: "Expand all",
                  style: "background-color:transparent;",
                  onAction: "up.onExpandAllClick",
                },
                {
                  ref: "collapseAllButton",
                  icon: "b-fa b-fa-angle-double-up",
                  tooltip: "Collapse all",
                  style: "background-color:transparent;",
                  onAction: "up.onCollapseAllClick",
                },
              ],
            },

            {
              type: "buttonGroup",
              items: [
                {
                  ref: "zoomInButton",
                  icon: "b-fa b-fa-search-plus",
                  tooltip: "Zoom in",
                  style: "background-color:transparent;",
                  onAction: "up.onZoomInClick",
                },
                {
                  ref: "zoomOutButton",
                  icon: "b-fa b-fa-search-minus",
                  tooltip: "Zoom out",
                  style: "background-color:transparent;",
                  onAction: "up.onZoomOutClick",
                },
                {
                  ref: "zoomToFitButton",
                  icon: "b-fa b-fa-compress-arrows-alt",
                  tooltip: "Zoom to fit",
                  style: "background-color:transparent;",
                  onAction: "up.onZoomToFitClick",
                },
                {
                  ref: "previousButton",
                  icon: "b-fa b-fa-angle-left",
                  tooltip: "Previous time span",
                  style: "background-color:transparent;",
                  onAction: "up.onShiftPreviousClick",
                },
                {
                  ref: "nextButton",
                  icon: "b-fa b-fa-angle-right",
                  tooltip: "Next time span",
                  style: "background-color:transparent;",
                  onAction: "up.onShiftNextClick",
                },
              ],
            },
            {
              type: "buttonGroup",
              items: [
                {
                  type: "button",
                  ref: "featuresButton",
                  icon: "b-fa b-fa-tasks",
                  tooltip: "Toggle features",
                  toggleable: true,
                  menu: {
                    onItem: "up.onFeaturesClick",
                    onBeforeShow: "up.onFeaturesShow",
                    items: [
                      {
                        text: "Draw dependencies",
                        feature: "dependencies",
                        checked: false,
                      },
                      {
                        text: "Task labels",
                        feature: "labels",
                        checked: true,
                      },
                      {
                        text: "Project lines",
                        feature: "projectLines",
                        checked: false,
                      },
                      {
                        text: "Highlight non-working time",
                        feature: "nonWorkingTime",
                        checked: false,
                      },
                      {
                        text: "Enable cell editing",
                        feature: "cellEdit",
                        checked: false,
                      },
                      {
                        text: "Show baselines",
                        feature: "baselines",
                        checked: false,
                      },
                      {
                        text: "Show rollups",
                        feature: "rollups",
                        checked: false,
                      },
                      {
                        text: "Show progress line",
                        feature: "progressLine",
                        checked: false,
                      },
                      {
                        text: "Hide schedule",
                        cls: "b-separator",
                        subGrid: "normal",
                        checked: false,
                      },
                    ],
                  },
                },
                {
                  type: "button",
                  ref: "settingsButton",
                  icon: "b-fa b-fa-cogs",

                  tooltip: "Adjust settings",
                  toggleable: true,
                  menu: {
                    type: "popup",
                    anchor: true,
                    cls: "settings-menu",
                    layoutStyle: {
                      flexDirection: "column",
                    },
                    onBeforeShow: "up.onSettingsShow",

                    items: [
                      {
                        type: "slider",
                        ref: "rowHeight",
                        text: "Row height",
                        width: "12em",
                        showValue: true,
                        min: 30,
                        max: 70,
                        onInput: "up.onSettingsRowHeightChange",
                      },
                      {
                        type: "slider",
                        ref: "barMargin",
                        text: "Bar margin",
                        width: "12em",
                        showValue: true,
                        min: 0,
                        max: 10,
                        onInput: "up.onSettingsMarginChange",
                      },
                      {
                        type: "slider",
                        ref: "duration",
                        text: "Animation duration ",
                        width: "12em",
                        min: 0,
                        max: 2000,
                        step: 100,
                        showValue: true,
                        onInput: "up.onSettingsDurationChange",
                      },
                    ],
                  },
                },
                {
                  type: "button",
                  color: "b-red",
                  ref: "criticalPathsButton",
                  icon: "b-fa b-fa-fire",
                  tooltip: "Highlight critical paths",
                  toggleable: true,
                  onAction: "up.onCriticalPathsClick",
                },
              ],
            },
            {
              type: "datefield",
              ref: "startDateField",
              label: "Project start",
              format: "DD/MM/YY",
              // required  : true, (done on load)
              flex: "0 0 17em",
              listeners: {
                change: "up.onStartDateChange",
              },
            },
            {
              type: "textfield",
              ref: "filterByName",
              cls: "filter-by-name",
              flex: "0 0 12.5em",
              // Label used for material, hidden in other themes
              label: "Find tasks by name",
              // Placeholder for others
              placeholder: "Find tasks by name",
              clearable: true,
              width: 250,
              keyStrokeChangeDelay: 100,
              triggers: {
                filter: {
                  align: "end",
                  cls: "b-fa b-fa-filter",
                },
              },
              onChange: "up.onFilterChange",
            },
          ],
        },
      ],
    };
  }

  setAnimationDuration(value) {
    const me = this,
      cssText = `.b-animating .b-gantt-task-wrap { transition-duration: ${
        value / 1000
      }s !important; }  `;

    me.gantt.transitionDuration = value;

    if (me.transitionRule) {
      me.transitionRule.cssText = cssText;
    } else {
      me.transitionRule = CSSHelper.insertRule(cssText);
    }
  }

  updateStartDateField() {
    const { startDateField } = this.widgetMap;

    startDateField.value = this.gantt.project.startDate;

    // This handler is called on project.load/propagationComplete, so now we have the
    // initial start date. Prior to this time, the empty (default) value would be
    // flagged as invalid.
    startDateField.required = true;
  }
  async onSaveDisbaleClick() {
    //const { gantt } = this;
    this.trigger("onSaveDisbaleClick", null);
  }
  // region controller methods
  async onSaveClick() {
    const { gantt } = this;
    this.trigger("SaveGanttChanges", null);
  }
  async onCreateBaselineClick() {
    const { gantt } = this;
    this.trigger("ShowBaseline", null);
  }
  async onLoadFileClick() {
    //const { gantt } = this;
    this.trigger("LoadFile", null);
  }
  async onFileChange(event) {
    //console.log(event.files[0]);
    //if(event.value.toString() !== '')
    this.trigger("SetFile", event);
  }
  async onShowResourcesChange() {
    this.trigger("ShowHideResources", null);
  }
  async onAddTaskClick() {
    const { gantt } = this,
      added = gantt.taskStore.rootNode.appendChild({
        name: "New task",
        duration: 1,
      });
    // run propagation to calculate new task fields
    await gantt.project.propagateAsync();

    // scroll to the added task
    await gantt.scrollRowIntoView(added);
    //console.log(gantt.taskStore.allRecords);

    gantt.features.cellEdit.startEditing({
      record: added,
      field: "name",
    });
  }

  onEditTaskClick() {
    const { gantt } = this;
    if (gantt.selectedRecord) {
      gantt.editTask(gantt.selectedRecord);
    } else {
      Toast.show("First select the task you want to edit");
    }
  }

  onExpandAllClick() {
    this.gantt.expandAll();
  }

  onCollapseAllClick() {
    this.gantt.collapseAll();
  }

  onZoomInClick() {
    this.gantt.zoomIn();
  }

  onZoomOutClick() {
    this.gantt.zoomOut();
  }

  onZoomToFitClick() {
    this.gantt.zoomToFit({
      leftMargin: 50,
      rightMargin: 50,
    });
  }

  onShiftPreviousClick() {
    this.gantt.shiftPrevious();
  }
  onUndoTassk() {
    const { gantt } = this;
    // run propagation to calculate new task fields
    gantt.project.stm.undo();
  }
  onRedoTassk() {
    const { gantt } = this;
    // run propagation to calculate new task fields
    gantt.project.stm.redo();
  }
  onShiftNextClick() {
    this.gantt.shiftNext();
  }

  onStartDateChange({ value, oldValue }) {
    if (!oldValue) {
      // ignore initial set
      return;
    }

    this.gantt.startDate = DateHelper.add(value, -1, "week");

    this.gantt.project.setStartDate(value);
  }
  onStatusDateChange({ value, oldValue }) {
    this.gantt.features.progressLine.statusDate = value;
  }

  onFilterChange({ value }) {
    if (value === "") {
      this.gantt.taskStore.clearFilters();
    } else {
      value = value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

      this.gantt.taskStore.filter({
        filters: (task) => task.name && task.name.match(new RegExp(value, "i")),
        replace: true,
      });
    }
  }

  onFeaturesClick({ source: item }) {
    const { gantt } = this;

    if (item.feature) {
      const feature = gantt.features[item.feature];
      feature.disabled = !feature.disabled;
    } else if (item.subGrid) {
      const subGrid = gantt.subGrids[item.subGrid];
      subGrid.collapsed = !subGrid.collapsed;
    }
  }

  toggleBaselineVisible({ source: item }) {
    console.log("toggleBaselineVisible");
    const { gantt } = this;
    if (item._checked) {
      gantt.features.baselines.disabled = !item._checked;
    } else {
      var anyChecked = false;
      item._parent.items.forEach((x) => (anyChecked = anyChecked | x._checked));
      gantt.features.baselines.disabled = !anyChecked;
    }
    gantt.taskStore.allRecords.forEach((x) =>
      x.baselines.allRecords.forEach((y) => {
        //console.log(y);
        //console.log(item);
        var adding = item._checked & (y.name == item.text);
        //console.log(item._checked & (y.name == item.text) ? "remove" : "add");
        //if (adding) y.classList[adding ? "remove" : "add"](`hideBaseline`);
        //else y.removeCls(`hideBaseline`);
        //y.cls[(item._checked & y.name == item.text) ? "remove" : "add"](`hideBaseline`);
      })
    );
  }

  onFeaturesShow({ source: menu }) {
    const { gantt } = this;

    menu.items.map((item) => {
      const { feature } = item;

      if (feature) {
        // a feature might be not presented in the gantt
        // (the code is shared between "advanced" and "php" demos which use a bit different set of features)
        if (gantt.features[feature]) {
          item.checked = !gantt.features[feature].disabled;
        }
        // hide not existing features
        else {
          item.hide();
        }
      } else {
        item.checked = gantt.subGrids[item.subGrid].collapsed;
      }
    });
  }

  onSettingsShow({ source: menu }) {
    const { gantt } = this,
      { rowHeight, barMargin, duration } = menu.widgetMap;

    rowHeight.value = gantt.rowHeight;
    barMargin.value = gantt.barMargin;
    barMargin.max = gantt.rowHeight / 2 - 5;
    duration.value = gantt.transitionDuration;
  }

  onSettingsRowHeightChange({ value }) {
    this.gantt.rowHeight = value;
    this.widgetMap.settingsButton.menu.widgetMap.barMargin.max = value / 2 - 5;
  }

  onSettingsMarginChange({ value }) {
    this.gantt.barMargin = value;
  }

  onSettingsDurationChange({ value }) {
    this.gantt.transitionDuration = value;
    this.styleNode.innerHTML = `.b-animating .b-gantt-task-wrap { transition-duration: ${
      value / 1000
    }s !important; }`;
  }

  onCriticalPathsClick({ source }) {
    this.gantt.features.criticalPaths.disabled = !source.pressed;
  }

  onUndo() {
    this.gantt.project.stm.undo();
  }

  onRedo() {
    this.gantt.project.stm.redo();
  }

  async onExportPDFClick() {
    const { gantt } = this;
    this.trigger("onExportPDF", null);
    //
  }

  async onExportMSPClick() {
    const { gantt } = this;
    this.trigger("onExportMSP", null);
    //this.gantt.features.mspExport.export({ filename: "TEST MSP" });
  }
  // endregion
}

// Register this widget type with its Factory
GanttToolbar.initClass();
