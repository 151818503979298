import { userService } from "@/api/user-auth";
import * as moment from "moment";
const later = require("@breejs/later");
const tempDays = ["Sun", "Mon", "Tues", "Weds", "Thurs", "Fri", "Sat", "Sun"];

const state = {
  entitiesLoaded: false,
  enterpriseEntitiesLoaded: false,
  entitiesUpdated: false,
  enterpriseEntitiesUpdated: false,
  localAllEntities: [],
  localAllRelations: [],
  localAllResources: [],
  localAllAssignments: [],
  localAllCalendars: [],
  selectedEntitites: [],
  allBaselines: [],
  isSave: 0,
  isSaveClicked: false,
  pasteGanttStore: false,
  isCopyDone: false,
  isPasteDone: false,
  tempGanttRecords: null,
  isCopyGanttRecordStarted: false,
};

const actions = {
  updateActivity({ commit }, activityToUpdate) {
    var entityToUpdateInd = state.localAllEntities.findIndex(
      (x) => x.id == activityToUpdate.activityId
    );
    if (entityToUpdateInd > -1) {
      var entityToUpdate = state.localAllEntities[entityToUpdateInd];
      for (const property in activityToUpdate.changes) {
        entityToUpdate[property] = activityToUpdate.changes[property];
      }
    }
  },
  loadActivities({ commit, rootGetters }, scheduleHeaderId) {
    state.entitiesLoaded = false;
    userService
      .post(`Activity/GetByScheduleHeaderIdForGantt/` + scheduleHeaderId, {
        ReportingPeriodDate: new Date().toISOString(),
      })
      .then((result) => {
        if (result.succeeded && result.data != null) {
          result.data.taskStore.forEach((x) => (x.isUpdated = false));
          commit("mutateAllEntities", result.data.taskStore);
          result.data.dependencyStore.forEach((x) => (x.isUpdated = false));
          commit("mutateAllRelations", result.data.dependencyStore);
          result.data.resourceStore.forEach((x) => (x.isUpdated = false));
          commit("mutateAllResources", result.data.resourceStore);
          result.data.assignmentStore.forEach((x) => (x.isUpdated = false));
          commit("mutateAllAssignments", result.data.assignmentStore);
          result.data.calendarStore.forEach((x) => (x.isUpdated = false));
          commit("mutateAllCalendars", result.data.calendarStore);
          state.entitiesLoaded = true;
        }
      })
      .catch((error) => {
        //dispatch("alert/error", error, { root: true });
      });
  },

  loadAndCopyActivities({ commit, rootGetters }, scheduleHeaderId) {
    state.isCopyDone = false;
    state.entitiesLoaded = false;
    userService
      .post(`Activity/GetByScheduleHeaderIdForGantt/` + scheduleHeaderId, {
        ReportingPeriodDate: new Date().toISOString(),
      })
      .then((result) => {
        if (result.succeeded && result.data != null) {
          result.data.taskStore.forEach(function (row, index) {
            row.isUpdated = false;
            //row.WorkEffortUnit = null;
            if (row.earlyStartDate.length > 0) {
              row.earlyStartDate = new Date(row.earlyStartDate)
                .toISOString()
                .slice(0, -1);
            }
            if (row.earlyEndDate.length > 0) {
              row.earlyEndDate = new Date(row.earlyEndDate)
                .toISOString()
                .slice(0, -1);
            }
            row.phantomId = "_generatedClassDefEx" + row.id;
            if (row.parentId != 0) {
              let tempCheckParentId = result.data.taskStore.findIndex(
                (x) => x.id == row.parentId
              );
              if (tempCheckParentId < 0) {
                row.parentId = null;
              } else {
                row.parentId = "_generatedClassDefEx" + row.parentId;
                /*  row.phantomId =
                  "_generatedClassDefEx" +
                  result.data.taskStore[tempCheckParentId].id; //row.id; */
              }
            } else {
              row.parentId = null;
            }
          });
          /*  .sort((a, b) => {
              if (a.id === null) {
                return -1;
              }
              if (b.id === null) {
                return 1;
              }
              if (a.id == b.id) {
                return 0;
              }
              return a.id < b.id ? -1 : 1;
            }); */
          commit("mutateAllEntities", result.data.taskStore);
          result.data.dependencyStore.forEach(function (row, index) {
            row.isUpdated = false;
            row.ActivityRelationType = row.activityRelationType;
            row.phantomId = "_generatedClassDefEx" + row.id;
            row.id = null;
            if (row.predecessorActivityId != null)
              row.predecessorActivityId =
                "_generatedClassDefEx" + row.predecessorActivityId;
            if (row.successorActivityId != null)
              row.successorActivityId =
                "_generatedClassDefEx" + row.successorActivityId;
            delete row.toTask; //= "_generatedClassDefEx" + row.toTask;
            delete row.fromTask; // = "_generatedClassDefEx" + row.fromTask;
            delete row.activityRelationType;
          });
          commit("mutateAllRelations", result.data.dependencyStore);
          result.data.resourceStore.forEach((x) => (x.isUpdated = false));
          commit("mutateAllResources", result.data.resourceStore);
          result.data.assignmentStore.forEach(function (row, index) {
            row.isUpdated = false;
            index++;
            row.resourceId = row.resource;
            row.EventId = "_generatedClassDefEx" + row.event;
            row.phantomId = "_generatedClassDefEx" + index;
            row.id = null;
          });
          commit("mutateAllAssignments", result.data.assignmentStore);
          result.data.calendarStore.forEach((x) => (x.isUpdated = false));
          commit("mutateAllCalendars", result.data.calendarStore);
          state.isCopyDone = true;

          //state.tempGanttRecords = result;
        }
      })
      .catch((error) => {
        //dispatch("alert/error", error, { root: true });
      });
  },
  loadEnterpriseActivities({ commit, rootGetters }, scheduleHeaderId) {
    state.enterpriseEntitiesLoaded = false;
    userService
      .post(`Activity/LoadEnterpriseResourceAssignmentsForGantt/`)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          result.data.taskStore.forEach((x) => (x.isUpdated = false));
          commit("mutateAllEntities", result.data.taskStore);
          result.data.dependencyStore.forEach((x) => (x.isUpdated = false));
          commit("mutateAllRelations", result.data.dependencyStore);
          result.data.resourceStore.forEach((x) => (x.isUpdated = false));
          commit("mutateAllResources", result.data.resourceStore);
          result.data.assignmentStore.forEach((x) => (x.isUpdated = false));
          commit("mutateAllAssignments", result.data.assignmentStore);
          result.data.calendarStore.forEach((x) => (x.isUpdated = false));
          commit("mutateAllCalendars", result.data.calendarStore);
          state.enterpriseEntitiesLoaded = true;
        }
      })
      .catch((error) => {
        //dispatch("alert/error", error, { root: true });
      });
  },
  updateActivities({ commit, rootGetters }, batchActivitiesObj) {
    //console.log("In updateActivities");
    //console.log(batchActivitiesObj);
    if (batchActivitiesObj.ActivitiesToUpdate.length > 0) {
      for (let i = 0; i < batchActivitiesObj.ActivitiesToUpdate.length; i++) {
        var updatedActivity = batchActivitiesObj.ActivitiesToUpdate[i]._data;
        var entityToUpdateInd = state.localAllEntities.findIndex(
          (obj) => obj.id === updatedActivity.id
        );
        updatedActivity.plannedStartDate =
          batchActivitiesObj.ActivitiesToUpdate[i]._data.startDate;
        updatedActivity.plannedEndDate =
          batchActivitiesObj.ActivitiesToUpdate[i]._data.endDate;
        updatedActivity.comment =
          batchActivitiesObj.ActivitiesToUpdate[i]._data.note;
        updatedActivity.isUpdated = true;
        updatedActivity.plannedDuration =
          batchActivitiesObj.ActivitiesToUpdate[i]._data.duration;
        updatedActivity.plannedDurationUnit =
          batchActivitiesObj.ActivitiesToUpdate[i]._data.durationUnit;
        batchActivitiesObj.ActivitiesToUpdate[i]._data.baselines =
          batchActivitiesObj.ActivitiesToUpdate[i].baselines.allRecords.map(
            function (item) {
              return {
                id: item.id,
                startDate: item.startDate,
                endDate: item.endDate,
                code: item.name,
                scheduleHeaderId: currSHId,
              };
            }
          );
        commit("mutateEntity", {
          Index: entityToUpdateInd,
          updatedEntity: updatedActivity,
        });
      }
    }
    if (batchActivitiesObj.ActivitiesToAdd.length > 0) {
      batchActivitiesObj.ActivitiesToAdd.sort(compareActivities);
      //  console.log(batchActivitiesObj.ActivitiesToAdd);
      var currSHId = rootGetters["schedulingDataManager/selectedEntityId"];
      for (let i = 0; i < batchActivitiesObj.ActivitiesToAdd.length; i++) {
        // console.log("Adding");
        // console.log(batchActivitiesObj.ActivitiesToAdd[i]._data);
        batchActivitiesObj.ActivitiesToAdd[i]._data.phantomId =
          batchActivitiesObj.ActivitiesToAdd[i]._data.id;
        if (!isValid(batchActivitiesObj.ActivitiesToAdd[i]._data.inactive))
          batchActivitiesObj.ActivitiesToAdd[i]._data.inactive = false;
        batchActivitiesObj.ActivitiesToAdd[i]._data.id = null;
        // batchActivitiesObj.ActivitiesToAdd[i]._data.comment =
        //   batchActivitiesObj.ActivitiesToUpdate[i]._data.note;
        batchActivitiesObj.ActivitiesToAdd[i]._data.scheduleHeaderId = currSHId;
        batchActivitiesObj.ActivitiesToAdd[i].plannedStartDate =
          batchActivitiesObj.ActivitiesToAdd[i]._data.startDate;
        batchActivitiesObj.ActivitiesToAdd[i].plannedEndDate =
          batchActivitiesObj.ActivitiesToAdd[i]._data.endDate;
        batchActivitiesObj.ActivitiesToAdd[i]._data.baselines =
          batchActivitiesObj.ActivitiesToAdd[i].baselines.allRecords.map(
            function (item) {
              return {
                id: item.id,
                startDate: item.startDate,
                endDate: item.endDate,
                code: item.code,
                scheduleHeaderId: currSHId,
              };
            }
          );
        commit("addEntity", batchActivitiesObj.ActivitiesToAdd[i]._data);
      }
    }
    if (batchActivitiesObj.ActivitiesToDelete.length > 0) {
      for (let i = 0; i < batchActivitiesObj.ActivitiesToDelete.length; i++) {
        var entityToDeleteInd = state.localAllEntities.findIndex(
          (obj) => obj.id === batchActivitiesObj.ActivitiesToDelete[i].id
        );
        var activityToDelete = batchActivitiesObj.ActivitiesToDelete[i]._data;
        activityToDelete.isDeleted = true;
        commit("mutateEntity", {
          Index: entityToDeleteInd,
          updatedEntity: activityToDelete,
        });
      }
    }
  },
  updateRelations({ commit, rootGetters }, batchRelationsObj) {
    if (batchRelationsObj.RelationsToUpdate.length > 0) {
      for (let i = 0; i < batchRelationsObj.RelationsToUpdate.length; i++) {
        var updatedRelation = batchRelationsObj.RelationsToUpdate[i]._data;
        var entityToUpdateInd = state.localAllRelations.findIndex(
          (obj) => obj.id === updatedRelation.id
        );
        updatedRelation.isUpdated = true;
        //console.log("updating relation");
        //console.log(updatedRelation);
        commit("mutateRelationEntity", {
          Index: entityToUpdateInd,
          updatedEntity: updatedRelation,
        });
      }
    }
    for (let i = 0; i < batchRelationsObj.RelationsToAdd.length; i++) {
      var relationToAdd = {
        PredecessorActivityId: isValid(
          batchRelationsObj.RelationsToAdd[i].fromTask.id
        )
          ? batchRelationsObj.RelationsToAdd[i].fromTask.id
          : batchRelationsObj.RelationsToAdd[i].fromTask.phantomId,
        PredecessorScheduleId:
          batchRelationsObj.RelationsToAdd[i].fromTask.scheduleHeaderId,
        SuccessorActivityId: isValid(
          batchRelationsObj.RelationsToAdd[i].toTask.id
        )
          ? batchRelationsObj.RelationsToAdd[i].toTask.id
          : batchRelationsObj.RelationsToAdd[i].toTask.phantomId,
        SuccessorScheduleId:
          batchRelationsObj.RelationsToAdd[i].toTask.scheduleHeaderId,
        LagTime: batchRelationsObj.RelationsToAdd[i]._data.lag,
        LagUnit: batchRelationsObj.RelationsToAdd[i]._data.lagUnit,
        ActivityRelationType: batchRelationsObj.RelationsToAdd[i]._data.type,
        phantomId: batchRelationsObj.RelationsToAdd[i]._data.id,
        id: null,
      };
      commit("addRelationEntity", relationToAdd);
      //console.log("After Adding");
      //console.log(state.localAllRelations);
    }

    if (batchRelationsObj.RelationsToDelete.length > 0) {
      for (let i = 0; i < batchRelationsObj.RelationsToDelete.length; i++) {
        var entityToDeleteInd = state.localAllRelations.findIndex(
          (obj) => obj.id === batchRelationsObj.RelationsToDelete[i].id
        );
        var relationToDelete = batchRelationsObj.RelationsToDelete[i]._data;
        relationToDelete.isDeleted = true;
        commit("mutateRelationEntity", {
          Index: entityToDeleteInd,
          updatedEntity: relationToDelete,
        });
      }
    }
  },
  updateAssignments({ commit, rootGetters }, batchAssignmentObj) {
    // console.log("Assignments to update");
    // console.log(batchAssignmentObj.AssignmentsToUpdate);
    if (batchAssignmentObj.AssignmentsToUpdate.length > 0) {
      for (let i = 0; i < batchAssignmentObj.AssignmentsToUpdate.length; i++) {
        var updatedAssignment = batchAssignmentObj.AssignmentsToUpdate[i]._data;
        var entityToUpdateInd = state.localAllAssignments.findIndex(
          (obj) => obj.id === updatedAssignment.id
        );
        updatedAssignment.isUpdated = true;
        commit("mutateAssignmentEntity", {
          Index: entityToUpdateInd,
          updatedEntity: updatedAssignment,
        });
      }
    }
    // console.log("Assignments to Add");
    // console.log(batchAssignmentObj.AssignmentsToAdd);
    if (batchAssignmentObj.AssignmentsToAdd.length > 0) {
      for (let i = 0; i < batchAssignmentObj.AssignmentsToAdd.length; i++) {
        var assignmentToAdd = {
          EventId: isValid(batchAssignmentObj.AssignmentsToAdd[i]._data.eventId)
            ? batchAssignmentObj.AssignmentsToAdd[i]._data.eventId
            : batchAssignmentObj.AssignmentsToAdd[i]._data.event.phantomId,
          phantomId: batchAssignmentObj.AssignmentsToAdd[i]._data.id,
          id: null,
          ResourceId: isValid(
            batchAssignmentObj.AssignmentsToAdd[i]._data.resourceId
          )
            ? batchAssignmentObj.AssignmentsToAdd[i]._data.resourceId
            : batchAssignmentObj.AssignmentsToAdd[i]._data.resource.phantomId,
          units: batchAssignmentObj.AssignmentsToAdd[i]._data.units,
          scheduleHeaderId:
            rootGetters["schedulingDataManager/selectedEntityId"],
        };
        commit("addAssignmentEntity", assignmentToAdd);
      }
    }
    // console.log("Assignments to delete");
    // console.log(batchAssignmentObj.AssignmentsToDelete);
    if (batchAssignmentObj.AssignmentsToDelete.length > 0) {
      for (let i = 0; i < batchAssignmentObj.AssignmentsToDelete.length; i++) {
        var entityToDeleteInd = state.localAllAssignments.findIndex(
          (obj) => obj.id === batchAssignmentObj.AssignmentsToDelete[i].id
        );
        var assignmentToDelete =
          batchAssignmentObj.AssignmentsToDelete[i]._data;
        assignmentToDelete.isDeleted = true;
        // console.log('Marking as deleted');
        // console.log(assignmentToDelete);
        commit("mutateAssignmentEntity", {
          Index: entityToDeleteInd,
          updatedEntity: assignmentToDelete,
        });
      }
    }
  },
  saveUpdates({ commit, dispatch, rootGetters }, reportingDate) {
    state.entitiesUpdated = false;
    var baselineStore = {
      baselinesToCreate: rootGetters["baselines/baselinesToAdd"],
    };
    var activityStore = {
      activitiesToCreate: state.localAllEntities.filter((x) => x.id === null),
      activitiesToUpdate: state.localAllEntities.filter(
        (x) => x.isUpdated === true
      ),
      activityIdsToDelete: state.localAllEntities
        .filter((x) => x.isDeleted === true)
        .map((a) => a.id),
    };
    state.localAllEntities.forEach((x) => (x.isUpdated = false));
    var dependencyStore = {
      dependenciesToCreate: state.localAllRelations.filter(
        (x) => x.id === null
      ),
      dependenciesToUpdate: state.localAllRelations.filter(
        (x) => x.isUpdated == true
      ),
      dependenciesIdsToDelete: state.localAllRelations
        .filter((x) => x.isDeleted === true)
        .map((a) => a.id),
    };
    state.localAllRelations.forEach((x) => (x.isUpdated = false));
    var assignmentStore = {
      assignmentsToCreate: state.localAllAssignments.filter(
        (x) => x.id === null
      ),
      assignmentsToUpdate: state.localAllAssignments.filter(
        (x) => x.isUpdated === true
      ),
      assignmentIdsToDelete: state.localAllAssignments
        .filter((x) => x.isDeleted === true)
        .map((a) => a.id),
    };
    state.localAllAssignments.forEach((x) => (x.isUpdated = false));

    var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = new Date(reportingDate - tzoffset)
      .toISOString()
      .slice(0, -1);
    state.isSaveClicked = true;

    userService
      .post(`Activity/Batch`, {
        BaselineStore: baselineStore,
        ActivityStore: activityStore,
        DependenciesStore: dependencyStore,
        ReportingDate: localISOTime,
        AssignmentsStore: assignmentStore,
      })
      .then((result) => {
        if (result.succeeded && result.data != null) {
          if (isValid(result.data.lastSavedReportingPeriod)) {
            var updatedActivities = state.localAllEntities.map((item) => {
              item.reportingPeriodStartDate =
                result.data.lastSavedReportingPeriod.startDate;
              item.reportingPeriodEndDate =
                result.data.lastSavedReportingPeriod.endDate;
              return item;
            });

            commit("mutateAllEntitiesAfterSave", updatedActivities);
          }
          if (isValid(result.data.taskStore)) {
            for (let i = 0; i < result.data.taskStore.length; i++) {
              var entityToUpdateInd = state.localAllEntities.findIndex(
                (x) =>
                  x.id === null &&
                  x.phantomId === result.data.taskStore[i].phantomId
              );
              commit("mutateEntity", {
                Index: entityToUpdateInd,
                updatedEntity: result.data.taskStore[i],
              });
            }
          }
          dispatch("removeDeletedActivities");

          if (isValid(result.data.dependencyStore)) {
            for (let i = 0; i < result.data.dependencyStore.length; i++) {
              var dependencyToUpdateInd = state.localAllRelations.findIndex(
                (x) =>
                  x.id === null &&
                  x.phantomId === result.data.dependencyStore[i].phantomId
              );
              commit("mutateRelationEntity", {
                Index: dependencyToUpdateInd,
                updatedEntity: result.data.dependencyStore[i],
              });
            }
          }
          dispatch("removeDeletedDependencies");

          if (isValid(result.data.assignmentStore)) {
            for (let i = 0; i < result.data.assignmentStore.length; i++) {
              var AssignmentToUpdateInd = state.localAllAssignments.findIndex(
                (x) =>
                  x.id === null &&
                  x.phantomId === result.data.assignmentStore[i].phantomId
              );
              commit("mutateAssignmentEntity", {
                Index: AssignmentToUpdateInd,
                updatedEntity: result.data.assignmentStore[i],
              });
            }
          }
          dispatch("removeDeletedAssignments");
          state.isSave++;
          state.entitiesUpdated = true;
          state.isSaveClicked = false;
        }
      });
  },
  removeDeletedDependencies({ commit }) {
    var recordsToDelete = state.localAllRelations.filter(
      (x) => x.isDeleted === true
    );
    if (recordsToDelete.length >= 1) {
      for (let i = 0; i < recordsToDelete.length; i++) {
        var toDeleteInd = state.localAllRelations.findIndex(
          (x) => x.id === recordsToDelete[i].id
        );
        commit("removeRelationEntity", { Index: toDeleteInd });
      }
    }
  },
  removeDeletedActivities({ commit }) {
    var recordsToDelete = state.localAllEntities.filter(
      (x) => x.isDeleted === true
    );
    if (recordsToDelete.length >= 1) {
      for (let i = 0; i < recordsToDelete.length; i++) {
        var toDeleteInd = state.localAllEntities.findIndex(
          (x) => x.id === recordsToDelete[i].id
        );
        commit("removeEntity", { Index: toDeleteInd });
      }
    }
  },
  removeDeletedAssignments({ commit }) {
    var recordsToDelete = state.localAllAssignments.filter(
      (x) => x.isDeleted === true
    );
    if (recordsToDelete.length >= 1) {
      for (let i = 0; i < recordsToDelete.length; i++) {
        var AssignmentToDeleteInd = state.localAllAssignments.findIndex(
          (x) => x.id === recordsToDelete[i].id
        );
        commit("removeAssignmentEntity", { Index: AssignmentToDeleteInd });
      }
    }
  },
  addActivities({ commit }, addActivitiesObj) {
    //console.log("Adding");
    //console.log(addActivitiesObj.ActivitiesToAdd);
    for (let i = 0; i < addActivitiesObj.ActivitiesToAdd.length; i++) {
      addActivitiesObj.ActivitiesToAdd[i].id = null;
      commit("addEntity", addActivitiesObj.ActivitiesToAdd[i]);
    }
  },

  updateCopiedAssignments({ commit, rootGetters }, AssignmentsToAdd) {
    //console.log("Assignments to update");
    // console.log(batchAssignmentObj.AssignmentsToUpdate);

    // console.log("Assignments to Add");
    // console.log(batchAssignmentObj.AssignmentsToAdd);
    if (AssignmentsToAdd.length > 0) {
      for (let i = 0; i < AssignmentsToAdd.length; i++) {
        var assignmentToAdd = {
          EventId: isValid(AssignmentsToAdd[i].event)
            ? AssignmentsToAdd[i].event
            : AssignmentsToAdd[i].phantomId,
          phantomId: AssignmentsToAdd[i].id,
          id: null,
          ResourceId: isValid(AssignmentsToAdd[i].resourceId)
            ? AssignmentsToAdd[i].resourceId
            : AssignmentsToAdd[i].resource.phantomId,
          units: AssignmentsToAdd[i].units,
          scheduleHeaderId:
            rootGetters["schedulingDataManager/selectedEntityId"],
        };
        //commit("addAssignmentEntity", assignmentToAdd);
      }
    }
  },
  saveCopiedRecord({ commit, dispatch, rootGetters }, reportingDate) {
    //reportingDate = new Date(new Date(reportingDate) - 1000 * 60 * 60 * 24 * 1);
    var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = new Date(reportingDate).toISOString().slice(0, -1); //new Date(reportingDate - tzoffset)
    //.toISOString()
    //.slice(0, -1);
    //localISOTime = localISOTime.setDate(localISOTime.getDate() - 1);
    dispatch("updateCopiedAssignments", state.localAllAssignments);

    var baselineStore = {
      baselinesToCreate: rootGetters["baselines/baselinesToAdd"],
    };
    var activityStore = {
      activitiesToCreate: state.localAllEntities,
      activitiesToUpdate: [],
      activityIdsToDelete: [],
    };
    var dependencyStore = {
      dependenciesToCreate: state.localAllRelations,
      dependenciesToUpdate: [],
      dependenciesIdsToDelete: [],
    };
    state.localAllRelations.forEach((x) => (x.isUpdated = false));
    var assignmentStore = {
      assignmentsToCreate: state.localAllAssignments,
      assignmentsToUpdate: [],
      assignmentIdsToDelete: [],
    };
    state.localAllAssignments.forEach((x) => (x.isUpdated = false));

    userService
      .post(`Activity/Batch`, {
        BaselineStore: baselineStore,
        ActivityStore: activityStore,
        DependenciesStore: dependencyStore,
        ReportingDate: localISOTime,
        AssignmentsStore: assignmentStore,
      })
      .then((result) => {
        state.isCopyGanttRecordStarted = false;
        state.isCopyDone = false;
        state.isPasteDone = false;
        state.tempGanttRecords = null;
        state.isCopyGanttRecordStarted = false;
        if (result.succeeded && result.data != null) {
          state.localAllAssignments = [];
          state.localAllEntities = [];
          state.localAllRelations = [];
          state.localAllCalendars = [];
          baselineStore = {};
          console.info("Rows Copied successfully");
        }
      });
  },
};
function compareActivities(a, b) {
  var aCl = a.childLevel;
  var bCl = b.childLevel;
  var aWbs = +a.wbsCode.replaceAll(".", "");
  var bWbs = +b.wbsCode.replaceAll(".", "");
  return aCl < bCl
    ? -1
    : aCl > bCl
    ? 1
    : aWbs < bWbs
    ? -1
    : aWbs > bWbs
    ? 1
    : 0;
}
function isValid(valueToCheck) {
  if (
    String(valueToCheck) != "" &&
    String(valueToCheck) != "null" &&
    String(valueToCheck) != "undefined"
  )
    return true;
  else {
    return false;
  }
}

function treeify(list, idAttr, parentAttr, childrenAttr) {
  if (!idAttr) idAttr = " id ";
  if (!parentAttr) parentAttr = "parent ";
  if (!childrenAttr) childrenAttr = "children";
  var treeList = [];
  var lookup = {};
  list.forEach(function (obj) {
    lookup[obj[idAttr]] = obj;
    obj[childrenAttr] = [];
  });
  list.forEach(function (obj) {
    if (obj[parentAttr] != null) {
      if (lookup[obj[parentAttr]] !== undefined) {
        lookup[obj[parentAttr]][childrenAttr].push(obj);
      } else {
        treeList.push(obj);
      }
    } else {
      treeList.push(obj);
    }
  });
  return treeList;
}
const mutations = {
  mutateAllEntities(state, result) {
    state.localAllEntities = result;
  },

  mutateAllEntitiesAfterSave(state, result) {
    //console.log("1", "mutateAllEntities");
    state.localAllEntities = result;
  },
  mutateEntity(state, result) {
    state.localAllEntities[result.Index] = result.updatedEntity;
    //console.log("2", "mutateEntity");
    // for (var prop in state.localAllEntities[result.Index]) {
    //   if (Object.prototype.hasOwnProperty.call(result.updatedEntity, prop)) {
    //     state.localAllEntities[result.Index][prop] = result.updatedEntity[prop];
    //   }
    // }
    //state.localAllEntities[result.Index].isUpdated = true;
  },
  addEntity(state, newEntity) {
    state.localAllEntities.push(newEntity);
  },
  removeEntity(state, result) {
    state.localAllEntities.splice(result.Index, 1);
    //console.log("3", "removeEntity");
  },
  mutateAllRelations(state, result) {
    state.localAllRelations = result;
  },
  mutateRelationEntity(state, result) {
    state.localAllRelations[result.Index] = result.updatedEntity;
    //console.log("4", "Relationships");
    // for (var prop in state.localAllRelations[result.Index]) {
    //   if (Object.prototype.hasOwnProperty.call(result.updatedEntity, prop)) {
    //     state.localAllRelations[result.Index][prop] =
    //       result.updatedEntity[prop];
    //   }
    // }
    //state.localAllRelations[result.Index].isUpdated = true;
  },
  addRelationEntity(state, newEntity) {
    state.localAllRelations.push(newEntity);
  },
  removeRelationEntity(state, result) {
    state.localAllRelations.splice(result.Index, 1);
    //console.log("5", "RemoveRelation");
  },
  mutateAllResources(state, result) {
    state.localAllResources = result;
  },
  addResourceEntity(state, newEntity) {
    state.localAllResources.push(newEntity);
  },
  mutateResourceEntity(state, result) {
    for (var prop in state.localAllResources[result.Index]) {
      if (Object.prototype.hasOwnProperty.call(result.updatedEntity, prop)) {
        state.localAllResources[result.Index][prop] =
          result.updatedEntity[prop];
      }
    }
    //state.localAllResources[result.Index].isUpdated = true;
  },

  mutateAllAssignments(state, result) {
    state.localAllAssignments = result;
  },
  addAssignmentEntity(state, newEntity) {
    state.localAllAssignments.push(newEntity);
  },
  removeAssignmentEntity(state, result) {
    state.localAllAssignments.splice(result.Index, 1);
    //console.log("7", "RemoveAssignments");
  },
  mutateAssignmentEntity(state, result) {
    state.localAllAssignments[result.Index] = result.updatedEntity;
    //console.log("6");
    // console.log("Updating");
    // console.log(state.localAllAssignments[result.Index]);
    // for (var prop in state.localAllAssignments[result.Index]) {
    //   if (Object.prototype.hasOwnProperty.call(result.updatedEntity, prop)) {
    //     state.localAllAssignments[result.Index][prop] =
    //       result.updatedEntity[prop];
    //   }
    // }
    //state.localAllAssignments[result.Index].isUpdated = true;
  },

  mutateAllCalendars(state, result) {
    //console.log("Updating");
    var tempRecurrentFrom = null,
      tempRecurrentTo = null,
      tempWeekDays = null,
      TempString = " ";
    result = JSON.parse(JSON.stringify(result));
    result.forEach(function (row) {
      row.intervals.forEach(function (element) {
        if (element.recurrentStartDate != null) {
          tempRecurrentFrom = element.recurrentStartDate.split(" ");
          tempRecurrentTo = element.recurrentEndDate.split(" ");
          delete element.startDate;
          delete element.endDate;

          if (tempRecurrentFrom[4] == "6-7") {
            element.recurrentStartDate =
              "every weekend at " +
              tempRecurrentFrom[1] +
              ":" +
              tempRecurrentFrom[0];

            element.recurrentEndDate =
              "every weekend at " +
              tempRecurrentTo[1] +
              ":" +
              tempRecurrentTo[0];
          } else if (tempRecurrentFrom[4] == "1-5") {
            element.recurrentStartDate =
              "every weekday at " +
              tempRecurrentFrom[1] +
              ":" +
              tempRecurrentFrom[0];

            element.recurrentEndDate =
              "every weekday at " +
              tempRecurrentTo[1] +
              ":" +
              tempRecurrentTo[0];
          } else {
            tempWeekDays = tempRecurrentFrom[4].split(",");

            tempWeekDays.forEach((x) => {
              if (x != null) TempString += tempDays[x] + ",";
            });
            element.recurrentStartDate =
              "on " +
              TempString.slice(0, -1) +
              " at " +
              tempRecurrentFrom[1] +
              ":" +
              tempRecurrentFrom[0];

            element.recurrentEndDate =
              "on " +
              TempString.slice(0, -1) +
              " at " +
              tempRecurrentTo[1] +
              ":" +
              tempRecurrentTo[0];
          }
        } else {
          delete element.recurrentEndDate;
          delete element.recurrentStartDate;
        }
      });
    });
    //console.log(result);
    state.localAllCalendars = result;
  },

  mutatePasteRecords(state, { flag, scheduleHeaderRecord }) {
    state.isPasteDone = false;
    state.pasteGanttStore = flag;
    if (flag && scheduleHeaderRecord != null) {
      state.localAllEntities.forEach(function (row, index) {
        row.scheduleHeaderId = scheduleHeaderRecord.id;
        row.id = null;
      });
      state.localAllRelations.forEach(function (row, index) {
        row.predecessorScheduleId = scheduleHeaderRecord.id;
        row.successorScheduleId = scheduleHeaderRecord.id;
      });
      state.localAllAssignments.forEach(function (row, index) {
        row.scheduleHeaderId = scheduleHeaderRecord.id;
        delete row.event;
        delete row.resource;
      });
      state.isPasteDone = true;
      //state.entitiesLoaded = true;
    }
  },
  mutateGanttCopy(state) {
    state.isCopyGanttRecordStarted = true;
  },
};

const getters = {
  allEntities(state) {
    return state.localAllEntities;
  },
  allEntitiesAsTree(state) {
    return treeify(state.localAllEntities, "id", "parentId", "children");
  },

  allEntitiesCount(state) {
    return state.localAllEntities.length;
  },
  allRelations() {
    return state.localAllRelations;
  },
  allResources() {
    return state.localAllResources;
  },
  allAssignments() {
    return state.localAllAssignments;
  },
  allCalendars() {
    return state.localAllCalendars;
  },
  entitiesLoaded(state) {
    return state.entitiesLoaded;
  },
  entitiesUpdated(state) {
    return state.entitiesUpdated;
  },
  enterpriseEntitiesLoaded(state) {
    return state.enterpriseEntitiesLoaded;
  },
  enterpriseEntitiesUpdated(state) {
    return state.enterpriseEntitiesUpdated;
  },
};

export const activities = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
