<template>
  <div style="height: 100%; background-color: white">
    <div class="line"></div>
    <div class="columns" style="height: 100%; width: 100%">
      <div class="container-grid" style="width: 100%; height: 100%">
        <div class="box" style="height: 100%" ref="scriptContainerRiskHeader">
          <risk-toolbar />
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="multiple"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            :enableRangeSelection="true"
            :undoRedoCellEditing="true"
            :autoGroupColumnDef="autoGroupColumnDef"
            :columnDefs="columnRiskHeaderDefs"
            :rowData="rowParentData"
            :treeData="true"
            :defaultColDef="defaultHeaderColDef"
            :getDataPath="getDataPath"
            :components="riskHeadercomponents"
            :gridOptions="gridParentOptions"
            :overlayLoadingTemplate="loadingTemplate"
            :overlayNoRowsTemplate="noRowsTemplate"
            :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
            :detailCellRendererParams="detailCellRendererParams"
            @cellClicked="cellClicked"
            @cell-value-changed="onCellValueChanged"
            @grid-ready="onHeaderGridReady"
            @sort-changed="onHeaderColumnChanged"
            @column-resized="onHeaderColumnChanged"
            @column-visible="onHeaderColumnChanged"
            @column-row-group-changed="onHeaderColumnChanged"
            @column-value-changed="onHeaderColumnChanged"
            @column-moved="onHeaderColumnChanged"
            @column-pinned="onHeaderColumnChanged"
          >
          </ag-grid-vue>
          <!--  :columnDefs="columnStructureDefs" -->
        </div>
        <div class="handler"></div>
        <div class="box" ref="scriptContainerRiskItems">
          <risk-item-toolbar />

          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="multiple"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            :enableRangeSelection="true"
            :columnDefs="columnRiskItemDefs"
            :rowData="rowRiskItemData"
            :gridOptions="gridRiskItemOptions"
            :animateRows="true"
            :defaultColDef="defaultRiskItemColDef"
            :groupDefaultExpanded="groupDefaultExpanded"
            :overlayLoadingTemplate="loadingTemplate"
            :overlayNoRowsTemplate="noRowsTemplate"
            :undoRedoCellEditing="true"
            :components="riskItemcomponents"
            :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
            :detailCellRendererParams="detailCellRendererParams"
            @cell-value-changed="onCellRiskItemValueChanged"
            @cellClicked="cellRiskItemClicked"
            id="riskItemGrid"
            @first-data-rendered="riskheaderHeightSetter()"
            @grid-ready="onRiskItemGridReady"
            @column-resized="onItemColumnChanged"
            @sort-changed="onItemColumnChanged"
            @column-visible="onItemColumnChanged"
            @column-row-group-changed="onItemColumnChanged"
            @column-value-changed="onItemColumnChanged"
            @column-moved="onItemColumnChanged"
            @column-pinned="onItemColumnChanged"
          >
          </ag-grid-vue>
          <!-- 
           @grid-ready="onRiskItemGridReady"
            @sort-changed="onRiskItemColumnChanged"
            @column-resized="onRiskItemColumnChanged"
            @column-visible="onRiskItemColumnChanged"
            @column-row-group-changed="onRiskItemColumnChanged"
            @column-value-changed="onRiskItemColumnChanged"
            @column-moved="onRiskItemColumnChanged"
            @column-pinned="onRiskItemColumnChanged" -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import riskToolbar from "./RiskToolbar";
import * as moment from "moment";
import * as utilitiesModule from "@/assets/utilities.js";
import { debounce } from "lodash";
import riskItemToolbar from "./RiskItemToolbar";
import {
  getColumnDef,
  postColumnState,
  getColumnState,
} from "@/api/networking.js";
export default {
  name: "RiskManager",
  components: {
    AgGridVue,
    riskToolbar,
    riskItemToolbar,
  },
  data() {
    return {
      defaultRiskItemColDef: null,
      defaultHeaderColDef: null,
      autoGroupColumnDef: null,
      columnRiskHeaderDefs: null,
      tempParentData: null,
      rowParentData: null,
      getDataPath: null,
      detailCellRendererParams: {
        refreshStrategy: "everything",
      },
      i: -1,
      gridParentOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
        getRowNodeId: (data) => data.uid,
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
      },
      groupDefaultExpanded: null,
      riskHeadercomponents: {
        //riskHeaderCellRenderer: getriskCellRenderer(),
        datePicker: getDatePicker(),
        integerCellEditor: getIntegerCellEditor(),
        decimalCellEditor: getDecimalCellEditor(),
        lookupCellEditor: getLookupCellEditor(),
        iconRiskHeaderCellRenderer: getriskCellRenderer(),
      },
      riskItemcomponents: {
        RiskItemDatePickerCellEditor: getRiskItemDatePicker(),
        integerCellEditor: getIntegerCellEditor(),
        decimalCellEditor: getDecimalCellEditor(),
        lookupCellEditor: getLookupCellEditor(),
        RiskSeverityLookupValue: getRiskSeverityLookupValue(),
        RiskTypeLookupValue: getRiskTypiesLookupValue(),
      },
      undoRedoCellEditingLimit: 15,
      parentObj: null,
      singleClickEdit: false,
      invalidindex: [],
      loadingTemplate: `<span class="ag-overlay-loading-center">Data is loading...</span>`,
      groupHeight: 0,
      columnRiskItemDefs: null,
      rowRiskItemData: null,
      gridRiskItemOptions: {
        animateRows: true,
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
      },
      tempnewId: -1,
      deleteSettings: {
        withBackdrop: true,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "center-center",
      },
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
    };
  },
  watch: {
    LoadParentData() {
      this.rowParentData = this.getParentCurrentData;
      this.tempParentData = Array.from(this.getParentCurrentData);
      this.gridParentOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.riskheaderHeightSetter();
      this.reloadHeaderColumnState();

      SetRiskTypies(this.getRiskTypeData);

      //this.Parentcomponents =
    },

    LoadChildrenData() {
      this.rowRiskItemData = this.getChildrenCurrentData;

      this.gridRiskItemOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      SetRiskSeverities(this.getRiskSeveritiesData);
      this.reloadItemColumnState();

      //this.Parentcomponents =
    },
    SaveError() {
      this.$vToastify.setSettings(this.NormalSettings);
      this.$vToastify.error("Invalid risk Resources Type!");
    },
    UndoCounter() {
      this.gridParentOptions.api.undoCellEditing();
    },

    RedoCounter() {
      this.gridParentOptions.api.redoCellEditing();
    },

    UndoChildrenCounter() {
      this.gridRiskItemOptions.api.undoCellEditing();
    },

    RedoChildrenCounter() {
      this.gridRiskItemOptions.api.redoCellEditing();
    },

    AddRecordCounter() {
      if (this.AddRecordCounter > 0) {
        this.tempnewId--;
        let newRow = {
          id: --this.tempnewId,
          code: "",
          description: "",
          rowUpdated: true,
          parentId: 1,
          path: [1, this.tempnewId],
          programs: null,
          portfolios: null,
          projects: null,
          extraColumnValues: [],
          uid: --this.tempnewId,
        };
        this.setParentObj(newRow);
        this.gridParentOptions.api.applyTransaction({
          add: [newRow],
        });

        this.gridParentOptions.api.redrawRows();
        this.gridParentOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        let tempRowIndex = 0;
        this.gridParentOptions.api.forEachNode((node) => {
          if (node.data.uid == this.tempnewId) {
            let thisParent = node.parent;
            while (thisParent) {
              thisParent.setExpanded(true);
              thisParent = thisParent.parent;
            }
            node.setSelected(true);
            node.setExpanded(true);
            tempRowIndex = node.rowIndex;
          }
        });
        this.tempParentData.push(newRow);
        /* let lastRowIndex = this.gridParentOptions.api.getSelectedNodes();
        console.log(lastRowIndex); */

        this.gridParentOptions.api.ensureIndexVisible(tempRowIndex);
        this.gridParentOptions.api.setFocusedCell(tempRowIndex, "code");
      }
    },

    AddChildrenRecordCounter() {
      if (this.AddChildrenRecordCounter > 0) {
        this.gridRiskItemOptions.api.applyTransaction({
          add: [
            this.getChildrenCurrentData[this.getChildrenCurrentData.length - 1],
          ],
        });
        //this.rowRiskItemData = this.getChildrenCurrentData;
        let lastRowIndex = this.gridRiskItemOptions.api.getLastDisplayedRow();
        this.gridRiskItemOptions.api.redrawRows();
        this.gridRiskItemOptions.api.ensureIndexVisible(lastRowIndex);
        this.gridRiskItemOptions.api.setFocusedCell(lastRowIndex, "code");
        this.gridRiskItemOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
      }
    },

    SaveCounter() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.SaveCounter > 0) {
        let newRecord = [],
          newParentData = [],
          indexes = [],
          emptyRowCounter = 0;
        this.gridParentOptions.api.refreshCells();
        this.gridParentOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            if (node.data.code != null) {
              this.tempParentData.filter((element) => {
                if (element.code === node.data.code && node.key != undefined) {
                  if (node.data.id != element.id) {
                    node.data.rowUpdated = "Duplicate";
                    indexes.push(node.rowIndex);
                  }
                }
              });
              newParentData.push(node.data);
            } else {
              newParentData = [];
              emptyRowCounter++;
              return false;
            }
          }
        });
        newRecord = [];
        this.gridRiskItemOptions.api.refreshCells();
        this.gridRiskItemOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            if (node.data.code != null) {
              /* this.rowRiskItemData.filter(element => {
                if (element.code === node.data.code && node.key != undefined) {
                  if (node.key != element.id) {
                    node.data.rowUpdated = "Duplicate";
                    indexes.push(node.rowIndex);
                  }
                }
              }); */
              newRecord.push(node.data);
            } else {
              newRecord = [];
              emptyRowCounter++;
              return false;
            }
          }
        });

        if (emptyRowCounter > 0 || indexes.length > 0) {
          this.gridParentOptions.api.redrawRows();
          this.gridParentOptions.api.refreshCells();
          newRecord = [];
          emptyRowCounter = 0;
          this.$vToastify.error("Code Value is empty or duplicate!");
        } else {
          this.mutateParentRecord({ returnedNewData: newParentData });
          this.saveData();
        }
      }
    },

    DeleteCounter() {
      if (this.DeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes = this.gridParentOptions.api.getSelectedNodes();

              let selectedData = selectedNodes.map((node) => node.data);
              if (selectedData.length > 0) {
                let DataDelete = [],
                  deleteIds = [];
                for (let i = 0; i < selectedData.length; i++) {
                  DataDelete.push(
                    this.tempParentData.filter((x) =>
                      x.path.includes(selectedData[i].id)
                    )
                  );
                }
                DataDelete.forEach((element) => {
                  element.forEach((data) => {
                    selectedData.push(data);
                  });
                });

                DataDelete = _.uniqBy(selectedData, "id");
                let Deletevalues = DataDelete.map((x) => x.id);
                Deletevalues.forEach((element) => {
                  deleteIds.push(
                    this.tempParentData.findIndex((x) => x.Id == element)
                  );
                });

                deleteIds.forEach((x) => {
                  delete this.tempParentData[x];
                });
                let indices = Deletevalues.filter((x) => x > 0);
                if (indices.length > 0) {
                  this.DeleteData({ APIName: "RiskHeader", Id: indices });
                }
                this.gridParentOptions.api.applyTransaction({
                  remove: DataDelete,
                });
                deleteIds = null;
                indices = null;
                Deletevalues = null;
                DataDelete = null;
                selectedData = null;
              }
            } else {
              this.$vToastify.setSettings(this.NormalSettings);
            }
          });
      }
    },

    isDelete() {
      if (this.isDelete > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.gridParentOptions.api.redrawRows();
        this.gridParentOptions.api.refreshCells();
        //this.rowRiskItemData = this.getChildrenCurrentData;
        this.gridRiskItemOptions.api.redrawRows();
        this.gridRiskItemOptions.api.refreshCells();
        this.$vToastify.success("Row Deleted Successfully!");
      }
    },

    DataWatcher() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.DataWatcher > -1) {
        this.tempParentData.forEach((row) => (row.rowUpdated = false));
        let tempdata = [];
        this.gridParentOptions.api.forEachNode((node) => {
          node.data.rowUpdated = false;
          if (node.data.id < 0) tempdata.push(node.data);
        });
        if (tempdata.length > 0) {
          this.gridParentOptions.api.applyTransaction({
            remove: tempdata,
          });
          this.gridParentOptions.api.applyTransaction({
            add: this.getChildTempData,
          });
        }

        this.gridParentOptions.api.redrawRows();
        this.gridParentOptions.api.refreshCells();
        this.gridParentOptions.api.clearFocusedCell();
        let selectedNodes = this.gridParentOptions.api.getSelectedNodes();
        if (selectedNodes.length > 0) {
          this.gridParentOptions.api.selectIndex(
            selectedNodes[0].rowIndex,
            false,
            false
          );
          this.gridParentOptions.api.setFocusedCell(
            selectedNodes[0].rowIndex,
            "code"
          );
          this.setParentObj(selectedNodes[0].data);
        }
        //  this.rowRiskItemData = this.rowRiskItemData.filter(x => x.id > 0);
        this.rowRiskItemData.forEach((row) => (row.rowUpdated = false));
        tempdata = [];
        this.gridRiskItemOptions.api.forEachNode((node) => {
          node.data.rowUpdated = false;
          if (node.data.id < 0) tempdata.push(node.data);
        });
        if (tempdata.length > 0) {
          this.gridRiskItemOptions.api.applyTransaction({
            remove: tempdata,
          });
          this.gridRiskItemOptions.api.applyTransaction({
            add: this.gettempChildData,
          });
        } else {
          this.gridRiskItemOptions.api.applyTransaction({
            update: this.gettempChildData,
          });
        }

        this.gridRiskItemOptions.api.redrawRows();
        this.gridRiskItemOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        this.gridRiskItemOptions.api.clearFocusedCell();
        this.rowRiskItemData = [];
        this.gridRiskItemOptions.api.forEachNode((node) => {
          this.rowRiskItemData.push(node.data);
        });
        this.loadRiskItem({ riskHeaderId: this.getParentSelectedId });
        selectedNodes = this.gridRiskItemOptions.api.getSelectedNodes();
        if (selectedNodes.length > 0) {
          this.gridRiskItemOptions.api.selectIndex(
            selectedNodes[0].rowIndex,
            false,
            false
          );
          this.gridRiskItemOptions.api.setFocusedCell(
            selectedNodes[0].rowIndex,
            "code"
          );
          this.setChildrenObj(selectedNodes[0].data);
        }
        this.invalidindex = [];
        this.$vToastify.success("Data successfully saved!");
      } else {
        this.tempParentData = [];
        this.gridParentOptions.api.redrawRows();
        this.gridParentOptions.api.refreshCells();
      }
    },

    ColumnDefCounters() {
      // this.columnDefs = this.getParentGridColumn;
      this.getParentGridColumn.forEach((row) => {
        this.columnRiskHeaderDefs.push(row);
      });
      this.reloadHeaderColumnState();
    },

    ColumnChildDefCounters() {
      // this.columnDefs = this.getParentGridColumn;
      this.getChildrenGridColumn.forEach((row) => {
        this.columnRiskItemDefs.push(row);
      });
      this.reloadItemColumnState();
    },

    DeleteChildCounter() {
      if (this.DeleteChildCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes =
                this.gridRiskItemOptions.api.getSelectedNodes();

              let selectedData = selectedNodes.map((node) => node.data);
              if (selectedData.length > 0) {
                let DataDelete = [],
                  deleteIds = [];
                for (let i = 0; i < selectedData.length; i++) {
                  DataDelete.push(
                    this.tempParentData.filter((x) =>
                      x.path.includes(selectedData[i].id)
                    )
                  );
                }
                DataDelete.forEach((element) => {
                  element.forEach((data) => {
                    selectedData.push(data);
                  });
                });

                DataDelete = _.uniqBy(selectedData, "id");
                let Deletevalues = DataDelete.map((x) => x.id);
                Deletevalues.forEach((element) => {
                  deleteIds.push(
                    this.tempParentData.findIndex((x) => x.Id == element)
                  );
                });

                deleteIds.forEach((x) => {
                  delete this.tempParentData[x];
                });
                let indices = Deletevalues.filter((x) => x > 0);
                if (indices.length > 0) {
                  this.DeleteData({ APIName: "RiskItem", Id: indices });
                }
                this.gridRiskItemOptions.api.applyTransaction({
                  remove: DataDelete,
                });
                deleteIds = null;
                indices = null;
                Deletevalues = null;
                DataDelete = null;
                selectedData = null;
              }
            } else {
              this.$vToastify.setSettings(this.NormalSettings);
            }
          });
      }
    },

    SaveRelation() {
      let tempData = [];
      this.gridParentOptions.api.forEachNode((node) => {
        if (node.data != undefined) {
          if (
            node.data.id == this.getParentSelectedId &&
            node.data.parentId > 0
          ) {
            tempData.push(node.data);
          }
        }
      });
      this.gridParentOptions.api.applyTransaction({
        remove: tempData,
      });

      this.gridParentOptions.api.applyTransaction({
        add: this.getRiskHeaderRelation,
      });
      this.gridParentOptions.api.redrawRows();
      this.gridParentOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });

      this.tempParentData = [];
      //  let tempdata = [];
      this.gridParentOptions.api.forEachNode((node) => {
        // node.data.rowUpdated = false;
        this.tempParentData.push(node.data);
      });
    },
  },

  computed: {
    /**
     * Load CostElements Controller Gettters, states
     */

    ...mapGetters("riskController", [
      "getParentGridColumn",
      "getParentCurrentData",
      "getAllStructureDetailsvalue",
      "getCopiedRows",
      "getParentSelectedId",
      "getParentTempData",
      "getCalenderData",
      "getParentSelectedObj",
      "getResourceTypeData",
      "getChildrenGridColumn",
      "getChildrenCurrentData",
      "getRiskSeveritiesData",
      "getChildTempData",
      "getRiskTypeData",
      "gettempChildData",
    ]),
    ...mapGetters("relationControl", ["getRiskHeaderRelation"]),

    /**riskResourceController */

    ...mapState({
      colDefs: (state) => state.riskController.currentParentColDef,
      ColumnDefCounters: (state) =>
        state.riskController.currentParentGridColDefCountCounter,
      LoadParentData: (state) =>
        state.riskController.currentParentLoadRecordCounter,
      UndoCounter: (state) => state.riskController.currentParentUndoWatcher,
      RedoCounter: (state) => state.riskController.currentParentRedoWatcher,
      PasteRowCounter: (state) => state.riskController.duplicateRecord,
      AddRecordCounter: (state) =>
        state.riskController.currentParentAddRecordCounter,
      DeleteCounter: (state) =>
        state.riskController.currentParentDeleteRecordCounter,
      isDelete: (state) => state.riskController.isDelete,
      SaveCounter: (state) =>
        state.riskController.currentParentSaveRecordCounter,
      DataWatcher: (state) => state.riskController.datawatcher,
      SaveError: (state) => state.riskController.SaveError,

      LoadChildrenData: (state) =>
        state.riskController.currentChildrenLoadRecordCounter,
      AddChildrenRecordCounter: (state) =>
        state.riskController.currentChildrenAddRecordCounter,
      RedoChildrenCounter: (state) =>
        state.riskController.currentChildrenRedoWatcher,
      UndoChildrenCounter: (state) =>
        state.riskController.currentChildrenUndoWatcher,
      DeleteChildCounter: (state) =>
        state.riskController.currentChildrenDeleteRecordCounter,
      SaveRelation: (state) => state.relationControl.saveRiskHeaderCounter,
      ColumnChildDefCounters: (state) =>
        state.riskController.currentChildrenGridColDefCountCounter,
    }),

    noRowsTemplate() {
      if (this.isElementdetail) {
        return this.loadingTemplate;
      } else {
        return `<span>No Rows to Show</span>`;
      }
    },
  },
  methods: {
    /**
     * Load CostElements Controller Actions, Mutation
     */

    ...mapActions("riskController", [
      "loadRiskHeader",
      "saveData",
      "DeleteData",
      "loadRiskItem",
      "loadRiskSeverity",
      "LoadHeaderExtraColumnValue",
      "LoadItemExtraColumnValue",
      "loadRiskType",
    ]),
    ...mapMutations("riskController", [
      "setParentObj",
      "resetParentObj",
      "setSelectedParentRowObject",
      "mutateParentUndostate",
      "mutateParentRedostate",
      "mutateChildrenUndostate",
      "mutateChildrenRedostate",
      "mutateRecord",
      "setChildrenObj",
      "mutateParentRecord",
      //  "setNewChildData",
    ]),

    cellClicked(params) {
      this.rowRiskItemData = [];
      if (
        params.node.data.id > 0 &&
        params.node.data.parentId > 0 //&&
        // params.column.colId == "ag-Grid-AutoColumn"
      ) {
        this.setParentObj(params.node.data);
        this.loadRiskItem({ riskHeaderId: params.node.data.id });
      } else if (
        params.column.colId == "ag-Grid-AutoColumn" &&
        params.node.data.parentId > 0
      ) {
        this.setParentObj(params.node.data);
      } else if (params.node.data == null) {
        this.rowRiskItemData = [];
        // this.resetParentObj(null);
      }
      //let selectedNodes = params.node;
      params.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    cellRiskItemClicked(params) {
      this.setChildrenObj(params.node.data);
      //let selectedNodes = params.node;
      params.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    onCellRiskItemValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();
      if (params.node.data) {
        let selectedNodes = params.node.data;
        if (selectedNodes.length > 0) {
          this.setParentObj(params.node.data);
        }
      }

      if (params.column.colId.match("riskSeverityId")) {
        if (params.newValue != "") {
          if (
            typeof parseInt(params.newValue) === "number" &&
            this.getRiskSeveritiesData.find((x) => x.id == params.newValue) ==
              undefined
          ) {
            params.data.riskSeverityId = params.newValue;
            params.data.tempriskSeverityId = this.getRiskSeveritiesData.find(
              (x) => x.description == params.newValue
            ).id;

            params.data.colour = this.getRiskSeveritiesData.find(
              (x) => x.description == params.newValue
            ).colour;
          } else {
            params.data.tempriskSeverityId = params.newValue;
            params.data.riskSeverityId = this.getRiskSeveritiesData.find(
              (x) => x.id == params.newValue
            ).description;
            params.data.colour = this.getRiskSeveritiesData.find(
              (x) => x.id == params.newValue
            ).colour;
          }
        } else {
          params.data.riskSeverityId = null;
          params.data.tempRiskSeverityId = null;
          params.data.colour = null;
        }
      } else if (params.column.colId.match("et_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;

        tempIndex = params.node.data.extraColumnValues.findIndex(
          (y) => y.riskItemExtraColumnId == parseInt(colvalue[1])
        );
        params.node.data.rowUpdated = true;
        if (tempIndex < 0) {
          params.node.data.extraColumnValues.push({
            riskItemId: params.node.data.id,
            riskItemExtraColumnId: colvalue[1],
            value: params.newValue,
          });
        } else {
          params.node.data.extraColumnValues[tempIndex].value = params.newValue;
        }
      } else if (params.column.colId.match("riskTypeId")) {
        if (params.newValue != "") {
          if (
            typeof parseInt(params.newValue) === "number" &&
            this.getRiskTypeData.find((x) => x.id == params.newValue) ==
              undefined
          ) {
            params.data.riskTypeId = params.newValue;
            params.data.tempriskTypeId = this.getRiskTypeData.find(
              (x) => x.description == params.newValue
            ).id;
          } else {
            params.data.tempriskTypeId = params.newValue;
            params.data.riskTypeId = this.getRiskTypeData.find(
              (x) => x.id == params.newValue
            ).description;
          }
        } else {
          params.data.riskTypeId = null;
          params.data.tempriskTypeId = null;
        }
      }

      /*  if (params.column.colId == "code") {
        var CodeIndex = this.tempParentData.filter(
          x => x.id != params.node.data.id
        );
        CodeIndex = CodeIndex.findIndex(
          x => x.code.toLowerCase() == params.newValue.toLowerCase()
        );
        if (CodeIndex > -1) {
          this.$vToastify.setSettings(this.NormalSettings);
          this.$vToastify.error("Duplicate code value detected!");
          params.node.data.code = params.newValue;
          params.node.data.rowUpdated = "Duplicate";
        } else {
          params.node.data.rowUpdated = true;
        }
      } */

      params.node.data.rowUpdated = true;
      // params.api.refreshCells({ force: true, suppressFlash: true });
      if (undoSize == 0) {
        this.mutateChildrenUndostate(false);
        params.node.data.rowUpdated = false;
      } else {
        this.mutateChildrenUndostate(true);
      }

      if (redoSize == 0) {
        this.mutateChildrenRedostate(false);
      } else {
        this.mutateChildrenRedostate(true);
        params.node.data.rowUpdated = false;
      }
      // if (params.node.data.description.length > 30) {
      this.riskheaderHeightSetter();
      //  }

      params.api.refreshCells({ force: true, suppressFlash: true });
    },

    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();
      if (params.node.data) {
        let selectedNodes = params.node.data;
        if (selectedNodes.length > 0) {
          this.setParentObj(params.node.data);
        }
      }

      if (params.column.colId == "code") {
        //   var CodeIndex = this.tempParentData.filter(
        //     x => x.id != params.node.data.id
        //   );
        //   CodeIndex = CodeIndex.findIndex(
        //     x => x.code.toLowerCase() == params.newValue.toLowerCase()
        //   );
        //   if (CodeIndex > -1) {
        //     this.$vToastify.setSettings(this.NormalSettings);
        //     this.$vToastify.error("Duplicate code value detected!");
        //     params.node.data.code = params.newValue;
        //     params.node.data.rowUpdated = "Duplicate";
        //   } else {
        //     params.node.data.rowUpdated = true;
        //   }
        // } else if (params.column.colId.match("et_")) {
        //   let colvalue = params.column.colId.split("_");
        //   let tempIndex = -1;

        //   tempIndex = params.node.data.extraColumnValues.findIndex(
        //     y => y.riskHeaderExtraColumnId == parseInt(colvalue[1])
        //   );
        //   params.node.data.rowUpdated = true;
        //   if (tempIndex < 0) {
        //     params.node.data.extraColumnValues.push({
        //       riskHeaderId: params.node.data.id,
        //       riskHeaderExtraColumnId: colvalue[1],
        //       value: params.newValue,
        //     });
        //   } else {
        //     params.node.data.extraColumnValues[tempIndex].value = params.newValue;
        //   }
        // } else {
        //   params.node.data.rowUpdated = true;
        // }

        // if (params.column.colId == "code") {
        var CodeIndex = this.tempParentData.filter(
          (x) => x.id != params.node.data.id
        );
        CodeIndex = CodeIndex.findIndex(
          (x) => x.code.toLowerCase() == params.newValue.toLowerCase()
        );
        if (CodeIndex > -1) {
          this.$vToastify.setSettings(this.NormalSettings);
          this.$vToastify.error("Duplicate code value detected!");
          params.node.data.code = params.newValue;
          params.node.data.rowUpdated = "Duplicate";
        } else {
          params.node.data.rowUpdated = true;
        }
      } else if (params.column.colId.match("et_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;

        tempIndex = params.node.data.extraColumnValues.findIndex(
          (y) => y.riskHeaderExtraColumnId == parseInt(colvalue[1])
        );
        params.node.data.rowUpdated = true;
        if (tempIndex < 0) {
          params.node.data.extraColumnValues.push({
            riskHeaderId: params.node.data.id,
            riskHeaderExtraColumnId: colvalue[1],
            value: params.newValue,
          });
        } else {
          params.node.data.extraColumnValues[tempIndex].value = params.newValue;
        }
      } else {
        params.node.data.rowUpdated = true;
      }

      params.node.data.rowUpdated = true;
      // params.api.refreshCells({ force: true, suppressFlash: true });
      if (undoSize == 0) {
        this.mutateParentUndostate(false);
        params.node.data.rowUpdated = false;
      } else {
        this.mutateParentUndostate(true);
      }

      if (redoSize == 0) {
        this.mutateParentRedostate(false);
      } else {
        this.mutateParentRedostate(true);
        params.node.data.rowUpdated = false;
      }
      params.api.refreshCells({ force: true, suppressFlash: true });
    },

    onRowDragMove(event) {
      setriskPotentialParentForNode(event.api, event.overNode);
    },

    onRowDragLeave(event) {
      setriskPotentialParentForNode(event.api, null);
    },

    onRowDragEnd(event) {
      this.$vToastify.setSettings(this.NormalSettings);
      if (!potentialParent) {
        return;
      }
      var movingData = event.node.data;
      var newParentPath = potentialParent.data ? potentialParent.data.path : [];
      if (potentialParent.data.id > 0) {
        var needToChangeParent = !areriskPathsEqual(
          newParentPath,
          movingData.path
        );
        var invalidMode = isSelectionriskParentOfTarget(
          event.node,
          potentialParent
        );
        if (invalidMode) {
          this.$vToastify.error("invalid move!");
          console.error("invalid move");
        }
        if (needToChangeParent && !invalidMode) {
          var updatedRows = [];
          moveriskToPath(newParentPath, event.node, updatedRows);

          updatedRows.forEach((element) => {
            element.rowUpdated = true;
          });
          if (updatedRows[0].path.length > 1) {
            updatedRows[0].parentId =
              updatedRows[0].path[updatedRows[0].path.length - 2];
          } else {
            updatedRows[0].parentId = null;
          }
          this.gridParentOptions.api.applyTransactionAsync({
            update: updatedRows,
          });

          this.gridParentOptions.api.clearFocusedCell();
        }
      } else {
        this.$vToastify.error("invalid move!");
        console.error("invalid move");
      }
      setriskPotentialParentForNode(event.api, null);
    },

    onHeaderGridReady(params) {
      this.loadRiskType();
      getColumnDef(38)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerRiskHeader;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time

          this.LoadHeaderExtraColumnValue().then(() => {
            this.loadRiskHeader();
          });
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    onRiskItemGridReady(params) {
      // console.log(params.columnApi);
      getColumnDef(39)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerRiskItems;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .then(() => {
          /*  */
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          this.LoadItemExtraColumnValue();
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    reloadHeaderColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 38)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridParentOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          //this.riskheaderHeightSetter();
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onHeaderColumnChanged: debounce(function () {
      this.riskheaderHeightSetter();
      this.onHeaderColumnStateSubmit();
    }, 300),

    onHeaderColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridParentOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 38,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },
    onItemColumnChanged: debounce(function () {
      this.onItemColumnStateSubmit();
    }, 300),

    reloadItemColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 39)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridRiskItemOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onItemColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      this.riskheaderHeightSetter();
      var d = this.gridRiskItemOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 39,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },

    riskheaderHeightSetter() {
      var padding = 20;
      var height = riskheaderHeightGetter() + padding;
      this.gridRiskItemOptions.api.setHeaderHeight(height);
      this.gridRiskItemOptions.api.resetRowHeights();
    },
  },

  beforeMount() {
    /*Set Grid Tree Collumn */

    //defaultRiskItemColDef
    /*Set Grid Tree Collumn */
    this.defaultRiskItemColDef = Object.assign({}, this.defaultColDef, {
      //flex: 1,
      resizable: true,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space:normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          "  </div>" +
          "</div>",
      },
    });
    this.defaultHeaderColDef = {};
    this.autoGroupColumnDef = {
      headerName: "",
      field: "uid",
      rowDrag: true,
      suppressPaste: true,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: "iconRiskHeaderCellRenderer",
      },
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      width: 160,
    };
    this.getDataPath = (data) => {
      return data.path;
    };

    this.columnRiskHeaderDefs = [];
    this.columnRiskItemDefs = [];
    /**
     * Load Column Defination
     * Load All CostElement's Data
     * Set Basic Functions Here
     */

    this.columnRiskItemDefs = [];
    this.rowRiskItemData = [];
  },

  mounted() {
    //this.columnRiskHeaderDefs = this.getParentGridColumn;
    //this.columnRiskItemDefs = this.getChildrenGridColumn;
    this.loadRiskHeader();
    this.loadRiskSeverity();
    //
  },
};
var groupHeight;
window.moveriskToPath = function moveriskToPath(
  newParentPath,
  node,
  allUpdatedNodes
) {
  //console.log("adsadasdas");
  var oldPath = node.data.path;
  var fileName = oldPath[oldPath.length - 1];
  var newChildPath = newParentPath.slice();
  newChildPath.push(fileName);
  node.data.path = newChildPath;
  allUpdatedNodes.push(node.data);
  if (node.childrenAfterGroup) {
    node.childrenAfterGroup.forEach(function (childNode) {
      moveriskToPath(newChildPath, childNode, allUpdatedNodes);
    });
  }
};

window.isSelectionriskParentOfTarget = function isSelectionriskParentOfTarget(
  selectedNode,
  targetNode
) {
  var children = selectedNode.childrenAfterGroup;
  for (var i = 0; i < children.length; i++) {
    if (targetNode && children[i].key === targetNode.key) return true;
    isSelectionriskParentOfTarget(children[i], targetNode);
  }
  return false;
};

function riskheaderHeightGetter() {
  var columnHeaderTexts = [
    ...document.querySelectorAll(".ag-header-cell-text"),
  ];
  var clientHeights = columnHeaderTexts.map(
    (headerText) => headerText.clientHeight
  );
  var tallestHeaderTextHeight = Math.max(...clientHeights);
  //console.log(clientHeights)
  return tallestHeaderTextHeight;
}

window.areriskPathsEqual = function areriskPathsEqual(path1, path2) {
  //console.log(path2, path2, path1);
  if (path1.length !== path2.length) {
    return false;
  }
  var equal = true;
  path1.forEach(function (item, index) {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
};

window.setriskPotentialParentForNode = function setriskPotentialParentForNode(
  api,
  overNode
) {
  var newPotentialParent;
  if (overNode) {
    newPotentialParent = overNode;
  } else {
    newPotentialParent = null;
  }
  var alreadySelected = potentialParent === newPotentialParent;
  if (alreadySelected) {
    return;
  }
  var rowsToRefresh = [];
  if (potentialParent) {
    rowsToRefresh.push(potentialParent);
  }
  if (newPotentialParent) {
    rowsToRefresh.push(newPotentialParent);
  }
  potentialParent = newPotentialParent;
  refreshRows(api, rowsToRefresh);
};

window.refreshRows = function refreshRows(api, rowsToRefresh) {
  var params = {
    rowNodes: rowsToRefresh,
    force: true,
  };
  api.refreshCells(params);
};

window.getriskCellRenderer = function getriskCellRenderer() {
  function riskCellRenderer() {}
  riskCellRenderer.prototype.init = function (params) {
    var tempDiv = document.createElement("div");
    tempDiv.innerHTML =
      params.node.data.parentId > 0
        ? '<img src="Icons/risk.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
        : params.node.data.parentId == null && params.node.data.rowType == 2
        ? '<img src="Icons/projects2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
        : params.node.data.parentId == null && params.node.data.rowType == 1
        ? '<img src="Icons/programs2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
        : params.node.data.parentId == null && params.node.data.rowType == 0
        ? '<img src="Icons/portfolio2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
        : null;
    this.eGui = tempDiv.firstChild;
  };
  riskCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return riskCellRenderer;
};

var potentialParent = null;

window.getRiskItemDatePicker = function getRiskItemDatePicker() {
  function DatepickerRiskItem() {}
  var tempDatevalue = null;
  DatepickerRiskItem.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "date");
    if (params.value.length > 0 && params.value != null) {
      this.eInput.value = params.value.substr(0, 10);
    }
    tempDatevalue = params.value; // moment(this.getSelectedRowObject.endDate).toISOString().substr(0, 10);

    this.eInput.classList.add("input");
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
    /*  $(this.eInput).datepicker({
      constrainInput: true, // prevent letters in the input field
      autoSize: true, // automatically resize the input field
      dateFormat: "dd/mm/yyyy",
      changeMonth: true,
      changeYear: true,
      yearRange: "1930:" + new Date().getFullYear(),
    }); */
  };
  DatepickerRiskItem.prototype.getGui = function () {
    return this.eInput;
  };
  DatepickerRiskItem.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  DatepickerRiskItem.prototype.getValue = function () {
    if (this.eInput.value.length > 0) {
      return moment(this.eInput.value).format("YYYY-MM-DD");
    } else {
      return tempDatevalue;
    }
  };
  DatepickerRiskItem.prototype.destroy = function () {};
  DatepickerRiskItem.prototype.isPopup = function () {
    return false;
  };
  return DatepickerRiskItem;
};

window.getIntegerCellEditor = function getIntegerCellEditor() {
  function IntegerPicker() {}
  IntegerPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.setAttribute("step", "1");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };

  /*  IntegerPicker.prototype.isKeyPressedNavigation = function(event) {
    return event.keyCode !=110;
  }; */
  IntegerPicker.prototype.getGui = function () {
    return this.eInput;
  };
  IntegerPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  IntegerPicker.prototype.getValue = function () {
    return parseInt(this.eInput.value);
  };
  IntegerPicker.prototype.destroy = function () {};
  IntegerPicker.prototype.isPopup = function () {
    return false;
  };
  return IntegerPicker;
};

window.getDecimalCellEditor = function getDecimalCellEditor() {
  function DecimalPicker() {}
  DecimalPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };
  DecimalPicker.prototype.getGui = function () {
    return this.eInput;
  };
  DecimalPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  DecimalPicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  DecimalPicker.prototype.destroy = function () {};
  DecimalPicker.prototype.isPopup = function () {
    return false;
  };
  return DecimalPicker;
};

window.getLookupCellEditor = function getLookupCellEditor() {
  function Lookuppicker() {}
  Lookuppicker.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var CostModelTypeFilter = CostModelTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );
    CostModelTypeFilter.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  Lookuppicker.prototype.getGui = function () {
    return this.eInput;
  };
  Lookuppicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Lookuppicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Lookuppicker.prototype.destroy = function () {};
  Lookuppicker.prototype.isPopup = function () {
    return false;
  };
  return Lookuppicker;
};

var RiskSeverities = null,
  RiskTypies = null;

window.SetRiskTypies = function SetRiskTypies(data) {
  //console.log(data);
  RiskTypies = data;
};

window.SetRiskSeverities = function SetRiskSeverities(data) {
  RiskSeverities = data;
};

window.getRiskSeverityLookupValue = function getRiskSeverityLookupValue() {
  function CustomRiskSeveritiesCombobox() {}
  CustomRiskSeveritiesCombobox.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.setAttribute("id", "resourceType");
    var RiskSeveritiesIDs = RiskSeverities.sort((a, b) =>
      a.sortOrder > b.sortOrder ? 1 : -1
    );
    //console.log(RiskSeveritiesIDs);
    this.eOptionVal = document.createElement("option");
    //Statical set data in grid ComboBox
    // this.eInput.appendChild(this.eOptionVal);
    RiskSeveritiesIDs.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      if (x.description == params.value) {
        this.eOptionVal.selected = true;
      }
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  CustomRiskSeveritiesCombobox.prototype.getGui = function () {
    return this.eGui;
  };

  CustomRiskSeveritiesCombobox.prototype.getGui = function () {
    return this.eInput;
  };
  CustomRiskSeveritiesCombobox.prototype.afterGuiAttached = function () {
    this.eInput.focus();
  };
  CustomRiskSeveritiesCombobox.prototype.getValue = function () {
    return this.eInput.value;
  };
  CustomRiskSeveritiesCombobox.prototype.destroy = function () {};
  CustomRiskSeveritiesCombobox.prototype.isPopup = function () {
    return false;
  };
  return CustomRiskSeveritiesCombobox;
};

window.getriskcolorPicker = function getriskcolorPicker() {
  function riskcolorPicker() {}
  riskcolorPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "color");
    if (params.value == null) {
      this.eInput.value = "#FFFFFF";
    } else {
      this.eInput.value = params.value;
    }
    this.eInput.classList.add("input");
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };
  riskcolorPicker.prototype.getGui = function () {
    return this.eInput;
  };
  riskcolorPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  riskcolorPicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  riskcolorPicker.prototype.destroy = function () {};
  riskcolorPicker.prototype.isPopup = function () {
    return false;
  };
  return riskcolorPicker;
};

window.getRiskTypiesLookupValue = function getRiskTypiesLookupValue() {
  function CustomRiskTypiesCombobox() {}
  CustomRiskTypiesCombobox.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.setAttribute("id", "resourceType");
    var RiskTypIDs = RiskTypies.sort((a, b) =>
      a.sortOrder > b.sortOrder ? 1 : -1
    );
    //console.log(RiskSeveritiesIDs);
    this.eOptionVal = document.createElement("option");
    //Statical set data in grid ComboBox
    // this.eInput.appendChild(this.eOptionVal);
    RiskTypIDs.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      if (x.description == params.value) {
        this.eOptionVal.selected = true;
      }
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  CustomRiskTypiesCombobox.prototype.getGui = function () {
    return this.eGui;
  };

  CustomRiskTypiesCombobox.prototype.getGui = function () {
    return this.eInput;
  };
  CustomRiskTypiesCombobox.prototype.afterGuiAttached = function () {
    this.eInput.focus();
  };
  CustomRiskTypiesCombobox.prototype.getValue = function () {
    return this.eInput.value;
  };
  CustomRiskTypiesCombobox.prototype.destroy = function () {};
  CustomRiskTypiesCombobox.prototype.isPopup = function () {
    return false;
  };
  return CustomRiskTypiesCombobox;
};
</script>

<style scoped lang="scss">
/**/
.container-grid {
  margin-top: 0.1%;
  background-color: #fff;
  color: #444;
  /* Use flexbox */
  display: flex;
}

.line {
  width: 99.5%;
  height: 1%;
  border-bottom: 1px solid black;
  position: relative;
}
.box {
  margin-top: 0.1%;
  color: #fff;
  border-radius: 1px;
  padding: 15px;
  font-size: 150%;

  /* Use box-sizing so that element's outerwidth will match width property */
  box-sizing: border-box;

  /* Allow box to grow and shrink, and ensure they are all equally sized */
  flex: 1 1 auto;
}

.handler {
  margin-top: 0.8%;
  position: relative;
  width: 1px;
  padding: 0;
  cursor: col-resize;
  flex: 0 0 auto;
}

.handler::before {
  margin-top: 0.8%;
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background: silver;
  margin: 0 auto;
}
</style>
