//all data used to display on dashboard
import * as moment from "moment";
import { getRemoteRow, getMonthColumnsJSON } from "@/api/networking.js";
/////////////////////////// bar chart (life-to-date)  //////////////////////////////////
const barChart_Actuals = "rgb(11, 127, 171)";
const barChart_EV = "rgb(240, 94, 27)";
const barChart_BudgetToDate = "rgb(153,153,0)";
const barChart_LifeBudget = "rgb(0,0,0)";
const barChart_Contigency = "rgb(49,49,49)";
//////////////////////////////////////// data used in Highest Earned chart

var yvalue = [300000, 700000, 100000, 400000, 100000];
var yvalue1 = [500000, 600000, 600000, 700000, 800000];
var yvalue2 = [30000, 70000, 10000, 40000, 10000];

var Actuals = {
  y: [
    "Poles",
    "Taihan 132kv",
    "Transformer",
    "Civils and building",
    "Substaion Design",
  ],
  x: [
    Math.random() * 1500000,
    Math.random() * 500000,
    Math.random() * 300000,
    Math.random() * 800000,
    Math.random() * 1500000,
  ],
  name: "Actual",
  orientation: "h",
  type: "bar",
  width: 0.4,
  barmode: "overlay",
  hoverinfo: "x",
  marker: {
    opacity: 0.9,
    color: barChart_Actuals,
    line: {
      width: 1,
    },
  },
};

var EV = {
  y: [
    "Poles",
    "Taihan 132kv",
    "Transformer",
    "Civils and building",
    "Substaion Design",
  ],
  x: [
    Math.random() * 1500000,
    Math.random() * 500000,
    Math.random() * 300000,
    Math.random() * 800000,
    Math.random() * 1500000,
  ],
  name: "EV",
  type: "bar",
  orientation: "h",
  width: 0.4,
  barmode: "overlay",
  hoverinfo: "x",
  marker: {
    opacity: 0.7,
    color: barChart_EV,
    line: {
      width: 1,
    },
  },
};

var Budget = {
  y: [
    "Poles",
    "Taihan 132kv",
    "Transformer",
    "Civils and building",
    "Substaion Design",
  ],
  x: [
    Math.random() * 1500000,
    Math.random() * 500000,
    Math.random() * 300000,
    Math.random() * 800000,
    Math.random() * 1500000,
  ],
  name: "Budget",
  type: "bar",
  width: 0.4,
  barmode: "overlay",
  orientation: "h",
  hoverinfo: "x",
  marker: {
    opacity: 0.6,
    color: barChart_LifeBudget,
    line: {
      width: 1,
    },
  },
};

var Forcast = {
  y: [
    "Poles",
    "Taihan 132kv",
    "Transformer",
    "Civils and building",
    "Substaion Design",
  ],
  x: [
    Math.random() * 1500000,
    Math.random() * 500000,
    Math.random() * 300000,
    Math.random() * 800000,
    Math.random() * 1500000,
  ],
  name: "CAC",
  type: "bar",
  orientation: "h",
  barmode: "overlay",
  hoverinfo: "x",
  width: 0.4,
  marker: {
    opacity: 0.4,
    color: barChart_BudgetToDate,
    line: {
      width: 1,
    },
  },
};

//////////////////////////////////////// TrendFY year Function
const options = { year: "numeric", month: "short" };
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
var storeX = [];
function AccumulatedValues(startDate, Trendlength) {
  startDate = new Date(
    startDate[1],
    monthNames.findIndex((x) => x == startDate[0]) - 1
  );
  for (let i = 0; i < Trendlength; i++) {
    storeX.push(
      new Date(startDate.setMonth(startDate.getMonth() + 1)).toLocaleDateString(
        undefined,
        options
      )
    );
  }
  return storeX;
}

//////////////////////////////////////// DateRange Trend

//////////////////////////////////////// financial year
var financial_year = null;
function getCurrentFinancialYear(reportDate) {
  var getMonth = reportDate.getMonth() + 1;
  if (getMonth < 7) {
    financial_year = [
      "Jul " + (reportDate.getFullYear() - 1),
      "Jun " + reportDate.getFullYear(),
    ];
  } else {
    financial_year = [
      "Jul " + reportDate.getFullYear(),
      "Jun " + (reportDate.getFullYear() + 1),
    ];
  }
  return financial_year;
}

//////////////////////////////////////// Trend Line Graph Shadow

function getTrendPoint(datavalue, st_ed) {
  var index = datavalue.findIndex(function (element, i) {
    if (element == st_ed) {
      return i;
    }
    return 0;
  });
  return index;
}

//////////////////////////////////////// Life To Date

var LifeActuals = {
  y: ["<b> Life To Date </b>"],
  x: [500000],
  name: "Actual",
  type: "bar",
  width: 0.4,
  orientation: "h",
  hoverinfo: "x",
  marker: {
    opacity: 0.7,
    color: barChart_Actuals,
    line: {
      width: 1,
    },
  },
};

var LifeEV = {
  y: ["<b> Life To Date </b>"],
  x: [1000000],
  name: "EV",
  type: "bar",
  width: 0.4,
  orientation: "h",
  hoverinfo: "x",
  marker: {
    opacity: 0.3,
    color: barChart_EV,
    line: {
      width: 1,
    },
  },
};

var LifetoBudget = {
  y: ["<b> Life To Date </b>"],
  x: [1500000],
  name: "Budget To Date",
  type: "bar",
  width: 0.4,
  orientation: "h",
  hoverinfo: "x",
  marker: {
    opacity: 0.3,
    color: barChart_BudgetToDate,
    line: {
      width: 1,
    },
  },
};

var LifeBudget = {
  y: ["<b> Life To Date </b>"],
  x: [4000000],
  name: "Budget",
  type: "bar",
  width: 0.4,
  hoverinfo: "x",
  orientation: "h",
  marker: {
    opacity: 0.3,
    color: barChart_LifeBudget,
    line: {
      width: 1,
    },
  },
};

//////////////////////////////////////// data used in Highest budget chart

var BudActuals = {
  y: [
    "Poles",
    "Taihan 132kv",
    "Transformer",
    "Civils and building",
    "Substaion Design",
  ],
  x: [
    Math.random() * 1500000,
    Math.random() * 500000,
    Math.random() * 300000,
    Math.random() * 800000,
    Math.random() * 1500000,
  ],
  name: "Actual",
  orientation: "h",
  type: "bar",
  width: 0.4,
  barmode: "overlay",
  hoverinfo: "x",
  marker: {
    opacity: 0.9,
    color: barChart_Actuals,
    line: {
      width: 1,
    },
  },
};

var BudEV = {
  y: [
    "Poles",
    "Taihan 132kv",
    "Transformer",
    "Civils and building",
    "Substaion Design",
  ],
  x: [
    Math.random() * 1500000,
    Math.random() * 500000,
    Math.random() * 300000,
    Math.random() * 800000,
    Math.random() * 1500000,
  ],
  name: "EV",
  type: "bar",
  orientation: "h",
  width: 0.4,
  barmode: "overlay",
  hoverinfo: "x",
  marker: {
    opacity: 0.7,
    color: barChart_EV,
    line: {
      width: 1,
    },
  },
};

var BudBudget = {
  y: [
    "Poles",
    "Taihan 132kv",
    "Transformer",
    "Civils and building",
    "Substaion Design",
  ],
  x: [
    Math.random() * 1500000,
    Math.random() * 500000,
    Math.random() * 300000,
    Math.random() * 800000,
    Math.random() * 1500000,
  ],
  name: "Budget",
  type: "bar",
  width: 0.4,
  barmode: "overlay",
  orientation: "h",
  hoverinfo: "x",
  marker: {
    opacity: 0.6,
    color: barChart_LifeBudget,
    line: {
      width: 1,
    },
  },
};

var BudForcast = {
  y: [
    "Poles",
    "Taihan 132kv",
    "Transformer",
    "Civils and building",
    "Substaion Design",
  ],
  x: [
    Math.random() * 1500000,
    Math.random() * 500000,
    Math.random() * 300000,
    Math.random() * 800000,
    Math.random() * 1500000,
  ],
  name: "CAC",
  type: "bar",
  orientation: "h",
  barmode: "overlay",
  hoverinfo: "x",
  width: 0.4,
  marker: {
    opacity: 0.4,
    color: barChart_BudgetToDate,
    line: {
      width: 1,
    },
  },
};

///////////////////////////////////////// Currency

function AUD(n, currency) {
  if (n != undefined) {
    return (
      currency +
      n
        .toFixed()
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }
  return 0;
}

//////////////////////////////////////// trend Graph

function TrendingGraph(input, array) {
  for (let i = 0; i < input.length; i++) {
    if (typeof array[i] === "undefined") {
      array.push(input[i]);
    } else {
      if (isFinite(input[i])) {
        array[i] += input[i];
      }
    }
  }
  return array;
}

//////////////////////////////////////// Remove Last element

function Removetrail(inputval) {
  while (inputval[inputval.length - 1] === 0) {
    // While the last element is a 0,
    inputval.pop(); // Remove that last element
  }
  return inputval;
}

//////////////////////////////////////// Remove Zero Value from element

function RemoveZero(inputval) {
  for (var i = 0; i < inputval.length; i++) {
    if (inputval[i] == 0 && i > 0) {
      inputval[i] = inputval[i - 1];
    }
  }
  return inputval;
}

//////////////////////////////////////// Chart API

const state = {
  dashboardName: "SI- 00362 | ARA NEW 33KV SUPPLY TO WESTCONNEX STG 3B",
  loadingStatus: false,
  cards: [
    {
      id: 0,
      formate: "layout1",
      class: "is-12",
      heading: "",
      charts: [],
    },

    {
      //KPI
      id: 1,
      class: "is-12",
      title: "KPI",
      h: window.innerWidth,
      heading: "REPORTING DATE: ",
      charts: [
        {
          //SPI
          id: 1,
          type: 2,
          title: "KPI",
          formate: "layout1",
          class: "is-12",
          data: [
            {
              type: "indicator",
              mode: "gauge+delta+number",
              title: { text: "SPI" },
              value: 0.08,
              number: { suffix: "%" },
              delta: { reference: 100, relative: true },
              gauge: {
                axis: { visible: true, tickformat: "", range: [0, 200] },
                bar: { color: "rgb(255,0,0)" },
              },
              domain: { x: [0, 0.95], y: [0, 1] },
            },
          ],
          layout: {
            margin: { t: 0, b: 0, l: 25, r: 40 },
            height: 275,
            width: 280,
          },
          config: {
            responsive: true,
          },
        },
        {
          id: 2,
          type: 3,
          title: "KPI",
          formate: "layout1",
          class: "is-7",
          data: [
            {
              //CPI
              type: "indicator",
              mode: "gauge+delta+number",
              title: { text: "CPI" },
              value: 130,
              number: { suffix: "%" },
              delta: { reference: 100, relative: true },
              gauge: {
                axis: { visible: true, range: [0, 200] },
                bar: { color: "(0,128,0)" },
              },
              domain: { x: [0, 0.95], y: [0, 1] },
            },
          ],
          layout: {
            margin: { t: 0, b: 10, l: 50, r: 25 },
            height: 275,
            width: 280,
          },
          config: {
            responsive: true,
          },
        },
        {
          // Line graph
          id: 3,
          type: 4,
          title: "LIFE-TO-DATE",
          formate: "layout1",
          class: [],
          data: [LifeActuals, LifeEV, LifetoBudget, LifeBudget],
          layout: {
            barmode: "overlay",
            margin: { t: 0, r: 0, l: 120, b: 50 },
            showlegend: true,
            height: 110,
            width: 550,
            xaxis: {
              hoverformat: "$,.0f",
            },
            hovermode: "closest",
            legend: {
              orientation: "h",
              yanchor: "bottom",
              y: 1.02,
              xanchor: "right",
              x: 1,
            },
          },

          config: {
            responsive: true,
          },
        },
        {
          //table
          id: 4,
          type: 2,
          title: "",
          formate: "layout1",
          class: [],
          data: [
            {
              type: "table",
              header: {
                values: [["<b> % Complete</b>"]],
                align: "center",
                line: { width: 1, color: "black" },
                fill: { color: "lightgrey" },
                font: { family: "Verdana", size: 12, color: "black" },
              },
              cells: {
                values: [["<b>63%</b>"]],
                align: "center",
                line: { color: "black", width: 1 },
                font: { family: "Verdana", size: 15, color: ["black"] },
              },
            },
          ],
          layout: {
            margin: { t: 50, b: 0, l: 25, r: 2 },
            height: 130,
            width: 160,
          },
          config: {
            responsive: true,
          },
        },
        {
          id: 5,
          type: 2,
          title: "LIFE-TO-DATE VARIANCES",
          class: [],
          formate: "layout1",
          data: [
            {
              type: "table",
              header: {
                values: [["Budget Var."], ["<b>$1,500,000</b>"]],
                align: "center",
                fill: { color: ["lightgrey", "white"] },
                line: { color: "black", width: 1 },
                font: {
                  family: "Verdana",
                  size: 12,
                  color: ["black", "green"],
                },
              },
              cells: {
                values: [
                  ["Cost Var", "Schedule Var"],
                  ["<b>$234,567</b>", "<b>$765,433</b>"],
                ],
                align: "center",
                fill: { color: ["lightgrey", "white"] },
                line: { color: "black", width: 1 },
                font: {
                  family: "Verdana",
                  size: 12,
                  color: ["black", ["green", "red"]],
                },
              },
            },
          ],
          layout: {
            margin: { t: 50, b: 0, l: 25, r: 2 },
            height: 150,
            width: 300,
            color: "rgb(255,255,255)",
            title: "<b>   LIFE-TO-DATE VARIANCES</b>",
          },
          config: {
            responsive: true,
          },
        },
        {
          id: 6,
          type: 2,
          title: "FINANCIAL YEAR",
          class: [],
          formate: "layout1",
          data: [
            {
              type: "table",
              header: {
                values: [["Budget"], ["<b>$300,000</b>"]],
                align: "center",
                fill: { color: ["grey", "white"] },
                line: { color: "black", width: 1.0 },
                font: {
                  family: "Verdana",
                  size: 12,
                  color: ["white", "green"],
                },
              },
              cells: {
                values: [
                  ["Spend", "CAC"],
                  ["<b>$234,567</b>", "<b>$360,000</b>"],
                ],
                align: "center",
                fill: { color: ["grey", "white"] },
                line: { color: "black", width: 1 },
                font: {
                  family: "Verdana",
                  size: 12,
                  color: ["white", ["green", "red"]],
                },
              },
            },
          ],
          layout: {
            margin: { t: 50, b: 0, l: 25, r: 2 },
            height: 150,
            width: 280,
            title: "<b>   FINANCIAL YEAR </b>",
          },
          config: {
            responsive: true,
          },
        },
      ],
    },

    {
      //TRENDS
      id: 2,
      class: "is-12",
      title: "TRENDS",
      charts: [
        {
          id: 1,
          type: 2,
          class: [],
          formate: "layout2",
          data: [
            {
              x: [
                "1/1/2020",
                "1/4/2020",
                "1/7/2020",
                "1/10/2020",
                "1/1/2021",
                "1/4/2021",
                "1/7/2021",
                "1/10/2021",
                "1/1/2022",
                "1/4/2022",
              ],
              y: [100, 101, 108, 110, 152, 300, 880, 960, 990, 1000],
              mode: "lines+markers",
              name: "Budget",
              //text: ['tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object'],
              line: { shape: "spline", color: barChart_Contigency },
              type: "scatter",
              hoverinfo: "y",
            },
            {
              x: [
                "1/1/2020",
                "1/4/2020",
                "1/7/2020",
                "1/10/2020",
                "1/1/2021",
                "1/4/2021",
                "1/7/2021",
                "1/10/2021",
                "1/1/2022",
                "1/4/2022",
              ],
              y: [80, 100, 107, 121, 170, 250, 600, 700],
              mode: "lines+markers",
              name: "Actual",
              //  text: ['tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object'],
              line: { shape: "spline", color: barChart_Actuals },
              type: "scatter",
              hoverinfo: "y",
            },
            {
              x: [
                "1/1/2020",
                "1/4/2020",
                "1/7/2020",
                "1/10/2020",
                "1/1/2021",
                "1/4/2021",
                "1/7/2021",
                "1/10/2021",
                "1/1/2022",
                "1/4/2022",
              ],
              y: [100, 110, 145, 180, 240, 350, 930, 970],
              mode: "lines+markers",
              name: "EV",
              //  text: ['tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object'],
              line: { shape: "spline", color: "rgb(240, 94, 27)" },
              type: "scatter",
              hoverinfo: "y",
            },
          ],
          layout: {
            margin: { t: 20, b: 70, l: 35, r: 60 },
            height: 350,
            width: 630,
            xaxis: {
              showgrid: true,
              showline: true,
              linewidth: 2,
              linecolor: "black",
              ticks: "auto",
              tickwidth: 2,
              tickcolor: "crimson",
              ticklen: 2,
            },
            yaxis: {
              hoverformat: "$,.0f",
              showline: true,
              linewidth: 2,
              linecolor: "black",
              ticks: "auto",
              tickwidth: 2,
              tickcolor: "crimson",
              ticklen: 2,
            },
            legend: {
              traceorder: "reversed",
              font: { size: 16 },
              yref: "paper",
              orientation: "h",
              yanchor: "bottom",
              y: 1.02,
              xanchor: "right",
              x: 1,
            },
            shapes: [
              {
                type: "box",
                x0: "1/7/2020",
                y0: 0,
                x1: "1/7/2021",
                yref: "paper",
                y1: 1,
                opacity: 0.5,
                annotation_text: "decline",
                annotation_position: "top left",
                annotation: {
                  size: 20,
                  family: "Times New Roman",
                },
                //title:"<b> 01/07/2020 - 30/6/2021 2020-2021</b>",
                fillcolor: "darkgrey",
                line: {
                  color: "grey",
                  width: 0.2,
                  dash: "dot",
                },
              },
            ],
          },
          config: {
            responsive: true,
          },
        },
        {
          id: 2,
          type: 3,
          formate: "layout2",
          class: [],
          data: [
            {
              x: [
                "Development",
                "Design",
                "Management",
                "Procurement",
                "Construction",
              ],
              y: yvalue,
              text: yvalue,
              name: "Actual",
              type: "bar",
              offsetgroup: 0,
              textposition: "auto",
              hovertemplate: yvalue,
              marker: {
                color: barChart_Actuals, //['rgb(0,0,0)', 'rgb(255,0,0)', 'rgb(0,0,0)', 'rgb(0,0,0)', 'rgb(0,0,0)'],
                opacity: 0.6,
                line: {
                  color: "rgb(0,0,0)",
                  width: 2,
                },
              },
            },
            {
              x: [
                "Development",
                "Design",
                "Management",
                "Procurement",
                "Construction",
              ],
              y: yvalue2,
              text: yvalue2,
              name: "Commitment",
              type: "bar",
              offsetgroup: 0,
              base: yvalue,
              hovertemplate: yvalue2,
              textposition: "auto",
              marker: {
                color: barChart_BudgetToDate,
                opacity: 0.6,
                line: {
                  color: "rgb(0,0,0)",
                  width: 2,
                },
              },
            },
            {
              x: [
                "Development",
                "Design",
                "Management",
                "Procurement",
                "Construction",
              ],
              y: yvalue1,
              text: yvalue1,
              name: "Budget",
              type: "bar",
              offsetgroup: 1,
              hovertemplate: yvalue1,
              textposition: "auto",
              marker: {
                color: barChart_Contigency,
                opacity: 0.6,
                line: {
                  color: "rgb(0,0,0)",
                  width: 2,
                },
              },
            },
          ],
          layout: {
            margin: { t: 40, b: 35, l: 50, r: 0 },
            height: 315,
            width: 720,
            barmode: "overlay",
            legend: {
              orientation: "h",
              yanchor: "bottom",
              y: 0.97,
              xanchor: "right",
              x: 0.95,
            },
            xaxis: {
              showgrid: true,
              showline: true,
              linewidth: 2,
              tickangle: 0,
              linecolor: "black",
              ticks: "auto",
              tickwidth: 2,
              tickcolor: "crimson",
              ticklen: 2,
            },
            yaxis: {
              hoverformat: "$,.0f",
              showline: true,
              linewidth: 2,
              linecolor: "black",
              ticks: "auto",
              tickwidth: 2,
              tickcolor: "crimson",
              ticklen: 2,
            },
          },
          config: {
            responsive: true,
          },
        },
      ],
    },

    {
      //KEY AREAS
      id: 3,
      class: "is-12",
      title: "KEY AREAS",
      charts: [
        {
          id: 1,
          type: 4,
          formate: "layout2",
          title: "Highest Earned Project",
          class: [],
          data: [Budget, Forcast, Actuals, EV],
          layout: {
            barmode: "overlay",
            margin: { t: 50, r: 30, l: 170, b: 27 },
            showlegend: true,
            height: 300,
            width: 630,
            hovermode: "closest",
            title: "<b>Highest Earned Project </b>",
            legend: {
              orientation: "h",
              yanchor: "bottom",
              y: 0.99,
              xanchor: "right",
              x: 0.7,
            },
            xaxis: {
              hoverformat: "$,.0f",
              showgrid: true,
              showline: true,
              linewidth: 2,
              linecolor: "black",
              ticks: "auto",
              tickwidth: 2,
              tickcolor: "crimson",
              ticklen: 2,
            },
            yaxis: {
              showline: true,
              linewidth: 2,
              linecolor: "black",
              tickangle: 0,
              ticks: "auto",
              ticklen: 1.5,
            },
          },
          config: {
            responsive: true,
          },
        },
        {
          id: 2,
          type: 2,
          formate: "layout2",
          title: "Highest Budget Project",
          class: [],
          data: [BudBudget, BudForcast, BudActuals, BudEV],
          layout: {
            barmode: "overlay",
            margin: { t: 50, r: 30, l: 170, b: 27 },
            showlegend: true,
            height: 300,
            width: 630,
            title: "<b>Highest Budget Project</b>",
            legend: {
              orientation: "h",
              yanchor: "bottom",
              y: 0.99,
              xanchor: "right",
              x: 0.7,
            },
            hovermode: "closest",
            xaxis: {
              hoverformat: "$,.0f",
              showgrid: true,
              showline: true,
              linewidth: 2,
              linecolor: "black",
              ticks: "auto",
              tickwidth: 2,
              tickcolor: "crimson",
              ticklen: 2,
            },
            yaxis: {
              showline: true,
              linewidth: 2,
              linecolor: "black",
              tickangle: 0,
              ticks: "auto",
              ticklen: 1.5,
            },
          },
          config: {
            responsive: true,
          },
        },
      ],
    },
  ],
};

const actions = {
  LoadPortfolio({ dispatch, commit }, { reportDate, viewID }) {
    getMonthColumnsJSON(viewID)
      .then((monthsData) => {
        //Examine monthsData for the header that has the month and year
        //Pass that with the rowData
        //var startMonth = convertedHeader;
        getRemoteRow(viewID)
          .then((rowData) => {
            commit("resetPortfolio", { loadingstatus: false });
            commit("storeapivalues", {
              data: rowData,
              MonthColumnsData: monthsData,
              reportingDate: reportDate,
            });
          })
          .catch((error) => {
            commit("resetPortfolio", { loadingstatus: true });
            dispatch("alert/error", error, { root: true });
          });
      })
      .catch((error) => {
        commit("resetPortfolio", { loadingstatus: true });
        dispatch("alert/error", error, { root: true });
      });
  },
};

const mutations = {
  changeLoadingStatus(state, { loadingstatus }) {
    state.loadingStatus = loadingstatus;
  },

  storeapivalues(state, { data, MonthColumnsData, reportingDate }) {
    {
      //Title
      state.cards[0].heading = "";
    }

    var headerExtration = MonthColumnsData.toString()
      .slice(110, -1)
      .replace("\r\n", " ")
      .split("[");
    var startDateString = headerExtration[0].split('"');
    var startPoint = null,
      monthsToReportingDate = 0;
    if (startDateString.length > 2) {
      startPoint = startDateString[1].split(",");
      var dateFrom = new Date(
        startPoint[1],
        monthNames.findIndex((x) => x == startPoint[0]) - 1
      );
      var dateTo = new Date(reportingDate);
      monthsToReportingDate =
        dateTo.getMonth() -
        dateFrom.getMonth() +
        12 * (dateTo.getFullYear() - dateFrom.getFullYear());
    }
    //Filter var for KPI Trends and Key Areas
    var trendfilter = data
      .map((a, index) => {
        if (!a.path[1]) {
          const container = {};
          container["path"] = a.path[1];
          container["projectpath"] = a.path[0];
          container["Description"] = a.description;
          container["code"] = a.code;
          container["budget"] = a.budget;
          container["forecast"] = a.forecast;
          container["actual"] = a.actual;
          container["EV"] = a.earnedValue;
          container["commited"] = a.commited - a.deliveredCost;
          container["index"] = index;
          container["EVGraph"] = [];
          container["MActual"] = [];
          container["Mbudget"] = [];
          container["percentage"] = a.percent;
          container["MCAC"] = [];
          var tempMFTP = 0,
            pm = 0;
          for (let i = 1; i < headerExtration.length; i++) {
            if (a["m" + i + "actualAccumulated"] == undefined) {
              a["m" + i + "actualAccumulated"] = 0;
            }
            if (a["m" + i + "forecastAccumulated"] == undefined) {
              a["m" + i + "forecastAccumulated"] = 0;
            }
            container["MActual"].push(a["m" + i + "actualAccumulated"]);
            container["Mbudget"].push(a["m" + i + "forecastAccumulated"]);
            if (i < monthsToReportingDate) {
              container["EVGraph"].push(
                (((a["m" + i + "forecastAccumulated"] / a.budget) * a.percent) /
                  (a.forecast / a.budget)) *
                  a.budget
              );
              tempMFTP = a["m" + i + "actual"];
              container["MCAC"].push(tempMFTP);
            } else if (i == monthsToReportingDate) {
              container["EVGraph"].push(
                (((a["m" + i + "forecastAccumulated"] / a.budget) * a.percent) /
                  (a.forecast / a.budget)) *
                  a.budget
              );
              pm = i - 1;
              if (pm == 0) {
                tempMFTP = 0;
              } else {
                tempMFTP =
                  a["m" + i + "forecastAccumulated"] /
                    (a.earnedValue / a.actual) -
                  a["m" + pm + "actualAccumulated"];
              }
              container["MCAC"].push(tempMFTP);
            } else {
              container["EVGraph"].push(0);
              tempMFTP = a["m" + i + "forecast"] / (a.earnedValue / a.actual);
              container["MCAC"].push(tempMFTP);
            }
            if (tempMFTP == undefined) {
              tempMFTP = 0;
            }
            /* if (
              (a.actual != undefined || a.actual > 0) &&
              (a.earnedValue != undefined || a.earnedValue > 0)
            ) {
              tempMCAC =
                a["m" + i + "forecastAccumulated"] /
                  (a.earnedValue / a.actual) -
                tempMFTP;
            }
            if (tempMCAC != Infinity) {
              if (isNaN(tempMCAC)) {
                tempMCAC = 0;
              } 
              container["MCAC"].push(tempMFTP)
            };*/
          }
          return container;
        }
      })
      .filter(Boolean);

    {
      //KPI
      //SPI
      var sumev = 0,
        sumforecast = 0,
        sumactual = 0,
        sumbudget = 0;
      //sumcommited = 0,
      //sumpercentage = 0;
      trendfilter.forEach((element) => {
        sumev += element.EV;
        sumforecast += element.forecast;
        sumactual += element.actual;
        sumbudget += element.budget;
        //sumcommited += element.commited;
        //sumpercentage += element.percentage;
      });

      var SPI = sumev / sumforecast;
      if (isNaN(SPI)) {
        SPI = 0;
      }
      state.cards[1].charts[0].data[0].value = SPI * 100;
      if (state.cards[1].charts[0].data[0].value < 100) {
        state.cards[1].charts[0].data[0].gauge.bar.color = "rgb(255,0,0)";
      } else {
        state.cards[1].charts[0].data[0].gauge.bar.color = "(0,128,0)";
      }
      //CPI
      var CPI = sumev / sumactual;
      if (isNaN(CPI)) {
        CPI = 0;
      }
      state.cards[1].charts[1].data[0].value = CPI * 100;
      if (state.cards[1].charts[1].data[0].value < 100) {
        state.cards[1].charts[1].data[0].gauge.bar.color = "rgb(255,0,0)";
      } else {
        state.cards[1].charts[1].data[0].gauge.bar.color = "(0,128,0)";
      }
      {
        //Life to Date
        (LifeActuals.x[0] = AUD(sumactual, "$")),
          (LifeEV.x[0] = AUD(sumev, "$")),
          (LifetoBudget.x[0] = AUD(sumforecast, "$")),
          (LifeBudget.x[0] = AUD(sumbudget, "$")),
          (state.cards[1].charts[2].data = [
            LifeActuals,
            LifeEV,
            LifeBudget,
            LifetoBudget,
          ]);
      }
      {
        //Completion Rate
        state.cards[1].charts[3].data[0].cells.values = [
          "<b>" + ((sumev / sumbudget) * 100).toFixed(2) + "%<b>",
        ];
      }
      {
        //Life To-date Variances
        var calVariance = sumbudget - sumactual;
        if (isNaN(calVariance)) {
          calVariance = 0;
        }
        //Header
        var tempsign = "$",
          tempsign2 = "$";
        if (calVariance < 0) {
          (state.cards[1].charts[4].data[0].header.font.color[1] = "red"),
            (calVariance = -1 * calVariance);
          tempsign = "-$";
        } else {
          state.cards[1].charts[5].data[0].header.font.color[1] = "green";
          tempsign = "$";
        }
        state.cards[1].charts[4].data[0].header.values = [
          ["Variance"],
          ["<b> " + AUD(calVariance, tempsign) + "<b>"],
        ];

        //cells
        var SV = sumev - sumforecast;
        if (isNaN(SV)) {
          SV = 0;
        }
        if (SV < 0) {
          SV = SV * -1;
          state.cards[1].charts[4].data[0].cells.font.color[1][1] = "red";
          tempsign = "-$";
        } else {
          state.cards[1].charts[4].data[0].cells.font.color[1][1] = "green";
          tempsign = "$";
        }

        var CV = sumev - sumactual;
        if (isNaN(CV)) {
          CV = 0;
        }
        if (CV < 0) {
          CV = CV * -1;
          state.cards[1].charts[4].data[0].cells.font.color[1][0] = "red";
          tempsign2 = "-$";
        } else {
          state.cards[1].charts[4].data[0].cells.font.color[1][0] = "green";
          tempsign2 = "$";
        }
        state.cards[1].charts[4].data[0].cells.values = [
          ["CV", "SV"],
          [
            "<b>" + AUD(CV, tempsign2) + "</b>",
            "<b>" + AUD(SV, tempsign) + "</b>",
          ],
        ];
      }
    }

    {
      //Trend
      //Bar graph

      {
        //Line Graph

        var valueactual = [];
        var valuebudget = [];
        var valuecommitment = [];
        var valueMCAC = [];
        var templenActual = [],
          templenBudget = [],
          templenEVgraph = [];
        trendfilter.forEach((element) => {
          Removetrail(element.Mbudget);
          Removetrail(element.MActual);
          if (element.EVGraph.length > 0) {
            Removetrail(element.EVGraph);
          }
          templenActual.push(element.MActual.length);
          templenBudget.push(element.Mbudget.length);
          templenEVgraph.push(element.EVGraph.length);
        });

        var maxActual = Math.max(...templenActual);
        var maxBudget = Math.max(...templenBudget);
        var maxEVgraph = Math.max(...templenEVgraph);
        trendfilter.forEach((element) => {
          for (let i = 0; i < maxBudget; i++) {
            if (element.Mbudget[i] == undefined && i > 0) {
              element.Mbudget[i] = element.Mbudget[i - 1];
            }
          }

          for (let i = 0; i < maxActual; i++) {
            if (element.MActual[i] == undefined && i > 0) {
              element.MActual[i] = element.MActual[i - 1];
            }
          }

          for (let i = 0; i < maxEVgraph; i++) {
            if (element.EVGraph[0] == undefined) {
              element.EVGraph[i] = 0;
            }
            if (element.EVGraph[i] == undefined && i > 0) {
              element.EVGraph[i] = element.EVGraph[i - 1];
            }
          }
        });
        trendfilter.forEach((element) => {
          TrendingGraph(element.Mbudget, valuebudget);
          TrendingGraph(element.MActual, valueactual);
          TrendingGraph(element.EVGraph, valuecommitment);
          TrendingGraph(element.MCAC, valueMCAC);
        });

        Removetrail(valueactual);
        Removetrail(valuebudget);
        Removetrail(valuecommitment);
        if (startPoint != null) {
          AccumulatedValues(startPoint, headerExtration.length - 1);
        }

        valuebudget = RemoveZero(valuebudget);
        valueactual = RemoveZero(valueactual);
        valuecommitment = RemoveZero(valuecommitment);

        var reportDate = moment.utc(reportingDate).local().format("DD/MM/YYYY");
        state.cards[1].heading = "REPORTING DATE: " + reportDate;

        reportDate = new Date(reportingDate);
        getCurrentFinancialYear(reportDate);
        var ReportFyYear = financial_year;

        {
          var index = getTrendPoint(storeX, ReportFyYear[0]);
          var FYstart = 0,
            FYend = storeX.length - 1;
          if (index > -1) {
            FYstart = index;
            state.cards[2].charts[0].layout.shapes[0].x0 = storeX[index];
          }

          index = getTrendPoint(storeX, ReportFyYear[1]);
          if (index > -1) {
            FYend = index;
          }

          {
            // FINANCIAL YEAR=
            var sumBudget = 0,
              sumActual = 0,
              sumCAC = 0;
            if (FYstart > 0) {
              sumBudget = valuebudget[FYend] - valuebudget[FYstart];
              if (valueactual[FYend] == undefined) {
                sumActual =
                  valueactual[valueactual.length - 1] - valueactual[FYstart];
              } else {
                sumActual = valueactual[FYend] - valueactual[FYstart];
              }
            } else {
              sumBudget = valuebudget[FYend];
              if (valueactual[FYend] == undefined) {
                sumActual = valueactual[valueactual.length - 1];
              } else {
                sumActual = valueactual[FYend];
              }
            }
            for (let i = FYstart; i <= FYend; i++) {
              if (valueMCAC[i] != undefined) {
                if (isFinite(valueMCAC[i])) {
                  sumCAC += valueMCAC[i];
                }
              }
            }

            //Header
            if (sumBudget < 0) {
              state.cards[1].charts[5].data[0].header.font.color[1] = "red";
              tempsign = "-$";
            } else {
              state.cards[1].charts[5].data[0].header.font.color[1] = "green";
              tempsign = "$";
            }

            try {
              state.cards[1].charts[5].data[0].header.values = [
                ["Budget"],
                ["<b> " + AUD(sumBudget, tempsign) + "<b>"],
              ];
            } catch (err) {
              state.cards[1].charts[5].data[0].header.values = [
                ["Budget"],
                ["<b> " + "$0" + "<b>"],
              ];
            }

            //cells

            var SPEND = sumActual; //data[0].commited - data[0].deliveredCost
            if (isNaN(SPEND)) {
              SPEND = 0;
            }
            if (SPEND < 0) {
              SPEND = SPEND * -1;
              state.cards[1].charts[5].data[0].cells.font.color[1][0] = "red";
              tempsign2 = "-$";
            } else {
              state.cards[1].charts[5].data[0].cells.font.color[1][0] = "green";
              tempsign2 = "$";
            }

            if (CPI == 0) {
              CPI = 1;
            }
            var CAC = sumCAC;
            if (isNaN(CAC)) {
              CAC = 0;
            }
            if (CAC < 0) {
              CAC = CAC * -1;
              state.cards[1].charts[5].data[0].cells.font.color[1][1] = "red";
              tempsign = "-$";
            } else {
              state.cards[1].charts[5].data[0].cells.font.color[1][1] = "green";
              tempsign = "$";
            }

            state.cards[1].charts[5].data[0].cells.values = [
              ["Actual", "CAC"],
              [
                "<b>" + AUD(SPEND, tempsign2) + "</b>",
                "<b>" + AUD(CAC, "$") + "</b>",
              ],
            ];
          }

          {
            //-6 Month TrendGraph
            {
              //Trend Graph 6 month backwards and 18 months forwards

              var tempstart = 0,
                tempend = storeX.length;
              //Budget
              if (FYstart >= 6) {
                tempstart = FYstart - 6;
                //temptrendstart=FYstart
              }

              if (FYend + 19 <= storeX.length) {
                tempend = FYend + 19;
              }
              (state.cards[2].charts[0].data[0].x = storeX.slice(
                tempstart,
                tempend
              )),
                (state.cards[2].charts[0].data[0].y = valuebudget.slice(
                  tempstart,
                  tempend
                ));

              //Actual
              (state.cards[2].charts[0].data[1].x = storeX.slice(
                tempstart,
                tempend
              )),
                (state.cards[2].charts[0].data[1].y = valueactual.slice(
                  tempstart,
                  tempend
                ));

              //EV
              (state.cards[2].charts[0].data[2].x = storeX.slice(
                tempstart,
                tempend
              )),
                (state.cards[2].charts[0].data[2].y = valuecommitment.slice(
                  tempstart,
                  tempend
                ));

              //Financial Year color shape
              state.cards[2].charts[0].layout.shapes[0].x0 = storeX[FYstart];
              state.cards[2].charts[0].layout.shapes[0].x1 = storeX[FYend];
            }
          }
        }
      }
      trendfilter = data
        .map((a, index) => {
          if (a.path[2] && !a.path[3]) {
            const container = {};
            container["path"] = a.path[2];
            container["Description"] = a.description;
            container["budget"] = a.budget;
            container["actual"] = a.actual;
            container["commited"] = a.commited - a.deliveredCost;
            container["index"] = index;
            return container;
          }
        })
        .filter(Boolean);
      var valueofx = [];
      valueactual = 0;
      valuebudget = 0;
      valuecommitment = 0;
      valueMCAC = [];
      var tempticks = [];
      {
        trendfilter.sort(function (a, b) {
          return a.code === b.code ? 0 : a.code < b.code ? -1 : 1;
        });
        trendfilter.forEach((element, i) => {
          valueofx.push(element.Description);
          if (element.actual) {
            valueactual += element.actual;
          }
          if (element.budget) {
            valuebudget += element.budget;
          }
          if (element.commited) {
            valuecommitment += element.commited;
          }
          tempticks.push(i);
        });
        //Actual
        (state.cards[2].charts[1].data[0].x = valueofx),
          (state.cards[2].charts[1].data[0].y = [valueactual]),
          (state.cards[2].charts[1].data[0].text = AUD(valueactual, "$"));

        //Commitment
        (state.cards[2].charts[1].data[1].x = valueofx),
          (state.cards[2].charts[1].data[1].y = [valuecommitment]),
          (state.cards[2].charts[1].data[1].text = AUD(valuecommitment, "$"));
        (state.cards[2].charts[1].data[1].base = valueactual),
          //Budget
          (state.cards[2].charts[1].data[2].x = valueofx),
          (state.cards[2].charts[1].data[2].y = [valuebudget]),
          (state.cards[2].charts[1].data[2].text = AUD(valuebudget, "$"));
      }
    }

    {
      //Key Areas
      trendfilter = data
        .map((a, index) => {
          if (
            a.path.filter((item) => item.includes("Prj_")).length > 0 &&
            a.budget &&
            !a.path.filter((item) => item.includes("Sd_")).length > 0
          ) {
            const container = {};
            container["path"] = a.path;
            container["Description"] = a.description;
            container["budget"] = a.budget;
            container["actual"] = a.actual;
            container["EV"] = a.earnedValue;
            CPI = a.earnedValue / a.actual;
            container["CAC"] = a.budget / CPI;
            container["index"] = index;
            return container;
          }
        })
        .filter(Boolean);

      var highestValue = trendfilter
        .sort((a, b) => (b.EV < a.EV ? -1 : 1))
        .slice(0, 5)
        .reverse();

      var graphY = [];
      var graphActual = [];
      var graphEV = [];
      var graphCAC = [];
      var graphBudget = [];
      /*Higest EV Graph*/
      highestValue.forEach((element) => {
        graphY.push(element.Description.slice(0, 20));
        graphActual.push(element.actual);
        graphBudget.push(element.budget);
        graphEV.push(element.EV);
        graphCAC.push(element.CAC);
      });

      //Forcast
      Forcast.y = graphY;
      Forcast.x = graphCAC;

      //Actual
      Actuals.y = graphY;
      Actuals.x = graphActual;

      //EV
      EV.y = graphY;
      EV.x = graphEV;

      //Budget
      Budget.y = graphY;
      Budget.x = graphBudget;

      /*Higest Budget Graph*/
      highestValue = trendfilter
        .sort((a, b) => (b.budget < a.budget ? -1 : 1))
        .slice(0, 5)
        .reverse();
      graphY = [];
      graphActual = [];
      graphEV = [];
      graphCAC = [];
      graphBudget = [];
      highestValue.forEach((element) => {
        graphY.push(element.Description.slice(0, 20));
        graphActual.push(element.actual);
        graphBudget.push(element.budget);
        graphEV.push(element.EV);
        graphCAC.push(element.CAC);
      });

      //Forcast
      BudForcast.y = graphY;
      BudForcast.x = graphCAC;

      //Actual
      BudActuals.y = graphY;
      BudActuals.x = graphActual;

      //EV
      BudEV.y = graphY;
      BudEV.x = graphEV;

      //Budget
      BudBudget.y = graphY;
      BudBudget.x = graphBudget;
    }

    if (!state.loadingStatus) {
      state.loadingStatus = true;
    }
  },

  resetPortfolio(state, { loadingstatus }) {
    state.loadingStatus = loadingstatus;
    state.cards[0].heading = null;
    {
      //KPI
      state.cards[1].heading = null;
      state.cards[1].charts[0].data[0].value = 0;
      state.cards[1].charts[1].data[0].value = 0;
      LifeActuals.x[0] = 0;
      LifeEV.x[0] = 0;
      LifetoBudget.x[0] = 0;
      LifeBudget.x[0] = 0;
      state.cards[1].charts[2].data = [
        LifeActuals,
        LifeEV,
        LifeBudget,
        LifetoBudget,
      ];
      state.cards[1].charts[3].data[0].cells.values = ["<b> 0%<b>"];
      state.cards[1].charts[4].data[0].header.values = [
        ["Variance"],
        ["<b>0<b>"],
      ];
      state.cards[1].charts[4].data[0].cells.values = [
        ["CV", "SV"],
        ["<b> 0</b>", "<b> 0 </b>"],
      ];
      state.cards[1].charts[5].data[0].header.values = [
        ["Budget"],
        ["<b> " + "$0" + "<b>"],
      ];

      state.cards[1].charts[5].data[0].cells.values = [
        ["Actual", "CAC"],
        ["<b>0</b>", "<b>0</b>"],
      ];
    }
    {
      //Budget
      state.cards[2].charts[0].data[0].x = 0;
      state.cards[2].charts[0].data[0].y = 0;
      //Actual
      state.cards[2].charts[0].data[1].x = 0;
      state.cards[2].charts[0].data[1].y = 0;
      //EV
      state.cards[2].charts[0].data[2].x = 0;
      state.cards[2].charts[0].data[2].y = 0;

      //Actual
      state.cards[2].charts[1].data[0].x = 0;
      state.cards[2].charts[1].data[0].y = 0;

      //Commitment
      state.cards[2].charts[1].data[1].x = 0;
      state.cards[2].charts[1].data[1].y = 0;

      //Budget
      state.cards[2].charts[1].data[2].x = 0;
      state.cards[2].charts[1].data[2].y = 0;
    }
    {
      Forcast.y = 0;
      Forcast.x = 0;

      //Actual
      Actuals.y = 0;
      Actuals.x = 0;

      //EV
      EV.y = 0;
      EV.x = 0;

      //Budget
      Budget.y = 0;
      Budget.x = 0;

      //Forcast
      BudForcast.y = 0;
      BudForcast.x = 0;

      //Actual
      BudActuals.y = 0;
      BudActuals.x = 0;

      //EV
      BudEV.y = 0;
      BudEV.x = 0;

      //Budget
      BudBudget.y = 0;
      BudBudget.x = 0;
    }
  },
};

const getters = {
  cards() {
    return state.cards;
  },
  dashboardName() {
    return state.dashboardName;
  },
  getLoadingStatus() {
    return state.loadingStatus;
  },
  row() {
    return state.row;
  },
};

export const dashboardportfolio = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
