/* eslint-disable no-unused-vars */
import { userService } from "./user-auth";

/*
async function getJSON(url) {
  const response = await fetch(url, {
    method: "GET",
    mode: "no-cors",
    cache: "no-cache",
  });

  // process response
  if (response.succeeded) {
    return response.json();
  } else {
    throw new Error("Failed Response");
  }
}*/

async function getModuleBlob(url) {
  const response = await fetch(url, {
    method: "GET",
    mode: "no-cors",
    cache: "no-cache",
  });

  // process response
  if (response.succeeded) {
    //if (response.succeeded) {
    //var respoObkj = response.data;
    return response.blob();
  } else {
    throw new Error("Failed Response");
  }
}

/**
 * Fetches Row Data
 * @param {string} viewName
 *
 * Usage:
 *  getRemoteRow(id)
 *    .then(rowdata => {...})
 *    .catch(error => {...})
 */
export async function getRemoteRow(id) {
  var rows = await userService.post(`view/ViewRows/${id}`);
  return rows;
}

/**
 * Fetches Column Config Module
 * @param {string} viewName
 *
 * Usage:
 *  getRemoteColumns(id)
 *    .then(columnConfig => { columnConfig.hydrateColumns(vueThis, utilitiesModule); })
 *    .catch(error => {...})
 */
export async function getRemoteColumns(id) {
  let moduleBlob = await userService.getFile(`userview/columns/${id}`);
  //let moduleBlob =   await getModuleBlob(`/services/1.0/userview/columns/${id}`);
  //const url = BASE_URL + "/mock/cost-struct-view/column3.js";

  //Mock Working
  //let moduleBlob = await getModuleBlob(`/mock/cost-struct-view/column${id}.js`);

  //let mb2 = await userService.getFile("view/"+id+"/columns/");
  //return mb2;

  return moduleBlob;
}

export async function postChanges(id, data) {
  //return await userService.post("view/" + id + "/rows/update/", {
  return await userService.post("view/UpdateViewRows/" + id, {
    Data: data,
  });
}

export async function getViews() {
  return await userService.getJson("view/GetAll/");
}

export async function getMasterData(filters, selectedDataTypeId) {
  var loadDataURL = "";
  switch (selectedDataTypeId) {
    case 0:
      loadDataURL = "Portfolio/GetFiltered/";
      break;
    case 1:
      loadDataURL = "Program/GetFiltered/";
      break;
    case 2:
      loadDataURL = "Project/GetFiltered/";
      break;
  }
  return await userService.post(loadDataURL, filters);
}

export async function getAllMasterData(filters, selectedDataTypeId) {
  var loadDataURL = "";
  switch (selectedDataTypeId) {
    case 0:
      loadDataURL = "Portfolio/GetAll/";
      break;
    case 1:
      loadDataURL = "Program/GetAll/";
      break;
    case 2:
      loadDataURL = "Project/GetAll/";
      break;
  }
  return await userService.getJson(loadDataURL, filters);
}
export async function UpdateMasterData(data, selectedDataTypeId) {
  var loadDataURL = "";
  switch (selectedDataTypeId) {
    case 0:
      loadDataURL = "Portfolio/Update/";
      break;
    case 1:
      loadDataURL = "Program/Update/";
      break;
    case 2:
      loadDataURL = "Project/Update/";
      break;
  }
  return await userService.post(loadDataURL, data);
}

export async function getFilters(id) {
  //return await userService.getJson(`/filters/${id}/list/`);
  return await userService.getJson(`/JIVEUser/GetP3MFilter/`);
}

export async function postUserFilters(id, filters) {
  //return await userService.post(`/filters/${id}/`, filters);
  return await userService.post(`/JIVEUser/UpdateP3MFilter/`, filters);
}

export async function postColumnState(state) {
  return await userService.post(`userview/updatecolumnstate/`, state);
  //return await userService.post(`view/${id}/columns/state/`, state);
}

export async function postColumn(state) {
  return await userService.post(`userview/updatecolumns/`, state);
  //return await userService.post(`view/${id}/columns/state/`, state);
}

export async function getColumnState(id, componentId) {
  return await userService.getJson(
    `userview/columnstate/${id}?componentId=${componentId}`
  );
}

export async function resetColumnState(id) {
  return await userService.getJson(`userview/resetcolumnstate/${id}`);
}

export async function getMonthColumns(id) {
  return await userService.getFile(`userview/monthcolumns/${id}`);
}

export async function getMonthColumnsJSON(id) {
  return await userService.getJson(`userview/monthcolumns/${id}`);
}

export async function getAuthoritiesValues(values) {
  return await userService.post(`/Processset/Execute/`, values);
}

export async function getStructureAll() {
  return await userService.getJson(`/Structure/GetAll/`);
}

export async function getStructureDetails(id) {
  return await userService.getJson(
    `/StructureDetail/GetAllByStructureId/${id}`
  );
}
export async function UpdateStructure(values) {
  return await userService.post(`/Structure/Update`, values);
}

export async function UpdateStructureDetail(values) {
  return await userService.post(`/StructureDetail/Update`, values);
}

export async function getAllStructureDetail() {
  return await userService.getJson(`/StructureDetail/GetAll/`);
}

export async function DeleteStructureDetail(structureDetailId) {
  return await userService.post(`/StructureDetail/Delete/`, structureDetailId);
}

export async function DeleteStructure(structurelId) {
  return await userService.post(`/Structure/Delete/`, structurelId);
}

export async function DeleteProject(projectId) {
  return await userService.post(`Project/Delete/`, projectId);
}

export async function DeleteProgram(programId) {
  return await userService.post(`Program/Delete/`, programId);
}

export async function DeletePortfolio(portfolioId) {
  return await userService.post(`Portfolio/Delete/`, portfolioId);
}

export async function getSpendingCurveHeaderAll() {
  return await userService.getJson(`/SpendingCurveHeader/GetAll/`);
}

export async function UpdateSpendingCurveHeader(values) {
  return await userService.post(`/SpendingCurveHeader/Update/`, values);
}

export async function DeleteSpendingCurveHeader(curveheaderID) {
  return await userService.post(`SpendingCurveHeader/Delete/`, curveheaderID);
}

export async function getCurveDetails(SpendingCurveHeaderId) {
  return await userService.getJson(
    `/SpendingCurveDetail/Get/${SpendingCurveHeaderId}`
  );
}

export async function UpdateCurveDetail(values) {
  return await userService.post(`/SpendingCurveDetail/Update/`, values);
}

export async function DeleteSpendingCurveDetail(Id) {
  return await userService.post(`SpendingCurveDetail/Delete/`, Id);
}

export async function CostHeaderGetByModel() {
  return await userService.getJson(`/CostHeader/GetByModel/`);
}

export async function DeletecostHeaders(costHeaderId) {
  return await userService.post(`/CostHeader/Delete/`, costHeaderId);
}
export async function GetCostElements({ CostElements }) {
  let CostHeaderID = CostElements[1];
  return await userService.getJson(
    `/${CostElements[0]}/GetByCostHeader/${CostHeaderID}`
  );
}

export async function UpdateCostHeader(values) {
  return await userService.post(`/CostHeader/Update`, values);
}

export async function DeleteElement(DeleteURL, Id) {
  return await userService.post(`/${DeleteURL}/Delete/`, Id);
}

export async function GetByTableType(values) {
  return await userService.getJson(`/ExtraColumn/GetByTableType/${values}`);
}

export async function getCostModelType() {
  return await userService.getJson(`/CostModelType/GetAll/`);
}

export async function getAllRowType() {
  return await userService.getJson(`/RowType/GetAll/`);
}

export async function getAllExtraColumnDetails(value) {
  return await userService.getJson(`/${value}/GetAll/`);
}

export async function UpdateExtraColumnDetails(UpdateURL, values) {
  return await userService.post(`/${UpdateURL}/Update/`, values);
}

export async function DeleteExtraColumns(DeleteURL, Id) {
  return await userService.post(`/${DeleteURL}/Delete/`, Id);
}
export async function getColumnDef(value) {
  return await userService.getFile(`/UserView/GetComponentColumn/${value}`);
}

export async function getByRiskHeaderId(value) {
  //return await userService.getJson(`/${value}`);
  return await userService.getJson(`/RiskItem/GetByRiskHeader/${value}`);
}

export async function getByPerformanceItemId(value) {
  //return await userService.getJson(`/${value}`);
  return await userService.getJson(
    `/PerformanceItem/GetByperformanceHeader/${value}`
  );
}
