const state = {
  selectedIntegrateViewId: 0,
  environments: [
    {
      name: "Production",
      value: 0,
    },
    {
      name: "UAT",
      value: 1,
    },
  ],
  selectedEnv: 0,
  allDataTypes: [
    {
      id: 0,
      name: "CXProjectMgmt",
      displayName: "Project Configuration",
      iconClass: "fas fa-folder-open",
      apiPrefix: "CXProjectMgmt",
      getAllApiSuffix: "GetAll",
      processSetId: 515, //Prod: 515, Test: 658
      selectedEnvironment: "PROD",
    },
    {
      id: 1,
      name: "UATCXProjectMgmt",
      displayName: "UAT Project Configuration",
      iconClass: "fas fa-folder-open",
      apiPrefix: "UATCXProjectMgmt",
      getAllApiSuffix: "GetAll",
      processSetId: 515, //523,
      selectedEnvironment: "UAT",
    },
    {
      id: 2,
      name: "CXProjectMgmt_New",
      displayName: "UAT Project Configuration",
      iconClass: "fas fa-folder-open",
      apiPrefix: "CXProjectMgmt",
      getAllApiSuffix: "GetAll",
      processSetId: 567, //523,
      selectedEnvironment: "UAT",
    },
  ],
  docTypeCodes: [
    { code: "COR", compareGroups: false, compareSecurity: false },
    { code: "BGT", compareGroups: false, compareSecurity: false },
    { code: "VARB", compareGroups: false, compareSecurity: false },
    { code: "CPI", compareGroups: false, compareSecurity: false },
    { code: "BTR", compareGroups: false, compareSecurity: false },
    { code: "CAL", compareGroups: false, compareSecurity: false },
    { code: "CLM", compareGroups: false, compareSecurity: false },
    { code: "CTR", compareGroups: false, compareSecurity: false },
    { code: "DBDN", compareGroups: false, compareSecurity: false },
    { code: "DOC", compareGroups: false, compareSecurity: false },
    { code: "DBUP", compareGroups: false, compareSecurity: false },
    { code: "FOR", compareGroups: false, compareSecurity: false },
    { code: "NB", compareGroups: false, compareSecurity: false },
    { code: "VAR", compareGroups: false, compareSecurity: false },
    { code: "PPC", compareGroups: false, compareSecurity: false },
    { code: "DIA", compareGroups: false, compareSecurity: false },
    { code: "TND", compareGroups: false, compareSecurity: false },
    { code: "TSH", compareGroups: false, compareSecurity: false },
    { code: "TSK", compareGroups: false, compareSecurity: false },
  ],
  compareOptions: {
    docTypes: false,
    savedSearches: false,
    commonUDF: false,
    groups: false,
    roles: false,
    costCodes: false,
  },
  projectCodeRowData: [],
  templateDetails: "",
  idSequence: 0,
  newCompareProcessSetId: 567, //Prod: 567, Test: 759
  newGetProjectsFromTemplateProcessSetId: 568, //Prod; 568, Test: 755
  getDocCodesProcessSetId: 569, //Prod: 569, Test: 751
};

const actions = {
  resetCompareOptions() {
    state.compareOptions.docTypes = false;
    state.compareOptions.savedSearches = false;
    state.compareOptions.commonUDF = false;
    state.compareOptions.groups = false;
    state.compareOptions.roles = false;
    state.compareOptions.costCodes = false;
  },
  setSelectedAnalyticDataByName({ commit }, dataId) {
    console.log("dataId", dataId);
    console.log("state.allDataTypes", state.allDataTypes);

    var selectedMDT = state.allDataTypes.find(
      (x) => x.name.toLowerCase() == dataId.toLowerCase()
    );
    // console.log("selectedMDT", selectedMDT);
    commit("mutateSelectedAnalyticDataType", {
      selectedIntegrateViewId: selectedMDT.id,
    });
  },
};

const mutations = {
  mutateSelectedAnalyticDataType(state, { selectedIntegrateViewId }) {
    state.selectedIntegrateViewId = selectedIntegrateViewId;
  },
  updateSavedSearches(state, { value }) {
    state.compareOptions.savedSearches = value;
  },
  updateCompareOption(state, value) {
    state.compareOptions[value.name] = value.boolVal;
  },
  updateTemplateDetails(state, value) {
    state.templateDetails = value;
  },
  addProjectCode(state, tmpPrjCode) {
    state.projectCodeRowData.push(tmpPrjCode);
  },
  commitProjectCode(state, user) {
    let updatedRowData = state.projectCodeRowData.map((row) => ({
      ...row,
      ghost: row.id === user.id ? false : row.ghost,
    }));
    state.projectCodeRowData = updatedRowData;
  },
  setProjectCodeRowData(state, value) {
    state.projectCodeRowData = value;
  },
};

const getters = {
  templateDetails() {
    return state.templateDetails;
  },
  getProjectCodeRowData() {
    return state.projectCodeRowData;
  },
  selectedIntegrateDataType({ selectedIntegrateViewId, allDataTypes }) {
    if (selectedIntegrateViewId == null) selectedIntegrateViewId = 0;
    return state.allDataTypes.find((dt) => dt.id == selectedIntegrateViewId);
  },
  selectedProcessSetId({ selectedIntegrateViewId, allDataTypes }) {
    if (selectedIntegrateViewId == null) processSetId = 0;
    return state.allDataTypes.find((dt) => dt.id == selectedIntegrateViewId)
      .processSetId;
  },
  selectedEnvironment({ selectedIntegrateViewId }) {
    //if (selectedIntegrateViewId == null) selectedEnvironment = "PROD";
    return state.environments.find((dt) => dt.value == state.selectedEnv).name;
    //return state.allDataTypes.find((dt) => dt.id == selectedIntegrateViewId)
    //.selectedEnvironment;
  },
};

export const analyticsDataManager = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
