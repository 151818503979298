import {
  getAllExtraColumnDetails,
  CostHeaderGetByModel,
  getCostModelType,
  UpdateCostHeader,
  DeletecostHeaders,
} from "@/api/networking.js";
import { post } from "@/api/user-auth.js";
import * as moment from "moment";

let tempProjectParent = [
    {
      uid: 1,
      id: 1,
      code: "Unassigned ",
      description: "Unassigned ",
      rowType: 2,
      path: [1],
    },
  ],
  tempProgramParent = [],
  temprow = null,
  AddNewRowId = -1;

const state = {
  /* Module Title*/
  APIName: "CostHeader",
  /*left Side(parent grid details)*/
  /*CurrentData and details*/
  currentParentData: [],
  currentParentTempRow: [],
  currentParentId: null,
  currentParentSelectedObj: [],
  returnedParentDataData: [],

  /*Main Data Confing Tools*/
  currentParentLoadRecordCounter: 0,
  currentParentAddRecordCounter: 0,
  currentParentDeleteRecordCounter: 0,
  currentParentSaveRecordCounter: 0,

  /*Parent Node Grid Config*/
  currentParentUndoWatcher: 0,
  currentParentRedoWatcher: 0,
  currentParentredostate: false,
  currentParentundostate: false,

  /*Parent Node Grid Config*/
  isRelationship: false,
  currentCostModelType: [],

  /*Commonn Config*/
  isSave: 0,
  isDelete: 0,
  datawatcher: 0,
  SaveError: 0,

  /* Parent Column Def */
  currentParentColDef: [],
  currentParentExtaColumnLoadCounter: 0,
  totalRowLength: 0,
};

const actions = {
  /*LoadCostModelType */
  async LoadCostModelType({ dispatch }) {
    getCostModelType()
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentCostModelType = rowData.data;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /*Extra Column */
  async LoadParentExtraColumn({ dispatch }) {
    state.currentParentColDef = [];
    getAllExtraColumnDetails(state.APIName + "ExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          dispatch("LoadCostModelType");
          rowData.data.forEach((y) =>
            state.currentParentColDef.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 120,
              editable: function (params) {
                if (params.data.rowType == 3) return true;
                return false;
              },
              filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: "OR",
              },
              filter: "agSetColumnFilter",
              cellStyle: function (params) {
                if (params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                } else if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return moment(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.currentParentExtaColumnLoadCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, {
          root: true,
        });
      });
  },

  /*CostHeader Data */
  async LoadParentData({ dispatch, commit }) {
    CostHeaderGetByModel()
      .then((rowData) => {
        if (rowData.succeeded) {
          rowData.data.forEach(function (x) {
            if (x.extraColumnValues != null) {
              x.extraColumnValues.forEach(
                (y) => (x["et_" + y.costHeaderExtraColumnId] = y.value)
              );
            }
          });
          commit("setParentData", {
            returnedData: rowData.data,
          });
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, {
          root: true,
        });
      });
  },

  /*Delete CostHeader */
  async DeleteParentNode({ dispatch }, { costHeaderId }) {
    DeletecostHeaders(costHeaderId)
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentParentId = null;
          state.currentParentSelectedObj = [];
          state.isDelete++;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /*Save CostHeader */
  async saveData({ dispatch, commit }, { tempData }) {
    var rowsToInsert = [];
    var rowsToUpdate = [];
    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {};
    if (tempData.length > 0) {
      tempData.forEach((row) => {
        if (row.id < -1) rowsToInsert.push(row);
        else {
          rowsToUpdate.push({
            Id: row.id,
            code: row.code,
            description: row.description,
            comment: row.comment,
            isActive: row.isActive,
            status: row.status,
            ExtraColumnValues: row.extraColumnValues,
          });
        }
      });
    }

    /*  state.currentParentData.forEach(row => {
      if (row.rowUpdated) {
        if (row.id == -1) {
          rowsToInsert.push(row);
        } else {
          rowsToUpdate.push({
            Id: row.id,
            code: row.code,
            description: row.description,
            comment: row.comment,
            isActive: row.isActive,
            status: row.status,
            ExtraColumnValues: row.extraColumnValues,
          });
        }
      }
    }); */
    if (rowsToInsert.length > 0) {
      createURL = state.APIName + "/Create";
      createObj = { costHeadersToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = state.APIName + "/Update";
      updateObj = { CostHeadersToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          //console.log(values.data);
          returnedValues = values.data;
          // if (values[1]) returnedValues = returnedValues.concat(values[1]);
          commit("setSavedParentData", {
            returnedData: returnedValues,
          });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = UpdateCostHeader(updateObj, state.selectedDataTypeId);
      Promise.all([createP, updateP])
        .then((values) => {
          var returnedValues = [];
          if (values[0]) returnedValues = values[0];
          if (values[1]) returnedValues = values[1].data;
          commit("setSavedParentData", {
            returnedData: returnedValues,
          });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
  },
};

const mutations = {
  /*left Side(parent grid details)*/
  /*CurrentData and details*/

  setParentData(state, { returnedData }) {
    // returnedData.forEach(row => (row.rowUpdated = false));
    let newRows = [],
      tempid = 2;

    returnedData.forEach(function (row) {
      row.rowUpdated = false;
      if (row.projects != null) {
        row.projects.forEach((parentProjects) => {
          temprow = { ...row };
          //  tempid++;
          //temprow = row;
          temprow.uid = ++tempid;
          temprow.parentId = parentProjects.id;
          temprow.rowType = 3;
          temprow.path = [parentProjects.id, temprow.uid];
          newRows.push(temprow);
          let TempIndex = tempProjectParent.findIndex(
            (x) => x.id == parentProjects.id
          );
          if (TempIndex == -1) {
            //tempid++;
            parentProjects.uid = ++tempid;
            parentProjects.rowType = 2;
            parentProjects.parentId = null;
            parentProjects.path = [parentProjects.id];
            tempProjectParent.push(parentProjects);
          }
        });
      }
      if (row.programs != null) {
        row.programs.forEach((parentPrograms) => {
          // tempid++;
          temprow = { ...row };
          temprow.uid = ++tempid;
          temprow.rowType = 3;
          temprow.parentId = parentPrograms.id;
          temprow.path = [parentPrograms.id, temprow.uid];
          newRows.push(temprow);
          let TempIndex = tempProgramParent.findIndex(
            (x) => x.id == parentPrograms.id
          );
          if (TempIndex == -1) {
            // tempid++;
            parentPrograms.uid = ++tempid;
            parentPrograms.rowType = 1;
            parentPrograms.parentId = null;
            parentPrograms.path = [parentPrograms.id];
            tempProgramParent.push(parentPrograms);
          }
        });
      }
      if (row.projects == null && row.programs == null) {
        tempid++;
        row.uid = tempid;
        row.parentId = 1;
        row.rowType = 3;
        row.path = [1, tempid];
        temprow = row;
        newRows.push(temprow);
      }
    });
    returnedData = newRows;
    tempProjectParent.forEach((row) => {
      returnedData.push(row);
    });
    tempProgramParent.forEach((row) => {
      returnedData.push(row);
    });
    state.totalRowLength = returnedData.length;
    state.currentParentData = returnedData.sort((a, b) =>
      a.uid > b.uid ? 1 : -1
    );
    //console.log(returnedData);
    /*Reset the parent Grid property */
    state.currentParentId = null;
    state.currentParentSelectedObj = [];
    state.currentParentDeleteRecordCounter = 0;
    state.currentParentUndoWatcher = 0;
    state.currentParentRedoWatcher = 0;
    state.currentParentredostate = false;
    state.currentParentundostate = false;
    state.currentParentAddRecordCounter = 0;
    state.currentParentLoadRecordCounter++;
  },

  setSavedParentData(state, { returnedData }) {
    state.returnedParentDataData = [];
    /*  state.currentParentData = state.currentParentData.filter(
      x => x.rowUpdated == true
    ); */
    returnedData.forEach((row) => {
      row.rowUpdated = false;
      state.totalRowLength++;
      /*  });
    returnedData.forEach(function(row) { */
      //row.rowUpdated = false;
      state.totalRowLength++;
      /*  row.parentId = 1;
      row.rowType = 3;
      row.path = [1, row.id]; */
      //row.uid = 2;

      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.costHeaderExtraColumnId] = y.value)
        );
      }

      row.rowType = 3;

      row.uid = state.totalRowLength;
      // state.currentParentData.push(row);
      let tempIndex = state.currentParentData.findIndex((x) => x.id == row.id);
      if (tempIndex == -1) {
        row.parentId = 1;
        row.path = [1, state.totalRowLength];
        row.objectRelationships = [];
        row.uid = state.totalRowLength;
        row.rowType = 3;
      } else {
        row.uid = state.currentParentData[tempIndex].path[1];
        row.parentId = state.currentParentData[tempIndex].parentId;
        row.path = state.currentParentData[tempIndex].path;
        row.objectRelationships =
          state.currentParentData[tempIndex].objectRelationships;
        row.costModelType = state.currentParentData[tempIndex].costModelType;
        row.rowType = 3;
        row.costHeaderOption =
          state.currentParentData[tempIndex].costHeaderOption;
      }
      state.currentParentId = row.id;
    });
    //console.log(state.currentParentData[2]);
    state.returnedParentDataData = returnedData;
    /*Reset the parent Grid property */
    state.currentParentUndoWatcher = 0;
    state.currentParentRedoWatcher = 0;
    state.currentParentredostate = false;
    state.currentParentundostate = false;
    state.datawatcher++;
  },

  setParentObj(state, { row }) {
    state.currentParentSelectedObj = row;
    state.currentParentId = row.id;
  },

  /*mutate counter for CRUD*/
  muatateParentAdd(state) {
    /*  state.currentParentData.push({
      id: -111,
      code: "",
      description: "",
      comment: "",
      costHeaderOption: "",
      costModelType: [],
      costModelTypeId: "",
      extraColumnValues: [],
      objectRelationships: [],
      parentId: 1,
      rowType: 3,
      project: null,
      program: null,
      path: [1, -111],
      rowUpdated: true,
    }); */
    state.currentParentAddRecordCounter++;
  },
  mutateCurrentParentData(state, row) {
    state.currentParentData.push({ row });
  },

  mutateParentDelete(state) {
    state.currentParentDeleteRecordCounter++;
  },

  mutateParentSave(state) {
    state.currentParentSaveRecordCounter++;
  },

  /*Parent Grid Config*/
  mutateParentRedo(state) {
    state.currentParentRedoWatcher++;
  },

  mutateParentUndo(state) {
    state.currentParentUndoWatcher++;
  },

  mutateParentRedostate(state, value) {
    state.currentParentredostate = value;
  },

  mutateParentUndostate(state, value) {
    state.currentParentundostate = value;
  },

  mutateCostHeaderRealtionship(state, isRelationship) {
    state.isRelationship = isRelationship;
  },
};

const getters = {
  /*left Side(parent grid details)*/
  /*CurrentData and details*/
  getParentGridColumn() {
    return state.currentParentColDef;
  },

  getParentCurrentData() {
    return state.currentParentData;
  },

  getParentSelectedObj() {
    return state.currentParentSelectedObj;
  },
  getParentSelectedId() {
    return state.currentParentId;
  },
  /*Extra Config */
  getCostModelTypeData() {
    return state.currentCostModelType;
  },
  getcostHeaderStatus() {
    return state.isRelationship;
  },
  getSavedData() {
    return state.returnedParentDataData;
  },
  getAllProjects() {
    return tempProjectParent;
  },

  getAllPrograms() {
    return tempProgramParent;
  },
};

/* CostHeader Module */
export const costHeaderController = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
