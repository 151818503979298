<template>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-if="selectedUserDataType.id == 0"
  >
    <group-admin />
  </section>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedUserDataType.id == 1"
  >
    <component-access />
  </section>
  <section
    style="height: 100%"
    v-show="loggedIn"
    v-else-if="selectedUserDataType.id == 2"
  >
    <user-admin />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import LoadScript from "vue-plugin-load-script";
import GroupAdmin from "../components/UserMgmt/GroupAdmin.vue";
import ComponentAccess from "../components/UserMgmt/ComponentAccess.vue";
import UserAdmin from "../components/UserMgmt/UserAdmin.vue";
export default {
  name: "UserConfigView",
  components: {
    GroupAdmin,
    ComponentAccess,
    UserAdmin,
  },
  watch: {
    selectedUserDataType() {
      Vue.use(LoadScript);
      var scriptToUse =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8080/drag-bar.js"
          : process.env.NODE_ENV === "Staging"
          ? "https://jiveconnect-staging.azurewebsites.net/drag-bar.js"
          : "https://app.jiveconnect.com.au/drag-bar.js";

      Vue.unloadScript(scriptToUse).catch((errormsg) => {
        if (errormsg != undefined) {
          console.error(errormsg);
        }
      });
      Vue.loadScript(scriptToUse).catch((error) => {
        console.error(error);
      });
    },
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("userManagement", ["selectedUserDataType"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted: function () {
    console.log("user Management: $route.params.id", this.$route.params.id);
    if (
      !this.isValid(this.selectedUserDataType) ||
      this.selectedUserDataType.name != this.$route.params.id
    ) {
      console.log("Loading Data Type");
      this.setSelectedData(this.$route.params.id);
    }

    Vue.use(LoadScript);
    var scriptToUse =
      process.env.NODE_ENV === "development"
        ? "http://localhost:8080/drag-bar.js"
        : process.env.NODE_ENV === "Staging"
        ? "https://jiveconnect-staging.azurewebsites.net/drag-bar.js"
        : "https://app.jiveconnect.com.au/drag-bar.js";

    Vue.unloadScript(scriptToUse).catch((errormsg) => {
      if (errormsg != undefined) {
        console.error(errormsg);
      }
    });

    Vue.loadScript(scriptToUse).catch((error) => {
      console.error(error);
    });
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("userManagement", ["updateViewList", "setSelectedData"]),
    isValid(valueToCheck) {
      if (
        String(valueToCheck) != "" &&
        String(valueToCheck) != "null" &&
        String(valueToCheck) != "undefined"
      )
        return true;
      else {
        return false;
      }
    },
  },
};
</script>
