<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start">
        <button
          class="button"
          title="Save"
          @click="saveRecords"
          v-if="currentRouteName != 'login'"
        >
          <span class="icon is-small">
            <i class="fas fa-save"></i>
          </span>
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "ClientAdminToolBar",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapMutations("dataManager", ["addDataRecord"]),
    //...mapActions("clientAdmin", ["saveClient"]),
    saveRecords() {
      this.saveClient();
    },
  },
};
</script>

<style lang="scss" scoped>
div.module-toolbar {
  margin-bottom: 0.1rem !important;
}
</style>
