// simplified model handling using `value` prop and `input` event for $emit , //
add dynmic class and id (if not set) based on component tag template:`

<template>
  <div
    :id="id ? id : $options._componentTag + '-' + _uid"
    :class="$options._componentTag"
  >
    <slot></slot>
  </div>
</template>
<script>
//import 'ext-language_tools.js';
import { mapMutations } from "vuex";
export default {
  props: ["value", "id", "options"],
  model: {
    prop: "value",
    event: "valueList",
  },
  name: "processSetDataView",
  data() {
    return {
      // valueList: this.value,
    };
  },
  watch: {
    value() {
      // two way binding – emit changes to parent
      if (this.value != null) {
        this.$emit("input", this.value); // update value on external model changes

        if (this.oldValue !== this.value) {
          this.editor.setValue(this.value, 1);
        }
      } else {
        this.editor.setValue("// Code", 1);
      }
    },
    /* input() {
      // console.log(this.valueList);
      ///  this.editor.on("change", () => {
      // oldValue set to prevent internal updates
      this.value = this.editor.getValue();
      //  });
    }, */
  },
  mounted() {
    // editor
    this.editor = window.ace.edit(this.$el.id); // deprecation
    this.editor.$blockScrolling = Infinity; // ignore doctype warnings

    const session = this.editor.getSession();

    session.on("changeAnnotation", () => {
      const a = session.getAnnotations();
      const b = a.slice(0).filter((item) => item.text.indexOf("DOC") == -1);
      if (a.length > b.length) session.setAnnotations(b);
    }); // editor options //
    //github.com/ajaxorg/ace/wiki/Configuring-Ace
    this.options = this.options || {}; // opinionated option defaults
    this.options.maxLines = Infinity;
    this.options.printMargin = true;
    this.options.maxLines = 40;
    this.options.minLines = 40;
    this.options.enableSnippets = true;
    this.options.autoScrollEditorIntoView = true;
    this.options.highlightActiveLine = true;
    this.options.enableBasicAutocompletion = true;
    this.options.animatedScroll = true;
    this.options.autoScrollEditorIntoView = true;
    this.options.hScrollBarAlwaysVisible = true;
    this.options.vScrollBarAlwaysVisible = true;
    if (this.options.cursor === "none" || this.options.cursor === false) {
      this.editor.renderer.$cursorLayer.element.style.display =
        "font-size: 12pt; font-family: 'Consolas'";
      delete this.options.cursor;
    } // add missing mode and theme paths
    if (this.options.mode && this.options.mode.indexOf("ace/mode/") === -1) {
      this.options.wrap = false;
      this.options.mode = `ace/mode/javascript`;
    }

    if (this.options.theme && this.options.theme.indexOf("ace/theme/") === -1) {
      this.options.theme = `ace/theme/textmate`;
    }
    this.options.showGutter = true;
    this.editor.setOptions(this.options); // set model value // if no model value found – use slot content
    this.editor.container.style.maxHeight = "50em";
    // this.editor.container.style.maxWidth = "78ex";
    //this.editor.container.style.position="initial";
    //this.editor.setValue(this.value, -1);

    if (
      !this.value ||
      this.value === "" ||
      this.value == null ||
      this.value.length == 0
    ) {
      this.editor.setValue("// Code", 1);
      this.$emit("input", this.editor.getValue());
    } else {
      this.editor.setValue(this.value, -1);
    } // editor value changes
    this.editor.on("change", () => {
      // oldValue set to prevent internal updates
      //this.value=this.oldValue = this.editor.getValue();

      this.mutateScriptValue({
        codeValue: (this.oldValue = this.editor.getValue()),
      });
    });
    //this.value = this.oldValue =
  },
  methods: {
    ...mapMutations("processSetController", ["mutateScriptValue"]),
    // eslint-disable-next-line vue/no-dupe-keys
    editor() {
      return this.editor;
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped></style>
