import { userService } from "@/api/user-auth";

// @typedef views: [{id: Number(>=0), order: Number(??0), title: String}]
const state = {
  views: [],
  selectedViewId: -1,
  uploadDataWatcher: 0,
  uploadColumnStateWatcher: 0,
  reloadColumnStateWatcher: 0,
  selectedRowType: -1,
  dataLoadCounter: 0,
  userViewsLoaded: false,
};

const actions = {
  updateViewList({ commit }) {
    // TODO: consider not always loading views if already the same? pass in `state` if required as first param destruct
    // async api call views
    // success -> commit/mutate values
    userService.getJson("view/GetAll").then((result) => {
      if (result.succeeded && result.data != null) {
        result.data.sort(function (view1, view2) {
          return view1.userViewOrder - view2.userViewOrder;
        });
        let views = result.data;
        commit("mutateViews", {
          views: views,
        });
        this.userViewsLoaded = true;
      }
    });
  },
  updateViewListAsync({ commit }) {
    return new Promise((resolve, reject) => {
      userService.getJson("view/GetAll").then(
        (result) => {
          result.data.sort(function (view1, view2) {
            return view1.userViewOrder - view2.userViewOrder;
          });
          let views = result.data;
          commit("mutateViews", {
            views: views,
          });
          this.userViewsLoaded = true;
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  resetColumnsState({ commit, dispatch }, { viewId }) {
    userService.post(`userview/resetcolumnstate/${viewId}/`).then(
      (result) => {
        if (result.succeeded) {
          commit("requestColumnStateReload");
        } else {
          dispatch("alert/error", result.message, { root: true });
        }
      },
      (error) => {
        commit("requestColumnStateReload");
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  setSelectedViewId({ commit }, viewId) {
    commit("mutateSelectedView", {
      selectedViewId: viewId,
    });
  },
};

const mutations = {
  mutateViews(state, { views }) {
    //views = views.filter(x => x.viewTypeId != 7);
    state.views = [...views];
    if (state.selectedViewId < 0 && state.views.length > 0) {
      state.selectedViewId = state.views[0].id;
    }
    //state.dataLoadCounter++;
  },
  mutateSelectedView(state, { selectedViewId }) {
    state.selectedViewId = selectedViewId;
  },
  requestDataUpload(state) {
    state.uploadDataWatcher += 1;
  },
  requestColumnStateUpload(state) {
    state.uploadColumnStateWatcher += 1;
  },
  requestColumnStateReload(state) {
    state.reloadColumnStateWatcher += 1;
  },
  setSelectedRowType(state, currSelectedRowType) {
    state.selectedRowType = currSelectedRowType;
  },
};

const getters = {
  selectedView({ selectedViewId, views }) {
    return views.find((v) => v.id == selectedViewId);
  },
  selectedViewTitle(state, { selectedView }) {
    return selectedView?.name ?? "Unselected View";
  },
  userViewsLoaded(state) {
    return state.userViewsLoaded;
  },
  allUserViews(state) {
    return state.views;
  },
  getDashboardViews(state) {
    return state.views.filter((x) => x.componentId > 0);
  },
};

export const userView = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
