<template>
  <div
    class="sidemenu"
    :class="{ 'is-expanded': sidemenuIsExpanded }"
    v-if="loggedIn"
  >
    <ul @click="sidemenuIsExpanded = !sidemenuIsExpanded">
      <li>
        <i class="fas fa-bars"></i>
        <h1></h1>
      </li>
    </ul>

    <ul
      :class="{ 'is-active': showFilterModal }"
      @click.capture.prevent="toggleStructureFilterModal"
      aria-expanded="false"
    >
      <li>
        <i class="fas fa-filter"></i>
        <h1>Filters</h1>
      </li>
      <ul>
        <li><h1>Filters</h1></li>
      </ul>
    </ul>
    <ul v-if="loggedIn" @click.capture="expandSubMenu" aria-expanded="false">
      <li>
        <i class="fas fa-hat-wizard"></i>
        <h1>Wizards</h1>
        <span class="expandable">
          <i class="fas fa-angle-down"></i><i class="fas fa-angle-up"></i>
        </span>
      </li>
      <ul>
        <li><h1>Wizards</h1></li>

        <li
          class="long-view-text-size"
          v-for="currPS in wizardProcessSets"
          :key="currPS.id"
          @click.capture.prevent="callExecuteProcessSet(currPS.id)"
        >
          {{ currPS.name }}
        </li>
      </ul>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
$sidemenu-orientation: right;
$sidemenu-color-bg: #d4d6da;
$sidemenu-color-text: #111;
$sidemenu-color-hover: #99a4b9;
@import "./collapsable-sidebar.scss";
.active {
  color: #a6a6a6 !important;
}
</style>

<script>
import { expandSubMenu } from "./collapsable-sidebar.js";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "CollapsableActionBar",
  data() {
    return {
      initialDataLoaded: false,
      sidemenuIsExpanded: false,
      wizardProcessSets: [],
      //isRowSelected: false,
    };
  },
  computed: {
    ...mapState({
      showFilterModal: (state) =>
        state.filterControl.isStructureFilterModalVisible,
      views: (state) => state.userView.views,
    }),
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("processSetDataManager", [
      "allWizardProcessSets",
      { processSetsLoaded: "entitiesLoaded" },
      { allProcessSets: "allEntities" },
    ]),
    ...mapGetters("rowTypeDataManager", [
      "getIdByName",
      { rowTypesLoaded: "entitiesLoaded" },
      { rowTypes: "allEntities" },
    ]),
    ...mapGetters("dataManager", ["selectedDataType", "getSelectedRowObject"]),
    currentRouteName() {
      return this.$route.name;
    },
    isRowSelected() {
      return this.isValid(this.getSelectedRowObject);
    },
  },
  watch: {
    processSetsLoaded() {
      //This flag is set once the web call is resolved and the data is loaded
      if (this.processSetsLoaded)
        this.wizardProcessSets = this.allWizardProcessSets;
    },
    $route(to, from) {
      if (this.loggedIn) {
        if (!this.initialDataLoaded) {
          this.loadAllData();
          this.loadRowTypes();
          this.setWizardProcessSets();
        }

        switch (to.name.toLowerCase()) {
          case "data":
            this.wizardProcessSets = this.allWizardProcessSets.filter(
              (x) =>
                x.rowTypeId ==
                this.getIdByName(this.selectedDataType.API.toLowerCase())
            );
            console.log(this.wizardProcessSets);
            break;
          default:
            this.wizardProcessSets = [];
            break;
        }
      }
    },
    selectedDataType() {
      this.setWizardProcessSets();
    },
  },
  methods: {
    expandSubMenu: expandSubMenu,
    ...mapActions("account", ["login", "logout"]),
    ...mapActions("processSetDataManager", [
      "loadAllData",
      "executeProcessSet",
    ]),
    ...mapActions("rowTypeDataManager", { loadRowTypes: "loadAllData" }),
    ...mapMutations("filterControl", ["toggleStructureFilterModal"]),
    setWizardProcessSets() {
      this.wizardProcessSets = this.allWizardProcessSets.filter(
        (x) =>
          x.rowTypeId ==
          this.getIdByName(this.selectedDataType.API.toLowerCase())
      );
    },
    isValid(valueToCheck) {
      if (
        String(valueToCheck) != "" &&
        String(valueToCheck) != "null" &&
        String(valueToCheck) != "undefined"
      )
        return true;
      else {
        return false;
      }
    },
    callExecuteProcessSet(psId) {
      if (this.isValid(this.getSelectedRowObject)) {
        var processSetContext = {};
        processSetContext.id = psId;
        processSetContext.ExecuteAsync = true;
        processSetContext.incomingData = [];
        processSetContext.incomingData.push({
          inputDataName: "selectedEntity",
          inputData: this.getSelectedRowObject,
        });
        // console.log("Executing Wizard");
        // console.log(this.getSelectedRowObject);
        // console.log(processSetContext);
        this.executeProcessSet(processSetContext);
      }
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.loadAllData();
      this.loadRowTypes();
      this.setWizardProcessSets();
      this.initialDataLoaded = true;
    }
  },
};
</script>
