import { userService } from "@/api/user-auth";
import * as moment from "moment";
let data = [],
  i = -1,
  tempData = null;
function getParentLoop(id, path) {
  let p = data.filter((i) => i.id == id)[0];
  if (p.parentId) {
    path.unshift(p.id);
    return getParentLoop(p.parentId, path);
  } else {
    path.unshift(p.id);
  }
  return path;
}

function setpath(data) {
  data.map((i) => {
    i.path = [];
    if (i.parentId) {
      i.path.push(i.id);
      getParentLoop(i.parentId, i.path);
    } else {
      i.path.push(i.id);
    }
    return i;
  });
  return data;
}

const state = {
  isMenuExpanded: false,
  isCommitmentCostElementModalVisible: false,
  CommitmentCostElementdetailcode: null,
  CommitmentCostElementdetailId: null,
  currentCommitmentCostElementdetailId: 0,
  CommitmentSaveCounter: 0,
  filtertitle: null,
  loadCommitmentCostElementCounter: 0,
  CommitmentCostElementLoadcounter: 0,
  CommitmentCostElementcodeValue: null,
  currentCommitmentCostElementData: null,
  currentBudgetCostElementData: null,
  currentEstimateCostElementData: null,
  CommitmentloadCounter: 0,
};

const actions = {
  loadCommitmentCostElementbyCostHeadersID(
    { dispatch, commit },
    { costHeaderId, CostModelTypeId, APIName }
  ) {
    userService
      .getJson(
        `${APIName}/GetByActualCostElement/${costHeaderId}/${CostModelTypeId}`
      )
      .then((result) => {
        if (result.succeeded && result.data != null) {
          state.currentCommitmentCostElementData = result.data;
          state.loadCommitmentCostElementCounter++;
          /*  let indexs = state.currenttempCommitmentCostElementData.filter(
            x => x.parentId == null
          );

          indexs.forEach(element => {
            dispatch("LoadSchedualHeader", element.scheduleHeaderId);
          }); */
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
  loadBudgetCostElementbyCostHeadersID(
    { dispatch, commit },
    { costHeaderId, CostModelTypeId, APIName }
  ) {
    userService
      .getJson(
        `${APIName}/GetByActualCostElement/${costHeaderId}/${CostModelTypeId}`
      )
      .then((result) => {
        if (result.succeeded && result.data != null) {
          state.currentBudgetCostElementData = result.data;
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  loadEstimateCostElementbyCostHeadersID(
    { dispatch, commit },
    { costHeaderId, CostModelTypeId, APIName }
  ) {
    userService
      .getJson(
        `${APIName}/GetByActualCostElement/${costHeaderId}/${CostModelTypeId}`
      )
      .then((result) => {
        if (result.succeeded && result.data != null) {
          state.currentEstimateCostElementData = result.data;
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
};
const mutations = {
  toggleCommitmentCostElementFilterModal(state) {
    state.isCommitmentCostElementModalVisible =
      !state.isCommitmentCostElementModalVisible;
  },
  mutateCommitmentCostElementTitle(state, title) {
    state.filtertitle = title;
  },

  mutateCommitmentCostElementCodeValue(state, { code: code, Id: Id }) {
    state.CommitmentCostElementcodeValue = code;
    state.CommitmentCostElementdetailId = Id;
    state.CommitmentSaveCounter++;
  },
  setCommitmentCostElementId(state, { code: Id }) {
    if (Id != 0) {
      state.currentCommitmentCostElementdetailId = Id;
    } else {
      state.currentCommitmentCostElementdetailId = 0;
    }
  },

  mutateCommitmentCostElementLoadCounter(state) {
    state.CommitmentCostElementLoadcounter++;
  },

  setCommitmentCostElementData(state, { returnedData }) {
    if (returnedData != null) {
      let indexs = state.currentCommitmentCostElementData.filter(
        (x) => x.parentId == null
      );
      var tempCostheader = [];

      indexs.forEach((element) => {
        tempCostheader = returnedData.filter(
          (x) => x.id == element.costHeaderId
        );
        var tempparent = state.currentCommitmentCostElementData.find(
          (x) => x.id == element.id
        );
        if (tempparent.parentId == null) {
          tempparent.parentId = tempCostheader[0].id;
          tempCostheader[0].parentId = null;
          state.currentCommitmentCostElementData.unshift(tempCostheader[0]);
        }
      });

      data = Array.from(new Set(state.currentCommitmentCostElementData));
      tempData = setpath(data);
      state.currentCommitmentCostElementData = tempData;
      state.CommitmentloadCounter++;
    }
  },
};
const getters = {
  getTitle() {
    return state.filtertitle + " Module";
  },

  getCommitmentCostElementDetailcode() {
    return state.CommitmentCostElementcodeValue;
  },

  getCommitmentCostElementDetailId() {
    return state.CommitmentCostElementdetailId;
  },
  getCurrentCommitmentCostElement() {
    return state.currentCommitmentCostElementData;
  },

  getDetailsColumns() {
    return [
      {
        headerName: "Code",
        field: "code",
        sortable: true,
        resizable: true,
        width: 90,
        filterParams: { excelMode: "windows" },
        editable: false,
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Description",
        field: "description",
        sortable: true,
        resizable: true,
        width: 300,
        editable: false,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
      },
    ];
  },

  getCurrentBudgetCostElement() {
    return state.currentBudgetCostElementData;
  },
  getCurrentEstimateCostElement() {
    return state.currentEstimateCostElementData;
  },
};

export const commitmentCostElementControl = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
