<template>
  <nav
    class="navbar"
    role="navigation"
    aria-label="main navigation"
    style="border-bottom: 2px solid; margin-bottom: 5px"
  >
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-end" style="margin-right: 18px">
        <!--  Save Button -->
        <!--   <button
          class="button"
          title="Save"
          @click="mutateParentSave"
          v-if="currentRouteName != 'login'"
          :disabled="getParentSelectedId == null"
        >
          <span class="icon is-small">
            <i class="fas fa-save"></i>
          </span>
        </button> -->
        <!--  RelationShip Button -->
        <!--   :class="{ 'is-active': showFilterModal }" -->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add Relationship"
          aria-expanded="false"
          :class="{ 'is-active': showFilterModal }"
          @click.capture.prevent="AddRelationship"
          :disabled="getParentSelectedId == 0"
        >
          <span class="icon is-small">
            <i class="fas fa-network-wired"></i>
          </span>
        </button>

        <!--  Rest Column State Button -->
        <button
          class="button"
          title="Reset Columns"
          v-if="currentRouteName == 'login'"
        >
          <span class="icon is-small">
            <i class="fas fa-text-width"></i>
          </span>
        </button>
        <!--  Undo Button -->
        <button
          class="button"
          title="Undo"
          v-if="currentRouteName != 'login'"
          @click="mutateParentUndo"
          :disabled="isUndo == false"
        >
          <span class="icon is-small">
            <i class="fas fa-undo"></i>
          </span>
        </button>
        <!--  Redo Button -->
        <button
          class="button"
          title="Redo"
          v-if="currentRouteName != 'login'"
          @click="mutateParentRedo"
          :disabled="isRedo == false"
        >
          <span class="icon is-small">
            <i class="fas fa-redo"></i>
          </span>
        </button>
        <!--  Add New Record Button -->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add New"
          @click="mutateParentAddRecord"
        >
          <span class="icon is-small">
            <i class="fas fa-plus"></i>
          </span>
        </button>
        <!--  Delete Button -->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Delete"
          @click="mutateParentDelete"
          :disabled="getParentSelectedId == null"
        >
          <!--  @click="saveRecords" @click="deleteRecord"> -->
          <span class="icon is-small">
            <i class="fas fa-trash-alt"></i>
          </span>
        </button>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
p {
  width: 165px;
  display: inline-block;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}
nav.navbar {
  border-top: 0px solid black;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
</style>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "PerformanceToolbar",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("performanceSheetManager", ["getParentSelectedId"]),
    ...mapState({
      showFilterModal: (state) =>
        state.filterControl.isStructureFilterModalVisible,
    }),
    /*Parent Controller State */
    ...mapState({
      isRedo: (state) => state.performanceSheetManager.currentParentredostate,
      isUndo: (state) => state.performanceSheetManager.currentParentundostate,
    }),

    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    /* Parent Controller Mutations */
    ...mapMutations("performanceSheetManager", [
      "mutateParentUndo",
      "mutateParentRedo",
      "mutateParentDelete",
      "mutateParentAddRecord",
      "mutateParentSave",
      "mutatePerformanceHeaderRealtionship",
    ]),

    /* RelationShip Mutations */
    ...mapMutations("relationControl", ["toggleRelationFilterModal"]),

    /* RelationShip Mutations */
    ...mapActions("relationControl", ["GetPerformanceHeaderRelations"]),

    AddRelationship() {
      this.mutatePerformanceHeaderRealtionship(true);
      this.GetPerformanceHeaderRelations({
        performanceHeaderID: this.getParentSelectedId,
      });
      this.toggleRelationFilterModal();
    },
  },
};
</script>
