<template>
  <span
    ><button class="viewGantt" v-if="params.data" @click="btnClickedHandler()">
      <i v-bind:class="params.iconClass"></i> {{ params.message }}
    </button></span
  >
</template>

<script>
export default {
  methods: {
    btnClickedHandler() {
      this.params.clicked(this.params.value);
    },
  },
};
</script>

<style scoped>
.viewGantt {
  background-color: white;
  border-color: #dbdbdb;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  /* padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px); */
  text-align: center;
  white-space: nowrap;
}
.viewGantt i {
  padding: 13px 13px;
}
</style>
