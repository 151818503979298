<template>
  <span
    ><select class="viewGantt" @click="valueClickedHandler()">
      <option disabled value="">Please select one</option>
    </select></span
  >
</template>

<script>
export default {
  methods: {
    valueClickedHandler() {
      //this.params.clicked(this.params.value);
      this.params.value;
    },
  },
  beforeMount() {
    console.log(this.params);
  },
};
</script>

<style scoped>
.viewGantt {
  background-color: transparent;
  border-color: #dbdbdb;
  border-width: 0px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  width: 100%;
  white-space: nowrap;
}
.viewGantt i {
  padding: 13px 13px;
}
</style>
