<template>
  <div style="height: 100%; background-color: white">
    <div class="line"></div>
    <div class="columns" style="height: 100%; width: 100%">
      <div
        class="container-grid"
        style="width: 100%; height: 100%l overflow: auto;"
      >
        <div
          class="box"
          style="height: 100%"
          ref="scriptContainerSpendingCurve"
        >
          <SpendingCurveLeft />
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="multiple"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="false"
            :columnDefs="CurvecolumnDefs"
            :rowData="rowCurveData"
            :enableRangeSelection="true"
            :undoRedoCellEditing="true"
            :gridOptions="gridCurveOptions"
            :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
            @cell-value-changed="onCellValueChangedCurve"
            @selection-changed="onSelectionChanged"
            @grid-ready="onGridReady"
            @sort-changed="onColumnChanged"
            @column-resized="onColumnChanged"
            @column-visible="onColumnChanged"
            @column-row-group-changed="onColumnChanged"
            @column-value-changed="onColumnChanged"
            @column-moved="onColumnChanged"
            @column-pinned="onColumnChanged"
          >
          </ag-grid-vue>
        </div>
        <div class="handler"></div>
        <div class="box" ref="scriptContainerSpendingCurveDetails">
          <SpendingCurveRight />
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="multiple"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            :columnDefs="DetailcolumnDefs"
            :rowData="rowDetailData"
            :gridOptions="gridDetailOptions"
            :treeData="false"
            :animateRows="true"
            :defaultColDef="defaultColDef"
            :autoGroupColumnDef="autoGroupColumnDef"
            :overlayLoadingTemplate="loadingTemplate"
            :overlayNoRowsTemplate="noRowsTemplate"
            :enableRangeSelection="true"
            :undoRedoCellEditing="true"
            :components="components"
            :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
            :detailCellRendererParams="detailCellRendererParams"
            @cell-value-changed="onCellValueChanged"
            @cellClicked="cellClicked"
            @grid-ready="onDetailsGridReady"
            @sort-changed="onDetailsColumnChanged"
            @column-resized="onDetailsColumnChanged"
            @column-visible="onDetailsColumnChanged"
            @column-row-group-changed="onDetailsColumnChanged"
            @column-value-changed="onDetailsColumnChanged"
            @column-moved="onDetailsColumnChanged"
            @column-pinned="onDetailsColumnChanged"
          >
          </ag-grid-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import SpendingCurveRight from "./SpendingCurveToolbar.vue";
import SpendingCurveLeft from "./SpendingCurveLeftToolbar.vue";
import LevelIconCellRenderer from "@/components/Data/LevelIconCellRenderer.vue";
import * as utilitiesModule from "@/assets/utilities.js";
import { debounce } from "lodash";
import {
  getColumnDef,
  postColumnState,
  getColumnState,
} from "@/api/networking.js";
export default {
  name: "SpendingCurveManager",
  components: {
    AgGridVue,
    SpendingCurveRight,
    SpendingCurveLeft,
    LevelIconCellRenderer, // eslint-disable-line
  },
  data() {
    return {
      rowCurveData: null,
      CurvecolumnDefs: null,
      components: null,
      undoRedoCellEditingLimit: null,
      i: -1,
      gridCurveOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
        processDataFromClipboard: (params) => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          const lastIndex =
            this.gridCurveOptions.api.getModel().rowsToDisplay.length - 1;
          const focusedCell = this.gridCurveOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;
          if (focusedIndex + params.data.length - 1 > lastIndex) {
            const resultLastIndex = focusedIndex + (params.data.length - 1);
            const addRowCount = resultLastIndex - lastIndex;
            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }
            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];
            rowsToAdd.map((r) => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map((i) => {
                row[currColumn.colDef.field] = i;
                currColumn =
                  this.gridCurveOptions.columnApi.getDisplayedColAfter(
                    currColumn
                  );
              });
              row.extraColumnValues = [];
              row.rowUpdated = true;
              (row.id = -1), newRowData.push(row);
              this.rowCurveData.push(row);
            });

            this.gridCurveOptions.api.applyTransaction({
              add: newRowData,
            });
            this.gridCurveOptions.api.redrawRows();
          }
          this.gridCurveOptions.api.refreshCells();
          return params.data;
        },
      },
      detailCellRendererParams: {
        refreshStrategy: "everything",
      },
      rowDetailData: null,
      DetailcolumnDefs: null,
      clickcell: false,
      defaultColDef: null,
      singleClickEdit: false,
      gridDetailOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
        processDataFromClipboard: (params) => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          const lastIndex =
            this.gridDetailOptions.api.getModel().rowsToDisplay.length - 1;
          const focusedCell = this.gridDetailOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;
          if (focusedIndex + params.data.length - 1 > lastIndex) {
            const resultLastIndex = focusedIndex + (params.data.length - 1);
            const addRowCount = resultLastIndex - lastIndex;
            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }
            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];
            rowsToAdd.map((r) => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map((i) => {
                row[currColumn.colDef.field] = i;
                currColumn =
                  this.gridDetailOptions.columnApi.getDisplayedColAfter(
                    currColumn
                  );
              });
              row.extraColumnValues = [];
              row.rowUpdated = true;
              row.spendingCurveHeaderId = this.getCurveIdvalue;
              row.id = -1;
              this.currentDetailData.push(row);
              newRowData.push(row);
            });

            this.gridDetailOptions.api.applyTransaction({
              add: newRowData,
            });
            this.gridDetailOptions.api.redrawRows();
          }
          this.gridDetailOptions.api.refreshCells();
          return params.data;
        },
      },
      isCurvedetail: false,
      getDataPath: null,
      autoGroupColumnDef: null,
      groupDefaultExpanded: null,
      SelectId: null,
      // rowDetailData: [],
      loadingTemplate: `<span class="ag-overlay-loading-center">Data is loading...</span>`,

      deleteSettings: {
        withBackdrop: true,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "center-center",
      },
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
    };
  },
  watch: {
    LoadCurveCounter() {
      if (this.LoadCurveCounter > 0) {
        this.rowCurveData = this.getCurveuctureData;
        this.gridCurveOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        this.reloadColumnState();
      }
    },

    SaveError() {
      this.$vToastify.error("Code Value is empty or duplicate!");
    },

    curveAddConter() {
      if (this.curveAddConter > 0) {
        this.rowCurveData = this.getCurveuctureData;
        this.gridCurveOptions.api.redrawRows();
        this.gridCurveOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        let lastRowIndex = this.gridCurveOptions.api.getLastDisplayedRow();

        /*  this.gridCurveOptions.api.selectIndex(lastRowIndex, false, false);
        this.gridCurveOptions.api.setFocusedCell(lastRowIndex, "code"); */

        this.gridCurveOptions.api.forEachNode((node) => {
          if (node.data.id < 0) {
            node.setSelected(true);
            lastRowIndex = node.rowIndex;
          }
        });
        this.gridCurveOptions.api.ensureIndexVisible(lastRowIndex);
        this.gridCurveOptions.api.setFocusedCell(lastRowIndex, "code");
      }
    },
    isDelete() {
      if (this.isDelete > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.rowDetailData = this.currentDetailData;
        this.gridDetailOptions.api.redrawRows();
        this.gridDetailOptions.api.refreshCells();
        this.$vToastify.success("Row Deleted Successfully!");
      }
    },

    dataWatcher() {
      // this.LoadSpendingCurve();
      this.gridCurveOptions.api.forEachNode((node) => {
        node.data.rowUpdated = false;
      });
      this.gridCurveOptions.api.redrawRows();
      this.gridCurveOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.rowDetailData = this.rowDetailData.filter(
        (x) => x.rowUpdated == false
      );
      let tempDetailData = [];
      this.gridDetailOptions.api.forEachNode((node) => {
        if (node.data.rowUpdated) {
          node.data.rowUpdated = false;
          tempDetailData.push(node.data);
        }
      });

      this.gridDetailOptions.api.applyTransaction({
        remove: tempDetailData,
      });

      this.gridDetailOptions.api.applyTransaction({
        add: this.getSavedDetailData,
      });
      this.gridDetailOptions.api.redrawRows();
      this.gridDetailOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.gridDetailOptions.api.clearFocusedCell();
      /*   let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
      this.gridDetailOptions.api.selectIndex(
        selectedNodes[0].rowIndex,
        false,
        false
      );
      this.gridDetailOptions.api.setFocusedCell(
        selectedNodes[0].rowIndex,
        "code"
      );
      this.setDeatilId({ selectedId: selectedNodes[0].key }); */

      this.$vToastify.success("Data successfully saved!");
    },

    undoWatcher() {
      this.undo();
    },

    redoWatcher() {
      this.redo();
    },

    CurveundoWatcher() {
      this.Curveundo();
    },

    CurveredoWatcher() {
      this.Curveredo();
    },

    AddRecordCounter() {
      if (this.AddRecordCounter > 0) {
        if (this.getDetailIdvalue == null) {
          let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
          if (selectedNodes.length > 0) {
            this.setDeatilId({ selectedId: selectedNodes[0].key });
          }
        }
        this.gridDetailOptions.api.refreshCells({ force: true });
        let lastRowIndex = this.gridDetailOptions.api.getLastDisplayedRow();
        this.gridDetailOptions.api.selectIndex(lastRowIndex, false, false);
        this.gridDetailOptions.api.setFocusedCell(lastRowIndex, "code");
      }
    },

    LoadCounter() {
      if (this.SelectId != null) {
        this.rowDetailData = this.getDetailData;
        //     this.rowDetailData = Array.from(this.rowDetailData);
        this.resetRequestUndo(false);
        this.resetRequestRedo(false);
        this.gridDetailOptions.api.refreshCells();
        this.isCurvedetail = true;
        this.reloadDetailsColumnState();
      }
    },

    SaveCounter() {
      if (this.SaveCounter > 0) {
        let newRecord = this.rowDetailData.findIndex(
          (x) => x.rowUpdated == true
        );
        let IdIndex = this.getCurveuctureData.findIndex(
          (x) => x.rowUpdated == true
        );
        if (IdIndex > -1 || newRecord > -1) {
          this.saveCurveData();
        }
      }
    },

    CurveDeleteCounter() {
      if (this.CurveDeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              if (this.rowDetailData.length == 0) {
                let selectedNodes =
                  this.gridCurveOptions.api.getSelectedNodes();
                let selectedData = selectedNodes.map((node) => node.data);
                let deleteIds = [];
                let indices = [];
                selectedData.forEach((x) => {
                  deleteIds.push(x.id);
                });
                indices = deleteIds.filter((x) => x > 0);
                if (indices.length > 0) {
                  this.DeleteCurve({ curveheaderID: indices });
                }
                this.gridCurveOptions.api.applyTransaction({
                  remove: selectedData,
                });
                indices = [];
                selectedNodes = [];
                deleteIds = [];
                selectedData = [];
              } else {
                this.$vToastify.setSettings(this.NormalSettings);
                this.$vToastify.error("Curves are not allowed to delete.");
              }
            }
          });
      }
    },

    DeleteCounter() {
      if (this.DeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();

              if (selectedNodes.length < 2) {
                var selectedData = this.gridDetailOptions.api.getSelectedRows();

                let TotalNodes = this.rowDetailData.filter(
                  (x) => x.id == selectedData[0].id
                );

                if (selectedNodes[0].data.id > 0) {
                  this.DeleteDetailCurve({ Id: [selectedNodes[0].data.id] });
                }

                this.gridDetailOptions.api.applyTransaction({
                  remove: TotalNodes,
                });
                TotalNodes = [];
              } else {
                let selectedData = selectedNodes.map((node) => node.data);

                if (selectedData.length > 0) {
                  let DataDelete = [],
                    deleteIds = [];
                  for (let i = 0; i < selectedData.length; i++) {
                    DataDelete.push(
                      this.rowDetailData.filter((x) =>
                        x.path.includes(selectedData[i].data.id)
                      )
                    );
                  }
                  DataDelete.forEach((element) => {
                    element.forEach((data) => {
                      selectedData.push(data);
                    });
                  });

                  DataDelete = _.uniqBy(selectedData, "id");
                  let Deletevalues = DataDelete.map((x) => x.id);
                  Deletevalues.forEach((element) => {
                    deleteIds.push(
                      this.rowDetailData.findIndex((x) => x.id == element)
                    );
                  });

                  deleteIds.forEach((x) => {
                    delete this.rowDetailData[x];
                  });
                  let indices = Deletevalues.filter((x) => x > 0);
                  if (indices.length > 0 || selectedData[0].data.id > 0) {
                    this.DeleteDetailCurve({ Id: [selectedData[0].data.id] });
                  }
                  this.gridDetailOptions.api.applyTransaction({
                    remove: DataDelete,
                  });
                  deleteIds = null;
                  indices = null;
                  Deletevalues = null;
                  DataDelete = null;
                  selectedData = null;
                }
              }
            } else {
              this.$vToastify.setSettings(this.NormalSettings);
            }
          });
      }
    },
    currentParentColumDefCounter() {
      this.getParentColDefs.forEach((row) => {
        this.CurvecolumnDefs.push(row);
      });
      this.reloadColumnState();
    },
    currentChildColumDefCounter() {
      this.getChildColDefs.forEach((row) => {
        this.DetailcolumnDefs.push(row);
      });
      this.reloadDetailsColumnState();
    },
  },
  computed: {
    ...mapGetters("spendingcurveManager", [
      "getCurveColumns",
      "getDetailsColumns",
      "getCurveData",
      "getDetailData",
      "getCurveIdvalue",
      "getRedoWatcher",
      "getundoWatcher",
      "getCurveRedoWatcher",
      "getCurveUndoWatcher",
      "getDetailIdvalue",
      "getCurveuctureData",
      "getParentColDefs",
      "getChildColDefs",
      "getSavedDetailData",
    ]),

    ...mapState("spendingcurveManager", [
      "dataWatcher",
      "redoWatcher",
      "undoWatcher",
      "SaveError",
      "CurveundoWatcher",
      "CurveredoWatcher",
      "AddRecordCounter",
      "SaveCounter",
      "LoadCounter",
      "tempSaveData",
      "currentDetailData",
      "DeleteCounter",
      "isDelete",
      "isCurvectureDelete",
      "CurveDeleteCounter",
      "LoadCurveCounter",
      "curveAddConter",
      "tempCurveData",
      "currentParentColumDefCounter",
      "currentChildColumDefCounter",
    ]),

    noRowsTemplate() {
      if (this.isCurvedetail) {
        return this.loadingTemplate;
      } else {
        return `<span>No Rows to Show</span>`;
      }
    },
  },
  methods: {
    ...mapMutations("spendingcurveManager", [
      "setSelectedRowObject",
      "requestUndo",
      "resetRequestUndo",
      "resetRequestRedo",
      "resetCurveRequestRedo",
      "resetCurveRequestUndo",
      "setcurrentCurveID",
      "setCurveValue",
      "mutateAddcounter",
      "mutateRecord",
      "setDeatilId",
      "setSelectedRowObject",
    ]),

    ...mapActions("spendingcurveManager", [
      "LoadSpendingCurve",
      "saveCurveData",
      "LoadDetails",
      "DeleteCurve",
      "DeleteDetailCurve",
      "LoadSpendingCurveHeaderExtraColumn",
      "LoadSpendingCurveDetailExtraColumn",
    ]),
    onCellValueChangedCurve(params) {
      params.node.data.rowUpdated = true;
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();

      if (params.column.colId.match("et_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;

        tempIndex = params.node.data.extraColumnValues.findIndex(
          (y) => y.SpendingCurveHeaderExtraColumnId == parseInt(colvalue[1])
        );
        params.node.data.rowUpdated = true;
        if (tempIndex < 0) {
          params.node.data.extraColumnValues.push({
            SpendingCurveHeaderId: params.node.data.id,
            SpendingCurveHeaderExtraColumnId: colvalue[1],
            value: params.newValue,
          });
        } else {
          params.node.data.extraColumnValues[tempIndex].value = params.newValue;
        }
      }

      if (undoSize == 0) {
        this.resetCurveRequestUndo(false);
        params.node.data.rowUpdated = false;
      } else {
        this.resetCurveRequestUndo(true);
      }
      if (redoSize == 0) {
        this.resetCurveRequestRedo(false);
      } else {
        this.resetCurveRequestRedo(true);
        params.node.data.rowUpdated = false;
      }
    },

    onSelectionChanged(event) {
      this.setSelectedRowObject(0);
      var selectedRowObjs = event.api.getSelectedNodes();
      if (selectedRowObjs.length > 0) {
        this.SelectId = selectedRowObjs[0].data.id;
        this.isCurvedetail = false;
        if (this.SelectId == -1) {
          this.rowDetailData = [];
          this.setCurveValue(this.SelectId);
          //this.setDetailData({ returnedData: null });
          this.isCurvedetail = false;
        } else {
          // this.rowDetailData = [];
          this.rowDetailData = [];
          this.setCurveValue(this.SelectId);
          this.LoadDetails(this.SelectId);
        }
      } else {
        //   this.rowDetailData = [];
        this.rowDetailData = [];
      }
      this.$vToastify.setSettings(this.NormalSettings);
    },

    onGridReady() {
      getColumnDef(14)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerSpendingCurve;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);

          //this.gridStructureOptions.api.setColumnDefs(container);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          this.LoadSpendingCurveHeaderExtraColumn().then(() => {
            this.LoadSpendingCurve();
          });
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    reloadColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 14)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridCurveOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnChanged: debounce(function () {
      this.onColumnStateSubmit();
    }, 300),

    onColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridCurveOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 14,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },

    onDetailsGridReady() {
      // this.loadStructureRows();
      getColumnDef(24)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerSpendingCurveDetails;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .then(() => {
          this.LoadSpendingCurveDetailExtraColumn();
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    onDetailsColumnChanged: debounce(function () {
      this.onColumnDetailsStateSubmit();
    }, 300),

    reloadDetailsColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 24)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridDetailOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnDetailsStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridDetailOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 24,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },

    undo() {
      this.gridDetailOptions.api.undoCellEditing();
    },

    redo() {
      this.gridDetailOptions.api.redoCellEditing();
    },

    Curveundo() {
      this.gridCurveOptions.api.undoCellEditing();
    },

    Curveredo() {
      this.gridCurveOptions.api.redoCellEditing();
    },

    cellClicked(params) {
      params.api.refreshCells();
      this.setSelectedRowObject(params.node.data);
      let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
      this.setDeatilId({ selectedId: selectedNodes[0].data.id });
      this.gridDetailOptions.api.refreshCells();
    },

    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();

      params.node.data.rowUpdated = true;
      if (params.column.colId.match("et_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;

        tempIndex = params.node.data.extraColumnValues.findIndex(
          (y) => y.SpendingCurveDetailExtraColumnId == parseInt(colvalue[1])
        );
        params.node.data.rowUpdated = true;
        if (tempIndex < 0) {
          params.node.data.extraColumnValues.push({
            SpendingCurveDetailId: params.node.data.id,
            SpendingCurveDetailExtraColumnId: colvalue[1],
            value: params.newValue,
          });
        } else {
          params.node.data.extraColumnValues[tempIndex].value = params.newValue;
        }
      }
      if (undoSize == 0) {
        this.resetRequestUndo(false);
        params.node.data.rowUpdated = false;
      } else {
        this.resetRequestUndo(true);
      }

      if (redoSize == 0) {
        this.resetRequestRedo(false);
      } else {
        this.resetRequestRedo(true);
        params.node.data.rowUpdated = false;
      }
      this.gridDetailOptions.api.refreshCells();
    },
  },

  beforeMount() {
    this.setcurrentCurveID();
    this.setSelectedRowObject(0);
    this.resetRequestUndo(false);
    this.resetRequestRedo(false);
    // this.LoadSpendingCurve();
    this.DetailcolumnDefs = [];
    this.rowDetailData = 0;
    this.CurvecolumnDefs = [];
  },

  mounted() {
    this.undoRedoCellEditingLimit = 15;
    // this.DetailcolumnDefs = this.getDetailsColumns;
    this.rowDetailData = 0;
    //   this.CurvecolumnDefs = this.getCurveColumns;
  },
};
</script>

<style scoped lang="scss">
.container-grid {
  margin-top: 0.1%;
  background-color: #fff;
  color: #444;
  /* Use flexbox */
  display: flex;
}

.line {
  width: 99.5%;
  height: 1%;
  border-bottom: 1px solid black;
  position: relative;
}
.box {
  margin-top: 0.1%;
  color: #fff;
  border-radius: 1px;
  padding: 15px;
  font-size: 150%;

  /* Use box-sizing so that element's outerwidth will match width property */
  box-sizing: border-box;

  /* Allow box to grow and shrink, and ensure they are all equally sized */
  flex: 1 1 auto;
}

.handler {
  margin-top: 0.8%;
  position: relative;
  width: 1px;
  padding: 0;
  cursor: col-resize;
  flex: 0 0 auto;
}

.handler::before {
  margin-top: 0.8%;
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background: silver;
  margin: 0 auto;
}
</style>
