<template>
  <div style="height: 100%; background-color: white" ref="scriptContainerP3M">
    <!-- <div ref="scriptContainer" /> -->
    <DataToolBar />
    <div class="columns" style="height: 100%; width: 100%">
      <div class="container-grid" style="width: 100%; height: 100%">
        <div class="box">
          <!--   <div class="column half" style="height: 100%; overflow: auto"> -->
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="multiple"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            :gridOptions="gridOptions"
            :rowData="rowData"
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :components="components"
            :enableRangeSelection="true"
            :undoRedoCellEditing="true"
            :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
            @cell-value-changed="onCellValueChanged"
            @selection-changed="onSelectionChanged"
            @grid-ready="onGridReady"
            @sort-changed="onColumnChanged"
            @column-resized="onColumnChanged"
            @column-visible="onColumnChanged"
            @column-row-group-changed="onColumnChanged"
            @column-value-changed="onColumnChanged"
            @column-moved="onColumnChanged"
            @column-pinned="onColumnChanged"
          >
          </ag-grid-vue>
        </div>
        <div class="handler"></div>
        <div class="box" style="width: 1%; overflow: auto; margin-left: 0.7em">
          <data-operation />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import DataToolBar from "@/components/Data/DataToolbar.vue";
import DataOperation from "@/components/Data/DataOperation.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { getAllMasterData } from "@/api/networking.js";
import * as moment from "moment";
import * as utilitiesModule from "@/assets/utilities.js";
import { debounce } from "lodash";
import {
  getColumnDef,
  postColumnState,
  getColumnState,
} from "@/api/networking.js";
/* @processDataFromClipboard="processDataFromClipboard"*/

export default {
  name: "DataManager",
  props: {
    DataOperationselectedDataType: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      rowData: null,
      columnDefs: null,
      gridOptions: {
        // columnDefs: [],
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
          ],
        },
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
        processDataFromClipboard: (params) => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          const lastIndex =
            this.gridOptions.api.getModel().rowsToDisplay.length - 1;
          const focusedCell = this.gridOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;

          if (focusedIndex + params.data.length - 1 > lastIndex) {
            const resultLastIndex = focusedIndex + (params.data.length - 1);
            const addRowCount = resultLastIndex - lastIndex;
            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }
            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];
            rowsToAdd.map((r) => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map((i) => {
                row[currColumn.colDef.field] = i;
                currColumn =
                  this.gridOptions.columnApi.getDisplayedColAfter(currColumn);
              });
              row.rowUpdated = true;
              (row.id = -1), newRowData.push(row);
              this.rowData.push(row);
            });
            this.gridOptions.api.updateRowData({ add: newRowData });
          }
          this.gridOptions.api.refreshCells();
          return params.data;
        },
      },
      CodeIndex: [],
      tmpCode: false,
      defaultColDef: null,
      components: {
        datePicker: getDatePicker(),
        integerCellEditor: getIntegerCellEditor(),
        decimalCellEditor: getDecimalCellEditor(),
        lookupCellEditor: getLookupCellEditor(),
      },
      selectedRowIndex: 0,
      undoRedoCellEditingLimit: null,
      deleteSettings: {
        withBackdrop: true,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "center-center",
      },
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
    };
  },
  components: {
    AgGridVue,
    DataToolBar,
    DataOperation,
  },
  watch: {
    selectedDataType() {
      //this.columnDefs = [];
      // this.LoadP3MExtraColumn(this.selectedDataType.id);

      //this.loadDataRows();
      this.setSelectedRowObject(0);
      this.undoRedoCellEditingLimit = 15;
      this.resetRequestUndo(false);
      this.resetRequestUndo(false);
      this.onGridReady(this.gridOptions);
    },

    isDelete() {
      if (this.isDelete > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.gridOptions.api.redrawRows();
        this.gridOptions.api.refreshCells();
        this.$vToastify.success("Row Deleted Successfully!");
      }
    },
    SaveCounter() {
      if (this.SaveCounter > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.gridOptions.api.refreshCells();
        this.$vToastify.success("Data successfully saved!");
      }
    },

    extracolumnCounter() {
      this.getExtraCurrentColumns.forEach((row) => {
        this.columnDefs.push(row);
      });
      this.reloadColumnState();
    },

    undoWatcher() {
      this.undo();
    },

    redoWatcher() {
      this.redo();
    },

    rowData() {
      this.gridOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },
    isDuplicate() {
      if (this.isDuplicate > 1) {
        let IndexValue = this.CodeIndex.findIndex(
          (x) =>
            x.code.toLowerCase() == this.getSelectedRowObject.code.toLowerCase()
        );
        if (IndexValue > -1) {
          if (this.getSelectedRowObject.rowUpdated) {
            this.$vToastify.setSettings(this.NormalSettings);
            this.$vToastify.error("Duplicate code value detected!");
            this.getSelectedRowObject.rowUpdated = "Duplicate";
          }
        } else {
          this.getSelectedRowObject.rowUpdated = true;
        }
      }
    },
    editCount() {
      this.gridOptions.api.redrawRows();
      this.gridOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },
    getSelectedRowObject() {
      this.gridOptions.api.redrawRows();
      this.gridOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },
    dataWatcher() {
      if (this.dataWatcher > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.loadDataRows();
        this.$vToastify.success("Data successfully saved!");
      }
    },

    AddCounter() {
      setTimeout(() => {
        this.SetFocus();
      }, 1);
    },

    DeleteCounter() {
      if (this.DeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes = this.gridOptions.api.getSelectedNodes();
              let selectedData = selectedNodes.map((node) => node.data);
              let deleteIds = [];
              let indices = [];
              selectedData.forEach((x) => {
                deleteIds.push(x.id);
              });
              indices = deleteIds.filter((x) => x > 0);
              if (indices.length > 0) {
                if (this.selectedDataType.id == 0) {
                  this.deleteMetadata({ portfolioId: indices });
                } else if (this.selectedDataType.id == 1) {
                  this.deleteMetadata({ programId: indices });
                } else if (this.selectedDataType.id == 2) {
                  this.deleteMetadata({ projectId: indices });
                }
              }
              this.gridOptions.api.applyTransaction({
                remove: selectedData,
              });
              indices = [];
              selectedNodes = [];
              deleteIds = [];
              selectedData = [];
            } else {
              this.$vToastify.setSettings(this.NormalSettings);
            }
          });
      }
    },

    isSaveCounter() {
      if (this.isSaveCounter > 0) {
        let indexes = [],
          emptyRowCounter = 0;
        this.gridOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            if (node.data.code != null) {
              this.rowData.filter((element) => {
                if (element.code === node.data.code) {
                  if (node.data.id != element.id) {
                    node.data.rowUpdated = "Duplicate";
                    indexes.push(node.rowIndex);
                  }
                }
              });
            } else {
              emptyRowCounter++;
              return false;
            }
          }
        });
        if (indexes.length > 0 || emptyRowCounter > 0) {
          this.gridOptions.api.redrawRows();
          this.gridOptions.api.refreshCells();
          indexes = [];
          emptyRowCounter = 0;
          this.$vToastify.error("Code Value is empty or duplicate!");
        } else {
          this.saveData();
        }
      }
    },
  },
  computed: {
    ...mapGetters("dataManager", [
      "selectedDataType",
      "getCurrentData",
      "getSavedData",
      // "getCurrentColumns",
      "getSelectedRowObject",
      "getRedoWatcher",
      "getundoWatcher",
      "getExtraCurrentColumns",
    ]),
    ...mapState("dataManager", [
      "dataWatcher",
      "editCount",
      "redoWatcher",
      "undoWatcher",
      "AddCounter",
      "DeleteCounter",
      "isDelete",
      "isDuplicate",
      "isSaveCounter",
      "extracolumnCounter",
      "columnDefCounter",
    ]),
    ...mapState("relationControl", ["SaveCounter"]),
    newData: {
      get() {
        return this.getCurrentData;
      },
      set(newData) {
        return newData;
      },
    },
  },
  methods: {
    ...mapMutations("dataManager", [
      "setCurrentData",
      "setSavedData",
      "setSelectedRowObject",
      "mutateEditCount",
      "requestUndo",
      "resetRequestUndo",
      "resetRequestRedo",
      "mutateExtaColumn",
    ]),
    ...mapActions("dataManager", [
      "deleteMetadata",
      "saveData",
      "LoadP3MExtraColumn",
      "LoadDataMangerColDef",
    ]),
    loadRows() {
      this.loading = true;
      this.set = new Set();
      getDataRow(this.dataType.id, this.filters)
        .then((rowData) => {
          this.rowData = rowData;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
      this.reloadColumnState();
    },
    loadDataRows() {
      getAllMasterData(this.filters, this.selectedDataType.id)
        .then((returnedData) => {
          this.setCurrentData({ returnedData: returnedData.data });
          this.rowData = this.rowData.map(({ x, y }) => [x, y]);
          this.rowData = this.getCurrentData; //returnedData.data;
          setTimeout(() => {
            if (Object.keys(this.getSelectedRowObject).length > 0) {
              this.gridOptions.api.forEachNode((node) => {
                if (this.getSelectedRowObject.id == node.data.id) {
                  node.setSelected(true);
                }
              });
            }
          }, 100);
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
      //
    },
    gridrefresh: function () {
      this.gridOptions.api.refreshCells();
    },
    onGridReady(params) {
      /*  this.gridApi = event.api;
      this.gridColumnApi = event.columnApi; */
      //  this.columnDefs = [];
      getColumnDef(this.selectedDataType.componentId)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerP3M;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          this.setSelectedRowObject(0);
          this.LoadP3MExtraColumn(this.selectedDataType.id).then(() => {
            this.loadDataRows();
          });
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    reloadColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, this.selectedDataType.componentId)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });

            // console.log(this.gridOptions.api.getColumnDefs());
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onCellValueChanged(params) {
      if (params.column.colId == "code") {
        var CodeIndex = this.rowData.filter((x) => x.id != params.node.data.id);
        CodeIndex = CodeIndex.findIndex(
          (x) => x.code.toLowerCase() == params.newValue.toLowerCase()
        );
        if (CodeIndex > -1) {
          this.$vToastify.setSettings(this.NormalSettings);
          this.$vToastify.error("Duplicate code value detected!");
          params.node.data.code = params.newValue;
          params.node.data.rowUpdated = "Duplicate";
        } else {
          params.node.data.rowUpdated = true;
        }
      } else if (params.column.colId.match("et_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;
        if (this.selectedDataType.id == 0) {
          tempIndex = params.node.data.extraColumnValues.findIndex(
            (y) => y.portfolioExtraColumnId == parseInt(colvalue[1])
          );
          params.node.data.rowUpdated = true;
          if (tempIndex < 0) {
            params.node.data.extraColumnValues.push({
              portfolioIdId: params.node.data.id,
              portfolioIdExtraColumnId: colvalue[1],
              value: params.newValue,
            });
          } else {
            params.node.data.extraColumnValues[tempIndex].value =
              params.newValue;
          }
        } else if (this.selectedDataType.id == 1) {
          tempIndex = params.node.data.extraColumnValues.findIndex(
            (y) => y.ProgramExtraColumnId == parseInt(colvalue[1])
          );
          params.node.data.rowUpdated = true;
          if (tempIndex < 0) {
            params.node.data.extraColumnValues.push({
              ProgramId: params.node.data.id,
              ProgramExtraColumnId: colvalue[1],
              value: params.newValue,
            });
          } else {
            params.node.data.extraColumnValues[tempIndex].value =
              params.newValue;
          }
        } else if (this.selectedDataType.id == 2) {
          tempIndex = params.node.data.extraColumnValues.findIndex(
            (y) => y.ProjectExtraColumnId == parseInt(colvalue[1])
          );
          params.node.data.rowUpdated = true;
          if (tempIndex < 0) {
            params.node.data.extraColumnValues.push({
              ProjectId: params.node.data.id,
              ProjectExtraColumnId: colvalue[1],
              value: params.newValue,
            });
          } else {
            params.node.data.extraColumnValues[tempIndex].value =
              params.newValue;
          }
        }
      } else {
        params.node.data.rowUpdated = true;
      }
      //params.node.data.rowUpdated = true;
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();
      if (undoSize == 0) {
        this.resetRequestUndo(false);
        params.node.data.rowUpdated = false;
      } else {
        this.resetRequestUndo(true);
      }

      if (redoSize == 0) {
        this.resetRequestRedo(false);
      } else {
        this.resetRequestRedo(true);
        params.node.data.rowUpdated = false;
      }
      params.api.refreshCells();
    },

    SetFocus() {
      let tempRowIndex = 0;
      this.gridOptions.api.clearFocusedCell();
      tempRowIndex = this.gridOptions.api.getLastDisplayedRow();
      this.gridOptions.api.selectIndex(tempRowIndex, false, false);
      this.gridOptions.api.setFocusedCell(tempRowIndex, "code");
      this.gridOptions.api.refreshCells();
    },
    undo() {
      this.gridOptions.api.undoCellEditing();
    },

    redo() {
      this.gridOptions.api.redoCellEditing();
    },
    onColumnChanged: debounce(function () {
      this.onColumnStateSubmit();
    }, 300),

    onColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridOptions.columnApi.getColumnState();

      postColumnState({
        componentId: this.selectedDataType.componentId,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },

    onSelectionChanged(event) {
      var selectedRowObjs = event.api.getSelectedNodes();
      if (selectedRowObjs.length > 0) {
        this.setSelectedRowObject(selectedRowObjs[0].data);
        this.CodeIndex = this.rowData.filter(
          (x) => x.id != selectedRowObjs[0].data.id
        );
      }
      this.gridOptions.api.refreshCells();
    },
  },
  beforeMount() {
    this.columnDefs = [];
    this.rowData = [];
  },
  mounted() {},
};

window.getDatePicker = function getDatePicker() {
  // Initialize all input of type date

  function Datepicker() {}
  var tempDatevalue = null;
  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "date");
    if (params.value != null) {
      this.eInput.value = moment(params.value).toISOString().substr(0, 10);
    }
    tempDatevalue = params.value;
    this.eInput.classList.add("input");
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
    /* $(this.eInput).Datepicker({
      constrainInput: true, // prevent letters in the input field
      autoSize: true, // automatically resize the input field
      dateFormat: "dd/mm/yyyy",
      changeMonth: true,
      changeYear: true,
      yearRange: "1930:" + new Date().getFullYear(),
      clearButton: true,
    }); */
  };

  Datepicker.prototype.getGui = function () {
    return this.eInput;
  };
  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Datepicker.prototype.getValue = function () {
    if (this.eInput.value.length > 0) {
      return moment(this.eInput.value).add(1, "days").format("YYYY-MM-DD");
    } else {
      return tempDatevalue;
    }
  };
  Datepicker.prototype.destroy = function () {};
  Datepicker.prototype.isPopup = function () {
    return false;
  };
  return Datepicker;
};

window.getIntegerCellEditor = function getIntegerCellEditor() {
  function IntegerPicker() {}
  IntegerPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.setAttribute("step", "1");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };

  /*  IntegerPicker.prototype.isKeyPressedNavigation = function(event) {
    return event.keyCode !=110;
  }; */
  IntegerPicker.prototype.getGui = function () {
    return this.eInput;
  };
  IntegerPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  IntegerPicker.prototype.getValue = function () {
    return parseInt(this.eInput.value);
  };
  IntegerPicker.prototype.destroy = function () {};
  IntegerPicker.prototype.isPopup = function () {
    return false;
  };
  return IntegerPicker;
};

window.getDecimalCellEditor = function getDecimalCellEditor() {
  function DecimalPicker() {}
  DecimalPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.classList.add("input");
    //this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };
  DecimalPicker.prototype.getGui = function () {
    return this.eInput;
  };
  DecimalPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  DecimalPicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  DecimalPicker.prototype.destroy = function () {};
  DecimalPicker.prototype.isPopup = function () {
    return false;
  };
  return DecimalPicker;
};

window.getLookupCellEditor = function getLookupCellEditor() {
  function Lookuppicker() {}
  Lookuppicker.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var CostModelTypeFilter = CostModelTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );
    CostModelTypeFilter.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  Lookuppicker.prototype.getGui = function () {
    return this.eInput;
  };
  Lookuppicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Lookuppicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Lookuppicker.prototype.destroy = function () {};
  Lookuppicker.prototype.isPopup = function () {
    return false;
  };
  return Lookuppicker;
};
</script>

<style scoped lang="scss">
.container-grid {
  margin-top: 0.1%;
  background-color: #fff;
  color: #444;
  /* Use flexbox */
  display: flex;
}

.line {
  width: 98%;
  height: 1%;
  border-bottom: 1px solid black;
  position: relative;
}
.box {
  margin-top: 0.1%;
  color: #fff;
  border-radius: 1px;
  padding: 15px;
  font-size: 150%;

  /* Use box-sizing so that element's outerwidth will match width property */
  box-sizing: border-box;

  /* Allow box to grow and shrink, and ensure they are all equally sized */
  flex: 1 1 auto;
}

.handler {
  margin-top: 0.8%;
  position: relative;
  width: 1px;
  padding: 0;
  cursor: col-resize;
  flex: 0 0 auto;
}

.handler::before {
  margin-top: 0.8%;
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background: silver;
  margin: 0 auto;
}
</style>
