/**
 * Custom task model
 *
 * Taken from the vanilla example
 */
import { TaskModel } from "@bryntum/gantt";
import JiveConnectBaseline from "./jiveConnectBaseline.js";
// here you can extend our default Task class with your additional fields, methods and logic
export default class JiveConnectTask extends TaskModel {
  static get fields() {
    return [
      { name: "code", type: "string" },
      { name: "description", type: "string" },
      { name: "externalSystemId", type: "string" },
      { name: "plannedStartDate", type: "date" },
      { name: "plannedEndDate", type: "date" },
      { name: "plannedDuration", type: "number" },
      { name: "plannedDurationUnit", type: "string" },
      { name: "scheduleHeaderId", type: "string" },
      {
        name: "effortUnit",
        dataSource: "EffortUnit",
        convert(value) {
          return value || "hour";
        },
      },
      //{ name: "status", type: "string" },
      // { name: "actualEndDate", type: "date" },
      // { name: "actualDuration", type: "number" },
      // { name: "actualDurationUnit", type: "integer" },
      // { name: "workEffort", type: "number" },
      // { name: "workEffortUnit", type: "integer" },
      // { name: "slack", type: "number" },
      // { name: "slackUnit", type: "integer" },
      // { name: "calendarId", type: "integer" },
      // { name: "constraintType", type: "number" },
      // { name: "constraintDate", type: "date" },
      // { name: "critical", type: "bool" },
      // { name: "earlyStartDate", type: "date" },
      // { name: "earlyEndDate", type: "date" },
      // { name: "earlyDuration", type: "number" },
      // { name: "earlyDurationUnit", type: "integer" },
      // { name: "lateStartDate", type: "date" },
      // { name: "lateEndDate", type: "date" },
      // { name: "lateDuration", type: "number" },
      // { name: "lateDurationUnit", type: "integer" },
      // { name: "schedulingMode", type: "integer" },
    ];
  }

  get status() {
    let status = "Not started";

    if (this.isCompleted) {
      status = "Completed";
    } else if (this.isLate) {
      status = "Late";
    } else if (this.isStarted) {
      status = "Started";
    }

    return status;
  }
}
