<template>
  <section
    style="height: 100%; width: 100%; overflow: auto"
    v-if="
      selectedDashboardType.id == 0 &&
      selectedDashboardType.clientId == clientId
    "
    v-show="loggedIn"
  >
    <dashboard-portfolio />
  </section>
  <section
    style="height: 100%; width: 100%; overflow: auto"
    v-else-if="
      selectedDashboardType.id == 1 &&
      selectedDashboardType.clientId == clientId
    "
    v-show="loggedIn"
  >
    <dashboard />
  </section>
  <section
    style="height: 100%; width: 100%; overflow: auto"
    v-else-if="
      selectedDashboardType.id == 2 &&
      selectedDashboardType.clientId != clientId
    "
    v-show="loggedIn"
  >
    <default-portfolio-dashboard />
  </section>
  <section
    style="height: 100%; width: 100%; overflow: auto"
    v-else-if="
      selectedDashboardType.id == 3 &&
      selectedDashboardType.clientId != clientId
    "
    v-show="loggedIn"
  >
    <default-project-dashboard />
  </section>

  <section
    style="height: 100%; width: 100%; overflow: auto"
    v-else-if="retval == undefined && clientId == 3"
    v-show="loggedIn"
  >
    <dashboard-portfolio />
  </section>

  <section
    style="height: 100%; width: 100%; overflow: auto"
    v-else-if="retval == undefined && clientId != 3"
    v-show="loggedIn"
  >
    <default-project-dashboard />
  </section>
</template>

<script>
import dashboard from "./Dashboard.vue";
import DashboardPortfolio from "./DashboardPortfolio.vue";
import DefaultPortfolioDashboard from "@/components/DefaultDashboards/DefaultPortfolioDashboard.vue";
import DefaultProjectDashboard from "@/components/DefaultDashboards/DefaultProjectDashboard.vue";
import { mapGetters, mapState, mapMutations } from "vuex";

export default {
  name: "DashBoardsMenu",
  components: {
    dashboard,
    DashboardPortfolio,
    DefaultProjectDashboard,
    DefaultPortfolioDashboard,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("account", ["loggedIn", "clientId"]),
    ...mapGetters("dashboardMenu", ["selectedDashboardType"]),
    ...mapState("dashboardMenu", ["retval"]),
  },
  mounted: function () {},
  beforeMount: function () {
    //console.log(this.retval, this.clientId);
    if (this.retval == undefined && this.clientId == 3) {
      if (!this.selectedDashboardType)
        this.mutateSelectedDashboardType({ selectedDashboardTypeId: 1 });
      // this.selectedDashboardValues();
    } else if (this.clientId != 3) {
      //With the dynamic menu logic, we don't want to set the dashboardTypeId if it already has been set.
      if (!this.selectedDashboardType)
        this.mutateSelectedDashboardType({ selectedDashboardTypeId: 3 });
      // this.selectedDashboardValues();
    }
  },

  methods: {
    ...mapMutations("dashboardMenu", ["mutateSelectedDashboardType"]),
  },
};
</script>
<style lang="scss" scoped>
.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 5;
  cursor: pointer;
}
</style>
