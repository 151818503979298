import { userService } from "@/api/user-auth";
let tempProjectParent = [
    {
      id: 1,
      code: "Unassigned ",
      description: "Unassigned ",
      rowType: 2,
      path: [1],
    },
  ],
  temprow = null;
const state = {
  isMenuExpanded: false,
  isRelationFilterModalVisible: false,
  portfolios: [],
  costHeader: null,
  scheduleHeader: null,
  performanceHeader: null,
  riskHeader: null,
  programs: [],
  projects: [],
  chkPortfolios: {},
  chkPrograms: {},
  chkProjects: {},
  selectedPortfolios: [],
  selectedPrograms: [],
  selectedProjects: [],
  relationWatcher: 0,
  SaveCounter: 0,
  saveCostHeaderCounter: 0,
  savePerformanceHeaderCounter: 0,
  saveRiskHeaderCounter: 0,
  savedCostHeader: [],
  savedRiskHeader: [],
  savedPerformanceHeader: [],
  saveScheduleCounter: 0,
  savedScheduleHeader: [],
};

const actions = {
  GetScheduleHeaderRelations({ commit }, { scheduleHeaderID }) {
    userService
      .getJson(`/ScheduleHeader/GetP2MSchedule/${scheduleHeaderID}`)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          let newRelation = result.data;
          newRelation.programs.sort((a, b) => (a.code > b.code ? 1 : -1));
          newRelation.projects.sort((a, b) => (a.code > b.code ? 1 : -1));
          commit("mutateScheduleHeaderRelations", {
            relation: newRelation,
            relationID: scheduleHeaderID,
          });
        }
      });
  },
  GetCostHeaderRelations({ commit }, { costHeaderID }) {
    userService
      .getJson(`/CostHeader/GetP2MCostHeader/${costHeaderID}`)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          let newRelation = result.data;
          newRelation.programs.sort((a, b) => (a.code > b.code ? 1 : -1));
          newRelation.projects.sort((a, b) => (a.code > b.code ? 1 : -1));
          commit("mutateCostHeaderRelations", {
            relation: newRelation,
            relationID: costHeaderID,
          });
        }
      });
  },

  GetRiskHeaderRelations({ commit }, { riskHeaderID }) {
    userService
      .getJson(`/RiskHeader/GetP2MRiskHeader/${riskHeaderID}`)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          let newRelation = result.data;
          newRelation.portfolios.sort((a, b) => (a.code > b.code ? 1 : -1));
          newRelation.programs.sort((a, b) => (a.code > b.code ? 1 : -1));
          newRelation.projects.sort((a, b) => (a.code > b.code ? 1 : -1));
          commit("mutateRiskHeaderRelations", {
            relation: newRelation,
            relationID: riskHeaderID,
          });
        }
      });
  },

  GetPerformanceHeaderRelations({ commit }, { performanceHeaderID }) {
    userService
      .getJson(
        `/PerformanceHeader/GetP3MPerformanceHeader/${performanceHeaderID}`
      )
      .then((result) => {
        if (result.succeeded && result.data != null) {
          let newRelation = result.data;
          newRelation.portfolios.sort((a, b) => (a.code > b.code ? 1 : -1));
          newRelation.programs.sort((a, b) => (a.code > b.code ? 1 : -1));
          newRelation.projects.sort((a, b) => (a.code > b.code ? 1 : -1));
          commit("mutatePerformanceHeaderRelations", {
            relation: newRelation,
            relationID: performanceHeaderID,
          });
        }
      });
  },
  GetRelations({ commit }, { relationID, DataID }) {
    userService.getJson("/Portfolio/GetP3MStructure").then((result) => {
      if (result.succeeded && result.data != null) {
        let newRelation = result.data;
        newRelation.portfolios.sort((a, b) => (a.code > b.code ? 1 : -1));
        newRelation.programs.sort((a, b) => (a.code > b.code ? 1 : -1));
        newRelation.projects.sort((a, b) => (a.code > b.code ? 1 : -1));
        commit("mutateRelation", {
          relation: newRelation,
          relationID: relationID,
          DataID: DataID,
        });
      }
    });
  },
  saveRelations({ dispatch, commit, getters }, { relation, typeName }) {
    commit("saveRelations", { relation: relation });
    // NOTE: use of `getter.Relation`, this is used to prevent un-necessary clauses
    {
      //Convert relation Obejct in UpdateObject
      var tempPorject = getters.relation.Projects;
      var tempPortfolio = getters.relation.Portfolios;
      var tempProgram = getters.relation.Programs;
      var UpdateObj = null,
        objectRelationshipsArray = [];

      if (typeName == "Programs") {
        for (let i = 0; i < tempPorject.length; i++) {
          objectRelationshipsArray.push({
            portfolioId: null,
            programId: tempProgram[0],
            projectId: tempPorject[i],
          });
        }
        for (let i = 0; i < tempPortfolio.length - 1; i++) {
          objectRelationshipsArray.push({
            portfolioId: tempPortfolio[i],
            programId: tempProgram[0],
            projectId: null,
          });
        }
        objectRelationshipsArray = objectRelationshipsArray.filter(
          (x) => x.projectId != "null"
        );
        UpdateObj = {
          programs: [
            {
              id: tempProgram[0],
              objectRelationships: objectRelationshipsArray,
            },
          ],
        };
      } else {
        for (let i = 0; i < tempPorject.length; i++) {
          objectRelationshipsArray.push({
            portfolioId: tempPortfolio[0],
            programId: null,
            projectId: tempPorject[i],
          });
        }
        for (let i = 0; i < tempProgram.length - 1; i++) {
          objectRelationshipsArray.push({
            portfolioId: tempPortfolio[0],
            programId: tempProgram[i],
            projectId: null,
          });
        }
        objectRelationshipsArray = objectRelationshipsArray.filter(
          (x) => x.projectId != "null"
        );
        UpdateObj = {
          portfolios: [
            {
              id: tempPortfolio[0],
              objectRelationships: objectRelationshipsArray,
            },
          ],
        };
      }

      userService
        .post(`/Portfolio/UpdateP3MStructure/`, UpdateObj)
        .then((result) => {
          if (result.succeeded) {
            commit("requestFilterUpdate");
            state.SaveCounter++;
          } else {
            dispatch("alert/error", result.message, { root: true });
          }
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
      //userService.post(`/Relation/${clientId}/`, getters.Relation);
      // TODO: potentially change this once `selectAll` is implemented
    }
  },

  saveCostRelations({ dispatch, commit, getters }, { relation }) {
    commit("saveRelations", { relation: relation });
    // NOTE: use of `getter.Relation`, this is used to prevent un-necessary clauses
    {
      //Convert relation Obejct in UpdateObject
      var tempCostHeader = getters.relation.CostHeader;
      var tempPorject = getters.relation.Projects;
      var tempProgram = getters.relation.Programs;
      var UpdateObj = null,
        objectRelationshipsArray = [];
      //  if (tempCostHeader.objectRelationships.length > 0) {
      for (let i = 0; i < tempProgram.length; i++) {
        objectRelationshipsArray.push({
          costHeaderId: tempCostHeader.id,
          programId: tempProgram[i],
          projectId: null,
        });
      }
      for (let i = 0; i < tempPorject.length; i++) {
        objectRelationshipsArray.push({
          costHeaderId: tempCostHeader.id,
          programId: null,
          projectId: tempPorject[i],
        });
      }

      tempCostHeader.objectRelationships = objectRelationshipsArray;

      UpdateObj = {
        CostHeader: tempCostHeader,
      };
      userService
        .post(`/CostHeader/UpdateP2MCostHeader/`, UpdateObj)
        .then((result) => {
          if (result.succeeded) {
            commit("setCostHeaderData", { returnedData: result.data });
            commit("requestFilterUpdate");

            state.SaveCounter++;
          } else {
            dispatch("alert/error", result.message, { root: true });
          }
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
      //userService.post(`/Relation/${clientId}/`, getters.Relation);
      // TODO: potentially change this once `selectAll` is implemented
    }
  },
  saveScheduleRelations({ dispatch, commit, getters }, { relation }) {
    commit("saveRelations", { relation: relation });
    // NOTE: use of `getter.Relation`, this is used to prevent un-necessary clauses
    {
      //Convert relation Obejct in UpdateObject
      var tempScheduleHeader = getters.relation.ScheduleHeader;
      var tempPorject = getters.relation.Projects;
      var tempProgram = getters.relation.Programs;
      var UpdateObj = null,
        objectRelationshipsArray = [];
      if (tempScheduleHeader.id > 0) {
        for (let i = 0; i < tempProgram.length; i++) {
          objectRelationshipsArray.push({
            scheduleHeaderId: tempScheduleHeader.id,
            programId: tempProgram[i],
            projectId: null,
          });
        }
        for (let i = 0; i < tempPorject.length; i++) {
          objectRelationshipsArray.push({
            scheduleHeaderId: tempScheduleHeader.id,
            programId: null,
            projectId: tempPorject[i],
          });
        }

        tempScheduleHeader.objectRelationships = objectRelationshipsArray;
      }

      UpdateObj = {
        SchedduleHeader: tempScheduleHeader,
      };
      userService
        .post(`/ScheduleHeader/UpdateP2MScheduleHeader/`, UpdateObj)
        .then((result) => {
          if (result.succeeded) {
            commit("setScheduleHeaderData", { returnedData: result.data });
            commit("requestFilterUpdate");
            state.SaveCounter++;
          } else {
            dispatch("alert/error", result.message, { root: true });
          }
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
      //userService.post(`/Relation/${clientId}/`, getters.Relation);
      // TODO: potentially change this once `selectAll` is implemented
    }
  },

  saveRiskHeaderRelations({ dispatch, commit, getters }, { relation }) {
    commit("saveRelations", { relation: relation });
    // NOTE: use of `getter.Relation`, this is used to prevent un-necessary clauses
    {
      //Convert relation Obejct in UpdateObject
      var tempRiskHeader = getters.relation.RiskHeader;
      var tempPortfolio = getters.relation.Portfolios;
      var tempPorject = getters.relation.Projects;
      var tempProgram = getters.relation.Programs;
      var UpdateObj = null,
        objectRelationshipsArray = [];

      for (let i = 0; i < tempPortfolio.length; i++) {
        objectRelationshipsArray.push({
          riskHeaderId: tempRiskHeader.id,
          portfolioId: tempPortfolio[i],
          projectId: null,
          programId: null,
        });
      }
      //  if (tempRiskHeader.objectRelationships.length > 0) {
      for (let i = 0; i < tempProgram.length; i++) {
        objectRelationshipsArray.push({
          riskHeaderId: tempRiskHeader.id,
          programId: tempProgram[i],
          projectId: null,
          portfolioId: null,
        });
      }
      for (let i = 0; i < tempPorject.length; i++) {
        objectRelationshipsArray.push({
          riskHeaderId: tempRiskHeader.id,
          programId: null,
          portfolioId: null,
          projectId: tempPorject[i],
        });
      }

      tempRiskHeader.objectRelationships = objectRelationshipsArray;

      UpdateObj = {
        riskHeader: tempRiskHeader,
      };
      userService
        .post(`/RiskHeader/UpdateP2MRiskHeader/`, UpdateObj)
        .then((result) => {
          if (result.succeeded) {
            commit("setRiskHeaderData", { returnedData: result.data });
            commit("requestFilterUpdate");

            state.SaveCounter++;
          } else {
            dispatch("alert/error", result.message, { root: true });
          }
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
      //userService.post(`/Relation/${clientId}/`, getters.Relation);
      // TODO: potentially change this once `selectAll` is implemented
    }
  },

  savePerformanceHeaderRelations({ dispatch, commit, getters }, { relation }) {
    commit("saveRelations", { relation: relation });
    // NOTE: use of `getter.Relation`, this is used to prevent un-necessary clauses
    {
      //Convert relation Obejct in UpdateObject
      var tempPerformanceHeader = getters.relation.PerformanceHeader;
      var tempPortfolio = getters.relation.Portfolios;
      var tempPorject = getters.relation.Projects;
      var tempProgram = getters.relation.Programs;
      var UpdateObj = null,
        objectRelationshipsArray = [];

      for (let i = 0; i < tempPortfolio.length; i++) {
        objectRelationshipsArray.push({
          performanceHeaderId: tempPerformanceHeader.id,
          portfolioId: tempPortfolio[i],
          projectId: null,
          programId: null,
        });
      }
      //  if (tempPerformanceHeader.objectRelationships.length > 0) {
      for (let i = 0; i < tempProgram.length; i++) {
        objectRelationshipsArray.push({
          performanceHeaderId: tempPerformanceHeader.id,
          programId: tempProgram[i],
          projectId: null,
          portfolioId: null,
        });
      }
      for (let i = 0; i < tempPorject.length; i++) {
        objectRelationshipsArray.push({
          performanceHeaderId: tempPerformanceHeader.id,
          programId: null,
          portfolioId: null,
          projectId: tempPorject[i],
        });
      }

      tempPerformanceHeader.objectRelationships = objectRelationshipsArray;

      UpdateObj = {
        performanceHeader: tempPerformanceHeader,
      };
      userService
        .post(`/PerformanceHeader/UpdateP3MPerformanceHeader/`, UpdateObj)
        .then((result) => {
          if (result.succeeded) {
            commit("setPerformanceHeaderData", { returnedData: result.data });
            commit("requestFilterUpdate");

            state.SaveCounter++;
          } else {
            dispatch("alert/error", result.message, { root: true });
          }
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
      //userService.post(`/Relation/${clientId}/`, getters.Relation);
      // TODO: potentially change this once `selectAll` is implemented
    }
  },
};

const mutations = {
  toggleMenuExpanded(state) {
    state.isMenuExpanded = !state.isMenuExpanded;
  },
  toggleRelationFilterModal(state) {
    state.isRelationFilterModalVisible = !state.isRelationFilterModalVisible;
  },
  mutateRelation(state, { relation, relationID, DataID }) {
    state.portfolios = relation.portfolios;
    state.programs = relation.programs;
    state.projects = relation.projects;
    state.selectedPortfolios = [];
    state.selectedPrograms = [];
    state.selectedProjects = [];
    state.chkPortfolios = {};
    state.chkPrograms = {};
    state.chkPrograms = {};
    state.projects.forEach((p) => {
      state.chkProjects[p.id] = false;
    });
    if (DataID == 0) {
      state.programs.forEach((p) => {
        state.chkPrograms[p.id] = false;
      });
      relation.portfolios.forEach((element) => {
        if (element.objectRelationships[0]) {
          if (
            element.objectRelationships != undefined &&
            element.objectRelationships[0].portfolioId == relationID
          ) {
            element.objectRelationships.forEach((ids) => {
              state.selectedPortfolios = ids.portfolioId;
              state.selectedPrograms.push(ids.programId);
              state.selectedProjects.push(ids.projectId);
              state.chkProjects[ids.projectId] = true;
              state.chkPrograms[ids.programId] = true;
            });
          }
        }
      });
    } else {
      state.portfolios.forEach((p) => {
        state.chkPortfolios[p.id] = false;
      });
      relation.programs.forEach((element) => {
        if (element.objectRelationships[0]) {
          if (
            element.objectRelationships != undefined &&
            element.objectRelationships[0].programId == relationID
          ) {
            element.objectRelationships.forEach((ids) => {
              state.selectedPrograms = ids.programId;
              state.selectedProjects.push(ids.projectId);
              state.selectedPortfolios.push(ids.portfolioId);
              state.chkProjects[ids.projectId] = true;
              state.chkPortfolios[ids.portfolioId] = true;
            });
          }
        }
      });
      relation.portfolios.forEach((element) => {
        if (element.objectRelationships[0]) {
          if (
            element.objectRelationships != undefined &&
            element.objectRelationships[0].programId == relationID
          ) {
            element.objectRelationships.forEach((ids) => {
              state.selectedPortfolios.push(ids.portfolioId);
              state.chkPortfolios[ids.portfolioId] = true;
            });
          }
        }
      });
    }
    // Force users to pick at least one filter
    if (
      state.selectedPortfolios.length < 1 &&
      state.selectedPrograms.length < 1 &&
      state.selectedProjects.length < 1
    ) {
      state.isRelationFilterModalVisible = true;
    }
    state.relationWatcher += 1;
  },

  mutateCostHeaderRelations(state, { relation }) {
    state.costHeader = [];
    state.programs = [];
    state.projects = [];
    state.costHeader = relation.costHeader;
    state.programs = relation.programs;
    state.projects = relation.projects;
    state.selectedPrograms = [];
    state.selectedProjects = [];
    state.chkPrograms = {};
    state.chkProjects = {};
    //UnCheck all projects and Program.
    state.programs.forEach((p) => {
      state.chkPrograms[p.id] = false;
    });
    state.projects.forEach((p) => {
      state.chkProjects[p.id] = false;
    });
    let tempcostrelations = state.costHeader.objectRelationships.filter(
      (x) => x.programId != null
    );
    relation.programs.forEach((element) => {
      tempcostrelations.forEach((ids) => {
        if (ids.programId == element.id) {
          state.selectedPrograms.push(element.id);
          state.chkPrograms[element.id] = true;
        }
      });
    });
    tempcostrelations = state.costHeader.objectRelationships.filter(
      (x) => x.projectId != null
    );
    relation.projects.forEach((element) => {
      tempcostrelations.forEach((ids) => {
        if (ids.projectId == element.id) {
          state.selectedProjects.push(element.id);
          state.chkProjects[element.id] = true;
        }
      });
    });

    if (
      state.selectedPrograms.length < 1 &&
      state.selectedProjects.length < 1
    ) {
      state.isRelationFilterModalVisible = true;
    }
    state.relationWatcher += 1;
  },

  mutateRiskHeaderRelations(state, { relation }) {
    state.riskHeader = [];
    state.programs = [];
    state.projects = [];
    state.riskHeader = relation.riskHeader;
    state.portfolios = relation.portfolios;
    state.programs = relation.programs;
    state.projects = relation.projects;
    state.selectedPrograms = [];
    state.selectedProjects = [];
    state.chkPrograms = {};
    state.chkProjects = {};
    //UnCheck all projects and Program.
    state.portfolios.forEach((p) => {
      state.chkPortfolios[p.id] = false;
    });
    state.programs.forEach((p) => {
      state.chkPrograms[p.id] = false;
    });
    state.projects.forEach((p) => {
      state.chkProjects[p.id] = false;
    });

    let tempriskrelations = state.riskHeader.objectRelationships.filter(
      (x) => x.portfolioId != null
    );
    relation.portfolios.forEach((element) => {
      tempriskrelations.forEach((ids) => {
        if (ids.portfolioId == element.id) {
          state.selectedPortfolios.push(element.id);
          state.chkPortfolios[element.id] = true;
        }
      });
    });

    tempriskrelations = state.riskHeader.objectRelationships.filter(
      (x) => x.programId != null
    );
    relation.programs.forEach((element) => {
      tempriskrelations.forEach((ids) => {
        if (ids.programId == element.id) {
          state.selectedPrograms.push(element.id);
          state.chkPrograms[element.id] = true;
        }
      });
    });
    tempriskrelations = state.riskHeader.objectRelationships.filter(
      (x) => x.projectId != null
    );
    relation.projects.forEach((element) => {
      tempriskrelations.forEach((ids) => {
        if (ids.projectId == element.id) {
          state.selectedProjects.push(element.id);
          state.chkProjects[element.id] = true;
        }
      });
    });

    if (
      state.selectedPrograms.length < 1 &&
      state.selectedProjects.length < 1
    ) {
      state.isRelationFilterModalVisible = true;
    }
    state.relationWatcher += 1;
  },
  mutateScheduleHeaderRelations(state, { relation }) {
    state.scheduleHeader = [];
    state.programs = [];
    state.projects = [];
    state.scheduleHeader = relation.scheduleHeader;
    state.programs = relation.programs;
    state.projects = relation.projects;
    state.selectedPrograms = [];
    state.selectedProjects = [];
    state.chkPrograms = {};
    state.chkProjects = {};
    //UnCheck all projects and Program.
    state.programs.forEach((p) => {
      state.chkPrograms[p.id] = false;
    });
    state.projects.forEach((p) => {
      state.chkProjects[p.id] = false;
    });
    let tempcostrelations = state.scheduleHeader.objectRelationships.filter(
      (x) => x.programId != null
    );
    relation.programs.forEach((element) => {
      tempcostrelations.forEach((ids) => {
        if (ids.programId == element.id) {
          state.selectedPrograms.push(element.id);
          state.chkPrograms[element.id] = true;
        }
      });
    });
    tempcostrelations = state.scheduleHeader.objectRelationships.filter(
      (x) => x.projectId != null
    );
    relation.projects.forEach((element) => {
      tempcostrelations.forEach((ids) => {
        if (ids.projectId == element.id) {
          state.selectedProjects.push(element.id);
          state.chkProjects[element.id] = true;
        }
      });
    });

    if (
      state.selectedPrograms.length < 1 &&
      state.selectedProjects.length < 1
    ) {
      state.isRelationFilterModalVisible = true;
    }
    state.relationWatcher += 1;
  },

  mutatePerformanceHeaderRelations(state, { relation }) {
    state.performanceHeader = [];
    state.programs = [];
    state.projects = [];
    state.performanceHeader = relation.performanceHeader;
    state.portfolios = relation.portfolios;
    state.programs = relation.programs;
    state.projects = relation.projects;
    state.selectedPrograms = [];
    state.selectedProjects = [];
    state.chkPrograms = {};
    state.chkProjects = {};
    //UnCheck all projects and Program.
    state.portfolios.forEach((p) => {
      state.chkPortfolios[p.id] = false;
    });
    state.programs.forEach((p) => {
      state.chkPrograms[p.id] = false;
    });
    state.projects.forEach((p) => {
      state.chkProjects[p.id] = false;
    });

    let tempperformancerelations =
      state.performanceHeader.objectRelationships.filter(
        (x) => x.portfolioId != null
      );
    relation.portfolios.forEach((element) => {
      tempperformancerelations.forEach((ids) => {
        if (ids.portfolioId == element.id) {
          state.selectedPortfolios.push(element.id);
          state.chkPortfolios[element.id] = true;
        }
      });
    });

    tempperformancerelations =
      state.performanceHeader.objectRelationships.filter(
        (x) => x.programId != null
      );
    relation.programs.forEach((element) => {
      tempperformancerelations.forEach((ids) => {
        if (ids.programId == element.id) {
          state.selectedPrograms.push(element.id);
          state.chkPrograms[element.id] = true;
        }
      });
    });
    tempperformancerelations =
      state.performanceHeader.objectRelationships.filter(
        (x) => x.projectId != null
      );
    relation.projects.forEach((element) => {
      tempperformancerelations.forEach((ids) => {
        if (ids.projectId == element.id) {
          state.selectedProjects.push(element.id);
          state.chkProjects[element.id] = true;
        }
      });
    });

    if (
      state.selectedPrograms.length < 1 &&
      state.selectedProjects.length < 1
    ) {
      state.isRelationFilterModalVisible = true;
    }
    state.relationWatcher += 1;
  },
  saveRelations(state, { relation }) {
    state.selectedPortfolios = relation.selectedPortfolios;
    state.selectedPrograms = relation.selectedPrograms;
    state.selectedProjects = relation.selectedProjects;
  },
  requestFilterUpdate(state) {
    state.relationWatcher += 1;
  },

  setCostHeaderData(state, { returnedData }) {
    returnedData.costHeader.projects = returnedData.projects;
    returnedData.costHeader.programs = returnedData.programs;
    var temprow = {},
      tempstore = [],
      tempuid = 10000;
    if (
      returnedData.projects.length == 0 &&
      returnedData.programs.length == 0
    ) {
      returnedData.costHeader.uid = ++tempuid;
      returnedData.costHeader.parentId = 1;
      returnedData.costHeader.rowType = 3;
      returnedData.costHeader.path = [1, returnedData.costHeader.uid];
      tempstore.push(returnedData.costHeader);
    } else {
      tempuid++;
      returnedData.projects.forEach(function (parentProjects) {
        temprow = { ...returnedData.costHeader };
        temprow.uid = ++tempuid;
        temprow.parentId = parentProjects.id;
        temprow.path = [parentProjects.id, temprow.uid];
        tempstore.push(temprow);
        tempuid++;
        temprow = { ...parentProjects };
        temprow.uid = ++tempuid;
        temprow.rowType = 2;
        temprow.parentId = null;
        temprow.path = [temprow.id];
        tempstore.push(temprow);
      });
      tempuid++;
      returnedData.programs.forEach(function (parentPrograms) {
        temprow = { ...returnedData.costHeader };
        temprow.uid = ++tempuid;
        temprow.parentId = parentPrograms.id;
        temprow.path = [parentPrograms.id, temprow.uid];
        tempstore.push(temprow);
        tempuid++;
        temprow = { ...parentPrograms };
        temprow.uid = ++tempuid;
        temprow.rowType = 1;
        temprow.parentId = null;
        temprow.path = [temprow.id];
        tempstore.push(temprow);
      });
    }
    state.savedCostHeader = tempstore;
    state.saveCostHeaderCounter++;
  },

  setScheduleHeaderData(state, { returnedData }) {
    returnedData.scheduleHeader.projects = null;
    var temprow = {},
      tempstore = [];
    //returnedData.costHeader.programs = [];
    returnedData.projects.forEach(function (parentProjects) {
      returnedData.scheduleHeader.project = parentProjects;

      if (returnedData.scheduleHeader.parentId != undefined) {
        // tempDuplicateCostHeader.push(returnedData.costHeader);
        temprow = returnedData.scheduleHeader;
        //temprow.rowType = 2;
        temprow.parentId = parentProjects.id;
        temprow.path = [parentProjects.id, temprow.id];
        tempstore.push(temprow);
      } else {
        // returnedData.scheduleHeader.rowType = 3;
        returnedData.scheduleHeader.parentId = parentProjects.id;
        returnedData.scheduleHeader.path = [
          parentProjects.id,
          returnedData.scheduleHeader.id,
        ];
        tempstore.push(returnedData.scheduleHeader);
      }
    });
    if (returnedData.projects.length == 0) {
      returnedData.scheduleHeader.parentId = 1;
      //  returnedData.scheduleHeader.rowType = 3;
      returnedData.scheduleHeader.path = [1, returnedData.scheduleHeader.id];
      tempstore.push(returnedData.scheduleHeader);
    }
    //console.log(tempstore);

    state.savedScheduleHeader = tempstore;
    state.saveScheduleCounter++;
  },

  setRiskHeaderData(state, { returnedData }) {
    returnedData.riskHeader.projects = returnedData.projects;
    returnedData.riskHeader.programs = returnedData.programs;
    returnedData.riskHeader.portfolios = returnedData.portfolios;
    var temprow = {},
      tempstore = [],
      tempuid = 10000;
    if (
      returnedData.projects.length == 0 &&
      returnedData.programs.length == 0 &&
      returnedData.portfolios.length == 0
    ) {
      returnedData.riskHeader.uid = ++tempuid;
      returnedData.riskHeader.parentId = 1;
      returnedData.riskHeader.path = [1, returnedData.riskHeader.uid];
      tempstore.push(returnedData.riskHeader);
    } else {
      tempuid++;
      returnedData.projects.forEach(function (parentProjects) {
        temprow = { ...returnedData.riskHeader };
        temprow.uid = ++tempuid;
        temprow.parentId = parentProjects.id;
        temprow.path = [parentProjects.id, temprow.uid];
        tempstore.push(temprow);
        tempuid++;
        temprow = { ...parentProjects };
        temprow.uid = ++tempuid;
        temprow.rowType = 2;
        temprow.parentId = null;
        temprow.path = [temprow.id];
        tempstore.push(temprow);
      });
      tempuid++;
      returnedData.programs.forEach(function (parentPrograms) {
        temprow = { ...returnedData.riskHeader };
        temprow.uid = ++tempuid;
        temprow.parentId = parentPrograms.id;
        temprow.path = [parentPrograms.id, temprow.uid];
        tempstore.push(temprow);
        tempuid++;
        temprow = { ...parentPrograms };
        temprow.uid = ++tempuid;
        temprow.rowType = 1;
        temprow.parentId = null;
        temprow.path = [temprow.id];
        tempstore.push(temprow);
      });
      tempuid++;
      returnedData.portfolios.forEach(function (parentPortfolios) {
        temprow = { ...returnedData.riskHeader };
        temprow.uid = ++tempuid;
        temprow.parentId = parentPortfolios.id;
        temprow.path = [parentPortfolios.id, temprow.uid];
        tempstore.push(temprow);
        tempuid++;
        temprow = { ...parentPortfolios };
        temprow.uid = ++tempuid;
        temprow.rowType = 0;
        temprow.parentId = null;
        temprow.path = [temprow.id];
        tempstore.push(temprow);
      });
    }
    state.savedRiskHeader = tempstore;
    state.saveRiskHeaderCounter++;
  },

  setPerformanceHeaderData(state, { returnedData }) {
    //  returnedData.performanceHeader.projects = [];
    returnedData.performanceHeader.projects = returnedData.projects;
    returnedData.performanceHeader.programs = returnedData.programs;
    returnedData.performanceHeader.portfolios = returnedData.portfolios;
    var temprow = {},
      tempstore = [],
      tempuid = 10000;
    if (
      returnedData.projects.length == 0 &&
      returnedData.programs.length == 0 &&
      returnedData.portfolios.length == 0
    ) {
      returnedData.performanceHeader.uid = ++tempuid;
      returnedData.performanceHeader.parentId = 1;
      returnedData.performanceHeader.path = [
        1,
        returnedData.performanceHeader.uid,
      ];
      tempstore.push(returnedData.performanceHeader);
    } else {
      tempuid++;
      returnedData.projects.forEach(function (parentProjects) {
        temprow = { ...returnedData.performanceHeader };
        temprow.uid = ++tempuid;
        temprow.parentId = parentProjects.id;
        temprow.path = [parentProjects.id, temprow.uid];
        tempstore.push(temprow);
        tempuid++;
        temprow = { ...parentProjects };
        temprow.uid = ++tempuid;
        temprow.rowType = 2;
        temprow.parentId = null;
        temprow.path = [temprow.id];
        tempstore.push(temprow);
      });
      tempuid++;
      returnedData.programs.forEach(function (parentPrograms) {
        temprow = { ...returnedData.performanceHeader };
        temprow.uid = ++tempuid;
        temprow.parentId = parentPrograms.id;
        temprow.path = [parentPrograms.id, temprow.uid];
        tempstore.push(temprow);
        tempuid++;
        temprow = { ...parentPrograms };
        temprow.uid = ++tempuid;
        temprow.rowType = 1;
        temprow.parentId = null;
        temprow.path = [temprow.id];
        tempstore.push(temprow);
      });
      tempuid++;
      returnedData.portfolios.forEach(function (parentPortfolios) {
        temprow = { ...returnedData.performanceHeader };
        temprow.uid = ++tempuid;
        temprow.parentId = parentPortfolios.id;
        temprow.path = [parentPortfolios.id, temprow.uid];
        tempstore.push(temprow);
        tempuid++;
        temprow = { ...parentPortfolios };
        temprow.uid = ++tempuid;
        temprow.rowType = 0;
        temprow.parentId = null;
        temprow.path = [temprow.id];
        tempstore.push(temprow);
      });
    }
    state.savedPerformanceHeader = tempstore;
    state.savePerformanceHeaderCounter++;
  },
};

const getters = {
  relation({
    costHeader,
    scheduleHeader,
    riskHeader,
    performanceHeader,
    selectedPortfolios,
    selectedPrograms,
    selectedProjects,
  }) {
    return {
      ScheduleHeader: scheduleHeader,
      CostHeader: costHeader,
      RiskHeader: riskHeader,
      PerformanceHeader: performanceHeader,
      Portfolios: selectedPortfolios,
      Programs: selectedPrograms,
      Projects: selectedProjects,
    };
  },
  getCostHeaderRelation() {
    return state.savedCostHeader;
  },

  getScheduleHeaderRelation() {
    return state.savedScheduleHeader;
  },
  getRiskHeaderRelation() {
    return state.savedRiskHeader;
  },

  getPerformanceHeaderRelation() {
    return state.savedPerformanceHeader;
  },
};

export const relationControl = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
