import axios from "axios";

import {
  authHeader,
  refreshHeader,
  authHeaderWithResponseType,
  hasRefreshTokenExpired,
  hasTokenExpired,
} from "./auth-header";

export const userService = {
  login,
  logout,
  post,
  getJson,
  getModuleBlob,
  getFile,
  postFile,
  put,
};

function login(email, password) {
  const requestOptions = {
    headers: { "Content-Type": "application/json" },
  };
  return axios
    .post(
      `Account/Authenticate`,
      JSON.stringify({ email, password }),
      requestOptions
    )
    .then((response) => {
      // login successful if there's a jwt token in the response
      if (response.data.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
      return response.data.data;
    })
    .catch(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("pageRoute");
  localStorage.removeItem("componentCode");
  localStorage.removeItem("GanttElements");
  localStorage.removeItem("CostElement");
}

export function post(path, data) {
  if (hasTokenExpired() && !hasRefreshTokenExpired()) {
    const requestRefreshOptions = {
      headers: refreshHeader(),
    };
    return axios
      .get(`refresh/`, requestRefreshOptions)
      .then((response) => {
        // refresh successful if there's a jwt token in the response
        if (response.data.data.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("user", JSON.stringify(response.data.data));
          const updatedRequestOptions = {
            headers: { ...authHeader(), "Content-Type": "application/json" },
          };

          return axios
            .post(`${path}`, JSON.stringify(data), updatedRequestOptions)
            .then((response) => {
              return response.data;
            })
            .catch(handleResponse);
        }
        return response.data;
      })
      .catch(handleResponse);
  } else {
    const requestOptions = {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return axios
      .post(`${path}`, JSON.stringify(data), requestOptions)
      .then((response) => {
        return response.data;
      })
      .catch(handleResponse);
  }
}

function getJson(path) {
  if (hasTokenExpired() && !hasRefreshTokenExpired()) {
    const requestRefreshOptions = {
      headers: refreshHeader(),
    };
    return axios
      .get(`refresh/`, requestRefreshOptions)
      .then((response) => {
        // refresh successful if there's a jwt token in the response
        if (response.data.data.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("user", JSON.stringify(response.data.data));
          const updatedRequestOptions = {
            headers: authHeader(),
          };
          return axios
            .get(`${path}`, updatedRequestOptions)
            .then((response) => {
              return response.data;
            })
            .catch(handleResponse);
        }
        return response.data;
      })
      .catch(handleResponse);
  } else {
    const requestOptions = {
      headers: authHeader(),
    };

    return axios
      .get(`${path}`, requestOptions)
      .then((response) => {
        return response.data;
      })
      .catch(handleResponse);
  }
}

function getModuleBlob(path) {
  if (hasTokenExpired() && !hasRefreshTokenExpired()) {
    const requestRefreshOptions = {
      headers: refreshHeader(),
    };
    return axios
      .get(`refresh/`, requestRefreshOptions)
      .then((response) => {
        // refresh successful if there's a jwt token in the response
        if (response.data.data.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("user", JSON.stringify(response.data.data));
          const updatedRequestOptions = {
            headers: authHeaderWithResponseType("blob"),
          };
          return axios
            .get(`${path}`, updatedRequestOptions)
            .then((response) => {
              var blob = new Blob([response.data.data], {
                type: "application/javascript; charset=utf-8",
              });
              return blob;
            })
            .catch(handleResponse);
        }
        return response.data.data;
      })
      .catch(handleResponse);
  } else {
    const requestOptions = {
      headers: authHeaderWithResponseType("blob"),
    };

    return axios
      .get(`${path}`, requestOptions)
      .then((response) => {
        var blob = new Blob([response.data.data], {
          type: "application/javascript; charset=utf-8",
        });
        return blob;
      })
      .catch(handleResponse);
  }
}

export function postFile(path, data) {
  //var body = JSON.stringify(data);

  if (hasTokenExpired() && !hasRefreshTokenExpired()) {
    const requestRefreshOptions = {
      headers: refreshHeader(),
    };

    return axios
      .get(`refresh/`, requestRefreshOptions)
      .then((response) => {
        if (response.data.data.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("user", JSON.stringify(response.data.data));
          const updatedRequestOptions = {
            headers: { ...authHeader(), "Content-Type": "application/json" },
            responseType: "blob",
          };
          return axios
            .post(`${path}`, body, updatedRequestOptions)
            .then((response) => {
              return response.data.data;
            })
            .catch(handleResponse);
        }
        return response.data.data;
      })
      .catch(handleResponse);
  } else {
    const requestOptions = {
      // headers: { ...authHeader(), "Content-Type": "application/json" },
      headers: {
        ...authHeader(),
        //"Content-Type": "multipart/form-data",
      },
      //responseType: "blob",
    };

    return (
      axios
        //.post(`${path}`, body, requestOptions)
        .post(`${path}`, data, requestOptions)
        .then((response) => {
          console.log("Raw response");
          console.log(response);
          //return response.data.data;
          return response.data;
        })
        .catch(handleResponse)
    );
  }
}

function getFile(path) {
  if (hasTokenExpired() && !hasRefreshTokenExpired()) {
    const requestRefreshOptions = {
      method: "GET",
      headers: refreshHeader(),
    };
    return axios
      .get(`refresh/`, requestRefreshOptions)
      .then((response) => {
        // refresh successful if there's a jwt token in the response
        if (response.data.data.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("user", JSON.stringify(response.data.data));
          const updatedRequestOptions = {
            method: "GET",
            headers: authHeader(),
            responseType: "blob",
          };
          return axios
            .get(`${path}`, updatedRequestOptions)
            .then((response) => {
              return response.data.data;
            })
            .catch(handleResponse);
        }
        return response.data.data;
      })
      .then((response) => {
        return response.data.data;
      })
      .catch(handleResponse);
  } else {
    const requestOptions = {
      headers: authHeader(),
      responseType: "blob",
    };

    return axios
      .get(`${path}`, requestOptions)
      .then((response) => {
        return response.data;
        //return response.data;
      })
      .catch(handleResponse);
  }
}

function put(path, data) {
  if (hasTokenExpired() && !hasRefreshTokenExpired()) {
    const requestRefreshOptions = {
      headers: refreshHeader(),
    };
    return axios
      .get(`refresh/`, requestRefreshOptions)
      .then((response) => {
        // refresh successful if there's a jwt token in the response
        if (response.data.data.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("user", JSON.stringify(response.data.data));
          const updatedRequestOptions = {
            headers: { ...authHeader(), "Content-Type": "application/json" },
          };
          return axios
            .put(`${path}`, JSON.stringify(data), updatedRequestOptions)
            .then((response) => {
              return response.data.data;
            })
            .catch(handleResponse);
        }
        return response.data.data;
      })
      .catch(handleResponse);
  } else {
    const requestOptions = {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };

    return axios
      .put(`${path}`, JSON.stringify(data), requestOptions)
      .then((response) => {
        return response.data.data;
      })
      .catch(handleResponse);
  }
}

function handleResponse(error) {
  if (error.response && error.response.status === 401) {
    // auto logout if 401 response returned from api
    logout();
    if (location.pathname != "login") {
      location.reload(true);
    }
  }
  if (error.response && error.response.data && error.response.data.message) {
    return Promise.reject(error.response.data.message);
  } else if (
    error.response &&
    error.response.data &&
    error.response.statusText == "Internal Server Error"
  ) {
    // removes ex from error message and return the firt line from error message
    var st = -1;
    const ex = "Application.Exceptions.ApiException: ";
    var es = "";
    if (typeof error.response.data === "string") {
      es = error.response.data.replace(ex, "");
      st = es.indexOf("\n");
    }
    if (st > 0) {
      var r = es.slice(0, st);
      return Promise.reject(r);
    } else {
      return Promise.reject(error.response.statusText);
    }
  } else if (error.response && error.response.statusText) {
    return Promise.reject(error.response.statusText);
  } else if (error.request) {
    return Promise.reject("Network Error: server did not respond");
  } else if (error.message) {
    return Promise.reject(error.message);
  } else {
    return Promise.reject(error);
  }
}
