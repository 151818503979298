<template>
  <nav
    class="navbar"
    role="navigation"
    aria-label="main navigation"
    style="border-bottom: 2px solid; margin-bottom: 5px margin-top: 5px"
  >
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-end" style="margin-right: 18px">
        <!--  Save Button -->
        <button
          class="button"
          title="Save"
          @click="saveData"
          v-if="currentRouteName != 'login'"
          :disabled="getCurrentParentObjId == null"
        >
          <span class="icon is-small">
            <i class="fas fa-save"></i>
          </span>
        </button>
        <!--  RelationShip Button -->
        <!--   :class="{ 'is-active': showFilterModal }" -->
        <!--  <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add Relationship"
          aria-expanded="false"
          :class="{ 'is-active': showFilterModal }"
          @click.capture.prevent="AddRelationship"
          :disabled="getChildrenSelectedId == null"
          
        >
          <span class="icon is-small">
            <i class="fas fa-network-wired"></i>
          </span>
        </button> -->

        <!--  Rest Column State Button -->
        <!--   <button
          class="button"
          title="Reset Columns"
          v-if="currentRouteName == 'login'"
        >
          <span class="icon is-small">
            <i class="fas fa-text-width"></i>
          </span>
        </button>
       
        <button class="button" title="Undo" v-if="currentRouteName != 'login'">
          <span class="icon is-small">
            <i class="fas fa-undo"></i>
          </span>
        </button>
        
        <button class="button" title="Redo" v-if="currentRouteName != 'login'">
          <span class="icon is-small">
            <i class="fas fa-redo"></i>
          </span>
        </button>-->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add New"
          @click="addProcessHeader"
        >
          <span class="icon is-small">
            <i class="fas fa-plus"></i>
          </span>
        </button>
        <!-- Copy Gantt and Schedule Record-->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Copy"
          aria-expanded="false"
          @click="copyRecord"
          :disabled="getCurrentParentObjId == null"
        >
          <span class="icon is-small">
            <i class="fas fa-copy"></i>
          </span>
        </button>
        <!--  Add New Sub Record Button -->
        <!--   <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add Sub New"
          @click="mutateChildrenAddSubRecord"
          :disabled="getChildrenSelectedId == null"
        >
          <span class="icon is-small">
            <i class="far fa-plus-square"></i>
          </span>
        </button> -->
        <!--  Export Button -->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Export To JSON"
          @click="exportToJsonFile"
          :disabled="getCurrentParentObjId == null"
        >
          <span class="icon is-small">
            <i class="fa-solid fa-file-export"></i>
          </span>
        </button>
        <input
          type="file"
          ref="file"
          id="file"
          style="display: none"
          @change="exportProcessSet()"
        />
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Import"
          @click="$refs.file.click()"
        >
          <span class="icon is-small">
            <i class="fa-solid fa-file-import"></i>
          </span>
        </button>
        <!--  Delete Button -->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Delete"
          @click="mutateDeleteParentProcessSet"
          :disabled="getCurrentParentObjId == null"
        >
          <span class="icon is-small">
            <i class="fas fa-trash-alt"></i>
          </span>
        </button>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
p {
  width: 165px;
  display: inline-block;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}
nav.navbar {
  border-top: 0px solid black;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
</style>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "processParentSetToolbar",
  components: {},
  data() {
    return {
      file: null,
      content: null,
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
    };
  },
  mounted() {
    this.$vToastify.setSettings(this.NormalSettings);
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("processSetController", ["getCurrentParentObjId"]),

    /*Children Controller State */

    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions("processSetController", ["saveData"]),
    ...mapMutations("processSetController", [
      "addProcessHeader",
      "copyRecord",
      "exportToJsonFile",
      "mutateDeleteParentProcessSet",
      "convertJSONtoProcessSet",
    ]),
    exportProcessSet() {
      this.file = this.$refs.file.files[0];
      const reader = new FileReader();
      if (this.file.name.includes(".txt")) {
        reader.onload = (res) => {
          this.content = res.target.result;
        };
        reader.onerror = (err) => console.log(err);
        reader.readAsText(this.file);
      } else {
        reader.onload = (res) => {
          try {
            if (JSON.parse(res.target.result)) {
              this.convertJSONtoProcessSet({
                tempProcessHeaderdata: JSON.parse(res.target.result),
              });
            }
          } catch (e) {
            this.$vToastify.error("invalid json", e);
            console.error("invalid json", e);
          }
        };
        reader.onerror = (err) => console.log(err);
        reader.readAsText(this.file);
      }
    },
  },
};
</script>
