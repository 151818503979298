import { post } from "../api/user-auth.js";
import {
  getAllRowType,
  getAllExtraColumnDetails,
  UpdateExtraColumnDetails,
  DeleteExtraColumns,
} from "@/api/networking.js";

var valueTypeFilter = [
  {
    name: "String",
    value: 0,
  },
  {
    name: "Integer",
    value: 1,
  },
  {
    name: "Decimal",
    value: 2,
  },
  {
    name: "DateTime",
    value: 5,
  },
  {
    name: "Boolean",
    value: 6,
  },
  {
    name: "Lookup",
    value: 13,
  },
];

const state = {
  currentRowTypeData: [],
  currentDetailData: [],
  LoadRowTypeCounter: 0,
  tempSaveData: [],
  currentRowTypeID: null,
  dataWatcher: 0,
  selectedRowObj: {},
  addingRecord: false,
  addingDeatilRecord: false,
  addingSubRecord: false,
  isRelationship: false,
  undoWatcher: 0,
  redoWatcher: 0,
  isRedo: false,
  isUndo: false,
  CurveundoWatcher: 0,
  CurveredoWatcher: 0,
  isCurveRedo: false,
  isCurveUndo: false,
  SaveError: 0,
  currentDetailID: null,
  AddRecordCounter: 0,
  AddSubRecordCounter: 0,
  SaveCounter: 0,
  LoadCounter: 0,
  SaveWatcher: 0,
  popups: false,
  DeleteCounter: 0,
  isDelete: 0,
  isCurveDelete: 0,
  tempData: [],
  APIName: null,
};

const actions = {
  async LoadRowType({ dispatch, commit }) {
    getAllRowType()
      .then((rowData) => {
        if (rowData.succeeded) {
          commit("setRowTypeData", { returnedData: rowData.data });
          state.APIName = null;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async LoadExtraColumn({ dispatch, commit }, ExtraColumn) {
    getAllExtraColumnDetails(ExtraColumn + "ExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          commit("setDetailData", { returnedData: rowData.data });
          state.APIName = ExtraColumn + "ExtraColumn";
          state.currentDetailID = null;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async DeleteExtraColumn({ dispatch }, { IDs }) {
    DeleteExtraColumns(state.APIName, IDs)
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentDetailID = null;
          state.AddRecordCounter = 0;
          state.isDelete++;

          for (let a = 0; a < IDs.length; a++) {
            let tempindex = state.currentDetailData.findIndex(
              (x) => x.id == IDs[a]
            );
            delete state.currentDetailData[tempindex];
          }
          //state.currentDetailData = state.currentDetailData.filter(Boolean);
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
  async saveExtraColummData({ dispatch, commit }) {
    var rowsToInsert = [];
    var rowsToUpdate = [];
    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {};

    state.currentDetailData.forEach((row) => {
      if (row.rowUpdated) {
        if (row.id <= -1) {
          row.valueType = valueTypeFilter.find(
            (x) => x.value == row.valueType
          ).value;
          rowsToInsert.push(row);
        } else {
          rowsToUpdate.push({
            Id: row.id,
            code: row.code,
            description: row.description,
            valueType: valueTypeFilter.find((x) => x.value == row.valueType)
              .value,
          });
        }
      }
    });

    if (rowsToInsert.length > 0) {
      createURL = state.APIName + "/Create";
      createObj = { NewExtraColumnsToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = state.APIName;
      updateObj = { UpdateExtraColumnsToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          commit("setDetailSavedData", { returnedSavedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = UpdateExtraColumnDetails(updateURL, updateObj);
      Promise.all([createP, updateP])
        .then((values) => {
          var returnedValues = [];
          if (values[1].data) returnedValues = values[1].data;
          if (values[1].data)
            returnedValues = returnedValues.concat(values[1].data);
          commit("setDetailSavedData", { returnedSavedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
  },
};

const mutations = {
  setRowTypeData(state, { returnedData }) {
    state.currentRowTypeData = returnedData;
    state.LoadRowTypeCounter++;
  },

  setDetailSavedData(state, { returnedSavedData }) {
    returnedSavedData.forEach((row) => (row.rowUpdated = false));
    state.currentDetailData = state.currentDetailData.filter((x) => x.id > 0);
    returnedSavedData.forEach((row) => {
      let tempIndexs = state.currentDetailData.findIndex((x) => x.id == row.id);
      if (tempIndexs < 0) {
        state.currentDetailData.push(row);
      } else {
        state.currentDetailData[tempIndexs].code = row.code;
        state.currentDetailData[tempIndexs].description = row.description;
        state.currentDetailData[tempIndexs].valueType = row.valueType;
        state.currentDetailData[tempIndexs].rowUpdated = false;
      }
    });

    state.SaveCounter = 0;
    state.dataWatcher += 1;
  },

  setDetailData(state, { returnedData }) {
    if (returnedData != null) {
      returnedData.forEach((row) => (row.rowUpdated = false));
      state.currentDetailData = returnedData;
    }
    state.LoadCounter++;
  },

  setRowTypeValue(state, selectedId) {
    state.currentDetailID = null;
    state.AddRecordCounter = 0;
    state.DeleteCounter = 0;
    state.isDelete = 0;
    state.currentRowTypeID = selectedId;
  },

  setDeatilId(state, selectedId) {
    state.currentDetailID = selectedId;
  },

  setSelectedRowObject(state, selectedObj) {
    if (selectedObj != 0) {
      state.selectedRowObj = selectedObj;
    } else {
      state.selectedRowObj = 0;
    }
  },

  requestUndo(state) {
    state.undoWatcher += 1;
  },

  requestRedo(state) {
    state.redoWatcher += 1;
  },

  resetRequestUndo(state, Undovalue) {
    state.isUndo = Undovalue;
  },

  resetRequestRedo(state, Redovalue) {
    state.isRedo = Redovalue;
  },

  mutateAddRecord(state) {
    state.AddRecordCounter++;
    state.currentDetailData.push({
      id: -1,
      code: "",
      description: "",
      valueType: 0,
      rowUpdated: true,
    });
  },

  mutateSaveCounter(state) {
    state.SaveCounter++;
  },

  mutateRecord(state, { returnedNewData }) {
    state.tempSaveData = [];
    returnedNewData.forEach((element) => {
      if (element.rowUpdated) {
        state.tempSaveData.push(element);
      }
    });
    state.tempSaveData = returnedNewData;
  },

  mutateDelete(state) {
    state.DeleteCounter++;
  },
};

const getters = {
  getDataWatcher() {
    return state.dataWatcher;
  },

  getRedoWatcher() {
    return state.redoWatcher;
  },

  getUndoWatcher() {
    return state.undoWatcher;
  },

  getisRedo() {
    return state.isRedo;
  },

  getisUndo() {
    return state.isUndo;
  },

  getSelectedRowObject() {
    return state.selectedRowObj;
  },

  getRowTypeData() {
    return state.currentRowTypeData;
  },

  getDetailData() {
    return state.currentDetailData;
  },
  getTempDate() {
    return state.tempData;
  },

  getExtraColumnIdvalue() {
    return state.currentRowTypeID;
  },

  getDetailIdvalue() {
    return state.currentDetailID;
  },

  getRowTypeColumns() {
    return [
      {
        headerName: "Code",
        field: "code",
        sortable: true,
        resizable: true,
        width: 280,
        filterParams: { excelMode: "windows" },
        editable: false,
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
      },
      {
        headerName: "Description",
        field: "description",
        sortable: true,
        resizable: true,
        width: 390,
        editable: false,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
      },
    ];
  },

  getDetailsColumns() {
    return [
      {
        headerName: "Code",
        field: "code",
        sortable: true,
        resizable: true,
        width: 200,
        filterParams: { excelMode: "windows" },
        editable: function (params) {
          if (params.data.id > 0) {
            return false;
          } else {
            return true;
          }
        },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
        cellRenderer: function (params) {
          if (params.data.rowUpdated == "Duplicate") {
            let eIconGui = document.createElement("span");
            return (eIconGui.innerHTML =
              '<i class="fas fa-exclamation" style="color:red;"></i>' +
              " " +
              params.data.code);
          }
          return params.data.code;
        },
      },
      {
        headerName: "Description",
        field: "description",
        sortable: true,
        resizable: true,
        width: 300,
        editable: true,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
      },
      {
        headerName: "Type",
        field: "valueType",
        sortable: true,
        resizable: true,
        width: 100,
        filterParams: { excelMode: "windows" },
        filter: "agSetColumnFilter",
        editable: function (params) {
          if (params.data.id > 0) {
            return false;
          } else {
            return true;
          }
        },
        cellStyle: function (params) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
          return null;
        },
        cellRenderer: function (params) {
          var valueTypeFilter = [
            {
              name: "String",
              value: 0,
            },
            {
              name: "Integer",
              value: 1,
            },
            {
              name: "Decimal",
              value: 2,
            },
            {
              name: "DateTime",
              value: 5,
            },
            {
              name: "Boolean",
              value: 6,
            },
            {
              name: "Lookup",
              value: 13,
            },
          ];
          return valueTypeFilter.find((x) => x.value == params.value).name;
        },
        cellEditor: "CustomCombobox",
      },
    ];
  },
};

export const costelementExtraColumnManager = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
