import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import axios from "axios";
import router from "./router";
//import config from "../public/config.json";
import LoadScript from "vue-plugin-load-script";

Vue.config.productionTip = false;
Vue.use(LoadScript);
var scriptToUse =
  process.env.NODE_ENV === "development"
    ? "./loadingScreen.js"
    : process.env.NODE_ENV === "Staging"
    ? "https://jiveconnect-staging.azurewebsites.net/loadingScreen.js"
    : "https://app.jiveconnect.com.au/loadingScreen.js";

Vue.loadScript(scriptToUse).catch((error) => {
  console.error(error);
});
import VueToastify from "vue-toastify";
Vue.use(VueToastify, {
  //Only allow one notification on the screen at a time, others will be queued up for showing later.
  singular: false,

  //Enables backdrop to be shown.
  withBackdrop: false,

  //The rgba value for the backdrop.
  backdrop: "rgba(0, 0, 0, 0.2)",

  //Defines where the notifications should be showing on the screen. Available values are: "top-left", "top-center", "top-right", "center-left", "center-center", "center-right", "bottom-left", "bottom-center", "bottom-right"
  position: "top-right",

  //The duration in milliseconds the error notification should be visible for.
  errorDuration: 3000,

  //The duration in milliseconds the success notification should be visible for.
  successDuration: 2000,

  //The duration in milliseconds the warning and info notification should be visible for.
  warningInfoDuration: 2000,

  //Whether the notifications disappears after the set time.
  canTimeout: true,

  //Whether the notifications can be paused by hovering over them.
  canPause: true,

  //Whether the progressbar should be shown on the notification or not.
  hideProgressbar: false,

  //Whether a default title should be shown if no title is supplied.
  defaultTitle: true,

  //What theme should be displaying. By default there's light and dark.
  theme: "light",

  //Whether new notifications should display on top of the stack or not.
  orderLatest: true,

  //null	If string supplied this will apply the usual transition classes (eg.: .name-enter-active), if object supplied it expect a name and optionally a moveClass (this class has to use !important for its rules) attribute. The name will be applied as above. The move class applied when the notifications adjust their position.
  transition: null,

  //If set to false, no icon will be shown on the notification.
  iconEnabled: true, //If set to false, no icon will be shown on the notification.

  //Whether to enable dismissing the notification by dragging or not.
  draggable: true,

  //A number between 0 - 5 representing how far the notification should be dragged to dismiss.
  dragThreshold: 0.75,

  //If string is set, this will be appended to every user supplied icon's class.
  baseIconClass: null,

  //Defines how many toasts should be visible at a time. Others are queued.
  maxToasts: 5,

  //If turned on, only toasts with unique mode/type will be show. Others are queued.
  oneType: false,
});

// TODO: future use the following: https://github.com/FortAwesome/vue-fontawesome/tree/3.x#installation
import "@fortawesome/fontawesome-free/js/all.js";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";

import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-039715 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( JIVE Connect Pty Ltd )_is_granted_a_( Single Application )_Developer_License_for_the_application_( JIVEConnect )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( JIVEConnect )_need_to_be_licensed___( JIVEConnect )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released before_( 12 May 2024 )____[v2]_MTcxNTQ2ODQwMDAwMA==f7efb812848b2a28e073dcc251af9da7"
  //"CompanyName=JIVE Connect Pty Ltd,LicensedApplication=JIVEConnect,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-026812,ExpiryDate=13_May_2023_[v2]_MTY4MzkzMjQwMDAwMA==1a6b9a6674d4ebc7225c9d8849b17859"
  //"CompanyName=JIVE Connect Pty Ltd,LicensedApplication=JIVEConnect,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-015637,ExpiryDate=13_May_2022_[v2]_MTY1MjM5NjQwMDAwMA==139f4ff23733f8ab9c48cbb934bf660f"
);

class AppSettings {
  //backendUrl = "https://app.jiveconnect.com.au/services/v1.0/";
  //backendUrl = "https://localhost:9001/services/v1.0/";
}

axios
  .get("")
  // eslint-disable-next-line no-unused-vars
  .then((response) => {
    axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL; //response.data.backendUrl;
    const appSettings = new AppSettings();
    appSettings.backendUrl = process.env.VUE_APP_AXIOS_BASE_URL; //response.data.backendUrl;
    Vue.prototype.$appSettings = appSettings;
    Vue.prototype.url = function (path) {
      return this.$appSettings.backendUrl + path;
    };

    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }) // eslint-disable-next-line no-unused-vars
  .catch((error) => {
    console.error(error);
    alert("configuration error");
    debugger;
  });
