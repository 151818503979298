var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loggedIn)?_c('div',{staticClass:"sidemenu",class:{ 'is-expanded': _vm.sidemenuIsExpanded }},[_c('ul',{on:{"click":function($event){_vm.sidemenuIsExpanded = !_vm.sidemenuIsExpanded}}},[_vm._m(0)]),_vm._l((_vm.allComponents),function(jiveComponent){return _c('ul',{key:jiveComponent.MenuHeader,class:{
      'is-active': jiveComponent.Components[0].Path == '/' + _vm.currentRouteName,
    },attrs:{"aria-expanded":"false"},on:{"!click":function($event){return _vm.expandSubMenu.apply(null, arguments)}}},[_c('li',[(jiveComponent.Icon && !jiveComponent.Icon.startsWith('fas'))?_c('span',{staticClass:"icon"},[(jiveComponent.Icon)?_c('img',{staticClass:"level-icon",attrs:{"src":require('@/assets/icons' + jiveComponent.Icon)}}):_vm._e()]):_vm._e(),_c('h1',[_vm._v(_vm._s(jiveComponent.MenuHeader))]),_vm._m(1,true)]),_c('ul',[_c('li',[_c('h1',[_vm._v(_vm._s(jiveComponent.MenuHeader))])]),_vm._l((jiveComponent.Components),function(currComponent){return _c('li',{key:currComponent.Id,staticClass:"long-view-text-size",class:{
          'is-active':
            (currComponent.Path + '/' + currComponent.Code ==
              _vm.currentRoutePath)
            | (currComponent.Path + '/' + String(currComponent.Id) ==
              _vm.currentRoutePath),
        },on:{"click":function($event){return _vm.moveTo(currComponent.Path, currComponent.Code)}}},[_c('LevelIconCellRenderer',{attrs:{"value":currComponent.Code}}),_vm._v(" "+_vm._s(currComponent.Desc)+" ")],1)})],2)])})],2):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fas fa-bars"}),_c('h1')])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"expandable"},[_c('i',{staticClass:"fas fa-angle-down"}),_c('i',{staticClass:"fas fa-angle-up"})])
}]

export { render, staticRenderFns }