<template>
  <div class="section mt-6">
    <div class="columns is-desktop">
      <form
        class="column is-one-third is-offset-one-third"
        @submit.prevent="handleSubmit"
      >
        <div class="field">
          <p class="control has-icons-left has-icons-right">
            <input
              class="input"
              type="text"
              placeholder="Username"
              v-model="username"
              :class="{ 'is-danger': invalidUsername }"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
            <span class="icon is-small is-right" v-if="invalidUsername">
              <!-- <i class="fas fa-check"></i> -->
              <i class="fas fa-exclamation-triangle"></i>
            </span>
          </p>
          <p class="help is-danger" v-if="invalidUsername">
            Username is required
          </p>
        </div>

        <div class="field">
          <p class="control has-icons-left has-icons-right">
            <input
              class="input"
              type="password"
              placeholder="Password"
              v-model="password"
              :class="{ 'is-danger': invalidPassword }"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-lock"></i>
            </span>
            <span class="icon is-small is-right" v-if="invalidPassword">
              <!-- <i class="fas fa-check"></i> -->
              <i class="fas fa-exclamation-triangle"></i>
            </span>
          </p>
          <p class="help is-danger" v-if="invalidPassword">
            Password is required
          </p>
        </div>

        <div class="field">
          <p class="control is-expanded">
            <button
              class="button is-success is-fullwidth is-size-5"
              type="submit"
              :disabled="status.loggingIn"
              :class="{ 'is-loading': status.loggingIn }"
            >
              Login
            </button>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
    };
  },
  watch: {
    entitiesLoaded() {
      if (this.entitiesLoaded) {
        //Route to first in list.
        console.log("Entities Loaded at Login", this.allComponents);
        if (
          this.allComponents.length > 0 &&
          this.allComponents[0].Components.length > 0
        ) {
          this.$router.push(
            this.allComponents[0].Components[0].Path +
              "/" +
              this.allComponents[0].Components[0].Id
          );
        }
      }
    },
  },
  computed: {
    ...mapState("account", ["status"]),
    ...mapGetters("jiveComponents", ["entitiesLoaded", "allComponents"]),
    invalidUsername: function () {
      return this.submitted && !this.username;
    },
    invalidPassword: function () {
      return this.submitted && !this.password;
    },
  },
  created() {
    // reset login status
    this.logout();
  },
  methods: {
    ...mapActions("account", ["login", "logout"]),
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;
      const { username, password } = this;
      if (username && password) {
        this.login({ username, password });
      }
    },
  },
};
</script>
