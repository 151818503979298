import { userService } from "@/api/user-auth.js";

import { post } from "@/api/networking.js";

const state = {
  image: "",
  clientLogoPath: "",
  clientLogoFile: {},
  currentUsers: [],
  systemConnInfo: {},
  userSettingsLoaded: false,
};

const actions = {
  async connectToCX({ commit }, AccountRestPassword) {
    userService
      .post("Account/ConnectToCX", AccountRestPassword)
      .then((result) => {
        // if (result.succeeded && result.data != null) {
        // } else {
        // }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async saveConnections({ commit }, settingInfo) {
    await userService
      .post("JIVEUser/AddSetting", settingInfo)
      .then((result) => {
        //if (result.succeeded && result.data != null) {}
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async loadUserSettings({ dispatch, commit, rootGetters }) {
    userService
      .getJson("JIVEUser/GetSettingByFilter?filter=EXT_SYS")
      .then((result) => {
        if (result.succeeded && result.data != null) {
          try {
            result.data.forEach((x) => {
              let currConnInfo = JSON.parse(x.data);
              state.systemConnInfo[x.name.substring(8)] = currConnInfo;
            });
          } catch (ex) {
            console.log("Exception", ex);
          }
          state.userSettingsLoaded = true;
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
};

const getters = {
  userSettingsLoaded() {
    return state.userSettingsLoaded;
  },

  getLoadedConnInfo() {
    return state.systemConnInfo;
  },
};

const mutations = {};

export const externalSystemsAccess = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
