// store/index.js
const state = {
  fromdate: null,
  todate: null,
  reportDate: null,
};
const mutations = {
  storedatevalues(state, { fromdate, todate, reportDate }) {
    (state.fromdate = fromdate),
      (state.todate = todate),
      (state.reportDate = reportDate);
  },
};
// const actions = {
//     storedatevalues({ commit }, payload) {
//         commit("storedatevalues", payload);
//     },
// };
const getters = {
  resultval({ fromdate, todate, reportDate }) {
    return {
      from: fromdate,
      to: todate,
      report: reportDate,
    };
  },
};
export const storedates = {
  namespaced: true,
  state,
  //   actions,
  mutations,
  getters,
};
