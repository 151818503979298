import { post } from "../api/user-auth.js";
import * as moment from "moment";
import * as utilitiesModule from "@/assets/utilities.js";
import {
  DeleteProject,
  DeleteProgram,
  DeletePortfolio,
  UpdateMasterData,
  getAllExtraColumnDetails,
  getColumnDef,
} from "@/api/networking.js";

const state = {
  selectedDataTypeId: "",
  dataTypes: [
    {
      id: "Portfolios",
      name: "Portfolios",
      API: "Portfolio",
      iconClass: "fas fa-folder-open",
      componentId: 6,
    },
    {
      id: "Programs",
      name: "Programs",
      API: "Program",
      iconClass: "fas fa-sitemap",
      componentId: 7,
    },
    {
      id: "Projects",
      name: "Projects",
      API: "Project",
      iconClass: "fas fa-diagram",
      componentId: 8,
    },
    {
      id: "Costs",
      name: "Costs",
      iconClass: "fas fa-diagram",
      componentId: 5,
    },
    {
      id: "Schedule Header",
      name: "Schedules",
      API: "SCHEDULEHEADER",
      iconClass: "fas fa-folder-open",
      apiPrefix: "ScheduleHeader",
      componentId: 9,
    },
    {
      id: "ERP",
      name: "Enterprise Resource Planning",
      API: "activity",
      iconClass: "fas fa-folder-open",
      apiPrefix: "EnterpriseResourceHandler",
    },
  ],
};

const actions = {};

const mutations = {
  setSelectedDataType(state, selectedDataTypeId) {
    state.selectedDataTypeId = selectedDataTypeId;
  },
};

const getters = {};

export const TransactionalData = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
