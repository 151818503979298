import { render, staticRenderFns } from "./GroupAdmin.vue?vue&type=template&id=5181025b&scoped=true&"
import script from "./GroupAdmin.vue?vue&type=script&lang=js&"
export * from "./GroupAdmin.vue?vue&type=script&lang=js&"
import style0 from "./GroupAdmin.vue?vue&type=style&index=0&id=5181025b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5181025b",
  null
  
)

export default component.exports