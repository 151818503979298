import { Toolbar, Toast, DateHelper, CSSHelper } from "@bryntum/gantt";

export default class ERPToolbar extends Toolbar {
  // Factoryable type name
  static get type() {
    return "erptoolbar";
  }

  static get $name() {
    return "ERPToolbar";
  }

  // Called when toolbar is added to the Gantt panel
  set parent(parent) {
    super.parent = parent;
    const me = this;

    //me.gantt = parent;

    parent.project.on({
      load: "updateStartDateField",
      refresh: "updateStartDateField",
      thisObj: me,
    });

    me.styleNode = document.createElement("style");
    document.head.appendChild(me.styleNode);
  }

  get parent() {
    return super.parent;
  }

  static get configurable() {
    return {
      items: [
        {
          type: "buttonGroup",
          style: "position: absolute;  height: 60%; right: 4.5%; top: 15px;",
          items: [
            {
              type: "buttonGroup",
              items: [
                {
                  style: "background-color:transparent;",
                  //color: "b-green",
                  ref: "saveUpdatesBtn",
                  icon: "fas fa-save",
                  // text: "Save",
                  tooltip: "Save Changes",
                  onAction: "up.onSaveClick",
                  //onAction: this.trigger("customSaveCmd1", null),
                  //bubbleEvents: { action: true },
                },
              ],
            },
            {
              type: "buttonGroup",
              style: "background-color:transparent;",
              items: [
                {
                  ref: "expandAllButton",
                  icon: "b-fa b-fa-angle-double-down",
                  tooltip: "Expand all",
                  style: "background-color:transparent;",
                  onAction: "up.onExpandAllClick",
                },
                {
                  ref: "collapseAllButton",
                  icon: "b-fa b-fa-angle-double-up",
                  tooltip: "Collapse all",
                  style: "background-color:transparent;",
                  onAction: "up.onCollapseAllClick",
                },
              ],
            },
            {
              type: "buttonGroup",
              items: [
                {
                  ref: "zoomInButton",
                  icon: "b-fa b-fa-search-plus",
                  tooltip: "Zoom in",
                  style: "background-color:transparent;",
                  onAction: "up.onZoomInClick",
                },
                {
                  ref: "zoomOutButton",
                  icon: "b-fa b-fa-search-minus",
                  tooltip: "Zoom out",
                  style: "background-color:transparent;",
                  onAction: "up.onZoomOutClick",
                },
                {
                  ref: "zoomToFitButton",
                  icon: "b-fa b-fa-compress-arrows-alt",
                  tooltip: "Zoom to fit",
                  style: "background-color:transparent;",
                  onAction: "up.onZoomToFitClick",
                },
                {
                  ref: "previousButton",
                  icon: "b-fa b-fa-angle-left",
                  tooltip: "Previous time span",
                  style: "background-color:transparent;",
                  onAction: "up.onShiftPreviousClick",
                },
                {
                  ref: "nextButton",
                  icon: "b-fa b-fa-angle-right",
                  tooltip: "Next time span",
                  style: "background-color:transparent;",
                  onAction: "up.onShiftNextClick",
                },
              ],
            },
          ],
        },
      ],
    };
  }

  setAnimationDuration(value) {
    const me = this,
      cssText = `.b-animating .b-gantt-task-wrap { transition-duration: ${
        value / 1000
      }s !important; }  `;

    me.gantt.transitionDuration = value;

    if (me.transitionRule) {
      me.transitionRule.cssText = cssText;
    } else {
      me.transitionRule = CSSHelper.insertRule(cssText);
    }
  }

  updateStartDateField() {
    // const { startDateField } = this.widgetMap;
    // startDateField.value = this.gantt.project.startDate;
    // // This handler is called on project.load/propagationComplete, so now we have the
    // // initial start date. Prior to this time, the empty (default) value would be
    // // flagged as invalid.
    // startDateField.required = true;
  }

  // region controller methods
  async onSaveClick() {
    //const { gantt } = this;
    this.trigger("SaveGanttChanges", null);
  }
  async onCreateBaselineClick() {
    //const { gantt } = this;
    this.trigger("CreateBaseline", null);
  }
  async onLoadFileClick() {
    //const { gantt } = this;
    this.trigger("LoadFile", null);
  }
  async onFileChange(event) {
    //console.log(event.files[0]);
    //if(event.value.toString() !== '')
    this.trigger("SetFile", event);
  }

  async onAddTaskClick() {
    const { gantt } = this,
      added = gantt.taskStore.rootNode.appendChild({
        name: "New task",
        duration: 1,
      });

    // run propagation to calculate new task fields
    await gantt.project.propagateAsync();

    // scroll to the added task
    await gantt.scrollRowIntoView(added);

    gantt.features.cellEdit.startEditing({
      record: added,
      field: "name",
    });
  }

  onEditTaskClick() {
    const { gantt } = this;

    if (gantt.selectedRecord) {
      gantt.editTask(gantt.selectedRecord);
    } else {
      Toast.show("First select the task you want to edit");
    }
  }

  onExpandAllClick() {
    this.gantt.expandAll();
  }

  onCollapseAllClick() {
    this.gantt.collapseAll();
  }

  onZoomInClick() {
    this.gantt.zoomIn();
  }

  onZoomOutClick() {
    this.gantt.zoomOut();
  }

  onZoomToFitClick() {
    this.gantt.zoomToFit({
      leftMargin: 50,
      rightMargin: 50,
    });
  }

  onShiftPreviousClick() {
    this.gantt.shiftPrevious();
  }

  onShiftNextClick() {
    this.gantt.shiftNext();
  }

  onStartDateChange({ value, oldValue }) {
    if (!oldValue) {
      // ignore initial set
      return;
    }

    this.gantt.startDate = DateHelper.add(value, -1, "week");

    this.gantt.project.setStartDate(value);
  }
  onStatusDateChange({ value, oldValue }) {
    this.gantt.features.progressLine.statusDate = value;
  }
  onFilterChange({ value }) {
    if (value === "") {
      this.gantt.taskStore.clearFilters();
    } else {
      value = value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

      this.gantt.taskStore.filter({
        filters: (task) => task.name && task.name.match(new RegExp(value, "i")),
        replace: true,
      });
    }
  }

  onFeaturesClick({ source: item }) {
    const { gantt } = this;

    if (item.feature) {
      const feature = gantt.features[item.feature];
      feature.disabled = !feature.disabled;
    } else if (item.subGrid) {
      const subGrid = gantt.subGrids[item.subGrid];
      subGrid.collapsed = !subGrid.collapsed;
    }
  }

  onFeaturesShow({ source: menu }) {
    const { gantt } = this;

    menu.items.map((item) => {
      const { feature } = item;

      if (feature) {
        // a feature might be not presented in the gantt
        // (the code is shared between "advanced" and "php" demos which use a bit different set of features)
        if (gantt.features[feature]) {
          item.checked = !gantt.features[feature].disabled;
        }
        // hide not existing features
        else {
          item.hide();
        }
      } else {
        item.checked = gantt.subGrids[item.subGrid].collapsed;
      }
    });
  }

  onSettingsShow({ source: menu }) {
    const { gantt } = this,
      { rowHeight, barMargin, duration } = menu.widgetMap;

    rowHeight.value = gantt.rowHeight;
    barMargin.value = gantt.barMargin;
    barMargin.max = gantt.rowHeight / 2 - 5;
    duration.value = gantt.transitionDuration;
  }

  onSettingsRowHeightChange({ value }) {
    this.gantt.rowHeight = value;
    this.widgetMap.settingsButton.menu.widgetMap.barMargin.max = value / 2 - 5;
  }

  onSettingsMarginChange({ value }) {
    this.gantt.barMargin = value;
  }

  onSettingsDurationChange({ value }) {
    this.gantt.transitionDuration = value;
    this.styleNode.innerHTML = `.b-animating .b-gantt-task-wrap { transition-duration: ${
      value / 1000
    }s !important; }`;
  }

  onCriticalPathsClick({ source }) {
    this.gantt.features.criticalPaths.disabled = !source.pressed;
  }

  // endregion
}

// Register this widget type with its Factory
ERPToolbar.initClass();
