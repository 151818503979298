import { ResourceModel } from "@bryntum/gantt";

export default class JiveConnectResource extends ResourceModel {
  static get fields() {
    return [
      { name: "calendarId", type: "integer" },
      { name: "comment", type: "string" },
      { name: "description", type: "string" },
      { name: "externalSystemId", type: "string" },
      { name: "parentId", type: "integer" },
      { name: "resourceTypeId", type: "integer" },
    ];
  }
}
