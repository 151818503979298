import {
  getAllExtraColumnDetails,
  getAllStructureDetail,
  DeleteElement,
} from "@/api/networking.js";
import { post } from "@/api/user-auth.js";

/* Create Tree Path */
let data = [],
  i = -1,
  tempData = null;
function getParentLoop(id, path) {
  let p = data.filter((i) => i.id == id)[0];
  if (p.parentId) {
    path.unshift(p.id);
    return getParentLoop(p.parentId, path);
  } else {
    path.unshift(p.id);
  }
  return path;
}

function setpath(data) {
  data.map((i) => {
    i.path = [];
    if (i.parentId) {
      i.path.push(i.id);
      getParentLoop(i.parentId, i.path);
    } else {
      i.path.push(i.id);
    }
    return i;
  });
  return data;
}

const state = {
  /* Module Title*/
  APIName: "Resources",
  /*left Side(Children grid details)*/
  /*CurrentData and details*/
  currentChildrenData: [],
  currentchildTempData: [],
  currentChildrenId: null,
  currentChildrenSelectedObj: [],
  currentParentID: [],
  currentselectedChildrenRows: [],
  cuurentcopyRowvalues: [],

  /*Main Data Confing Tools*/
  currentChildrenLoadRecordCounter: 0,
  currentChildrenAddRecordCounter: 0,
  currentChildrenSubRecordCounter: 0,
  currentChildrenDeleteRecordCounter: 0,
  currentChildrenSaveRecordCounter: 0,

  /*Children Node Grid Config*/
  currentChildrenUndoWatcher: 0,
  currentChildrenRedoWatcher: 0,
  currentChildrenredostate: false,
  currentChildrenundostate: false,

  /*Children Node Grid Config*/
  isRelationship: false,
  currentCostModelType: [],

  /*Commonn Config*/
  isSave: 0,
  isDelete: 0,
  datawatcher: 0,
  SaveError: 0,
  duplicateRecord: 0,

  /* Children Column Def */
  currentChildrenColDef: [],
  loadStructureCounter: 0,
  currentGridColDefCount: 0,
  currentChildrenGridColDefCountCounter: 0,
  tempCalenarData: [],
  tempcalendarCounter: 0,
  tempResourceTypeData: [],
  tempResourceCounter: 0,
  currentParentColumDefCounter: 0,
  currentParentColDefs: [],
};

const actions = {
  /*Extra Column */
  async LoadCalendarExtraColumn({ dispatch }) {
    state.currentParentColDefs = [];
    getAllExtraColumnDetails("ResourceExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          rowData.data.forEach((y) =>
            state.currentParentColDefs.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 120,
              editable: true,
              filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: "OR",
              },
              filter: "agSetColumnFilter",
              cellStyle: function (params) {
                if (params.data != undefined && params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                } else if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return new Date(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.currentParentColumDefCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, {
          root: true,
        });
      });
  },
  /*ResourceType*/
  async LoadResourceTypesColumn({ dispatch }) {
    getAllExtraColumnDetails("ResourceType")
      .then((rowData) => {
        if (rowData.succeeded) {
          state.tempResourceTypeData = rowData.data;
          state.tempResourceCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, {
          root: true,
        });
      });
  },
  /* Load Calendar */
  async LoadCalendarColumn({ dispatch }) {
    getAllExtraColumnDetails("Calendar")
      .then((rowData) => {
        if (rowData.succeeded) {
          state.tempCalenarData = rowData.data;
          state.tempcalendarCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, {
          root: true,
        });
      });
  },

  /*LoadCostElements */
  async loadResources({ dispatch, commit }) {
    getAllExtraColumnDetails(state.APIName)
      .then((rowData) => {
        if (rowData.succeeded) {
          commit("setChildrenData", { returnedData: rowData.data });
          i = -2;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /*Cache Structure Details */
  async LoadGlobalStructureDetails({ dispatch }) {
    getAllStructureDetail()
      .then((rowData) => {
        if (rowData.succeeded) {
          state.tempstructuredetails = rowData.data;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /**Delete Cost Element */
  async DeleteData({ dispatch }, { Id }) {
    DeleteElement(state.APIName, Id)
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentChildrenId = null;
          state.currentChildrenSelectedObj = [];
          state.currentChildrenUndoWatcher = 0;
          state.currentChildrenRedoWatcher = 0;
          state.currentChildrenredostate = false;
          state.currentChildrenundostate = false;
          state.isDelete++;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /**Save Cost Element */
  async saveData({ dispatch, commit }) {
    var rowsToInsert = [];
    var rowsToUpdate = [];
    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {};

    state.currentchildTempData.forEach((row) => {
      if (row.code != undefined && row.tempresourceTypeId != null) {
        if (row.rowUpdated) {
          if (row.id <= -1) {
            //delete row.path;
            // row.resourceTypeId = row.tempresourceTypeId;
            row.calendarId = row.tempcalendarId;
            rowsToInsert.push(row);
          } else {
            rowsToUpdate.push({
              Id: row.id,
              code: row.code,
              description: row.description,
              calendarId: row.calendarId,
              resourceTypeId: row.resourceTypeId,
              comment: row.comment,
              capacity: row.capacity,
              parentId: row.parentId,
              ExtraColumnValues: row.extraColumnValues,
            });
          }
        }
      } else {
        state.SaveError++;
        rowsToInsert = [];
        rowsToUpdate = [];
        return false;
      }
    });

    if (rowsToInsert.length > 0) {
      createURL = state.APIName + "/Create";

      createObj = { ResourcesToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = state.APIName + "/Update";

      updateObj = { ResourcesToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          /* if (values)
                      returnedValues = returnedValues.concat(values.data); */
          commit("setSavedChildrenData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = post(updateURL, updateObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = returnedValues.concat(values.data);
          commit("setSavedChildrenData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
    i = -1;
  },
};

const mutations = {
  /*left Side(Children grid details)*/
  /*CurrentData and details*/

  setChildrenData(state, { returnedData }) {
    returnedData.forEach((row) => {
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.resourceExtraColumnId] = y.value)
        );
      }
      if (row.calendarId != null && state.tempCalenarData.length > 0) {
        row.tempcalendarId = row.calendarId;
        var tempdata = state.tempCalenarData.filter(
          (x) => x.id == row.calendarId
        );
        row.calendarId = tempdata[0].description;
      }
      // row.tempresourceTypeId = row.resourceTypeId;
      var tempdata1 = state.tempResourceTypeData.filter(
        (x) => x.id == row.resourceTypeId
      );
      row.tempresourceTypeId = tempdata1[0].description;
    });
    data = returnedData;
    tempData = setpath(data);
    state.currentChildrenData = returnedData;
    /*Reset the Children Grid property */
    state.currentChildrenId = null;
    state.currentChildrenSelectedObj = [];
    state.currentChildrenDeleteRecordCounter = 0;
    state.currentChildrenUndoWatcher = 0;
    state.currentChildrenRedoWatcher = 0;
    state.currentChildrenredostate = false;
    state.currentChildrenundostate = false;
    state.currentChildrenAddRecordCounter = 0;
    state.currentChildrenLoadRecordCounter++;
  },

  setSavedChildrenData(state, { returnedData }) {
    returnedData.forEach((row) => (row.rowUpdated = false));
    state.currentchildTempData.forEach((row) => {
      row.rowUpdated = false;
      if (row.id < 0) {
        let tempValues = returnedData.findIndex(
          (x) => x.code == row.code && x.description == row.description
        ).id;
        if (tempValues > 0) {
          row.id = returnedData[0].id;
          row.path[row.path.length - 1] = returnedData[0].id;
        }
      }
      if (row.parentId < 0) {
        row.parentId = returnedData.find(
          (x) => x.code == row.code && x.description == row.description
        ).parentId;
        if (row.path.filter((x) => x < 0).length > 1) {
          row.path = state.currentchildTempData.find(
            (x) => x.id == row.parentId
          ).path;
          row.path.push(row.id); // row.id;
        } else {
          row.path[row.path.length - 2] = row.parentId;
          row.path[row.path.length - 1] = row.id;
        }
      }
      if (row.calendarId != null) {
        row.tempcalendarId = row.calendarId;
        var tempdata = state.tempCalenarData.filter(
          (x) => x.id == row.calendarId
        );
        row.calendarId = tempdata[0].description;
      }
      var tempdata1 = state.tempResourceTypeData.filter(
        (x) => x.id == row.resourceTypeId
      );
      row.tempresourceTypeId = tempdata1[0].description;

      delete row.ChildStructureDetails;
    });
    //state.currentChildrenData = returnedData;
    /*Reset the Children Grid property */
    state.currentChildrenUndoWatcher = 0;
    state.currentChildrenRedoWatcher = 0;
    state.currentChildrenredostate = false;
    state.currentChildrenundostate = false;
    state.datawatcher++;
  },

  setChildrenObj(state, row) {
    state.currentChildrenSelectedObj = row;
    state.currentChildrenId = row.id;
  },

  setSelectedChildrenRowObject(state, row) {
    state.currentselectedChildrenRows = row;
  },

  /*mutate counter for CRUD*/
  mutateChildrenAddRecord(state) {
    state.currentChildrenAddRecordCounter++;
  },

  mutateChildrenAddSubRecord(state) {
    state.currentChildrenSubRecordCounter++;
  },

  mutateChildrenDelete(state) {
    state.currentChildrenDeleteRecordCounter++;
  },

  mutateChildrenSave(state) {
    state.currentChildrenSaveRecordCounter++;
  },

  /*Children Grid Config*/
  mutateChildrenRedo(state) {
    state.currentChildrenRedoWatcher++;
  },

  mutateChildrenUndo(state) {
    state.currentChildrenUndoWatcher++;
  },

  mutateChildrenRedostate(state, value) {
    state.currentChildrenredostate = value;
  },

  mutateChildrenUndostate(state, value) {
    state.currentChildrenundostate = value;
  },

  mutateElementRealtionship(state, isRelationship) {
    state.isRelationship = isRelationship;
  },

  mutateChildrenCopyRecord(state) {
    const tempcopydata = [],
      tempconstData = [];

    state.currentselectedChildrenRows.forEach((row) => {
      tempcopydata.push(row);
      tempconstData.push(JSON.parse(JSON.stringify(row)));
    });

    let tempchange = null,
      tempchangechild = null,
      trackParent = false;
    let objtempParentData = tempcopydata[0].path;
    for (let y = 0; y < tempcopydata.length; y++) {
      tempcopydata[y].id = i - 25;
      tempcopydata[y].rowUpdated = true;
      if (y > 0) {
        if (tempcopydata[y].parentId == tempconstData[y - 1].parentId) {
          trackParent = false;
          tempchangechild = y - 1;
        } else {
          trackParent = true;
          tempchange = tempconstData.findIndex(
            (x) => x.id == tempcopydata[y].parentId
          );
        }
      }
      delete tempcopydata[y].path;
      if (tempcopydata[y].parentId > 0) {
        if (y == 0) {
          tempcopydata[y].parentId =
            state.currentselectedChildrenRows[0].parentId;
          objtempParentData[objtempParentData.length - 1] = tempcopydata[y].id;
          tempcopydata[y].path = objtempParentData;
        } else {
          if (trackParent) {
            tempcopydata[y].parentId = tempcopydata[tempchange].id;
          } else {
            tempcopydata[y].parentId = tempcopydata[tempchangechild].parentId;
          }
        }
      } else {
        tempcopydata[y].parentId = null;
      }
      i--;
    }
    state.cuurentcopyRowvalues = setpath(tempcopydata);
    state.duplicateRecord++;
  },

  mutateRecord(state, { returnedNewData }) {
    state.currentchildTempData = [];
    returnedNewData.forEach((element) => {
      if (element.rowUpdated) {
        element.calendarId = element.tempcalendarId;
        state.currentchildTempData.push(element);
      }
    });
    state.currentchildTempData = returnedNewData;
  },
};

const getters = {
  /*left Side(Children grid details)*/
  /*CurrentData and details*/
  getChildrenGridColumn() {
    return state.currentChildrenColDef;
  },

  getChildrenCurrentData() {
    return state.currentChildrenData;
  },

  getChildTempData() {
    return state.currentchildTempData;
  },

  getChildrenSelectedObj() {
    return state.currentChildrenSelectedObj;
  },
  getChildrenSelectedId() {
    return state.currentChildrenId;
  },
  /*Extra Config */
  getCostModelTypeData() {
    return state.currentCostModelType;
  },
  getcostHeaderStatus() {
    return state.isRelationship;
  },
  getAllStructureDetailsvalue() {
    return state.tempstructuredetails;
  },

  getCopiedRows() {
    return state.cuurentcopyRowvalues;
  },
  getcalendarData() {
    return state.tempCalenarData;
  },
  getResourceTypeData() {
    return state.tempResourceTypeData;
  },
  getParentColDefs(state) {
    return state.currentParentColDefs;
  },
};

/* CostHeader Module */
export const scheduleResourceController = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
