<template>
  <div style="height: 100%">
    <div ref="scriptContainer" />
    <ModuleToolBar />
    <!-- :animateRows="true" -->
    <!-- :applyColumnDefOrder="true"  // columnDefs order will be matched from array-->
    <ag-grid-vue
      v-if="showGrid"
      class="ag-theme-balham ag-default-layout"
      rowSelection="single"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :pivotMode="pivotMode"
      :sideBar="sideBar"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      :autoGroupColumnDef="autoGroupColumnDef"
      :treeData="treeData"
      :animateRows="true"
      :groupDefaultExpanded="groupDefaultExpanded"
      :getDataPath="getDataPath"
      :suppressColumnStateEvents="true"
      :overlayLoadingTemplate="loadingTemplate"
      :overlayNoRowsTemplate="noRowsTemplate"
      :debounceVerticalScrollbar="true"
      :defaultExportParams="defaultExportParams"
      @cell-value-changed="onCellValueChanged"
      @grid-ready="onGridReady"
      @sort-changed="onColumnChanged"
      @selection-changed="onSelectionChanged"
      @column-resized="onColumnChanged"
      @column-visible="onColumnChanged"
      @column-pivot-changed="onColumnChanged"
      @column-row-group-changed="onColumnChanged"
      @column-value-changed="onColumnChanged"
      @column-moved="onColumnChanged"
      @column-pinned="onColumnChanged"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
/**
 * Four flows of loading rows/columns
 *
 * # onPageLoad/onGridReady
 * 1. getRemoteColumns/__hydrateColumns
 * 2. loadMonthColumns/__hydrateMonthColumns
 * 3. loadColumnState/applyColumnState
 * 4. loadRows
 *
 * # Filter Change -> can change date + rows
 * 1. loadMonthColumns [remove all Date parseable headerNames, concat new months, cache __hydrateMonthColumns]
 * 2. loadColumnState [hiding and order of old/new is a little odd]
 * 3. loadRows
 *
 * # Reset Columns (theoretically don't need to call `loadRows`)
 * 1. resetColumnsCalled
 * 2. __hydrateColumns <useCached>
 * 3. __hydrateMonthColumns <use Cached>
 *
 */
import { AgGridVue } from "ag-grid-vue";
import ModuleToolBar from "@/components/Navigation/ModuleToolBar.vue";
import LevelIconCellRenderer from "@/components/Data/LevelIconCellRenderer.vue";
import "ag-grid-enterprise";
import * as utilitiesModule from "@/assets/utilities.js";
import { debounce } from "lodash";
import { mapMutations, mapGetters, mapState } from "vuex";
import {
  getRemoteRow,
  getRemoteColumns,
  getColumnState,
  postChanges,
  postColumnState,
  getMonthColumns,
} from "@/api/networking.js";

export default {
  name: "ViewGrid",
  props: {
    view: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
      gridOptions: null,
      defaultExportParams: {
        allColumns: true,
        columnGroups: true,
        fileName: "fileName",
      },
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      autoGroupColumnDef: null,
      treeData: true,
      showGrid: true,
      sideBar: false,
      pivotMode: false,
      groupDefaultExpanded: null,
      getDataPath: null,
      loadingTemplate: `<span class="ag-overlay-loading-center">Data is loading...</span>`,
      loading: false,
      set: new Set(),
    };
  },
  components: {
    AgGridVue,
    ModuleToolBar,
    LevelIconCellRenderer, // eslint-disable-line
  },
  beforeMount() {
    this.gridOptions = {
      valueCache: true,
      enableRangeSelection: true,
      enableCharts: true,
      statusBar: {
        statusPanels: [
          { statusPanel: "agFilteredRowCountComponent", align: "center" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      // suppressAnimationFrame: false,
      // suppressColumnMoveAnimation: true,
      // animateRows: true,  // makes it better lol
      // suppressScrollOnNewData: true,
      // // debounceVerticalScrollbar: true,
      // suppressRowHoverHighlight: true,
      // rowBuffer: 5,
      // suppressCellSelection: true,//?
      getRowClass: (params) => {
        // params.data && params.data.hidden
        return params.data && params.data.hidden ? "ag-hidden-row" : null;
      },
      getRowHeight: (params) => {
        // params.data && params.data.isHidden
        return params.data && params.data.hidden ? 0 : 25;
      },
    };
    this.rowData = [];
    this.columnDefs = [];
    this.defaultColDef = Object.assign({}, this.defaultColDef, {
      // flex: 1,
      width: 180,
      cellClass: "ag-default-cell",
      resizable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
    });
    this.autoGroupColumnDef = {
      headerName: "Unloaded",
      cellRendererParams: { suppressCount: true },
      resizable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      width: 250,
    };
    this.groupDefaultExpanded = -1;
    this.getDataPath = (data) => {
      return data.path;
    };
    // this.sideBar = {
    //   toolPanels: [
    //     {
    //       id: 'filters',
    //       labelDefault: 'Filters',
    //       labelKey: 'filters',
    //       iconKey: 'filter',
    //       toolPanel: 'agFiltersToolPanel',
    //     },
    //   ],
    // }
  },
  watch: {
    selectedViewId() {
      this.onGridReady(this.gridOptions);
    },
    filterWatcher() {
      // NOTE: don't load rows on initial filter load, this is already done via `onGridReady`
      // NOTE: we also have to reload months as they may change as well
      if (this.filterWatcher > 1) {
        this.loadMonthColumns();
        this.loadRows();
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  computed: {
    ...mapGetters("filterControl", ["filters"]),
    ...mapState("filterControl", ["filterWatcher"]),
    ...mapState("userView", ["selectedRowType", "selectedViewId"]),
    noRowsTemplate() {
      if (this.loading) {
        return this.loadingTemplate;
      } else {
        return `<span>No Rows to Show</span>`;
      }
    },
  },
  methods: {
    ...mapMutations("userView", ["setSelectedRowType"]),
    onGridReady(params) {
      // replace stale references with updated ones
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      if (this.view != null && !this.loading) {
        console.info(this.view);
        this.loading = true;
        getRemoteColumns(this.view.id)
          .then((moduleBlob) => {
            // get blob
            // create script element
            // @onload use loaded global function from script
            let container = this.$refs.scriptContainer;
            var moduleUrl = URL.createObjectURL(moduleBlob);
            let scriptEl = document.createElement("script");
            scriptEl.type = "text/javascript";
            // NOTE: need eslint disable line because of dynamic function
            scriptEl.onload = () => {
              __hydrateColumns(this, utilitiesModule);
            }; // eslint-disable-line
            scriptEl.src = moduleUrl;
            container.appendChild(scriptEl);
            URL.revokeObjectURL(moduleUrl);
          })
          .then(() => {
            // load road async <can assume rows will already be set in backend>
            // NOTE: we can load month columns and row columns at same time
            this.loadMonthColumns();
            this.loadRows();
          })
          .catch((error) => {
            this.loading = false;
            console.error(error);
          });
      }
    },
    setTreeMode(newTreeMode) {
      if (this.treeData != newTreeMode) {
        this.treeData = newTreeMode;
        this.sideBar = !newTreeMode;
        this.pivotMode = !newTreeMode;
        this.gridColumnApi.setPivotMode(!newTreeMode);
        // strictly speaking we don't need to do this in nextTick
        this.$nextTick(() => {
          this.showGrid = false;
          this.gridApi = null;
          this.gridColumnApi = null;

          // we always have to do this in nextTick
          this.$nextTick(() => {
            this.showGrid = true;
          });
        });
      }
    },
    loadRows() {
      //this.loading = true;
      this.gridApi.showLoadingOverlay();
      this.set = new Set();
      getRemoteRow(this.view.id)
        .then((rowData) => {
          this.view;
          if (rowData.length > 0) {
            this.rowData = [];
            this.rowData = rowData;
            this.gridApi.hideOverlay();
            this.loading = false;
            this.defaultExportParams.fileName = this.rowData[0].description;
          }
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
    loadMonthColumns() {
      getMonthColumns(this.view.id).then((monthsData) => {
        if (monthsData.size != 0) {
          let container = this.$refs.scriptContainer;
          var moduleUrl = URL.createObjectURL(monthsData);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";

          scriptEl.onload = () => {
            // let _debug_columnWithMonths = this.columnDefs.filter(x => x.headerName && Date.parse(x.headerName) );
            // NOTE: remove all month related columns, before adding correct ones

            let columnWithoutMonths = this.columnDefs.filter(
              (x) => x.headerName && !Date.parse(x.headerName)
            );
            this.columnDefs = [...columnWithoutMonths];
            //if (typeof __hydrateMonthsColumns !== "undefined") {
            __hydrateMonthsColumns(this, utilitiesModule); // eslint-disable-line
            //}
            // always applyColumnState after getting monthColumns
            this.reloadColumnState();
          };
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        }
        this.gridApi.hideOverlay();
      });
    },
    resetMonthColumnsOrder() {
      // gets current `columnState`
      // sorts it to ensure monotonic months
      // apply new state with order
      if (this.gridColumnApi == null && this.showGrid) {
        // not a real issue, leaving it as a warning, probably can be ignored or fixed by changing reset logic
        console.warn("[WARN] resetMonthColumnsOrder :: gridColumnApi == null");
        return;
      }
      let columnState = this.gridColumnApi.getColumnState();

      // forecast > actual > variance (should order also be enforced?)
      let monthRe = /m(\d+)(forecast|actual|variance)$/;
      columnState.sort((a, b) => {
        let aIsMonth = a.colId.match(monthRe);
        let bIsMonth = b.colId.match(monthRe);
        if (
          aIsMonth != null &&
          bIsMonth != null &&
          aIsMonth.length == 3 &&
          bIsMonth.length == 3
        ) {
          // sort based on month
          let aMonthIndex = parseInt(aIsMonth[1]) || 0;
          let bMonthIndex = parseInt(bIsMonth[1]) || 0;

          let monthDiff = aMonthIndex - bMonthIndex;
          return monthDiff;
          // TODO: ?if required? to enfore f>a>v order if diff=0, order by secondary match`isMonth[2]`
          /*
                        if (monthDiff == 0) {
                          // TODO
                        } else {
                          return monthDiff;
                        }*/
        } else {
          return (aIsMonth ? 1 : 0) - (bIsMonth ? 1 : 0);
        }
      });

      this.gridColumnApi.applyColumnState({
        state: columnState,
        applyOrder: true,
      });

      /* API that may be useful in the future to do similar things
                //let columnWithoutMonths = this.columnDefs.filter(x => x.headerName && !Date.parse(x.headerName) );
                //let columnWithMonths = this.columnDefs.filter(x => x.headerName && Date.parse(x.headerName) );

      :applyColumnDefOrder="true",
        then modify this.columnDefs = [...columnWithoutMonths, ...columnWithMonths];
        or this.gridApi.setColumnDefs([...columnWithoutMonths, ...columnWithMonths]);

      seems to be buggy, but seems to work if no animation/current work is being done:
        this.gridColumnApi.moveColumn("m1forecast",0);
        this.gridColumnApi.moveColumns([...columnWithMonths],0);
      */
      // console.log("resetMonthColumnsOrder", columnState);
    },
    reloadColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(this.view.id, 0)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridColumnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
            // console.log("applyColumnState");
          } else {
            // NOTE: columnState has been reset to default
            if (this.loading == false) {
              // NOTE: this will fully reset columns to blank state

              if (typeof __hydrateColumns !== "undefined") {
                console.log("detected column reset: __hydrateColumns");
                __hydrateColumns(this, utilitiesModule); // eslint-disable-line
              } else {
                console.error("failed column reset: __hydrateColumns");
              }

              if (typeof __hydrateMonthsColumns !== "undefined") {
                console.log("detected column reset: __hydrateMonthsColumns");
                // let _debug_columnWithMonths = this.columnDefs.filter(x => x.headerName && Date.parse(x.headerName) );
                // NOTE: remove all month related columns, before adding correct ones
                let columnWithoutMonths = this.columnDefs.filter(
                  (x) => x.headerName && !Date.parse(x.headerName)
                );
                this.columnDefs = [...columnWithoutMonths];
                __hydrateMonthsColumns(this, utilitiesModule); // eslint-disable-line
              } else {
                console.error("failed column reset: __hydrateMonthsColumns");
              }
              // NOTE: for some reason this is required, TODO: figure out why Vue reactivity seems broken?
              this.gridColumnApi.resetColumnState();
              this.gridColumnApi.resetColumnGroupState();
            } else {
              console.warn("cannot reset column while loading", resp);
            }
          }
          this.loading = true;

          if (this.view.viewTypeId == 3 || this.view.viewTypeId == 8) {
            // TODO: hardcoded for now, replace with appropriate mechanism later // view.id == 1
            this.setTreeMode(false);
          } else {
            this.setTreeMode(true);
          }
          if (this.loading) {
            this.gridApi.showLoadingOverlay();
          }
          this.loading = false;
          this.resetMonthColumnsOrder();
        })
        .catch((error) => {
          console.error("Failed to Load Column State: ", error);
          this.loading = false;
        });
      this.gridApi.hideOverlay();
    },
    onCellValueChanged(params) {
      this.set.add(params.node.id);
    },
    onSubmit() {
      console.log("submitted");
      var data = [];
      this.set.forEach((id) => {
        data.push(this.gridApi.getRowNode(id).data);
      });
      console.log("submitted", data);
      postChanges(this.view.id, data)
        .then(() => {
          this.set.clear();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onColumnChanged: debounce(function () {
      this.onColumnStateSubmit();
    }, 300),
    onColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridColumnApi.getColumnState();
      postColumnState({
        userViewId: this.view.id,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },
    onSelectionChanged(event) {
      var selectedRows = event.api.getSelectedNodes();
      this.setSelectedRowType(selectedRows[0].data.type);
      //console.log(this.setSelectedRowType);
    },
  },
};

window.rowGroupCallback = function rowGroupCallback(params) {
  return params.node.key;
};

window.getIndentClass = function getIndentClass(params) {
  var indent = 0;
  var node = params.node;
  while (node && node.parent) {
    indent++;
    node = node.parent;
  }
  return "indent-" + indent;
};
</script>

<style scoped lang="scss"></style>
