<template>
  <div
    style="height: 100%; background-color: white"
    ref="scriptContainerschedulingData"
  >
    <div class="line" style="margin-top: -0.17%; margin-left: 0.2%"></div>
    <div class="columns" style="height: 100%">
      <div
        class="column"
        style="margin-left: 1%; margin-right: 1%; height: 100%; width: 100%"
      >
        <SchedulingBar />

        <ag-grid-vue
          class="ag-theme-balham ag-default-layout"
          rowSelection="multiple"
          enableCellChangeFlash="true"
          stopEditingWhenGridLosesFocus="true"
          :treeData="true"
          :getDataPath="getDataPath"
          :gridOptions="gridOptions"
          :rowData="rowData"
          :suppressClickEdit="ganttAccess"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :components="components"
          :autoGroupColumnDef="autoGroupColumnDef"
          :enableRangeSelection="true"
          :undoRedoCellEditing="true"
          :overlayLoadingTemplate="overlayLoadingTemplate"
          @cell-clicked="onCellClicked"
          @cell-value-changed="onCellValueChanged"
          @grid-ready="onGridReady"
          @sort-changed="onColumnChanged"
          @column-resized="onColumnChanged"
          @column-visible="onColumnChanged"
          @column-row-group-changed="onColumnChanged"
          @column-value-changed="onColumnChanged"
          @column-moved="onColumnChanged"
          @column-pinned="onColumnChanged"
        >
        </ag-grid-vue>
        <!-- :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
            @cell-value-changed="onCellValueChanged"
            @selection-changed="onSelectionChanged"
            @grid-ready="onGridReady" -->
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import * as moment from "moment";
import SchedulingBar from "./schedulingDataToolbar.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import BtnCellRenderer from "../../assets/btn-cell-renderer.vue";
import * as utilitiesModule from "@/assets/utilities.js";
import { debounce } from "lodash";
import {
  getColumnDef,
  postColumnState,
  getColumnState,
} from "@/api/networking.js";
export default {
  name: "schedulingDataView",
  props: {},
  data() {
    return {
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      autoGroupColumnDef: {
        headerName: "P3M Structure",
        colId: "SId",
        sort: "asc",
        sortable: true, // naming your grouped column
        showRowGroup: true,
      },
      components: {
        CustomCombobox: getComboBoxModelCellRenderer(),
        datePicker: getScheduleingDatePicker(),
        integerCellEditor: getIntegerCellEditor(),
        decimalCellEditor: getDecimalCellEditor(),
        lookupCellEditor: getLookupCellEditor(),
        iconCellRenderer: getIconCellRenderer(),
      },
      defaultColDef: null,
      rowData: null,
      componentObj: this,
      gridOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
          ],
        },
        getRowNodeId: (data) => data.uid,
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
        processDataFromClipboard: (params) => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          let selectedNodes = this.gridOptions.api.getSelectedNodes();
          this.gridOptions.api.forEachNode((node) => {
            if (node.data.parentId === selectedNodes[0].data.parentId) {
              node.setSelected(true);
              node.setExpanded(false);
            }
          });
          let selectedData = selectedNodes.map((node) => node.data);
          let selectedKey = selectedData[0].id;
          selectedData = selectedData[0].parentId;

          selectedNodes = this.tempChildData;
          /*   this.gridOptions.api.getModel().gridOptionsWrapper.gridOptions
              .rowData; */
          const indexes = selectedNodes
            .map((selectedNodes, i) =>
              selectedNodes.parentId === selectedData ? i : null
            )
            .filter((i) => i !== null);

          const focusedCell = this.gridOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;
          const getParentId =
            this.gridOptions.api.getSelectedNodes(focusedIndex);

          var tempId = getParentId.findIndex((x) => x.data.id == selectedKey);
          if (tempId + params.data.length - 1 > indexes.length - 1) {
            const addRowCount = params.data.length - (indexes.length - tempId);

            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            var temp = null;

            /* if (getParentId[tempId].data.id < 0) {
              this.i = getParentId[tempId].data.id;
            }
            if (getParentId[tempId].data.path.length > 1) {
              temp = getParentId[tempId].data.path.length;
              temp = getParentId[tempId].data.path.slice(0, temp - 1);
            }
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }

            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];

            //rowsToAdd = rowsToAdd.filter(Boolean);
            rowsToAdd.map(r => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map(i => {
                row[currColumn.colDef.field] = i;
                currColumn = this.gridOptions.columnApi.getDisplayedColAfter(
                  currColumn
                );
              });
              row.program = this.getParentSelectedObj.program;
              row.project = this.getParentSelectedObj.project;
              row.scheduleHeaderTypeId = this.getParentSelectedObj.scheduleHeaderTypeId;
              row.id = ;
              /*  if (row.parentId != null) {
                if (getParentId[0].data.id < 0) {
                  row.path = temp.concat([row.id]); //[row.parentId, row.id];
                } else {
                  if (getParentId[0].data.path.length < 2) {
                    row.path = [row.parentId, row.id];
                  } else {
                    row.path = temp.concat([row.id]);
                  }
                }
              } else {
                row.path = [row.id];
              } */
            /* row.extraColumnValues = [];
              newRowData.push(row);
            });
            this.gridOptions.api.applyTransactionAsync({
              add: newRowData,
            });
            */ this.gridOptions.api.redrawRows();
            this.gridOptions.api.refreshCells();
          }
          var undoSize = this.gridOptions.api.getCurrentUndoSize();
          if (undoSize == 0) {
            this.mutateParentUndostate(true);
          }
          return params.data;
        },
      },
      overlayLoadingTemplate: null,
      deleteSettings: {
        withBackdrop: true,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "center-center",
      },
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
    };
  },
  components: {
    AgGridVue,
    SchedulingBar,
    btnCellRenderer: BtnCellRenderer, // eslint-disable-line vue/no-unused-components
  },
  watch: {
    //This should be updated when a menu option is selected
    selectedScheduleDataType() {
      //Load the rows for the selected data type
      this.rowData = [];
      this.gridOptions.api.setRowData(null);
      this.gridOptions.api.redrawRows();
      this.gridOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.loadAllData();
      this.gridOptions.api.redrawRows();
      this.gridOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },
    entitiesLoaded() {
      //This flag is set once the web call is resolved and the data is loaded
      this.rowData = [];
      this.gridOptions.api.setRowData(null);
      this.gridOptions.api.redrawRows();
      this.gridOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      if (this.entitiesLoaded) this.rowData = this.allEntities;
      SetCalendarvals(this.getcalendarData);
      this.gridOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    UndoCounter() {
      this.gridOptions.api.undoCellEditing();
    },
    RedoCounter() {
      this.gridOptions.api.redoCellEditing();
    },
    AddCounter() {
      this.gridOptions.api.applyTransaction({
        add: this.getParentAddRow,
      });
      this.gridOptions.api.redrawRows();
      this.gridOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      setTimeout(() => {
        this.SetFocus();
      }, 1000);
    },

    ColdefCounter() {
      // this.columnDefs = this.getParentGridColumn;
      this.getParentColDefs.forEach((row) => {
        this.columnDefs.push(row);
      });
      // this.reloadColumnState();
      // this.columnDefs = this.getParentColDefs;
    },

    SaveCounter() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.SaveCounter > 0) {
        let newRecord = [];
        this.gridOptions.api.refreshCells();
        this.gridOptions.api.forEachNode((node) => {
          if (node.data != undefined) {
            if (node.data.rowUpdated) {
              newRecord.push(node.data);
            }
          }
        });
        if (newRecord.length == 0) {
          this.gridOptions.api.redrawRows();
          this.gridOptions.api.refreshCells();
          newRecord = [];
          this.$vToastify.error("Code Value is empty or duplicate!");
        } else {
          this.mutateRecord({ returnedNewData: newRecord });
          this.saveData();
        }
      }
    },

    DataWatcher() {
      let tempdata = [];
      this.gridOptions.api.forEachNode((node) => {
        if (node.data != undefined) {
          if (node.data.rowUpdated) {
            node.data.rowUpdated = false;
            tempdata.push(node.data);
          }
        }
      });
      this.gridOptions.api.applyTransaction({
        remove: tempdata,
      });
      //console.log(tempdata, this.getSavedData);
      this.gridOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.gridOptions.api.applyTransaction({
        add: this.getSavedData,
      });

      this.gridOptions.api.redrawRows();
      this.gridOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      if (this.CopyRows) {
        this.mutateGanttCopy();
      }
      this.$vToastify.success("Data successfully saved!");
    },

    isDelete() {
      if (this.isDelete > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.gridOptions.api.redrawRows();
        this.gridOptions.api.refreshCells();
        this.$vToastify.success("Row Deleted Successfully!");
      }
    },

    DeleteCounter() {
      if (this.DeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes = this.gridOptions.api.getSelectedNodes();
              let selectedData = selectedNodes.map((node) => node.data);
              let deleteIds = [];
              let indices = [];
              selectedData.forEach((x) => {
                deleteIds.push(x.id);
              });
              indices = deleteIds.filter((x) => x > 0);
              if (indices.length > 0) {
                this.DeleteParentNode({ Id: indices });
              }
              this.gridOptions.api.applyTransaction({
                remove: selectedData,
              });
              indices = [];
              selectedNodes = [];
              deleteIds = [];
              selectedData = [];
            }
          });
      }
    },
    SaveRelation() {
      let tempData = [];
      // tempStoreData = [];
      //console.log(this.getScheduleHeaderRelation);

      this.gridOptions.api.forEachNode((node) => {
        if (node.data != undefined) {
          if (node.data.id == this.getParentSelectedId) {
            node.data.parentId = this.getScheduleHeaderRelation[0].parentId;
            node.data.path = this.getScheduleHeaderRelation[0].path;
            node.data.projects = this.getScheduleHeaderRelation[0].projects;
            tempData.push(node.data);
          }
        }
      });

      this.gridOptions.api.applyTransaction({
        update: tempData,
      });

      /*  this.getScheduleHeaderRelation.forEach(projRow => {
        tempData[0].parentId = projRow.parentId;
        tempData[0].path = projRow.path;
        tempData[0].project = projRow.project;
        tempStoreData.push(tempData[0]);
      }); */
      // console.log(tempStoreData);
      /*  this.gridOptions.api.applyTransaction({
        add: tempStoreData,
      }); */
      this.gridOptions.api.redrawRows();
      this.gridOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },
    isCopyGanttRecordStarted() {
      this.restCopyButtonState();
      if (this.isCopyGanttRecordStarted) {
        this.gridOptions.api.showLoadingOverlay();
      } else {
        this.gridOptions.api.hideOverlay();
      }
    },
  },
  methods: {
    ...mapActions("schedulingDataManager", [
      "loadAllData",
      "setSelectedEntity",
      "LoadParentExtraColumn",
      "saveData",
      "DeleteParentNode",
      "LoadCalendarColumn",
    ]),
    ...mapMutations("schedulingDataManager", [
      "setParentObj",
      "mutateParentUndostate",
      "mutateParentRedostate",
      "mutateRecord",
      "restCopyButtonState",
    ]),

    ...mapMutations("activities", ["mutateGanttCopy"]),

    SetFocus() {
      let tempRowIndex = 0;
      this.gridOptions.api.forEachNode((node) => {
        //if (node != undefined && node.data != undefined && node.data.id < 0) {
        if (node.data.id === 1) {
          node.setExpanded(true);
          if (node.data.id < 0) {
            tempRowIndex = node.rowIndex;
          }
        }
      });
      //console.log(tempRowIndex);
      this.gridOptions.api.ensureIndexVisible(tempRowIndex);
      this.gridOptions.api.setFocusedCell(tempRowIndex, "code");
    },

    onCellClicked(event) {
      if (event.data.parentId != null) {
        var selectedRowObjs = event.data;
        if (selectedRowObjs != undefined) {
          //console.log(selectedRowObjs);
          this.setParentObj({ row: selectedRowObjs });
          if (event.column.colId == "ag-Grid-AutoColumn") {
            this.setSelectedEntity(selectedRowObjs.id);
            localStorage.setItem(
              "GanttElements",
              JSON.stringify(selectedRowObjs)
            );
            this.$router.push("/gantt");
          }
        }
      }
    },

    onCellValueChanged(params) {
      if (params.node.data.parentId > 0) {
        params.node.data.rowUpdated = true;
        var undoSize = params.api.getCurrentUndoSize();
        var redoSize = params.api.getCurrentRedoSize();
        if (undoSize == 0) {
          this.mutateParentUndostate(false);
          params.node.data.rowUpdated = false;
        } else {
          this.mutateParentUndostate(true);
        }
        if (redoSize == 0) {
          this.mutateParentRedostate(false);
        } else {
          this.mutateParentRedostate(true);
          params.node.data.rowUpdated = false;
        }
        //console.log(params.node.data.parentId);
        if (params.column.colId.match("et_")) {
          let colvalue = params.column.colId.split("_");
          let tempIndex = -1;

          tempIndex = params.node.data.extraColumnValues.findIndex(
            (y) => y.ScheduleHeaderExtraColumnId == parseInt(colvalue[1])
          );
          params.node.data.rowUpdated = true;
          if (tempIndex < 0) {
            params.node.data.extraColumnValues.push({
              ScheduleHeaderId: params.node.data.id,
              ScheduleHeaderExtraColumnId: colvalue[1],
              value: params.newValue,
            });
          } else {
            params.node.data.extraColumnValues[tempIndex].value =
              params.newValue;
          }
        } else if (params.column.colId.match("calendarId")) {
          if (params.newValue != "") {
            params.data.calendarId = params.newValue;
            params.data.tempcalendarId = this.getcalendarData.find(
              (x) => x.description == params.newValue
            ).id;
          } else {
            params.data.calendarId = params.newValue;
            params.data.tempcalendarId = null;
          }
        }

        params.api.refreshCells({ force: true, suppressFlash: true });
      }
    },

    onGridReady(params) {
      getColumnDef(this.selectedDataType.componentId)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerschedulingData;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          this.LoadParentExtraColumn().then(() => {
            this.loadAllData();
          });
          //
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    reloadColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, this.selectedDataType.componentId)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnChanged: debounce(function () {
      this.onColumnStateSubmit();
    }, 300),

    onColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridOptions.columnApi.getColumnState();

      postColumnState({
        componentId: this.selectedDataType.componentId,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },
  },
  computed: {
    ...mapGetters("schedulingDataManager", [
      "allEntities",
      "entitiesLoaded",
      "selectedScheduleDataType",
      "getParentColDefs",
      "getParentAddRow",
      "getParentSelectedObj",
      "getcalendarData",
      "getParentSelectedId",
      "getSavedData",
    ]),

    ...mapState("activities", ["isCopyGanttRecordStarted", "isCopyDone"]),
    ...mapGetters("dataManager", ["selectedDataType"]),
    ...mapGetters("relationControl", ["getScheduleHeaderRelation"]),
    ...mapGetters("jiveComponents", ["ganttAccess"]),
    ...mapState({
      RedoCounter: (state) =>
        state.schedulingDataManager.currentParentRedoWatcher,
      UndoCounter: (state) =>
        state.schedulingDataManager.currentParentUndoWatcher,
      AddCounter: (state) =>
        state.schedulingDataManager.currentParentAddRecordCounter,
      DeleteCounter: (state) =>
        state.schedulingDataManager.currentParentDeleteRecordCounter,
      isDelete: (state) => state.schedulingDataManager.isDelete,
      SaveCounter: (state) =>
        state.schedulingDataManager.currentParentSaveRecordCounter,
      DataWatcher: (state) => state.schedulingDataManager.datawatcher,
      ColdefCounter: (state) =>
        state.schedulingDataManager.currentParentColumDefCounter,
      SaveRelation: (state) => state.relationControl.saveScheduleCounter,
      CopyRows: (state) => state.schedulingDataManager.isCopyButtonClicked,
    }),
  },
  created() {
    this.overlayLoadingTemplate = `<span class="ag-overlay-loading-center"> Please wait while your rows are loading........</span>`;
  },
  mounted() {
    //this.columnDefs = this.getParentColDefs;
    this.rowData = [];
    this.LoadCalendarColumn();
  },

  beforeDestroy() {
    console.log("Main Vue destroyed");
  },

  beforeMount() {
    /*Set Grid Tree Collumn */
    //  this.components = { iconCellRenderer: getIconCellRenderer() };
    this.defaultColDef = Object.assign({}, this.defaultColDef, {
      width: 180,
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      resizable: true,
    });
    this.autoGroupColumnDef = {
      headerName: "View Schedule",
      field: "uid",
      rowDrag: true,
      suppressPaste: true,
      //   cellRendererFramework:"ScheduleCellRenderer",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: "iconCellRenderer",
      },
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      width: 160,
      icons: {
        sortAscending: '<i class="fa fa-sort-alpha-up"/>',
        sortDescending: '<i class="fa fa-sort-alpha-down"/>',
        menu: '<i class="fa fa-caret-square-down"/>',
      },
    };
    this.getDataPath = (data) => {
      return data.path;
    };
    //this.loadAllData();
    /**
     * Load Column Defination
     * Load All CostElement's Data
     * Set Basic Functions Here
     */
  },
};

window.getIconCellRenderer = function getIconCellRenderer() {
  function IconCellRenderer() {}
  IconCellRenderer.prototype.init = function (params) {
    var tempDiv = document.createElement("div");
    params.api.refreshCells();
    tempDiv.innerHTML =
      params.node.data != undefined
        ? params.node.data.parentId > 0
          ? '<img src="Icons/schedule2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
          : params.node.data.parentId == null && params.node.data.rowType == 2
          ? '<img src="Icons/projects2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
          : params.node.data.parentId == null && params.node.data.rowType == 1
          ? '<img src="Icons/programs2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
          : params.node.data.parentId == null && params.node.data.rowType == 0
          ? '<img src="Icons/portfolio2.svg"; style="margin-top:5px; height:20px; width:20px;"/>'
          : null
        : null;
    this.eGui = tempDiv.firstChild;
  };
  IconCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return IconCellRenderer;
};
var calendarTypes = null;

window.SetCalendarvals = function SetCalendarvals(data) {
  calendarTypes = data;
};

window.getComboBoxModelCellRenderer = function getComboBoxModelCellRenderer() {
  function CustomCombobox() {}
  CustomCombobox.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var calendarTypeIDs = calendarTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );

    this.eOptionVal = document.createElement("option");
    //Statical set data in grid ComboBox
    this.eOptionVal.text = "";
    this.eOptionVal.value = "";
    this.eInput.appendChild(this.eOptionVal);

    calendarTypeIDs.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      if (x.description == params.value) {
        this.eOptionVal.selected = true;
      }
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  CustomCombobox.prototype.getGui = function () {
    return this.eGui;
  };

  CustomCombobox.prototype.getGui = function () {
    return this.eInput;
  };
  CustomCombobox.prototype.afterGuiAttached = function () {
    this.eInput.focus();
  };
  CustomCombobox.prototype.getValue = function () {
    return this.eInput.value;
  };
  CustomCombobox.prototype.destroy = function () {};
  CustomCombobox.prototype.isPopup = function () {
    return false;
  };
  return CustomCombobox;
};

window.getIntegerCellEditor = function getIntegerCellEditor() {
  function IntegerPicker() {}
  IntegerPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.setAttribute("step", "1");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };

  /*  IntegerPicker.prototype.isKeyPressedNavigation = function(event) {
    return event.keyCode !=110;
  }; */
  IntegerPicker.prototype.getGui = function () {
    return this.eInput;
  };
  IntegerPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  IntegerPicker.prototype.getValue = function () {
    return parseInt(this.eInput.value);
  };
  IntegerPicker.prototype.destroy = function () {};
  IntegerPicker.prototype.isPopup = function () {
    return false;
  };
  return IntegerPicker;
};

window.getDecimalCellEditor = function getDecimalCellEditor() {
  function DecimalPicker() {}
  DecimalPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };
  DecimalPicker.prototype.getGui = function () {
    return this.eInput;
  };
  DecimalPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  DecimalPicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  DecimalPicker.prototype.destroy = function () {};
  DecimalPicker.prototype.isPopup = function () {
    return false;
  };
  return DecimalPicker;
};

window.getLookupCellEditor = function getLookupCellEditor() {
  function Lookuppicker() {}
  Lookuppicker.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var CostModelTypeFilter = CostModelTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );
    CostModelTypeFilter.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  Lookuppicker.prototype.getGui = function () {
    return this.eInput;
  };
  Lookuppicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Lookuppicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Lookuppicker.prototype.destroy = function () {};
  Lookuppicker.prototype.isPopup = function () {
    return false;
  };
  return Lookuppicker;
};

window.getScheduleingDatePicker = function getScheduleingDatePicker() {
  function ScheduleingDatePicker() {}
  var tempDatevalue = null;
  ScheduleingDatePicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "date");
    if (params.value.length > 0) {
      this.eInput.value = new Date(params.value).toISOString().substr(0, 10);
    }
    tempDatevalue = params.value; // moment(this.getSelectedRowObject.endDate).toISOString().substr(0, 10);

    this.eInput.classList.add("input");
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
    /*  $(this.eInput).ScheduleingDatePicker({
      constrainInput: true, // prevent letters in the input field
      autoSize: true, // automatically resize the input field
      dateFormat: "dd/mm/yyyy",
      changeMonth: true,
      changeYear: true,
      yearRange: "1930:" + new Date().getFullYear(),
    }); */
  };
  ScheduleingDatePicker.prototype.getGui = function () {
    return this.eInput;
  };
  ScheduleingDatePicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  ScheduleingDatePicker.prototype.getValue = function () {
    if (this.eInput.value.length > 0) {
      return moment(this.eInput.value).format("YYYY-MM-DD");
    } else {
      return tempDatevalue;
    }
  };
  ScheduleingDatePicker.prototype.destroy = function () {};
  ScheduleingDatePicker.prototype.isPopup = function () {
    return false;
  };
  return ScheduleingDatePicker;
};
</script>
<style scoped lang="scss">
.line {
  width: 99.5%;
  height: 1%;
  border-bottom: 1px solid black;
  position: relative;
}
</style>
