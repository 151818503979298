const state = {
  dashboardMenuTypes: [
    {
      id: 0,
      name: "Portfolio",
      iconClass: "fas fa-folder-open",
      clientId: 3,
      viewId: 26,
    },
    {
      id: 1,
      name: "Project",
      iconClass: "fas fa-diagram",
      clientId: 3,
      viewId: 30,
    },
    {
      id: 2,
      name: "Portfolio",
      iconClass: "fas fa-folder-open",
      clientId: 0,
      viewId: 60,
    },
    {
      id: 3,
      name: "Project",
      iconClass: "fas fa-diagram",
      clientId: 0,
      viewId: 61,
    },
    {
      id: 4,
      name: "Portfolio",
      iconClass: "fas fa-folder-open",
      clientId: 4,
      viewId: 67,
    },
    {
      id: 5,
      name: "Project",
      iconClass: "fas fa-diagram",
      clientId: 4,
      viewId: 66,
    },
  ],
  selectedDashboardTypeId: -1,
  retval: null,
};

const mutations = {
  mutateSelectedDashboardType(state, { selectedDashboardTypeId }) {
    state.selectedDashboardTypeId = selectedDashboardTypeId;
    state.retval = state.dashboardMenuTypes.find(
      (dt) => dt.id == selectedDashboardTypeId
    );
  },
  mutateSelectedDashboardTypeId(state, selectedDashboardTypeId) {
    console.log("selectedDashboardTypeId", selectedDashboardTypeId);
    var selectedDBType = state.dashboardMenuTypes.find(
      (dt) => dt.viewId == selectedDashboardTypeId
    );
    console.log("selectedDBType", selectedDBType);
    state.selectedDashboardTypeId = selectedDBType.id;
    // state.selectedDashboardTypeId = state.dashboardMenuTypes.find(
    //   dt => dt.viewId == selectedDashboardTypeId
    // );
  },
};
const actions = {
  setSelectedDashboardId({ commit }, dashboardId) {
    console.log("dashboardId", dashboardId);
    commit("mutateSelectedDashboardTypeId", dashboardId);
    console.log(
      "mutatedSelectedDashboardType: ",
      state.selectedDashboardTypeId
    );
  },
};
const getters = {
  selectedDashboardType({ selectedDashboardTypeId, dashboardMenuTypes }) {
    state.retval = dashboardMenuTypes.find(
      (dt) => dt.id == selectedDashboardTypeId
    );

    return state.retval;
  },
  selectedDashboardValues() {
    state.retval = state.dashboardMenuTypes.find(
      (dt) => dt.id == state.selectedDashboardTypeId
    );

    return state.retval;
  },
};
export const dashboardMenu = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
