import { post } from "../api/user-auth.js";
import {
  getAllExtraColumnDetails,
  DeleteElement,
  getByPerformanceItemId,
} from "@/api/networking.js";

let tempProjectParent = [
    {
      uid: 1,
      id: 1,
      code: "Unassigned ",
      description: "Unassigned ",
      rowType: 2,
      path: [1],
    },
  ],
  tempProgramParent = [],
  tempPortfolioParent = [],
  temprow = null,
  tempnewId = -1;
/* Create Tree Path */
let data = [],
  i = -1,
  tempData = null;
function getParentLoop(id, path) {
  let p = data.filter((i) => i.id == id)[0];
  if (p.parentId) {
    path.unshift(p.id);
    return getParentLoop(p.parentId, path);
  } else {
    path.unshift(p.id);
  }
  return path;
}

function setpath(data) {
  data.map((i) => {
    i.path = [];
    if (i.parentId) {
      i.path.push(i.id);
      getParentLoop(i.parentId, i.path);
    } else {
      i.path.push(i.id);
    }
    return i;
  });
  return data;
}
const state = {
  /* Module Title*/
  APIName: "performance",
  /*left Side(Parent grid details)*/
  /*CurrentData and details*/
  currentParentData: [],
  currentParentTempData: [],
  currentParentId: null,
  currentParentSelectedObj: [],
  currentParentID: [],
  currentselectedParentRows: [],
  cuurentcopyRowvalues: [],

  /*Main Data Confing Tools*/
  currentParentLoadRecordCounter: 0,
  currentParentAddRecordCounter: 0,
  currentParentSubRecordCounter: 0,
  currentParentDeleteRecordCounter: 0,
  currentParentSaveRecordCounter: 0,

  /*Parent Node Grid Config*/
  currentParentUndoWatcher: 0,
  currentParentRedoWatcher: 0,
  currentParentredostate: false,
  currentParentundostate: false,

  /*Parent Node Grid Config*/
  isRelationship: false,
  currentCostModelType: [],

  /*Commonn Config*/
  isSave: 0,
  isDelete: 0,
  datawatcher: 0,
  SaveError: 0,
  duplicateRecord: 0,

  /* Parent Column Def */
  currentParentColDef: [
    /*  {
      headerName: "Code",
      field: "code",
      editable: function(params) {
        if (params.data.parentId > 0) {
          return true;
        } else {
          return false;
        }
      },
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data != undefined) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
        }
        return null;
      },
      width: 90,
      suppressSizeToFit: true,
      /* cellRenderer: function(params) {
        if (params.data.rowUpdated == "Duplicate") {
          let eIconGui = document.createElement("span");
          return (eIconGui.innerHTML =
            '<i class="fas fa-exclamation" style="color:red;"></i>' +
            " " +
            params.data.code);
        }
        return params.data.code;
      },
    },
    {
      headerName: "Description",
      field: "description",
      editable: function(params) {
        if (params.data.parentId > 0) {
          return true;
        } else {
          return false;
        }
      },
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data != undefined) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
        }
        return null;
      },
      width: 300,
    }, */
  ],
  loadStructureCounter: 0,
  currentGridColDefCount: 0,
  currentParentGridColDefCountCounter: 0,
  tempCalenarData: [],
  tempCalenderCounter: 0,
  tempResourceTypeData: [],
  tempResourceCounter: 0,
  currtempData: [],

  /*CurrentData and details*/
  currentChildrenData: [],
  currentchildTempData: [],
  currentChildrenId: null,
  currentChildrenSelectedObj: [],
  currentselectedChildrenRows: [],

  /*Main Data Confing Tools*/
  currentChildrenLoadRecordCounter: 0,
  currentChildrenAddRecordCounter: 0,
  currentChildrenSubRecordCounter: 0,
  currentChildrenDeleteRecordCounter: 0,
  currentChildrenSaveRecordCounter: 0,

  /*Children Node Grid Config*/
  currentChildrenUndoWatcher: 0,
  currentChildrenRedoWatcher: 0,
  currentChildrenredostate: false,
  currentChildrenundostate: false,

  currentChildrenGridColDefCountCounter: 0,

  currentChildrenColDef: [
    /*   {
      headerName: "Code",
      field: "code",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 90,
      suppressSizeToFit: true,
      cellEditor: "PerformanceMetricLookupValue",
    },
    {
      headerName: "Description",
      field: "description",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 300,
      cellEditor: "PerformanceMetricLookupValue",
    },
    {
      headerName: "ResolutionPlan",
      field: "resolutionPlan",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 100,
    },
    {
      headerName: "PerformanceStatusId",
      field: "performanceStatusId",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.colour) {
          return { "background-color": params.data.colour };
        }
        return null;
      },
      width: 100,
      cellEditor: "PerformanceStatusLookupValue",
    }, */
  ],
  performanceMetricsValues: [],
  performanceStatusValues: [],
  tempSaveData: [],
  totalRowLength: 0,
};

const actions = {
  /*loadPerformanceMetric */
  async loadperformanceMetric({ dispatch, commit }) {
    getAllExtraColumnDetails("PerformanceMetric")
      .then((rowData) => {
        if (rowData.succeeded) {
          state.performanceMetricsValues = rowData.data;
          //commit("setChildrenData", { returnedData: rowData.data });
          i = -2;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
  /*loadPerformanceStatus */
  async loadperformanceStatus({ dispatch, commit }) {
    getAllExtraColumnDetails("PerformanceStatus")
      .then((rowData) => {
        if (rowData.succeeded) {
          state.performanceStatusValues = rowData.data;
          i = -2;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /*LoadData */
  async loadperformanceHeader({ dispatch, commit }) {
    getAllExtraColumnDetails("PerformanceHeader")
      .then((rowData) => {
        if (rowData.succeeded) {
          commit("setParentData", { returnedData: rowData.data });
          i = -2;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /*LoadPerformanceItemData */
  async loadPerformanceItem({ dispatch, commit }, { performanceHeaderId }) {
    getByPerformanceItemId(performanceHeaderId)
      .then((rowData) => {
        if (rowData.succeeded) {
          commit("setChildrenData", { returnedData: rowData.data });
          i = -2;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /*Extra Column */
  async LoadHeaderExtraColumnValue({ dispatch }) {
    getAllExtraColumnDetails("PerformanceHeaderExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentParentColDef = state.currentParentColDef.slice(0, 2);

          rowData.data.forEach((y) =>
            state.currentParentColDef.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 100,
              filterParams: { excelMode: "windows" },
              filter: "agSetColumnFilter",
              editable: function (params) {
                if (params.data.parentId > 0) {
                  return true;
                }
                return false;
              },
              cellStyle: function (params) {
                if (params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                }
                if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return new Date(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.currentParentGridColDefCountCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async LoadItemExtraColumnValue({ dispatch }) {
    getAllExtraColumnDetails("PerformanceItemExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentChildrenColDef = []; //state.currentParentColDef.slice(0, 2);

          rowData.data.forEach((y) =>
            state.currentChildrenColDef.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 100,
              editable: true,
              filterParams: { excelMode: "windows" },
              filter: "agSetColumnFilter",
              cellStyle: function (params) {
                if (params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                }
                if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return new Date(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.currentChildrenGridColDefCountCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /**Delete Cost Element */
  async DeleteData({ dispatch }, { APIName, Id }) {
    DeleteElement(APIName, Id)
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentParentId = null;
          state.currentParentSelectedObj = [];
          state.currentParentUndoWatcher = 0;
          state.currentParentRedoWatcher = 0;
          state.currentParentredostate = false;
          state.currentParentundostate = false;
          state.isDelete++;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /**Save Cost Element */
  async saveData({ dispatch, commit }) {
    var rowsToInsert = [];
    var rowsToUpdate = [];
    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {};

    state.currentParentTempData.forEach((row) => {
      if (row.rowUpdated) {
        if (row.id <= -1) rowsToInsert.push(row);
        else {
          rowsToUpdate.push({
            Id: row.id,
            code: row.code,
            Description: row.description,
            ExtraColumnValues: row.extraColumnValues,
          });
        }
      }
    });

    if (rowsToInsert.length > 0) {
      createURL = "PerformanceHeader/Create";

      createObj = { PerformanceHeadersToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = "PerformanceHeader/Update";

      updateObj = { PerformanceHeadersToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          /* if (values)
                        returnedValues = returnedValues.concat(values.data); */
          commit("setSavedParentData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = post(updateURL, updateObj)
        .then((values) => {
          var returnedValues = [];
          if (values[1]) returnedValues = values[1].data;
          commit("setSavedParentData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    rowsToInsert = [];
    rowsToUpdate = [];
    createURL = "";
    updateURL = "";
    createObj = "";
    updateObj = "";
    state.currentchildTempData.forEach((row) => {
      if (row.rowUpdated) {
        if (row.id <= -1) {
          row.performanceStatusId = row.tempperformanceStatusId;
          rowsToInsert.push(row);
        } else {
          rowsToUpdate.push({
            Id: row.id,
            Code: row.code,
            Description: row.description,
            ParentID: row.parentId,
            PerformanceStatusId: row.tempperformanceStatusId,
            PerformanceMetricId: row.performanceMetricId,
            ResolutionPlan: row.resolutionPlan,
            PerformanceHeaderId: row.performanceHeaderId,
            ExtraColumnValues: row.extraColumnValues,
          });
        }
      }
    });

    if (rowsToInsert.length > 0) {
      createURL = "PerformanceItem/Create";

      createObj = { PerformanceItemsToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = "PerformanceItem/Update";

      updateObj = { PerformanceItemsToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          /* if (values)
                        returnedValues = returnedValues.concat(values.data); */
          commit("setSavedChildrenData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = post(updateURL, updateObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          commit("setSavedChildrenData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
  },
};

const mutations = {
  /*left Side(Parent grid details)*/
  /*CurrentData and details*/

  setParentData(state, { returnedData }) {
    let newRows = [],
      tempid = 2000;
    returnedData.forEach((row) => {
      tempid++;
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.performanceHeaderExtraColumnId] = y.value)
        );
      }
      if (row.projects != null) {
        row.projects.forEach(function (parentProjects) {
          temprow = { ...row };
          temprow.uid = ++tempid;
          // temprow.rowType = 2;
          temprow.parentId = parentProjects.id;
          temprow.path = [parentProjects.id, temprow.uid];
          newRows.push(temprow);
          let TempIndex = tempProjectParent.findIndex(
            (x) => x.id == parentProjects.id
          );
          if (TempIndex == -1) {
            parentProjects.uid = ++tempid;
            parentProjects.parentId = null;
            parentProjects.rowType = 2;
            parentProjects.path = [parentProjects.id];
            tempProjectParent.push(parentProjects);
          }
        });
      }
      if (row.programs != null) {
        //console.log(row.projects.length, row.id);
        row.programs.forEach(function (parentPrograms) {
          temprow = { ...row };
          temprow.uid = ++tempid;
          //temprow.rowType = 3;
          temprow.parentId = parentPrograms.id;
          temprow.path = [parentPrograms.id, temprow.uid];
          newRows.push(temprow);

          let TempIndex = tempProgramParent.findIndex(
            (x) => x.id == parentPrograms.id
          );
          if (TempIndex == -1) {
            parentPrograms.uid = ++tempid;
            parentPrograms.parentId = null;
            parentPrograms.rowType = 1;
            parentPrograms.path = [parentPrograms.id];
            tempProgramParent.push(parentPrograms);
          }
        });
      }

      if (row.portfolios != null) {
        //console.log(row.projects.length, row.id);
        row.portfolios.forEach(function (parentPortfolios) {
          temprow = { ...row };
          temprow.uid = ++tempid;
          //temprow.rowType = 3;
          temprow.parentId = parentPortfolios.id;
          temprow.path = [parentPortfolios.id, temprow.uid];
          newRows.push(temprow);

          let TempIndex = tempPortfolioParent.findIndex(
            (x) => x.id == parentPortfolios.id
          );
          if (TempIndex == -1) {
            parentPortfolios.uid = ++tempid;
            parentPortfolios.parentId = null;
            parentPortfolios.rowType = 0;
            parentPortfolios.path = [parentPortfolios.id];
            tempPortfolioParent.push(parentPortfolios);
          }
        });
      }
      if (
        row.projects == null &&
        row.programs == null &&
        row.portfolios == null
      ) {
        row.uid = ++tempid;
        row.parentId = 1;
        row.path = [1, row.id];
        temprow = row;
        newRows.push(temprow);
      }
    });
    returnedData = newRows;
    tempProjectParent.forEach((row) => {
      returnedData.push(row);
    });
    tempProgramParent.forEach((row) => {
      returnedData.push(row);
    });
    tempPortfolioParent.forEach((row) => {
      returnedData.push(row);
    });
    state.totalRowLength = returnedData.length;
    state.currentParentData = returnedData.sort((a, b) =>
      a.code > b.code ? 1 : -1
    );
    //state.currentParentData = returnedData;
    /*Reset the Parent Grid property */
    state.currentParentId = null;
    state.currentParentSelectedObj = [];
    state.currentParentDeleteRecordCounter = 0;
    state.currentParentUndoWatcher = 0;
    state.currentParentRedoWatcher = 0;
    state.currentParentredostate = false;
    state.currentParentundostate = false;
    state.currentParentAddRecordCounter = 0;
    state.currentParentLoadRecordCounter++;
  },

  setSavedParentData(state, { returnedData }) {
    //returnedData.forEach(row => (row.rowUpdated = false));
    let newRows = [];
    returnedData.forEach((row) => {
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.performanceHeaderExtraColumnId] = y.value)
        );
      }
      state.totalRowLength++;
      if (
        row.projects == null &&
        row.programs == null &&
        row.portfolios == null
      ) {
        row.parentId = 1;
        row.uid = state.totalRowLength++;
        row.path = [1, state.totalRowLength++];
      }
    });
    state.currtempData = returnedData;
    /*Reset the Parent Grid property */
    state.currentParentUndoWatcher = 0;
    state.currentParentRedoWatcher = 0;
    state.currentParentredostate = false;
    state.currentParentundostate = false;
    state.datawatcher++;
  },

  setParentObj(state, row) {
    state.currentParentSelectedObj = row;
    state.currentParentId = row.id;
  },

  resetParentObj(state, row) {
    state.currentParentSelectedObj = null;
    state.currentParentId = null;
  },

  setSelectedParentRowObject(state, row) {
    state.currentselectedParentRows = row;
    //state.currentParentSelectedObj = row;
    // state.currentParentId = row.id;
  },

  /*mutate counter for CRUD*/
  mutateParentAddRecord(state) {
    state.currentParentAddRecordCounter++;
    /* state.currentParentData.push({
      id: --tempnewId,
      code: "",
      description: "",
      rowUpdated: true,
      parentId: 1,
      path: [1, tempnewId],
      programs: null,
      portfolios: null,
      projects: null,
      extraColumnValues: [],
    }); */
  },

  mutateParentDelete(state) {
    state.currentParentDeleteRecordCounter++;
  },

  mutateParentSave(state) {
    state.currentParentSaveRecordCounter++;
  },

  /*Parent Grid Config*/
  mutateParentRedo(state) {
    state.currentParentRedoWatcher++;
  },

  mutateParentUndo(state) {
    state.currentParentUndoWatcher++;
  },

  mutateParentRedostate(state, value) {
    state.currentParentredostate = value;
  },

  mutateParentUndostate(state, value) {
    state.currentParentundostate = value;
  },

  mutateElementRealtionship(state, isRelationship) {
    state.isRelationship = isRelationship;
  },

  //Performance Items
  setChildrenData(state, { returnedData }) {
    returnedData.forEach((row) => {
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.performanceItemExtraColumnId] = y.value)
        );
      }

      var tempdata11 = state.performanceStatusValues.filter(
        (x) => x.id == row.performanceStatusId
      );
      row.performanceStatusId = tempdata11[0].description;
      row.tempperformanceStatusId = tempdata11[0].id;
      row.colour = tempdata11[0].colour;
    });
    data = returnedData;
    tempData = setpath(data);
    state.currentChildrenData = returnedData;
    /*Reset the Children Grid property */
    state.currentChildrenId = null;
    state.currentChildrenSelectedObj = [];
    state.currentChildrenDeleteRecordCounter = 0;
    state.currentChildrenUndoWatcher = 0;
    state.currentChildrenRedoWatcher = 0;
    state.currentChildrenredostate = false;
    state.currentChildrenundostate = false;
    state.currentChildrenAddRecordCounter = 0;
    state.currentChildrenLoadRecordCounter++;
  },

  setSavedChildrenData(state, { returnedData }) {
    //returnedData.forEach(row => (row.rowUpdated = false));
    returnedData.forEach((row) => {
      row.rowUpdated = false;
    });

    state.currentchildTempData.forEach((row) => {
      row.rowUpdated = false;
      if (row.id < 0) {
        if (row.extraColumnValues != null) {
          row.extraColumnValues.forEach(
            (y) => (row["et_" + y.performanceItemExtraColumnId] = y.value)
          );
        }
        var tempdata11 = state.performanceStatusValues.filter(
          (x) => x.id == row.performanceStatusId
        );
        row.performanceStatusId = tempdata11[0].description;
        row.tempperformanceStatusId = tempdata11[0].id;
        row.colour = tempdata11[0].colour;

        row.id = returnedData.find(
          (x) => x.code == row.code && x.description == row.description
        ).id;
        row.path[row.path.length - 1] = row.id;
      }
      if (row.parentId < 0) {
        row.parentId = returnedData.find(
          (x) => x.code == row.code && x.description == row.description
        ).parentId;
        if (row.path.filter((x) => x < 0).length > 1) {
          row.path = state.currentchildTempData.find(
            (x) => x.id == row.parentId
          ).path;
          row.path.push(row.id); // row.id;
        } else {
          row.path[row.path.length - 2] = row.parentId;
          row.path[row.path.length - 1] = row.id;
        }
      }
    });
    state.tempSaveData = state.currentchildTempData;
    /*Reset the Children Grid property */
    state.currentChildrenUndoWatcher = 0;
    state.currentChildrenRedoWatcher = 0;
    state.currentChildrenredostate = false;
    state.currentChildrenundostate = false;
    state.datawatcher++;
  },

  setChildrenObj(state, row) {
    state.currentChildrenSelectedObj = row;
    state.currentChildrenId = row.id;
  },

  setSelectedChildrenRowObject(state, row) {
    state.currentselectedChildrenRows = row;
    //state.currentChildrenSelectedObj = row;
    // state.currentChildrenId = row.id;
  },

  /*mutate counter for CRUD*/
  mutateChildrenAddRecord(state) {
    state.currentChildrenAddRecordCounter++;
  },

  mutateChildrenAddSubRecord(state) {
    state.currentChildrenSubRecordCounter++;
  },

  mutateChildrenDelete(state) {
    state.currentChildrenDeleteRecordCounter++;
  },

  mutateChildrenSave(state) {
    state.currentChildrenSaveRecordCounter++;
  },

  /*Children Grid Config*/
  mutateChildrenRedo(state) {
    state.currentChildrenRedoWatcher++;
  },

  mutateChildrenUndo(state) {
    state.currentChildrenUndoWatcher++;
  },

  mutateChildrenRedostate(state, value) {
    state.currentChildrenredostate = value;
  },

  mutateChildrenUndostate(state, value) {
    state.currentChildrenundostate = value;
  },

  mutatePerformanceHeaderRealtionship(state, isRelationship) {
    state.isRelationship = isRelationship;
  },
  mutateParentRecord(state, { returnedNewData }) {
    state.currentParentTempData = [];
    returnedNewData.forEach((element) => {
      if (element.rowUpdated) {
        state.currentParentTempData.push(element);
      }
    });
    //state.currentchildTempData = returnedNewData;
  },

  mutateRecord(state, { returnedNewData }) {
    state.currentchildTempData = [];
    returnedNewData.forEach((element) => {
      if (element.rowUpdated) {
        state.currentchildTempData.push(element);
      }
    });
    state.currentchildTempData = returnedNewData;
  },

  mutateChildrenCopyRecord(state) {
    const tempcopydata = [],
      tempconstData = [];

    state.currentselectedChildrenRows.forEach((row) => {
      tempcopydata.push(row);
      tempconstData.push(JSON.parse(JSON.stringify(row)));
    });

    let tempchange = null,
      tempchangechild = null,
      trackParent = false;
    let objtempParentData = tempcopydata[0].path;
    for (let y = 0; y < tempcopydata.length; y++) {
      tempcopydata[y].id = i - 25;
      tempcopydata[y].rowUpdated = true;
      if (y > 0) {
        if (tempcopydata[y].parentId == tempconstData[y - 1].parentId) {
          trackParent = false;
          tempchangechild = y - 1;
        } else {
          trackParent = true;
          tempchange = tempconstData.findIndex(
            (x) => x.id == tempcopydata[y].parentId
          );
        }
      }
      delete tempcopydata[y].path;
      if (tempcopydata[y].parentId > 0) {
        if (y == 0) {
          tempcopydata[y].parentId =
            state.currentselectedChildrenRows[0].parentId;
          objtempParentData[objtempParentData.length - 1] = tempcopydata[y].id;
          tempcopydata[y].path = objtempParentData;
        } else {
          if (trackParent) {
            tempcopydata[y].parentId = tempcopydata[tempchange].id;
          } else {
            tempcopydata[y].parentId = tempcopydata[tempchangechild].parentId;
          }
        }
      } else {
        tempcopydata[y].parentId = null;
      }
      i--;
    }
    state.cuurentcopyRowvalues = setpath(tempcopydata);
    state.duplicateRecord++;
  },
};

const getters = {
  /*left Side(Parent grid details)*/
  /*CurrentData and details*/
  getParentGridColumn() {
    return state.currentParentColDef;
  },

  getParentCurrentData() {
    return state.currentParentData;
  },

  getParentTempData() {
    return state.currentParentTempData;
  },

  getParentSelectedObj() {
    return state.currentParentSelectedObj;
  },
  getParentSelectedId() {
    return state.currentParentId;
  },
  /*Extra Config */

  getCopiedRows() {
    return state.cuurentcopyRowvalues;
  },

  getChildrenGridColumn() {
    return state.currentChildrenColDef;
  },

  getChildrenCurrentData() {
    return state.currentChildrenData;
  },

  getChildrenSelectedObj() {
    return state.currentChildrenSelectedObj;
  },
  getChildrenSelectedId() {
    return state.currentChildrenId;
  },
  getPerformanceMetricsData() {
    return state.performanceMetricsValues;
  },

  getPerformanceStatusData() {
    return state.performanceStatusValues;
  },

  getperformanceHeaderStatus() {
    return state.isRelationship;
  },

  getCurrentTempDate() {
    return state.currtempData;
  },
};

export const performanceSheetManager = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
