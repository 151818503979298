<template>
  <splitpanes class="default-theme">
    <pane>
      <genericGridComponent
        style="height: 100%; width: 100%"
        :rowData="rowData"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
      >
      </genericGridComponent>
    </pane>

    <pane>
      <genericGridComponent
        style="height: 100%; width: 100%"
        :rowData="childRowData"
        :gridOptions="childGridOptions"
        :columnDefs="childColumnDefs"
      >
      </genericGridComponent>
    </pane>
  </splitpanes>

  <!-- <div style="background-color: white; height: 100%;">
    <DataToolBar />
    <div class="columns" style="width: 100%;height: 100%">
      <div class="container-grid" style="width: 100%;height: 100%">
        <div class="box" style="height: 100%">
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="multiple"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            :gridOptions="gridOptions"
            :rowData="rowData"
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :enableRangeSelection="true"
            :undoRedoCellEditing="true"
          >
          </ag-grid-vue>
        </div>
        <div class="handler"></div>
      </div>
    </div>-->
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import DataToolBar from "@/components/Data/DataToolbar.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import BtnCellRenderer from "../../assets/ps-btn-cell-renderer.vue";
import genericGridComponent from "../GenericComponents/genericGridComponent.vue";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import * as moment from "moment";
export default {
  name: "processSetView",
  props: {},
  data() {
    return {
      rowData: [],
      childRowData: [],
      gridOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
          ],
        },
      },
      childGridOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
          ],
        },
      },
      childColumnDefs: [
        {
          headerName: "Id",
          field: "id",
          sortable: true,
          resizable: true,
          editable: false,
          filterParams: { excelMode: "windows" },
          filter: "agSetColumnFilter",
        },
        {
          headerName: "Started",
          field: "startDate",
          sortable: true,
          resizable: true,
          editable: true,
          valueFormatter: function (params) {
            if (params.value) {
              return moment
                .utc(params.value)
                .local()
                .format("DD/MM/YYYY HH:mm A");
            }
            return null;
          },
          filterParams: { excelMode: "windows" },
          filter: "agSetColumnFilter",
        },
        {
          headerName: "Ended",
          field: "endDate",
          sortable: true,
          resizable: true,
          valueFormatter: function (params) {
            if (params.value) {
              return moment
                .utc(params.value)
                .local()
                .format("DD/MM/YYYY HH:mm A");
            }
            return null;
          },
          filterParams: { excelMode: "windows" },
          editable: true,
          filter: "agSetColumnFilter",
        },
        {
          headerName: "Success?",
          field: "executedSuccessfully",
          sortable: true,
          resizable: true,
          filterParams: { excelMode: "windows" },
          editable: true,
          filter: "agSetColumnFilter",
        },
        {
          headerName: "Retry Instance",
          field: "id",
          sortable: true,
          cellRendererFramework: "btnCellRenderer",
          cellRendererParams: {
            message: "Retry",
            iconClass: "fas fa-redo",
            clicked: (field) => {
              //We use an arrow function here, so we don't lose reference to 'this'
              //this.setSelectedChildEntity(field);
              this.retryProcesssetInstance(field);
            },
          },
          minWidth: 150,
        },
      ],
      defaultColDef: null,
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          resizable: true,
          width: 405,
          editable: true,
          filterParams: { excelMode: "windows" },
          filter: "agSetColumnFilter",
        },
        {
          headerName: "Description",
          field: "description",
          sortable: true,
          resizable: true,
          width: 305,
          filterParams: { excelMode: "windows" },
          editable: true,
          filter: "agSetColumnFilter",
        },
        {
          headerName: "View History",
          field: "id",
          sortable: true,
          cellRendererFramework: "btnCellRenderer",
          cellRendererParams: {
            message: "View History",
            iconClass: "fas fa-history",
            clicked: (field) => {
              //We use an arrow function here, so we don't lose reference to 'this'
              this.setSelectedEntity(field);
              this.setChildDataType("ProcessSetInstance");
              this.loadChildData();
            },
          },
          minWidth: 150,
        },
      ],
    };
  },
  components: {
    //AgGridVue,
    //DataToolBar,
    btnCellRenderer: BtnCellRenderer, // eslint-disable-line vue/no-unused-components
    genericGridComponent,
    Splitpanes,
    Pane,
  },
  watch: {
    //This should be updated when a menu option is selected
    selectedScheduleDataType() {
      //Load the rows for the selected data type
      this.loadAllData();
    },
    entitiesLoaded() {
      //This flag is set once the web call is resolved and the data is loaded
      if (this.entitiesLoaded) this.rowData = this.allEntities;
    },
    childEntitiesLoaded() {
      //This flag is set once the web call is resolved and the data is loaded
      if (this.childEntitiesLoaded) this.childRowData = this.allChildEntities;
    },
  },
  methods: {
    ...mapActions("processSetDataManager", [
      "loadAllData",
      "loadChildData",
      "setSelectedEntity",
      "setChildDataType",
      "retryProcesssetInstance",
    ]),
  },
  computed: {
    ...mapGetters("processSetDataManager", [
      "allEntities",
      "allChildEntities",
      "entitiesLoaded",
      "childEntitiesLoaded",
    ]),
  },
  mounted() {
    this.loadAllData();
  },
};
</script>

<style></style>
