<template>
  <nav
    class="navbar"
    role="navigation"
    aria-label="main navigation"
    style="border-bottom: 2px solid; margin-bottom: 5px"
  >
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-end" style="margin-right: 18px">
        <!--  Save Button -->
        <button
          class="button"
          title="Save"
          @click="SaveFunction"
          v-if="currentRouteName != 'login'"
          :disabled="getExceptionSelectedObj == null"
        >
          <span class="icon is-small">
            <i class="fas fa-save"></i>
          </span>
        </button>

        <!--  Rest Column State Button -->
        <button
          class="button"
          title="Reset Columns"
          v-if="currentRouteName == 'login'"
        >
          <span class="icon is-small">
            <i class="fas fa-text-width"></i>
          </span>
        </button>
        <!--  Undo Button -->
        <button
          class="button"
          title="Undo"
          v-if="currentRouteName != 'login'"
          @click="mutateExceptionUndo"
          :disabled="isUndo == false"
        >
          <span class="icon is-small">
            <i class="fas fa-undo"></i>
          </span>
        </button>
        <!--  Redo Button -->
        <button
          class="button"
          title="Redo"
          v-if="currentRouteName != 'login'"
          @click="mutateExceptionRedo"
          :disabled="isRedo == false"
        >
          <span class="icon is-small">
            <i class="fas fa-redo"></i>
          </span>
        </button>
        <!--  Add New Record Button -->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add New"
          @click="muatateExceptionAdd"
        >
          <span class="icon is-small">
            <i class="fas fa-plus"></i>
          </span>
        </button>
        <!--  Delete Button -->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Delete"
          @click="mutateExceptionDelete"
          :disabled="getExceptionSelectedObj == null"
        >
          <!--  @click="saveRecords" @click="deleteRecord"> -->
          <span class="icon is-small">
            <i class="fas fa-trash-alt"></i>
          </span>
        </button>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
p {
  width: 165px;
  display: inline-block;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}
nav.navbar {
  border-top: 0px solid black;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
</style>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "CalendarExceptionToolBar",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("calendarController", ["getExceptionSelectedObj"]),

    /*Exception Controller State */
    ...mapState({
      isRedo: (state) => state.calendarController.currentExceptionredostate,
      isUndo: (state) => state.calendarController.currentExceptionundostate,
    }),

    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    /* Exception Controller Mutations */
    ...mapMutations("calendarController", [
      "mutateExceptionUndo",
      "mutateExceptionRedo",
      "mutateExceptionDelete",
      "muatateExceptionAdd",
      "mutateExceptionSave",
      "mutateParentSave",
    ]),
    SaveFunction() {
      this.mutateExceptionSave();
      this.mutateParentSave();
    },
  },
};
</script>
