<template>
  <Plotly
    :data="data"
    :layout="layout"
    :displayModeBar="displayModeBar"
    :config="config"
  />
</template>

<script>
import { Plotly } from "vue-plotly";

export default {
  components: {
    Plotly,
  },

  props: {
    data: Array,
    layout: Object,
    displayModeBar: Boolean,
    config: Object,
  },

  data() {
    return {};
  },
};
</script>
