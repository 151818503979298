<template>
  <nav class="navbar" role="navigation" aria-label="main navigation" style="">
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start"></div>
      <div class="navbar-end">
        <button
          class="button"
          title="Save"
          v-if="toolbarConfig.showSave"
          @click="$emit('save')"
          :disabled="!dataDirty"
        >
          <span class="icon is-small">
            <i class="fas fa-save"></i>
          </span>
        </button>
        <button
          class="button"
          title="Add New"
          @click="$emit('addNew')"
          aria-expanded="false"
          v-if="toolbarConfig.showAddNew"
          :disabled="!toolbarConfig.showAddNew"
        >
          <span class="icon is-small">
            <i class="fas fa-plus"></i>
          </span>
        </button>
        <!-- 
        <button
          class="button"
          title="Reset Columns"
          v-if="currentRouteName == 'login'"
          disabled
        >
          <span class="icon is-small">
            <i class="fas fa-text-width"></i>
          </span>
        </button>

        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Undo"
          @click="requestUndo"
          :disabled="getisUndo == false"
        >
          <span class="icon is-small">
            <i class="fas fa-undo"></i>
          </span>
        </button>

        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Redo"
          @click="requestRedo"
          :disabled="getisRedo == false"
        >
          <span class="icon is-small">
            <i class="fas fa-redo"></i>
          </span>
        </button>

        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add New"
          @click="addDataRecord"
        >
          <span class="icon is-small">
            <i class="fas fa-plus"></i>
          </span>
        </button> -->

        <!-- :disabled="Object.keys(getSelectedRowObject).length < 1" -->
        <button
          class="button"
          title="Delete"
          @click="$emit('delete')"
          v-if="toolbarConfig.showDelete"
          :disabled="!toolbarConfig.showDelete"
        >
          <span class="icon is-small">
            <i class="fas fa-trash-alt"></i>
          </span>
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "GenericToolBar",
  components: {},
  props: {
    selectedRowParentObjs: Object,
    dataDirty: Boolean,
    toolbarConfig: Object,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
p {
  width: 165px;
  display: inline-block;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}
nav.navbar {
  border-top: 1px solid black;
  border-bottom: 1px solid;
  margin: 3px;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
</style>
