<template>
  <div class="modal is-active is-overlay">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button
          class="delete"
          aria-label="close"
          @click="toggleStructureFilterModal"
        ></button>
      </header>
      <section class="modal-card-body">
        <!-- Structure Filter Tabs -->
        <div class="tabs is-centered is-boxed">
          <ul>
            <li
              :class="{ 'is-active': 'portfolios' == selectedTab }"
              @click="selectedTab = 'portfolios'"
              v-if="isPortfolio"
            >
              <a>
                <LevelIconCellRenderer :value="FiltersName[0]" />
                <span>Portfolios</span>
              </a>
            </li>
            <li
              :class="{ 'is-active': 'programs' == selectedTab }"
              @click="selectedTab = 'programs'"
              v-if="isProgram"
            >
              <a>
                <LevelIconCellRenderer :value="FiltersName[1]" />
                <span>Programs</span>
              </a>
            </li>
            <li
              :class="{ 'is-active': 'projects' == selectedTab }"
              @click="selectedTab = 'projects'"
              v-if="isProject"
            >
              <a>
                <LevelIconCellRenderer :value="FiltersName[2]" />
                <span>Projects</span>
              </a>
            </li>
            <li
              :class="{ 'is-active': 'date' == selectedTab }"
              @click="selectedTab = 'date'"
              v-if="isDate"
            >
              <a>
                <LevelIconCellRenderer :value="FiltersName[3]" />
                <span>Dates</span>
              </a>
            </li>
          </ul>
        </div>

        <!-- Structure Filter Section -->
        <div id="global-filter-section-content" class="section">
          <div class="field is-grouped" v-show="'date' != selectedTab">
            <div class="control has-icons-left is-expanded">
              <input
                class="input"
                type="email"
                placeholder="Filter"
                v-model="partialName"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-search"></i>
              </span>
            </div>
            <div class="control buttons has-addons">
              <button class="button" @click="allSelect">Select</button>
              <button class="button" @click="allUnselect">Unselect</button>
              <button class="button" @click="allToggle">Toggle</button>
            </div>
          </div>

          <!-- Portfolio Tab -->
          <div
            v-for="item in portfolios"
            :key="'chkPf' + item.id"
            v-show="'portfolios' == selectedTab"
            class="field"
          >
            <div class="control" v-show="partialMatch(item, chkPortfolios)">
              <label class="checkbox" :for="'chkPf' + item.id">
                <input
                  type="checkbox"
                  :id="'chkPf' + item.id"
                  :name="'chkPf' + item.id"
                  :value="item.description"
                  v-model="chkPortfolios[item.id]"
                />
                {{ item.code }} - {{ item.description }}
              </label>
            </div>
          </div>

          <!-- Programs Tab -->
          <div
            v-for="item in programs"
            :key="'chkPrg' + item.id"
            v-show="'programs' == selectedTab"
            class="field"
          >
            <div class="control" v-show="partialMatch(item, chkPrograms)">
              <label class="checkbox" :for="'chkPrg' + item.id">
                <input
                  type="checkbox"
                  :id="'chkPrg' + item.id"
                  :name="'chkPrg' + item.id"
                  :value="item.description"
                  v-model="chkPrograms[item.id]"
                />
                {{ item.code }} - {{ item.description }}
              </label>
            </div>
          </div>

          <!-- Projects Tab -->
          <div
            v-for="item in projects"
            :key="'chkPrj' + item.id"
            v-show="'projects' == selectedTab"
            class="field"
          >
            <div class="control" v-show="partialMatch(item, chkProjects)">
              <label class="checkbox" :for="'chkPrj' + item.id">
                <input
                  type="checkbox"
                  :id="'chkPrj' + item.id"
                  :name="'chkPrj' + item.id"
                  :value="item.description"
                  v-model="chkProjects[item.id]"
                />
                {{ item.code }} - {{ item.description }}
              </label>
            </div>
          </div>

          <!-- Dates tab -->
          <div v-show="'date' == selectedTab">
            <DateRangePicker />
          </div>
          <p align="center">
            <span class="danger" v-html="alertlabel.message"> </span>
          </p>
        </div>
        <div></div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="saveUserFilters">
          Apply
        </button>
        <button class="button" @click="CancelEvent">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "@/components/Filters/DateRangePicker.vue";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import LevelIconCellRenderer from "../Data/LevelIconCellRenderer.vue";
const alertlabel = {
  isError: false,
  message: null,
};

export default {
  name: "StructureFilter",
  components: {
    DateRangePicker,
    LevelIconCellRenderer,
  },
  data() {
    return {
      chkPortfolios: {},
      chkPrograms: {},
      chkProjects: {},
      selectedTab: "programs",
      partialName: "",
      alertlabel,
      isPortfolio: false,
      isProject: false,
      isProgram: false,
      isDate: false,
      title: "Filters",
      FiltersName: [
        "FilterPortfolio",
        "FilterProgram",
        "FilterProject",
        "FilterDates",
      ],
    };
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("filterControl", ["filters"]),
    ...mapGetters("storedates", ["resultval"]),
    ...mapGetters("dataManager", ["selectedDataType", "getRelationshipStatus"]),
    ...mapState({
      portfolios: (state) => state.filterControl.portfolios,
      programs: (state) => state.filterControl.programs,
      projects: (state) => state.filterControl.projects,
      filterDataWatcher: (state) => state.filterControl.filterWatcher,
    }),
    ...mapGetters("dashboard", ["dashboardName"]),

    ...mapGetters("dashboardportfolio", ["dashboardName"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    filterDataWatcher() {
      this.reloadFilters();
    },
  },
  created() {},
  mounted() {
    this.reloadFilters();
  },
  methods: {
    ...mapMutations("filterControl", [
      "toggleStructureFilterModal",
      "resetCurrentDate",
    ]),
    ...mapMutations("dataManager", ["mutateRealtionship"]),
    ...mapMutations("userView", ["requestDataUpload"]),
    ...mapActions({
      saveFilters: "filterControl/saveFilters",
      updateFilters: "filterControl/updateFilters",
    }),
    ...mapMutations("storedates", ["storedatevalues"]),
    ...mapMutations("dashboard", ["storeapivalues"]),
    ...mapMutations("dashboardportfolio", ["storeapivalues"]),
    reloadFilters() {
      this.isPortfolio = true;
      this.isProject = true;
      this.isProgram = true;
      this.isDate = true;
      this.istitle = false;
      this.title = "Filters";

      this.resetCurrentDate();
      // reset filters
      this.chkPortfolios = {};
      this.chkPrograms = {};
      this.chkProjects = {};
      // if filters not loaded for some reason, reload it
      if (this.filterDataWatcher < 1) {
        // TODO: find current `client` and load filters for it
        this.updateFilters();
        return;
      }

      const filters = this.filters;
      this.portfolios.forEach((p) => {
        this.chkPortfolios[p.id] = false;
      });
      filters.Portfolios.forEach((v) => {
        this.chkPortfolios[v] = true;
      });

      this.programs.forEach((p) => {
        this.chkPrograms[p.id] = false;
      });
      filters.Programs.forEach((v) => {
        this.chkPrograms[v] = true;
      });

      this.projects.forEach((p) => {
        this.chkProjects[p.id] = false;
      });
      filters.Projects.forEach((v) => {
        this.chkProjects[v] = true;
      });
    },
    CancelEvent() {
      this.filters.isSave = false;
      this.reloadFilters();
      this.toggleStructureFilterModal();
    },
    saveUserFilters() {
      let filters = [];
      let closureAddSelected = ([key, isSelected]) => (isSelected ? [key] : []);
      let flatMapAddSelected = (baseModel) =>
        Object.entries(baseModel).map(closureAddSelected).flat(1);

      filters.selectedPortfolios = flatMapAddSelected(this.chkPortfolios);
      filters.selectedPrograms = flatMapAddSelected(this.chkPrograms);
      filters.selectedProjects = flatMapAddSelected(this.chkProjects);
      this.saveFilters({
        filters: filters,
      });
      this.toggleStructureFilterModal();
    },
    savefilter() {
      this.filters.isSave = true;
      alertlabel.isError = false;
      alertlabel.message = null;
      this.$store.commit("storedates/storedatevalues", {
        fromdate: this.filters.From,
        todate: this.filters.To,
        reportDate: this.filters.Report,
      });
      this.toggleStructureFilterModal();
    },
    partialMatch(item, model) {
      if (!this.partialName) {
        return true;
      } else if (item.id < 0) {
        return true;
      } else if ((item && item.description) || (item && item.code)) {
        return (
          item.description
            .toLowerCase()
            .indexOf(this.partialName.toLowerCase()) > -1 ||
          item.code.toLowerCase().indexOf(this.partialName.toLowerCase()) > -1
        );
      } else if (item && item.description) {
        return (
          item.description
            .toLowerCase()
            .indexOf(this.partialName.toLowerCase()) > -1
        );
      } else if (model && model && model[item.id]) {
        return true;
      } else {
        return false;
      }
    },
    allSelect() {
      if (this.selectedTab == "portfolios") {
        for (const [key] of Object.entries(this.chkPortfolios)) {
          this.chkPortfolios[key] = true;
        }
        this.chkPortfolios = { ...this.chkPortfolios };
      } else if (this.selectedTab == "programs") {
        for (const [key] of Object.entries(this.chkPrograms)) {
          this.chkPrograms[key] = true;
        }
        this.chkPrograms = { ...this.chkPrograms };
      } else if (this.selectedTab == "projects") {
        for (const [key] of Object.entries(this.chkProjects)) {
          this.chkProjects[key] = true;
        }
        this.chkProjects = { ...this.chkProjects };
      }
    },
    allUnselect() {
      if (this.selectedTab == "portfolios") {
        for (const [key] of Object.entries(this.chkPortfolios)) {
          this.chkPortfolios[key] = false;
        }
        this.chkPortfolios = { ...this.chkPortfolios };
      } else if (this.selectedTab == "programs") {
        for (const [key] of Object.entries(this.chkPrograms)) {
          this.chkPrograms[key] = false;
        }
        this.chkPrograms = { ...this.chkPrograms };
      } else if (this.selectedTab == "projects") {
        for (const [key] of Object.entries(this.chkProjects)) {
          this.chkProjects[key] = false;
        }
        this.chkProjects = { ...this.chkProjects };
      }
    },
    allToggle() {
      if (this.selectedTab == "portfolios") {
        for (const [key, value] of Object.entries(this.chkPortfolios)) {
          this.chkPortfolios[key] = value ? false : true;
        }
        this.chkPortfolios = { ...this.chkPortfolios };
      } else if (this.selectedTab == "programs") {
        for (const [key, value] of Object.entries(this.chkPrograms)) {
          this.chkPrograms[key] = value ? false : true;
        }
        this.chkPrograms = { ...this.chkPrograms };
      } else if (this.selectedTab == "projects") {
        for (const [key, value] of Object.entries(this.chkProjects)) {
          this.chkProjects[key] = value ? false : true;
        }
        this.chkProjects = { ...this.chkProjects };
      }
    },
  },
};
</script>

<style lang="scss">
#global-filter-section-content {
  height: 25vw;
  padding-top: 0px;
}
.danger {
  color: #f44336;
}
/* Red */
</style>
