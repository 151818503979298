<template>
  <div style="height: 100%; background-color: white">
    <div class="line"></div>
    <div class="columns" style="height: 100%; width: 100%">
      <div class="container-grid" style="width: 100%; height: 100%">
        <div class="box" style="height: 100%" ref="scriptContainerRowType">
          <CostElementLeftToolbar />
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="single"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="false"
            :columnDefs="rowtypecolumnDefs"
            :rowData="rowtypecolumnData"
            :enableRangeSelection="true"
            :undoRedoCellEditing="true"
            :gridOptions="gridRowTypeOptions"
            @selection-changed="onSelectionChanged"
            @grid-ready="onGridReady"
            @sort-changed="onColumnChanged"
            @column-resized="onColumnChanged"
            @column-visible="onColumnChanged"
            @column-row-group-changed="onColumnChanged"
            @column-value-changed="onColumnChanged"
            @column-moved="onColumnChanged"
            @column-pinned="onColumnChanged"
          >
          </ag-grid-vue>
        </div>
        <div class="handler"></div>
        <div class="box" ref="scriptContainerExtraColumn">
          <CostElementToolbar />
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="multiple"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            :columnDefs="DetailcolumnDefs"
            :rowData="rowElementData"
            :gridOptions="gridDetailOptions"
            :treeData="false"
            :animateRows="true"
            :defaultColDef="defaultColDef"
            :autoGroupColumnDef="autoGroupColumnDef"
            :overlayLoadingTemplate="loadingTemplate"
            :overlayNoRowsTemplate="noRowsTemplate"
            :enableRangeSelection="true"
            :undoRedoCellEditing="true"
            :components="gridDetailOptionsComponents"
            :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
            :detailCellRendererParams="detailCellRendererParams"
            @cell-value-changed="onCellValueChanged"
            @cellClicked="cellClicked"
            @grid-ready="onDetailsGridReady"
            @sort-changed="onDetailsColumnChanged"
            @column-resized="onDetailsColumnChanged"
            @column-visible="onDetailsColumnChanged"
            @column-row-group-changed="onDetailsColumnChanged"
            @column-value-changed="onDetailsColumnChanged"
            @column-moved="onDetailsColumnChanged"
            @column-pinned="onDetailsColumnChanged"
          >
          </ag-grid-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font: normal 11px/22px "Open Sans", sans-serif;
  color: black;
  border: 1px solid #ccc;
}
.container-grid {
  margin-top: 0.1%;
  background-color: #fff;
  color: #444;
  /* Use flexbox */
  display: flex;
}

.line {
  width: 99.5%;
  height: 1%;
  border-bottom: 1px solid black;
  position: relative;
}
.box {
  margin-top: 0.1%;
  color: #fff;
  border-radius: 1px;
  padding: 15px;
  font-size: 150%;

  /* Use box-sizing so that element's outerwidth will match width property */
  box-sizing: border-box;

  /* Allow box to grow and shrink, and ensure they are all equally sized */
  flex: 1 1 auto;
}

.handler {
  margin-top: 0.8%;
  position: relative;
  width: 1px;
  padding: 0;
  cursor: col-resize;
  flex: 0 0 auto;
}

.handler::before {
  margin-top: 0.8%;
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background: silver;
  margin: 0 auto;
}
</style>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import CostElementToolbar from "./CostElementExtraColumnToolbar.vue";
import CostElementLeftToolbar from "./CostElementExtraColunmToolBars.vue";
import LevelIconCellRenderer from "@/components/Data/LevelIconCellRenderer.vue";
import * as utilitiesModule from "@/assets/utilities.js";
import { debounce } from "lodash";
import {
  getColumnDef,
  postColumnState,
  getColumnState,
} from "@/api/networking.js";
export default {
  name: "CostElementExtraColumnManager",
  components: {
    AgGridVue,
    CostElementToolbar,
    CostElementLeftToolbar,
    LevelIconCellRenderer, // eslint-disable-line
  },
  data() {
    return {
      rowtypecolumnData: null,
      rowtypecolumnDefs: null,
      undoRedoCellEditingLimit: null,
      invalidindex: [],
      i: -1,
      gridRowTypeOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
      },
      detailCellRendererParams: {
        refreshStrategy: "everything",
      },
      rowElementData: null,
      DetailcolumnDefs: null,
      clickcell: false,
      defaultColDef: null,
      singleClickEdit: false,
      gridDetailOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
        processDataFromClipboard: (params) => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          const lastIndex =
            this.gridDetailOptions.api.getModel().rowsToDisplay.length - 1;
          const focusedCell = this.gridDetailOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;
          if (focusedIndex + params.data.length - 1 > lastIndex) {
            const resultLastIndex = focusedIndex + (params.data.length - 1);
            const addRowCount = resultLastIndex - lastIndex;
            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }
            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];
            rowsToAdd.map((r) => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map((i) => {
                row[currColumn.colDef.field] = i;
                currColumn =
                  this.gridDetailOptions.columnApi.getDisplayedColAfter(
                    currColumn
                  );
              });
              row.rowUpdated = true;
              row.valueType = 0;
              row.id = -1;
              this.rowElementData.push(row);
              newRowData.push(row);
            });

            this.gridDetailOptions.api.applyTransaction({
              add: newRowData,
            });
            this.gridDetailOptions.api.redrawRows();
          }
          this.gridDetailOptions.api.refreshCells();
          return params.data;
        },
      },

      gridDetailOptionsComponents: {
        CustomCombobox: getCustomCellRenderer(),
      },
      getDataPath: null,
      autoGroupColumnDef: null,
      groupDefaultExpanded: null,
      SelectId: null,
      tempElementData: [],
      loadingTemplate: `<span class="ag-overlay-loading-center">Data is loading...</span>`,
      isElementdetail: false,
      deleteSettings: {
        withBackdrop: true,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "center-center",
      },
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
    };
  },
  watch: {
    LoadRowTypeCounter() {
      this.rowtypecolumnData = this.getRowTypeData;
      this.reloadColumnState();
    },

    LoadCounter() {
      this.rowElementData = this.getDetailData;
      this.reloadDetailsColumnState();
    },
    undoWatcher() {
      this.undo();
    },

    redoWatcher() {
      this.redo();
    },

    AddRecordCounter() {
      if (this.AddRecordCounter > 0) {
        let lastRowIndex = this.gridDetailOptions.api.getLastDisplayedRow();
        this.gridDetailOptions.api.selectIndex(lastRowIndex, false, false);
        this.gridDetailOptions.api.setFocusedCell(lastRowIndex, "code");
      }
    },

    SaveCounter() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.SaveCounter > 0) {
        let newRecord = [],
          indexes = [];
        this.gridDetailOptions.api.refreshCells();
        this.gridDetailOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            if (node.data.code != null) {
              this.rowElementData.filter((element) => {
                if (element.code === node.data.code) {
                  if (node.data.id != element.id) {
                    node.data.rowUpdated = "Duplicate";
                    indexes.push(node.rowIndex);
                  }
                }
              });
              newRecord.push(node.data);
            } else {
              newRecord = [];
              indexes = [];
              return false;
            }
          }
        });

        if (indexes.length > 0) {
          this.gridDetailOptions.api.redrawRows();
          this.gridDetailOptions.api.refreshCells();

          newRecord = [];
          indexes = [];

          this.$vToastify.error("Code Value is empty or duplicate!");
        } else {
          this.saveExtraColummData();
        }
      }
    },

    dataWatcher() {
      if (this.dataWatcher > 0) {
        this.rowElementData = this.getDetailData;
        this.gridDetailOptions.api.redrawRows();
        this.gridDetailOptions.api.refreshCells();
        this.resetRequestUndo(false);
        this.resetRequestRedo(false);
        this.gridDetailOptions.api.clearFocusedCell();
        let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
        this.gridDetailOptions.api.selectIndex(
          selectedNodes[0].rowIndex,
          false,
          false
        );
        this.gridDetailOptions.api.setFocusedCell(
          selectedNodes[0].rowIndex,
          "code"
        );
        this.setDeatilId({ selectedId: selectedNodes[0].key });
        this.$vToastify.success("Data successfully saved!");
      }
    },

    DeleteCounter() {
      if (this.DeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes = this.gridDetailOptions.api.getSelectedNodes();
              let selectedData = selectedNodes.map((node) => node.data);
              let deleteIds = [];
              let indices = [];
              selectedData.forEach((x) => {
                deleteIds.push(x.id);
              });
              indices = deleteIds.filter((x) => x > 0);
              if (indices.length > 0) {
                this.DeleteExtraColumn({ IDs: indices });
              }
              this.gridDetailOptions.api.applyTransaction({
                remove: selectedData,
              });
              indices = [];
              selectedNodes = [];
              deleteIds = [];
              selectedData = [];
              this.$vToastify.setSettings(this.NormalSettings);
            }
          });
      }
    },

    isDelete() {
      if (this.isDelete > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.gridDetailOptions.api.redrawRows();
        this.gridDetailOptions.api.refreshCells();
        this.$vToastify.success("Row Deleted Successfully!");
      }
    },
  },

  computed: {
    ...mapGetters("costelementExtraColumnManager", [
      "getRowTypeColumns",
      "getRowTypeData",
      "getDetailData",
      "getDetailsColumns",
      "getTempDate",
    ]),

    ...mapState("costelementExtraColumnManager", [
      "LoadRowTypeCounter",
      "LoadCounter",
      "undoWatcher",
      "redoWatcher",
      "SaveCounter",
      "AddRecordCounter",
      "isDelete",
      "DeleteCounter",
      "dataWatcher",
      "tempData",
    ]),

    noRowsTemplate() {
      if (this.isElementdetail) {
        return this.loadingTemplate;
      } else {
        return `<span>No Rows to Show</span>`;
      }
    },
  },
  methods: {
    ...mapActions("costelementExtraColumnManager", [
      "LoadRowType",
      "LoadExtraColumn",
      "saveExtraColummData",
      "DeleteExtraColumn",
    ]),

    ...mapMutations("costelementExtraColumnManager", [
      "setRowTypeValue",
      "requestUndo",
      "requestRedo",
      "resetRequestRedo",
      "resetRequestUndo",
      "setDeatilId",
    ]),

    onSelectionChanged(event) {
      var selectedRowObjs = event.api.getSelectedNodes();
      this.isElementdetail = false;
      this.SelectId = selectedRowObjs[0].data.id;
      this.tempElementData = [];
      this.rowElementData = [];
      this.setRowTypeValue(this.SelectId);
      this.LoadExtraColumn(selectedRowObjs[0].data.code);
      this.$vToastify.setSettings(this.NormalSettings);
    },

    undo() {
      this.gridDetailOptions.api.undoCellEditing();
    },

    redo() {
      this.gridDetailOptions.api.redoCellEditing();
    },

    cellClicked(params) {
      var selectedRowObjs = params.api.getSelectedNodes();
      this.setDeatilId(selectedRowObjs[0].data.id);
      params.api.refreshCells();
    },

    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();

      if (params.column.colId == "code") {
        var CodeIndex = this.rowElementData.filter(
          (x) => x.id != params.node.data.id
        );
        CodeIndex = CodeIndex.findIndex(
          (x) => x.code.toLowerCase() == params.newValue.toLowerCase()
        );
        if (CodeIndex > -1) {
          this.$vToastify.setSettings(this.NormalSettings);
          this.$vToastify.error("Duplicate code value detected!");
          params.node.data.code = params.newValue;
          params.node.data.rowUpdated = "Duplicate";
          params.api.refreshCells();
        } else {
          params.node.data.rowUpdated = true;
          params.api.refreshCells();
        }
      } else {
        params.node.data.rowUpdated = true;
      }
      params.api.refreshCells();
      if (undoSize == 0) {
        this.resetRequestUndo(false);
        params.node.data.rowUpdated = false;
      } else {
        this.resetRequestUndo(true);
      }

      if (redoSize == 0) {
        this.resetRequestRedo(false);
      } else {
        this.resetRequestRedo(true);
        params.node.data.rowUpdated = false;
      }
      params.api.refreshCells({ force: true, suppressFlash: true });
    },

    onGridReady() {
      getColumnDef(29)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerRowType;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);

          //this.gridStructureOptions.api.setColumnDefs(container);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          this.LoadRowType();
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    reloadColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 29)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridRowTypeOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnChanged: debounce(function () {
      this.onColumnStateSubmit();
    }, 300),

    onColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridRowTypeOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 29,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },

    onDetailsGridReady() {
      // this.loadStructureRows();
      getColumnDef(15)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerExtraColumn;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    onDetailsColumnChanged: debounce(function () {
      this.onColumnDetailsStateSubmit();
    }, 300),

    reloadDetailsColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 15)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridDetailOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnDetailsStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridDetailOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 15,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },
  },

  beforeMount() {
    this.undoRedoCellEditingLimit = 15;
    this.DetailcolumnDefs = [];
    this.rowtypecolumnDefs = [];
  },

  mounted() {},
};

window.getCustomCellRenderer = function getCustomCellRenderer() {
  function CustomCombobox() {}
  var valueTypeFilter = [
    {
      name: "String",
      value: 0,
    },
    {
      name: "Integer",
      value: 1,
    },
    {
      name: "Decimal",
      value: 2,
    },
    {
      name: "DateTime",
      value: 5,
    },
    {
      name: "Boolean",
      value: 6,
    },
    {
      name: "Lookup",
      value: 13,
    },
  ];
  CustomCombobox.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    valueTypeFilter = valueTypeFilter.sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    valueTypeFilter.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      this.eOptionVal.text = x.name;
      this.eOptionVal.value = x.value;
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  CustomCombobox.prototype.getGui = function () {
    return this.eGui;
  };

  CustomCombobox.prototype.getGui = function () {
    return this.eInput;
  };
  CustomCombobox.prototype.afterGuiAttached = function () {
    this.eInput.focus();
  };
  CustomCombobox.prototype.getValue = function () {
    return this.eInput.value;
  };
  CustomCombobox.prototype.destroy = function () {};
  CustomCombobox.prototype.isPopup = function () {
    return false;
  };
  return CustomCombobox;
};
</script>
