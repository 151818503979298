<template>
  <section style="margin-top: -0.48%; width: 100%; height: 100%"></section>
</template>

<script>
export default {
  name: "processSetUIView",
  props: {},
  data() {
    return {};
  },
  components: {},
  watch: {},
  methods: {},

  computed: {},
  mounted() {},
  beforeMount() {},
  created() {},
};
</script>

<style></style>
