<template>
  <div
    style="height: 95vh; width: 96.2vw; background-color: white"
    ref="scriptContainerP3M"
  >
    <GenericToolBar
      :selectedRowParentObjs="selectedGroup"
      :dataDirty="dataDirty"
      :toolbarConfig="toolbarConfig"
      @save="onSaveClick"
    />
    <div class="columns" style="height: 96vh; width: 100%">
      <div class="container-grid" style="width: 100%">
        <div class="box" style="height: 96vh">
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            rowSelection="single"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            :gridOptions="gridOptions"
            :rowData="rowData"
            :columnDefs="columnDefs"
            @selection-changed="onSelectionChanged"
          >
          </ag-grid-vue>
        </div>
        <div class="handler"></div>
        <div
          class="box"
          style="width: 1%; overflow: auto; margin-left: 0.7em"
          :disabled="this.groupSelected != true"
        >
          <ag-grid-vue
            class="ag-theme-balham ag-default-layout"
            enableCellChangeFlash="true"
            stopEditingWhenGridLosesFocus="true"
            :gridOptions="componentGridOptions"
            :rowData="componentRowData"
            :columnDefs="componentColumnDefs"
            @cell-value-changed="onUserCellValueChanged"
          >
          </ag-grid-vue>
          <!-- <data-operation />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import GenericToolBar from "@/components/Data/GenericToolbar.vue";

export default {
  name: "ComponentAccess",
  props: {},
  data() {
    return {
      selectedGroup: {},
      columnDefs: [],
      componentColumnDefs: [],
      rowData: [],
      componentRowData: [],
      gridOptions: {},
      toolbarConfig: {
        showAddNew: false,
        showSave: true,
      },
      componentGridOptions: {
        treeData: true,
        autoGroupColumnDef: {
          headerName: "Path",
          cellRendererParams: {
            suppressCount: true,
          },
        },
        getDataPath: function (data) {
          return data.Path;
        },
      },
      dataDirty: false,
      groupSelected: false,
      rowDragManaged: true,
      tmpId: -1,
    };
  },
  components: {
    AgGridVue,
    GenericToolBar,
  },
  mounted() {
    this.loadAllLicencedComponents();
    this.loadGroupsAndUsers();
  },
  watch: {
    entitiesLoaded() {
      if (this.entitiesLoaded) {
        //this.rowData = [...this.allGroups];
        this.columnDefs = this.defaultGroupColumns;
      }
    },
    rawEntitiesLoaded() {
      //console.log("allRawComponents", this.allRawComponents);
      if (this.rawEntitiesLoaded) {
        //this.componentRowData = this.allRawComponents;
        this.rowData = [...this.allGroupAccess];
        this.componentColumnDefs = this.defaultComponentColumns;
      }
    },
  },
  methods: {
    ...mapActions("groupAdmin", ["loadGroupsAndUsers", "saveGroupsAndMembers"]),
    ...mapActions("jiveComponents", [
      "loadAllLicencedComponents",
      "updateLicencedComponents",
      "saveGroupAccess",
      "addGroupAccess",
    ]),
    onSelectionChanged(event) {
      var selectedRowObjs = event.api.getSelectedNodes();
      if (selectedRowObjs.length > 0) {
        this.updateLicencedComponents(selectedRowObjs[0].data.id);
        this.componentRowData = this.allRawComponents;
      } else this.selectedGroup = null;
      //this.componentGridOptions.api.refreshCells();
    },

    onUserCellValueChanged(event) {
      this.dataDirty = true;
      event.node.data.rowUpdated = true;
      var selectedGrpObj = this.gridOptions.api.getSelectedNodes();
      var selectedGrp = selectedGrpObj[0].data;
      console.log("selectedGrp", selectedGrp);
      selectedGrp.updated = true;
      //Check to see if it already in the list
      var currObj = selectedGrp.groupComponentAccess.find(
        (x) => x.componentId == event.node.data.Id
      );
      if (event.node.data.ShowInMenu) {
        console.log("Adding ", event.node.data);
        if (this.isValid(currObj)) {
          currObj.order = event.node.data.Order;
          currObj.updated = true;
        } else {
          selectedGrp.groupComponentAccess.push({
            componentId: event.node.data.Id,
            order: event.node.data.Order,
            updated: true,
          });
        }
      } else
        selectedGrp.groupComponentAccess =
          selectedGrp.groupComponentAccess.filter(
            (gca) => gca.componentId !== event.node.data.Id
          );
      // if (this.isValid(currObj)) {
      //   currObj.order = event.node.data.Order;
      //   currObj.updated = true;
      // }
    },
    isValid(valueToCheck) {
      if (
        String(valueToCheck) != "" &&
        String(valueToCheck) != "null" &&
        String(valueToCheck) != "undefined"
      )
        return true;
      else {
        return false;
      }
    },
    onSaveClick() {
      this.saveGroupAccess(this.componentRowData);
    },
    onAddNewClick() {
      this.dataDirty = true;
      this.rowData.push({
        id: this.tmpId,
        code: "",
        description: "",
        updated: true,
        Users: [],
      });
      this.UpdateMembershipFlag(this.tmpId);
      --this.tmpId;
    },
  },
  computed: {
    ...mapGetters("groupAdmin", [
      "allGroups",
      "allUsers",
      "defaultUserColumns",
      "defaultGroupColumns",
      "allMemberships",
      "entitiesLoaded",
    ]),
    ...mapGetters("jiveComponents", [
      "rawEntitiesLoaded",
      "allRawComponents",
      "defaultComponentColumns",
      "allGroupAccess",
    ]),
    ...mapGetters("jiveComponents", [
      {
        allComponents: "allRawComponents",
        defaultComponentColumns: "defaultComponentColumns",
      },
    ]),
  },
};
</script>

<style scoped lang="scss">
.container-grid {
  margin-top: 0.1%;
  background-color: #fff;
  color: #444;
  /* Use flexbox */
  display: flex;
}
.box {
  margin-top: 0.1%;
  color: #fff;
  border-radius: 1px;
  padding: 15px;
  font-size: 150%;

  /* Use box-sizing so that element's outerwidth will match width property */
  box-sizing: border-box;

  /* Allow box to grow and shrink, and ensure they are all equally sized */
  flex: 1 1 auto;
}
.handler {
  margin-top: 0.8%;
  position: relative;
  width: 1px;
  padding: 0;
  cursor: col-resize;
  flex: 0 0 auto;
}

.handler::before {
  margin-top: 0.8%;
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background: silver;
  margin: 0 auto;
}
</style>
