import { post } from "../api/user-auth.js";
import {
  getAllExtraColumnDetails,
  DeleteElement,
  getByRiskHeaderId,
} from "@/api/networking.js";

let tempProjectParent = [
    {
      uid: 1,
      id: 1,
      code: "Unassigned ",
      description: "Unassigned ",
      rowType: 2,
      path: [1],
    },
  ],
  tempProgramParent = [],
  tempPortfolioParent = [],
  temprow = null;
/* Create Tree Path */
let data = [],
  i = -1,
  tempData = null;
function getParentLoop(id, path) {
  let p = data.filter((i) => i.id == id)[0];
  if (p.parentId) {
    path.unshift(p.id);
    return getParentLoop(p.parentId, path);
  } else {
    path.unshift(p.id);
  }
  return path;
}

function setpath(data) {
  data.map((i) => {
    i.path = [];
    if (i.parentId) {
      i.path.push(i.id);
      getParentLoop(i.parentId, i.path);
    } else {
      i.path.push(i.id);
    }
    return i;
  });
  return data;
}
const state = {
  /* Module Title*/
  APIName: "risk",
  /*left Side(Parent grid details)*/
  /*CurrentData and details*/
  currentParentData: [],
  currentParentTempData: [],
  currentParentId: null,
  currentParentSelectedObj: [],
  currentParentID: [],
  currentselectedParentRows: [],
  cuurentcopyRowvalues: [],

  /*Main Data Confing Tools*/
  currentParentLoadRecordCounter: 0,
  currentParentAddRecordCounter: 0,
  currentParentSubRecordCounter: 0,
  currentParentDeleteRecordCounter: 0,
  currentParentSaveRecordCounter: 0,

  /*Parent Node Grid Config*/
  currentParentUndoWatcher: 0,
  currentParentRedoWatcher: 0,
  currentParentredostate: false,
  currentParentundostate: false,

  /*Parent Node Grid Config*/
  isRelationship: false,
  currentCostModelType: [],

  /*Commonn Config*/
  isSave: 0,
  isDelete: 0,
  datawatcher: 0,
  SaveError: 0,
  duplicateRecord: 0,

  /* Parent Column Def */
  currentParentColDef: [
    /*  {
      headerName: "Code",
      field: "code",
      editable: function(params) {
        if (params.data.parentId > 0) {
          return true;
        } else {
          return false;
        }
      },
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
       if (params.data != undefined) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
        }
        return null;
      },
      width: 90,
      suppressSizeToFit: true,
      /* cellRenderer: function(params) {
        if (params.data.rowUpdated == "Duplicate") {
          let eIconGui = document.createElement("span");
          return (eIconGui.innerHTML =
            '<i class="fas fa-exclamation" style="color:red;"></i>' +
            " " +
            params.data.code);
        }
        return params.data.code;
      }, 
    },
    {
      headerName: "Description",
      field: "description",
      editable: function(params) {
        if (params.data.parentId > 0) {
          return true;
        } else {
          return false;
        }
      },
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data != undefined) {
          if (params.data.rowUpdated) {
            return { "background-color": "LightGreen" };
          }
        }
        return null;
      },
      width: 300,
    }, */
  ],
  loadStructureCounter: 0,
  currentGridColDefCount: 0,
  currentParentGridColDefCountCounter: 0,
  tempCalenarData: [],
  tempCalenderCounter: 0,
  tempResourceTypeData: [],
  tempResourceCounter: 0,
  currtempData: [],

  /*CurrentData and details*/
  currentChildrenData: [],
  currentchildTempData: [],
  currentChildrenId: null,
  currentChildrenSelectedObj: [],
  currentselectedChildrenRows: [],

  /*Main Data Confing Tools*/
  currentChildrenLoadRecordCounter: 0,
  currentChildrenAddRecordCounter: 0,
  currentChildrenSubRecordCounter: 0,
  currentChildrenDeleteRecordCounter: 0,
  currentChildrenSaveRecordCounter: 0,

  /*Children Node Grid Config*/
  currentChildrenUndoWatcher: 0,
  currentChildrenRedoWatcher: 0,
  currentChildrenredostate: false,
  currentChildrenundostate: false,
  riskTypeData: null,

  currentChildrenColDef: [
    /*  {
      headerName: "",
      rowDrag: true,
      field: "id",
      suppressPaste: true,
      //   cellRendererFramework:"riskCellRenderer",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        // innerRenderer: "riskMetricCellRenderer",
      },
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      width: 100,
      icons: {
        sortAscending: '<i class="fa fa-sort-alpha-up"/>',
        sortDescending: '<i class="fa fa-sort-alpha-down"/>',
        menu: '<i class="fa fa-caret-square-down"/>',
      },
    },*/
    /*   {
      headerName: "Code",
      field: "code",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 90,
      suppressSizeToFit: true,
      cellRenderer: function(params) {
        if (params.data.rowUpdated == "Duplicate") {
          let eIconGui = document.createElement("span");
          return (eIconGui.innerHTML =
            '<i class="fas fa-exclamation" style="color:red;"></i>' +
            " " +
            params.data.code);
        }
        return params.data.code;
      },
    },
    {
      headerName: "Description",
      field: "description",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 300,
    },
    {
      headerName: "Severity",
      field: "riskSeverityId",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.colour) {
          return { "background-color": params.data.colour };
        }
        return null;
      },
      width: 100,
      cellEditor: "RiskSeverityLookupValue",
    },
    {
      headerName: "Owner",
      field: "owner",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 100,
    },
    {
      headerName: "Mitigation",
      field: "mitigation",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 100,
    },
    {
      headerName: "Status",
      field: "currentStatus",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      width: 100,
    },
    {
      headerName: "DueDate",
      field: "dueDate",
      editable: true,
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      cellStyle: function(params) {
        if (params.data.rowUpdated) {
          return { "background-color": "LightGreen" };
        }
        return null;
      },
      valueFormatter: function(params) {
        if (params.value) {
          return new Date(params.value)
            .toISOString()
            .replace(/T.*/
    /*,  "")
            .split("-")
            .reverse()
            .join("/");
        }
        return null;
      },
      width: 100,
      cellEditor: "RiskItemDatePickerCellEditor",
    }, */
  ],
  riskSeveritiesValues: [],
  currentChildrenGridColDefCountCounter: 0,
};

const actions = {
  /*LoadData */
  async loadRiskHeader({ dispatch, commit }) {
    getAllExtraColumnDetails("RiskHeader")
      .then((rowData) => {
        if (rowData.succeeded) {
          commit("setParentData", { returnedData: rowData.data });
          i = -2;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /*RiskType */
  async loadRiskType({ dispatch, commit }) {
    getAllExtraColumnDetails("RiskType")
      .then((rowData) => {
        if (rowData.succeeded) {
          state.riskTypeData = rowData.data;
          i = -2;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
  /*LoadRiskItemData */
  async loadRiskItem({ dispatch, commit }, { riskHeaderId }) {
    getByRiskHeaderId(riskHeaderId)
      .then((rowData) => {
        if (rowData.succeeded) {
          commit("setChildrenData", { returnedData: rowData.data });
          i = -2;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /*LoadData */
  async loadRiskSeverity({ dispatch, commit }) {
    getAllExtraColumnDetails("RiskSeverity")
      .then((rowData) => {
        if (rowData.succeeded) {
          state.riskSeveritiesValues = rowData.data;
          i = -2;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
  /*Extra Column */
  /*Extra Column */
  async LoadHeaderExtraColumnValue({ dispatch }) {
    getAllExtraColumnDetails("RiskHeaderExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentParentColDef = state.currentParentColDef.slice(0, 2);

          rowData.data.forEach((y) =>
            state.currentParentColDef.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 100,
              filterParams: { excelMode: "windows" },
              filter: "agSetColumnFilter",
              editable: function (params) {
                if (params.data.parentId > 0) {
                  return true;
                }
                return false;
              },
              cellStyle: function (params) {
                if (params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                }
                if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return new Date(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.currentParentGridColDefCountCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  async LoadItemExtraColumnValue({ dispatch }) {
    getAllExtraColumnDetails("RiskItemExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentChildrenColDef = []; //state.currentParentColDef.slice(0, 2);

          rowData.data.forEach((y) =>
            state.currentChildrenColDef.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 100,
              editable: true,
              filterParams: { excelMode: "windows" },
              filter: "agSetColumnFilter",
              cellStyle: function (params) {
                if (params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                }
                if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return new Date(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.currentChildrenGridColDefCountCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /**Delete Cost Element */
  async DeleteData({ dispatch }, { APIName, Id }) {
    DeleteElement(APIName, Id)
      .then((rowData) => {
        if (rowData.succeeded) {
          if (APIName == "RiskItem") {
            state.currentChildrenData = state.currentChildrenData.filter(
              (x) => x.id != Id
            );
          } else {
            state.currentParentId = null;
            state.currentParentSelectedObj = [];
          }

          state.currentParentUndoWatcher = 0;
          state.currentParentRedoWatcher = 0;
          state.currentParentredostate = false;
          state.currentParentundostate = false;
          state.isDelete++;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /**Save Cost Element */
  async saveData({ dispatch, commit }) {
    var rowsToInsert = [];
    var rowsToUpdate = [];
    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {};

    state.currentParentTempData.forEach((row) => {
      if (row.rowUpdated) {
        if (row.id <= -1) rowsToInsert.push(row);
        else {
          rowsToUpdate.push({
            Id: row.id,
            code: row.code,
            Description: row.description,
            ExtraColumnValues: row.extraColumnValues,
          });
        }
      }
    });

    if (rowsToInsert.length > 0) {
      createURL = "RiskHeader/Create";

      createObj = { RiskHeadersToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = "RiskHeader/Update";

      updateObj = { RiskHeadersToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          /* if (values)
                        returnedValues = returnedValues.concat(values.data); */
          commit("setSavedParentData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = post(updateURL, updateObj)
        .then((values) => {
          var returnedValues = [];
          if (values[1]) returnedValues = values[1].data;
          commit("setSavedParentData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    rowsToInsert = [];
    rowsToUpdate = [];
    createURL = "";
    updateURL = "";
    createObj = "";
    updateObj = "";
    let temprow = null;
    state.currentChildrenData.forEach((row) => {
      if (row.rowUpdated) {
        if (row.id <= -1) {
          if (row.tempriskSeverityId != undefined) {
            [row.riskSeverityId, row.tempriskSeverityId] = [
              row.tempriskSeverityId,
              row.riskSeverityId,
            ];
          } else {
            row.riskSeverityId = 0;
          }
          if (row.tempriskTypeId != undefined) {
            [row.riskTypeId, row.tempriskTypeId] = [
              row.tempriskTypeId,
              row.riskTypeId,
            ];
          } else {
            row.riskTypeId = 0;
          }
          // row.riskSeverityId = row.tempriskSeverityId;
          // row.riskTypeId = row.tempriskTypeId;
          temprow = { ...row };
          rowsToInsert.push(temprow);
          if (row.tempriskSeverityId != undefined) {
            [row.tempriskSeverityId, row.riskSeverityId] = [
              row.riskSeverityId,
              row.tempriskSeverityId,
            ];
          } else {
            row.riskSeverityId = 0;
          }
          if (row.tempriskTypeId != undefined) {
            [row.tempriskTypeId, row.riskTypeId] = [
              row.riskTypeId,
              row.tempriskTypeId,
            ];
          } else {
            row.riskTypeId = 0;
          }
          /*   [
           */
        } else {
          rowsToUpdate.push({
            Id: row.id,
            code: row.code,
            description: row.description,
            owner: row.owner,
            mitigation: row.mitigation,
            currentStatus: row.currentStatus,
            riskSeverityId: row.tempriskSeverityId,
            dueDate: row.dueDate,
            riskHeaderId: row.riskHeaderId,
            riskTypeId: row.tempriskTypeId,
            ExtraColumnValues: row.extraColumnValues,
          });
        }
      }
    });

    if (rowsToInsert.length > 0) {
      createURL = "RiskItem/Create";

      createObj = { RiskItemsToCreate: rowsToInsert };
    }
    if (rowsToUpdate.length > 0) {
      updateURL = "RiskItem/Update";

      updateObj = { RiskItemsToUpdate: rowsToUpdate };
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          //    state.currentChildrenData=state.currentChildrenData.filter(x=>x.id>0);
          //   state.currentChildrenData.push()
          /* if (values)
                        returnedValues = returnedValues.concat(values.data); */
          commit("setSavedChildrenData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = post(updateURL, updateObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          commit("setSavedChildrenData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
  },
};

const mutations = {
  /*left Side(Parent grid details)*/
  /*CurrentData and details*/

  setParentData(state, { returnedData }) {
    let newRows = [],
      tempid = 2000;
    returnedData.forEach((row) => {
      tempid++;
      if (row != undefined) {
        row.rowUpdated = false;
        if (row.extraColumnValues != null) {
          row.extraColumnValues.forEach(
            (y) => (row["et_" + y.riskHeaderExtraColumnId] = y.value)
          );
        }
        if (row.projects != null) {
          row.projects.forEach(function (parentProjects) {
            temprow = { ...row };
            temprow.uid = ++tempid;
            temprow.parentId = parentProjects.id;
            temprow.path = [parentProjects.id, temprow.uid];
            newRows.push(temprow);
            let TempIndex = tempProjectParent.findIndex(
              (x) => x.id == parentProjects.id
            );
            if (TempIndex == -1) {
              parentProjects.uid = ++tempid;
              parentProjects.parentId = null;
              parentProjects.rowType = 2;
              parentProjects.path = [parentProjects.id];
              tempProjectParent.push(parentProjects);
            }
          });
        }
        if (row.programs != null) {
          //console.log(row.projects.length, row.id);
          row.programs.forEach(function (parentPrograms) {
            temprow = { ...row };
            temprow.uid = ++tempid;
            // temprow.rowType = 3;
            temprow.parentId = parentPrograms.id;
            temprow.path = [parentPrograms.id, temprow.uid];
            newRows.push(temprow);

            let TempIndex = tempProgramParent.findIndex(
              (x) => x.id == parentPrograms.id
            );
            if (TempIndex == -1) {
              parentPrograms.uid = ++tempid;
              parentPrograms.parentId = null;
              parentPrograms.rowType = 1;
              parentPrograms.path = [parentPrograms.id];
              tempProgramParent.push(parentPrograms);
            }
          });
        }

        if (row.portfolios != null) {
          //console.log(row.projects.length, row.id);
          row.portfolios.forEach(function (parentPortfolios) {
            temprow = { ...row };
            temprow.uid = ++tempid;
            // temprow.rowType = 3;
            temprow.parentId = parentPortfolios.id;
            temprow.path = [parentPortfolios.id, temprow.uid];
            newRows.push(temprow);

            let TempIndex = tempPortfolioParent.findIndex(
              (x) => x.id == parentPortfolios.id
            );
            if (TempIndex == -1) {
              parentPortfolios.uid = ++tempid;
              parentPortfolios.parentId = null;
              parentPortfolios.rowType = 0;
              parentPortfolios.path = [parentPortfolios.id];
              tempPortfolioParent.push(parentPortfolios);
            }
          });
        }
        if (
          row.projects == null &&
          row.programs == null &&
          row.portfolios == null
        ) {
          row.uid = ++tempid;
          row.parentId = 1;
          row.path = [1, row.id];
          temprow = row;
          newRows.push(temprow);
        }
      }
    });
    returnedData = newRows;
    tempProjectParent.forEach((row) => {
      returnedData.push(row);
    });
    tempProgramParent.forEach((row) => {
      returnedData.push(row);
    });

    tempPortfolioParent.forEach((row) => {
      returnedData.push(row);
    });

    state.currentParentData = returnedData.sort((a, b) =>
      a.code > b.code ? 1 : -1
    );
    //state.currentParentData = returnedData;
    /*Reset the Parent Grid property */
    state.currentParentId = null;
    state.currentParentSelectedObj = [];
    state.currentParentDeleteRecordCounter = 0;
    state.currentParentUndoWatcher = 0;
    state.currentParentRedoWatcher = 0;
    state.currentParentredostate = false;
    state.currentParentundostate = false;
    state.currentParentAddRecordCounter = 0;
    state.currentParentLoadRecordCounter++;
  },

  setSavedParentData(state, { returnedData }) {
    //returnedData.forEach(row => (row.rowUpdated = false));
    returnedData.forEach((row) => {
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.riskHeaderExtraColumnId] = y.value)
        );
      }

      if (
        row.projects == null &&
        row.programs == null &&
        row.portfolios == null
      ) {
        row.parentId = 1;
        // row.rowType = 3;
        row.path = [1, row.id];
        row.uid = -9999;
      }
    });
    //  data = returnedData;
    // tempData = setpath(data);
    state.currtempData = returnedData;
    /*Reset the Parent Grid property */
    state.currentParentUndoWatcher = 0;
    state.currentParentRedoWatcher = 0;
    state.currentParentredostate = false;
    state.currentParentundostate = false;
    state.datawatcher++;
  },

  setParentObj(state, row) {
    state.currentParentSelectedObj = row;
    state.currentParentId = row.id;
  },

  resetParentObj(state, row) {
    state.currentParentSelectedObj = null;
    state.currentParentId = null;
  },

  setSelectedParentRowObject(state, row) {
    state.currentselectedParentRows = row;
    //state.currentParentSelectedObj = row;
    // state.currentParentId = row.id;
  },

  /*mutate counter for CRUD*/
  mutateParentAddRecord(state) {
    state.currentParentAddRecordCounter++;
  },

  mutateParentDelete(state) {
    state.currentParentDeleteRecordCounter++;
  },

  mutateParentSave(state) {
    state.currentParentSaveRecordCounter++;
  },

  /*Parent Grid Config*/
  mutateParentRedo(state) {
    state.currentParentRedoWatcher++;
  },

  mutateParentUndo(state) {
    state.currentParentUndoWatcher++;
  },

  mutateParentRedostate(state, value) {
    state.currentParentredostate = value;
  },

  mutateParentUndostate(state, value) {
    state.currentParentundostate = value;
  },

  mutateElementRealtionship(state, isRelationship) {
    state.isRelationship = isRelationship;
  },

  //Risk Items
  setChildrenData(state, { returnedData }) {
    var tempdata11 = null;
    returnedData.forEach((row) => {
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.riskItemExtraColumnId] = y.value)
        );
      }
      if (row.riskSeverityId > 0) {
        tempdata11 = state.riskSeveritiesValues.filter(
          (x) => x.id == row.riskSeverityId
        );
        row.riskSeverityId = tempdata11[0].description;
        row.tempriskSeverityId = tempdata11[0].id;
        row.colour = tempdata11[0].colour;
      }
      tempdata11 = null;
      if (row.riskTypeId > 0) {
        tempdata11 = state.riskTypeData.filter((x) => x.id == row.riskTypeId);
        row.riskTypeId = tempdata11[0].description;
        row.tempriskTypeId = tempdata11[0].id;
      }
    });
    data = returnedData;
    tempData = setpath(data);
    state.currentChildrenData = returnedData;
    /*Reset the Children Grid property */
    state.currentChildrenId = null;
    state.currentChildrenSelectedObj = [];
    state.currentChildrenDeleteRecordCounter = 0;
    state.currentChildrenUndoWatcher = 0;
    state.currentChildrenRedoWatcher = 0;
    state.currentChildrenredostate = false;
    state.currentChildrenundostate = false;
    state.currentChildrenAddRecordCounter = 0;
    state.currentChildrenLoadRecordCounter++;
  },

  setSavedChildrenData(state, { returnedData }) {
    //returnedData.forEach(row => (row.rowUpdated = false));
    var tempdata11 = null;
    //state.currentChildrenData = state.currentChildrenData.filter(x => x.id > 0);
    returnedData.forEach((row) => {
      row.rowUpdated = false;
      if (row.extraColumnValues != null) {
        row.extraColumnValues.forEach(
          (y) => (row["et_" + y.riskItemExtraColumnId] = y.value)
        );
      }
      if (row.riskSeverityId > 0) {
        tempdata11 = state.riskSeveritiesValues.filter(
          (x) => x.id == row.riskSeverityId
        );

        row.riskSeverityId = tempdata11[0].description;
        row.tempriskSeverityId = tempdata11[0].id;
        row.colour = tempdata11[0].colour;
      }
      tempdata11 = null;
      if (row.riskTypeId > 0) {
        tempdata11 = state.riskTypeData.filter((x) => x.id == row.riskTypeId);
        row.riskTypeId = tempdata11[0].description;
        row.tempriskTypeId = tempdata11[0].id;
      }
    });
    data = returnedData;
    tempData = setpath(data);
    state.currentchildTempData = returnedData;
    /*Reset the Children Grid property */
    state.currentChildrenUndoWatcher = 0;
    state.currentChildrenRedoWatcher = 0;
    state.currentChildrenredostate = false;
    state.currentChildrenundostate = false;
    state.datawatcher++;
  },

  setChildrenObj(state, row) {
    state.currentChildrenSelectedObj = row;
    state.currentChildrenId = row.id;
  },

  setSelectedChildrenRowObject(state, row) {
    state.currentselectedChildrenRows = row;
    //state.currentChildrenSelectedObj = row;
    // state.currentChildrenId = row.id;
  },

  /*mutate counter for CRUD*/
  mutateChildrenAddRecord(state) {
    state.currentChildrenData.push({
      id: -1,
      code: "",
      description: "",
      status: "",
      owner: "",
      mitigation: "",
      currentStatus: "",
      riskSeverityId: "",
      riskTypeId: "",
      tempRiskSeverityId: "",
      dueDate: "",
      riskHeaderId: state.currentParentId,
      //public long RiskTypeId { get; set; }
      rowUpdated: true,
      extraColumnValues: [],
    });
    state.currentChildrenAddRecordCounter++;
  },

  mutateChildrenAddSubRecord(state) {
    state.currentChildrenSubRecordCounter++;
  },

  mutateChildrenDelete(state) {
    state.currentChildrenDeleteRecordCounter++;
  },

  mutateChildrenSave(state) {
    state.currentChildrenSaveRecordCounter++;
  },

  /*Children Grid Config*/
  mutateChildrenRedo(state) {
    state.currentChildrenRedoWatcher++;
  },

  mutateChildrenUndo(state) {
    state.currentChildrenUndoWatcher++;
  },

  mutateChildrenRedostate(state, value) {
    state.currentChildrenredostate = value;
  },

  mutateChildrenUndostate(state, value) {
    state.currentChildrenundostate = value;
  },

  mutateRiskHeaderRealtionship(state, isRelationship) {
    state.isRelationship = isRelationship;
  },
  mutateParentRecord(state, { returnedNewData }) {
    state.currentParentTempData = [];
    returnedNewData.forEach((element) => {
      if (element.rowUpdated) {
        state.currentParentTempData.push(element);
      }
    });
    //state.currentchildTempData = returnedNewData;
  },
};

const getters = {
  /*left Side(Parent grid details)*/
  /*CurrentData and details*/
  getParentGridColumn() {
    return state.currentParentColDef;
  },

  getParentCurrentData() {
    return state.currentParentData;
  },

  getParentTempData() {
    return state.currentParentTempData;
  },

  getParentSelectedObj() {
    return state.currentParentSelectedObj;
  },
  getParentSelectedId() {
    return state.currentParentId;
  },
  /*Extra Config */

  getCopiedRows() {
    return state.cuurentcopyRowvalues;
  },

  getChildrenGridColumn() {
    return state.currentChildrenColDef;
  },

  getChildrenCurrentData() {
    return state.currentChildrenData;
  },

  getChildrenSelectedObj() {
    return state.currentChildrenSelectedObj;
  },
  getChildrenSelectedId() {
    return state.currentChildrenId;
  },
  getRiskSeveritiesData() {
    return state.riskSeveritiesValues;
  },

  getriskHeaderStatus() {
    return state.isRelationship;
  },
  getChildTempData() {
    return state.currtempData;
  },
  getRiskTypeData() {
    return state.riskTypeData;
  },
  gettempChildData() {
    return state.currentchildTempData;
  },
};

export const riskController = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
