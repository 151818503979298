<template>
  <div style="margin-left: 25px">
    <label class="label">Select company logo</label>
    <div class="control">
      <div class="file has-name">
        <label class="file-label">
          <input
            class="file-input"
            type="file"
            name="resume"
            id="file"
            ref="file"
            v-on:change="handleFileUpload()"
          />
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label"> Choose a file… </span>
          </span>
          <span class="file-name"> {{ clientLogoPath }} </span>
        </label>
      </div>
    </div>
    <button v-on:click="submitFile()">Submit</button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "ClientAdmin",
  data() {
    return {
      file: "",
    };
  },
  methods: {
    ...mapMutations("clientAdmin", ["setClientLogoPath"]),
    submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      //     axios.post( '/single-file',
      //         formData,
      //         {
      //         headers: {
      //             'Content-Type': 'multipart/form-data'
      //         }
      //       }
      //     ).then(function(){
      //   console.log('SUCCESS!!');
      // })
      // .catch(function(){
      //   console.log('FAILURE!!');
      // });
    },
    handleFileUpload() {
      this.setClientLogoPath(this.$refs.file.files[0]);
    },
  },
  watch: {
    // clientLogoPath(oldVal, newValue){
    //     this.store.getClientLogo;
    // },
  },
  computed: {
    //...mapGetters("clientAdmin", ["getClientLogo"]),
    ...mapState("clientAdmin", ["clientLogoPath"]),
  },
};
</script>

<style scoped lang="scss"></style>
