<template>
  <nav
    class="navbar"
    role="navigation"
    aria-label="main navigation"
    style="border-bottom: 1px solid"
  >
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start"></div>
      <div class="navbar-end">
        <button
          class="button"
          title="Save"
          @click="saveRecords"
          v-if="currentRouteName != 'login'"
          :disabled="Object.keys(getSelectedRowObject).length < 1"
        >
          <span class="icon is-small">
            <i class="fas fa-save"></i>
          </span>
        </button>
        <button
          class="button"
          title="Reset Columns"
          v-if="currentRouteName == 'login'"
          disabled
        >
          <span class="icon is-small">
            <i class="fas fa-text-width"></i>
          </span>
        </button>

        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Undo"
          @click="requestUndo"
          :disabled="getisUndo == false"
        >
          <span class="icon is-small">
            <i class="fas fa-undo"></i>
          </span>
        </button>

        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Redo"
          @click="requestRedo"
          :disabled="getisRedo == false"
        >
          <span class="icon is-small">
            <i class="fas fa-redo"></i>
          </span>
        </button>

        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add New"
          @click="addDataRecord"
        >
          <span class="icon is-small">
            <i class="fas fa-plus"></i>
          </span>
        </button>

        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Delete"
          @click="mutateDelete"
          :disabled="Object.keys(getSelectedRowObject).length < 1"
        >
          <!-- @click="deleteRecord"> -->
          <span class="icon is-small">
            <i class="fas fa-trash-alt"></i>
          </span>
        </button>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
p {
  width: 165px;
  display: inline-block;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}
nav.navbar {
  border-top: 1px solid black;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
</style>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "UserAdminToolBar",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("userManagement", [
      "getSelectedRowObject",
      "getisRedo",
      "getisUndo",
    ]),
    ...mapState({
      showFilterModal: (state) =>
        state.filterControl.isStructureFilterModalVisible,
    }),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapMutations("userManagement", [
      "addDataRecord",
      "requestUndo",
      "requestRedo",
      "mutateDelete",
    ]),
    ...mapActions("userManagement", ["saveUser"]),
    saveRecords() {
      this.saveUser();
    },
  },
};
</script>
