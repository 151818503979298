//all data used to display on dashboard
import * as moment from "moment";
import localization from "moment/locale/en-au";
import {
  getRemoteRow,
  getAuthoritiesValues,
  getMonthColumnsJSON,
} from "@/api/networking.js";

/////////////////////////// bar chart (life-to-date)  //////////////////////////////////
const barChart_Actuals = "rgb(11, 127, 171)";
const barChart_EV = "rgb(240, 94, 27)";
const barChart_BudgetToDate = "rgb(153,153,0)";
const barChart_LifeBudget = "rgb(0,0,0)";

/////////////////////////// bar chart (AUTHORITIES)  //////////////////////////////////
const barChart_Original_budget = "rgb(105,105,105)";
const barChart_Extra = "rgb(255,0,0)";
const barChart_Contigency = "rgb(49,49,49)";

//////////////////////////////////////// data used in Bar chart

var yvalue = [300000, 700000, 100000, 400000, 100000];
var yvalue1 = [500000, 600000, 600000, 700000, 800000];
var yvalue2 = [30000, 70000, 10000, 40000, 10000];

var Actuals = {
  y: 0,
  x: 0,
  name: "Actual",
  orientation: "h",
  type: "bar",
  width: 0.4,
  barmode: "overlay",
  hoverinfo: "x",
  marker: {
    opacity: 0.9,
    color: barChart_Actuals,
    line: {
      width: 1,
    },
  },
};

var EV = {
  y: 0,
  x: 0,
  name: "EV",
  type: "bar",
  orientation: "h",
  width: 0.4,
  barmode: "overlay",
  hoverinfo: "x",
  marker: {
    opacity: 0.6,
    color: barChart_EV,
    line: {
      width: 1,
    },
  },
};

var Budget = {
  y: 0,
  x: 0,
  name: "Budget",
  type: "bar",
  width: 0.4,
  barmode: "overlay",
  orientation: "h",
  hoverinfo: "x",
  marker: {
    opacity: 0.6,
    color: barChart_LifeBudget,
    line: {
      width: 1,
    },
  },
};

var ForcastCAC = {
  y: 0,
  x: 0,
  name: "CAC",
  type: "bar",
  orientation: "h",
  barmode: "overlay",
  width: 0.4,
  hoverinfo: "x",
  marker: {
    opacity: 0.4,
    color: barChart_BudgetToDate,
    line: {
      width: 1,
    },
  },
};

//////////////////////////////////////// TrendFY year Function
const options = { year: "numeric", month: "short" };
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
var storeX = [];
function AccumulatedValues(startDate, Trendlength) {
  startDate = new Date(
    startDate[1],
    monthNames.findIndex((x) => x == startDate[0]) - 1
  );
  for (let i = 0; i < Trendlength; i++) {
    storeX.push(
      new Date(startDate.setMonth(startDate.getMonth() + 1)).toLocaleDateString(
        undefined,
        options
      )
    );
  }
  return storeX;
}

//////////////////////////////////////// Get Highest Projects

function getAllIndexes(arr, val) {
  var indexes = [],
    i;
  for (i = 0; i < arr.length; i++)
    if (arr[i].projectcode === val) indexes.push(i);
  return indexes;
}

//////////////////////////////////////// financial year
var financial_year = null;
function getCurrentFinancialYear(reportDate) {
  var getMonth = reportDate.getMonth() + 1;
  if (getMonth < 7) {
    financial_year = [
      "Jul " + (reportDate.getFullYear() - 1),
      "Jun " + reportDate.getFullYear(),
    ];
  } else {
    financial_year = [
      "Jul " + reportDate.getFullYear(),
      "Jun " + (reportDate.getFullYear() + 1),
    ];
  }
  return financial_year;
}

//////////////////////////////////////// Trend Line Graph Shadow

function getTrendPoint(datavalue, st_ed) {
  var index = datavalue.findIndex(function (element, i) {
    if (element == st_ed) {
      return i;
    }
    return 0;
  });
  return index;
}

//////////////////////////////////////// Life To Date

var LifeActuals = {
  y: ["<b> Life To Date </b>"],
  x: [500000],
  name: "Actual",
  type: "bar",
  width: 0.4,
  orientation: "h",
  hoverinfo: "x",
  marker: {
    opacity: 0.7,
    color: barChart_Actuals,
    line: {
      width: 1,
    },
  },
};

var LifeEV = {
  y: ["<b> Life To Date </b>"],
  x: [1000000],
  name: "EV",
  type: "bar",
  width: 0.4,
  orientation: "h",
  hoverinfo: "x",
  marker: {
    opacity: 0.3,
    color: barChart_EV,
    line: {
      width: 1,
    },
  },
};

var LifetoBudget = {
  y: ["<b> Life To Date </b>"],
  x: [1500000],
  name: "Budget To Date",
  type: "bar",
  width: 0.4,
  orientation: "h",
  hoverinfo: "x",
  marker: {
    opacity: 0.3,
    color: barChart_BudgetToDate,
    line: {
      width: 1,
    },
  },
};

var LifeBudget = {
  y: ["<b> Life To Date </b>"],
  x: [4000000],
  name: "Budget",
  type: "bar",
  width: 0.4,
  orientation: "h",
  hoverinfo: "x",
  marker: {
    opacity: 0.3,
    color: barChart_LifeBudget,
    line: {
      width: 1,
    },
  },
};

//////////////////////////////////////// Authoritites

var Authorititesoriginal = {
  y: ["<b> AUTHORITIES </b>"],
  x: [3000000],
  name: "Original Budget",
  type: "bar",
  width: 0.4,
  orientation: "h",
  hoverinfo: "x",
  marker: {
    color: barChart_Original_budget,
    line: {
      width: 1,
    },
  },
};

var Authorititesextra = {
  y: ["<b> AUTHORITIES </b>"],
  x: [1000000],
  name: "Extra",
  type: "bar",
  width: 0.4,
  orientation: "h",
  hoverinfo: "x",
  marker: {
    color: barChart_Extra,
    line: {
      width: 1,
    },
  },
};

var Authorititescontingency = {
  y: ["<b> AUTHORITIES </b>"],
  x: [500000],
  name: "Contigency",
  type: "bar",
  width: 0.4,
  orientation: "h",
  hoverinfo: "x",
  marker: {
    color: barChart_Contigency,
    line: {
      width: 1,
    },
  },
};

///////////////////////////////////////// Currency
function AUD(n, currency) {
  if (n != undefined) {
    return (
      currency +
      n
        .toFixed()
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }
  return 0;
}

//////////////////////////////////////// trend Graph

function TrendingGraph(input, array) {
  for (let i = 0; i < input.length; i++) {
    if (typeof array[i] === "undefined") {
      array.push(input[i]);
    } else {
      if (isFinite(input[i])) {
        array[i] += input[i];
      }
    }
  }
  return array;
}

//////////////////////////////////////// Remove Last element

function Removetrail(inputval) {
  while (inputval[inputval.length - 1] === 0) {
    // While the last element is a 0,
    inputval.pop(); // Remove that last element
  }
  return inputval;
}

//////////////////////////////////////// Remove Zero Value from element

function RemoveZero(inputval) {
  for (var i = 0; i < inputval.length; i++) {
    if (inputval[i] == 0 && i > 0) {
      inputval[i] = inputval[i - 1];
    }
  }
  return inputval;
}
//////////////////////////////////////// Chart API

const state = {
  dashboardName:
    "SI- 00362 | ARA NEW 33KV SUPPLY TO WESTCONNEX STG 3B | Andrew Taylor | Execution | Gate 3: 04/02/2021 | PC: 30/11/2022",
  loadingStatus: false,
  cards: [
    {
      id: 0,
      formate: "layout1",
      class: "is-12",
      heading: "",
      charts: [],
    },

    {
      //KPI
      id: 1,
      class: "is-12",
      title: "KPI",
      h: window.innerWidth,
      heading: "REPORTING DATE: ",
      charts: [
        {
          //SPI
          id: 1,
          type: 2,
          title: "KPI",
          formate: "layout1",
          class: "is-12",
          data: [
            {
              type: "indicator",
              mode: "gauge+delta+number",
              title: { text: "SPI" },
              value: 0.08,
              number: { suffix: "%" },
              delta: { reference: 100, relative: true },
              gauge: {
                axis: { visible: true, tickformat: "", range: [0, 200] },
                bar: { color: "rgb(255,0,0)" },
              },
              domain: { x: [0, 0.95], y: [0, 1] },
            },
          ],
          layout: {
            margin: { t: 0, b: 0, l: 25, r: 40 },
            height: 275,
            width: 280,
          },
          config: {
            responsive: true,
          },
        },
        {
          id: 2,
          type: 3,
          title: "KPI",
          formate: "layout1",
          class: "is-7",
          data: [
            {
              //CPI
              type: "indicator",
              mode: "gauge+delta+number",
              title: { text: "CPI" },
              value: 130,
              number: { suffix: "%" },
              delta: { reference: 100, relative: true },
              gauge: {
                axis: { visible: true, range: [0, 200] },
                bar: { color: "(0,128,0)" },
              },
              domain: { x: [0, 0.95], y: [0, 1] },
            },
          ],
          layout: {
            margin: { t: 0, b: 10, l: 50, r: 25 },
            height: 275,
            width: 280,
          },
          config: {
            responsive: true,
          },
        },
        {
          // Line graph
          id: 3,
          type: 4,
          title: "LIFE-TO-DATE",
          formate: "layout1",
          class: [],
          data: [LifeActuals, LifeEV, LifetoBudget, LifeBudget],
          layout: {
            barmode: "overlay",
            margin: { t: 0, r: 0, l: 120, b: 50 },
            showlegend: true,
            xaxis: {
              hoverformat: "$,.0f",
            },
            height: 110,
            width: 550,
            hovermode: "closest",
            legend: {
              orientation: "h",
              yanchor: "bottom",
              y: 1.02,
              xanchor: "right",
              x: 1,
            },
          },

          config: {
            responsive: true,
          },
        },
        {
          // Line graph
          id: 4,
          type: 4,
          title: "LIFE-TO-DATE",
          formate: "layout1",
          class: [],
          data: [
            Authorititesoriginal,
            Authorititesextra,
            Authorititescontingency,
          ],
          layout: {
            barmode: "stack",
            margin: { t: 0, r: 0, l: 120, b: 50 },
            showlegend: true,
            hovermode: "closest",
            height: 110,
            width: 610,
            xaxis: {
              hoverformat: "$,.0f",
            },
            legend: {
              orientation: "h",
              yanchor: "bottom",
              y: 1.02,
              xanchor: "right",
              x: 1,
            },
          },

          config: {
            responsive: true,
          },
        },
        {
          //table
          id: 5,
          type: 2,
          title: "",
          formate: "layout1",
          class: [],
          data: [
            {
              type: "table",
              header: {
                values: [["<b> % Complete</b>"]],
                align: "center",
                line: { width: 1, color: "black" },
                fill: { color: "lightgrey" },
                font: { family: "Verdana", size: 12, color: "black" },
              },
              cells: {
                values: [["<b>63%</b>"]],
                align: "center",
                line: { color: "black", width: 1 },
                font: { family: "Verdana", size: 15, color: ["black"] },
              },
            },
          ],
          layout: {
            margin: { t: 50, b: 0, l: 25, r: 2 },
            height: 130,
            width: 160,
          },
          config: {
            responsive: true,
          },
        },
        {
          id: 6,
          type: 2,
          title: "LIFE-TO-DATE VARIANCES",
          class: [],
          formate: "layout1",
          data: [
            {
              type: "table",
              header: {
                values: [["Budget Var."], ["<b>$1,500,000</b>"]],
                align: "center",
                fill: { color: ["lightgrey", "white"] },
                line: { color: "black", width: 1 },
                font: {
                  family: "Verdana",
                  size: 12,
                  color: ["black", "green"],
                },
              },
              cells: {
                values: [
                  ["Cost Var", "Schedule Var"],
                  ["<b>$234,567</b>", "<b>$765,433</b>"],
                ],
                align: "center",
                fill: { color: ["lightgrey", "white"] },
                line: { color: "black", width: 1 },
                font: {
                  family: "Verdana",
                  size: 12,
                  color: ["black", ["green", "red"]],
                },
              },
            },
          ],
          layout: {
            margin: { t: 50, b: 0, l: 20, r: 2 },
            height: 150,
            width: 300,
            color: "rgb(255,255,255)",
            title: "<b>   LIFE-TO-DATE VARIANCES</b>",
          },
          config: {
            responsive: true,
          },
        },
        {
          id: 7,
          type: 2,
          title: "FINANCIAL YEAR",
          class: [],
          formate: "layout1",
          data: [
            {
              type: "table",
              header: {
                values: [["Budget"], ["<b>0</b>"]],
                align: "center",
                fill: { color: ["grey", "white"] },
                line: { color: "black", width: 1.0 },
                font: {
                  family: "Verdana",
                  size: 12,
                  color: ["white", "green"],
                },
              },
              cells: {
                values: [
                  ["Spend", "CAC"],
                  ["<b>0</b>", "<b>0</b>"],
                ],
                align: "center",
                fill: { color: ["grey", "white"] },
                line: { color: "black", width: 1 },
                font: {
                  family: "Verdana",
                  size: 12,
                  color: ["white", ["green", "red"]],
                },
              },
            },
          ],
          layout: {
            margin: { t: 50, b: 0, l: 25, r: 2 },
            height: 150,
            width: 280,
            title: "<b>    FINANCIAL YEAR </b>",
          },
          config: {
            responsive: true,
          },
        },
      ],
    },

    {
      //TRENDS
      id: 2,
      class: "is-12",
      title: "TRENDS",
      charts: [
        {
          id: 1,
          type: 2,
          class: [],
          formate: "layout2",
          data: [
            {
              x: [
                "01/01/2020",
                "01/04/2020",
                "01/07/2020",
                "01/10/2020",
                "01/01/2021",
                "01/04/2021",
                "01/07/2021",
                "01/10/2021",
                "01/01/2022",
                "01/04/2022",
              ],
              y: [100, 101, 108, 110, 152, 300, 880, 960, 990, 1000],
              mode: "lines+markers",
              name: "Budget",
              //text: ['tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object'],
              line: { shape: "spline", color: barChart_Contigency },
              type: "scatter",
              hoverinfo: "y",
            },
            {
              x: [
                "01/01/2020",
                "01/04/2020",
                "01/07/2020",
                "01/10/2020",
                "01/01/2021",
                "01/04/2021",
                "01/07/2021",
                "01/10/2021",
                "01/01/2022",
                "01/04/2022",
              ],
              y: [80, 100, 107, 121, 170, 250, 600, 700],
              mode: "lines+markers",
              name: "Actual",
              //  text: ['tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object'],
              line: { shape: "spline", color: barChart_Actuals },
              type: "scatter",
              hoverinfo: "y",
            },
            {
              x: [
                "01/01/2020",
                "01/04/2020",
                "01/07/2020",
                "01/10/2020",
                "01/01/2021",
                "01/04/2021",
                "01/07/2021",
                "01/10/2021",
                "01/01/2022",
                "01/04/2022",
              ],
              y: [100, 110, 145, 180, 240, 350, 930, 970],
              mode: "lines+markers",
              name: "EV",
              //  text: ['tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object', 'tweak line smoothness<br>with "smoothing" in line object'],
              line: { shape: "spline", color: "rgb(240, 94, 27)" },
              type: "scatter",
              hoverinfo: "y",
            },
          ],
          layout: {
            margin: { t: 20, b: 70, l: 35, r: 60 },
            height: 350,
            width: 630,
            hovermode: "closest",
            xaxis: {
              showgrid: true,
              tickformat: "%b %d",
              showline: true,
              linewidth: 2,
              linecolor: "black",
              ticks: "auto",
              tickwidth: 2,
              tickcolor: "crimson",
              ticklen: 2,
            },
            yaxis: {
              hoverformat: "$,.0f",
              showline: true,
              linewidth: 2,
              linecolor: "black",
              ticks: "auto",
              tickwidth: 2,
              tickcolor: "crimson",
              ticklen: 2,
            },
            legend: {
              traceorder: "reversed",
              font: { size: 16 },
              yref: "paper",
              orientation: "h",
              yanchor: "bottom",
              y: 1.02,
              xanchor: "right",
              x: 1,
            },
            shapes: [
              {
                type: "box",
                x0: "01/07/2020",
                y0: 0,
                x1: "01/072021",
                yref: "paper",
                y1: 1,
                opacity: 0.5,
                annotation_text: "decline",
                annotation_position: "top left",
                annotation: {
                  size: 20,
                  family: "Times New Roman",
                },
                //title:"<b> 01/07/2020 - 30/6/2021 2020-2021</b>",
                fillcolor: "darkgrey",
                line: {
                  color: "grey",
                  width: 0.2,
                  dash: "dot",
                },
              },
            ],
          },
          config: {
            responsive: true,
          },
        },
        {
          id: 2,
          type: 3,
          formate: "layout2",
          class: [],
          data: [
            {
              x: [
                "Development",
                "Design",
                "Management",
                "Procurement",
                "Construction",
              ],
              y: yvalue,
              text: yvalue,
              name: "Actual",
              type: "bar",
              offsetgroup: 0,
              textposition: "auto",
              hovertemplate: yvalue,
              marker: {
                color: barChart_Actuals, //['rgb(0,0,0)', 'rgb(255,0,0)', 'rgb(0,0,0)', 'rgb(0,0,0)', 'rgb(0,0,0)'],
                opacity: 0.6,
                line: {
                  color: "rgb(0,0,0)",
                  width: 2,
                },
              },
            },
            {
              x: [
                "Development",
                "Design",
                "Management",
                "Procurement",
                "Construction",
              ],
              y: yvalue2,
              text: yvalue2,
              name: "Commitment",
              // type: 'bar',
              offsetgroup: 0,
              base: yvalue,
              textposition: "auto",
              hovertemplate: yvalue2,
              type: "bar",
              marker: {
                color: barChart_BudgetToDate,
                opacity: 0.6,
                line: {
                  color: "rgb(0,0,0)",
                  width: 2,
                },
              },
            },
            {
              x: [
                "Development",
                "Design",
                "Management",
                "Procurement",
                "Construction",
              ],
              y: yvalue1,
              text: yvalue1,
              name: "Budget",
              type: "bar",
              offsetgroup: 1,
              textposition: "auto",
              hovertemplate: yvalue1,
              marker: {
                color: barChart_Contigency,
                opacity: 0.6,
                line: {
                  color: "rgb(0,0,0)",
                  width: 2,
                },
                font: {
                  color: "black",
                },
              },
            },
          ],
          layout: {
            margin: { t: 40, b: 70, l: 50, r: 0 },
            height: 350,
            width: 720,
            barmode: "overlay",
            hovermode: "closest",
            legend: {
              font: {
                color: "black",
              },
              orientation: "h",
              yanchor: "bottom",
              // y: 1.02,
              y: 0.97,
              xanchor: "right",
              x: 0.95,
              traceorder: "reversed",
              yref: "paper",
            },
            xaxis: {
              showgrid: true,
              showline: true,
              linewidth: 2,
              tickangle: 25,
              linecolor: "black",
              ticks: "auto",
              tickwidth: 2,
              tickcolor: "crimson",
              ticklen: 2,
            },
            yaxis: {
              hoverformat: "$,.0f",
              showline: true,
              linewidth: 2,
              linecolor: "black",
              ticks: "auto",
              tickwidth: 2,
              tickcolor: "crimson",
              ticklen: 2,
            },
          },
          config: {
            responsive: true,
          },
        },
      ],
    },

    {
      //KEY AREAS
      id: 3,
      class: "is-12",
      title: "KEY AREAS",
      charts: [
        {
          id: 1,
          type: 4,
          formate: "layout2",
          title: "NETWORK ACTIVITIES",
          class: [],
          data: [Budget, ForcastCAC, EV, Actuals],
          layout: {
            barmode: "overlay",
            margin: { t: 50, r: 30, l: 170, b: 27 },
            showlegend: true,
            height: 300,
            width: 630,
            title: "<b>   NETWORK ACTIVITIES</b>",
            legend: {
              orientation: "h",
              yanchor: "bottom",
              y: 0.99,
              xanchor: "right",
              x: 0.75,
            },
            hovermode: "closest",
            xaxis: {
              hoverformat: "$,.0f",
              showgrid: true,
              showline: true,
              linewidth: 2,
              linecolor: "black",
              ticks: "auto",
              tickwidth: 2,
              tickcolor: "crimson",
              ticklen: 2,
            },
            yaxis: {
              showline: true,
              linewidth: 2,
              linecolor: "black",
              tickangle: 0,
              ticks: "auto",
              ticklen: 1.5,
            },
          },
          config: {
            responsive: true,
          },
        },
        {
          id: 2,
          type: 2,
          title: "",
          formate: "layout2",
          class: [],
          data: [
            {
              type: "table",
              header: {
                values: [["<b> </b>"]],
                align: "left",
                line: { color: "black" },
                fill: { color: "white" },
                font: { family: "verdana ", size: 12, color: "black" },
              },
              /*   cells: {
                              values: [
                                  ["<b>PM executive summary:</b>"], [""]],
                              align: "center",
                              line: { color: "black" },
                              font: { family: "verdana ", size: 11, color: "black" },
                          }, */
            },
          ],
          layout: {
            margin: { t: 80, b: 5, l: 5, r: 5 },
            height: 300,
            width: 700,
          },
          config: {
            responsive: true,
          },
        },
      ],
    },
  ],
};

const actions = {
  LoadDefaultDashboard({ dispatch, commit }, { reportDate, viewID }) {
    getMonthColumnsJSON(viewID)
      .then((monthsData) => {
        //Examine monthsData for the header that has the month and year
        // Pass that with the rowData
        //var startMonth = convertedHeader;
        getRemoteRow(viewID)
          .then((rowData) => {
            if (rowData.length > 0) {
              var projectID = rowData[0].uid.split("_");
              var graphval = {
                id: 272, //272, 242
                incomingData: [
                  {
                    inputDataName: "ProjectId",
                    inputDataType: 1,
                    inputData: parseInt(projectID[1]),
                  },
                ],
              };
              commit("resetDashboard", { loadingstatus: false });
              commit("storeDefultapivalues", {
                data: rowData,
                MonthColumnsData: monthsData,
                reportingDate: reportDate,
              });
            }
          })
          .catch((error) => {
            commit("resetDashboard", { loadingstatus: true });
            dispatch("alert/error", error, { root: true });
          });
      })
      .catch((error) => {
        commit("resetDashboard", { loadingstatus: true });
        dispatch("alert/error", error, { root: true });
      });
  },

  LoadDashboard({ dispatch, commit }, { reportDate, viewID }) {
    getMonthColumnsJSON(viewID)
      .then((monthsData) => {
        //Examine monthsData for the header that has the month and year
        //Pass that with the rowData
        //var startMonth = convertedHeader;
        getRemoteRow(viewID)
          .then((rowData) => {
            if (rowData.length > 0) {
              var projectID = rowData[0].uid.split("_");
              var graphval = {
                id: 272, //272, 242
                incomingData: [
                  {
                    inputDataName: "ProjectId",
                    inputDataType: 1,
                    inputData: parseInt(projectID[1]),
                  },
                ],
              };
              getAuthoritiesValues(graphval).then((returnvalues) => {
                if (returnvalues.succeeded && returnvalues.data.length > 0) {
                  commit("resetDashboard", { loadingstatus: false });
                  commit("storeapivalues", {
                    data: rowData,
                    MonthColumnsData: monthsData,
                    reportingDate: reportDate,
                    graphval: returnvalues.data,
                  });
                } else {
                  commit("resetDashboard", { loadingstatus: false });
                  commit("storeapivalues", {
                    data: rowData,
                    MonthColumnsData: monthsData,
                    reportingDate: reportDate,
                    graphval: null,
                  });
                }
              });
            }
          })
          .catch((error) => {
            commit("resetDashboard", { loadingstatus: true });
            dispatch("alert/error", error, { root: true });
          });
      })
      .catch((error) => {
        commit("resetDashboard", { loadingstatus: true });
        dispatch("alert/error", error, { root: true });
      });
  },
};

const mutations = {
  changeLoadingStatus(state, { loadingstatus }) {
    state.loadingStatus = loadingstatus;
  },

  //Ausgrid Only
  storeapivalues(state, { data, MonthColumnsData, reportingDate, graphval }) {
    {
      //Title
      moment.updateLocale("en", localization);
      var start = [],
        end = [];
      if (data[0].startDate) {
        start = data[0].startDate.toString().split(" ");
        start = new Date(start[0]).toLocaleString("en-AU");
        if (start == "Invalid Date") {
          start = data[0].startDate.toString().split(" ");
          start = start[0];
        } else {
          start = start.split(",");
          start = start[0];
        }
        /* start=start[0].toString().split('/');
                start=new Date(start[2], parseInt(start[1])-1, start[0]);
                start=moment(start).format("DD/MM/YYYY") */
      } else {
        start = "";
      }

      if (data[0].endDate) {
        end = data[0].endDate.toString().split(" ");
        end = new Date(end[0]).toLocaleString("en-AU");
        if (end == "Invalid Date") {
          end = data[0].endDate.toString().split(" ");
          end = end[0];
        } else {
          end = end.split(",");
          end = end[0];
        }
        /*  end=end[0].toString().split('/');
                 end=new Date(end[2], parseInt(end[1])-1, end[0]);
                 end=moment(end).format("DD/MM/YYYY") */
      } else {
        end = "";
      }
      var ProjectManager = "";
      if (data[0].extra.ProjectManager != undefined) {
        ProjectManager = data[0].extra.ProjectManager;
      }
      state.cards[0].heading =
        data[0].code +
        " | " +
        data[0].description +
        " | " +
        ProjectManager +
        " | Execution | Gate 3: " +
        start +
        " | PC: " +
        end;
    }
    {
      //KPI
      //Reporting Date
      var reportDate = moment.utc(reportingDate).local().format("DD/MM/YYYY");
      state.cards[1].heading = "REPORTING DATE: " + reportDate;
      //SPI
      var SPI = data[0].earnedValue / data[0].forecast;
      if (isNaN(SPI)) {
        SPI = 0;
      }
      state.cards[1].charts[0].data[0].value = SPI * 100;
      if (state.cards[1].charts[0].data[0].value < 100) {
        state.cards[1].charts[0].data[0].gauge.bar.color = "rgb(255,0,0)";
      } else {
        state.cards[1].charts[0].data[0].gauge.bar.color = "(0,128,0)";
      }
      //CPI
      var CPI = data[0].earnedValue / data[0].actual;
      if (isNaN(CPI)) {
        CPI = 0;
      }
      state.cards[1].charts[1].data[0].value = CPI * 100;
      if (state.cards[1].charts[1].data[0].value < 100) {
        state.cards[1].charts[1].data[0].gauge.bar.color = "rgb(255,0,0)";
      } else {
        state.cards[1].charts[1].data[0].gauge.bar.color = "(0,128,0)";
      }
      {
        //Life to Date
        (LifeActuals.x[0] = AUD(data[0].actual, "$")),
          (LifeEV.x[0] = AUD(data[0].earnedValue, "$")),
          (LifetoBudget.x[0] = AUD(data[0].forecast, "$")),
          (LifeBudget.x[0] = AUD(data[0].budget, "$")),
          (state.cards[1].charts[2].data = [
            LifeActuals,
            LifeEV,
            LifeBudget,
            LifetoBudget,
          ]);
      }
      {
        //Completion Rate
        state.cards[1].charts[4].data[0].cells.values = [
          "<b>" + (data[0].percent * 100).toFixed(2) + "%<b>",
        ];
      }
      {
        //Life To-date Variances
        if (isNaN(data[0].budget)) {
          data[0].budget = 0;
        }
        //Header
        var tempsign = "$",
          tempsign2 = "$",
          tempBudget = data[0].budget - data[0].actual;
        if (tempBudget < 0) {
          (state.cards[1].charts[5].data[0].header.font.color[1] = "red"),
            (tempBudget = tempBudget * -1);
          tempsign = "-$";
        } else {
          state.cards[1].charts[5].data[0].header.font.color[1] = "green";
          tempsign = "$";
        }
        state.cards[1].charts[5].data[0].header.values = [
          ["Variance"],
          ["<b> " + AUD(tempBudget, tempsign) + "<b>"],
        ];

        //cells
        var SV = data[0].earnedValue - data[0].forecast;
        if (isNaN(SV)) {
          SV = 0;
        }
        if (SV < 0) {
          SV = SV * -1;
          state.cards[1].charts[5].data[0].cells.font.color[1][1] = "red";
          tempsign = "-$";
        } else {
          state.cards[1].charts[5].data[0].cells.font.color[1][1] = "green";
          tempsign = "$";
        }

        var CV = data[0].earnedValue - data[0].actual;
        if (isNaN(CV)) {
          CV = 0;
        }
        if (CV < 0) {
          CV = CV * -1;
          state.cards[1].charts[5].data[0].cells.font.color[1][0] = "red";
          tempsign2 = "-$";
        } else {
          state.cards[1].charts[5].data[0].cells.font.color[1][0] = "green";
          tempsign2 = "$";
        }
        state.cards[1].charts[5].data[0].cells.values = [
          ["CV", "SV"],
          [
            "<b>" + AUD(CV, tempsign2) + "</b>",
            "<b>" + AUD(SV, tempsign) + "</b>",
          ],
        ];
      }
      {
        //Authoritites Graph
        if (graphval != null) {
          if (graphval.split(":")) {
            var tempGraph = graphval.split(":"),
              valueholder = [];
            valueholder[0] = tempGraph[1].split(",");
            valueholder[1] = tempGraph[2].split(",");
            valueholder[2] = tempGraph[3].split("}]");

            Authorititesoriginal.x = [parseInt(valueholder[0][0])];
            Authorititesextra.x = [parseInt(valueholder[1][0])];
            Authorititescontingency.x = [parseInt(valueholder[2][0])];

            state.cards[1].charts[3].data = [
              Authorititesoriginal,
              Authorititesextra,
              Authorititescontingency,
            ];
          }
        }
      }
    }
    {
      //Trend
      //Bar graph
      var headerExtration = MonthColumnsData.toString()
        .slice(110, -1)
        .replace("\r\n", " ")
        .split("[");
      var startDateString = headerExtration[0].split('"');

      if (startDateString.length > 1) {
        var startPoint = startDateString[1].split(",");
        var dateFrom = new Date(
          startPoint[1],
          monthNames.findIndex((x) => x == startPoint[0]) - 1
        );
        var dateTo = new Date(reportingDate);
        var monthsToReportingDate =
          dateTo.getMonth() -
          dateFrom.getMonth() +
          12 * (dateTo.getFullYear() - dateFrom.getFullYear());
        var trendfilter = data
          .map((a, index) => {
            if (!a.path[2]) {
              const container = {};
              container["path"] = a.path[1];
              container["projectpath"] = a.path[0];
              container["code"] = a.code;
              container["Description"] = a.description;
              container["budget"] = a.budget;
              container["actual"] = a.actual;
              container["EV"] = a.earnedValue;
              container["comments"] = a.comments;
              container["Commitment"] = a.commited - a.deliveredCost;
              container["index"] = index;
              container["MActual"] = [];
              container["Mbudget"] = [];
              container["EVGraph"] = [];
              container["MCAC"] = [];
              var tempMFTP = 0,
                pm = 0;
              for (let i = 1; i < headerExtration.length; i++) {
                if (a["m" + i + "actualAccumulated"] == undefined) {
                  a["m" + i + "actualAccumulated"] = 0;
                }
                if (a["m" + i + "forecastAccumulated"] == undefined) {
                  a["m" + i + "forecastAccumulated"] = 0;
                }
                container["MActual"].push(a["m" + i + "actualAccumulated"]);
                container["Mbudget"].push(a["m" + i + "forecastAccumulated"]);
                if (i < monthsToReportingDate) {
                  container["EVGraph"].push(
                    (((a["m" + i + "forecastAccumulated"] / a.budget) *
                      a.percent) /
                      (a.forecast / a.budget)) *
                      a.budget
                  );
                  tempMFTP = a["m" + i + "actual"];
                  container["MCAC"].push(tempMFTP);
                } else if (i == monthsToReportingDate) {
                  container["EVGraph"].push(
                    (((a["m" + i + "forecastAccumulated"] / a.budget) *
                      a.percent) /
                      (a.forecast / a.budget)) *
                      a.budget
                  );
                  pm = i - 1;
                  if (pm == 0) {
                    tempMFTP = 0;
                  } else {
                    tempMFTP =
                      a["m" + i + "forecastAccumulated"] /
                        (a.earnedValue / a.actual) -
                      a["m" + pm + "actualAccumulated"];
                  }
                  container["MCAC"].push(tempMFTP);
                } else {
                  container["EVGraph"].push(0);
                  tempMFTP =
                    a["m" + i + "forecast"] / (a.earnedValue / a.actual);
                  //console.log(tempMFTP, a.earnedValue/a.actual);
                  container["MCAC"].push(tempMFTP);
                }
                if (tempMFTP == undefined) {
                  tempMFTP = 0;
                }
                //tempMCAC = a["m" + i + "forecastAccumulated"] / (a.earnedValue / a.actual) - (tempMFTP);
                // console.log( a.path[1], tempMCAC);
                //container["MCAC"].push(tempMCAC)
              }
              return container;
            }
          })
          .filter(Boolean);
        var valueofx = [];
        var valueactual = [];
        var valuebudget = [];
        var valuecommitment = [];
        var valueMCAC = [];
        var comments = trendfilter[0].comments;
        var projectpath = trendfilter[0].projectpath;
        {
          //Line Graph
          Removetrail(valueactual);
          Removetrail(valuebudget);
          Removetrail(valuecommitment);
          storeX = [];

          TrendingGraph(trendfilter[0].MActual, valueactual);
          TrendingGraph(trendfilter[0].Mbudget, valuebudget);
          TrendingGraph(trendfilter[0].EVGraph, valuecommitment);
          TrendingGraph(trendfilter[0].MCAC, valueMCAC);

          Removetrail(valueactual);
          Removetrail(valuebudget);
          Removetrail(valuecommitment);

          valuebudget = RemoveZero(valuebudget);
          valueactual = RemoveZero(valueactual);
          valuecommitment = RemoveZero(valuecommitment);
          storeX = [];

          valuebudget = RemoveZero(valuebudget);
          valueactual = RemoveZero(valueactual);
          valuecommitment = RemoveZero(valuecommitment);

          AccumulatedValues(startPoint, headerExtration.length - 1);

          reportDate = new Date(reportingDate);
          getCurrentFinancialYear(reportDate);
          var ReportFyYear = financial_year;

          {
            var index = getTrendPoint(storeX, ReportFyYear[0]);
            var FYstart = 0,
              FYend = storeX.length - 1;
            if (index > -1) {
              FYstart = index;
              state.cards[2].charts[0].layout.shapes[0].x0 = storeX[index];
            }

            index = getTrendPoint(storeX, ReportFyYear[1]);
            if (index > -1) {
              FYend = index;
            }
          }
          {
            // FINANCIAL YEAR=
            var sumBudget = 0,
              sumActual = 0,
              sumCAC = 0;
            if (FYstart > 0) {
              sumBudget = valuebudget[FYend] - valuebudget[FYstart];
              if (valueactual[FYend] == undefined) {
                sumActual =
                  valueactual[valueactual.length - 1] - valueactual[FYstart];
              } else {
                sumActual = valueactual[FYend] - valueactual[FYstart];
              }
            } else {
              sumBudget = valuebudget[FYend];
              if (valueactual[FYend] == undefined) {
                sumActual = valueactual[valueactual.length - 1];
              } else {
                sumActual = valueactual[FYend];
              }
            }
            for (let i = FYstart; i <= FYend; i++) {
              if (valueMCAC[i] != undefined) {
                if (isFinite(valueMCAC[i])) {
                  sumCAC += valueMCAC[i];
                }
              }
            }

            //Header
            if (sumBudget < 0) {
              state.cards[1].charts[6].data[0].header.font.color[1] = "red";
              tempsign = "-$";
            } else {
              state.cards[1].charts[6].data[0].header.font.color[1] = "green";
              tempsign = "$";
            }
            state.cards[1].charts[6].data[0].header.values = [
              ["Budget"],
              ["<b> " + AUD(sumBudget, tempsign) + "<b>"],
            ];

            //cells

            var SPEND = sumActual; //data[0].commited - data[0].deliveredCost
            if (isNaN(SPEND)) {
              SPEND = 0;
            }
            if (SPEND < 0) {
              SPEND = SPEND * -1;
              state.cards[1].charts[6].data[0].cells.font.color[1][0] = "red";
              tempsign2 = "-$";
            } else {
              state.cards[1].charts[6].data[0].cells.font.color[1][0] = "green";
              tempsign2 = "$";
            }

            if (CPI == 0) {
              CPI = 1;
            }
            var CAC = sumCAC;
            if (isNaN(CAC)) {
              CAC = 0;
            }
            if (CAC < 0) {
              CAC = CAC * -1;
              state.cards[1].charts[6].data[0].cells.font.color[1][1] = "red";
              tempsign = "-$";
            } else {
              state.cards[1].charts[6].data[0].cells.font.color[1][1] = "green";
              tempsign = "$";
            }

            state.cards[1].charts[6].data[0].cells.values = [
              ["Actual", "CAC"],
              [
                "<b>" + AUD(SPEND, tempsign2) + "</b>",
                "<b>" + AUD(CAC, "$") + "</b>",
              ],
            ];
          }

          {
            //-6 Month TrendGraph
            {
              //Trend Graph 6 month backwards and 18 months forwards

              var tempstart = 0,
                tempend = storeX.length;
              //Budget
              if (FYstart >= 6) {
                tempstart = FYstart - 6;
                //temptrendstart=FYstart
              }

              if (FYend + 19 <= storeX.length) {
                tempend = FYend + 19;
              }

              (state.cards[2].charts[0].data[0].x = storeX.slice(
                tempstart,
                tempend
              )),
                (state.cards[2].charts[0].data[0].y = valuebudget.slice(
                  tempstart,
                  tempend
                ));

              //Actual
              (state.cards[2].charts[0].data[1].x = storeX.slice(
                tempstart,
                tempend
              )),
                (state.cards[2].charts[0].data[1].y = valueactual.slice(
                  tempstart,
                  tempend
                ));

              //EV
              (state.cards[2].charts[0].data[2].x = storeX.slice(
                tempstart,
                tempend
              )),
                (state.cards[2].charts[0].data[2].y = valuecommitment.slice(
                  tempstart,
                  tempend
                ));

              //Financial Year color shape
              state.cards[2].charts[0].layout.shapes[0].x0 = storeX[FYstart];
              state.cards[2].charts[0].layout.shapes[0].x1 = storeX[FYend];
            }
          }
        }
        {
          trendfilter.sort(function (a, b) {
            return a.code === b.code ? 0 : a.code < b.code ? -1 : 1;
          });

          valueofx = [];
          valueactual = [];
          valuebudget = [];
          valuecommitment = [];

          trendfilter.forEach((element, i) => {
            if (projectpath == element.projectpath) {
              if (element.path != undefined) {
                valueofx.push(element.Description);
                if (element.actual == undefined) {
                  valueactual.push(0);
                } else {
                  valueactual.push(element.actual);
                }
                if (element.budget == undefined) {
                  valuebudget.push(0);
                } else {
                  valuebudget.push(element.budget);
                }

                if (element.Commitment == undefined) {
                  valuecommitment.push(0);
                } else {
                  valuecommitment.push(element.Commitment);
                }
              }
            }
          });
          //Actual
          (state.cards[2].charts[1].data[0].x = valueofx),
            (state.cards[2].charts[1].data[0].y = valueactual),
            (state.cards[2].charts[1].data[0].text = valueactual.map((x) =>
              AUD(x, "$")
            ));
          state.cards[2].charts[1].data[0].hovertemplate = valueactual.map(
            (x) => AUD(x, "$")
          );

          //Commitment
          (state.cards[2].charts[1].data[1].x = valueofx),
            (state.cards[2].charts[1].data[1].y = valuecommitment),
            (state.cards[2].charts[1].data[1].base = valueactual),
            (state.cards[2].charts[1].data[1].text = valuecommitment.map((x) =>
              AUD(x, "$")
            ));
          state.cards[2].charts[1].data[1].hovertemplate = valuecommitment.map(
            (x) => AUD(x, "$")
          );
          //Budget
          (state.cards[2].charts[1].data[2].x = valueofx),
            (state.cards[2].charts[1].data[2].y = valuebudget),
            (state.cards[2].charts[1].data[2].text = valuebudget.map((x) =>
              AUD(x, "$")
            ));
          state.cards[2].charts[1].data[2].hovertemplate = valuebudget.map(
            (x) => AUD(x, "$")
          );
        }
      }
      {
        //Key Areas
        trendfilter = data
          .map((a, index) => {
            if (a.path[2] && a.budget) {
              const container = {};
              container["path"] = a.path;
              container["Description"] = a.description;
              container["budget"] = a.budget;
              container["projectcode"] = a.path[0];
              if (a.actual == undefined) {
                CPI = 0;
                container["actual"] = 0;
              } else {
                CPI = a.earnedValue / a.actual;
              }
              if (a.earnedValue == undefined) {
                container["EV"] = 0;
              } else {
                container["EV"] = a.earnedValue;
              }
              if (CPI > 0) {
                container["CAC"] = a.budget / CPI;
              } else {
                container["CAC"] = 0;
              }
              container["index"] = index;
              return container;
            }
          })
          .filter(Boolean)
          .sort((a, b) => (b.budget < a.budget ? -1 : 1));

        var UniqueIndex = [],
          Duplicateindex = [];
        var alreadySeen = [];
        trendfilter.forEach(function (str, index) {
          if (alreadySeen[str.Description]) {
            Duplicateindex.push(index);
          } else {
            alreadySeen[str.Description] = true;
            UniqueIndex.push(index);
          }
        });

        if (Duplicateindex.length <= 0) {
          trendfilter = trendfilter.slice(0, 5).reverse();
        } else {
          trendfilter = trendfilter.filter(
            ({ projectcode }) => projectcode == trendfilter[0].projectcode
          );
          trendfilter = trendfilter.slice(0, 5).reverse();
        }
        //.slice(0,5).reverse();

        // console.log(trendfilter);

        //sort((x, y) => -(x.budget>y.budget)||+(x.budget<y.budget)).slice(0, 5)

        /*Y-value for key trend graph*/
        var graphY = [];
        var graphActual = [];
        var graphEV = [];
        var graphCAC = [];
        var graphBudget = [];

        trendfilter.forEach((element) => {
          graphY.push(element.Description);
          graphActual.push(element.actual);
          graphBudget.push(element.budget);
          graphEV.push(element.EV);
          graphCAC.push(element.CAC);
        });

        //CAC
        ForcastCAC.y = graphY;
        ForcastCAC.x = graphCAC;

        //Actual
        Actuals.y = graphY;
        Actuals.x = graphActual;

        //EV
        EV.y = graphY;
        EV.x = graphEV;

        //Budget
        Budget.y = graphY;
        Budget.x = graphBudget;

        //Table Comment
        //Table Comment
        if (comments) {
          state.cards[3].charts[1].data[0].header.values = [
            "<b>" + comments.replaceAll("\n", "<br />") + "<b>",
          ];
        }
      }
      state.cards[3].charts[0].layout.title = "<b> NETWORK ACTIVITIES </b>";
      if (!state.loadingStatus) {
        state.loadingStatus = true;
      }
    }
  },

  //Default Dashbaord
  storeDefultapivalues(state, { data, MonthColumnsData, reportingDate }) {
    {
      //Title
      moment.updateLocale("en", localization);
      var start = [],
        end = [];
      if (data[0].startDate) {
        start = data[0].startDate.toString().split(" ");
        start = new Date(start[0]).toLocaleString("en-AU");
        if (start == "Invalid Date") {
          start = data[0].startDate.toString().split(" ");
          start = start[0];
        } else {
          start = start.split(",");
          start = start[0];
        }
        /* start=start[0].toString().split('/');
                start=new Date(start[2], parseInt(start[1])-1, start[0]);
                start=moment(start).format("DD/MM/YYYY") */
      } else {
        start = "";
      }

      if (data[0].endDate) {
        end = data[0].endDate.toString().split(" ");
        end = new Date(end[0]).toLocaleString("en-AU");
        if (end == "Invalid Date") {
          end = data[0].endDate.toString().split(" ");
          end = end[0];
        } else {
          end = end.split(",");
          end = end[0];
        }
        /*  end=end[0].toString().split('/');
                 end=new Date(end[2], parseInt(end[1])-1, end[0]);
                 end=moment(end).format("DD/MM/YYYY") */
      } else {
        end = "";
      }
      var ProjectManager = "";
      if (data[0].extra.ProjectManager != undefined) {
        ProjectManager = data[0].extra.ProjectManager;
      }
      state.cards[0].heading =
        data[0].code +
        " | " +
        data[0].description +
        " | " +
        ProjectManager +
        " | Execution | Gate 3: " +
        start +
        " | PC: " +
        end;
    }
    {
      //KPI
      //Reporting Date
      var reportDate = moment.utc(reportingDate).local().format("DD/MM/YYYY");
      state.cards[1].heading = "REPORTING DATE: " + reportDate;
      //SPI
      var SPI = data[0].earnedValue / data[0].forecast;
      if (isNaN(SPI)) {
        SPI = 0;
      }
      state.cards[1].charts[0].data[0].value = SPI * 100;
      if (state.cards[1].charts[0].data[0].value < 100) {
        state.cards[1].charts[0].data[0].gauge.bar.color = "rgb(255,0,0)";
      } else {
        state.cards[1].charts[0].data[0].gauge.bar.color = "(0,128,0)";
      }
      //CPI
      var CPI = data[0].earnedValue / data[0].actual;
      if (isNaN(CPI)) {
        CPI = 0;
      }
      state.cards[1].charts[1].data[0].value = CPI * 100;
      if (state.cards[1].charts[1].data[0].value < 100) {
        state.cards[1].charts[1].data[0].gauge.bar.color = "rgb(255,0,0)";
      } else {
        state.cards[1].charts[1].data[0].gauge.bar.color = "(0,128,0)";
      }
      {
        //Life to Date
        (LifeActuals.x[0] = AUD(data[0].actual, "$")),
          (LifeEV.x[0] = AUD(data[0].earnedValue, "$")),
          (LifetoBudget.x[0] = AUD(data[0].forecast, "$")),
          (LifeBudget.x[0] = AUD(data[0].budget, "$")),
          (state.cards[1].charts[2].data = [
            LifeActuals,
            LifeEV,
            LifeBudget,
            LifetoBudget,
          ]);
      }
      {
        //Completion Rate
        state.cards[1].charts[4].data[0].cells.values = [
          "<b>" + (data[0].percent * 100).toFixed(2) + "%<b>",
        ];
      }
      {
        //Life To-date Variances
        if (isNaN(data[0].budget)) {
          data[0].budget = 0;
        }
        //Header
        var tempsign = "$",
          tempsign2 = "$",
          tempBudget = data[0].budget - data[0].actual;
        if (tempBudget < 0) {
          (state.cards[1].charts[5].data[0].header.font.color[1] = "red"),
            (tempBudget = tempBudget * -1);
          tempsign = "-$";
        } else {
          state.cards[1].charts[5].data[0].header.font.color[1] = "green";
          tempsign = "$";
        }
        state.cards[1].charts[5].data[0].header.values = [
          ["Variance"],
          ["<b> " + AUD(tempBudget, tempsign) + "<b>"],
        ];

        //cells
        var SV = data[0].earnedValue - data[0].forecast;
        if (isNaN(SV)) {
          SV = 0;
        }
        if (SV < 0) {
          SV = SV * -1;
          state.cards[1].charts[5].data[0].cells.font.color[1][1] = "red";
          tempsign = "-$";
        } else {
          state.cards[1].charts[5].data[0].cells.font.color[1][1] = "green";
          tempsign = "$";
        }

        var CV = data[0].earnedValue - data[0].actual;
        if (isNaN(CV)) {
          CV = 0;
        }
        if (CV < 0) {
          CV = CV * -1;
          state.cards[1].charts[5].data[0].cells.font.color[1][0] = "red";
          tempsign2 = "-$";
        } else {
          state.cards[1].charts[5].data[0].cells.font.color[1][0] = "green";
          tempsign2 = "$";
        }
        state.cards[1].charts[5].data[0].cells.values = [
          ["CV", "SV"],
          [
            "<b>" + AUD(CV, tempsign2) + "</b>",
            "<b>" + AUD(SV, tempsign) + "</b>",
          ],
        ];
      }
    }
    {
      //Trend
      //Bar graph
      var headerExtration = MonthColumnsData.toString()
        .slice(110, -1)
        .replace("\r\n", " ")
        .split("[");
      var startDateString = headerExtration[0].split('"');

      if (startDateString.length > 1) {
        var startPoint = startDateString[1].split(",");
        var dateFrom = new Date(
          startPoint[1],
          monthNames.findIndex((x) => x == startPoint[0]) - 1
        );
        var dateTo = new Date(reportingDate);
        var monthsToReportingDate =
          dateTo.getMonth() -
          dateFrom.getMonth() +
          12 * (dateTo.getFullYear() - dateFrom.getFullYear());
        var trendfilter = data
          .map((a, index) => {
            if (!a.path[2]) {
              const container = {};
              container["path"] = a.path[1];
              container["projectpath"] = a.path[0];
              container["code"] = a.code;
              container["Description"] = a.description;
              container["budget"] = a.budget;
              container["actual"] = a.actual;
              container["EV"] = a.earnedValue;
              container["comments"] = a.comments;
              container["Commitment"] = a.commited - a.deliveredCost;
              container["index"] = index;
              container["MActual"] = [];
              container["Mbudget"] = [];
              container["EVGraph"] = [];
              container["MCAC"] = [];
              var tempMFTP = 0,
                pm = 0;
              for (let i = 1; i < headerExtration.length; i++) {
                if (a["m" + i + "actualAccumulated"] == undefined) {
                  a["m" + i + "actualAccumulated"] = 0;
                }
                if (a["m" + i + "forecastAccumulated"] == undefined) {
                  a["m" + i + "forecastAccumulated"] = 0;
                }
                container["MActual"].push(a["m" + i + "actualAccumulated"]);
                container["Mbudget"].push(a["m" + i + "forecastAccumulated"]);
                if (i < monthsToReportingDate) {
                  container["EVGraph"].push(
                    (((a["m" + i + "forecastAccumulated"] / a.budget) *
                      a.percent) /
                      (a.forecast / a.budget)) *
                      a.budget
                  );
                  tempMFTP = a["m" + i + "actual"];
                  container["MCAC"].push(tempMFTP);
                } else if (i == monthsToReportingDate) {
                  container["EVGraph"].push(
                    (((a["m" + i + "forecastAccumulated"] / a.budget) *
                      a.percent) /
                      (a.forecast / a.budget)) *
                      a.budget
                  );
                  pm = i - 1;
                  if (pm == 0) {
                    tempMFTP = 0;
                  } else {
                    tempMFTP =
                      a["m" + i + "forecastAccumulated"] /
                        (a.earnedValue / a.actual) -
                      a["m" + pm + "actualAccumulated"];
                  }
                  container["MCAC"].push(tempMFTP);
                } else {
                  container["EVGraph"].push(0);
                  tempMFTP =
                    a["m" + i + "forecast"] / (a.earnedValue / a.actual);
                  //console.log(tempMFTP, a.earnedValue/a.actual);
                  container["MCAC"].push(tempMFTP);
                }
                if (tempMFTP == undefined) {
                  tempMFTP = 0;
                }
                //tempMCAC = a["m" + i + "forecastAccumulated"] / (a.earnedValue / a.actual) - (tempMFTP);
                // console.log( a.path[1], tempMCAC);
                //container["MCAC"].push(tempMCAC)
              }
              return container;
            }
          })
          .filter(Boolean);
        var valueofx = [];
        var valueactual = [];
        var valuebudget = [];
        var valuecommitment = [];
        var valueMCAC = [];
        var comments = trendfilter[0].comments;
        var projectpath = trendfilter[0].projectpath;
        {
          //Line Graph
          Removetrail(valueactual);
          Removetrail(valuebudget);
          Removetrail(valuecommitment);
          storeX = [];

          TrendingGraph(trendfilter[0].MActual, valueactual);
          TrendingGraph(trendfilter[0].Mbudget, valuebudget);
          TrendingGraph(trendfilter[0].EVGraph, valuecommitment);
          TrendingGraph(trendfilter[0].MCAC, valueMCAC);

          Removetrail(valueactual);
          Removetrail(valuebudget);
          Removetrail(valuecommitment);

          valuebudget = RemoveZero(valuebudget);
          valueactual = RemoveZero(valueactual);
          valuecommitment = RemoveZero(valuecommitment);
          storeX = [];

          valuebudget = RemoveZero(valuebudget);
          valueactual = RemoveZero(valueactual);
          valuecommitment = RemoveZero(valuecommitment);

          AccumulatedValues(startPoint, headerExtration.length - 1);

          reportDate = new Date(reportingDate);
          getCurrentFinancialYear(reportDate);
          var ReportFyYear = financial_year;

          {
            var index = getTrendPoint(storeX, ReportFyYear[0]);
            var FYstart = 0,
              FYend = storeX.length - 1;
            if (index > -1) {
              FYstart = index;
              state.cards[2].charts[0].layout.shapes[0].x0 = storeX[index];
            }

            index = getTrendPoint(storeX, ReportFyYear[1]);
            if (index > -1) {
              FYend = index;
            }
          }
          {
            // FINANCIAL YEAR=
            var sumBudget = 0,
              sumActual = 0,
              sumCAC = 0;
            if (FYstart > 0) {
              sumBudget = valuebudget[FYend] - valuebudget[FYstart];
              if (valueactual[FYend] == undefined) {
                sumActual =
                  valueactual[valueactual.length - 1] - valueactual[FYstart];
              } else {
                sumActual = valueactual[FYend] - valueactual[FYstart];
              }
            } else {
              sumBudget = valuebudget[FYend];
              if (valueactual[FYend] == undefined) {
                sumActual = valueactual[valueactual.length - 1];
              } else {
                sumActual = valueactual[FYend];
              }
            }
            for (let i = FYstart; i <= FYend; i++) {
              if (valueMCAC[i] != undefined) {
                if (isFinite(valueMCAC[i])) {
                  sumCAC += valueMCAC[i];
                }
              }
            }

            //Header
            if (sumBudget < 0) {
              state.cards[1].charts[6].data[0].header.font.color[1] = "red";
              tempsign = "-$";
            } else {
              state.cards[1].charts[6].data[0].header.font.color[1] = "green";
              tempsign = "$";
            }
            state.cards[1].charts[6].data[0].header.values = [
              ["Budget"],
              ["<b> " + AUD(sumBudget, tempsign) + "<b>"],
            ];

            //cells

            var SPEND = sumActual; //data[0].commited - data[0].deliveredCost
            if (isNaN(SPEND)) {
              SPEND = 0;
            }
            if (SPEND < 0) {
              SPEND = SPEND * -1;
              state.cards[1].charts[6].data[0].cells.font.color[1][0] = "red";
              tempsign2 = "-$";
            } else {
              state.cards[1].charts[6].data[0].cells.font.color[1][0] = "green";
              tempsign2 = "$";
            }

            if (CPI == 0) {
              CPI = 1;
            }
            var CAC = sumCAC;
            if (isNaN(CAC)) {
              CAC = 0;
            }
            if (CAC < 0) {
              CAC = CAC * -1;
              state.cards[1].charts[6].data[0].cells.font.color[1][1] = "red";
              tempsign = "-$";
            } else {
              state.cards[1].charts[6].data[0].cells.font.color[1][1] = "green";
              tempsign = "$";
            }

            state.cards[1].charts[6].data[0].cells.values = [
              ["Actual", "CAC"],
              [
                "<b>" + AUD(SPEND, tempsign2) + "</b>",
                "<b>" + AUD(CAC, "$") + "</b>",
              ],
            ];
          }

          {
            //-6 Month TrendGraph
            {
              //Trend Graph 6 month backwards and 18 months forwards

              var tempstart = 0,
                tempend = storeX.length;
              //Budget
              if (FYstart >= 6) {
                tempstart = FYstart - 6;
                //temptrendstart=FYstart
              }

              if (FYend + 19 <= storeX.length) {
                tempend = FYend + 19;
              }

              (state.cards[2].charts[0].data[0].x = storeX.slice(
                tempstart,
                tempend
              )),
                (state.cards[2].charts[0].data[0].y = valuebudget.slice(
                  tempstart,
                  tempend
                ));

              //Actual
              (state.cards[2].charts[0].data[1].x = storeX.slice(
                tempstart,
                tempend
              )),
                (state.cards[2].charts[0].data[1].y = valueactual.slice(
                  tempstart,
                  tempend
                ));

              //EV
              (state.cards[2].charts[0].data[2].x = storeX.slice(
                tempstart,
                tempend
              )),
                (state.cards[2].charts[0].data[2].y = valuecommitment.slice(
                  tempstart,
                  tempend
                ));

              //Financial Year color shape
              state.cards[2].charts[0].layout.shapes[0].x0 = storeX[FYstart];
              state.cards[2].charts[0].layout.shapes[0].x1 = storeX[FYend];
            }
          }
        }
        {
          trendfilter.sort(function (a, b) {
            return a.code === b.code ? 0 : a.code < b.code ? -1 : 1;
          });

          valueofx = [];
          valueactual = [];
          valuebudget = [];
          valuecommitment = [];

          trendfilter.forEach((element, i) => {
            if (projectpath == element.projectpath) {
              if (element.path != undefined) {
                valueofx.push(element.Description);
                if (element.actual == undefined) {
                  valueactual.push(0);
                } else {
                  valueactual.push(element.actual);
                }
                if (element.budget == undefined) {
                  valuebudget.push(0);
                } else {
                  valuebudget.push(element.budget);
                }

                if (element.Commitment == undefined) {
                  valuecommitment.push(0);
                } else {
                  valuecommitment.push(element.Commitment);
                }
              }
            }
          });
          //Actual
          (state.cards[2].charts[1].data[0].x = valueofx),
            (state.cards[2].charts[1].data[0].y = valueactual),
            (state.cards[2].charts[1].data[0].text = valueactual.map((x) =>
              AUD(x, "$")
            ));
          state.cards[2].charts[1].data[0].hovertemplate = valueactual.map(
            (x) => AUD(x, "$")
          );

          //Commitment
          (state.cards[2].charts[1].data[1].x = valueofx),
            (state.cards[2].charts[1].data[1].y = valuecommitment),
            (state.cards[2].charts[1].data[1].base = valueactual),
            (state.cards[2].charts[1].data[1].text = valuecommitment.map((x) =>
              AUD(x, "$")
            ));
          state.cards[2].charts[1].data[1].hovertemplate = valuecommitment.map(
            (x) => AUD(x, "$")
          );
          //Budget
          (state.cards[2].charts[1].data[2].x = valueofx),
            (state.cards[2].charts[1].data[2].y = valuebudget),
            (state.cards[2].charts[1].data[2].text = valuebudget.map((x) =>
              AUD(x, "$")
            ));
          state.cards[2].charts[1].data[2].hovertemplate = valuebudget.map(
            (x) => AUD(x, "$")
          );
        }
      }
      {
        //Key Areas
        trendfilter = data
          .map((a, index) => {
            if (a.path[1] && a.budget) {
              const container = {};
              container["path"] = a.path;
              container["Description"] = a.description;
              container["budget"] = a.budget;
              container["projectcode"] = a.path[0];
              if (a.actual == undefined) {
                CPI = 0;
                container["actual"] = 0;
              } else {
                CPI = a.earnedValue / a.actual;
              }
              if (a.earnedValue == undefined) {
                container["EV"] = 0;
              } else {
                container["EV"] = a.earnedValue;
              }
              if (CPI > 0) {
                container["CAC"] = a.budget / CPI;
              } else {
                container["CAC"] = 0;
              }
              container["index"] = index;
              return container;
            }
          })
          .filter(Boolean)
          .sort((a, b) => (b.budget < a.budget ? -1 : 1));

        var UniqueIndex = [],
          Duplicateindex = [];
        var alreadySeen = [];
        trendfilter.forEach(function (str, index) {
          if (alreadySeen[str.Description]) {
            Duplicateindex.push(index);
          } else {
            alreadySeen[str.Description] = true;
            UniqueIndex.push(index);
          }
        });

        if (Duplicateindex.length <= 0) {
          trendfilter = trendfilter.slice(0, 5).reverse();
        } else {
          trendfilter = trendfilter.filter(
            ({ projectcode }) => projectcode == trendfilter[0].projectcode
          );
          trendfilter = trendfilter.slice(0, 5).reverse();
        }
        //.slice(0,5).reverse();

        // console.log(trendfilter);

        //sort((x, y) => -(x.budget>y.budget)||+(x.budget<y.budget)).slice(0, 5)

        /*Y-value for key trend graph*/
        var graphY = [];
        var graphActual = [];
        var graphEV = [];
        var graphCAC = [];
        var graphBudget = [];

        trendfilter.forEach((element) => {
          graphY.push(element.Description);
          graphActual.push(element.actual);
          graphBudget.push(element.budget);
          graphEV.push(element.EV);
          graphCAC.push(element.CAC);
        });

        //CAC
        ForcastCAC.y = graphY;
        ForcastCAC.x = graphCAC;

        //Actual
        Actuals.y = graphY;
        Actuals.x = graphActual;

        //EV
        EV.y = graphY;
        EV.x = graphEV;

        //Budget
        Budget.y = graphY;
        Budget.x = graphBudget;

        //Table Comment
        //Table Comment
        if (comments) {
          state.cards[3].charts[1].data[0].header.values = [
            "<b>" + comments.replaceAll("\n", "<br />") + "<b>",
          ];
        }
      }
      state.cards[3].charts[0].layout.title = "<b>  BUDGET  </b>";
      if (!state.loadingStatus) {
        state.loadingStatus = true;
      }
    }
  },

  resetDashboard(state) {
    state.loadingStatus = true;
    state.cards[0].heading = null;
    {
      //KPI
      state.cards[1].heading = null;
      state.cards[1].charts[0].data[0].value = 0;
      state.cards[1].charts[1].data[0].value = 0;
      LifeActuals.x[0] = 0;
      LifeEV.x[0] = 0;
      LifetoBudget.x[0] = 0;
      LifeBudget.x[0] = 0;
      state.cards[1].charts[2].data = [
        LifeActuals,
        LifeEV,
        LifeBudget,
        LifetoBudget,
      ];
      state.cards[1].charts[4].data[0].cells.values = ["<b> 0%<b>"];
      state.cards[1].charts[5].data[0].header.values = [
        ["Variance"],
        ["<b>0<b>"],
      ];
      state.cards[1].charts[5].data[0].cells.values = [
        ["CV", "SV"],
        ["<b> 0</b>", "<b> 0 </b>"],
      ];
      Authorititesoriginal.x = 0;
      Authorititesextra.x = 0;
      Authorititescontingency.x = 0;
      state.cards[1].charts[3].data = [
        Authorititesoriginal,
        Authorititesextra,
        Authorititescontingency,
      ];
      state.cards[1].charts[6].data[0].header.values = [
        ["Budget"],
        ["<b> 0 <b>"],
      ];
      state.cards[1].charts[6].data[0].cells.values = [
        ["Actual", "CAC"],
        ["<b>0</b>", "<b>0</b>"],
      ];
    }
    {
      //Budget
      state.cards[2].charts[0].data[0].x = 0;
      state.cards[2].charts[0].data[0].y = 0;
      //Actual
      state.cards[2].charts[0].data[1].x = 0;
      state.cards[2].charts[0].data[1].y = 0;
      //EV
      state.cards[2].charts[0].data[2].x = 0;
      state.cards[2].charts[0].data[2].y = 0;
    }
    {
      //Budget
      state.cards[2].charts[0].data[0].x = 0;
      state.cards[2].charts[0].data[0].y = 0;
      //Actual
      state.cards[2].charts[0].data[1].x = 0;
      state.cards[2].charts[0].data[1].y = 0;
      //EV
      state.cards[2].charts[0].data[2].x = 0;
      state.cards[2].charts[0].data[2].y = 0;
    }
    {
      //Actual
      state.cards[2].charts[1].data[0].x = 0;
      state.cards[2].charts[1].data[0].y = 0;

      //Commitment
      state.cards[2].charts[1].data[1].x = 0;
      state.cards[2].charts[1].data[1].y = 0;

      //Budget
      state.cards[2].charts[1].data[2].x = 0;
      state.cards[2].charts[1].data[2].y = 0;
    }

    {
      ForcastCAC.y = 0;
      ForcastCAC.x = 0;

      //Actual
      Actuals.y = 0;
      Actuals.x = 0;

      //EV
      EV.y = 0;
      EV.x = 0;

      //Budget
      Budget.y = 0;
      Budget.x = 0;
      state.cards[3].charts[1].data[0].header.values = null;
    }
  },

  resetAuthorities(state) {
    state.loadingStatus = true;
    Authorititesoriginal.x = 0;
    Authorititesextra.x = 0;
    Authorititescontingency.x = 0;
    state.cards[1].charts[3].data = [
      Authorititesoriginal,
      Authorititesextra,
      Authorititescontingency,
    ];
  },
};

const getters = {
  cards({ currentData }) {
    return state.cards;
  },
  dashboardName({ currentData }) {
    return state.dashboardName;
  },
  getLoadingStatus() {
    return state.loadingStatus;
  },

  row({ currentData }) {
    return state.row;
  },
};

export const dashboard = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
