<template>
  <div class="preloader" id="preloader">
    <div class="wrapper">
      <div class="box-wrapper crate">
        <div class="box">
          <div class="face top"></div>
          <div class="face left"></div>
          <div class="face right"></div>
          <div class="face back"></div>
          <div class="face front"></div>
          <div class="face bottom"></div>
          <div class="hook"></div>
        </div>
      </div>
      <div class="box-wrapper crate">
        <div class="box">
          <div class="face top"></div>
          <div class="face left"></div>
          <div class="face right"></div>
          <div class="face back"></div>
          <div class="face front"></div>
          <div class="face bottom"></div>
          <div class="hook"></div>
        </div>
      </div>
      <div class="box-wrapper crate">
        <div class="box">
          <div class="face top"></div>
          <div class="face left"></div>
          <div class="face right"></div>
          <div class="face back"></div>
          <div class="face front"></div>
          <div class="face bottom"></div>
          <div class="hook"></div>
        </div>
      </div>
      <div class="box-wrapper crate">
        <div class="box">
          <div class="face top"></div>
          <div class="face left"></div>
          <div class="face right"></div>
          <div class="face back"></div>
          <div class="face front"></div>
          <div class="face bottom"></div>
          <div class="hook"></div>
        </div>
      </div>
      <div class="crane">
        <div class="mast">
          <div class="face left">
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
          </div>
          <div class="face right">
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
          </div>
          <div class="face back">
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
          </div>
          <div class="face front">
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
            <div class="support"></div>
          </div>
        </div>
        <div class="cap-wrapper">
          <div class="cap">
            <div class="tower">
              <div class="face left">
                <div class="support left"></div>
                <div class="support right"></div>
              </div>
              <div class="face right">
                <div class="support left"></div>
                <div class="support right"></div>
              </div>
              <div class="face back">
                <div class="support left"></div>
                <div class="support right"></div>
              </div>
              <div class="face front">
                <div class="support left"></div>
                <div class="support right"></div>
              </div>
              <div class="cable front"></div>
              <div class="cable mid-front"></div>
              <div class="cable mid-back"></div>
              <div class="cable back"></div>
            </div>
            <div class="arm">
              <div class="trolley">
                <div class="cables">
                  <div class="pully">
                    <div class="hook"></div>
                  </div>
                </div>
              </div>
              <div class="segment">
                <div class="face left">
                  <div class="supports"></div>
                </div>
                <div class="face right">
                  <div class="supports"></div>
                </div>
              </div>
              <div class="segment">
                <div class="face left">
                  <div class="supports"></div>
                </div>
                <div class="face right">
                  <div class="supports"></div>
                </div>
              </div>
              <div class="segment">
                <div class="face left">
                  <div class="supports"></div>
                </div>
                <div class="face right">
                  <div class="supports"></div>
                </div>
              </div>
              <div class="segment">
                <div class="face left">
                  <div class="supports"></div>
                </div>
                <div class="face right">
                  <div class="supports"></div>
                </div>
              </div>
            </div>
            <div class="arm back">
              <div class="segment">
                <div class="face left">
                  <div class="supports"></div>
                </div>
                <div class="face right">
                  <div class="supports"></div>
                </div>
              </div>
              <div class="segment">
                <div class="face left">
                  <div class="supports"></div>
                </div>
                <div class="face right">
                  <div class="supports"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-wrapper crate">
        <div class="box">
          <div class="face top"></div>
          <div class="face left"></div>
          <div class="face right"></div>
          <div class="face back"></div>
          <div class="face front"></div>
          <div class="face bottom"></div>
          <div class="hook"></div>
        </div>
      </div>
      <div class="box-wrapper crate">
        <div class="box">
          <div class="face top"></div>
          <div class="face left"></div>
          <div class="face right"></div>
          <div class="face back"></div>
          <div class="face front"></div>
          <div class="face bottom"></div>
          <div class="hook"></div>
        </div>
      </div>
      <div class="box-wrapper crate">
        <div class="box">
          <div class="face top"></div>
          <div class="face left"></div>
          <div class="face right"></div>
          <div class="face back"></div>
          <div class="face front"></div>
          <div class="face bottom"></div>
          <div class="hook"></div>
        </div>
      </div>
      <div class="box-wrapper crate">
        <div class="box">
          <div class="face top"></div>
          <div class="face left"></div>
          <div class="face right"></div>
          <div class="face back"></div>
          <div class="face front"></div>
          <div class="face bottom"></div>
          <div class="hook"></div>
        </div>
      </div>
      <div class="target">
        <div class="target-wrapper">
          <div class="box-wrapper">
            <div class="box">
              <div class="face top"></div>
              <div class="face left"></div>
              <div class="face right"></div>
              <div class="face back"></div>
              <div class="face front"></div>
              <div class="face bottom"></div>
              <div class="hook"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-wrapper base">
        <div class="box">
          <div class="face top"></div>
          <div class="face left"></div>
          <div class="face right"></div>
          <div class="face back"></div>
          <div class="face front"></div>
          <div class="face bottom"></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CraneLoading",
  data: () => ({}),
  mounted() {
    const preloader = new Preloader(document.getElementById("preloader"));
    preloader.start();
  },
};
</script>

<style lang="scss">
$colors: (
  primary: #186fb9,
  //rgb(4, 157, 227),      //color of base
  secondary: #a19f9f,
  //#186fb9,//#d1143d,  //color of crane
  accent: #ffad14//rgb(255, 202, 40),
  // color of crates,
);

$duration: 3s;
$crane-size: 0.5em;
$crane-offset: (1em - $crane-size) / 2;
$crane-beam-size: 0.15em;
$cable-size: 0.03em;

// html {
//   position: relative;
//   height: 100%;
//   overflow: hidden;
//   background: radial-gradient(#fff, lighten(map-get($colors, accent), 10%));
// }

// body {
//   display: flex;
//   align-items: flex-end;
//   justify-content: center;
//   height: 100%;
//   min-width: 500px;
// }

// button {
//   margin: 0 0 40px;
//   background: map-get($colors, primary);
//   color: white;
//   border: none;
//   padding: 0.5em 1em;
//   font-size: 12px;
//   cursor: pointer;
//   transition: background 0.2s ease-in-out;
//   border-radius: 5px;
//   text-transform: uppercase;
//   font-weight: 800;
//   outline: none;

//   &:hover,
//   &:focus {
//     background: darken(map-get($colors, primary), 15%);
//   }

//   &:active {
//     background: darken(map-get($colors, primary), 25%);
//   }
// }

.preloader {
  font-size: 5vmin;
  perspective: 1000em;
  display: flex;
  align-items: center;
  justify-content: center;

  left: 50%;
  top: 40%;
  transition: all 0.9s ease-in-out;
  transform: scale(0.9);
  opacity: 0;

  &.running {
    opacity: 1;
    transform: scale(1);
  }
}
.wrapper {
  width: 3.6em;
  height: 3.6em;
  transform: rotateX(45deg) rotateZ(-45deg);
  transform-style: preserve-3d;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  // animation-duration: $duration * 6;
  // animation-name: rotate;
  // animation-timing-function: linear;
  // animation-iteration-count: infinite;

  > div {
    width: 1em;
    height: 1em;
    padding: 0.1em;
  }

  .box-wrapper {
    transform-style: preserve-3d;
    width: 1em;
    height: 1em;
  }

  .crate .box {
    transition: transform 0.5s ease-in-out;
    transform: translateZ(-0.4em);

    .running & {
      transform: translateZ(0.5em);
    }
  }

  $crates: 7 5 1 3 2 4 6;
  @each $crate in $crates {
    $i: index($crates, $crate);
    .crate:nth-of-type(#{$crate}) .box {
      transition-delay: 0.1s * $i;
    }
  }

  .box {
    width: 1em;
    height: 1em;
    position: relative;
    transform: translateZ(0.5em);
    transform-style: preserve-3d;

    .face {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: darken(map-get($colors, accent), 10%);
    }

    .front {
      transform: rotateX(90deg) translateZ(0.5em);
    }

    .back {
      transform: rotateX(-90deg) translateZ(0.5em);
    }

    .right {
      transform: rotateY(-90deg) translateZ(0.5em);
      background: darken(map-get($colors, accent), 15%);
    }

    .left {
      transform: rotateY(90deg) translateZ(0.5em);
      background: darken(map-get($colors, accent), 15%);
    }

    .top {
      transform: translateZ(0.5em);
      background: lighten(map-get($colors, accent), 5%);
    }

    .bottom {
      transform: translateZ(-0.49em);
      background: map-get($colors, accent);
    }

    .hook {
      position: absolute;
      top: 30%;
      left: 30%;
      width: 40%;
      height: 40%;
      border: 0.1em solid darken(map-get($colors, accent), 35%);
      border-radius: 30%;
      box-sizing: border-box;
      transform: translateZ(0.5em) rotateY(90deg);
    }
  }

  .target {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    opacity: 0;
    transform-style: preserve-3d;
    transition: transform ($duration / 2 * 0.4) ease-in-out;
    transition-delay: $duration / 2 * 0.15;

    .target-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .transition & {
      opacity: 1;
      transform: rotateZ(180deg);

      .target-wrapper {
        transform-style: preserve-3d;
        animation-duration: $duration / 2;
        animation-name: transfer;
      }
    }

    .crate-0 & .box-wrapper {
      transform: translate3d(0em, 0em, 0);
    }

    .crate-1 & .box-wrapper {
      transform: translate3d(1.2em, 0em, 0);
    }

    .crate-2 & .box-wrapper {
      transform: translate3d(2.4em, 0em, 0);
    }

    .crate-3 & .box-wrapper {
      transform: translate3d(0em, 1.2em, 0);
    }

    .crate-4 & .box-wrapper {
      transform: translate3d(2.4em, 1.2em, 0);
    }

    .crate-5 & .box-wrapper {
      transform: translate3d(0em, 2.4em, 0);
    }

    .crate-6 & .box-wrapper {
      transform: translate3d(1.2em, 2.4em, 0);
    }

    .crate-7 & .box-wrapper {
      transform: translate3d(2.4em, 2.4em, 0);
    }
  }

  .transition & .source {
    animation-duration: $duration / 2;
    animation-name: source;
  }

  .transition & .destination {
    animation-duration: $duration / 2;
    animation-name: destination;
  }

  .base {
    position: absolute;
    padding: 0;

    .box {
      width: 3.6em;
      height: 3.6em;
      transform: translateZ(-0.5em);

      .top {
        background: lighten(map-get($colors, primary), 5%);
      }

      .front,
      .back {
        height: 1em;
        background: map-get($colors, primary);
      }

      .back {
        transform: rotateX(-90deg) translateZ(3.1em);
      }

      .left,
      .right {
        width: 1em;
        background: darken(map-get($colors, primary), 10%);
      }

      .left {
        transform: rotateY(90deg) translateZ(3.1em);
      }

      .bottom {
        transform: translateZ(-1.5em);
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }

  .crane {
    transform-style: preserve-3d;
    width: $crane-size;
    height: $crane-size;
    padding: (1.2em - $crane-size) / 2;
    position: relative;
  }

  .mast {
    width: $crane-size;
    height: $crane-size * 6.9;
    position: relative;
    transform-style: preserve-3d;
    transform-origin: 0 0;
    transform: rotateX(90deg) translateZ(-$crane-size / 2);

    .face {
      color: darken(map-get($colors, secondary), 5%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: $crane-beam-size solid;
      border-left-width: $crane-beam-size / 2;
      border-right-width: $crane-beam-size / 2;
      border-top: none;
      box-sizing: border-box;
    }

    .support {
      height: $crane-size;
      border-top: $crane-beam-size solid;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;

      &:after {
        content: " ";
        position: absolute;
        top: 50%;
        margin-top: -$crane-beam-size / 4;
        left: -50%;
        width: 200%;
        height: 0;
        border-top: $crane-beam-size / 2 solid;
        transform: rotate(45deg);
      }
    }

    .front,
    .back {
      color: darken(map-get($colors, secondary), 15%);
    }

    .front {
      transform: rotateY(90deg) translateZ($crane-size/2);
    }

    .back {
      transform: rotateY(-90deg) translateZ($crane-size/2);
    }

    .left {
      transform: translateZ($crane-size/2);
    }

    .right {
      transform: rotateY(180deg) translateZ($crane-size/2);
    }
  }

  .cap-wrapper {
    transform-style: preserve-3d;
    position: absolute;
    top: -0.1em;
    left: -0.1em;
    width: 1em;
    height: 1em;
    transform: translateZ($crane-size * 7);
  }

  .cap {
    transform-style: preserve-3d;
    background: lighten(map-get($colors, secondary), 2%);
    border: $crane-beam-size / 2 solid darken(map-get($colors, secondary), 10%);
    width: 1em;
    height: 1em;
    position: absolute;
    top: 0.1em;
    left: 0.1em;
    border-radius: 0.2em;
    transition: transform $duration / 2 ease-in-out;

    .transition & {
      transition-delay: $duration / 2 * 0.15;
      transition-duration: $duration / 2 * 0.4;
    }

    .crate-0 & {
      transform: rotateZ(135deg);
    }

    .crate-0.transition & {
      transform: rotateZ(315deg);
    }

    .crate-1 & {
      transform: rotateZ(180deg);
    }

    .crate-1.transition & {
      transform: rotateZ(360deg);
    }

    .crate-2 & {
      transform: rotateZ(-135deg);
    }

    .crate-2.transition & {
      transform: rotateZ(45deg);
    }

    .crate-3 & {
      transform: rotateZ(90deg);
    }

    .crate-3.transition & {
      transform: rotateZ(270deg);
    }

    .crate-4 & {
      transform: rotateZ(-90deg);
    }

    .crate-4.transition & {
      transform: rotateZ(90deg);
    }

    .crate-5 & {
      transform: rotateZ(45deg);
    }

    .crate-5.transition & {
      transform: rotateZ(225deg);
    }

    .crate-6 & {
      transform: rotateZ(0deg);
    }

    .crate-6.transition & {
      transform: rotateZ(180deg);
    }

    .crate-7 & {
      transform: rotateZ(-45deg);
    }

    .crate-7.transition & {
      transform: rotateZ(135deg);
    }

    .tower {
      width: $crane-size;
      height: $crane-size * 3;
      position: relative;
      transform-style: preserve-3d;
      transform-origin: 0 0;
      transform: rotateX(90deg) translate3d((1em - $crane-size) / 2, 0, -0.5em);

      > .cable {
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -$cable-size / 2;
        border-left: $cable-size solid black;
        transform-style: preserve-3d;
        transform-origin: 0 100%;

        &:after {
          margin-left: -$cable-size / 2;
          content: " ";
          height: 100%;
          display: block;
          border-left: $cable-size solid black;
          transform: rotateY(90deg);
        }

        &.front {
          height: 3.36em;
          transform: rotateX(63.4deg);
        }

        &.mid-front {
          height: 1.8em;
          transform: rotateX(30deg);
        }

        &.mid-back {
          height: 1.8em;
          transform: rotateX(-30deg);
        }

        &.back {
          height: 2.2em;
          transform: rotateX(-46.8deg);
        }
      }
    }

    .tower > .face {
      color: darken(map-get($colors, secondary), 5%);
      width: 100%;
      height: 100%;
      position: absolute;
      transform-style: preserve-3d;
      top: 0;
      left: 0;

      &.front {
        transform: rotateY(90deg) translateZ($crane-size/2);
      }

      &.back {
        transform: rotateY(-90deg) translateZ($crane-size/2);
      }

      &.left {
        transform: translateZ($crane-size/2);
      }

      &.right {
        transform: rotateY(180deg) translateZ($crane-size/2);
      }

      &.front,
      &.back {
        color: darken(map-get($colors, secondary), 15%);
      }

      .support {
        width: 100%;
        height: 100%;
        position: absolute;
        box-sizing: border-box;
        transform-style: preserve-3d;

        &:after {
          content: " ";
          position: absolute;
        }

        &.right {
          border-right: $crane-beam-size / 2 solid;
          transform-origin: 100% 0;
          transform: rotateX(-8deg) rotateZ(8deg);

          &:after {
            top: 40%;
            right: -$crane-beam-size;
            width: 0.7em;
            border-top: $crane-beam-size / 2 solid;
            transform: rotateZ(45deg);
          }
        }

        &.left {
          border-left: $crane-beam-size / 2 solid;
          transform-origin: 0 0;
          transform: rotateX(-8deg) rotateZ(-8deg);

          &:after {
            top: 59%;
            left: -$crane-beam-size + 0.02em;
            width: 0.5em;
            border-top: $crane-beam-size / 2 solid;
            transform: rotateZ(-45deg);
          }
        }
      }
    }
  }

  .arm {
    color: map-get($colors, secondary);
    position: absolute;
    border: $crane-beam-size / 2 solid;
    width: $crane-size;
    top: 1em;
    left: (1em - $crane-size) / 2;
    box-sizing: border-box;
    transform-style: preserve-3d;

    &.back {
      bottom: 1em;
      top: auto;
    }

    .segment {
      height: $crane-size;
      position: relative;
      transform-style: preserve-3d;
      border-bottom: $crane-beam-size / 2 solid;
      box-sizing: border-box;
      margin: 0 (-$crane-beam-size / 2) 0;

      &:last-child {
        margin-bottom: -$crane-beam-size / 2;

        .face:before {
          display: none;
        }
      }

      .face {
        position: absolute;
        width: 100%;
        height: $crane-size;
        transform-style: preserve-3d;
        top: -$crane-beam-size / 4;

        &:before {
          content: " ";
          position: absolute;
          top: 50%;
          right: 0;
          height: 100%;
          border-right: $crane-beam-size / 2 solid;
        }

        .supports {
          $join-angle: 24deg;
          position: absolute;
          width: 100%;
          height: 100%;
          transform-style: preserve-3d;
          overflow: hidden;

          &:before,
          &:after {
            content: " ";
            position: absolute;
            border-top: $crane-beam-size / 2 solid;
            width: 150%;
          }

          &:before {
            transform-origin: 0 0;
            transform: rotateZ($join-angle);
            top: 0;
          }

          &:after {
            transform-origin: 0 100%;
            transform: rotateZ(-$join-angle);
            bottom: 0;
          }
        }

        $segment-angle: 60deg;

        &.left {
          transform: rotateY(-$segment-angle);
          transform-origin: 0 0;
          color: darken(map-get($colors, secondary), 15%);
        }

        &.right {
          transform: rotateY($segment-angle);
          transform-origin: 100% 0;
          color: darken(map-get($colors, secondary), 7%);

          &:before {
            left: 0;
            right: auto;
          }

          .supports {
            transform: rotateZ(180deg);
          }
        }
      }
    }
  }

  .trolley {
    transform-style: preserve-3d;
    width: $crane-size + $crane-beam-size;
    height: $crane-size;
    position: absolute;
    background: darken(map-get($colors, secondary), 25%);
    top: 0.8em;
    left: -$crane-beam-size;
    transform: translateZ(-0.02em);
    transition: transform 0.5s ease-in-out;

    &:before,
    &:after {
      content: " ";
      position: absolute;
      width: 0.15em;
      height: 100%;
      background: darken(map-get($colors, secondary), 35%);
    }

    &:before {
      transform: rotateY(-90deg);
      transform-origin: 0% 50%;
      left: 0;
    }

    &:after {
      transform: rotateY(90deg);
      transform-origin: 100% 50%;
      right: 0;
    }

    .crate-1 &,
    .crate-3 &,
    .crate-4 &,
    .crate-6 & {
      transform: translateZ(-0.02em) translateY(-0.5em);
    }

    .cables,
    .pully {
      transform-style: preserve-3d;
      width: 0.2em;
      border: $cable-size solid black;
      box-sizing: border-box;
      position: absolute;

      &:before,
      &:after {
        content: " ";
        position: absolute;
        height: 100%;
        top: 0.2em;
        border-left: $cable-size solid black;
        transform: rotateY(90deg);
      }

      &:before {
        left: -$cable-size / 2;
      }

      &:after {
        right: -$cable-size / 2;
      }
    }

    .cables {
      transform-style: preserve-3d;
      top: 50%;
      left: 50%;
      margin-top: -0.1em;
      height: 1.2em;
      border-bottom: none;
      transform-origin: 0 0;
      transform: rotateX(-90deg) rotateY(-90deg);
    }

    .pully {
      height: 2.38em;
      top: 100%;
      left: -$cable-size;
      border-top: none;
      border-bottom-width: 0.2em;
      border-radius: 0 0 15% 15%;
      animation-duration: $duration / 2;
      animation-name: pully-reset;

      .transition & {
        animation-duration: $duration / 2;
        animation-name: pully;
      }
    }

    .hook {
      position: absolute;
      top: 100%;
      margin-top: 0.2em;
      transition: transform 0.6s ease-in-out;
      left: -0.08em;
      width: 0.3em;
      transform-style: preserve-3d;

      &:after {
        content: " ";
        position: absolute;
        width: 0.3em;
        height: 0.3em;
        border: 0.1em solid;
        border-right-color: transparent;
        box-sizing: border-box;
        border-radius: 50%;
        transform: rotateZ(-40deg);
      }

      .crate-0 &,
      .crate-7 & {
        transform: rotateY(45deg);
      }

      .crate-1 &,
      .crate-6 & {
        transform: rotateY(90deg);
      }

      .crate-2 &,
      .crate-5 & {
        transform: rotateY(-45deg);
      }
    }
  }
}

@keyframes pully {
  0% {
    transform: translateZ(0);
  }
  30%,
  70% {
    transform: translateY(-1.2em);
  }
  100% {
    transform: translateZ(0);
  }
}

@keyframes pully-reset {
  0% {
    transform: translateZ(0);
  }
  30%,
  70% {
    transform: translateY(-1.2em);
  }
  100% {
    transform: translateZ(0);
  }
}

@keyframes crane-move {
  0%,
  15%,
  100% {
    transform: rotateZ(0deg);
  }
  35%,
  50% {
    transform: rotateZ(180deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotateX(45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(45deg) rotateZ(360deg);
  }
}

@keyframes source {
  0%,
  30% {
    transform: translateZ(-0.9em);
  }
  70% {
    transform: translateZ(0);
  }
}

@keyframes destination {
  0%,
  30% {
    transform: translateZ(0);
  }
  70%,
  100% {
    transform: translateZ(-0.9em);
  }
}

@keyframes transfer {
  0% {
  }
  30%,
  70% {
    transform: translateZ(1.2em);
  }
  100% {
    transform: translateZ(0);
  }
}
</style>
