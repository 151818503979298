import { userService } from "@/api/user-auth";

const state = {
  entitiesLoaded: false,
  entitiesUpdated: 0,
  entityAdded: false,
  localAllEntities: [],
};
const actions = {
  loadEntities({ commit, rootGetters }, scheduleHeaderId) {
    state.entitiesLoaded = false;
    userService
      .getJson(`baseline/GetBaselinesByScheduleId/` + scheduleHeaderId)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          commit("mutateAllEntities", result.data);
          state.entitiesLoaded = true;
        }
      })
      .catch((error) => {
        //dispatch("alert/error", error, { root: true });
      });
  },
  addBaselineObj({ commit, rootGetters }, baselineToAdd) {
    baselineToAdd.added = true;
    commit("addBaseline", baselineToAdd);
    commit("updateEntityAdded", true);
    commit("updateEntitiesUpdated", true);
  },
};
const mutations = {
  mutateAllEntities(state, result) {
    state.localAllEntities = result;
  },
  addBaseline(state, newBaseline) {
    state.localAllEntities = [...state.localAllEntities, newBaseline];
    //state.localAllEntities.push(newBaseline);
  },
  updateEntityAdded(state, entityAdded) {
    state.entityAdded = entityAdded;
  },
  updateEntitiesUpdated(state, entityAdded) {
    state.entityAdded = entityAdded;
    state.entitiesUpdated += 1;
  },
};
const getters = {
  allEntities(state) {
    return state.localAllEntities;
  },
  entitiesLoaded(state) {
    return state.entitiesLoaded;
  },
  entityAdded(state) {
    return state.entityAdded;
  },
  baselinesToAdd(state) {
    return state.localAllEntities.filter((x) => x.added == true);
  },
};

export const baselines = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
