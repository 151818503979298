import {
  GetCostElements,
  getAllExtraColumnDetails,
  getStructureAll,
  getAllStructureDetail,
  DeleteElement,
} from "@/api/networking.js";
import { post, userService, getJson } from "@/api/user-auth.js";
import { flatMap } from "lodash";
import * as moment from "moment";

/* Create Tree Path */
let data = [],
  i = -1,
  tempData = null;
function getParentLoop(id, path) {
  let p = data.filter((i) => i.id == id)[0];
  if (p.parentId) {
    path.unshift(p.id);
    return getParentLoop(p.parentId, path);
  } else {
    path.unshift(p.id);
  }
  return path;
}

function setpath(data) {
  data.map((i) => {
    i.path = [];
    if (i.parentId) {
      i.path.push(i.id);
      getParentLoop(i.parentId, i.path);
    } else {
      i.path.push(i.id);
    }
    return i;
  });
  return data;
}

function setColElementsHeaders(TabelType) {
  switch (TabelType) {
    default:
      return null;
    case 5:
      return "PlanningCostElement";
    case 6:
      return "ForecastCostElement";
    case 7:
      return "CommitmentCostElement";
    case 8:
      return "ActualCostElement";
  }
}

const state = {
  /* Module Title*/
  APIName: "ActualCostElement",
  tempCostModelRowId: [],
  /*left Side(Children grid details)*/
  /*CurrentData and details*/
  currentChildrenData: [],
  currentchildTempData: [],
  currentChildrenId: null,
  currentChildrenSelectedObj: [],
  currentParentID: [],
  currentselectedChildrenRows: [],
  cuurentcopyRowvalues: [],

  /*Main Data Confing Tools*/
  currentChildrenLoadRecordCounter: 0,
  currentChildrenAddRecordCounter: 0,
  currentChildrenSubRecordCounter: 0,
  currentChildrenDeleteRecordCounter: 0,
  currentChildrenSaveRecordCounter: 0,

  /*Children Node Grid Config*/
  currentChildrenUndoWatcher: 0,
  currentChildrenRedoWatcher: 0,
  currentChildrenredostate: false,
  currentChildrenundostate: false,

  /*Children Node Grid Config*/
  isRelationship: false,
  currentCostModelType: [],

  /*Commonn Config*/
  isSave: 0,
  isDelete: 0,
  datawatcher: 0,
  SaveError: 0,
  duplicateRecord: 0,

  /* Children Column Def */
  currentChildrenColDef: [],
  currentTempChildrenColDef: [],
  loadStructureCounter: 0,
  currentGridColDefCount: 0,
  currentChildrenGridColDefCountCounter: 0,
  cuurentChildrenGridCommimentCounter: 0,
  tempstructuredetails: [],
  tempCostElementTypeValues: [],
};

const actions = {
  /*LoadCostElements */
  async LoadCostElementTypes({ dispatch, commit }, { rowTypeId }) {
    userService
      .getJson(`CostElementType/GetByRowTypeId/${rowTypeId}`)
      .then((result) => {
        if (result.succeeded && result.data != null) {
          state.tempCostElementTypeValues = result.data;
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /*LoadCostElements */
  async LoadCostElements({ dispatch, commit }, { CostElements }) {
    let params = [];

    // state.currentChildrenColDef = state.currentIntialChildrenColDef;

    params.push(CostElements.costModelType.rowType.code);
    params.push(CostElements.id);
    //state.APIName = CostElements.costModelType.rowType.code;

    if (state.currentExtraCostCoulmnId == 0) {
      state.currentExtraCostCoulmnId = state.APIName;
    } else if (state.APIName != CostElements.costModelType.rowType.code) {
      state.currentExtraCostCoulmnId = state.APIName;

      // dispatch("LoadExtraColumnValue", state.APIName);
    } else if (
      state.currentChildrenColDef.find((x) => x.field.match("et_")) == undefined
    ) {
      state.APIName = CostElements.costModelType.rowType.code;

      //dispatch("LoadExtraColumnValue", state.APIName);
    }

    /**
     * Load Col Defs
     *
     */
    //state.currentChildrenColDef.slice(0, 15);

    //dispatch("LoadStructures");
    // dispatch("LoadExtraColumnValue", state.APIName);
    //  state.currentGridColDefCount = state.currentChildrenColDef.length;

    GetCostElements({ CostElements: params })
      .then((rowData) => {
        if (rowData.succeeded) {
          rowData.data.forEach(function (x) {
            //Structure
            if (x.structureDetails.length > 0) {
              x.structureDetails.forEach(
                (y) => (x["sd_" + y.structureId] = y.code)
              );
            }
            if (x.costElementTypeId > 0) {
              let tempCostElementTypeValue =
                state.tempCostElementTypeValues.find(
                  (y) => y.id == x.costElementTypeId
                );
              if (typeof tempCostElementTypeValue == "object") {
                x.tempCostElementType = tempCostElementTypeValue.description;
                x.tempCostElementTypeIcon = tempCostElementTypeValue.icon;
              } else {
                x.tempCostElementType = null;
                x.tempCostElementTypeIcon = null;
              }
            } else {
              x.tempCostElementType = null;
            }

            if (
              x.commitmentDetails != undefined &&
              x.commitmentDetails.length > 0
            ) {
              x.commitmentDetails.forEach(
                (y) => (x["cmit_" + state.tempCostModelRowId[2].id] = y.code)
              );
            }

            //Budget

            if (x.budgetDetails != undefined && x.budgetDetails.length > 0) {
              x.budgetDetails.forEach(function (y) {
                //console.log(y);
                state.tempCostModelRowId[1].id == y.costHeaderCostModelTypeId
                  ? (x["cmit_" + state.tempCostModelRowId[1].id] = y.code)
                  : null;
              });
            }

            //Estimate
            if (
              x.estimateDetails != undefined &&
              x.estimateDetails.length > 0
            ) {
              x.estimateDetails.forEach(function (y) {
                state.tempCostModelRowId[0].id == y.costHeaderCostModelTypeId
                  ? (x["cmit_" + state.tempCostModelRowId[0].id] = y.code)
                  : null;
              });
            }

            //Activity
            if (x.activitiyDetails.length > 0) {
              x.activitiyDetails.forEach((y) => (x.activities = y.code));
            }
          });

          //ExtraColumn
          if (state.APIName == "ActualCostElement") {
            rowData.data.forEach(function (x) {
              if (x.extraColumnValues != null) {
                x.extraColumnValues.forEach(
                  (y) => (x["et_" + y.actualCostElementExtraColumnId] = y.value)
                );
              }
            });
          } else if (state.APIName == "CommitmentCostElement") {
            rowData.data.forEach(function (x) {
              if (x.extraColumnValues != null) {
                x.extraColumnValues.forEach(
                  (y) =>
                    (x["et_" + y.commitmentCostElementExtraColumnId] = y.value)
                );
              }
            });
          } else if (state.APIName == "ForecastCostElement") {
            rowData.data.forEach(function (x) {
              if (x.extraColumnValues != null) {
                x.extraColumnValues.forEach(
                  (y) =>
                    (x["et_" + y.forecastCostElementExtraColumnId] = y.value)
                );
              }
            });
          } else if (state.APIName == "PlanningCostElement") {
            rowData.data.forEach(function (x) {
              if (x.extraColumnValues != null) {
                x.extraColumnValues.forEach(
                  (y) =>
                    (x["et_" + y.planningCostElementExtraColumnId] = y.value)
                );
              }
            });
          }

          commit("setChildrenData", { returnedData: rowData.data });

          state.currentParentId = CostElements.id;
          i = -2;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch("alert/error", error, { root: true });
      });
  },

  /*Load Structure*/
  async LoadStructures(
    { dispatch },
    { CostModelRowObject, CostHeaderRowObject }
  ) {
    state.tempCostModelRowId = [];
    //state.CostHeaderRowObject=;
    state.APIName = CostHeaderRowObject;
    state.currentChildrenColDef = [];

    getStructureAll()
      .then((rowData) => {
        if (rowData.succeeded) {
          CostModelRowObject.forEach((row) => {
            if (row.rowTypeId == 5 || row.rowTypeId == 7) {
              state.currentChildrenColDef.push({
                headerName: row.description,
                field: "cmit_" + row.id,
                sortable: true,
                resizable: true,
                width: 165,
                editable: true,
                hide: false,
                filterParams: { excelMode: "windows" },
                filter: "agSetColumnFilter",
                cellStyle: function (params) {
                  if (params.data.rowUpdated) {
                    return { "background-color": "LightGreen" };
                  }
                  return null;
                },
              });

              state.tempCostModelRowId.push(row);
            }
          });

          if (state.APIName == "CommitmentCostElement") {
            /*  state.currentChildrenColDef[9].headerName = "Commitment Date";
            state.currentChildrenColDef[9].field = "commitmentDate"; */
            state.currentChildrenColDef = state.currentChildrenColDef.slice(
              0,
              2
            );
          } else if (state.APIName == "ForecastCostElement") {
            /*  state.currentChildrenColDef[9].headerName = "Plan Date";
            state.currentChildrenColDef[9].field = "planDate"; */
            state.currentChildrenColDef = state.currentChildrenColDef.slice(
              0,
              2
            );
          } else if (state.APIName == "PlanningCostElement") {
            /*  state.currentChildrenColDef[9].headerName = "Plan Date";
            state.currentChildrenColDef[9].field = "planDate"; */
            state.currentChildrenColDef = [];
          }

          rowData.data.forEach((y) =>
            state.currentChildrenColDef.push({
              headerName: y.code,
              field: "sd_" + y.id,
              sortable: true,
              resizable: true,
              width: 100,
              editable: true,
              filterParams: { excelMode: "windows" },
              filter: "agSetColumnFilter",
              cellStyle: function (params) {
                if (params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellRenderer: function (params) {
                if (params.value != undefined && params.value.match(" ")) {
                  let eIconGui = document.createElement("span");
                  return (eIconGui.innerHTML =
                    '<i class="fas fa-exclamation" style="color:red;"></i>' +
                    " " +
                    params.value);
                }
                return params.value;
              },
            })
          );
          dispatch("LoadExtraColumnValue", state.APIName);
          state.cuurentChildrenGridCommimentCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
  /*Extra Column */
  async LoadExtraColumnValue({ dispatch }, TabelType) {
    state.currentTempChildrenColDef = [];
    getAllExtraColumnDetails(TabelType + "ExtraColumn")
      .then((rowData) => {
        if (rowData.succeeded) {
          rowData.data.forEach((y) =>
            state.currentTempChildrenColDef.push({
              headerName: y.description,
              field: "et_" + y.id,
              sortable: true,
              resizable: true,
              width: 100,
              editable: true,
              filterParams: { excelMode: "windows" },
              filter: "agSetColumnFilter",
              cellStyle: function (params) {
                if (params.data.rowUpdated) {
                  return { "background-color": "LightGreen" };
                }
                return null;
              },
              cellEditorSelector: function (params) {
                if (y.valueType == 1) {
                  return {
                    component: "integerCellEditor",
                  };
                }
                if (y.valueType == 2) {
                  return {
                    component: "decimalCellEditor",
                  };
                } else if (y.valueType == 5) {
                  return {
                    component: "datePicker",
                  };
                } else if (y.valueType == 13) {
                  return {
                    component: "lookupCellEditor",
                  };
                }
                return null;
              },

              cellRenderer: function (params) {
                if (y.valueType == 6) {
                  var input = document.createElement("input");
                  input.type = "checkbox";

                  input.checked = params.value;
                  input.addEventListener("click", function (event) {
                    if (params.column.colId == "et_" + y.id) {
                      if (params.value == null) {
                        params.value = false;
                      }

                      params.value = !params.value;
                      params.node.setDataValue(
                        params.column.colId,
                        params.value
                      );
                    }
                    params.node.data.rowUpdated = true;
                  });
                  return input;
                } else if (y.valueType == 1 || y.valueType == 2) {
                  if (isNaN(params.value)) {
                    return null;
                  } else {
                    return params.value;
                  }
                }

                return params.value;
              },
              valueFormatter: function (params) {
                if (y.valueType == 5) {
                  if (params.value) {
                    return new Date(params.value)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("/");
                  }
                }
                return null;
              },
            })
          );
          state.currentChildrenGridColDefCountCounter++;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },
  /*Cache Structure Details */
  async LoadGlobalStructureDetails({ dispatch }) {
    getAllStructureDetail()
      .then((rowData) => {
        if (rowData.succeeded) {
          state.tempstructuredetails = rowData.data;
        } else {
          dispatch("alert/error", "Network Connection lost", {
            root: true,
          });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /**Delete Cost Element */
  async DeleteCostElement({ dispatch }, { Id }) {
    DeleteElement(state.APIName, Id)
      .then((rowData) => {
        if (rowData.succeeded) {
          state.currentChildrenId = null;
          state.currentChildrenSelectedObj = [];
          state.currentChildrenUndoWatcher = 0;
          state.currentChildrenRedoWatcher = 0;
          state.currentChildrenredostate = false;
          state.currentChildrenundostate = false;
          state.isDelete++;
        } else {
          dispatch("alert/error", "Network Connection lost", { root: true });
        }
      })
      .catch((error) => {
        dispatch("alert/error", error, { root: true });
      });
  },

  /**Save Cost Element */
  async saveData({ dispatch, commit }) {
    var rowsToInsert = [];
    var rowsToUpdate = [];
    var createP = Promise.resolve(1),
      updateP = Promise.resolve(1);
    var createURL = "",
      updateURL = "";
    var createObj = {},
      updateObj = {};

    state.currentchildTempData.forEach((row) => {
      if (row.code != undefined) {
        if (row.rowUpdated) {
          if (row.id <= -1) {
            row.ChildStructureDetails = row.path;
            //delete row.path;
            rowsToInsert.push(row);
          } else {
            rowsToUpdate.push({
              Id: row.id,
              code: row.code,
              description: row.description,
              parentId: row.parentId,
              Quantity: row.quantity,
              TotalQuantity: row.totalQuantity,
              Uom: row.uom,
              UnitCost: row.unitCost,
              TotalCost: row.totalCost,
              UnitHours: row.unitHours,
              TotalHours: row.totalHours,
              FromDate: row.FromDate,
              ToDate: row.ToDate,
              LumpSum: row.lumpSum,
              IsDisabled: row.isDisabled,
              PlanDate: row.planDate,
              objectRelationships: row.objectRelationships,
              StructureDetailsToAssign: row.structureDetails,
              ExtraColumnValues: row.extraColumnValues,
              ActivityToAssign: row.activitiyDetails,
              EstimateToAssign: row.estimateDetails,
              BudgetToAssign: row.budgetDetails,
              CommitmentToAssign: row.commitmentDetails,
            });
            if (state.APIName == "ActualCostElement") {
              rowsToUpdate[
                rowsToUpdate.findIndex((x) => x.Id == row.id)
              ].TransactionDate = row.transactionDate;
            } else if (state.APIName == "CommitmentCostElement") {
              rowsToUpdate[
                rowsToUpdate.findIndex((x) => x.Id == row.id)
              ].CommitmentDate = row.commitmentDate;
            }
          }
        } else {
          state.SaveError++;
          rowsToInsert = [];
          rowsToUpdate = [];
          return false;
        }
      }
    });

    if (rowsToInsert.length > 0) {
      createURL = state.APIName + "/Create";

      if (state.APIName == "ActualCostElement")
        createObj = { ActualCostElementToCreate: rowsToInsert };
      else if (state.APIName == "PlanningCostElement") {
        createObj = { PlanningCostElementToCreate: rowsToInsert };
      } else if (state.APIName == "ForecastCostElement") {
        createObj = { ForecastCostElementToCreate: rowsToInsert };
      } else if (state.APIName == "CommitmentCostElement") {
        createObj = { CommitmentCostElementToCreate: rowsToInsert };
      }
    }
    //  console.log(rowsToUpdate);
    if (rowsToUpdate.length > 0) {
      updateURL = state.APIName + "/Update";

      updateObj = null;
      if (state.APIName == "ActualCostElement")
        updateObj = { ActualCostElementsToUpdate: rowsToUpdate };
      else if (state.APIName == "PlanningCostElement") {
        updateObj = { PlanningCostElementsToUpdate: rowsToUpdate };
      } else if (state.APIName == "ForecastCostElement") {
        updateObj = { ForecastCostElementsToUpdate: rowsToUpdate };
      } else if (state.APIName == "CommitmentCostElement") {
        updateObj = { CommitmentCostElementsToUpdate: rowsToUpdate };
      }
    }

    if (createURL != "") {
      createP = post(createURL, createObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = values.data;
          /* if (values)
                    returnedValues = returnedValues.concat(values.data); */
          commit("setSavedChildrenData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }

    if (updateURL != "") {
      updateP = post(updateURL, updateObj)
        .then((values) => {
          var returnedValues = [];
          if (values) returnedValues = returnedValues.concat(values.data);
          commit("setSavedChildrenData", { returnedData: returnedValues });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        });
    }
    i = -1;
  },
};

const mutations = {
  /*left Side(Children grid details)*/
  /*CurrentData and details*/

  setChildrenData(state, { returnedData }) {
    returnedData.forEach((row) => {
      row.rowUpdated = false;
    });
    data = returnedData;
    tempData = setpath(data);
    state.currentChildrenData = returnedData;
    /*Reset the Children Grid property */
    state.currentChildrenId = null;
    state.currentChildrenSelectedObj = [];
    state.currentChildrenDeleteRecordCounter = 0;
    state.currentChildrenUndoWatcher = 0;
    state.currentChildrenRedoWatcher = 0;
    state.currentChildrenredostate = false;
    state.currentChildrenundostate = false;
    state.currentChildrenAddRecordCounter = 0;
    state.currentChildrenLoadRecordCounter++;
  },

  setSavedChildrenData(state, { returnedData }) {
    returnedData.forEach((row) => (row.rowUpdated = false));
    state.currentchildTempData.forEach((row) => {
      row.rowUpdated = false;
      if (row.id < 0) {
        row.id = returnedData.find(
          (x) => x.code == row.code && x.description == row.description
        ).id;
        row.path[row.path.length - 1] = row.id;
      }
      if (row.parentId < 0) {
        row.parentId = returnedData.find(
          (x) => x.code == row.code && x.description == row.description
        ).parentId;
        if (row.path.filter((x) => x < 0).length > 1) {
          row.path = state.currentchildTempData.find(
            (x) => x.id == row.parentId
          ).path;
          row.path.push(row.id); // row.id;
        } else {
          row.path[row.path.length - 2] = row.parentId;
          row.path[row.path.length - 1] = row.id;
        }
      }
      delete row.ChildStructureDetails;
    });
    //state.currentChildrenData = returnedData;
    /*Reset the Children Grid property */
    state.currentChildrenUndoWatcher = 0;
    state.currentChildrenRedoWatcher = 0;
    state.currentChildrenredostate = false;
    state.currentChildrenundostate = false;
    state.datawatcher++;
  },

  setChildrenObj(state, row) {
    if (row != 0) {
      state.currentChildrenSelectedObj = row;
      state.currentChildrenId = row.id;
    }
  },

  setSelectedChildrenRowObject(state, row) {
    state.currentselectedChildrenRows = row;
  },

  /*mutate counter for CRUD*/
  mutateChildrenAddRecord(state) {
    state.currentChildrenAddRecordCounter++;
  },

  mutateChildrenAddSubRecord(state) {
    state.currentChildrenSubRecordCounter++;
  },

  mutateChildrenDelete(state) {
    state.currentChildrenDeleteRecordCounter++;
  },

  mutateChildrenSave(state) {
    state.currentChildrenSaveRecordCounter++;
  },

  /*Children Grid Config*/
  mutateChildrenRedo(state) {
    state.currentChildrenRedoWatcher++;
  },

  mutateChildrenUndo(state) {
    state.currentChildrenUndoWatcher++;
  },

  mutateChildrenRedostate(state, value) {
    state.currentChildrenredostate = value;
  },

  mutateChildrenUndostate(state, value) {
    state.currentChildrenundostate = value;
  },

  mutateElementRealtionship(state, isRelationship) {
    state.isRelationship = isRelationship;
  },

  mutateChildrenCopyRecord(state) {
    const tempcopydata = [],
      tempconstData = [];

    state.currentselectedChildrenRows.forEach((row) => {
      tempcopydata.push({ ...row });
      tempconstData.push(JSON.parse(JSON.stringify(row)));
    });

    let tempchange = null,
      tempchangechild = null,
      trackParent = false;
    let objtempParentData = tempcopydata[0].path;
    for (let y = 0; y < tempcopydata.length; y++) {
      tempcopydata[y].id = i - 25;
      tempcopydata[y].rowUpdated = true;
      if (y > 0) {
        if (tempcopydata[y].parentId == tempconstData[y - 1].parentId) {
          trackParent = false;
          tempchangechild = y - 1;
        } else {
          trackParent = true;
          tempchange = tempconstData.findIndex(
            (x) => x.id == tempcopydata[y].parentId
          );
        }
      }
      delete tempcopydata[y].path;
      if (tempcopydata[y].parentId > 0) {
        if (y == 0) {
          tempcopydata[y].parentId =
            state.currentselectedChildrenRows[0].parentId;
          objtempParentData[objtempParentData.length - 1] = tempcopydata[y].id;
          tempcopydata[y].path = objtempParentData;
        } else {
          if (trackParent) {
            tempcopydata[y].parentId = tempcopydata[tempchange].id;
          } else {
            tempcopydata[y].parentId = tempcopydata[tempchangechild].parentId;
          }
        }
      } else {
        tempcopydata[y].parentId = null;
      }
      i--;
    }
    state.cuurentcopyRowvalues = setpath(tempcopydata);
    state.duplicateRecord++;
  },

  mutateRecord(state, { returnedNewData }) {
    state.currentchildTempData = [];
    returnedNewData.forEach((element) => {
      if (element.rowUpdated) {
        state.currentchildTempData.push(element);
      }
    });
    state.currentchildTempData = returnedNewData;
  },
};

const getters = {
  /*left Side(Children grid details)*/
  /*CurrentData and details*/
  getChildrenGridColumn() {
    return state.currentChildrenColDef;
  },
  getTempChildColDef() {
    return state.currentTempChildrenColDef;
  },

  getChildrenCurrentData() {
    return state.currentChildrenData;
  },

  getChildTempData() {
    return state.currentchildTempData;
  },

  getChildrenSelectedObj() {
    return state.currentChildrenSelectedObj;
  },
  getChildrenSelectedId() {
    return state.currentChildrenId;
  },
  /*Extra Config */
  getCostModelTypeData() {
    return state.currentCostModelType;
  },
  getcostHeaderStatus() {
    return state.isRelationship;
  },
  getAllStructureDetailsvalue() {
    return state.tempstructuredetails;
  },

  getCopiedRows() {
    return state.cuurentcopyRowvalues;
  },
  getCostElementTypes() {
    return state.tempCostElementTypeValues;
  },
};

/* CostHeader Module */
export const costElementController = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
