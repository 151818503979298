<template>
  <section style="margin-top: -0.48%; width: 100%; height: 100%">
    <div class="line" style="margin-top: 0.03%; margin-left: 0.2%"></div>
    <div
      class="column"
      style="background-color: white; width: 100%; height: 100%"
      ref="scriptContainerScheduleResources"
    >
      <ScheduleResourcesToolbar />

      <ag-grid-vue
        class="ag-theme-balham ag-default-layout"
        rowSelection="multiple"
        enableCellChangeFlash="true"
        stopEditingWhenGridLosesFocus="true"
        :enableRangeSelection="true"
        :undoRedoCellEditing="true"
        :treeData="true"
        :getDataPath="getDataPath"
        :defaultColDef="defaultColDef"
        :autoGroupColumnDef="autoGroupColumnDef"
        :columnDefs="columnDefs"
        :rowData="rowChildrenData"
        :components="childcomponents"
        :gridOptions="gridChildrenOptions"
        :overlayLoadingTemplate="loadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
        :detailCellRendererParams="detailCellRendererParams"
        @cellClicked="cellClicked"
        @cell-value-changed="onCellValueChanged"
        @row-drag-move="onRowDragMove"
        @row-drag-leave="onRowDragLeave"
        @row-drag-end="onRowDragEnd"
        @grid-ready="onGridReady"
        @sort-changed="onColumnChanged"
        @column-resized="onColumnChanged"
        @column-visible="onColumnChanged"
        @column-row-group-changed="onColumnChanged"
        @column-value-changed="onColumnChanged"
        @column-moved="onColumnChanged"
        @column-pinned="onColumnChanged"
      >
      </ag-grid-vue>
    </div>
  </section>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import ScheduleResourcesToolbar from "./ScheduleResourcesToolbar.vue";
import LevelIconCellRenderer from "@/components/Data/LevelIconCellRenderer.vue";
import * as moment from "moment";
import * as utilitiesModule from "@/assets/utilities.js";
import { debounce } from "lodash";
import {
  getColumnDef,
  postColumnState,
  getColumnState,
} from "@/api/networking.js";

export default {
  name: "ScheduleResourcesManager",
  components: {
    AgGridVue,
    ScheduleResourcesToolbar,
    LevelIconCellRenderer, // eslint-disable-line
    //ScheduleCellRenderer, // eslint-disable-line
  },
  data() {
    return {
      defaultColDef: null,
      autoGroupColumnDef: null,
      columnDefs: null,
      rowChildrenData: null,
      tempChildData: null,
      getDataPath: null,
      detailCellRendererParams: {
        refreshStrategy: "everything",
      },
      i: -1,
      gridChildrenOptions: {
        statusBar: {
          statusPanels: [
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
          ],
        },
        /*Add in Row Dynamically when Paste data from excel or ag-grid*/
        processDataFromClipboard: (params) => {
          const emptyLastRow =
            params.data[params.data.length - 1][0] === "" &&
            params.data[params.data.length - 1].length === 1;

          if (emptyLastRow) {
            params.data.splice(params.data.length - 1, 1);
          }

          let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
          this.gridChildrenOptions.api.forEachNode((node) => {
            if (node.data.parentId === selectedNodes[0].data.parentId) {
              node.setSelected(true);
              node.setExpanded(false);
            }
          });
          let selectedData = selectedNodes.map((node) => node.data);
          let selectedKey = selectedData[0].id;
          selectedData = selectedData[0].parentId;

          selectedNodes = this.tempChildData;
          /*   this.gridChildrenOptions.api.getModel().gridOptionsWrapper.gridOptions
              .rowData; */
          const indexes = selectedNodes
            .map((selectedNodes, i) =>
              selectedNodes.parentId === selectedData ? i : null
            )
            .filter((i) => i !== null);

          const focusedCell = this.gridChildrenOptions.api.getFocusedCell();
          const focusedIndex = focusedCell.rowIndex;
          const getParentId =
            this.gridChildrenOptions.api.getSelectedNodes(focusedIndex);

          var tempId = getParentId.findIndex((x) => x.data.id == selectedKey);

          if (tempId + params.data.length - 1 >= indexes.length - 1) {
            const addRowCount = params.data.length - (indexes.length - tempId);
            this.i = -this.tempChildData.length;
            this.i--;
            let rowsToAdd = [];
            let addedRows = 0;
            let currIndex = params.data.length - 1;
            var temp = null;
            if (getParentId[tempId].data.id < 0) {
              this.i = getParentId[tempId].data.id;
            }
            if (getParentId[tempId].data.path.length > 1) {
              temp = getParentId[tempId].data.path.length;
              temp = getParentId[tempId].data.path.slice(0, temp - 1);
            }
            while (addedRows < addRowCount) {
              rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
              addedRows++;
              currIndex--;
            }

            rowsToAdd = rowsToAdd.reverse();
            let newRowData = [];

            //rowsToAdd = rowsToAdd.filter(Boolean);
            rowsToAdd.map((r) => {
              let row = {};
              let currColumn = focusedCell.column;
              r.map((i) => {
                row[currColumn.colDef.field] = i;
                currColumn =
                  this.gridChildrenOptions.columnApi.getDisplayedColAfter(
                    currColumn
                  );
              });
              if (this.tempChildData.filter((x) => x.code == r[0]).length > 0) {
                row.rowUpdated = "Duplicate";
              } else {
                row.rowUpdated = true;
              }
              row.rowUpdated = true;
              row.extraColumnValues = [];
              row.parentId = getParentId[tempId].data.parentId;
              row.id = this.i - this.tempChildData.length;

              if (row.parentId != null) {
                if (getParentId[0].data.id < 0) {
                  row.path = temp.concat([row.id]); //[row.parentId, row.id];
                } else {
                  if (getParentId[0].data.path.length < 2) {
                    row.path = [row.parentId, row.id];
                  } else {
                    row.path = temp.concat([row.id]);
                  }
                }
              } else {
                row.path = [row.id];
              }

              newRowData.push(row);
              this.tempChildData.push(row);
              this.i = -this.tempChildData.length;
            });

            this.gridChildrenOptions.api.applyTransactionAsync({
              add: newRowData,
            });
            this.gridChildrenOptions.api.redrawRows();
            this.gridChildrenOptions.api.refreshCells();
          }
          var undoSize = this.gridChildrenOptions.api.getCurrentUndoSize();
          if (undoSize == 0) {
            this.mutateChildrenUndostate(true);
          }
          return params.data;
        },
      },
      groupDefaultExpanded: null,
      childcomponents: {
        resourceCalendarCombobox: getCalendarModelCellRenderer(),
        resourceTypeCombobox: getResourceTypesModelCellRenderer(),
        scheduleCellRenderer: getScheduleCellRenderer(),
        datePicker: getDatePicker(),
        integerCellEditor: getIntegerCellEditor(),
        decimalCellEditor: getDecimalCellEditor(),
        lookupCellEditor: getLookupCellEditor(),
        //   ScheduleCellRenderer: getScheduleCellRenderer(),
      },
      undoRedoCellEditingLimit: 15,
      parentObj: null,
      singleClickEdit: false,
      invalidindex: [],
      loadingTemplate: `<span class="ag-overlay-loading-center">Data is loading...</span>`,
      deleteSettings: {
        withBackdrop: true,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "center-center",
      },
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
    };
  },
  watch: {
    LoadChildData() {
      this.invalidindex = [];
      this.rowChildrenData = this.getChildrenCurrentData;
      this.tempChildData = Array.from(this.getChildrenCurrentData);
      SetCalendars(this.getcalendarData);
      SetResourceTypes(this.getResourceTypeData);
      this.gridChildrenOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      this.reloadColumnState();

      //this.childcomponents =
    },

    SaveError() {
      this.$vToastify.setSettings(this.NormalSettings);
      this.$vToastify.error("Invalid Schedule Resources Type!");
    },
    UndoCounter() {
      this.gridChildrenOptions.api.undoCellEditing();
    },

    RedoCounter() {
      this.gridChildrenOptions.api.redoCellEditing();
    },

    PasteRowCounter() {
      if (this.getCopiedRows.length > 0) {
        this.getCopiedRows.forEach((row) => {
          this.tempChildData.push(row);
        });
      }
      this.gridChildrenOptions.api.applyTransaction({
        add: this.getCopiedRows,
      });

      this.GridExapnd(this.getCopiedRows[0].id, false);
    },

    AddCounter() {
      setTimeout(() => {
        this.SetFocus();
      }, 100);
    },

    AddSubRecordCounter() {
      if (this.AddSubRecordCounter > 0) {
        if (
          this.getChildrenSelectedId == null &&
          this.tempChildData.length > 0
        ) {
          let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
          this.setChildrenObj(selectedNodes[0].data);
        }
        this.i = this.i - 1;
        this.AddNewRecord(true);
      }
    },

    AddRecordCounter() {
      if (this.AddRecordCounter > 0) {
        if (this.getChildrenSelectedId == null) {
          let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
          if (selectedNodes.length > 0) {
            this.setChildrenObj(selectedNodes[0].data);
          }
        }
        this.i = this.i - 1;
        this.AddNewRecord(false);
      }
    },

    SaveCounter() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.SaveCounter > 0) {
        let newRecord = [],
          indexes = [],
          emptyRowCounter = 0;
        newRecord = new Set(
          this.tempChildData.map((v) => v.code && v.rowUpdated == false)
        );
        if (newRecord.size < this.tempChildData.length) {
          indexes = _.countBy(this.tempChildData, "code");
          newRecord = _.filter(this.tempChildData, (x) => indexes[x.code] > 1);
          if (newRecord.length > 0) {
            newRecord.forEach((element) => {
              this.tempChildData.map((obj, index) => {
                if (obj.id === element.id) {
                  this.tempChildData[index].rowUpdated = true;
                }
              });
            });
          }
        }

        newRecord = [];
        indexes = [];
        this.invalidindex = Array.from(this.invalidindex);
        this.invalidindex = this.invalidindex.filter((x) => x !== undefined);
        this.gridChildrenOptions.api.refreshCells();
        this.gridChildrenOptions.api.forEachNode((node) => {
          if (node.data.rowUpdated) {
            if (node.data.code != null || node.data.resourceTypeId != null) {
              this.tempChildData.filter((element) => {
                if (element.code === node.data.code) {
                  if (node.key != element.id) {
                    node.data.rowUpdated = "Duplicate";
                    indexes.push(node.rowIndex);
                  }
                }
              });
              newRecord.push(node.data);
            } else {
              newRecord = [];
              emptyRowCounter++;
              return false;
            }
          }
        });
        if (emptyRowCounter > 0 || indexes.length > 0) {
          this.gridChildrenOptions.api.redrawRows();
          this.gridChildrenOptions.api.refreshCells();
          newRecord = [];
          emptyRowCounter = 0;
          this.$vToastify.error("Code Value is empty or duplicate!");
        } else if (this.invalidindex.length > 0) {
          this.gridChildrenOptions.api.redrawRows();
          this.gridChildrenOptions.api.refreshCells();
          newRecord = [];
          emptyRowCounter = 0;
          this.$vToastify.error("Invalid Schedule Resources Detail Code(s)!");
        } else {
          this.mutateRecord({ returnedNewData: newRecord });
          this.saveData();
        }
      }
    },

    DeleteCounter() {
      if (this.DeleteCounter > 0) {
        this.$vToastify.setSettings(this.deleteSettings);
        this.$vToastify
          .prompt({
            body: "Delete selected item(s)?",
            answers: { Confirm: true, Abort: false },
          })
          .then((value) => {
            if (value) {
              let selectedNodes =
                this.gridChildrenOptions.api.getSelectedNodes();

              let selectedData = selectedNodes.map((node) => node.data);
              if (selectedData.length > 0) {
                let DataDelete = [],
                  deleteIds = [];
                for (let i = 0; i < selectedData.length; i++) {
                  DataDelete.push(
                    this.tempChildData.filter((x) =>
                      x.path.includes(selectedData[i].id)
                    )
                  );
                }
                DataDelete.forEach((element) => {
                  element.forEach((data) => {
                    selectedData.push(data);
                  });
                });

                DataDelete = _.uniqBy(selectedData, "id");
                let Deletevalues = DataDelete.map((x) => x.id);
                Deletevalues.forEach((element) => {
                  deleteIds.push(
                    this.tempChildData.findIndex((x) => x.Id == element)
                  );
                });

                deleteIds.forEach((x) => {
                  delete this.tempChildData[x];
                });
                let indices = Deletevalues.filter((x) => x > 0);
                if (indices.length > 0) {
                  this.DeleteData({ Id: indices });
                }
                this.gridChildrenOptions.api.applyTransaction({
                  remove: DataDelete,
                });
                deleteIds = null;
                indices = null;
                Deletevalues = null;
                DataDelete = null;
                selectedData = null;
              }
            } else {
              this.$vToastify.setSettings(this.NormalSettings);
            }
          });
      }
    },

    isDelete() {
      if (this.isDelete > 0) {
        this.$vToastify.setSettings(this.NormalSettings);
        this.gridChildrenOptions.api.redrawRows();
        this.gridChildrenOptions.api.refreshCells();
        this.$vToastify.success("Row Deleted Successfully!");
      }
    },

    DataWatcher() {
      this.$vToastify.setSettings(this.NormalSettings);
      if (this.DataWatcher > -1) {
        this.gridChildrenOptions.api.applyTransaction({
          add: this.getChildTempData,
        });
        this.gridChildrenOptions.api.redrawRows();
        this.gridChildrenOptions.api.refreshCells();

        this.tempChildData = [];
        let tempValues = [];
        this.gridChildrenOptions.api.forEachNode((node) => {
          node.data.rowUpdated = false;
          if (node.data.id > 0) this.tempChildData.push(node.data);
          else tempValues.push(node.data);
        });
        this.gridChildrenOptions.api.applyTransaction({
          remove: tempValues,
        });
        this.gridChildrenOptions.api.clearFocusedCell();
        let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();
        if (selectedNodes.length > 0) {
          this.gridChildrenOptions.api.selectIndex(
            selectedNodes[0].rowIndex,
            false,
            false
          );
          this.gridChildrenOptions.api.setFocusedCell(
            selectedNodes[0].rowIndex,
            "code"
          );
          this.setChildrenObj(selectedNodes[0].data);
        }
        this.invalidindex = [];
        this.$vToastify.success("Data successfully saved!");
      } else {
        this.tempChildData = [];
        this.rowChildrenData = [];
        this.gridChildrenOptions.api.redrawRows();
        this.gridChildrenOptions.api.refreshCells();
      }
    },

    ColumnDefCounters() {
      // this.columnDefs = this.getChildrenGridColumn;
      this.getChildrenGridColumn.forEach((row) => {
        this.columnDefs.push(row);
      });
    },
    calendarLoad() {
      this.gridChildrenOptions.api.forEachNode((node) => {
        if (node.data.calendarId != null) {
          node.data.tempcalendarId = node.data.calendarId;
          var tempdata = this.getcalendarData.filter(
            (x) => x.id == node.data.calendarId
          );
          node.data.calendarId = tempdata[0].description;
        }
      });
      //console.log(this.getResourceTypeData);
      this.gridChildrenOptions.api.redrawRows();
      this.gridChildrenOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      SetCalendars(this.getcalendarData);
    },

    resourceLoad() {
      this.gridChildrenOptions.api.forEachNode((node) => {
        //node.data.tempresourceTypeId = node.data.resourceTypeId;
        var tempdata1 = this.getResourceTypeData.filter(
          (x) => x.id == node.data.resourceTypeId
        );

        node.data.tempresourceTypeId = tempdata1[0].description;
      });
      this.gridChildrenOptions.api.redrawRows();
      this.gridChildrenOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
      SetResourceTypes(this.getResourceTypeData);
    },
    ExtraColumnLoad() {
      this.getParentColDefs.forEach((row) => {
        this.columnDefs.push(row);
      });
      this.reloadColumnState();
    },
  },

  computed: {
    /**
     * Load CostElements Controller Gettters, states
     */

    ...mapGetters("scheduleResourceController", [
      "getChildrenGridColumn",
      "getChildrenCurrentData",
      "getAllStructureDetailsvalue",
      "getCopiedRows",
      "getChildrenSelectedId",
      "getChildTempData",
      "getcalendarData",
      "getChildrenSelectedObj",
      "getResourceTypeData",
      "getParentColDefs",
    ]),

    /**scheduleResourceController */

    ...mapState({
      colDefs: (state) =>
        state.scheduleResourceController.currentChildrenColDef,
      ColumnDefCounters: (state) =>
        state.scheduleResourceController.currentChildrenGridColDefCountCounter,
      LoadChildData: (state) =>
        state.scheduleResourceController.currentChildrenLoadRecordCounter,
      UndoCounter: (state) =>
        state.scheduleResourceController.currentChildrenUndoWatcher,
      RedoCounter: (state) =>
        state.scheduleResourceController.currentChildrenRedoWatcher,
      PasteRowCounter: (state) =>
        state.scheduleResourceController.duplicateRecord,
      AddRecordCounter: (state) =>
        state.scheduleResourceController.currentChildrenAddRecordCounter,
      AddSubRecordCounter: (state) =>
        state.scheduleResourceController.currentChildrenSubRecordCounter,
      DeleteCounter: (state) =>
        state.scheduleResourceController.currentChildrenDeleteRecordCounter,
      isDelete: (state) => state.scheduleResourceController.isDelete,
      SaveCounter: (state) =>
        state.scheduleResourceController.currentChildrenSaveRecordCounter,
      DataWatcher: (state) => state.scheduleResourceController.datawatcher,
      SaveError: (state) => state.scheduleResourceController.SaveError,
      calendarLoad: (state) =>
        state.scheduleResourceController.tempcalendarCounter,
      resourceLoad: (state) =>
        state.scheduleResourceController.tempResourceCounter,
      ExtraColumnLoad: (state) =>
        state.scheduleResourceController.currentParentColumDefCounter,
    }),

    noRowsTemplate() {
      if (this.isElementdetail) {
        return this.loadingTemplate;
      } else {
        return `<span>No Rows to Show</span>`;
      }
    },
  },
  methods: {
    /**
     * Load CostElements Controller Actions, Mutation
     */

    ...mapActions("scheduleResourceController", [
      "loadResources",
      "LoadCalendarColumn",
      "saveData",
      "DeleteData",
      "LoadResourceTypesColumn",
      "LoadCalendarExtraColumn",
    ]),
    ...mapMutations("scheduleResourceController", [
      "setChildrenObj",
      "setSelectedChildrenRowObject",
      "mutateChildrenUndostate",
      "mutateChildrenRedostate",
      "mutateRecord",
    ]),

    SetFocus() {
      let tempRowIndex = 0;
      this.gridCostHeaderOptions.api.forEachNode((node) => {
        if (node.data.id < 0) {
          node.setSelected(true);
          tempRowIndex = node.rowIndex;
        }
      });
      this.gridCostHeaderOptions.api.ensureIndexVisible(tempRowIndex);
      this.gridCostHeaderOptions.api.setFocusedCell(tempRowIndex, "code");
      //this.gridCostHeaderOptions.api.selectIndex(tempRowIndex, false, false);

      this.gridCostHeaderOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    cellClicked(params) {
      let tempdata = [];
      params.node.allLeafChildren.forEach((row) => {
        tempdata.push(row.data);
      });
      this.setSelectedChildrenRowObject(tempdata);

      let selectedNodes = params.node;
      params.api.forEachNode((node) => {
        if (node.data.path[node.data.path.length - 1] === selectedNodes.key) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
      this.setChildrenObj(params.node.data);
      if (params.column.colId == "ag-Grid-AutoColumn") {
        params.api.forEachNode((node) => {
          if (node.data.path[node.data.path.length - 1] === selectedNodes.id) {
            let thisParent = node.parent;
            while (thisParent) {
              thisParent.setExpanded(true);
              thisParent = thisParent.parent;
            }
            node.setSelected(true);
            node.setExpanded(true);
          }
        });
      }
      params.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    AddNewRecord(subRecord) {
      if (this.getChildrenSelectedId != null) {
        let tempDataIndex = 0;
        this.gridChildrenOptions.api.refreshCells();
        const focusedCell = this.gridChildrenOptions.api.getFocusedCell();
        const focusedIndex = focusedCell.rowIndex;
        const getParentId =
          this.gridChildrenOptions.api.getSelectedNodes(focusedIndex);
        var tempId = getParentId.findIndex(
          (x) => x.data.id == this.getChildrenSelectedId
        );
        let rowsToAdd = [];
        var temp = null;
        var params = {
          force: false,
          suppressFlash: true,
        };
        if (getParentId[tempId].data.id < 0) {
          this.i = getParentId[tempId].data.id;
        }
        if (getParentId[tempId].data.path.length > 1) {
          temp = getParentId[tempId].data.path.length;
          temp = getParentId[tempId].data.path.slice(0, temp - 1);
        }
        if (
          getParentId[tempId].allChildrenCount == null &&
          getParentId[tempId].uiLevel == 0
        ) {
          params.force = true;
        }
        this.i = this.i - this.tempChildData.length;
        rowsToAdd.push(1);
        rowsToAdd = rowsToAdd.reverse();
        let newRowData = [];
        rowsToAdd.map(() => {
          let row = {};

          if (subRecord) {
            row.parentId = getParentId[tempId].data.id;
          } else {
            row.parentId = getParentId[tempId].data.parentId;
          }
          if (row.parentId == 0) {
            row.parentId = null;
          }

          row.id = this.i - 1;
          if (row.path == undefined) {
            row.path = [];
          }
          if (row.parentId != null) {
            if (getParentId[0].data.id < 0) {
              if (subRecord) {
                if (temp != null) {
                  row.path = temp.concat([row.parentId], [row.id]);
                } else {
                  row.path = [row.parentId, row.id];
                }
              } else {
                row.path = temp.concat([row.id]); //[row.parentId, row.id];
              }
            } else {
              if (getParentId[0].data.path.length < 2) {
                row.path = [row.parentId, row.id];
              } else {
                if (subRecord) {
                  if (temp != null) {
                    row.path = temp.concat([row.parentId], [row.id]);
                  } else {
                    row.path = [row.parentId, row.id];
                  }
                  // row.path = temp.concat([row.parentId], [row.id]);
                } else {
                  row.path = temp.concat([row.id]);
                }
              }
            }
          } else {
            row.path = [row.id];
          }
          tempDataIndex = row.path[row.path.length - 1];
          row.resourceTypeId = null;
          row.tempresourceTypeId = null;
          row.extraColumnValues = [];
          row.capacity = 1;
          row.tempcalendarId = null;
          row.calendarId = null;
          row.code = null;
          row.description = null;
          row.rowUpdated = true;

          this.i = this.i - 2;
          newRowData.push(row);
          this.tempChildData.push(row);
        });
        this.gridChildrenOptions.api.applyTransaction({
          add: newRowData,
        });
        this.gridChildrenOptions.api.refreshCells(params);
        this.GridExapnd(newRowData[0].id, true);
        let selectedNodes = this.gridChildrenOptions.api.getSelectedNodes();

        this.setChildrenObj(selectedNodes[0].data);
      }
      if (this.gridChildrenOptions.api.getModel().rowsToDisplay.length == 0) {
        let rowsToAdd = [];
        rowsToAdd.push(1);
        rowsToAdd = rowsToAdd.reverse();
        let newRowData = [];
        rowsToAdd.map(() => {
          let row = {};
          row.rowUpdated = true;
          row.parentId = null;
          row.id = this.i - 1;
          row.path = [row.id];
          row.tempcalendarId = null;
          row.calendarId = null;
          row.resourceTypeId = null;
          row.tempresourceTypeId = null;
          row.capacity = 1;
          row.extraColumnValues = [];
          //row.CostHeaderID = this.parentObj.id;
          this.i = this.i - 2;
          newRowData.push(row);
          this.tempChildData.push(row);
        });
        this.gridChildrenOptions.api.applyTransactionAsync({
          add: newRowData,
        });
        this.gridChildrenOptions.api.refreshCells();
        this.setChildrenObj(selectedNodes[0].data);
        this.gridChildrenOptions.api.selectIndex(0, false, false);
        this.gridChildrenOptions.api.setFocusedCell(0, "code");
      }
    },

    GridExapnd(subRecord, expand) {
      this.gridChildrenOptions.api.clearFocusedCell();
      let tempRowIndex = 0;
      this.gridChildrenOptions.api.forEachNode((node) => {
        if (node.data.id == subRecord) {
          let thisParent = node.parent;
          while (thisParent && expand) {
            thisParent.setExpanded(true);
            thisParent = thisParent.parent;
          }
          node.setSelected(true);
          node.setExpanded(expand);
          tempRowIndex = node.rowIndex;
        }
      });
      this.gridChildrenOptions.api.ensureIndexVisible(tempRowIndex);
      this.gridChildrenOptions.api.setFocusedCell(tempRowIndex, "code");
      this.gridChildrenOptions.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },

    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize();
      var redoSize = params.api.getCurrentRedoSize();
      if (params.node.data) {
        let selectedNodes = params.node.data;
        if (selectedNodes.length > 0) {
          this.setChildrenObj(params.node.data);
        }
      }

      if (params.column.colId == "code") {
        var CodeIndex = this.tempChildData.filter(
          (x) => x.id != params.node.data.id
        );
        CodeIndex = CodeIndex.findIndex(
          (x) => x.code.toLowerCase() == params.newValue.toLowerCase()
        );
        if (CodeIndex > -1) {
          this.$vToastify.setSettings(this.NormalSettings);
          this.$vToastify.error("Duplicate code value detected!");
          params.node.data.code = params.newValue;
          params.node.data.rowUpdated = "Duplicate";
        } else {
          params.node.data.rowUpdated = true;
        }
      } else if (params.column.colId.match("calendarId")) {
        if (params.newValue != "") {
          if (
            typeof parseInt(params.newValue) === "number" &&
            this.getcalendarData.find((x) => x.id == params.newValue) ==
              undefined
          ) {
            params.data.calendarId = params.newValue;
            params.data.tempcalendarId = this.getcalendarData.find(
              (x) => x.description == params.newValue
            ).id;
          } else {
            params.data.tempcalendarId = params.newValue;
            params.data.calendarId = this.getcalendarData.find(
              (x) => x.description == params.newValue
            ).id;
          }
        } else {
          params.data.calendarId = null;
          params.data.tempcalendarId = null;
        }
      } else if (params.column.colId.match("tempresourceTypeId")) {
        if (params.newValue != "") {
          if (
            typeof parseInt(params.newValue) === "number" &&
            this.getResourceTypeData.find((x) => x.id == params.newValue) ==
              undefined
          ) {
            params.data.tempresourceTypeId = params.newValue;
            params.data.resourceTypeId = this.getResourceTypeData.find(
              (x) => x.description == params.newValue
            ).id;
          } else {
            params.data.resourceTypeId = params.newValue;
            params.data.tempresourceTypeId = this.getResourceTypeData.find(
              (x) => x.id == params.newValue
            ).description;
          }
        } else {
          params.data.resourceTypeId = null;
          params.data.tempresourceTypeId = null;
        }
      } else if (params.column.colId.match("et_")) {
        let colvalue = params.column.colId.split("_");
        let tempIndex = -1;

        tempIndex = params.node.data.extraColumnValues.findIndex(
          (y) => y.ResourceExtraColumnId == parseInt(colvalue[1])
        );
        params.node.data.rowUpdated = true;
        if (tempIndex < 0) {
          params.node.data.extraColumnValues.push({
            ResourceId: params.node.data.id,
            ResourceExtraColumnId: colvalue[1],
            value: params.newValue,
          });
        } else {
          params.node.data.extraColumnValues[tempIndex].value = params.newValue;
        }
      }

      params.node.data.rowUpdated = true;
      // params.api.refreshCells({ force: true, suppressFlash: true });
      if (undoSize == 0) {
        this.mutateChildrenUndostate(false);
        params.node.data.rowUpdated = false;
      } else {
        this.mutateChildrenUndostate(true);
      }

      if (redoSize == 0) {
        this.mutateChildrenRedostate(false);
      } else {
        this.mutateChildrenRedostate(true);
        params.node.data.rowUpdated = false;
      }
      params.api.refreshCells({ force: true, suppressFlash: true });
    },

    onRowDragMove(event) {
      setSchedulePotentialParentForNode(event.api, event.overNode);
    },

    onRowDragLeave(event) {
      setSchedulePotentialParentForNode(event.api, null);
    },

    onRowDragEnd(event) {
      this.$vToastify.setSettings(this.NormalSettings);
      if (!potentialParent) {
        return;
      }
      var movingData = event.node.data;
      var newParentPath = potentialParent.data ? potentialParent.data.path : [];
      if (potentialParent.data.id > 0) {
        var needToChangeParent = !areSchedulePathsEqual(
          newParentPath,
          movingData.path
        );
        var invalidMode = isSelectionScheduleParentOfTarget(
          event.node,
          potentialParent
        );
        if (invalidMode) {
          this.$vToastify.error("invalid move!");
          console.error("invalid move");
        }
        if (needToChangeParent && !invalidMode) {
          var updatedRows = [];
          moveScheduleToPath(newParentPath, event.node, updatedRows);

          updatedRows.forEach((element) => {
            element.rowUpdated = true;
          });
          if (updatedRows[0].path.length > 1) {
            updatedRows[0].parentId =
              updatedRows[0].path[updatedRows[0].path.length - 2];
          } else {
            updatedRows[0].parentId = null;
          }
          this.gridChildrenOptions.api.applyTransactionAsync({
            update: updatedRows,
          });

          this.gridChildrenOptions.api.clearFocusedCell();
        }
      } else {
        this.$vToastify.error("invalid move!");
        console.error("invalid move");
      }
      setSchedulePotentialParentForNode(event.api, null);
    },

    onGridReady(params) {
      getColumnDef(16)
        .then((moduleBlob) => {
          // get blob
          // create script element
          // @onload use loaded global function from script
          let container = this.$refs.scriptContainerScheduleResources;
          var moduleUrl = URL.createObjectURL(moduleBlob);
          let scriptEl = document.createElement("script");
          scriptEl.type = "text/javascript";
          // NOTE: need eslint disable line because of dynamic function
          scriptEl.onload = () => {
            __hydrateColumns(this, utilitiesModule);
          }; // eslint-disable-line
          scriptEl.src = moduleUrl;
          container.appendChild(scriptEl);
          URL.revokeObjectURL(moduleUrl);
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          this.LoadCalendarExtraColumn().then(() => {
            this.loadResources();
          });
        })
        .catch((error) => {
          console.error("alert/error", error, { root: true });
        });
    },

    reloadColumnState() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      //       otherwise will run normal code
      getColumnState(0, 16)
        .then((resp) => {
          if (resp.succeeded && resp.data) {
            // NOTE: suppressColumnStateEvents="true", ensure we will not double save/override
            this.gridChildrenOptions.columnApi.applyColumnState({
              state: JSON.parse(resp.data),
              applyOrder: true,
            });
          }
        })
        .then(() => {
          // load road async <can assume rows will already be set in backend>
          // NOTE: we can load month columns and row columns at same time
          // this.mutateExtaColumn();
        })
        .catch((error) => {
          console.log("alert/error", error, { root: true });
        });
    },
    onColumnChanged: debounce(function () {
      this.onColumnStateSubmit();
    }, 300),

    onColumnStateSubmit() {
      if (this.loading) {
        console.error("[ERR] onColumnStateSubmit called while loading");
        return;
      }
      var d = this.gridChildrenOptions.columnApi.getColumnState();

      postColumnState({
        componentId: 16,
        updatedColumnState: d,
      })
        .then(() => {
          console.log("Column State Saved");
        })
        .catch((error) => {
          console.error("Column State Not Saved: ", error);
        });
    },
  },

  beforeMount() {
    /*Set Grid Tree Collumn */
    this.LoadResourceTypesColumn();
    this.LoadCalendarColumn();
    this.components = { scheduleCellRenderer: getScheduleCellRenderer() };
    this.defaultColDef = Object.assign({}, this.defaultColDef, {
      width: 180,
    });
    this.autoGroupColumnDef = {
      headerName: "Resources",
      rowDrag: true,
      suppressPaste: true,
      //   cellRendererFramework:"ScheduleCellRenderer",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: "scheduleCellRenderer",
      },
      resizable: true,
      sortable: true,
      filterParams: { excelMode: "windows" },
      filter: "agSetColumnFilter",
      width: 160,
      icons: {
        sortAscending: '<i class="fa fa-sort-alpha-up"/>',
        sortDescending: '<i class="fa fa-sort-alpha-down"/>',
        menu: '<i class="fa fa-caret-square-down"/>',
      },
    };
    this.getDataPath = (data) => {
      return data.path;
    };
    this.columnDefs = [];
    /**
     * Load Column Defination
     * Load All CostElement's Data
     * Set Basic Functions Here
     */
  },

  mounted() {
    //   this.columnDefs = this.getChildrenGridColumn;
    //
  },
};

window.moveScheduleToPath = function moveScheduleToPath(
  newParentPath,
  node,
  allUpdatedNodes
) {
  //console.log("adsadasdas");
  var oldPath = node.data.path;
  var fileName = oldPath[oldPath.length - 1];
  var newChildPath = newParentPath.slice();
  newChildPath.push(fileName);
  node.data.path = newChildPath;
  allUpdatedNodes.push(node.data);
  if (node.childrenAfterGroup) {
    node.childrenAfterGroup.forEach(function (childNode) {
      moveScheduleToPath(newChildPath, childNode, allUpdatedNodes);
    });
  }
};

window.isSelectionScheduleParentOfTarget =
  function isSelectionScheduleParentOfTarget(selectedNode, targetNode) {
    var children = selectedNode.childrenAfterGroup;
    for (var i = 0; i < children.length; i++) {
      if (targetNode && children[i].key === targetNode.key) return true;
      isSelectionScheduleParentOfTarget(children[i], targetNode);
    }
    return false;
  };

window.areSchedulePathsEqual = function areSchedulePathsEqual(path1, path2) {
  //console.log(path2, path2, path1);
  if (path1.length !== path2.length) {
    return false;
  }
  var equal = true;
  path1.forEach(function (item, index) {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
};

window.setSchedulePotentialParentForNode =
  function setSchedulePotentialParentForNode(api, overNode) {
    var newPotentialParent;
    if (overNode) {
      newPotentialParent = overNode;
    } else {
      newPotentialParent = null;
    }
    var alreadySelected = potentialParent === newPotentialParent;
    if (alreadySelected) {
      return;
    }
    var rowsToRefresh = [];
    if (potentialParent) {
      rowsToRefresh.push(potentialParent);
    }
    if (newPotentialParent) {
      rowsToRefresh.push(newPotentialParent);
    }
    potentialParent = newPotentialParent;
    refreshRows(api, rowsToRefresh);
  };

window.refreshRows = function refreshRows(api, rowsToRefresh) {
  var params = {
    rowNodes: rowsToRefresh,
    force: true,
  };
  api.refreshCells(params);
};

window.getScheduleCellRenderer = function getScheduleCellRenderer() {
  function ScheduleCellRenderer() {}
  ScheduleCellRenderer.prototype.init = function (params) {
    var tempDiv = document.createElement("div");

    // params.api.refreshCells({ force: true, suppressFlash: true });
    tempDiv.innerHTML =
      params.node.data.resourceTypeId == 1
        ? params.node.allChildrenCount == null && params.data.parentId == null
          ? '<img src="Icons/resources2.svg"; alt="" style="margin-top:5px; margin-left:28px; height:20px; width:20px;"/>'
          : '<img src="Icons/resources2.svg"; alt="" style="margin-top:5px; height:20px; width:20px;"/>'
        : params.node.data.resourceTypeId == 2 ||
          params.node.data.resourceTypeId == 5 ||
          params.node.data.resourceTypeId == 6
        ? params.node.allChildrenCount == null && params.data.parentId == null
          ? '<img src="Icons/labour2.svg"; alt="" style="margin-top:5px; margin-left:28px;  height:20px; width:20px;"/>'
          : '<img src="Icons/labour2.svg"; alt="" style="margin-top:5px; height:20px; width:20px;"/>'
        : params.node.data.resourceTypeId == 3
        ? params.node.allChildrenCount == null && params.data.parentId == null
          ? '<img src="Icons/materials2.svg"; alt="" style="margin-top:5px; margin-left:28px; height:20px; width:20px;"/>'
          : '<img src="Icons/materials2.svg"; alt="" style="margin-top:5px; height:20px; width:20px;"/>'
        : params.node.data.resourceTypeId == 4
        ? params.node.allChildrenCount == null && params.data.parentId == null
          ? '<img src="Icons/equipment2.svg";  alt="" style="margin-top:5px; margin-left:28px;  height:20px; width:20px;"/>'
          : '<img src="Icons/equipment2.svg"; alt="" style="margin-top:5px;  height:20px; width:20px;"/>'
        : null;

    this.eGui = tempDiv.firstChild;
  };
  ScheduleCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return ScheduleCellRenderer;
};
var calendarTypes = null;

window.SetCalendars = function SetCalendars(data) {
  calendarTypes = data;
};

window.getCalendarModelCellRenderer = function getCalendarModelCellRenderer() {
  function CustomCombobox() {}
  CustomCombobox.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var calendarTypeIDs = calendarTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );

    this.eOptionVal = document.createElement("option");
    //Statical set data in grid ComboBox
    this.eOptionVal.text = "";
    this.eOptionVal.value = "";
    this.eInput.appendChild(this.eOptionVal);
    calendarTypeIDs.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      if (x.description == params.value) {
        this.eOptionVal.selected = true;
      }
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  CustomCombobox.prototype.getGui = function () {
    return this.eGui;
  };

  CustomCombobox.prototype.getGui = function () {
    return this.eInput;
  };
  CustomCombobox.prototype.afterGuiAttached = function () {
    this.eInput.focus();
  };
  CustomCombobox.prototype.getValue = function () {
    return this.eInput.value;
  };
  CustomCombobox.prototype.destroy = function () {};
  CustomCombobox.prototype.isPopup = function () {
    return false;
  };
  return CustomCombobox;
};

var potentialParent = null;

window.getDatePicker = function getDatePicker() {
  function Datepicker() {}
  var tempDatevalue = null;
  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "date");
    if (params.value != null) {
      this.eInput.value = moment(params.value).toISOString().substr(0, 10);
    }
    tempDatevalue = params.value; // moment(this.getSelectedRowObject.endDate).toISOString().substr(0, 10);

    this.eInput.classList.add("input");
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
    /*  $(this.eInput).datepicker({
      constrainInput: true, // prevent letters in the input field
      autoSize: true, // automatically resize the input field
      dateFormat: "dd/mm/yyyy",
      changeMonth: true,
      changeYear: true,
      yearRange: "1930:" + new Date().getFullYear(),
    }); */
  };
  Datepicker.prototype.getGui = function () {
    return this.eInput;
  };
  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Datepicker.prototype.getValue = function () {
    if (this.eInput.value.length > 0) {
      return moment(this.eInput.value).add(1, "days").format("YYYY-MM-DD");
    } else {
      return tempDatevalue;
    }
  };
  Datepicker.prototype.destroy = function () {};
  Datepicker.prototype.isPopup = function () {
    return false;
  };
  return Datepicker;
};

window.getIntegerCellEditor = function getIntegerCellEditor() {
  function IntegerPicker() {}
  IntegerPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.setAttribute("step", "1");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };

  /*  IntegerPicker.prototype.isKeyPressedNavigation = function(event) {
    return event.keyCode !=110;
  }; */
  IntegerPicker.prototype.getGui = function () {
    return this.eInput;
  };
  IntegerPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  IntegerPicker.prototype.getValue = function () {
    return parseInt(this.eInput.value);
  };
  IntegerPicker.prototype.destroy = function () {};
  IntegerPicker.prototype.isPopup = function () {
    return false;
  };
  return IntegerPicker;
};

window.getDecimalCellEditor = function getDecimalCellEditor() {
  function DecimalPicker() {}
  DecimalPicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.setAttribute("type", "number");
    this.eInput.classList.add("input");
    this.eInput.value = params.value;
    this.eInput.style.display = "inline-block";
    this.eInput.setAttribute("style", "width:100%");
    this.eInput.style.height = "100%";
    this.eInput.style.fontFamily = "Helvetica";
    this.eInput.style.fontweight = "bold";
    this.eInput.style.fontSize = "1.0em";
  };
  DecimalPicker.prototype.getGui = function () {
    return this.eInput;
  };
  DecimalPicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  DecimalPicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  DecimalPicker.prototype.destroy = function () {};
  DecimalPicker.prototype.isPopup = function () {
    return false;
  };
  return DecimalPicker;
};

window.getLookupCellEditor = function getLookupCellEditor() {
  function Lookuppicker() {}
  Lookuppicker.prototype.init = function (params) {
    this.eInput = document.createElement("select");
    this.eInput.setAttribute("class", "select");
    this.eOption = document.createElement("option");
    this.eInput.setAttribute("style", "width:100%");
    var CostModelTypeFilter = CostModelTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );
    CostModelTypeFilter.forEach((x) => {
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      this.eOptionVal.text = x.description;
      this.eOptionVal.value = x.description;
      this.eInput.appendChild(this.eOptionVal);
    });
  };
  Lookuppicker.prototype.getGui = function () {
    return this.eInput;
  };
  Lookuppicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Lookuppicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Lookuppicker.prototype.destroy = function () {};
  Lookuppicker.prototype.isPopup = function () {
    return false;
  };
  return Lookuppicker;
};

var ResourceTypes = null;

window.SetResourceTypes = function SetResourceTypes(data) {
  ResourceTypes = data;
};

window.getResourceTypesModelCellRenderer =
  function getResourceTypesModelCellRenderer() {
    function CustomResourceTypesCombobox() {}
    CustomResourceTypesCombobox.prototype.init = function (params) {
      this.eInput = document.createElement("select");
      this.eInput.setAttribute("class", "select");
      this.eOption = document.createElement("option");
      this.eInput.setAttribute("style", "width:100%");
      this.eInput.setAttribute("id", "resourceType");
      var ResourceTypesIDs = ResourceTypes.sort((a, b) =>
        a.description > b.description ? 1 : -1
      );
      //console.log(ResourceTypesIDs);
      this.eOptionVal = document.createElement("option");
      //Statical set data in grid ComboBox
      // this.eInput.appendChild(this.eOptionVal);
      ResourceTypesIDs.forEach((x) => {
        this.eOptionVal = document.createElement("option");
        //Statical set data in grid ComboBox
        this.eOptionVal.text = x.description;
        this.eOptionVal.value = x.description;
        if (x.description == params.value) {
          this.eOptionVal.selected = true;
        }
        this.eInput.appendChild(this.eOptionVal);
      });
    };
    CustomResourceTypesCombobox.prototype.getGui = function () {
      return this.eGui;
    };

    CustomResourceTypesCombobox.prototype.getGui = function () {
      return this.eInput;
    };
    CustomResourceTypesCombobox.prototype.afterGuiAttached = function () {
      this.eInput.focus();
    };
    CustomResourceTypesCombobox.prototype.getValue = function () {
      return this.eInput.value;
    };
    CustomResourceTypesCombobox.prototype.destroy = function () {};
    CustomResourceTypesCombobox.prototype.isPopup = function () {
      return false;
    };
    return CustomResourceTypesCombobox;
  };
</script>

<style scoped lang="scss">
.line {
  width: 99.5%;
  height: 1%;
  border-bottom: 1px solid black;
  position: relative;
}
</style>
