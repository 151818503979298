<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div
      id="navbarBasicExample"
      class="navbar-menu is-vcentered"
      v-if="loggedIn"
    >
      <div class="navbar-start">
        <div v-if="filters.From != null">
          <p align="right">
            From Date:
            <span v-html="new Date(filters.From).toLocaleDateString()"></span>
          </p>
        </div>
        <div v-if="filters.To != null">
          <p align="right">
            To Date:
            <span v-html="new Date(filters.To).toLocaleDateString()"></span>
          </p>
        </div>
        <div v-if="filters.Report != null">
          <p align="right">
            Reporting Date:
            <span v-html="new Date(filters.Report).toLocaleDateString()"></span>
          </p>
        </div>
      </div>
      <!-- <div class="module-toolbar buttons has-addons" v-if="loggedIn"> -->
      <div class="navbar-end">
        <button
          class="button small is-outlined"
          title="Save"
          @click.capture.prevent="requestDataUpload"
          v-if="currentRouteName == 'Views'"
        >
          <span class="icon is-small">
            <i class="fas fa-save"></i>
          </span>
          <!-- <span>Save</span> -->
        </button>
        <button
          class="button small is-outlined"
          title="Reset Columns"
          @click.capture.prevent="_resetColumnsState"
          v-if="currentRouteName == 'Views'"
        >
          <span class="icon is-small">
            <i class="fas fa-text-width"></i>
          </span>
          <!-- <span>Reset Columns</span> -->
        </button>
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          disabled
          title="Undo"
        >
          <span class="icon is-small">
            <i class="fas fa-undo"></i>
          </span>
          <!-- <span>Undo</span> -->
        </button>

        <button
          class="button"
          v-if="currentRouteName == 'Views'"
          disabled
          title="Redo"
        >
          <span class="icon is-small">
            <i class="fas fa-redo"></i>
          </span>
          <!-- <span>Redo</span> -->
        </button>
        <button class="button" disabled><span>&emsp;</span></button>
        <!-- <button
          class="button"
          title="Add Relationship"
          v-if="this.$store.state.selectedRowType > -1"
        >
         @click="addRelationship"> 
          <span class="icon is-small">
            <i class="fas fa-network-wired"></i>
          </span>
        </button> -->
      </div>
    </div>
  </nav>
</template>
<style lang="scss" scoped>
p {
  width: 165px;
  display: inline-block;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}
nav.navbar {
  border-top: 1px solid black;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
</style>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
const store = {
  FromDate: null,
  ToDate: null,
};

export default {
  name: "ModuleToolBar",
  data() {
    return { store };
  },
  computed: {
    ...mapState({
      showFilterModal: (state) =>
        state.filterControl.isStructureFilterModalVisible,
      views: (state) => state.userView.views,
    }),

    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("userView", ["selectedView"]),
    ...mapGetters("userView", ["canAddR", "selectedRowType"]),
    ...mapGetters("filterControl", ["filters"]),
    ...mapMutations("storedates", ["storedatevalues"]),
    currentRouteName() {
      if (this.filters.isSave) {
        if (this.filters.From != undefined) {
          store.FromDate = this.filters.From;
        } else {
          store.FromDate = null;
        }
        if (this.filters.To != undefined) {
          store.ToDate = this.filters.To;
        } else {
          store.ToDate = null;
        }
      }
      if (store.ToDate == null) {
        store.ToDate = this.filters.To;
        store.FromDate = this.filters.From;
        this.$store.commit("storedates/storedatevalues", {
          fromdate: this.filters.From,
          todate: this.filters.To,
        });
      }
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions("account", ["login", "logout"]),
    ...mapActions("userView", ["resetColumnsState"]),
    ...mapMutations("userView", ["mutateSelectedView", "requestDataUpload"]),
    _resetColumnsState() {
      this.resetColumnsState({ viewId: this.selectedView.id });
    },
  },
};
</script>
