<template>
  <section style="height: 100%" v-show="loggedIn">
    <view-grid
      ref="viewGrid"
      :view="selectedView"
      :fromDate="fromDate"
      :toDate="toDate"
      v-if="selectedView != null"
    />
  </section>
</template>

<script>
// @ is an alias to /src
import ViewGrid from "@/components/ViewGrid.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "Views",
  components: {
    ViewGrid,
  },
  watch: {
    uploadDataWatcher() {
      this.$refs.viewGrid.onSubmit();
    },
    uploadColumnStateWatcher() {
      this.$refs.viewGrid.onColumnStateSubmit();
    },
    reloadColumnStateWatcher() {
      // NOTE: this is special cased where if `columnState.data == null -> will call _hydrateFuncs again` to resetColumnState
      this.$refs.viewGrid.reloadColumnState();
    },
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("userView", [
      "selectedView",
      "userViewsLoaded",
      "allUserViews",
    ]),
    ...mapState({
      fromDate: (state) => state.filterControl.fromDate,
      toDate: (state) => state.filterControl.toDate,
      portfolios: (state) => state.filterControl.portfolios,
      programs: (state) => state.filterControl.programs,
      projects: (state) => state.filterControl.projects,
      uploadDataWatcher: (state) => state.userView.uploadDataWatcher,
      uploadColumnStateWatcher: (state) =>
        state.userView.uploadColumnStateWatcher,
      reloadColumnStateWatcher: (state) =>
        state.userView.reloadColumnStateWatcher,
    }),
    ...mapGetters("userView", ["selectedView"]),
  },
  mounted: function () {
    // console.log("Viewmounted");
    // console.log("this.$route.params.id", this.$route.params.id);
    if (!this.userViewsLoaded || this.selectedView == null) {
      console.log("Loading Views");
      this.updateViewListAsync().then((result) => {
        this.setSelectedViewId(this.$route.params.id);
      });
    }
    // this.updateViewList();
  },

  data() {
    return {};
  },
  methods: {
    ...mapActions("userView", [
      "updateViewList",
      "setSelectedViewId",
      "updateViewListAsync",
    ]),
  },
};
</script>
