<template>
  <div style="height: 100%; background-color: white">
    <div class="line"></div>
    <div
      class="column"
      style="height: 100%; width: 100%; background-color: white"
    >
      <div
        class="container-grid"
        style="width: 100%; height: 100%; margin: 1%; overflow: auto"
      >
        <div class="column is-multiline">
          <div class="column is-4 group">
            <img :alt="clientId" :src="getImgUrl()" height="28" />
          </div>
        </div>
        <div class="column is-multiline">
          <div class="column is-5 group">
            <input
              type="text"
              v-model="username"
              autocomplete="off"
              required
              disabled
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <!--  <label>UserName</label> -->
          </div>

          <div class="column is-5 group">
            <input
              type="password"
              v-model="oldPassword"
              name="getCurrentColumns[0].field"
              autocomplete="off"
              required
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label>Old Password</label>
          </div>

          <div class="column is-5 group">
            <p>
              <input
                type="password"
                value=""
                id="p-c"
                name="P-C"
                class="password"
                autocomplete="off"
                minlength="8"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}(/[^\w\s]|_/)"
                title="Must contain at least one number and one uppercase, lowercase letter and one punctuation, and at least 8 or more characters"
                required
                v-on:keyup="key"
              />
              <button
                class="unmask"
                @click="myFunction('p-c')"
                type="button"
              ></button>

              <span class="highlight"></span>
              <span class="bar"></span>
              <label>New Password</label>
            </p>
          </div>

          <div class="column is-5 group">
            <p>
              <input
                type="password"
                value=""
                id="p"
                name="P-C"
                class="password"
                autocomplete="off"
                minlength="8"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}(/[^\w\s]|_/)"
                title="Must contain at least one number and one uppercase, lowercase letter and one punctuation, and at least 8 or more characters"
                v-model="Newpassword"
                required
                v-on:keyup="key"
              />
              <button
                class="unmask"
                @click="myFunction('p')"
                type="button"
              ></button>

              <span class="highlight"></span>
              <span class="bar"></span>
              <label>Re-enter New Password</label>
            </p>
          </div>

          <div class="column is-3 group" style="text-align: center">
            <div id="strong"><span></span></div>
            <div id="valid"></div>
          </div>
          <div class="column is-5 group">
            <button
              class="button"
              @click="submit()"
              type="submit"
              value="Submit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "ag-grid-enterprise";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "UserMgmt",
  props: {},
  data() {
    return {
      passwordMatch: null,
      NormalSettings: {
        withBackdrop: false,
        backdrop: "rgba(0, 0, 0, 0.5)",
        position: "top-right",
      },
      oldPassword: "",
      Newpassword: "",
      has_minimum_lenth: false,
      has_number: false,
      has_lowercsae: false,
      has_uppercase: false,
      has_special: false,
    };
  },
  components: {},
  beforeMount() {
    this.$vToastify.setSettings(this.NormalSettings);
  },
  mounted() {},
  watch: {
    Newpassword() {
      this.has_minimum_lenth = this.Newpassword.length > 8;
      this.has_number = /\d/.test(this.Newpassword);
      this.has_lowercase = /[a-z]/.test(this.Newpassword);
      this.has_uppercase = /[A-Z]/.test(this.Newpassword);
      this.has_special = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.Newpassword);
    },
    resetError() {
      if (this.resetError > 0) {
        this.$vToastify.error(
          "Entered Current Account Password is not matched!"
        );
      }
    },

    passwordCounter() {
      if (this.passwordCounter > 0) {
        this.oldPassword = "";
        this.Newpassword = "";
        this.$vToastify.success("Password changed Successfully!");
      }
    },
  },
  computed: {
    ...mapGetters("clientAdmin", ["getUsers"]),
    ...mapState({
      views: (state) => state.userView.views,
    }),
    ...mapGetters("account", ["loggedIn", "username", "clientId"]),
    ...mapState("clientAdmin", ["passwordCounter", "resetError"]),
    usernameInitials() {
      return this.username
        .split(" ")
        .map((p) => p[0].toUpperCase())
        .join();
    },
    clientLogo() {
      return this.clientId.toString() == "3"
        ? "/logo/AusGrid.png"
        : "/logo/JH Logo.png";
    },
  },
  methods: {
    ...mapActions("clientAdmin", ["loadUsers", "resetPassword"]),
    getImgUrl() {
      var valToReturn = "";
      switch (this.clientId) {
        case 3:
          valToReturn = "/logo/AusGrid.png";
          break;
        case 4:
          valToReturn = "/logo/AusGrid.png";
          break;
        case 5:
          valToReturn = "/logo/Mirvac Logo.png";
          break;
        case 6:
          valToReturn = "/logo/Electranet.svg";
          break;
        default:
          console.log(`Sorry, we are out of ${expr}.`);
      }
      return valToReturn;
      //return this.clientId == 3 ? "/logo/AusGrid.png" : "/logo/JH Logo.png";
    },

    submit() {
      if (
        this.has_minimum_lenth &&
        this.has_number &&
        this.has_lowercase &&
        this.has_uppercase &&
        this.has_special
      ) {
        var AccountRestPassword = {
          Email: this.username,
          oldPassword: this.oldPassword,
          Newpassword: this.Newpassword,
        };
        this.resetPassword(AccountRestPassword);
      } else {
        $("#valid").html(
          "Password Pattern Don't Match. Must be 8+ characters long and contain at least 1 upper case letter, 1 number, 1 special character"
        );
      }
    },

    myFunction(value) {
      var x = document.getElementById(value);
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
      return false;
    },

    key: function (event) {
      var p_c = $("#p-c");
      var p = $("#p");
      if (p.val().length > 0) {
        if (p.val() != p_c.val()) {
          $("#valid").html("Passwords Don't Match");
        } else {
          $("#valid").html("");
        }
        var s = "weak";
        if (p.val().length > 5 && p.val().match(/\d+/g)) s = "medium";
        if (p.val().length > 6 && p.val().match(/[^\w\s]/gi)) s = "strong";
        if (s == "strong") {
          this.passwordMatch = true;
        } else {
          this.passwordMatch = false;
        }
        $("#strong span").addClass(s).html(s);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-family: Verdana;
  font-size: 1.4vw;
}
.formulate-input {
  .formulate-input-label {
    font-weight: bold;
  }
}
/* form starting stylings ------------------------------- */
.group {
  position: relative;
  margin-bottom: 15px;
}

input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
}
.line {
  width: 100%;
  height: 3%;
  border-bottom: 1px solid black;
  position: relative;
  background-color: white;
}

input:focus {
  outline: none;
}

/* LABEL ======================================= */
label {
  color: #999;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 7px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label {
  top: -10px;
  font-size: 14px;
  color: black;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 300px;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

.button {
  width: 140px;
  height: 45px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.button:hover {
  background-color: #2ee59d;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

.password[name="P-C"] + .unmask {
  position: absolute;
  right: 5%;
  top: 10px;
  width: 25px;
  height: 25px;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  font-family: FontAwesome;
  font-size: 14px;
  line-height: 24px;
  -webkit-appearance: none;
  outline: none;
}
.password[name="P-C"] + .unmask:before {
  content: "\f06e";
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  background: rgba(205, 205, 205, 0.2);
  z-index: 1;
  color: #aaa;
  border: 2px solid;
  border-radius: 50%;
}
.password[name="P-C"] + .unmask:before {
  content: "\f070";
  background: rgba(105, 205, 255, 0.2);
  color: #06a;
}
#valid {
  font-size: 12px;
  color: rgb(29, 15, 223);
  height: 15px;
}
#strong {
  height: 20px;
  font-size: 12px;
  color: rgb(223, 15, 15);
  text-transform: capitalize;
  background: rgba(205, 205, 205, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

#strong span {
  display: block;
  box-shadow: 0 0 0 #fff inset;
  height: 100%;
  transition: all 0.8s;
}
#strong .weak {
  box-shadow: 5em 0 0 #daa inset;
}
#strong .medium {
  color: rgb(223, 15, 15);
  box-shadow: 10em 0 0 #da6 inset;
}
#strong .strong {
  color: rgb(223, 15, 15);
  box-shadow: 50em 0 0 #ada inset;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
::-webkit-input-placeholder {
  color: #999;
  font-weight: bold;
}
.column {
  box-shadow: 0.01rem 0.01rem 0.01rem rgb(241, 224, 224);
  margin-top: 0.01rem;
}
</style>
