import { Baseline } from "@bryntum/gantt";

export default class JiveConnectBaseline extends Baseline {
  static get fields() {
    return [
      { name: "code", type: "string" },
      { name: "description", type: "string" },
    ];
  }
}
