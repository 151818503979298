import { userService } from "@/api/user-auth";

const state = {
  entitiesLoaded: false,
  rawEntitiesLoaded: false,
  entitiesLoading: false,
  entitiesUpdated: false,
  localAllComponents: [],
  localRawComponents: [],
  localAllModules: [],
  localAllGroupAccess: [],
  defaultComponentColumns: [
    {
      headerName: "Show In Menu?",
      field: "ShowInMenu",
      cellRenderer: function (params) {
        var input = document.createElement("input");
        input.type = "checkbox";
        input.checked = params.value;
        input.addEventListener("click", function (event) {
          let checked = event.target.checked;
          let colId = params.column.colId;
          params.node.setDataValue(colId, checked);
        });
        return input;
      },
      rowDrag: true,
    },
    {
      field: "Order",
      editable: true,
    },
    {
      field: "Code",
    },
    {
      field: "Desc",
    },
    {
      field: "MenuHeader",
    },
    {
      field: "Icon",
    },
  ],
  loadGanttAccess: null,
};

const actions = {
  loadJiveComponents({ commit, rootGetters }) {
    state.entitiesLoaded = false;
    state.entitiesLoading = true;
    userService.getJson(`JIVEUser/GetComponentAccess`).then((result) => {
      if (result.succeeded && result.data != null) {
        var modules = [
          ...new Set(
            result.data.map((x) => {
              return {
                Id: x.moduleId,
                Code: x.moduleCode,
                Desc: x.description,
              };
            })
          ),
        ];

        var components = [
          ...new Set(
            result.data.map((x) => {
              return {
                Id: x.componentId,
                Code: x.componentCode,
                Desc: x.componentDescription,
                MenuHeader: x.componentMenuHeader,
                Path: x.componentPath,
                Icon: x.componentIcon,
              };
            })
          ),
        ];
        components = _.groupBy(components, "MenuHeader");
        commit("mutateAllModules", modules);
        commit("mutateAllComponents", components);
        state.entitiesLoaded = true;
        state.entitiesLoading = false;
        state.loadGanttAccess = result.data.find(
          (x) => x.componentId == 9
        ).access;

        if (state.loadGanttAccess == "ReadOnly") state.loadGanttAccess = true;
        else state.loadGanttAccess = false;
      }
    });
  },
  loadAllLicencedComponents({ commit, rootGetters }) {
    state.rawEntitiesLoaded = false;
    state.entitiesLoading = true;
    userService.getJson(`JIVEUser/GetComponentsAndAccess`).then((result) => {
      if (result.succeeded && result.data != null) {
        var components = [
          ...new Set(
            result.data.allComponents.map((x) => {
              return {
                Id: x.id,
                Code: x.code,
                Desc: x.description,
                MenuHeader: x.menuHeader,
                Icon: x.icon,
                Order: 1,
                ShowInMenu: false,
                Path: [x.menuHeader, x.code],
              };
            })
          ),
        ];
        //components = _.groupBy(components, "MenuHeader");
        commit("mutateAllGroupAccess", result.data.groupAccess);
        commit("mutateRawComponents", components);
        //console.log("components", state.localRawComponents);
        //console.log("allAccess", state.localAllGroupAccess);
        state.rawEntitiesLoaded = true;
        state.entitiesLoading = false;
      }
    });
  },
  updateLicencedComponents({ commit, rootGetters }, groupId) {
    //console.log("Updating to " + groupId);
    var currGroup = state.localAllGroupAccess.find((x) => x.id == groupId);
    var componentsToUpdate = [...state.localRawComponents];
    componentsToUpdate = componentsToUpdate.map((obj) => ({
      ...obj,
      ShowInMenu: false,
    }));
    console.log(
      "currGroup.groupComponentAccess",
      currGroup.groupComponentAccess
    );
    console.log("componentsToUpdate", componentsToUpdate);
    currGroup.groupComponentAccess.forEach((x) => {
      componentsToUpdate.find((y) => y.Id == x.componentId).ShowInMenu = true;
    });
    currGroup.groupComponentAccess.forEach((x) => {
      componentsToUpdate.find((y) => y.Id == x.componentId).Order = x.order;
    });
    commit("mutateRawComponents", componentsToUpdate);
  },
  addGroupAccess({ commit }, groupAccessObj) {
    console.log("Adding groupAccessObj", groupAccessObj);
  },
  logout({ commit }) {
    state.entitiesLoaded = false;
    commit("mutateAllComponents", null);
    commit("mutateAllModules", null);
  },
  async saveGroupAccess({ commit }, newGroupAccess) {
    console.log("newGroupAccess", newGroupAccess);
    var updatedGroupsAccess = newGroupAccess.filter((x) => x.updated);
    await userService
      .post(
        `JIVEUser/UpdateGroupAccess`,
        state.localAllGroupAccess.filter((x) => x.updated)
      )
      .then((result) => {
        if (result.succeeded && result.data != null) {
          //Remove updated flag from all
          state.localAllGroupAccess;
          savedComponents = [...state.localAllGroupAccess].map((obj) => ({
            ...obj,
            updated: false,
          }));
          commit("mutateAllGroupAccess", savedComponents);
        }
      });
  },
};
function isValid(valueToCheck) {
  if (
    String(valueToCheck) != "" &&
    String(valueToCheck) != "null" &&
    String(valueToCheck) != "undefined"
  )
    return true;
  else {
    return false;
  }
}
const mutations = {
  mutateAllModules(state, result) {
    state.localAllModules = result;
  },
  mutateRawComponents(state, result) {
    state.localRawComponents = result;
  },
  mutateAllGroupAccess(state, result) {
    state.localAllGroupAccess = result;
  },
  mutateAllComponents(state, result) {
    state.localAllComponents = [];
    if (isValid(result)) {
      var resultKeys = Object.keys(result);
      for (var currKey in resultKeys) {
        state.localAllComponents.push({
          MenuHeader: resultKeys[currKey],
          Components: result[resultKeys[currKey]],
          Icon: result[resultKeys[currKey]][0].Icon,
        });
      }
    }
  },
};

const getters = {
  allModules(state) {
    return state.localAllModules;
  },
  allComponents(state) {
    return state.localAllComponents;
  },
  allRawComponents(state) {
    return state.localRawComponents;
  },
  allGroupAccess(state) {
    return state.localAllGroupAccess;
  },
  entitiesLoaded(state) {
    return state.entitiesLoaded;
  },
  rawEntitiesLoaded(state) {
    return state.rawEntitiesLoaded;
  },
  defaultComponentColumns(state) {
    return state.defaultComponentColumns;
  },
  menuHeaders(state) {
    return [...new Set(state.localAllComponents.map((x) => x.MenuHeader))];
  },
  ganttAccess(state) {
    return state.loadGanttAccess;
  },
};

export const jiveComponents = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
