<template>
  <div class="modal is-active is-overlay">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
      </header>
      <section class="modal-card-body columns">
        <div class="column">
          <div class="columns is-multiline is-mobile">
            <div class="column is-12 group">
              <input
                autofocus
                type="text"
                v-model="baselineCode"
                name="baselineCode"
                ref="baselineCode"
                id="baselineCode"
                autocomplete="off"
                required
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>Code</label>
            </div>
            <div class="column is-12 group">
              <input
                type="text"
                v-model="baselineDesc"
                name="desc"
                autocomplete="off"
                required
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>Description</label>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="create">Create</button>
        <button class="button" @click="close">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "AddBaselineModal",
  props: ["isModalVisible"],
  data() {
    return {
      title: "Add Baseline",
      baselineName: "",
      baselineCode: "",
      baselineDesc: "",
      errors: false,
    };
  },
  watch: {
    isModalVisible: function (newVal, oldVal) {
      if (this.isModalVisible) {
        document.activeElement.blur();
        this.$refs.baselineCode.focus();
      }
    },
  },
  methods: {
    create() {
      //this.$emit("create");
      if (this.baselineCode != "") this.$emit("create");
      //   else this.errors = true;
    },
    close() {
      this.$emit("close");
    },
    invalidateForm() {
      this.errors = true;
    },
    focusInput() {},
  },
};
</script>

<style lang="scss" scoped>
form.errors {
  :invalid {
    outline: 2px solid red;
  }
}
input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: transparent;
}

input[type="date"]:disabled {
  color: transparent;
}
input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

.title {
  font-family: Verdana;
  font-size: 1.4vw;
}
.formulate-input {
  .formulate-input-label {
    font-weight: bold;
  }
}
/* form starting stylings ------------------------------- */
.group {
  position: relative;
  margin-bottom: 15px;
}

input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  color: #000;
}

textarea {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
}
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}

h1 {
  font-family: "Trocchi", serif;
  font-weight: normal;
  line-height: 48px;
  margin: 0;
}
/* LABEL ======================================= */
label {
  color: #999;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 7px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label {
  top: -10px;
  font-size: 14px;
  color: black;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 300px;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
::-webkit-input-placeholder {
  color: #999;
  font-weight: bold;
}
.column {
  box-shadow: 0.01rem 0.01rem 0.01rem rgb(241, 224, 224);
  margin-top: 0.01rem;
}
</style>
