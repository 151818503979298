<template>
  <div style="background-color: white; height: 100%; width: 100%">
    <!-- <DataToolBar /> -->

    <ag-grid-vue
      class="ag-theme-balham ag-default-layout"
      rowSelection="multiple"
      enableCellChangeFlash="true"
      stopEditingWhenGridLosesFocus="true"
      :gridOptions="gridOptions"
      :rowData="rowData"
      :defaultColDef="defaultColDef"
      :columnDefs="columnDefs"
      @cell-clicked="onCellClicked"
      :enableRangeSelection="true"
      :enableCharts="enableCharts"
      :undoRedoCellEditing="true"
      :enablePivot="true"
      :processDataFromClipboard="processDataFromClipboard"
      :overlayNoRowsTemplate="overlayNoRowsTemplate"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import DataToolBar from "@/components/Data/DataToolbar.vue";

export default {
  name: "genericGridComponent",
  props: [
    "rowData",
    "gridOptions",
    "columnDefs",
    "processDataFromClipboard",
    "overlayNoRowsTemplate",
    "enableCharts",
  ],
  data() {
    return {
      //rowData: [],
      //   gridOptions: {
      //     statusBar: {
      //       statusPanels: [
      //         { statusPanel: "agTotalRowCountComponent", align: "center" },
      //         { statusPanel: "agFilteredRowCountComponent" },
      //       ],
      //     },
      //   },
      defaultColDef: null,
      //columnDefs: [],
    };
  },
  components: {
    AgGridVue,
    //DataToolBar,
  },
  watch: {},
  methods: {
    onCellClicked(event) {},
    onGridReady() {
      this.gridApi.setDomLayout("autoHeight");
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style></style>
