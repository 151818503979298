<template>
  <nav
    class="navbar"
    role="navigation"
    aria-label="main navigation"
    style="border-bottom: 2px solid; margin-bottom: 5px margin-top: 5px"
  >
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-end" style="margin-right: 18px">
        <!--  Save Button -->
        <button
          class="button"
          title="Save"
          @click="saveData"
          v-if="currentRouteName != 'login'"
          :disabled="getCurrentChildObjId == null"
        >
          <span class="icon is-small">
            <i class="fas fa-save"></i>
          </span>
        </button>
        <!--  RelationShip Button -->
        <!--   :class="{ 'is-active': showFilterModal }" -->
        <!--  <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add Relationship"
          aria-expanded="false"
          :class="{ 'is-active': showFilterModal }"
          @click.capture.prevent="AddRelationship"
          :disabled="getChildrenSelectedId == null"
          
        >
          <span class="icon is-small">
            <i class="fas fa-network-wired"></i>
          </span>
        </button> -->

        <!--  Rest Column State Button -->
        <button
          class="button"
          title="Reset Columns"
          v-if="currentRouteName == 'login'"
        >
          <span class="icon is-small">
            <i class="fas fa-text-width"></i>
          </span>
        </button>

        <!--   <button class="button" title="Undo" v-if="currentRouteName != 'login'">
          <span class="icon is-small">
            <i class="fas fa-undo"></i>
          </span>
        </button>

        <button class="button" title="Redo" v-if="currentRouteName != 'login'">
          <span class="icon is-small">
            <i class="fas fa-redo"></i>
          </span>
        </button> -->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add New"
          @click="addProcess"
          :disabled="getCurrentParentObjId == null"
        >
          <span class="icon is-small">
            <i class="fas fa-plus"></i>
          </span>
        </button>
        <!--  Add Process Between Button -->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add Process in Between Processes"
          @click="mutuateOrderId"
          :disabled="getCurrentParentObjId == null"
        >
          <span class="icon is-small">
            <i class="fa-solid fa-bars-progress"></i>
          </span>
        </button>
        <!--  Add New Sub Record Button -->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Add Sub New"
          @click="addChildProcess"
          :disabled="
            getCurrentChildObjId == null ||
            Object.keys(getCurrentChildObj).length === 0 ||
            (getCurrentChildObj.processType.className != 'PConditional' &&
              getCurrentChildObj.processType.className != 'PWhile') ||
            getCurrentChildObj.processType.className == null
          "
        >
          <span class="icon is-small">
            <i class="far fa-plus-square"></i>
          </span>
        </button>
        <!--  Delete Button -->
        <button
          class="button"
          v-if="currentRouteName != 'login'"
          title="Delete"
          @click="mutateDeleteProcesses"
          :disabled="getCurrentChildObjId == null"
        >
          <span class="icon is-small">
            <i class="fas fa-trash-alt"></i>
          </span>
        </button>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
p {
  width: 165px;
  display: inline-block;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}
nav.navbar {
  border-top: 0px solid black;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
</style>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "processSetChildrenToolbar",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("account", ["loggedIn"]),
    ...mapGetters("processSetController", [
      "getCurrentChildObjId",
      "getCurrentChildObj",
      "getCurrentParentObjId",
    ]),

    /*Children Controller State */

    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions("processSetController", ["saveData"]),
    ...mapMutations("processSetController", [
      "addProcess",
      "addChildProcess",
      "mutateDeleteProcesses",
      "mutuateOrderId",
    ]),
    /* Children Controller Mutations */
  },
};
</script>
